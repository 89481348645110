import { SvgIconProps } from "@mui/material";
import { FC } from "react";

const DocOutIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 13.75h8.75a1.25 1.25 0 0 0 1.25-1.25V4.687L9.062 1.25H3.75A1.25 1.25 0 0 0 2.5 2.5V5"
        stroke="#D4D9E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 1.25V5h3.75M1.25 9.375H7.5m-6.25 0L3.125 7.5M1.25 9.375l1.875 1.875"
        stroke="#D4D9E4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocOutIcon;
