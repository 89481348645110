import React, { FC, useEffect, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { Params, useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "../../../../components/icons/ArrowBackIcon";
import BrowserIcon from "../../../../components/icons/BrowserIcon";
import FormDetailDocIn from "./FormDetailDocIn";
import ModalRefuse from "../modal/ModalRefuse";
import { spacing_size } from "../../../../themes/size";
import { CATEGORY_DOC_IN_ENUM } from "../../../../enum/doc-in.enum";
import ModalAssignment from "../modal/ModalAssignment";
import { getDocInByIdApi } from "../../../../api/doc-in.api";
import { DocInModel } from "../../../../model/doc-in.model";
import ModalUnassigned from "../modal/ModalUnassigned";
import ModalCancel from "../modal/ModalCancel";
import ModalReply from "../modal/ModalReply";
import ModalProcession from "../modal/ModalProcession";
import { Department } from "../../../../model/department.model";
import { convertArrToString } from "../../../../utils/array.util";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { toast } from "react-toastify";
import { DASHBOARD_DOC_IN_VIEW_NAME } from "../../../../enum/dashboard.enum";

interface DetailDocInProps {}

const DetailDocIn: FC<DetailDocInProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params: Readonly<Params<string>> = useParams();
  const [docInDetail, setDocInDetail] = useState<DocInModel>();
  const [arrCodeHandlingDepartment, setArrCodeHandlingDepartment] = useState<[]>([]);

  const getDocInById = async () => {
    if (isNaN(Number(params?.id))) {
      navigate("/404");
    } else {
      await getDocInByIdApi(Number(params?.id))
        .then((res) => {
          setDocInDetail(res?.data);
          const newArr: any = res?.data?.handlingDepartments?.map((item: Department) => item.code);
          setArrCodeHandlingDepartment(newArr);
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            navigate("/404");
            const msg = getErrorMsgAxios(err);
            toast.error(msg);
          }
          // console.log(err);
        });
    }
  };

  const [openModalRefuse, setOpenModalRefuse] = useState<boolean>(false);
  const [openModalAssignment, setOpenModalAssignment] = useState<boolean>(false);
  const [openModalUnassigned, setOpenModalUnassigned] = useState<boolean>(false);
  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const [openModalReply, setOpenModalReply] = useState<boolean>(false);
  const [openModalProcession, setOpenModalProcession] = useState<boolean>(false);
  const [countAssignClose, setCountAssignClose] = useState<number>(0);

  useEffect(() => {
    getDocInById();
  }, []);

  const handleClickButtonBack = () => {
    if (location?.state != null && location.state.locationFrom == DASHBOARD_DOC_IN_VIEW_NAME.DASHOARD_DOC_IN_LIST) {
      navigate(`/dashboard/doc-ins`, {
        state: {
          isReload: false,
        },
      });
      return;
    }

    const { bodyFilter, docYear } = location?.state || {};
    navigate(`/doc-in`, {
      state: {
        bodyFilter: bodyFilter,
        docYear: docYear,
      },
    });
  };

  const handleClickBtnHistory = () => {
    const { bodyFilter, docYear } = location?.state || {};
    navigate(`/doc-in/${params?.id}/history`, {
      state: {
        bodyFilter: bodyFilter,
        docYear: docYear,
      },
    });
  };

  const handleCloseModalRefuse = () => {
    setOpenModalRefuse(false);
  };

  const handleOpenModalRefuse = () => {
    setOpenModalRefuse(true);
  };

  const handleCloseModalAssignment = () => {
    setOpenModalAssignment(false);
    setCountAssignClose(countAssignClose + 1);
  };

  const handleOpenModalAssignment = () => {
    setOpenModalAssignment(true);
  };

  const handleOpenUnassigned = () => {
    setOpenModalUnassigned(true);
  };

  const handleUpdate = () => {
    if (CATEGORY_DOC_IN_ENUM.DOMESTIC === docInDetail?.docCategory) {
      navigate(`/doc-in/${Number(params?.id)}/update-domestic`);
    } else if (CATEGORY_DOC_IN_ENUM.INTERNATIONAL === docInDetail?.docCategory) {
      navigate(`/doc-in/${Number(params?.id)}/update-international`);
    } else {
    }
  };

  const handleUnassigned = async () => {
    // await docInUnassignedApi(Number(docInDetail?.id))
    //   .then((res) => {
    //     if (res) {
    //       toast.success(DOC_IN_MSG.unAssignedSuccess);
    //       setOpenModalUnassigned(false);
    //       navigate("/doc-in");
    //     }
    //   })
    //   .catch((err) => {
    //     const msg = getErrorMsgAxios(err);
    //     toast.error(msg);
    //     throw Error(err);
    //   });
  };

  const handleCloseModalUnassigned = () => {
    setOpenModalUnassigned(false);
  };

  const handleOpenModalCancel = () => {
    setOpenModalCancel(true);
  };

  const handleCloseModalCancel = () => {
    setOpenModalCancel(false);
  };

  const handleOpenModalReply = () => {
    setOpenModalReply(true);
  };

  const handleCloseModalReply = () => {
    setOpenModalReply(false);
  };

  const handleOpenModalProcession = () => {
    setOpenModalProcession(true);
  };

  const handleCloseModalProcession = () => {
    setOpenModalProcession(false);
  };

  const renderBtnReply = (): React.ReactNode => {
    return (
      // isEnableBtnReply(docInDetail?.status as STATUS_DOC_IN_ENUM, currentUser, docInDetail?.assignee)
      docInDetail?.enableReplyBtn && (
        <Button type="button" variant="contained" color="cancel" onClick={handleOpenModalReply}>
          Phúc đáp
        </Button>
      )
    );
  };

  const renderBtnCancel = (): React.ReactNode => {
    return (
      // isEnableBtnCancel(
      //   docInDetail?.status as STATUS_DOC_IN_ENUM,
      //   currentUser,
      //   docInDetail?.createdBy,
      //   docInDetail?.docCategory
      // )
      docInDetail?.enableCancelBtn && (
        <Button variant="contained" onClick={handleOpenModalCancel} color="error">
          Huỷ
        </Button>
      )
    );
  };

  const renderBtnReturn = (): React.ReactNode => {
    return (
      // isEnableBtnReturn(
      //   docInDetail?.status as STATUS_DOC_IN_ENUM,
      //   currentUser,
      //   docInDetail?.assignee,
      //   docInDetail?.handlingDepartment,
      //   docInDetail?.docCategory
      // )
      docInDetail?.enableReturnBtn && (
        <Button variant="contained" onClick={handleOpenModalRefuse} color="error">
          Hoàn trả
        </Button>
      )
    );
  };

  const renderBtnApprove = (): React.ReactNode => {
    return (
      // isEnableBtnApprove(docInDetail?.status, currentUser, docInDetail?.docCategory) &&
      docInDetail?.enableApproveBtn && (
        <Button type="submit" variant="contained" onClick={handleOpenUnassigned} startIcon={<BrowserIcon />}>
          Trình duyệt
        </Button>
      )
    );
  };

  const renderBtnAssign = (): React.ReactNode => {
    return (
      // isEnableBtnAssign(docInDetail?.status, currentUser, docInDetail?.handlingDepartment) &&
      docInDetail?.enableAssignBtn && (
        <Button type="submit" variant="contained" onClick={handleOpenModalAssignment} startIcon={<BrowserIcon />}>
          Phân công
        </Button>
      )
    );
  };

  const renderBtnHandle = (): React.ReactNode => {
    return (
      // isEnableBtnHandle(docInDetail?.status, currentUser, docInDetail?.assignee) &&
      docInDetail?.enableHandleBtn && (
        <Button type="submit" variant="contained" onClick={handleOpenModalProcession} startIcon={<BrowserIcon />}>
          Xử lý
        </Button>
      )
    );
  };

  const renderBtnUpdate = (): React.ReactNode => {
    return (
      // isEnableBtnUpdate(docInDetail?.status, currentUser, docInDetail?.createdBy) &&
      docInDetail?.enableUpdateBtn && (
        <Button type="submit" variant="contained" onClick={handleUpdate} startIcon={<BrowserIcon />}>
          Cập nhật
        </Button>
      )
    );
  };

  return (
    <>
      <ModalReply open={openModalReply} onClose={handleCloseModalReply} id={Number(params?.id)} />
      <ModalRefuse open={openModalRefuse} onClose={handleCloseModalRefuse} id={Number(params?.id)} />
      {docInDetail?.handlingDepartments && (
        <ModalAssignment
          open={openModalAssignment}
          onClose={handleCloseModalAssignment}
          departmentCode={convertArrToString(arrCodeHandlingDepartment)}
          id={Number(params?.id)}
          countAssignClose={countAssignClose}
        />
      )}
      <ModalUnassigned
        departmentCode={convertArrToString(arrCodeHandlingDepartment)}
        open={openModalUnassigned}
        id={Number(params?.id)}
        onClose={handleCloseModalUnassigned}
        handleOnSubmitModal={handleUnassigned}
      />
      <ModalCancel id={Number(params?.id)} open={openModalCancel} onClose={handleCloseModalCancel} />
      <ModalProcession id={Number(params?.id)} open={openModalProcession} onClose={handleCloseModalProcession} />

      <Box>
        {/* action header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            type="button"
            startIcon={<ArrowBackIcon />}
            onClick={handleClickButtonBack}
          >
            Quay lại
          </Button>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={spacing_size} sx={{ justifyContent: "end" }}>
            <Button variant="outlined" onClick={handleClickBtnHistory}>
              Lịch sử
            </Button>
            {renderBtnReply()}
            {renderBtnCancel()}
            {renderBtnReturn()}
            {renderBtnApprove()}
            {renderBtnUpdate()}
            {renderBtnHandle()}
            {renderBtnAssign()}
          </Stack>
        </Box>

        {/* view detail */}
        <Box sx={{ margin: "1rem 0" }}>{docInDetail && <FormDetailDocIn docInDetail={docInDetail} />}</Box>

        {/* action footer */}
        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={spacing_size} sx={{ justifyContent: "end" }}>
            {renderBtnReply()}
            {renderBtnCancel()}
            {renderBtnReturn()}
            {renderBtnApprove()}
            {renderBtnUpdate()}
            {renderBtnHandle()}
            {renderBtnAssign()}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default DetailDocIn;
