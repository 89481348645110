import { Box, Pagination, Typography } from "@mui/material";
import React, { FC } from "react";
import { SIZE_DEFAULT } from "../../constants/page.constant";

interface PaginationProps {
  page: number;
  total: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  sizeDefault?: number;
}

const PaginationPage: FC<PaginationProps> = (props) => {
  const { page, total, onChange, sizeDefault = SIZE_DEFAULT } = props;

  const count = total % sizeDefault === 0 ? total / sizeDefault : Math.floor(total / sizeDefault) + 1;

  const totalItemView = (page: number, total: number) => {
    const a = (page + 1) * sizeDefault;
    if (a < total) {
      return a;
    }
    return total;
  };

  return (
    <Box
      sx={{
        marginTop: "1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography>
        Số lượng {totalItemView(page, total)}/{total} bản ghi
      </Typography>
      <Pagination
        count={count}
        defaultPage={1}
        page={page + 1}
        siblingCount={1}
        shape="rounded"
        onChange={onChange}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};

export default PaginationPage;
