import { SidebarModel } from "../model/sidebar.model";

export const SidebarUtil = (
  data: SidebarModel[],
  currentRoles: string[] | null,
  currentAccessRights: string[] | null,
  currentPositionCode: string | null,
  isPicDocOut: boolean
): SidebarModel[] => {
  return data
    .filter((item) => {
      if (item?.link === "/doc-out" && isPicDocOut === true) {
        return true;
      } else if (item?.roles && currentRoles !== null && currentRoles?.length > 0) {
        let checkRoles = item?.roles?.some((i) => currentRoles?.includes(i));
        let checkPosition = item?.position?.some((i) => currentPositionCode === i);
        return checkRoles || checkPosition;
      } else if (item?.position != null && currentPositionCode) {
        return item?.position?.some((i) => currentPositionCode === i);
      }
      return true;
    })
    .map((item) => {
      if (item.children) {
        item.children = SidebarUtil(item.children, currentRoles, currentAccessRights, currentPositionCode, isPicDocOut);
      }
      return item;
    });
};
