import { DocInModel, HistoryDocInModel } from "./../../../../model/doc-in.model";
import { filterDocInApi, getDocInByIdApi, getHistoryDocInByIdApi } from "./../../../../api/doc-in.api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RequestFilterDocInModel, ResponseFilterDocInModel } from "../../../../model/doc-in.model";
import { Pageable } from "../../../../model/page.model";
import { TOTAL_HEADER } from "../../../../constants/page.constant";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { toast } from "react-toastify";

interface DocInReducerState {
  pageDocIn: number;
  docInDetail: ResponseFilterDocInModel[] | null;
  totalDocInDetail: number;
  reloadFilterDocIn: number;
  docInDetailById: DocInModel[] | null;
  docInHistoryDetailById: HistoryDocInModel[] | null;
  pageDocInHistory: number;
  totalDocInHistory: number;
}

const initialState: DocInReducerState = {
  pageDocIn: 0,
  docInDetail: null,
  totalDocInDetail: 0,
  reloadFilterDocIn: 0,
  docInDetailById: null,
  docInHistoryDetailById: null,
  pageDocInHistory: 0,
  totalDocInHistory: 0,
};

export const filterDocInApiAsync = createAsyncThunk(
  "docIn/filter",
  async (data: { body: RequestFilterDocInModel | null; pageable: Pageable }) => {
    try {
      const res = await filterDocInApi(data.body, data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      const msg = getErrorMsgAxios(error);
      toast.error(msg);
      throw Error(error);
    }
  }
);

export const getDocInApiAsync = createAsyncThunk("docIn/getDocIn", async (id: number) => {
  try {
    const res = await getDocInByIdApi(id);
    return res.data;
  } catch (error: any) {
    const msg = getErrorMsgAxios(error);
    toast.error(msg);
    throw Error(error);
  }
});

export const getHistoryDocInByIdAsync = createAsyncThunk(
  "docIn/getHistoryDocInbyId",
  async (data: { pageable: Pageable }) => {
    try {
      const res = await getHistoryDocInByIdApi(data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      const msg = getErrorMsgAxios(error);
      toast.error(msg);
      throw Error(error);
    }
  }
);

const DocInReducer = createSlice({
  name: "DocInReducer",
  initialState,
  reducers: {
    setPageDocIn(state, action) {
      return {
        ...state,
        pageDocIn: action.payload,
      };
    },
    setReloadFilterDocIn(state, action) {
      return {
        ...state,
        reloadFilterDocIn: action.payload,
      };
    },
    setPageDocInHistory(state, action) {
      return {
        ...state,
        pageDocInHistory: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        filterDocInApiAsync.fulfilled,
        (
          state,
          action: {
            payload: { data: ResponseFilterDocInModel[] | null; total: number };
          }
        ) => {
          // action is inferred correctly here if using TS
          return {
            ...state,
            docInDetail: action.payload.data,
            totalDocInDetail: action.payload.total,
          };
        }
      )
      .addCase(
        getHistoryDocInByIdAsync.fulfilled,
        (
          state,
          action: {
            payload: { data: HistoryDocInModel[] | null; total: number };
          }
        ) => {
          return {
            ...state,
            docInHistoryDetailById: action.payload.data,
            totalDocInHistory: action.payload.total,
          };
        }
      );
    // You can chain calls, or have separate `builder.addCase()` lines each time

    // .addMatcher(
    //   isRejectedAction,
    //   // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
    //   (state, action) => {}
    // )
    // and provide a default case if no other handlers matched
    // .addDefaultCase((state, action) => {});
  },
});

export const { setPageDocIn, setReloadFilterDocIn, setPageDocInHistory } = DocInReducer.actions;
export default DocInReducer.reducer;
