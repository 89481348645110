import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import moment from "moment";
import BoxContainer from "../../../components/common/BoxContainer";
import NotFoundFilterView from "../../../components/common/NotFoundFilterView";
import PaginationPage from "../../../components/common/Pagination";
import FieldContainer from "../../../components/form/FieldContainer";
import XCircleIcon from "../../../components/icons/XCircleIcon";
import { UPLOAD_DATE_FORMAT, VIEW_DATE_FORMAT, VIEW_YEAR_FORMAT } from "../../../constants/date-time.constant";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { RelativesPersonnels } from "../../../enum/personnel.enum";
import { PersonnelModel, RelativesModel, SelectFieldCommonPersonnelModel } from "../../../model/personnel.model";
import { header_table_color } from "../../../themes/color";
import { spacing_size } from "../../../themes/size";
import { relationshipPersonnel } from "../common/util/personnel.util";
import ModalDelete from "../modal/ModalDelete";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import ModalUpdateRelatives from "../modal/ModalUpdateRelatives";
import { formatDateTime } from "../../../utils/data-time.util";
import { PHONE_PATTERN } from "../../../constants/regex.constant";

interface RelativesProps {
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  valueUploads: PersonnelModel | null;
  valuesRelatives: RelativesModel[] | null | undefined | any;
  setValuesRelatives: any;
}

const Relatives: FC<RelativesProps> = (props) => {
  const { valueUploads, setValuesUploads, valuesRelatives, setValuesRelatives } = props;
  const currentDate = new Date();
  const initialValues: RelativesModel = {
    fullName: "",
    relationship: "",
    birthYear: null,
    occupation: "",
    workPlace: "",
    phoneNumber: "",
  };

  const arrRelatives: any = valueUploads?.employeeRelatives;
  const [relativesDetail, setRelativesDetail] = useState<RelativesModel[]>(arrRelatives);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [indexRelatives, setIndexRealtives] = useState<number>(0);
  const [itemRelatives, setItemRelatives] = useState<RelativesModel | null>(null);
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);

  const validationSchema = Yup.object({
    fullName: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Họ và tên người thân")),
    relationship: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Mối quan hệ")),
    birthYear: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Năm sinh")),
    // occupation: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Nghề nghiệp")),
    // workPlace: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Nơi công tác")),
  });

  const handleOpen = async (index: number, e: React.ChangeEvent<unknown>) => {
    setOpenModalDelete(true);
    setIndexRealtives(index);
  };

  const handleCloseModal = () => {
    setOpenModalDelete(false);
  };

  const handleOpenModalUpdate = (item: RelativesModel, index: number) => {
    setOpenModalUpdate(true);
    setItemRelatives(item);
    setIndexRealtives(index);
  };

  const handleCloseModalUpdate = () => {
    setOpenModalUpdate(false);
  };

  const handleChangeRelationship =
    (formik: FormikHelpers<RelativesModel>) => (e: SelectFieldCommonPersonnelModel | null) => {
      formik.setFieldValue("relationship", e);
    };

  const handleOnSubmit = (values: RelativesModel, formik: FormikHelpers<RelativesModel>) => {
    const newValues = {
      ...values,
      relationship: values?.relationship ? values?.relationship?.code : "",
      birthYear: formatDateTime(values?.birthYear, VIEW_YEAR_FORMAT),
    };
    // setValuesRelatives([...valuesRelatives, newValues]);
    setRelativesDetail([...relativesDetail, newValues]);
    setValuesUploads({ ...valueUploads, employeeRelatives: [...relativesDetail, newValues] });
    formik.resetForm();
  };

  const handleDeleteItem = () => {
    const item: any = valueUploads?.employeeRelatives?.filter(
      (item: RelativesModel, i: number) => i !== indexRelatives
    );
    setRelativesDetail(item);
    setValuesUploads({ ...valueUploads, employeeRelatives: [...item] });
    // setValuesRelatives(item);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    if (!PHONE_PATTERN.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <>
      {openModalUpdate && (
        <ModalUpdateRelatives
          open={openModalUpdate}
          onClose={handleCloseModalUpdate}
          setValuesUploads={setValuesUploads}
          indexRelatives={indexRelatives}
          setRelativesDetail={setRelativesDetail}
          valueUploads={valueUploads}
          itemRelatives={itemRelatives}
        />
      )}
      {openModalDelete && (
        <ModalDelete open={openModalDelete} onClose={handleCloseModal} handleDeleteItem={handleDeleteItem} />
      )}
      <Box>
        <BoxContainer>
          <Typography sx={{ fontSize: "1.2rem", fontWeight: "500", marginBottom: "0.6rem" }}>
            Thêm mới người thân
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={true}
            // enableReinitialize={true}
          >
            {(formik) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="fullName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.fullName}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Họ và tên người thân"
                      notched={true}
                      variant={"input"}
                      id={"fullName"}
                      isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="relationship"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeRelationship(formik)}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.relationship}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Mối quan hệ"
                      notched={true}
                      variant={"react-select"}
                      id={"relationship"}
                      isRequired={true}
                      options={RelativesPersonnels}
                      getOptionLabel={(option: SelectFieldCommonPersonnelModel) => option.description}
                      getOptionValue={(option: SelectFieldCommonPersonnelModel) => option.code}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      name="birthYear"
                      onBlur={formik.handleBlur}
                      value={formik.values.birthYear}
                      placeholderText={"YYYY"}
                      label="Năm sinh"
                      notched={true}
                      variant={"date"}
                      id={"birthYear"}
                      isRequired={true}
                      maxDate={currentDate}
                      dateFormat="yyyy"
                      showYearPicker
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="phoneNumber"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.phoneNumber}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Số điện thoại"
                      notched={true}
                      variant={"input"}
                      id={"phoneNumber"}
                      // isRequired={true}
                      inputProps={{ maxLength: 20 }}
                      onKeyPress={handleKeyPress}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="occupation"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.occupation}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Nghề nghiệp"
                      notched={true}
                      variant={"input"}
                      id={"occupation"}
                      // isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FieldContainer
                      fullWidth
                      name="workPlace"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.workPlace}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Nơi công tác"
                      notched={true}
                      variant={"input"}
                      id={"workPlace"}
                      // isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={spacing_size}
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      <Button startIcon={<BrowserIcon />} variant="contained" color="primary" type="submit">
                        Lưu thông tin người thân
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </BoxContainer>
      </Box>

      {valueUploads?.employeeRelatives && valueUploads?.employeeRelatives?.length > 0 && (
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: header_table_color }}>
                  <TableCell sx={{ fontWeight: 600 }}>Họ và tên người thân</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Mối quan hệ</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Năm sinh</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Số điện thoại</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Nghề nghiệp</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Nơi công tác</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    Hành động
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {valueUploads?.employeeRelatives?.map((item: RelativesModel, index: number) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                    }}
                    key={index}
                  >
                    <TableCell>{item?.fullName}</TableCell>
                    <TableCell>{relationshipPersonnel(item?.relationship)}</TableCell>
                    <TableCell>{item?.birthYear}</TableCell>
                    <TableCell>{item?.phoneNumber}</TableCell>
                    <TableCell>{item?.occupation}</TableCell>
                    <TableCell>{item?.workPlace}</TableCell>
                    <TableCell align="center">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={spacing_size}
                        sx={{
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          type="button"
                          onClick={(e) => handleOpen(index, e)}
                        >
                          Xoá
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          type="button"
                          onClick={() => handleOpenModalUpdate(item, index)}
                        >
                          Cập nhật
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <PaginationPage total={10} page={1} onChange={handleChangePage} /> */}
        </Box>
      )}
    </>
  );
};

export default Relatives;
