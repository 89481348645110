import HTTP_TASK_SERVICE from "../../config/axios/axios-task.config";
import { HEADER_FORM_DATA } from "../../constants/axios.constant";

export const createCommentTaskApi = (data: FormData) => {
  return HTTP_TASK_SERVICE.post("/comments", data, {
    headers: HEADER_FORM_DATA,
  });
};

export const downloadFileCommentTaskApi = (taskId: number, id: number) => {
  return HTTP_TASK_SERVICE.get("/comments/attachment" + taskId + "/" + id, { responseType: "blob" });
};
