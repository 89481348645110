import { Button, Grid, Stack } from "@mui/material";
import FileSaver from "file-saver";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getDocBookApi } from "../../../../api/doc-book.api";
import { exportDocOutApi } from "../../../../api/doc-out.api";
import BoxContainer from "../../../../components/common/BoxContainer";
import FieldContainer from "../../../../components/form/FieldContainer";
import SearchIcon from "../../../../components/icons/SearchIcon";
import { DOC_OUT_MSG, FORM_MSG } from "../../../../constants/msg/msg.constant";
import { MAX_SIZE_EXPORT, SIZE_DEFAULT } from "../../../../constants/page.constant";
import { ALL_ENUM } from "../../../../enum/common.enum";
import { DOC_BOOK_ENUM } from "../../../../enum/doc-book.enum";
import { CategoryDocOutOptions } from "../../../../enum/doc-out.enum";
import { CodeDocBookModel, DocBook } from "../../../../model/doc-book.model";
import { FilterDocOutModel } from "../../../../model/doc-out.model";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { spacing_size } from "../../../../themes/size";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { filterDocOutApiAsync, setPageDocOut } from "../redux/doc-out.reducer";

interface FormFilterDocOutProps {
  setBodyFilter: any;
  bodyFilter: any;
  initValue: any;
  setDocBook: any;
}

interface InitialValuesForm {
  docCategory?: string | null | undefined;
  summary?: string | null | undefined;
  docBook?: string | null | undefined | DocBook | any;
  createdBy?: string | null | undefined;
  arrivalNbr?: string | number | null | undefined;
  fromPlace?: string | null | undefined;
  codeDocIn?: string | null | undefined;
}

const FormFilterDocOut: FC<FormFilterDocOutProps> = (props) => {
  const location = useLocation();
  const { setBodyFilter, bodyFilter, initValue, setDocBook } = props;
  const initBodyFilter: FilterDocOutModel = {
    docCategory: null,
    summary: null,
    docBook: null,
    createdBy: null,
    arrivalNbr: null,
    fromPlace: null,
    codeDocIn: null,
  };

  const currentDate = new Date();
  const dispatch = useAppDispatch();
  const reloadFilterDocOut = useAppSelector((state) => state.docOut.reloadFilterDocOut);
  const page = useAppSelector((state) => state.docOut.pageDocOut);
  const [docBookDetail, setDocBookDetail] = useState<DocBook[]>([]);
  const [isInitFilter, setIsInitFilter] = useState(true);

  const validationSchema = Yup.object({});

  const loadDataFilter = (body = bodyFilter) => {
    const pageable = { page, size: SIZE_DEFAULT };
    dispatch(filterDocOutApiAsync({ body: validateBodyFilterApi(body), pageable }));
  };

  const validateBodyFilterApi = (body: FilterDocOutModel) => {
    const { ...res } = body;
    return {
      ...res,
    };
  };

  const getDocBook = async () => {
    const params: CodeDocBookModel = {
      code: DOC_BOOK_ENUM.DOC_OUT,
    };

    await getDocBookApi(params).then((res) => {
      setDocBookDetail(res.data);
    });
  };

  useEffect(() => {
    getDocBook();
  }, []);

  useEffect(() => {
    if (reloadFilterDocOut !== 0) {
      loadDataFilter();
    }
  }, [reloadFilterDocOut]);

  useEffect(() => {
    if (isInitFilter) {
      setIsInitFilter(false);
      if (page > 0) {
        dispatch(setPageDocOut(0));
      } else {
        const stateLocation = location?.state;
        const body = {
          docCategory: stateLocation?.bodyFilter?.docCategory || null,
          summary: stateLocation?.bodyFilter?.summary || null,
          docBook: stateLocation?.bodyFilter?.docBook || null,
          createdBy: stateLocation?.bodyFilter?.createdBy || null,
          arrivalNbr: stateLocation?.bodyFilter?.arrivalNbr || null,
          fromPlace: stateLocation?.bodyFilter?.fromPlace || null,
          codeDocIn: stateLocation?.bodyFilter?.codeDocIn || null,
        };
        loadDataFilter(body);
      }
    } else {
      loadDataFilter();
    }
  }, [page]);

  const buildBodyFilter = (value: InitialValuesForm): FilterDocOutModel => {
    setDocBook(value?.docBook);
    const newValues = {
      ...value,
      docBook: value?.docBook?.id || null,
      docCategory: ALL_ENUM === value?.docCategory ? null : value?.docCategory,
      summary: value?.summary || null,
      createdBy: value?.createdBy || null,
      fromPlace: value?.fromPlace || null,
      docIn: value?.codeDocIn || null,
      arrivalNbr: value?.arrivalNbr || null,
    };

    return newValues;
  };

  const handleOnSubmit = (value: InitialValuesForm, formikHelper: FormikHelpers<InitialValuesForm>) => {
    const body = buildBodyFilter(value);
    setBodyFilter(body);
    if (page === 0) {
      const pageable = {
        page,
        size: SIZE_DEFAULT,
      };
      dispatch(filterDocOutApiAsync({ body, pageable }));
    } else {
      dispatch(setPageDocOut(0));
    }
  };

  const handleCancel = (formik: FormikProps<InitialValuesForm>) => {
    formik.resetForm();
    formik.setFieldValue("docCategory", "ALL");
    formik.setFieldValue("docBook", null);
    setBodyFilter(initBodyFilter);
    if (page === 0) {
      loadDataFilter(initBodyFilter);
    } else {
      dispatch(setPageDocOut(0));
    }
  };

  const handleExport = async (formik: FormikProps<InitialValuesForm>) => {
    const body = buildBodyFilter(formik.values);
    const pageable = {
      page,
      size: MAX_SIZE_EXPORT,
    };
    try {
      const res = await exportDocOutApi(body, pageable);
      FileSaver.saveAs(res?.data, `danh-sach-cong-van-di-${currentDate.getDate()}.xlsx`);
      toast.success(DOC_OUT_MSG.exportListSuccess);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const handleChangeDocOutDocBook = (formik: FormikHelpers<InitialValuesForm>) => (e: DocBook | null) => {
    formik.setFieldValue("docBook", e);
  };

  return (
    <BoxContainer>
      <Formik
        initialValues={initValue}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <Form>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    name="docCategory"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.docCategory}
                    placeholder=""
                    maxRows={12}
                    label="Loại công văn đi"
                    notched={true}
                    variant={"select"}
                    id={"docCategory"}
                    labelOption="description"
                    valueOption="code"
                    options={CategoryDocOutOptions}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    name="summary"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.summary}
                    placeholder="Nhập thông tin"
                    maxRows={12}
                    label="Trích yếu"
                    notched={true}
                    variant={"input"}
                    id={"summary"}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    name="docBook"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeDocOutDocBook(formik)}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.docBook}
                    placeholder="Nhập thông tin"
                    maxRows={12}
                    label="Sổ công văn"
                    notched={true}
                    variant={"react-select"}
                    id={"docBook"}
                    getOptionLabel={(option: DocBook) => `${option.name}`}
                    getOptionValue={(option: DocBook) => option.id}
                    options={docBookDetail}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    variant="input"
                    name="arrivalNbr"
                    type="text"
                    placeholder={FORM_MSG.placeholder}
                    label="Số đi"
                    inputProps={{ maxLength: 50 }}
                    id="arrivalNbr"
                    value={formik.values.arrivalNbr}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    name="fromPlace"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.fromPlace}
                    placeholder="Nhập thông tin"
                    maxRows={12}
                    label="Nơi nhận thông tin"
                    notched={true}
                    variant={"input"}
                    id={"fromPlace"}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    name="codeDocIn"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.codeDocIn}
                    placeholder="Nhập thông tin"
                    maxRows={12}
                    label="Số công văn đến"
                    notched={true}
                    variant={"input"}
                    id={"codeDocIn"}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    name="createdBy"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    // inputProps={{ maxLength: 255 }}
                    value={formik.values.createdBy}
                    placeholder="Nhập thông tin"
                    maxRows={12}
                    label="Người tạo"
                    notched={true}
                    variant={"input"}
                    id={"createdBy"}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button variant="contained" color="cancel" type="button" onMouseDown={() => handleCancel(formik)}>
                      Huỷ
                    </Button>
                    <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                      Tìm kiếm
                    </Button>
                    <Button variant="outlined" color="primary" type="button" onMouseDown={() => handleExport(formik)}>
                      Xuất Excel
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </BoxContainer>
  );
};

export default FormFilterDocOut;
