import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../store/hook";

interface AuthorizationRouterProps {
  roles?: string[];
  children?: React.ReactNode;
}

const AuthorizationRouter: FC<AuthorizationRouterProps> = (props) => {
  const { roles } = props;
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  if (currentUser) {
    for (const accessRight of currentUser.accessRights) {
      if (roles?.includes(accessRight.code)) {
        return <>{props.children}</>;
      }
    }
    return <Navigate to={"/404"} replace />;
  } else {
    return <Navigate to={"/login"} replace />;
  }
};

export default AuthorizationRouter;
