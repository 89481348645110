import { createSlice } from "@reduxjs/toolkit";

interface CommonState {
  sidebarWidth: number; // 57 min 280 max
  isCollapseSidebar: boolean;
  spinnerLoading: boolean;
  fetchCount: number;
}

const initialState: CommonState = {
  sidebarWidth: 280,
  isCollapseSidebar: false,
  spinnerLoading: false,
  fetchCount: 0,
};

const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    toggleCollapseSidebar(state, actions) {
      if (actions.payload) {
        return {
          ...state,
          isCollapseSidebar: actions.payload,
        };
      } else {
        return {
          ...state,
          isCollapseSidebar: actions.payload,
        };
      }
    },
    toggleWidthSidebar(state, actions) {
      return {
        ...state,
        sidebarWidth: actions.payload,
      };
    },
    showSpinner(state, action) {
      return {
        ...state,
        spinnerLoading: action.payload,
      };
    },
    increaseFetch(state, action) {
      return {
        ...state,
        fetchCount: state.fetchCount + 1,
      };
    },
    decreaseFetch(state, action) {
      const fetchCount = state.fetchCount - 1 < 0 ? 0 : state.fetchCount - 1;
      return {
        ...state,
        fetchCount,
      };
    },
  },
});
export const { toggleCollapseSidebar, toggleWidthSidebar, showSpinner, increaseFetch, decreaseFetch } =
  CommonSlice.actions;
export default CommonSlice.reducer;
