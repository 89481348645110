import { ContractAddendumModel, ContractModel } from "./../../../../model/personnel.model";
import { toast } from "react-toastify";
import { createHrApi, downloadFileHrByTypeApi, getDirectManagement, updateHrApi } from "../../../../api/hr.api";
import { ALLOW_EXTENSION_FILE, MAX_SIZE_FILE_MB } from "../../../../constants/file.constant";
import {
  CONTACT_TYPE_ENUM,
  FormPayHr,
  IDENTITY_DOCUMENTS_ENUM,
  INFORMATION_CHANGE_ENUM,
  OTHER_DOCUMENTS_ENUM,
  ObjectTabSelectPersonnel,
  POSITION_ENUM,
  RelativesPersonnels,
  SelectPersonnelOptions,
  TAB_SELECT_PERSONNEL_ENUM,
  TYPE_PERSONNEL_ENUM,
  GenderPersonnels,
  MaritalStatusPersonnels,
  ContactTypePersonnels,
} from "../../../../enum/personnel.enum";
import { EmployeeDetailModel, GeneralInformationModel } from "../../../../model/personnel.model";
import {
  convertFilesToArr,
  getFileExtension,
  isAllowExtensionFile,
  isDuplicateFile,
  isDuplicateFileUploaded,
  isMaxSizeFile,
  isMinSizeFile,
  previewAndDowndloadFile,
  previewOrDowndloadFile,
} from "../../../../utils/file.util";
import { HR_MSG } from "../../../../constants/msg/msg.constant";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { Location, NavigateFunction, useNavigate } from "react-router-dom";
import { debounce } from "@mui/material";
import { WAIT_TIME_DEBOUNCE } from "../../../../constants/date-time.constant";
import { TYPE_FILE_ENUM } from "../../../../enum/hr.enum";
import FileSaver from "file-saver";
import { isEmptyArr } from "../../../../utils/array.util";
import moment from "moment";
import {
  validationExpertsAndCoachingStaffSchema,
  validationLeadersAndDepartmentsSchema,
  validationOperatingPartsSchema,
  validationTheSubordinateUnitsSchema,
  validationOtherSchema,
} from "../constants/validate-form.constants";

export const isEnableFieldPartyMember = (selectPosition: POSITION_ENUM[]) => {
  if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
    return true;
  } else {
    return false;
  }
};

export const isEnableFieldUnionMember = (selectPosition: POSITION_ENUM[]) => {
  if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
    return true;
  } else {
    return false;
  }
};

export const isEnableFieldIdentityCard = (selectIdentity: IDENTITY_DOCUMENTS_ENUM[]) => {
  if (selectIdentity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
    return true;
  } else {
    return false;
  }
};

export const isEnableFieldPassport = (selectIdentity: IDENTITY_DOCUMENTS_ENUM[]) => {
  if (selectIdentity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
    return true;
  } else {
    return false;
  }
};

export const isEnableFieldWorkPermit = (selectDocumentOther: OTHER_DOCUMENTS_ENUM[]) => {
  if (selectDocumentOther?.includes(OTHER_DOCUMENTS_ENUM.WORK_PREMIT)) {
    return true;
  } else {
    return false;
  }
};

export const isEnableFieldTemporary = (selectDocumentOther: OTHER_DOCUMENTS_ENUM[]) => {
  if (selectDocumentOther?.includes(OTHER_DOCUMENTS_ENUM.TEMPORARY)) {
    return true;
  } else {
    return false;
  }
};

// change infomation contract addendum
export const isEnableFieldInformationChangeSalary = (selectField: INFORMATION_CHANGE_ENUM[]) => {
  if (selectField?.includes(INFORMATION_CHANGE_ENUM.SALARY_CONTRACT)) {
    return true;
  } else {
    return false;
  }
};

export const isEnableFieldAllowances = (selectField: INFORMATION_CHANGE_ENUM[]) => {
  if (selectField?.includes(INFORMATION_CHANGE_ENUM.ALLOWANCES)) {
    return true;
  } else {
    return false;
  }
};

export const isEnableMonthlyBonus = (selectField: INFORMATION_CHANGE_ENUM[]) => {
  if (selectField?.includes(INFORMATION_CHANGE_ENUM.MONTHLY_BONUS)) {
    return true;
  } else {
    return false;
  }
};

export const changeFileUploadPersonnel = (e: any, files: File[], fileUploaded: any, size: number) => {
  const dataFiles: File[] = [];
  if (e.target.files) {
    const currentFiles = convertFilesToArr(e.target.files);
    if (files.length > 0) {
      dataFiles.push(...files);
    }
    for (let i = 0; i < currentFiles.length; i++) {
      if (dataFiles.length + (fileUploaded.length || 0) === size) {
        break;
      }
      const file = currentFiles[i];
      if (
        isAllowExtensionFile(file, ALLOW_EXTENSION_FILE) &&
        !isMinSizeFile(file, 0) &&
        !isMaxSizeFile(file, MAX_SIZE_FILE_MB) &&
        !isDuplicateFile(dataFiles, file) &&
        !isDuplicateFileUploaded(fileUploaded, file)
      ) {
        dataFiles.push(file);
      }
    }
    e.target.value = null;
    return dataFiles;
  } else {
    return dataFiles;
  }
};

export const checkIdentity = (values: GeneralInformationModel | null) => {
  const arr: IDENTITY_DOCUMENTS_ENUM[] = [];
  if (values?.identityCard !== "" && values?.passport !== "" && values !== null) {
    arr.push(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD, IDENTITY_DOCUMENTS_ENUM.PASSPORT);
  } else if (values?.passport) {
    arr.push(IDENTITY_DOCUMENTS_ENUM.PASSPORT);
  } else if (values?.identityCard) {
    arr.push(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD);
  }
  return arr;
};

export const checkPosition = (values: GeneralInformationModel | null) => {
  const arr: POSITION_ENUM[] = [];
  if (values?.partyPosition !== "" && values?.unionPosition !== "" && values !== null) {
    arr.push(POSITION_ENUM.PARTY_MEMBER, POSITION_ENUM.UNION_MEMBER);
  } else if (values?.partyPosition) {
    arr.push(POSITION_ENUM.PARTY_MEMBER);
  } else if (values?.unionPosition) {
    arr.push(POSITION_ENUM.UNION_MEMBER);
  }
  return arr;
};

export const checkDocOther = (values: GeneralInformationModel | null) => {
  const arr: OTHER_DOCUMENTS_ENUM[] = [];
  if (values?.workPermitNumber !== "" && values?.temporaryResidenceCard !== "" && values !== null) {
    arr.push(OTHER_DOCUMENTS_ENUM.TEMPORARY, OTHER_DOCUMENTS_ENUM.WORK_PREMIT);
  } else if (values?.temporaryResidenceCard) {
    arr.push(OTHER_DOCUMENTS_ENUM.TEMPORARY);
  } else if (values?.workPermitNumber) {
    arr.push(OTHER_DOCUMENTS_ENUM.WORK_PREMIT);
  }
  return arr;
};

export const relationshipPersonnel = (relationship: string | undefined | null) => {
  for (const relativesPersonnel of RelativesPersonnels) {
    if (relativesPersonnel.code === relationship) {
      return relativesPersonnel.description;
    }
  }

  return null;
};

export const convertFormPayHr = (formPay: string | undefined | null) => {
  for (const formPayHr of FormPayHr) {
    if (formPayHr.code === formPay) {
      return formPayHr.description;
    }
  }
  return null;
};

export const convertType = (type: string | undefined | null) => {
  for (const typeHr of SelectPersonnelOptions) {
    if (typeHr.code === type) {
      return typeHr.description;
    }
  }
  return null;
};

export const convertTypeContract = (type: string | undefined | null) => {
  for (const typeContract of ContactTypePersonnels) {
    if (typeContract.code === type) {
      return typeContract.description;
    }
  }
  return null;
};

export const convertGender = (gender: string | undefined | null) => {
  for (const genderHr of GenderPersonnels) {
    if (genderHr.code === gender) {
      return genderHr.description;
    }
  }
  return null;
};

export const convertMaritalStatus = (maritalStatus: string | undefined | null) => {
  for (const maritalStatusHr of MaritalStatusPersonnels) {
    if (maritalStatusHr.code === maritalStatus) {
      return maritalStatusHr.description;
    }
  }
  return null;
};

export const isOther = (type: string) => {
  return TYPE_PERSONNEL_ENUM.OTHER === type;
};

export const isLeadersAndDepartments = (type: string) => {
  return TYPE_PERSONNEL_ENUM.LEADERS_AND_DEPARTMENTS === type;
};

export const isOperatingParts = (type: string) => {
  return TYPE_PERSONNEL_ENUM.OPERATING_PARTS === type;
};

export const isExpertsAndCoachingStaff = (type: string) => {
  return TYPE_PERSONNEL_ENUM.EXPERTS_AND_COACHING_STAFF === type;
};

export const isTheSubordinateUnits = (type: string) => {
  return TYPE_PERSONNEL_ENUM.THE_SUBORDINATE_UNITS === type;
};

export const appendFileFormData = (formData: FormData, files: any[], name: string) => {
  for (let index = 0; index < files?.length; index++) {
    const element = files[index];
    formData.append(name, element);
  }
};

export const buildBodyFormCreatePersonnel = (
  body: any,
  fileContractsUpload: any[],
  fileEducationsUpload: any[],
  fileContractsAddendumUpload: any[],
  fileDocumentsUpload: any[]
) => {
  const bodyFormData = new FormData();
  bodyFormData.append(
    "data",
    new Blob([JSON.stringify(body)], {
      type: "application/json",
    })
  );
  appendFileFormData(bodyFormData, fileContractsUpload, "fileContracts");
  appendFileFormData(bodyFormData, fileEducationsUpload, "fileEducations");
  appendFileFormData(bodyFormData, fileContractsAddendumUpload, "fileContractAddendum");
  appendFileFormData(bodyFormData, fileDocumentsUpload, "fileDocument");
  return bodyFormData;
};

export const buildBodyFormUpdatePersonnel = (
  body: any,
  fileContractsUpload: any[],
  fileEducationsUpload: any[],
  fileContractsAddendumUpload: any[],
  fileDocumentsUpload: any[],
  newFileContractsUpload: any[],
  newFileContractsAddendumUpload: any[]
) => {
  const bodyFormData = new FormData();
  bodyFormData.append(
    "data",
    new Blob([JSON.stringify(body)], {
      type: "application/json",
    })
  );
  appendFileFormData(bodyFormData, fileContractsUpload, "fileContracts");
  appendFileFormData(bodyFormData, fileEducationsUpload, "fileEducations");
  appendFileFormData(bodyFormData, fileContractsAddendumUpload, "fileContractAddendum");
  appendFileFormData(bodyFormData, newFileContractsUpload, "newFileContracts");
  appendFileFormData(bodyFormData, newFileContractsAddendumUpload, "newFileContractAddendum");
  return bodyFormData;
};

export const getNextStepTabPersonnel = (currentTab: string, step: number): TAB_SELECT_PERSONNEL_ENUM => {
  const objectCurrentTabSelect = ObjectTabSelectPersonnel.find((item) => item.code === currentTab);
  return ObjectTabSelectPersonnel.find((item) => item.order === objectCurrentTabSelect?.order + step)
    ?.code as TAB_SELECT_PERSONNEL_ENUM;
};

export const handleKeyPressPreventWithPattern = (pattern: any) => (event: React.KeyboardEvent<HTMLInputElement>) => {
  const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if (!pattern.test(key)) {
    event.preventDefault();
  }
};

export const handleCreateHr = (
  body: any,
  fileContractsUpload: any,
  fileEducationsUpload: any,
  fileContractsAddendumUpload: any,
  fileDocumentsUpload: any,
  navigate: NavigateFunction
) => {
  createHrApi(
    buildBodyFormCreatePersonnel(
      body,
      fileContractsUpload,
      fileEducationsUpload,
      fileContractsAddendumUpload,
      fileDocumentsUpload
    )
  )
    .then((res) => {
      toast.success(HR_MSG.createSuccess);

      navigate("/personnel");
    })
    .catch((err) => {
      toast.error(getErrorMsgAxios(err));
    });
};

export const handleUpdateHr = (
  body: any,
  fileContractsUpload: any,
  fileEducationsUpload: any,
  fileContractsAddendumUpload: any,
  fileDocumentsUpload: any,
  newFileContractsUpload: any,
  newFileContractsAddendumUpload: any,
  navigate: NavigateFunction,
  location: Location
) => {
  const { bodyFilter, department, position, type, status } = location?.state || {};

  updateHrApi(
    buildBodyFormUpdatePersonnel(
      body,
      fileContractsUpload,
      fileEducationsUpload,
      fileContractsAddendumUpload,
      fileDocumentsUpload,
      newFileContractsUpload,
      newFileContractsAddendumUpload
    )
  )
    .then((res) => {
      toast.success(HR_MSG.updateSuccess);
      navigate("/personnel", {
        state: {
          bodyFilter: bodyFilter,
          department: department,
          position: position,
          type: type,
          status: status,
        },
      });
    })
    .catch((err) => {
      toast.error(getErrorMsgAxios(err));
    });
};

export const loadActiveEmployee = async (inputValue: string, callback: (options: EmployeeDetailModel[]) => void) => {
  const MIN_LENGTH_INPUT = 1;
  if (inputValue && inputValue.length > MIN_LENGTH_INPUT) {
    const status: string = "NORMAL";
    await getDirectManagement(status, inputValue, true)
      .then((res) => {
        if (res.data) {
          callback(res.data);
        } else {
          callback([]);
        }
      })
      .catch((err) => {
        callback([]);
      });
  }
};

export const loadActiveEmployeeWithDebounce = debounce(loadActiveEmployee, WAIT_TIME_DEBOUNCE);

// is active contract or contract addendum
const isActiveContractOrContractAddendum = (contract: ContractModel | ContractAddendumModel) => {
  if (isPermanentTermContactType(contract.type)) {
    if (moment().isSameOrAfter(moment(contract.startDate), "date")) {
      return true;
    }
  }
  if (
    moment().isSameOrAfter(moment(contract.startDate), "date") &&
    moment().isSameOrBefore(moment(contract.endDate), "date")
  ) {
    return true;
  }
  return false;
};

export const isActiveContractAddendum = (item: ContractAddendumModel) => {
  if (moment().isSameOrBefore(moment(item.endDate), "date")) {
    return true;
  }
  return false;
};

// get is Active
export const getActiveContractOrContractAddendum = (data: ContractModel[] | ContractAddendumModel[]) => {
  for (const item of data) {
    if (isActiveContractOrContractAddendum(item)) {
      return item;
    }
  }
  return null;
};

// Check exist contract active
export const isExistContractActive = (contracts: any[] | ContractModel[]) => {
  for (const item of contracts) {
    if (isActiveContractOrContractAddendum(item)) {
      return true;
    }
  }
  return false;
};

// Check exist contract addendum active
export const isExistContractAddendumActive = (contractAddendum: ContractAddendumModel[] | any) => {
  const contractAddendumActive = getActiveContractOrContractAddendum(contractAddendum);
  if (contractAddendumActive === null) {
    return false;
  }
  return true;
};

// check trùng
export const isExistContract = (contractDetail: any[] | ContractModel[], codeContract: string) => {
  return contractDetail.map((item) => item.code).includes(codeContract);
};

// check trùng số văn bản
export const isExistDocumentCode = (documentDetail: any[], codeDocument: string | null | undefined) => {
  return documentDetail.map((item) => item.code).includes(codeDocument);
};

export const isContractOrContractAddendumActive = (values: ContractModel | ContractAddendumModel) => {
  if (isPermanentTermContactType(values.type)) {
    return true;
  }

  if (moment(values.endDate).isBefore(moment(), "date")) {
    return false;
  }

  return true;
};

export const isOverlapDateTimeContract = (contracts: any[] | ContractModel[], values: ContractModel) => {
  return contracts.some((item) =>
    isPermanentTermContactType(item?.type)
      ? moment(values.startDate).isSameOrAfter(moment(item.startDate), "date") ||
        moment(values.endDate).isSameOrAfter(moment(item.startDate), "date") ||
        moment(item.startDate).isBetween(moment(values.startDate), moment(values.endDate), "date", "[]")
      : moment(values.startDate).isBetween(moment(item.startDate), moment(item.endDate), "date", "[]") ||
        moment(values.endDate).isBetween(moment(item.startDate), moment(item.endDate), "date", "[]") ||
        moment(item.endDate).isBetween(moment(values.startDate), moment(values.endDate), "date", "[]") ||
        moment(item.endDate).isBetween(moment(values.startDate), moment(values.endDate), "date", "[]")
  );
};

export const isPermanentTermContactType = (type: string) => {
  if (CONTACT_TYPE_ENUM.PERMANENT_TERM === type) {
    return true;
  }
  return false;
};

export const isOverlapDateTimeContractAddendum = (
  contractAddendum: ContractAddendumModel[] | undefined,
  values: ContractAddendumModel
) => {
  if (!contractAddendum) {
    return false;
  }

  return contractAddendum.some(
    (item) =>
      moment(values.startDate).isBetween(moment(item.startDate), moment(item.endDate), "date", "[]") ||
      moment(values.endDate).isBetween(moment(item.startDate), moment(item.endDate), "date", "[]") ||
      moment(item.endDate).isBetween(moment(values.startDate), moment(values.endDate), "date", "[]") ||
      moment(item.endDate).isBetween(moment(values.startDate), moment(values.endDate), "date", "[]")
  );
};

export const isStartContractAddendum = (item: ContractAddendumModel) => {
  if (moment(item?.startDate).isSameOrBefore(moment(), "date")) {
    return true;
  }
  return false;
};

export const checkStartDateContractAddendum = (contractAddendum: ContractAddendumModel[]) => {
  for (const item of contractAddendum) {
    if (isActiveContractOrContractAddendum(item) && isStartContractAddendum(item)) {
      return true;
    }
  }
  return false;
};

export const getSalarySummaryDetail = (contractAddendum: ContractAddendumModel[], contracts: ContractModel[]) => {
  const defaultValue = {
    allowances: "",
    salary: "",
    monthlyBonus: "",
  };

  if (isEmptyArr(contracts)) {
    return defaultValue;
  }

  const activeContract = getActiveContractOrContractAddendum(contracts);

  if (!activeContract) {
    return defaultValue;
  }

  const summaryActiveContract = {
    allowances: activeContract.allowances,
    salary: activeContract.salary,
    monthlyBonus: activeContract.monthlyBonus,
  };

  if (isEmptyArr(contractAddendum)) {
    return summaryActiveContract;
  } else {
    const contractAddendumOfActiveContract = contractAddendum.filter(
      (item) => item.contractCode === activeContract.code
    );

    const activeContractAddendum = getActiveContractOrContractAddendum(contractAddendumOfActiveContract);

    if (activeContractAddendum !== null && checkStartDateContractAddendum(contractAddendum)) {
      return {
        allowances: activeContractAddendum.allowances || summaryActiveContract.allowances,
        salary: activeContractAddendum.salary || summaryActiveContract.salary,
        monthlyBonus: activeContractAddendum.monthlyBonus || summaryActiveContract.monthlyBonus,
      };
    }
    return summaryActiveContract;
  }
};

export const isDuplicateCodeContractAddendum = (
  contractAddendum: ContractAddendumModel[] | undefined,
  code: string
) => {
  if (contractAddendum) {
    return contractAddendum.some((item) => item.code === code);
  }
  return false;
};

export const isTimeContractAddendumBetweenTimeContract = (
  startDate: any,
  endDate: any,
  currentContract: ContractModel | undefined
) => {
  if (isPermanentTermContactType(currentContract?.type)) {
    if (
      moment(startDate).isSameOrAfter(moment(currentContract?.startDate), "date") &&
      moment(endDate).isSameOrAfter(moment(currentContract?.startDate), "date")
    ) {
      return false;
    }
  } else {
    if (
      moment(startDate).isBetween(moment(currentContract?.startDate), moment(currentContract?.endDate), "date", "[]") &&
      moment(endDate).isBetween(moment(currentContract?.startDate), moment(currentContract?.endDate), "date", "[]")
    ) {
      return false;
    }
  }
  return true;
};

export const isTimeContractCoincidesContractAddendum = (
  startDateContract: any,
  endDateContract: any,
  startDateContractAddendum: any,
  endDateContractAddendum: any
) => {
  if (
    moment(startDateContract).isAfter(moment(endDateContractAddendum), "date") ||
    moment(endDateContract).isBefore(moment(startDateContractAddendum), "date") ||
    moment(startDateContract).isBetween(
      moment(startDateContractAddendum),
      moment(endDateContractAddendum),
      "date",
      "()"
    ) ||
    moment(endDateContract).isBetween(moment(startDateContractAddendum), moment(endDateContractAddendum), "date", "()")
  ) {
    return true;
  }

  return false;
};

export const downloadFileHrByType = async (id: any, name: string, type: TYPE_FILE_ENUM) => {
  const body = {
    id: id,
    type,
  };

  let res = await downloadFileHrByTypeApi(body);
  downloadTempHrFileByType(res?.data, name, type);
};

export const downloadTempHrFileByType = (file: Blob, name: string, type: TYPE_FILE_ENUM) => {
  try {
    if (type === TYPE_FILE_ENUM.CONTRACT_ADDENDUM || type === TYPE_FILE_ENUM.CONTRACT) {
      previewAndDowndloadFile(name, getFileExtension(name), file);
    } else {
      previewOrDowndloadFile(name, getFileExtension(name), file);
    }
  } catch (err) {
    toast.error(getErrorMsgAxios(err));
  }
};

export const getValidationSchemaGI = (valueUploads: any) => {
  switch (valueUploads?.type as TYPE_PERSONNEL_ENUM) {
    case TYPE_PERSONNEL_ENUM.EXPERTS_AND_COACHING_STAFF: {
      return validationExpertsAndCoachingStaffSchema;
    }
    case TYPE_PERSONNEL_ENUM.LEADERS_AND_DEPARTMENTS: {
      return validationLeadersAndDepartmentsSchema;
    }
    case TYPE_PERSONNEL_ENUM.OPERATING_PARTS: {
      return validationOperatingPartsSchema;
    }
    case TYPE_PERSONNEL_ENUM.THE_SUBORDINATE_UNITS: {
      return validationTheSubordinateUnitsSchema;
    }
    case TYPE_PERSONNEL_ENUM.OTHER: {
      return validationOtherSchema;
    }
    default:
      return null;
  }
};

export const isInValidValueUploadGI = (valueUploads: any): boolean => {
  const validationSchema = getValidationSchemaGI(valueUploads);
  if (validationSchema && !validationSchema.isValidSync(valueUploads)) {
    return true;
  }

  return false;
};

export const genIdentity = (passport: string, identityCard: string) => {
  const c = [];

  if (passport) {
    c.push(IDENTITY_DOCUMENTS_ENUM.PASSPORT);
  }

  if (identityCard) {
    c.push(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD);
  }

  return c;
};

export const genSelectPosition = (partyPosition: string, unionPosition: string) => {
  const c = [];

  if (partyPosition) {
    c.push(POSITION_ENUM.PARTY_MEMBER);
  }

  if (unionPosition) {
    c.push(POSITION_ENUM.UNION_MEMBER);
  }

  return c;
};

export const genSelectDocOther = (workPermitNumber: string, temporaryResidenceCard: string) => {
  const c = [];
  if (workPermitNumber) {
    c.push(OTHER_DOCUMENTS_ENUM.WORK_PREMIT);
  }

  if (temporaryResidenceCard) {
    c.push(OTHER_DOCUMENTS_ENUM.TEMPORARY);
  }

  return c;
};

export const checkExpertAndCoachingStaff = (expert: any) => {
  if (expert === TYPE_PERSONNEL_ENUM.EXPERTS_AND_COACHING_STAFF) {
    return true;
  }
  return false;
};

// export file khi đẩy dữ liệu lên browser
export const exportFileFromBrowser = (file: File[]) => {
  console.log(file);
  try {
    if (file) {
      FileSaver.saveAs(file[0], file[0]?.name);
      toast.success("Xuất file thành công.");
    }
  } catch (error: any) {
    toast.error("Có lỗi xảy ra");
  }
};
