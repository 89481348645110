import { Box } from "@mui/material";
import React, { FC } from "react";

interface BoxContainerProps {
  children?: React.ReactNode | React.ReactElement;
  [key: string]: unknown;
}

const BoxContainer: FC<BoxContainerProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: "4px",
        bgcolor: "white",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
        mb: 2,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default BoxContainer;
