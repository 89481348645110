export const VIEW_DATE_FORMAT = "DD/MM/YYYY";
export const VIEW_MONTH_FORMAT = "MM/YYYY";
export const VIEW_DATE_TIME_WITHOUT_SECOND_FORMAT = "DD/MM/YYYY HH:mm";
export const VIEW_FULL_TIME_FORMAT = "HH:mm:ss";
export const VIEW_TIME_FORMAT = "HH:mm";
export const VIEW_YEAR_FORMAT = "YYYY";
export const UPLOAD_DATE_FORMAT = "YYYY-MM-DD";
export const UPLOAD_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const UPLOAD_MONTH_FORMAT = "YYYY-MM";
export const VIEW_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const FORMAT_DATE_TIME_UTC = "YYYY-MM-DDTHH:mm:ss[Z]";
export const FORMAT_ORIGINAL_TIME = "ddd MMM DD YYYY HH:mm:ss ZZ";
export const WAIT_TIME_DEBOUNCE = 1000;
