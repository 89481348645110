import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState, FC } from "react";
import HeaderPage from "../../../components/common/HeaderPage";
import XCircleIcon from "../../../components/icons/XCircleIcon";
import { ACCESS_RIGHT_ENUM } from "../../../enum/access-right.enum";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { hasAccessRight } from "../../../utils/auth.util";
import ModalCreateUser from "./modal/ModalCreateUser";
import { getAllAccessRightsApiAsync } from "./redux/user.slice";

interface HeaderUserProps {}

const HeaderUser: FC<HeaderUserProps> = (props) => {
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const handleCreate = async () => {
    setOpenModalCreate(true);
  };

  const handleCloseModal = () => {
    setOpenModalCreate(false);
  };

  return (
    <>
      <ModalCreateUser open={openModalCreate} onClose={handleCloseModal} />
      <HeaderPage title="Danh sách người dùng">
        <>
          {hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN) && (
            <Button variant="contained" onClick={handleCreate} startIcon={<XCircleIcon />}>
              Thêm mới
            </Button>
          )}
        </>
      </HeaderPage>
    </>
  );
};

export default HeaderUser;
