import React, { FC } from "react";

import { LocalizationMap, Worker } from "@react-pdf-viewer/core";
// Core viewer
import { Viewer } from "@react-pdf-viewer/core";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// Import the localization file
import vi_VN from "@react-pdf-viewer/locales/lib/vi_VN.json";

interface PagePdfProps {
  fileUrl: string;
}

const PagePdf: FC<PagePdfProps> = (props) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { fileUrl } = props;
  return (
    // "./pdf.worker.min.js"
    <Worker workerUrl="../../../../pdf.worker.min.js">
      <Viewer
        fileUrl={fileUrl}
        plugins={[
          // Register plugins
          defaultLayoutPluginInstance,
        ]}
        localization={vi_VN as unknown as LocalizationMap}
      />
    </Worker>
  );
};

export default PagePdf;
