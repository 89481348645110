import { Box, List } from "@mui/material";
import ItemSidebar from "./ItemSidebar";

interface SidebarProps {
  data: any;
}

const Sidebar = ({ data }: SidebarProps) => {
  return (
    <Box
      sx={{
        width: {
          xs: "0px",
          md: `${280}px`,
        },
        minWidth: {
          xs: "0px",
          md: `${280}px`,
          lg: `${280}px`,
        },
        backgroundColor: "#061748",
        position: "fixed",
        top: 0,
        height: "100vh",
        zIndex: 1,
        overflow: "hidden",
        marginTop: "66px",
      }}
    >
      <Box
        sx={{
          color: "white",
          position: "relative",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <List sx={{ p: 0 }}>{data && data.map((item: any) => <ItemSidebar key={item.id} {...item} />)}</List>
      </Box>
    </Box>
  );
};

export default Sidebar;
