import HTTP_HR_SERVICE from "../config/axios/axios-hr.config";
import { Pageable } from "../model/page.model";
import { FilterEmployeeModel, HrDepartmentModel, HrPositionModel } from "../model/personnel.model";
// import { HrDepartmentModel, HrPositionModel } from "../model/personnel.model";
import { DepartmentModel, FilterDepartmentReqModel, FilterPositionReqModel, PositionModel } from "../model/user.model";

export interface bodyDownloadFileHr {
  id: number;
  type: string;
}

export const filterPersonnelApi = () => {};

// api create
export const createHrApi = (data: FormData) => {
  return HTTP_HR_SERVICE.post("/hr-employee", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      type: "formData",
    },
  });
};

// api find by id
export const findHrByIdApi = (id: number) => {
  return HTTP_HR_SERVICE.get("/hr-employee", {
    params: { id },
  });
};

// api update
export const updateHrApi = (data: FormData) => {
  return HTTP_HR_SERVICE.put("/hr-employee", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      type: "formData",
    },
  });
};

// filter department
export const hrDepartmentApi = (body: HrDepartmentModel | null, pageable: Pageable) => {
  return HTTP_HR_SERVICE.post("/department/filter", body, {
    params: { ...pageable },
  });
};

// create department
export const hrCreateDepartment = (body: HrDepartmentModel | null) => {
  return HTTP_HR_SERVICE.post("/department", body);
};

// filter positon
export const hrPositionApi = (body: HrPositionModel | null, pageable: Pageable) => {
  return HTTP_HR_SERVICE.post("/position/filter", body, {
    params: { ...pageable },
  });
};

//filter employee
export const filterEmployeeApi = (body: FilterEmployeeModel | null, pageable: Pageable) => {
  return HTTP_HR_SERVICE.post("/hr-employee/filter", body, {
    params: { ...pageable },
  });
};
export const exportEmoloyeeApi = (body: FilterEmployeeModel | null) => {
  return HTTP_HR_SERVICE.post("/hr-employee/filter/excel-hr-employee", body, {
    responseType: "blob",
  });
};

// create position
export const hrCreatePosition = (body: HrPositionModel | null) => {
  return HTTP_HR_SERVICE.post("/position", body);
};

// filter department ( page: phòng ban)
export const filterDepartmentApi = (body: FilterDepartmentReqModel | null, pageable: Pageable) => {
  return HTTP_HR_SERVICE.post<DepartmentModel[]>("/department/filter", body, {
    params: { ...pageable },
  });
};

// filter position ( page: chức vụ)
export const filterPositionApi = (body: FilterPositionReqModel | null, pageable: Pageable) => {
  return HTTP_HR_SERVICE.post<PositionModel[]>("/position/filter", body, {
    params: { ...pageable },
  });
};

// export  department (page: phòng ban)
export const exportDepartment = (body: FilterDepartmentReqModel | null) => {
  return HTTP_HR_SERVICE.post("/department/filter/excel-department", body, {
    responseType: "blob",
  });
};

// export  position (page: phòng ban)
export const exportPosition = (body: FilterPositionReqModel | null) => {
  return HTTP_HR_SERVICE.post("/position/filter/excel-position", body, {
    responseType: "blob",
  });
};

// thêm mới department (page: phòng ban)
export const createDepartmentApi = (code: string, name: string, parentId: string, email: string) => {
  return HTTP_HR_SERVICE.post("/department", { code, name, parentId, email });
};

// thêm mới position (page: chức vụ)
export const createPositionApi = (code: string, name: string) => {
  return HTTP_HR_SERVICE.post("/position", { code, name });
};

// cập nhật department (page: phòng ban)
export const updateDepartmentApi = (
  id: any,
  code: string,
  name: string,
  parentId: string,
  status: number,
  email: string
) => {
  return HTTP_HR_SERVICE.put("/department", { id, code, name, parentId, status, email });
};

// cập nhật department (page: chức vụ)
export const updatePositionApi = (id: any, code: string, name: string, status: number) => {
  return HTTP_HR_SERVICE.put("/position", { id, code, name, status });
};

// dowload file hr CONTRACT or EDUCATION
export const downloadFileHrByTypeApi = (body: bodyDownloadFileHr) => {
  return HTTP_HR_SERVICE.post("/hr-employee/file/download", body, {
    responseType: "blob",
  });
};

export const getDetailPersonnelApi = (id: any) => {
  return HTTP_HR_SERVICE.get<any>(`/hr-employee?id=${id}`);
};

export const exportHr = (body: FilterEmployeeModel | null) => {
  return HTTP_HR_SERVICE.post("/hr-employee/filter/excel-hr-employee", body, {
    responseType: "blob",
  });
};

// get parent department
export const getDirectManagement = (status: string, q?: string, ignoreSpinner = false) => {
  return HTTP_HR_SERVICE.get("/hr-employee/status", {
    params: { status, q },
    ignoreSpinner,
  });
};

// get nationality
export const getNationality = () => {
  return HTTP_HR_SERVICE.get("/nationality");
};

// get summary salary
// export const getSummarySalaryApi = (id: number) => {
//   return HTTP_HR_SERVICE.get(`/hr-employee/search/summary-salary?id=${id}`);
// };

export const getDashboardTaskReceivers = (body: any, pageable: Pageable) => {
  return HTTP_HR_SERVICE.post("/hr-employee/filter/dashboard", body, { params: { ...pageable } });
};
