import moment, { Moment } from "moment";
import { string } from "yup";

export const isFromDateMoreThanDate = (fromDate: string, toDate: string) => {
  return false;
};

export const removeTimeOfDateTime = (dateTime: string | Date | Moment) => {
  if (dateTime instanceof string) {
    const newDate = moment(dateTime);
    newDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return newDate;
  }

  const cloneDate = (dateTime as Moment).clone();
  cloneDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return cloneDate;
};

export const formatDateTime = (dateTime: string | Date | undefined | any, format: string, defaultValue = "") => {
  return dateTime ? moment(dateTime).format(format) : defaultValue;
};

export const isDateInRangeDate = (
  date: Moment,
  fromDate: Moment,
  toDate: Moment,
  inclusivity?: "()" | "[)" | "(]" | "[]"
) => {
  return date.isBetween(fromDate, toDate, "day", inclusivity);
};
