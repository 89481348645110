import { Form, Formik, FormikHelpers } from "formik";
import React, { FC, useEffect } from "react";
import ModalContainer from "../../../../components/modal/ModalContainer";
import { useAppDispatch } from "../../../../store/hook";
import * as Yup from "yup";
import { FORM_MSG, POSITION_MSG } from "../../../../constants/msg/msg.constant";
import { spacing_size } from "../../../../themes/size";
import { Button, Grid, Stack } from "@mui/material";
import FieldContainer from "../../../../components/form/FieldContainer";
import BrowserIcon from "../../../../components/icons/BrowserIcon";
import { createPositionApi } from "../../../../api/hr.api";
import { toast } from "react-toastify";
import { filterPositionApiAsync } from "../../user/redux/user.slice";
import { getErrorMsgAxios } from "../../../../utils/error.util";
interface ModalCreatePositionProps {
  open: boolean;
  onClose: () => void;
  buildDataFilter: any;
  initValue: any;
}

interface InitialValuesForm {
  code: string | null;
  name: string;
  // parentId: string;
}

const ModalCreatePosition: FC<ModalCreatePositionProps> = (props) => {
  const { open, onClose, buildDataFilter, initValue } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {}, [open]);

  const initialValues: InitialValuesForm = {
    code: "",
    name: "",
    // parentId: "",
  };

  const validationSchema = Yup.object({
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Mã chức vụ")),
    name: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Tên chức vụ")),
  });

  const handleSubmit = async (values: InitialValuesForm, formik: FormikHelpers<InitialValuesForm>) => {
    try {
      await createPositionApi(values?.code || "", values?.name || "");
      toast.success(POSITION_MSG.createSuccess);
      await dispatch(filterPositionApiAsync(buildDataFilter(initValue)));
      onClose();
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };
  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Thêm mới chức vụ"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off" style={{ padding: "0px 1px" }}>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="code"
                    type="text"
                    value={formik.values.code}
                    placeholder="Nhập thông tin"
                    label="Mã chức vụ"
                    notched={true}
                    variant={"input"}
                    id={"code"}
                    isRequired
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.name}
                    placeholder="Nhập thông tin"
                    maxRows={12}
                    label="Tên chức vụ"
                    notched={true}
                    variant={"input"}
                    id={"name"}
                    isRequired
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    {/* <Button
                  variant="contained"
                  color="cancel"
                  type="button"
                  onClick={() => {
                    formik.resetForm();
                  }}
                >
                  Huỷ
                </Button> */}
                    <Button variant="contained" color="primary" type="submit" startIcon={<BrowserIcon />}>
                      Lưu
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalCreatePosition;
