import { Typography } from "@mui/material";
import React, { FC } from "react";

interface HeaderFormProps {
  title?: string;
}

const HeaderForm: FC<HeaderFormProps> = (props) => {
  const { title } = props;
  return <Typography sx={{ fontSize: "1.2rem", fontWeight: "600", marginBottom: "0.6rem" }}>{title}</Typography>;
};

export default HeaderForm;
