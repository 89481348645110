import React, { FC, useState } from "react";
import * as Yup from "yup";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { Form, Formik, FormikHelpers } from "formik";
import ModalContainer from "../../../components/modal/ModalContainer";
import { spacing_size } from "../../../themes/size";
import { Button, Grid, Stack } from "@mui/material";
import FieldContainer from "../../../components/form/FieldContainer";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import { PersonnelModel, WorkingProcessModel } from "../../../model/personnel.model";
import moment from "moment";
import { UPLOAD_DATE_FORMAT } from "../../../constants/date-time.constant";
import { formatDateTime } from "../../../utils/data-time.util";

interface ModalUpdateWPProps {
  open: boolean;
  onClose: () => void;
  itemWorkingProcess: WorkingProcessModel | null;
  indexWorkingProcess: number;
  valueUploads: PersonnelModel | null;
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  setWorkingProcessDetail: React.Dispatch<React.SetStateAction<WorkingProcessModel[]>>;
  setValuesWorkingProcess?: any;
}

const ModalUpdateWP: FC<ModalUpdateWPProps> = (props) => {
  const {
    open,
    onClose,
    itemWorkingProcess,
    indexWorkingProcess,
    valueUploads,
    setValuesUploads,
    setWorkingProcessDetail,
    setValuesWorkingProcess,
  } = props;

  const initialValues: WorkingProcessModel = {
    startDate: itemWorkingProcess?.startDate ? new Date(itemWorkingProcess?.startDate) : null,
    endDate: itemWorkingProcess?.endDate ? new Date(itemWorkingProcess?.endDate) : null,
    position: itemWorkingProcess?.position ? itemWorkingProcess?.position : "",
    workPlace: itemWorkingProcess?.workPlace ? itemWorkingProcess?.workPlace : "",
    workContent: itemWorkingProcess?.workContent ? itemWorkingProcess?.workContent : "",
    workDepartment: itemWorkingProcess?.workDepartment ? itemWorkingProcess?.workDepartment : "",
  };

  const validationSchema = Yup.object({
    startDate: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Thời gian làm việc từ tháng/năm")),
    endDate: Yup.date()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg("Thời gian làm việc đến tháng/năm"))
      .when("startDate", (startDate: any, schema: any) => {
        if (startDate) {
          return schema.min(
            startDate,
            "Thời gian làm việc đến tháng/năm phải lớn hơn Thời gian làm việc từ tháng/năm."
          );
        }
        return schema;
      }),
  });

  const buildBody = (values: WorkingProcessModel) => {
    const newValues = {
      ...values,
      startDate: formatDateTime(values?.startDate, UPLOAD_DATE_FORMAT),
      endDate: formatDateTime(values?.endDate, UPLOAD_DATE_FORMAT),
    };
    return newValues;
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (values: WorkingProcessModel, formik: FormikHelpers<WorkingProcessModel>) => {
    const newValues = buildBody(values);
    const arrWorkingProcess: any = valueUploads?.employeeWorkingHistories;
    arrWorkingProcess[indexWorkingProcess] = newValues;
    setValuesUploads({
      ...valueUploads,
      employeeWorkingHistories: [...arrWorkingProcess],
    });
    setWorkingProcessDetail(arrWorkingProcess);
    setValuesWorkingProcess(arrWorkingProcess);
    onClose();
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Cập nhật"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off" style={{ padding: "0px 1px" }}>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    name="startDate"
                    onBlur={formik.handleBlur}
                    value={formik.values.startDate}
                    notched={true}
                    variant={"date"}
                    id={"startDate"}
                    label="Thời gian làm việc từ tháng/năm"
                    isRequired={true}
                    placeholderText={FORM_MSG?.monthFormatPlaceholder}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    name="endDate"
                    onBlur={formik.handleBlur}
                    value={formik.values.endDate}
                    notched={true}
                    variant={"date"}
                    id={"endDate"}
                    label="Thời gian làm việc đến tháng/năm"
                    isRequired={true}
                    placeholderText={FORM_MSG?.monthFormatPlaceholder}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="position"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.position}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Chức vụ"
                    notched={true}
                    variant={"input"}
                    id={"position"}
                    // isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="workDepartment"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.workDepartment}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Phòng ban công tác"
                    notched={true}
                    variant={"input"}
                    id={"workDepartment"}
                    // isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="workPlace"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.workPlace}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Nơi làm việc"
                    notched={true}
                    variant={"input"}
                    id={"workPlace"}
                    // isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="workContent"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.workContent}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Ghi chú"
                    notched={true}
                    variant={"input"}
                    id={"workContent"}
                  />
                </Grid>
              </Grid>
              <Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                      marginTop: "20px",
                    }}
                  >
                    <Button variant="contained" color="primary" type="submit" startIcon={<BrowserIcon />}>
                      Lưu
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalUpdateWP;
