import { Button, Grid, Stack } from "@mui/material";
import FileSaver from "file-saver";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { exportDepartment } from "../../../api/hr.api";
import BoxContainer from "../../../components/common/BoxContainer";
import FieldContainer from "../../../components/form/FieldContainer";
import SearchIcon from "../../../components/icons/SearchIcon";
import { DEPARMENT_IN_MSG } from "../../../constants/msg/msg.constant";
import { useAppDispatch } from "../../../store/hook";
import { spacing_size } from "../../../themes/size";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { changePageDepartment, filterDepartmentApiAsync } from "../user/redux/user.slice";
interface InitialValuesForm {
  name: string | null;
  status: string | null;
}
function FormSearchDepartment(props: any) {
  const dispatch = useAppDispatch();
  const { initValue, setInitValue, buildDataFilter } = props;
  const initialValues: InitialValuesForm = {
    name: "",
    status: "",
  };

  const extractFilename = (contentDisposition: string): string => {
    const startIndex = contentDisposition.indexOf('filename="') + 'filename="'.length;
    const endIndex = contentDisposition.indexOf('"', startIndex);
    return contentDisposition.substring(startIndex, endIndex);
  };

  const validationSchema = Yup.object({});

  const handleExport = async (formik: FormikProps<InitialValuesForm>) => {
    const body = {
      ...initValue,
      name: formik.values.name,
      status: null,
    };
    try {
      const res = await exportDepartment(body);
      const contentDisposition = res.headers['content-disposition'];
      const filename = extractFilename(contentDisposition);

      FileSaver.saveAs(res?.data, filename);
      toast.success(DEPARMENT_IN_MSG.exportDepartment);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const onSubmitSearch = async (values: any) => {
    const body = {
      ...initValue,
      name: values.name,
    };
    setInitValue(body);
    await dispatch(filterDepartmentApiAsync(buildDataFilter(body)));
    await dispatch(changePageDepartment(0));
  };

  const handleCancel = async (formik: FormikProps<InitialValuesForm>) => {
    const body = {
      ...initValue,
      name: null,
    };
    formik.resetForm();
    setInitValue(body);
    await dispatch(filterDepartmentApiAsync(buildDataFilter(body)));
    await dispatch(changePageDepartment(0));
  };

  return (
    <>
      <BoxContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitSearch}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={spacing_size}>
                <Grid item md={4} xs={12}>
                  <FieldContainer
                    fullWidth
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.name}
                    placeholder="Nhập thông tin"
                    maxRows={12}
                    label="Bộ phận công tác"
                    notched={true}
                    variant={"input"}
                    id={"name"}
                  />
                </Grid>

                <Grid item xs={12} md={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    <Button variant="contained" color="cancel" type="button" onClick={() => handleCancel(formik)}>
                      Huỷ
                    </Button>
                    <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                      Tìm kiếm
                    </Button>
                    <Button variant="outlined" color="primary" type="button" onMouseDown={() => handleExport(formik)}>
                      Xuất Excel
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </BoxContainer>
    </>
  );
}

export default FormSearchDepartment;
