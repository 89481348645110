import React, { useEffect, useRef, useState } from "react";
import HeaderUpdateTask from "./HeaderUpdateTask";
import HeaderPage from "../../../components/common/HeaderPage";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import BoxContainer from "../../../components/common/BoxContainer";
import { spacing_size } from "../../../themes/size";
import FieldContainer from "../../../components/form/FieldContainer";
import { ALLOW_INPUT_FILE_DOC_IN } from "../../../constants/doc-in.constant";
import UploadSVG from "../../../components/images/upload.svg";
import { removeFile } from "../../../utils/file.util";
import OptionSelectUser from "../../../components/common/OptionSelectUser";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { UserModel } from "../../../model/user.model";
import { loadEmployeeWithDebounce } from "../../../utils/options.util";
import { MAX_FILE_LENGTH_TASK } from "../../../constants/task.constant";
import { changeFileTask } from "../../../utils/task.util";
import { convertDataToFormData } from "../../../utils/axios.ultil";
import { downloadFileTaskApi, getDetailByIdTaskApi, updateTaskApi } from "../../../api/task/task.api";
import { toast } from "react-toastify";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { BsInfoCircleFill } from "react-icons/bs";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useNavigate, Params, useParams } from "react-router-dom";
import moment from "moment";
import ViewFile from "../../../components/common/ViewFile";
import FileSaver from "file-saver";
import {
  TASK_PRIORITY_ENUM,
  TASK_STATUS_ENUM,
  taskPriorityOptions,
  taskVerifyAgainOptions,
} from "../../../enum/task.enum";
import HeaderForm from "../../../components/common/HeaderForm";
import { FORMAT_DATE_TIME_UTC, FORMAT_ORIGINAL_TIME } from "../../../constants/date-time.constant";

interface InitialValuesForm {
  subject: string | null;
  description: string | null;
  startDate: string | Date | null;
  endDate: string | Date | null;
  assignee: any;
  code: string;
  watchers?: any;
  priority?: any;
  verifyAgain?: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(65, 156, 221, 1)",
    p: 3,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(65, 156, 221, 1)",
  },
}));

function UpdateTask() {
  const btnSubmitRef = useRef<HTMLButtonElement>(null);
  const btnCancelRef = useRef<HTMLButtonElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [detailData, setDetailData] = useState<any>(null);
  const [deletedFileIds, setDeletedFileIds] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<InitialValuesForm>({
    code: "",
    watchers: [],
    assignee: "",
    startDate: null,
    endDate: null,
    subject: "",
    description: "",
    priority: TASK_PRIORITY_ENUM.NORMAL,
    verifyAgain: true,
  });

  const navigate = useNavigate();
  const params: Readonly<Params<any>> = useParams();

  const getDetailData = () => {
    if (isNaN(Number(params?.id))) {
      navigate("/404");
    } else {
      getDetailByIdTaskApi(Number(params?.id)).then((res) => {
        setDetailData(res.data);
        setInitialValues({
          code: res.data.code,
          assignee: res.data.assignee,
          startDate: moment(res.data.startDate).toDate(),
          endDate: moment(res.data.endDate).toDate(),
          subject: res.data.subject,
          description: res.data.description,
          watchers: res.data.taskWatchers,
          priority: res.data.priority,
          verifyAgain: res.data.verifyAgain,
        });
      });
    }
  };

  useEffect(() => {
    getDetailData();
  }, []);

  const validationSchema = Yup.object({
    subject: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Công việc")),
    description: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Nội dung công việc")),
    assignee: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Người nhận việc")),
    startDate: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Ngày bắt đầu dự kiến")),
    endDate: Yup.date()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg("Ngày giờ kết thúc dự kiến"))
      .when("startDate", (startDate: any, schema: any) => {
        return schema.test({
          test: function (endDate: Date | null | undefined) {
            if (startDate && endDate) {
              return (
                endDate.getFullYear() > startDate.getFullYear() ||
                (endDate.getFullYear() === startDate.getFullYear() && endDate.getMonth() > startDate.getMonth()) ||
                (endDate.getFullYear() === startDate.getFullYear() &&
                  endDate.getMonth() === startDate.getMonth() &&
                  endDate.getDate() >= startDate.getDate())
              );
            }
            return true;
          },
          message: "Ngày bắt đầu không thể lớn hơn ngày kết thúc",
        });
      })
      .when("startDate", (startDate: any, schema: any) => {
        return schema.test({
          test: function (endDate: Date | null | undefined) {
            if (startDate && endDate) {
              return endDate.getTime() > startDate.getTime();
            }
            return true;
          },
          message: "Giờ kết thúc phải lớn hơn giờ bắt đầu",
        });
      }),
    priority: Yup.string().nullable().required(FORM_MSG.requiredSelectFieldMsg("Độ ưu tiên")),
  });

  // change verify again
  const handleChangeVerifyAgain =
    (formik: FormikHelpers<InitialValuesForm>) => (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue("verifyAgain", e.target.value === "true" ? true : false);
    };

  // upload file
  const changeUploadFile = (e: any) => {
    setFiles(changeFileTask(e, files, "", getMaxFileLength()));
  };

  const getMaxFileLength = () => {
    const taskFilesLength = detailData?.taskFiles ? detailData?.taskFiles.length : 0;
    const maxFileLength = MAX_FILE_LENGTH_TASK - taskFilesLength + deletedFileIds.length;

    return maxFileLength;
  };

  // delete file
  const handleRemoveFile = (item: any) => {
    setFiles(removeFile(item, files));
  };

  const handleReset = (formik: any) => {
    formik.resetForm();
    setFiles([]);
    setDeletedFileIds([]);
  };

  const validateValuesForm = (values: InitialValuesForm) => {
    const watchers = values.watchers ? values.watchers.map((item: any) => item.email) : null;
    const assignee = values.assignee ? values.assignee?.email : null;
    const fileIds = deletedFileIds;

    const originalStartDate = values?.startDate ? moment(values?.startDate, FORMAT_ORIGINAL_TIME) : "";
    const originalEndDate = values?.endDate ? moment(values?.endDate, FORMAT_ORIGINAL_TIME) : "";

    const formatStartDate = originalStartDate ? originalStartDate.utc().format(FORMAT_DATE_TIME_UTC) : "";
    const formatEndDate = originalEndDate ? originalEndDate.utc().format(FORMAT_DATE_TIME_UTC) : "";

    return {
      ...values,
      watchers,
      assignee,
      fileIds,
      id: detailData.id,
      startDate: formatStartDate,
      endDate: formatEndDate,
    };
  };

  const handleOnSubmit = async (values: InitialValuesForm) => {
    try {
      await updateTaskApi(convertDataToFormData(files, validateValuesForm(values), "dataFile", "data"));
      toast.success("Cập nhật task thành công");
      navigate("/task/" + params.id);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const handleDeleteFiledId = (file: any) => {
    setDeletedFileIds([...deletedFileIds, file.id]);
  };

  return (
    <>
      <HeaderUpdateTask />
      <HeaderPage title="Cập nhật công việc" variant="h5" component="h5">
        <Stack direction={{ xs: "column", sm: "row" }} spacing={spacing_size} sx={{ marginBottom: "10px" }}>
          <Button
            variant="outlined"
            onClick={() => {
              btnCancelRef.current?.click();
            }}
          >
            Nhập lại
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              btnSubmitRef.current?.click();
            }}
            startIcon={<BrowserIcon />}
          >
            Cập nhật
          </Button>
        </Stack>
      </HeaderPage>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={true}
      >
        {(formik) => (
          <Form noValidate autoComplete="off">
            <BoxContainer>
              <HeaderForm title={"Thông tin công việc"} />
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="code"
                    value={formik.values.code}
                    type="text"
                    label="Mã công việc"
                    disabled
                    variant={"input"}
                    id={"code"}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="subject"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.subject}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    placeholder="Nhập thông tin"
                    label="Công việc"
                    notched={true}
                    variant={"input"}
                    id={"subject"}
                    isRequired={true}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    name="description"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    placeholder="Nhập thông tin"
                    label="Nội dung công việc"
                    isRequired
                    variant={"textarea"}
                    id={"description"}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="startDate"
                    onBlur={formik.handleBlur}
                    type="text"
                    placeholderText="dd/mm/yyyy"
                    label="Ngày giờ bắt đầu dự kiến:"
                    isRequired
                    variant={"date"}
                    id={"startDate"}
                    locale="pt-BR"
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="Pp"
                    // maxDate={currentDate}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="endDate"
                    onBlur={formik.handleBlur}
                    type="text"
                    placeholderText="dd/mm/yyyy"
                    label="Ngày giờ kết thúc dự kiến:"
                    isRequired
                    variant={"date"}
                    id={"endDate"}
                    locale="pt-BR"
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="Pp"
                    tooltip={
                      <LightTooltip
                        title={"Thời gian xử lý công việc bao gồm cả thời gian chờ kiểm tra của người giao việc"}
                        placement="top"
                        arrow
                      >
                        <Typography component={"span"}>
                          <BsInfoCircleFill style={{ color: "#0084FF" }} />
                        </Typography>
                      </LightTooltip>
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="assignee"
                    onBlur={formik.handleBlur}
                    type="text"
                    value={formik.values.assignee}
                    placeholder={FORM_MSG.reactSelectPlaceholder}
                    onChange={(e: UserModel) => {
                      formik.setFieldValue("assignee", e);
                    }}
                    label={"Người nhận việc"}
                    isRequired
                    getOptionLabel={(item: UserModel) => item.email}
                    getOptionValue={(item: UserModel) => item.email}
                    variant={"async-react-select"}
                    id={"assignee"}
                    isDisabled={detailData?.status !== TASK_STATUS_ENUM.RETURNED}
                    loadOptions={loadEmployeeWithDebounce}
                    components={{ Option: OptionSelectUser }}
                    isClearable
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="watchers"
                    onBlur={formik.handleBlur}
                    type="text"
                    value={formik.values.watchers}
                    placeholder={FORM_MSG.reactSelectPlaceholder}
                    onChange={(e: UserModel) => {
                      formik.setFieldValue("watchers", e);
                    }}
                    label={"Người nhận thông tin"}
                    getOptionLabel={(item: UserModel) => item.email}
                    getOptionValue={(item: UserModel) => item.email}
                    variant={"async-react-select"}
                    id={"watchers"}
                    loadOptions={loadEmployeeWithDebounce}
                    isMulti
                    components={{ Option: OptionSelectUser }}
                    isClearable
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    name="priority"
                    label="Độ ưu tiên"
                    options={taskPriorityOptions}
                    labelOption="description"
                    valueOption="code"
                    variant={"select"}
                    id={"priority"}
                    isRequired
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    name="verifyAgain"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeVerifyAgain(formik)}
                    value={formik.values.verifyAgain}
                    label="Việc cần người giao kiểm tra lại?"
                    variant={"radio"}
                    labelOption="description"
                    valueOption="code"
                    id={"verifyAgain"}
                    options={taskVerifyAgainOptions}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Typography>Tài liệu đính kèm</Typography>
                  {files && files.length < getMaxFileLength() && (
                    <>
                      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                        <Grid item sm={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                              border: "1px dashed #0240B8",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                padding: "1rem",
                              }}
                            >
                              <label>
                                <img
                                  src={UploadSVG}
                                  style={{
                                    width: "65px",
                                    height: "50px",
                                  }}
                                  alt="img background upload"
                                />
                              </label>
                              <Typography
                                className="mt-2"
                                sx={{
                                  color: "#ADAAAA",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                              >
                                Tải lên tài liệu liên quan
                              </Typography>
                            </Box>
                            <input
                              id="file-input"
                              // accept="application/msword, application/vnd.ms-excel, application/pdf"
                              accept={ALLOW_INPUT_FILE_DOC_IN}
                              type="file"
                              multiple
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                top: 0,
                                left: 0,
                                border: "none",
                                zIndex: 0,
                                opacity: 0,
                              }}
                              onChange={(e: any) => changeUploadFile(e)}
                              // #inputFile
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }} className="mt-2">
                    <Grid container item xs={12} md={12} lg={12}>
                      {files
                        ? files.map((item: any) => (
                            <ViewFile key={JSON.stringify(item)} item={item} handleRemove={handleRemoveFile} />
                          ))
                        : null}
                      {detailData?.taskFiles &&
                        detailData?.taskFiles
                          .filter((item: any) => !deletedFileIds.includes(item.id))
                          .map((file: any) => (
                            <ViewFile
                              key={JSON.stringify(file)}
                              item={file}
                              handleRemove={handleDeleteFiledId}
                              onClickItem={async () => {
                                try {
                                  const res = await downloadFileTaskApi(Number(file?.taskId), Number(file?.id));
                                  FileSaver.saveAs(res?.data, file?.name);
                                } catch (error) {
                                  toast.error(getErrorMsgAxios(error));
                                }
                              }}
                            />
                          ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#0084FF",
                      fontStyle: "italic",
                    }}
                  >
                    ! Vui lòng tải file Word, Excel, PDF, JPEG, PNG
                  </Typography>
                </Grid>
              </Grid>
            </BoxContainer>

            {/* action footer */}
            <Grid item sm={12} justifyContent="center">
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={spacing_size}
                sx={{ marginBottom: "10px", justifyContent: "end" }}
              >
                <Button variant="outlined" onClick={() => handleReset(formik)} ref={btnCancelRef}>
                  Nhập lại
                </Button>
                <Button type="submit" variant="contained" ref={btnSubmitRef} startIcon={<BrowserIcon />}>
                  Cập nhật
                </Button>
              </Stack>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UpdateTask;
