import { useEffect, useState } from "react";
import { Params, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getDetailByIdTaskApi } from "../../../api/task/task.api";
import { getErrorMsgAxios } from "../../../utils/error.util";
import HeaderDetail from "./HeaderDetail";
import InformationDetail from "./InformationDetail";

function TaskDetail() {
  const location = useLocation();
  const { bodyFilter } = location?.state || {};
  const params: Readonly<Params<any>> = useParams();
  const [task, setTask] = useState<any>(null);

  const navigate = useNavigate();

  const getDetailData = async () => {
    if (isNaN(Number(params?.id))) {
      navigate("/404");
    } else {
      await getDetailByIdTaskApi(Number(params?.id))
        .then((res) => {
          setTask(res.data);
        })
        .catch((err) => {
          if (err?.response?.data?.status) {
            navigate("/404");
            const msg = getErrorMsgAxios(err);
            toast.error(msg);
          }
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getDetailData();
  }, []);

  return (
    <>
      <HeaderDetail bodyFilter={bodyFilter} />
      {task && <InformationDetail task={task} getDetailData={getDetailData} />}
    </>
  );
}

export default TaskDetail;
