import { Button, Grid, Table, TableBody, TableContainer, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, Params, useLocation, useNavigate, useParams } from "react-router-dom";
import { downloadFileDocOutApi, getDetailApi } from "../../../../api/doc-out.api";
import BoxContainer from "../../../../components/common/BoxContainer";
import HeaderPage from "../../../../components/common/HeaderPage";
import TableRowDetail from "../../../../components/common/TableRowDetail";
import ArrowBackIcon from "../../../../components/icons/ArrowBackIcon";
import BrowserIcon from "../../../../components/icons/BrowserIcon";
import { VIEW_DATE_FORMAT } from "../../../../constants/date-time.constant";
import { ACCESS_RIGHT_ENUM } from "../../../../enum/access-right.enum";
import { DocOutModel } from "../../../../model/doc-out.model";
import { useAppSelector } from "../../../../store/hook";
import { spacing_size } from "../../../../themes/size";
import { hasAccessRight } from "../../../../utils/auth.util";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { toast } from "react-toastify";
import { previewOrDowndloadFile } from "../../../../utils/file.util";

const DetailDocOut = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params: Readonly<Params<string>> = useParams();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [docOut, setDocOut] = useState<DocOutModel>();

  const handleBack = () => {
    const { bodyFilter, docBook } = location?.state;
    navigate(`/doc-out`, {
      state: {
        bodyFilter: bodyFilter,
        docBook: docBook,
      },
    });
    // navigate("/doc-out");
  };

  const handleUpdate = () => {
    navigate(`/doc-out/${params?.id}/update`, {
      state: {
        id: params?.id,
        bodyFilter: location?.state?.bodyFilter,
        docBook: location?.state?.docBook,
      },
    });
  };

  const getDocOut = async () => {
    if (isNaN(Number(params?.id))) {
      navigate("/404");
    } else {
      await getDetailApi(Number(params?.id))
        .then((res) => {
          setDocOut(res.data);
        })
        .catch((err) => {
          if (err?.response?.data?.status) {
            navigate("/404");
            const msg = getErrorMsgAxios(err);
            toast.error(msg);
          }
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getDocOut();
  }, []);

  const showUpdateBtn = () => {
    if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN)) {
      return true;
    }
    const isCreatedBy = true;
    if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.CLERICAL_ASSISTANT) && isCreatedBy) {
      return true;
    }

    return false;
  };

  const downloadFile = (id: number, name: string, docType: string) => {
    downloadFileDocOutApi(id).then((res) => {
      previewOrDowndloadFile(name, docType, res?.data);
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        sx={{
          mb: 2,
        }}
      >
        Quay lại
      </Button>

      <HeaderPage title="Văn bản đi" variant="h5" component="h5">
        <>
          {showUpdateBtn() && (
            <Button type="button" variant="contained" startIcon={<BrowserIcon />} onClick={handleUpdate}>
              Cập nhật
            </Button>
          )}
        </>
      </HeaderPage>

      <BoxContainer>
        {/* thông tin chung */}
        <Typography sx={{ fontWeight: 600 }}>Thông tin chung</Typography>
        <TableContainer sx={{ padding: "1rem" }}>
          <Table sx={{ minWidth: 650 }} aria-label="detail doc out table">
            <TableBody>
              <TableRowDetail
                title={"Loại công văn đi:"}
                content={
                  <>
                    {docOut?.docCategoryName}
                    {docOut?.internalName && " - " + docOut?.internalName}
                    {docOut?.decisionName && " - " + docOut?.decisionName}
                    {docOut?.submissionName && " - " + docOut?.submissionName}
                  </>
                }
              />
              <TableRowDetail title={"Nơi nhận thông tin:"} content={docOut?.fromPlace} />
              <TableRowDetail
                title={"Số công văn đến:"}
                content={
                  docOut?.docIn && (
                    <Link to={`../../doc-in/${docOut?.docIn?.id}`} target="_blank">
                      {docOut?.docIn?.code} - {docOut?.docIn?.arrivalNbr}
                    </Link>
                  )
                }
              />
              <TableRowDetail title={"Trích yếu nội dung văn bản:"} content={docOut?.summary} />
              <TableRowDetail
                title={"Thông tin đính kèm:"}
                content={
                  <Grid container spacing={spacing_size}>
                    {docOut?.docOutFiles &&
                      docOut?.docOutFiles?.length > 0 &&
                      docOut?.docOutFiles.map((item) => (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              component="a"
                              sx={{ cursor: "pointer", color: "blue" }}
                              onClick={() => {
                                downloadFile(item.id, item.name, item.docType);
                              }}
                              type="button"
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                        </>
                      ))}
                  </Grid>
                }
              />
            </TableBody>
          </Table>
        </TableContainer>

        {/* thông tin ban hành */}
        <Typography sx={{ fontWeight: 600 }}>Thông tin ban hành</Typography>
        <TableContainer sx={{ padding: "1rem" }}>
          <Table sx={{ minWidth: 650 }} aria-label="detail doc out table">
            <TableBody>
              <TableRowDetail
                title={"Ngày ban hành:"}
                content={docOut?.issueDate && moment(docOut.issueDate).format(VIEW_DATE_FORMAT)}
              />
              <TableRowDetail title={"Sổ công văn:"} content={docOut?.docBook?.name} />
              <TableRowDetail title={"Số đi:"} content={docOut?.arrivalNbr} />
              <TableRowDetail
                title={"Người nhận bản lưu:"}
                content={
                  docOut?.issueReceivers &&
                  docOut?.issueReceivers.map((item, index, { length }) => (
                    <Typography component="span">
                      {item.fullName} ({item.email}){index < length - 1 && ", "}
                    </Typography>
                  ))
                }
              />
              <TableRowDetail title={"Nội dung ban hành:"} content={docOut?.issueContent} />
              <TableRowDetail
                title={"Phòng thực hiện ban hành:"}
                content={
                  <Typography>
                    {docOut?.issueDepartment?.code} -{" "}
                    {docOut?.issueDepartment?.displayName
                      ? docOut?.issueDepartment?.displayName
                      : docOut?.issueDepartment?.name}
                  </Typography>
                }
              />
            </TableBody>
          </Table>
        </TableContainer>
      </BoxContainer>
    </>
  );
};

export default DetailDocOut;
