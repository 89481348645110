import { Form, Formik, FormikHelpers } from "formik";
import React, { FC } from "react";
import * as Yup from "yup";
import { Button, Grid, Stack } from "@mui/material";
import ModalContainer from "../../../components/modal/ModalContainer";
import { FORM_MSG, HR_MSG } from "../../../constants/msg/msg.constant";
import { HrPositionModel } from "../../../model/personnel.model";
import FieldContainer from "../../../components/form/FieldContainer";
import { spacing_size } from "../../../themes/size";
import { hrCreatePosition } from "../../../api/hr.api";
import { toast } from "react-toastify";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { useAppDispatch } from "../../../store/hook";
import { getAllPositionApiAsync } from "../redux/personnel.reducer";
import { PAGEABLE_MAX_SIZE } from "../../../constants/page.constant";

interface ModalCreatePositionProps {
  open: boolean;
  onClose: () => void;
}

const ModalCreatePosition: FC<ModalCreatePositionProps> = (props) => {
  const { open, onClose } = props;
  const dispatch = useAppDispatch();

  const initialValues: HrPositionModel = {
    name: "",
    code: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Mã chức vụ")),
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Chức vụ")),
  });

  const handleSubmit = async (values: HrPositionModel, formik: FormikHelpers<HrPositionModel>) => {
    await hrCreatePosition(values)
      .then((res) => {
        if (res) {
          toast.success(HR_MSG.createPositionSuccess);
          dispatch(getAllPositionApiAsync({ body: { name: null, status: "ACTIVE" }, pageable: PAGEABLE_MAX_SIZE }));
          onClose();
        }
      })
      .catch((err) => {
        toast.error(getErrorMsgAxios(err));
      });
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Thêm mới chức vụ"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnChange={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off" style={{ padding: "0px 1px" }}>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.code}
                    placeholder={FORM_MSG.placeholder}
                    label="Mã chức vụ"
                    isRequired={true}
                    variant={"input"}
                    id={"code"}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.name}
                    placeholder={FORM_MSG.placeholder}
                    label="Tên chức vụ"
                    isRequired={true}
                    variant={"input"}
                    id={"name"}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Lưu
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalCreatePosition;
