import { ACCESS_RIGHT_ENUM } from "./../../../../../enum/access-right.enum";
import { ALLOW_EXTENSION_FILE_DOC_IN, CODE_OFFICE_MANAGER } from "../../../../../constants/doc-in.constant";
import { CATEGORY_DOC_IN_ENUM, senChannelDocIn, STATUS_DOC_IN_ENUM } from "../../../../../enum/doc-in.enum";
import { Department } from "../../../../../model/department.model";
import {
  convertFilesToArr,
  isAllowExtensionFile,
  isDuplicateFile,
  isDuplicateFileUploaded,
  isMaxSizeFile,
  isMinSizeFile,
} from "../../../../../utils/file.util";
import { CurrentUserModel } from "../../../../auth/redux/auth.slice";
import { hasAccessRight, hasAnyAccessRight } from "../../../../../utils/auth.util";
import { MAX_SIZE_FILE_MB } from "../../../../../constants/file.constant";

export const changeFileDocIn = (e: any, files: File[], fileUploaded: any, size: number): File[] => {
  const dataFiles: File[] = [];
  if (e.target.files) {
    const currentFiles = convertFilesToArr(e.target.files);
    if (files.length > 0) {
      dataFiles.push(...files);
    }
    for (let i = 0; i < currentFiles.length; i++) {
      if (dataFiles.length + (fileUploaded.length || 0) === size) {
        break;
      }
      const file = currentFiles[i];
      if (
        isAllowExtensionFile(file, ALLOW_EXTENSION_FILE_DOC_IN) &&
        !isMinSizeFile(file, 0) &&
        !isMaxSizeFile(file, MAX_SIZE_FILE_MB) &&
        !isDuplicateFile(dataFiles, file) &&
        !isDuplicateFileUploaded(fileUploaded, file)
      ) {
        dataFiles.push(file);
      }
    }
    e.target.value = null;
    return dataFiles;
  } else {
    return dataFiles;
  }
};

export const currentData = (pageSearch: any, data: any) => {
  const begin = (pageSearch - 1) * 10;
  const end = begin + 10;
  return data && data.slice(begin, end);
};

export const commafy = (num: any) => {
  const str = num.toString().split(".");
  // if (str[0].length >= 5) {
  //     str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  // }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ");
  }
  return str.join(".");
};

export const isEnableCreateButton = (currentUser: CurrentUserModel) => {
  // role văn thư hoặc nhân viên phòng quốc tế hoạc admin

  return hasAnyAccessRight(currentUser, [
    ACCESS_RIGHT_ENUM.ADMIN,
    ACCESS_RIGHT_ENUM.CLERICAL_ASSISTANT,
    ACCESS_RIGHT_ENUM.STAFF_INTERNATIONAL_DEPARTMENT,
  ]);
};

export const convertSentChannelDocIn = (sentChannel: string | undefined | null) => {
  for (const itemSentChannel of senChannelDocIn) {
    if (itemSentChannel.code === sentChannel) {
      return itemSentChannel.description;
    }
  }

  return null;
};

export const isDomesticCategoryDocIn = (categoryDocIn: string | CATEGORY_DOC_IN_ENUM | undefined): boolean => {
  return CATEGORY_DOC_IN_ENUM.DOMESTIC === categoryDocIn;
};

export const isInternationalCategoryDocIn = (categoryDocIn: string | CATEGORY_DOC_IN_ENUM | undefined): boolean => {
  return CATEGORY_DOC_IN_ENUM.INTERNATIONAL === categoryDocIn;
};

// hủy
export const isEnableBtnCancel = (
  status: STATUS_DOC_IN_ENUM,
  currentUser: CurrentUserModel | null,
  createdBy: string | undefined,
  categoryDocIn: string | CATEGORY_DOC_IN_ENUM | undefined
) => {
  const isRoleAdmin = hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN);
  const isCurrentUserCreatedBy = currentUser?.email === createdBy;

  if ([STATUS_DOC_IN_ENUM.RETURNED].includes(status) && (isRoleAdmin || isCurrentUserCreatedBy)) {
    return true;
  }
  return false;
};

// hoàn trả
export const isEnableBtnReturn = (
  status: STATUS_DOC_IN_ENUM,
  currentUser: CurrentUserModel | null,
  assignee: string,
  handlingDepartment: Department,
  categoryDocIn: string | undefined
) => {
  const isAdmin = hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN);
  // trang thái là chờ trình duyệt và role là Admin hoặc phó chánh văn phòng và lãnh đạo phòng quốc tế

  if (STATUS_DOC_IN_ENUM?.UNAPPROVED === status) {
    if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN)) {
      return true;
    }

    if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.CHIEF_OF_OFFICE) && isDomesticCategoryDocIn(categoryDocIn)) {
      return true;
    }

    if (
      hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.HEAD_OF_INTERNATIONAL_DEPARTMENT) &&
      isInternationalCategoryDocIn(categoryDocIn)
    ) {
      return true;
    }
  }

  // trạng thái chờ phân công và user là trưởng phòng chủ trì giải quyết
  const isHandlingDepartmentManager =
    currentUser?.departmentCode === handlingDepartment?.code &&
    CODE_OFFICE_MANAGER.includes(currentUser?.positionCode || "");
  if (STATUS_DOC_IN_ENUM.UNASSIGNED === status && (isAdmin || isHandlingDepartmentManager)) {
    return true;
  }

  // trang thái là chờ xử lý nhân viên được assign vào xử lý
  const isAssignee = currentUser?.email === assignee;
  if (STATUS_DOC_IN_ENUM.PENDING === status && (isAdmin || isAssignee)) {
    return true;
  }

  return false;
};

// duyệt
export const isEnableBtnApprove = (
  status: STATUS_DOC_IN_ENUM | string | undefined,
  currentUser: CurrentUserModel | null,
  docCategory: string | undefined
) => {
  if (status === STATUS_DOC_IN_ENUM?.UNAPPROVED) {
    if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN)) {
      return true;
    }

    if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.CHIEF_OF_OFFICE) && isDomesticCategoryDocIn(docCategory)) {
      return true;
    }

    if (
      hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.HEAD_OF_INTERNATIONAL_DEPARTMENT) &&
      isInternationalCategoryDocIn(docCategory)
    ) {
      return true;
    }
  }
  return false;
};

// cập nhật
export const isEnableBtnUpdate = (
  status: string | undefined,
  currentUser: CurrentUserModel | null,
  createdBy: string | undefined
) => {
  const isRoleAdmin = hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN);
  const isCurrentUserCreatedBy = currentUser?.email === createdBy;
  if (status === STATUS_DOC_IN_ENUM?.RETURNED && (isRoleAdmin || isCurrentUserCreatedBy)) {
    return true;
  }
  return false;
};

// xử lý
export const isEnableBtnHandle = (
  status: string | undefined,
  currentUser: CurrentUserModel | null,
  assignee: string
) => {
  const isRoleAdmin = hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN);
  const isUserHandlingDepartment = currentUser?.email === assignee;
  if (status === STATUS_DOC_IN_ENUM?.PENDING && (isRoleAdmin || isUserHandlingDepartment)) {
    return true;
  }
  return false;
};

// phân công
export const isEnableBtnAssign = (
  status: string | undefined,
  currentUser: CurrentUserModel | null,
  handlingDepartment: Department
) => {
  const isRoleAdmin = hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN);
  const isUserHandlingDepartment =
    currentUser?.departmentCode === handlingDepartment?.code &&
    CODE_OFFICE_MANAGER.includes(currentUser?.positionCode || "");
  if (status === STATUS_DOC_IN_ENUM?.UNASSIGNED && (isRoleAdmin || isUserHandlingDepartment)) {
    return true;
  }
  return false;
};

// phúc đáp
export const isEnableBtnReply = (
  status: STATUS_DOC_IN_ENUM,
  currentUser: CurrentUserModel | null,
  assignee: string | undefined
) => {
  const isAdmin = hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN);
  const isUserHandlingDepartment = currentUser?.email === assignee;
  if (status === STATUS_DOC_IN_ENUM?.PENDING && (isAdmin || isUserHandlingDepartment)) {
    return true;
  }
  return false;
};
