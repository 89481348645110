import { IDENTITY_DOCUMENTS_ENUM, OTHER_DOCUMENTS_ENUM, POSITION_ENUM } from "../enum/personnel.enum";
import { OptionCommon } from "../features/personnel/common/constants/personnel.constant";

export interface FormFilterPersonnelModel {
  keyword?: string | undefined;
  positionId?: string | undefined;
  departmentId?: string | undefined;
}

export interface ViewFilterPersonnelModel {
  // categoryPersonnel?: string| undefined;
}

export interface PersonnelModel {
  id?: number;
  type?: string;
  fullName?: string | undefined | null;
  code?: string | undefined | null;
  dob?: string | undefined | null;
  gender?: string | undefined | null;
  phoneNumber?: string | undefined | null;
  workingAddress?: string | undefined | null;
  maritalStatus?: string | SelectFieldCommonPersonnelModel | object | any;
  nationality?: NationalityModel;
  nationalityId?: string | undefined | null | any;
  ethnic?: string | undefined | null;
  religion?: string | undefined | null;
  nativePlace?: string | undefined | null;
  permanentAddress?: string | undefined | null;
  address?: string | undefined | null;
  email?: string | undefined | null;
  positionId?: string | undefined | null;
  position?: string | undefined | null | object;
  departmentId?: string | undefined | null;
  department?: string | undefined | null | object;
  managerId?: number | undefined | null | string;
  startDate?: string | undefined | null;
  taxCode?: string | undefined | null;
  identityCard?: string | undefined | null;
  idcIssueDate?: string | undefined | null;
  idcIssuePlace?: string | undefined | null;
  passport?: string | undefined | null;
  passportIssueDate?: string | undefined | null;
  passportIssuePlace?: string | undefined | null;
  partyPosition?: string | undefined | null;
  partyJoinDate?: string | undefined | null;
  partyJoinPlace?: string | undefined | null;
  unionPosition?: string | undefined | null;
  unionJoinDate?: string | undefined | null;
  unionJoinPlace?: string | undefined | null;
  rewards?: string | undefined | null;
  disciplines?: string | undefined | null;
  workPermitNumber?: string | undefined | null;
  workPermitFromDate?: string | undefined | null;
  workPermitToDate?: string | undefined | null;
  trcExpiredDate?: string | undefined | null | Date | any;
  temporaryResidenceCard?: string | undefined | null;
  employeeContactInfo?: EmployeeContactInfoModel | null;
  contracts?: ContractModel[] | undefined;
  employeeWorkingHistories?: WorkingProcessModel[] | undefined;
  educations?: LevelModel[] | undefined;
  employeeRelatives?: EmployeeContactInfoModel[] | undefined;
  documents?: DocumentModel[] | undefined;
  contractAddendums?: ContractAddendumModel[] | undefined;
  contractIds?: (number | undefined)[] | undefined | any;
  educationIds?: (number | undefined)[] | undefined | any;
  documentIds?: (number | undefined)[] | undefined | any;
  contractAddendumIds?: (number | undefined)[] | undefined | any;
  employeeManager?: EmployeeManagerModel;
  identity?: IDENTITY_DOCUMENTS_ENUM[] | any;
  selectPosition?: POSITION_ENUM[] | any;
  selectDocOther?: OTHER_DOCUMENTS_ENUM[] | undefined | any;
  status?: any;
  workingUnitInFederation?: string | undefined | null;
  externalWorkingUnit?: string | undefined | null;
  bankAccount?: string | undefined | null;
  [key: string]: any;
}

// GeneralInformation
export interface GeneralInformationModel {
  type?: string;
  fullName?: string | undefined | null;
  code?: string | undefined | null;
  dob?: string | undefined | null | any;
  gender?: string | object | any;
  phoneNumber?: number | undefined | null | any;
  workingAddress?: string | undefined | null;
  maritalStatus?: string | undefined | null;
  nationality?: NationalityModel;
  nationalityId?: string | undefined | null | any;
  ethnic?: string | undefined | null;
  religion?: string | undefined | null;
  nativePlace?: string | undefined | null;
  permanentAddress?: string | undefined | null;
  address?: string | undefined | null;
  email?: string | undefined | null;
  positionId?: string | any;
  departmentId?: string | any;
  managerId?: number | undefined | null | any;
  startDate?: string | undefined | null | Date | any;
  taxCode?: string | undefined | null;
  identityCard?: string | undefined | null;
  idcIssueDate?: string | undefined | null | Date | any;
  idcIssuePlace?: string | undefined | null;
  passport?: string | undefined | null;
  passportIssueDate?: string | undefined | null | Date | any;
  passportIssuePlace?: string | undefined | null;
  partyPosition?: string | undefined | null;
  partyJoinDate?: string | undefined | null | Date | any;
  partyJoinPlace?: string | undefined | null;
  unionPosition?: string | undefined | null;
  unionJoinDate?: string | undefined | null | Date | any;
  unionJoinPlace?: string | undefined | null;
  rewards?: string | undefined | null;
  disciplines?: string | undefined | null;
  relationship?: string | undefined | null;
  workPermitNumber?: string | undefined | null;
  workPermitFromDate?: string | Date | null | undefined | any;
  workPermitToDate?: string | Date | null | undefined | any;
  temporaryResidenceCard?: string | undefined | null;
  trcExpiredDate?: string | Date | null | undefined | any;
  department?: object | any;
  position?: object | any;
  fullNameContactInfo?: string | undefined | null;
  phoneNumberContactInfo?: string | undefined | null;
  relativesContactInfo?: string | undefined | null;
  employeeManager?: EmployeeManagerModel;
  identity?: IDENTITY_DOCUMENTS_ENUM[] | any;
  selectPosition?: POSITION_ENUM[] | any;
  selectDocOther?: OTHER_DOCUMENTS_ENUM[] | undefined | any;
  status?: any;
  workingUnitInFederation?: string | undefined | null;
  externalWorkingUnit?: string | undefined | null;
  bankAccount?: string | undefined | null;
  [key: string]: any;
}

// Hợp đồng
export interface ContractModel {
  id?: number | undefined | null;
  code?: string | undefined;
  type?: string | object | SelectFieldCommonPersonnelModel | any;
  status?: string | undefined;
  startDate?: string | Date | undefined | any;
  endDate?: string | Date | undefined | any;
  fileName?: string | undefined;
  note?: string | undefined;
  allowances?: string | undefined | null | any;
  salary?: string | undefined | null | any;
  monthlyBonus?: string | undefined | null | any;
  contractFile?: FileModel;
  fileTemp?: any;
  formPay?: string | undefined | null | any;
  contractAddendums?: ContractAddendumModel[];
}

// Quá trình công tác
export interface WorkingProcessModel {
  startDate?: string | undefined | null | Date;
  endDate?: string | undefined | null | Date;
  position?: string | undefined;
  workPlace?: string | undefined;
  workContent?: string | undefined;
  workDepartment?: string | undefined;
}

// Trình độ
export interface LevelModel {
  name?: string | undefined;
  issuePlace?: string | undefined;
  rank?: string | undefined;
  issueYear?: string | undefined | Date | any;
  note?: string | undefined;
  fileName?: string | undefined;
  educationFile?: FileModel;
  id?: number | undefined | null;
  fileTemp?: any;
}

// Người thân
export interface RelativesModel {
  fullName?: string | undefined | null;
  relationship?: string | object | any;
  birthYear?: string | undefined | null | Date | any;
  occupation?: string | undefined | null;
  workPlace?: string | undefined | null;
  phoneNumber?: string | undefined | null;
}

// Tài liệu
export interface DocumentModel {
  id?: number | any;
  issuedDate?: string | undefined | null | Date;
  code?: string | undefined | null;
  note?: string | undefined | null;
  fileName?: string | undefined | null;
  fileTemp?: string | File | DocumentFileModel | any;
  documentFile?: DocumentFileModel;
}

export interface DocumentFileModel {
  id?: number | any;
  name?: string | null | undefined | any;
  path?: string | null | undefined;
}

// EmployeeContact
export interface EmployeeContactInfoModel {
  fullName?: string | undefined | null;
  phoneNumber?: string | undefined | null;
  relationship?: string | undefined | null;
}

// Gender
export interface SelectFieldCommonPersonnelModel {
  code?: string | undefined;
  description?: string | undefined;
}

// department
export interface HrDepartmentModel {
  id?: number;
  name?: string | null;
  code?: string | undefined;
  status?: string | undefined | any;
  parentId?: string | null | undefined | number;
}

// position
export interface HrPositionModel {
  id?: number;
  name?: string | null;
  code?: string | undefined;
  status?: any;
}

export interface FilterEmployeeModel {
  keyword: string | null;
  phone?: string | null;
  position?: HrPositionModel | number | null | string;
  department?: HrDepartmentModel | number | null | string;
  startDate?: string | null | Date;
  endDate?: string | null | Date;
  status?: OptionCommon | string | null;
  type?: OptionCommon | string | null;
  taxCode?: string | undefined | null;
  identityCard?: string | undefined | null;
  passport?: string | undefined | null;
}

export interface EmployeeDetailModel {
  id?: number;
  type: string;
  code: string;
  fullName: string;
  email: string;
  position: string;
  department: string;
  phoneNumber: string;
  status: string;
  taxCode?: string | undefined | null;
  identityCard?: string | undefined | null;
  passport?: string | undefined | null;
  startDate?: string | null | Date;
}

// phụ lục hợp đồng
export interface ContractAddendumModel {
  id?: number | undefined | null;
  contractId?: number | undefined | null;
  contractCode?: string | undefined | any;
  code?: string | undefined;
  type?: string | object | SelectFieldCommonPersonnelModel | any;
  status?: string | undefined;
  startDate?: string | Date | undefined | any;
  endDate?: string | Date | undefined | any;
  fileName?: string | undefined;
  note?: string | undefined;
  allowances?: string | undefined | null | any;
  salary?: string | undefined | null | any;
  monthlyBonus?: string | undefined | null | any;
  contractFile?: FileModel;
  fileTemp?: any;
  checkInfChange?: any;
  [key: string]: any;
}

// SUmmary salary
export interface SummarySalaryModel {
  id?: number | undefined | null;
  start?: string | Date | undefined | null;
  enddate?: string | Date | undefined | null;
  salary?: number | undefined | null;
  monthlyBonus?: number | undefined | null;
  allowances?: number | undefined | null;
  [key: string]: any;
}

export interface FileModel {
  id?: number | any;
  name?: string | any;
  file?: string;
  path?: string;
}

export interface EmployeeManagerModel {
  id?: number;
  fullName?: string;
  email?: string;
}

// Nationality model
export interface NationalityModel {
  id?: number;
  name?: string;
}

export enum EmployeeStatusEnum {
  NORMAL = "NORMAL",
  RETIRED = "RETIRED",
  MATERNITY_LEAVE = "MATERNITY_LEAVE",
  INACTIVE_DUE_TO_EMAIL_CHANGE = "INACTIVE_DUE_TO_EMAIL_CHANGE",
}

export enum EmployeeTypeEnum {
  STAFF = "STAFF",
  EXPERT = "EXPERT",
}
