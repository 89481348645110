import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule"
import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import FileSaver from "file-saver"
import { Form, Formik, FormikProps } from 'formik'
import moment from "moment"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import { exportDashboardDocIns } from "../../../api/doc-in.api"
import BoxContainer from '../../../components/common/BoxContainer'
import HeaderPage from '../../../components/common/HeaderPage'
import NotFoundFilterView from "../../../components/common/NotFoundFilterView"
import PaginationPage from "../../../components/common/Pagination"
import ReadMore from '../../../components/common/ReadMore'
import FieldContainer from '../../../components/form/FieldContainer'
import LabelField from '../../../components/form/LabelField'
import ArrowBackIcon from '../../../components/icons/ArrowBackIcon'
import SearchIcon from "../../../components/icons/SearchIcon"
import { UPLOAD_DATE_FORMAT, VIEW_DATE_FORMAT, VIEW_TIME_FORMAT } from "../../../constants/date-time.constant"
import { DOC_IN_MSG, FORM_MSG } from '../../../constants/msg/msg.constant'
import { SIZE_DEFAULT } from "../../../constants/page.constant"
import { DASHBOARD_DOC_IN_VIEW_NAME } from "../../../enum/dashboard.enum"
import { STATUS_DOC_IN_ENUM } from "../../../enum/doc-in.enum"
import { InitialDashboardDocInsForm, ResponseDashboardDocInsModel } from "../../../model/doc-in.model"
import { useAppDispatch, useAppSelector } from "../../../store/hook"
import { header_table_color } from "../../../themes/color"
import { spacing_size } from '../../../themes/size'
import { convertDateToDD_MM_YYYY } from "../../../utils/date.util"
import { nameCodeDocIn } from "../../../utils/doc-in.util"
import { getErrorMsgAxios } from "../../../utils/error.util"
import ChipStatusDocIn from "../../document/document-in/common/ChipStatusDocIn"
import OutOfDateDocInIcon from "../../document/document-in/common/OutOfDateDocInIcon"
import { getDashboardDocInsThunk, setDashboardDocInsForm, setPageDashboardDocIns } from "./redux/doc-in-dashboard.reducer"


const ViewListDashboardDocIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const totalPage = useAppSelector((state) => state.docInDashboard.totalDashboardDocIns);
  const page = useAppSelector((state) => state.docInDashboard.pageDashboardDocIns);
  const dashboardDocInsForm = useAppSelector((state) => state.docInDashboard.dashboardDocInsForm);
  const dashboardDocInsData = useAppSelector((state) => state.docInDashboard.dashboardDocInsData);

  const [reload, setReload] = useState(0);

  const currentDate = new Date();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (reload == 0 && (location?.state?.isReload == null || location?.state?.isReload == true)) {
          resetData()
        } else {
          let body = {
            dashBoardType: dashboardDocInsForm.dashBoardType,
            docBookId: dashboardDocInsForm.docBookId,
            departmentCode: dashboardDocInsForm.departmentCode,
            fromPlace: dashboardDocInsForm.fromPlace,
            code: dashboardDocInsForm.code,
            summary: dashboardDocInsForm.summary,
            category: dashboardDocInsForm.category,
            fromDate: dashboardDocInsForm.fromDate ? moment(dashboardDocInsForm.fromDate).format(UPLOAD_DATE_FORMAT) : null,
            toDate: dashboardDocInsForm.toDate ? moment(dashboardDocInsForm.toDate).format(UPLOAD_DATE_FORMAT) : null
          }

          let pageable = {
            page: page,
            size: SIZE_DEFAULT
          }

          dispatch(getDashboardDocInsThunk({ body, pageable }))
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [page, reload])


  const handleOnSubmit = async (value: InitialDashboardDocInsForm) => {
    let body = {
      dashBoardType: dashboardDocInsForm.dashBoardType,
      docBookId: dashboardDocInsForm.docBookId,
      departmentCode: dashboardDocInsForm.departmentCode,
      fromPlace: value.fromPlace,
      code: value.code,
      summary: value.summary,
      category: dashboardDocInsForm.category,
      fromDate: value?.fromDate ? moment(value?.fromDate).format(UPLOAD_DATE_FORMAT) : null,
      toDate: value?.toDate ? moment(value?.toDate).format(UPLOAD_DATE_FORMAT) : null
    }
    let pageable = {
      page: 0,
      size: SIZE_DEFAULT
    }
    dispatch(getDashboardDocInsThunk({ body, pageable }))

    dispatch(setDashboardDocInsForm({
      ...dashboardDocInsForm,
      fromPlace: value.fromPlace,
      code: value.code,
      summary: value.summary,
      fromDate: value.fromDate,
      toDate: value.toDate
    }))
    dispatch(setPageDashboardDocIns(0));
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setPageDashboardDocIns(page - 1));
  };

  const handleCancel = (formik: FormikProps<InitialDashboardDocInsForm>) => {
    formik.resetForm();
    resetData()
  };

  const navigateToDashboard = () => {
    navigate("/dashboard", {
      state: {
        isReload: false
      }
    })
  }

  const handleExport = async (formik: FormikProps<InitialDashboardDocInsForm>) => {
    let value = formik.values;
    let body = {
      dashBoardType: dashboardDocInsForm.dashBoardType,
      docBookId: dashboardDocInsForm.docBookId,
      departmentCode: dashboardDocInsForm.departmentCode,
      fromPlace: value.fromPlace,
      code: value.code,
      summary: value.summary,
      category: dashboardDocInsForm.category,
      fromDate: value?.fromDate ? moment(value?.fromDate).format(UPLOAD_DATE_FORMAT) : null,
      toDate: value?.toDate ? moment(value?.toDate).format(UPLOAD_DATE_FORMAT) : null
    }
    try {
      const res = await exportDashboardDocIns(body);
      FileSaver.saveAs(res?.data, `bao-cao-danh-sach-cong-van-den-${convertDateToDD_MM_YYYY(currentDate)}.xlsx`);
      toast.success(DOC_IN_MSG.exportListSuccess);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const resetData = () => {
    let body = {
      dashBoardType: dashboardDocInsForm.dashBoardType,
      docBookId: dashboardDocInsForm.docBookId,
      departmentCode: dashboardDocInsForm.departmentCode,
      category: dashboardDocInsForm.category
    }
    let pageable = {
      page: page,
      size: SIZE_DEFAULT
    }
    dispatch(getDashboardDocInsThunk({ body, pageable }))
    dispatch(setDashboardDocInsForm({
      ...dashboardDocInsForm,
      fromPlace: "",
      code: "",
      summary: "",
      fromDate: "",
      toDate: ""
    }))
    dispatch(setPageDashboardDocIns(0))
    setReload(reload + 1)
  }

  return (
    <>
      <Box>
        <Button variant="outlined" color="primary" type="button" startIcon={<ArrowBackIcon />} onClick={navigateToDashboard} >Quay lại</Button>
        <HeaderPage title={`Danh sách công văn đến thuộc: "${dashboardDocInsForm.departmentName}"`}>
          <div></div>
        </HeaderPage>
        <BoxContainer>
          <Formik
            initialValues={dashboardDocInsForm}
            onSubmit={handleOnSubmit}
            // validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {(formik) => (
              <>
                <Form>
                  <Grid container spacing={spacing_size}>
                    <Grid item xs={12} md={6} lg={3}>
                      <FieldContainer
                        fullWidth
                        label="Nơi ban hành văn bản"
                        name="fromPlace"
                        onBlur={formik.handleBlur}
                        type="text"
                        inputProps={{ maxLength: 500 }}
                        value={formik.values.fromPlace}
                        notched={true}
                        placeholder={FORM_MSG.placeholder}
                        variant={"input"}
                        id={"fromPlace"}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FieldContainer
                        fullWidth
                        name="code"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        inputProps={{ maxLength: 255 }}
                        value={formik.values.code}
                        placeholder={FORM_MSG.placeholder}
                        maxRows={12}
                        label="Số và kí hiệu văn bản"
                        notched={true}
                        variant={"input"}
                        id={"code"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FieldContainer
                        fullWidth
                        label="Trích yếu"
                        name="summary"
                        onBlur={formik.handleBlur}
                        type="text"
                        inputProps={{ maxLength: 500 }}
                        value={formik.values.summary}
                        notched={true}
                        placeholder={FORM_MSG.placeholder}
                        variant={"input"}
                        id={"summary"}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <LabelField label='Ngày văn bản đến' name='' />
                      <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Grid item xs={12} md={6} lg={6}>
                          <FieldContainer
                            name="fromDate"
                            onBlur={formik.handleBlur}
                            value={formik.values.fromDate}
                            notched={true}
                            variant={"date"}
                            id={"fromDate"}
                            placeholderText="Từ ngày"
                            maxDate={currentDate}
                          />
                        </Grid>
                        <HorizontalRuleIcon fontSize="small" />
                        <Grid item xs={12} md={6} lg={6}>
                          <FieldContainer
                            name="toDate"
                            onBlur={formik.handleBlur}
                            value={formik.values.toDate}
                            notched={true}
                            variant={"date"}
                            id={"toDate"}
                            placeholderText="Đến ngày"
                            maxDate={currentDate}
                          />
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} justifyContent="center">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={spacing_size}
                        sx={{
                          justifyContent: "end",
                        }}
                      >
                        <Button variant="contained" color="cancel" type="button" onClick={() => handleCancel(formik)}>
                          Huỷ
                        </Button>
                        <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                          Tìm kiếm
                        </Button>
                        <Button variant="outlined" color="primary" type="button" onClick={() => handleExport(formik)}>
                          Xuất Excel
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        </BoxContainer>

        {/* Data */}
        <BoxContainer>
          {(dashboardDocInsData != null && dashboardDocInsData.length > 0 && (
            <Box>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: header_table_color }}>
                      <TableCell sx={{ fontWeight: 600 }}>Loại CV</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Sổ công văn</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Ngày văn bản đến</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Số đến</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Nơi ban hành văn bản</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Số và ký hiệu văn bản</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Trích yếu</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Thời hạn xử lý theo văn bản</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Trạng thái</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dashboardDocInsData?.map((item: ResponseDashboardDocInsModel, index: number) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                          cursor: "pointer",
                        }}
                        key={index}
                      >
                        <TableCell>{nameCodeDocIn(item?.docCategory)}</TableCell>
                        <TableCell>{item?.docBookName}</TableCell>
                        <TableCell>{moment(item?.arrivalDate).format(VIEW_DATE_FORMAT)}</TableCell>
                        <TableCell>{item?.arrivalNbr}</TableCell>
                        <TableCell>{item?.fromPlace}</TableCell>
                        <TableCell>
                          <Link
                            to={`/doc-in/${item.id}`}
                            state={{
                              locationFrom: DASHBOARD_DOC_IN_VIEW_NAME.DASHOARD_DOC_IN_LIST
                            }}
                            style={{ textDecoration: "none", color: "#0084FF" }}
                          >
                            {item?.code}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <ReadMore text={item?.summary} />
                        </TableCell>
                        <TableCell>
                          {item?.deadline && (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                {moment(item?.deadline).format(VIEW_DATE_FORMAT)}
                                {[
                                  STATUS_DOC_IN_ENUM.UNAPPROVED,
                                  STATUS_DOC_IN_ENUM.UNASSIGNED,
                                  STATUS_DOC_IN_ENUM.PENDING,
                                ].includes(item.status as STATUS_DOC_IN_ENUM) &&
                                  new Date(item?.deadline).getTime() < currentDate.getTime() && <OutOfDateDocInIcon />}
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: "0.8rem",
                                  color: "#8A8A8A",
                                }}
                              >
                                {moment(item?.deadline).format(VIEW_TIME_FORMAT)}
                              </Typography>
                            </>
                          )}
                          {!item?.deadline && "-"}
                        </TableCell>
                        <TableCell>
                          <ChipStatusDocIn status={item?.status} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationPage total={totalPage} page={page} onChange={handleChangePage} />
            </Box>
          ))}
          {(dashboardDocInsData == null || dashboardDocInsData.length === 0) && <NotFoundFilterView />}
        </BoxContainer>
      </Box >
    </>
  )
}

export default ViewListDashboardDocIn