import { FC, useEffect, useState } from "react";

// import module
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Select, { components, OptionProps } from "react-select";

// import project
import FieldContainer from "../../../../components/form/FieldContainer";
import { FORM_MSG, USER_MSG } from "../../../../constants/msg/msg.constant";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { getAllAccessRightsApiAsync, setReloadFilter } from "../redux/user.slice";
import { AccessRightModel } from "../../../../model/access-right.model";
import ModalContainer from "../../../../components/modal/ModalContainer";
import { createApi, searchVffApi } from "../../../../api/user.api";
import { Employee } from "../../../../model/user.model";
import { SIZE_DEFAULT } from "../../../../constants/page.constant";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { spacing_size } from "../../../../themes/size";
import { Department, DepartmentHR } from "../../../../model/department.model";
import { getListDepartmentFromHr } from "../../../../api/hr/department.api";
import { buildOptionObject } from "../../../../utils/options.util";
import { ACCESS_RIGHT_ENUM } from "../../../../enum/access-right.enum";

const OptionSelectEmployee = (props: OptionProps<Employee>) => {
  console.log(props);
  return (
    <components.Option {...props}>
      <Typography>{props.data.fullName}</Typography>
      <Typography fontSize={"0.8rem"}>{props.data.email}</Typography>
    </components.Option>
  );
};

interface ModalCreateUserProps {
  open: boolean;
  onClose: () => void;
}

interface InitialValuesForm {
  email: Employee | null;
  fullName: string;
  department: string;
  employeeId: string;
  position: string;
  accessRights: AccessRightModel[];
  dashboardDepartmentCode?: any;
}

const ModalCreateUser: FC<ModalCreateUserProps> = (props) => {
  const { open, onClose } = props;
  const allAccessRights = useAppSelector((state) => state.user.accessRights);
  const reloadFilterUser = useAppSelector((state) => state.user.reloadFilter);
  const [dashboardDepartments, setDashboardDepartments] = useState<Department[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allAccessRights === null && open) {
      dispatch(getAllAccessRightsApiAsync());
    }

    getAllDepartment();
  }, [open]);

  const initialValues: InitialValuesForm = {
    email: null,
    fullName: "",
    department: "",
    employeeId: "",
    position: "",
    accessRights: [],
    dashboardDepartmentCode: null
  };

  const validationSchema = Yup.object({
    email: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Email")),
    accessRights: Yup.array().nullable(),
    // .min(1, FORM_MSG.requiredSelectFieldMsg("Nhóm quyền"))
    // .required(FORM_MSG.requiredSelectFieldMsg("Nhóm quyền")),
  });

  const getAllDepartment = async () => {
    let arrDepartment: Department[] = [];
    const params: any = {
      keyword: "",
    };
    await getListDepartmentFromHr(params.keyword).then((data) => {
      data?.data?.map((item: DepartmentHR) => {
        arrDepartment.push(buildOptionObject(item, "code", "name", `${item?.code} - ${item.name}`));
      });
    });
    setDashboardDepartments(arrDepartment);
  };

  const handleChangeDashboardDepartment = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("dashboardDepartmentCode", e);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (values: InitialValuesForm, formik: FormikHelpers<InitialValuesForm>) => {
    try {
      await createApi(buildBody(values));
      toast.success(USER_MSG.createSuccess);
      dispatch(setReloadFilter(reloadFilterUser + 1));
      onClose();
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const buildBody = (values: InitialValuesForm) => {
    const arrDashboardDepartmentCode = values?.dashboardDepartmentCode
      ? values?.dashboardDepartmentCode?.map((item: Department) => item?.code)
      : null;

    let formatDashboardDepartmentCode = null;
    if (arrDashboardDepartmentCode != null) {
      formatDashboardDepartmentCode = arrDashboardDepartmentCode.toString();
    }

    return {
      email: values.email?.email || "",
      accessRights: values?.accessRights?.map((item) => item.code) || [],
      dashboardDepartmentCode: formatDashboardDepartmentCode == "" ? null : formatDashboardDepartmentCode
    }
  }

  const loadEmployee = (inputValue: string, callback: (options: Employee[]) => void) => {
    const MIN_LENGTH_INPUT = 3;
    if (inputValue && inputValue.length >= MIN_LENGTH_INPUT) {
      searchVffApi(inputValue || "", { page: 0, size: SIZE_DEFAULT })
        .then((res) => {
          if (res.data) {
            callback(res.data);
          } else {
            callback([]);
          }
        })
        .catch((err) => {
          callback([]);
        });
    } else {
      callback([]);
    }
  };

  const checkIsDisabledDepartmentCode = (values: InitialValuesForm) => {
    return values.accessRights?.find(accessRight => accessRight.code == ACCESS_RIGHT_ENUM.DASHBOARD) == null
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Thêm mới người dùng"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off" style={{ padding: "0px 1px" }}>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12}>
                  <FieldContainer
                    name="email"
                    onBlur={() => formik.handleBlur("email")}
                    onChange={async (e: Employee) => {
                      await formik.setFieldValue("email", e);
                      await formik.setFieldValue("fullName", e?.fullName || "");
                      await formik.setFieldValue("department", e?.departmentName || "");
                      await formik.setFieldValue("employeeId", e?.employeeId || "");
                      await formik.setFieldValue("position", e?.position || "");
                    }}
                    components={{ Option: OptionSelectEmployee }}
                    value={formik.values.email}
                    placeholder="Nhập họ và tên/Email để tìm kiếm"
                    label="Email"
                    variant={"async-react-select"}
                    getOptionLabel={(option: Employee) => option.email}
                    getOptionValue={(option: Employee) => option.email}
                    id={"email"}
                    loadOptions={loadEmployee}
                    isClearable
                    isRequired={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="fullName"
                    type="text"
                    value={formik.values.fullName}
                    placeholder=""
                    label="Họ và tên"
                    notched={true}
                    variant={"input"}
                    id={"fullName"}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="department"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.department}
                    placeholder=""
                    maxRows={12}
                    label="Bộ phận công tác"
                    notched={true}
                    variant={"input"}
                    id={"department"}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="employeeId"
                    type="text"
                    value={formik.values.employeeId}
                    placeholder=""
                    maxRows={12}
                    label="Mã nhân viên"
                    notched={true}
                    variant={"input"}
                    id={"employeeId"}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="search"
                    type="text"
                    value={formik.values.position}
                    placeholder=""
                    maxRows={12}
                    label="Chức vụ"
                    notched={true}
                    variant={"input"}
                    id={"search"}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="accessRights"
                    onBlur={formik.handleBlur}
                    onChange={(e: any) => {
                      formik.setFieldValue("accessRights", e);
                    }}
                    value={formik.values.accessRights}
                    placeholder="Nhập chọn nhóm quyền"
                    label="Nhóm quyền"
                    variant={"react-select"}
                    id={"accessRights"}
                    isMulti
                    getOptionLabel={(option: AccessRightModel) => option.text}
                    getOptionValue={(option: AccessRightModel) => option.code}
                    options={allAccessRights || []}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="dashboardDepartmentCode"
                    isDisabled={checkIsDisabledDepartmentCode(formik.values)}
                    onBlur={formik.handleBlur}
                    onChange={handleChangeDashboardDepartment(formik)}
                    type="text"
                    inputProps={{ maxLength: 12 }}
                    value={formik.values.dashboardDepartmentCode}
                    placeholder="Chọn thông tin"
                    label="Thông tin Bộ phận được phép xem số liệu thống kê của Phân hệ công văn"
                    variant={"react-select"}
                    id={"dashboardDepartmentCode"}
                    isClearable
                    options={dashboardDepartments}
                    menuPlacement="top"
                    isMulti
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="cancel"
                      type="button"
                      onClick={() => {
                        formik.resetForm();
                      }}
                    >
                      Huỷ
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Thêm mới
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalCreateUser;
