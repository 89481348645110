import React, { FC, useRef, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { spacing_size } from "../../../themes/size";
import {
  ContractAddendumModel,
  DocumentModel,
  GeneralInformationModel,
  LevelModel,
  PersonnelModel,
  RelativesModel,
  SelectFieldCommonPersonnelModel,
  WorkingProcessModel,
} from "../../../model/personnel.model";
import { StatusHR, TAB_SELECT_PERSONNEL_ENUM } from "../../../enum/personnel.enum";
import Contract from "./Contract";
import WorkingProcess from "./WorkingProcess";
import Level from "./Level";
import Relatives from "./Relatives";
import moment from "moment";
import { ContractModel } from "../../../model/personnel.model";
import { UPLOAD_DATE_FORMAT } from "../../../constants/date-time.constant";
import GeneralBtnSubmitIcon from "../../../components/icons/GeneralBtnSubmitIcon";
import Select from "react-select";
import Document from "./Document";
import GeneralInformation from "./GeneralInfomation";
import {
  genIdentity,
  genSelectDocOther,
  genSelectPosition,
  getNextStepTabPersonnel,
  handleUpdateHr,
  isInValidValueUploadGI,
} from "../common/util/personnel.util";
import { formatDateTime } from "../../../utils/data-time.util";
import { useLocation, useNavigate, Location } from "react-router-dom";

interface StepperUpdateProps {
  personnelDetail: PersonnelModel | null;
  id: number;
}

const StepperUpdate: FC<StepperUpdateProps> = (props) => {
  const { personnelDetail, id } = props;

  const navigate = useNavigate();
  const location: Location = useLocation();

  const genderObj = personnelDetail?.gender
    ? { code: personnelDetail?.gender, description: personnelDetail?.genderName }
    : null;
  const maritalStatusObj = personnelDetail?.maritalStatus
    ? { code: personnelDetail?.maritalStatus, description: personnelDetail?.maritalStatusName }
    : null;
  const statusHrObj = personnelDetail?.status
    ? { code: personnelDetail?.status, description: personnelDetail?.statusName }
    : null;

  const initialValuesUpload: PersonnelModel = {
    id,
    type: personnelDetail?.type,
    fullName: personnelDetail?.fullName || "",
    code: personnelDetail?.code || "",
    dob: formatDateTime(personnelDetail?.dob, UPLOAD_DATE_FORMAT),
    gender: personnelDetail?.gender || null,
    phoneNumber: personnelDetail?.phoneNumber || "",
    workingAddress: personnelDetail?.workingAddress || "",
    maritalStatus: personnelDetail?.maritalStatus || null,
    nationalityId: personnelDetail?.nationalityId || "",
    ethnic: personnelDetail?.ethnic || "",
    religion: personnelDetail?.religion || "",
    nativePlace: personnelDetail?.nativePlace || "",
    permanentAddress: personnelDetail?.permanentAddress || "",
    address: personnelDetail?.address || "",
    email: personnelDetail?.email || "",
    positionId: personnelDetail?.positionId || "",
    departmentId: personnelDetail?.departmentId || "",
    managerId: personnelDetail?.managerId || "",
    startDate: formatDateTime(personnelDetail?.startDate, UPLOAD_DATE_FORMAT),
    taxCode: personnelDetail?.taxCode || "",
    identityCard: personnelDetail?.identityCard || "",
    idcIssueDate: formatDateTime(personnelDetail?.idcIssueDate, UPLOAD_DATE_FORMAT),
    idcIssuePlace: personnelDetail?.idcIssuePlace || "",
    passport: personnelDetail?.passport || "",
    passportIssueDate: formatDateTime(personnelDetail?.passportIssueDate, UPLOAD_DATE_FORMAT),
    passportIssuePlace: personnelDetail?.passportIssuePlace || "",
    partyPosition: personnelDetail?.partyPosition || "",
    partyJoinDate: formatDateTime(personnelDetail?.partyJoinDate, UPLOAD_DATE_FORMAT),
    partyJoinPlace: personnelDetail?.partyJoinPlace || "",
    unionPosition: personnelDetail?.unionPosition || "",
    unionJoinDate: formatDateTime(personnelDetail?.unionJoinDate, UPLOAD_DATE_FORMAT),
    unionJoinPlace: personnelDetail?.unionJoinPlace || "",
    rewards: personnelDetail?.rewards || "",
    disciplines: personnelDetail?.disciplines || "",
    workPermitNumber: personnelDetail?.workPermitNumber || "",
    workPermitFromDate: formatDateTime(personnelDetail?.workPermitFromDate, UPLOAD_DATE_FORMAT),
    workPermitToDate: formatDateTime(personnelDetail?.workPermitToDate, UPLOAD_DATE_FORMAT),
    temporaryResidenceCard: personnelDetail?.temporaryResidenceCard || "",
    trcExpiredDate: formatDateTime(personnelDetail?.trcExpiredDate, UPLOAD_DATE_FORMAT),
    employeeContactInfo:
      personnelDetail?.employeeContactInfo !== null
        ? {
            fullName: personnelDetail?.employeeContactInfo?.fullName || null,
            phoneNumber: personnelDetail?.employeeContactInfo?.phoneNumber || null,
            relationship: personnelDetail?.employeeContactInfo?.relationship || null,
          }
        : null,
    contracts: [],
    contractAddendums: [],
    employeeWorkingHistories: personnelDetail?.employeeWorkingHistories,
    educations: [],
    documents: [],
    employeeRelatives: personnelDetail?.employeeRelatives,
    contractIds: [],
    contractAddendumIds: [],
    educationIds: [],
    documentIds: [],
    status: personnelDetail?.status || null,
    workingUnitInFederation: personnelDetail?.workingUnitInFederation || "",
    externalWorkingUnit: personnelDetail?.externalWorkingUnit || "",
    bankAccount: personnelDetail?.bankAccount || "",
  };

  const initialValuesViewGeneral: GeneralInformationModel = {
    type: personnelDetail?.type,
    fullName: personnelDetail?.fullName,
    code: personnelDetail?.code,
    dob: personnelDetail?.dob ? moment(personnelDetail?.dob).toDate() : "",
    gender: genderObj as any,
    phoneNumber: personnelDetail?.phoneNumber,
    workingAddress: personnelDetail?.workingAddress,
    maritalStatus: maritalStatusObj as any,
    nationalityId: personnelDetail?.nationality,
    ethnic: personnelDetail?.ethnic,
    religion: personnelDetail?.religion,
    nativePlace: personnelDetail?.nativePlace,
    permanentAddress: personnelDetail?.permanentAddress,
    address: personnelDetail?.address,
    email: personnelDetail?.email,
    positionId: personnelDetail?.position,
    departmentId: personnelDetail?.department,
    managerId: personnelDetail?.employeeManager,
    startDate: personnelDetail?.startDate ? moment(personnelDetail?.startDate).toDate() : "",
    taxCode: personnelDetail?.taxCode,
    identityCard: personnelDetail?.identityCard,
    idcIssueDate: personnelDetail?.idcIssueDate ? moment(personnelDetail?.idcIssueDate).toDate() : "",
    idcIssuePlace: personnelDetail?.idcIssuePlace,
    passport: personnelDetail?.passport,
    passportIssueDate: personnelDetail?.passportIssueDate ? moment(personnelDetail?.passportIssueDate).toDate() : "",
    passportIssuePlace: personnelDetail?.passportIssuePlace,
    partyPosition: personnelDetail?.partyPosition,
    partyJoinDate: personnelDetail?.partyJoinDate ? moment(personnelDetail?.partyJoinDate).toDate() : "",
    partyJoinPlace: personnelDetail?.partyJoinPlace,
    unionPosition: personnelDetail?.unionPosition,
    unionJoinDate: personnelDetail?.unionJoinDate ? moment(personnelDetail?.unionJoinDate).toDate() : "",
    unionJoinPlace: personnelDetail?.unionJoinPlace,
    rewards: personnelDetail?.rewards,
    disciplines: personnelDetail?.disciplines,
    workPermitNumber: personnelDetail?.workPermitNumber,
    workPermitFromDate: personnelDetail?.workPermitFromDate ? moment(personnelDetail?.workPermitFromDate).toDate() : "",
    workPermitToDate: personnelDetail?.workPermitToDate ? moment(personnelDetail?.workPermitToDate).toDate() : "",
    temporaryResidenceCard: personnelDetail?.temporaryResidenceCard,
    trcExpiredDate: personnelDetail?.trcExpiredDate ? moment(personnelDetail?.trcExpiredDate).toDate() : "",
    fullNameContactInfo: personnelDetail?.employeeContactInfo?.fullName,
    phoneNumberContactInfo: personnelDetail?.employeeContactInfo?.phoneNumber,
    relativesContactInfo: personnelDetail?.employeeContactInfo?.relationship,
    workingUnitInFederation: personnelDetail?.workingUnitInFederation,
    externalWorkingUnit: personnelDetail?.externalWorkingUnit,
    bankAccount: personnelDetail?.bankAccount,
    identity: genIdentity(personnelDetail?.passport || "", personnelDetail?.identityCard || ""),
    selectPosition: genSelectPosition(personnelDetail?.partyPosition || "", personnelDetail?.unionPosition || ""),
    selectDocOther: genSelectDocOther(
      personnelDetail?.workPermitNumber || "",
      personnelDetail?.temporaryResidenceCard || ""
    ),
  };

  const btnCurrentFormActiveRefNext = useRef<HTMLButtonElement>(null);
  const btnSubmitCurrentFormActiveRef = useRef<HTMLButtonElement>(null);

  const arrContractAddendum: ContractAddendumModel[] | null | undefined | any = personnelDetail?.contracts
    ?.map((item: ContractAddendumModel) => item?.contractAddendums)
    .flat();

  const [currentTab, setCurrentTab] = useState<string>(TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION);
  const [valueUploads, setValuesUploads] = useState<PersonnelModel | null>(initialValuesUpload);
  const [valueStatus, setValueStatus] = useState<any>(statusHrObj);

  const [fileContractsUpload, setFileContractsUpload] = React.useState<File[]>([]);
  const [newFileContractsUpload, setNewFileContractsUpload] = React.useState<File[]>([]);
  const [fileContractsAddendumUpload, setFileContractsAddendumUpload] = React.useState<File[]>([]);
  const [newFileContractsAddendumUpload, setNewFileContractsAddendumUpload] = React.useState<File[]>([]);
  const [fileEducationsUpload, setFileEducationsUpload] = React.useState<File[]>([]);
  const [fileDocumentsUpload, setFileDocumentsUpload] = useState<File[]>([]);

  const [valueFormLeadersAndDepartments, setValueFormLeadersAndDepartments] = useState<PersonnelModel | null>(
    initialValuesViewGeneral
  );
  const [valueFormExpertsAndCoachingStaff, setValueFormExpertsAndCoachingStaff] = useState<PersonnelModel | null>(
    initialValuesViewGeneral
  );
  const [valueFormOperatingParts, setValueFormOperatingParts] = useState<PersonnelModel | null>(
    initialValuesViewGeneral
  );
  const [valueFormTheSubordinateUnits, setValueFormTheSubordinateUnits] = useState<PersonnelModel | null>(
    initialValuesViewGeneral
  );
  const [valuesViewGeneralInformation, setValuesViewGeneralInformation] = useState<PersonnelModel | null>(
    initialValuesViewGeneral
  );
  const [valueFormOther, setValueFormOther] = useState<GeneralInformationModel | null>(initialValuesViewGeneral);
  const [valuesContract, setValuesContract] = useState<ContractModel[] | null | undefined>(personnelDetail?.contracts);
  const [valuesContractAddendum, setValuesContractAddendum] = useState<ContractModel[] | null | undefined>(
    arrContractAddendum
  );
  const [valuesWorkingProcess, setValuesWorkingProcess] = useState<WorkingProcessModel[] | null | undefined>(
    personnelDetail?.employeeWorkingHistories
  );
  const [valuesLevel, setValuesLevel] = useState<LevelModel[] | null | undefined>(personnelDetail?.educations);
  const [valuesRelatives, setValuesRelatives] = useState<RelativesModel[] | null | undefined>(
    personnelDetail?.employeeRelatives
  );
  const [valuesDocuments, setValuesDocuments] = useState<DocumentModel[] | null | undefined>(
    personnelDetail?.documents
  );

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TAB_SELECT_PERSONNEL_ENUM) => {
    btnCurrentFormActiveRefNext.current?.click();
    setCurrentTab(newValue);
  };

  const handleNextStepper = (currentTab: string) => {
    btnCurrentFormActiveRefNext.current?.click();
    setCurrentTab(getNextStepTabPersonnel(currentTab, 1));
  };

  const handlePreStepper = (currentTab: string) => {
    btnCurrentFormActiveRefNext.current?.click();
    setCurrentTab(getNextStepTabPersonnel(currentTab, -1));
  };

  const handleChangeStatus = (e: any) => {
    setValueStatus(e);
    setValuesUploads({
      ...valueUploads,
      status: e.code,
    });
  };

  const handleSubmit = () => {
    switch (currentTab) {
      case TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION: {
        btnSubmitCurrentFormActiveRef.current?.click();
        return;
      }
      case TAB_SELECT_PERSONNEL_ENUM.CONTRACT:
      case TAB_SELECT_PERSONNEL_ENUM.DOCUMENT:
      case TAB_SELECT_PERSONNEL_ENUM.LEVEL:
      case TAB_SELECT_PERSONNEL_ENUM.RELATIVE:
      case TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS: {
        if (isInValidValueUploadGI(valueUploads)) {
          setCurrentTab(TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION);
          setTimeout(() => {
            btnSubmitCurrentFormActiveRef.current?.click();
          }, 500);
          return;
        }

        handleUpdateHr(
          valueUploads,
          fileContractsUpload,
          fileEducationsUpload,
          fileContractsAddendumUpload,
          fileDocumentsUpload,
          newFileContractsUpload,
          newFileContractsAddendumUpload,
          navigate,
          location
        );
        break;
      }

      default:
        break;
    }

    // let bodyFormData = new FormData();
    // switch (valueUploads?.type) {
    //   case "TYPE_PERSONNEL_ENUM.STAFF":
    //     break;
    //   case "TYPE_PERSONNEL_ENUM.EXPERT":
    //     break;
    //   case "TYPE_PERSONNEL_ENUM.VIP":
    //     break;
    //   case TYPE_PERSONNEL_ENUM.OTHER:
    //     if (validationOtherSchema.isValidSync(valueUploads)) {
    //       setCurrentTab(NUMBER_ENUM.ONE);
    //       setTimeout(() => {
    //         // toast.error("Có lỗi ở form thông tin chung !");
    //         btnSubmitInfomationOtherRef.current?.click();
    //       }, 500);
    //       return;
    //     }
    //     break;
    //   default:
    //     break;
    // }
    // bodyFormData.append(
    //   "data",
    //   new Blob([JSON.stringify(valueUploads)], {
    //     type: "application/json",
    //   })
    // );
    // for (let index = 0; index < fileContractsUpload?.length; index++) {
    //   const element = fileContractsUpload[index];
    //   bodyFormData.append("fileContracts", element);
    // }
    // for (let index = 0; index < fileEducationsUpload?.length; index++) {
    //   const element = fileEducationsUpload[index];
    //   bodyFormData.append("fileEducations", element);
    // }
    // for (let index = 0; index < fileContractsAddendumUpload?.length; index++) {
    //   const element = fileContractsAddendumUpload[index];
    //   bodyFormData.append("fileContractAddendum", element);
    // }
    // for (let index = 0; index < fileDocumentsUpload?.length; index++) {
    //   const element = fileDocumentsUpload[index];
    //   bodyFormData.append("fileDocument", element);
    // }
    // if (currentTab === NUMBER_ENUM.ONE && valueUploads?.type === "TYPE_PERSONNEL_ENUM.STAFF") {
    //   btnSubmitInfomationStaffRef.current?.click();
    // } else if (currentTab === NUMBER_ENUM.ONE && valueUploads?.type === "TYPE_PERSONNEL_ENUM.EXPERT") {
    //   btnSubmitInfomationExpertRef.current?.click();
    // } else if (currentTab === NUMBER_ENUM.ONE && valueUploads?.type === "TYPE_PERSONNEL_ENUM.VIP") {
    //   btnSubmitInfomationVipRef.current?.click();
    // } else if (currentTab === NUMBER_ENUM.ONE && valueUploads?.type === TYPE_PERSONNEL_ENUM.OTHER) {
    //   btnSubmitInfomationOtherRef.current?.click();
    // } else {
    //   if (valueUploads?.identity?.length === 0) {
    //     toast.error("Có lỗi ở form thông tin chung. Bắt buộc phải chọn một trong hai loại giấy tờ tuỳ thân!");
    //   } else if (valueUploads?.type === "TYPE_PERSONNEL_ENUM.EXPERT" && valueUploads?.selectDocOther?.length === 0) {
    //     toast.error("Có lỗi ở form thông tin chung. Bắt buộc phải chọn một trong hai thông tin loại giấy tờ!");
    //   } else {
    //     updateHrApi(bodyFormData)
    //       .then((res) => {
    //         if (res) {
    //           navigate("/personnel");
    //           toast.success(HR_MSG.updateSuccess);
    //         }
    //       })
    //       .catch((err) => {
    //         toast.error(getErrorMsgAxios(err));
    //       });
    //   }
    // }
  };

  return (
    <>
      <TabContext value={currentTab}>
        {/* header */}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={spacing_size}
          justifyContent="space-between"
          alignItems="center"
        >
          {/* tab list */}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList aria-label="lab API tabs example" onChange={handleChangeTab} variant="scrollable">
              <Tab
                label="Thông tin chung"
                value={TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION}
                sx={{
                  fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION ? "bold" : "normal",
                  "&.Mui-selected": { fontWeight: "bold" },
                  fontSize: "1.2rem",
                }}
              />
              <Tab
                label="Hợp đồng"
                value={TAB_SELECT_PERSONNEL_ENUM.CONTRACT}
                sx={{
                  fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.CONTRACT ? "bold" : "normal",
                  "&.Mui-selected": { fontWeight: "bold" },
                  fontSize: "1.2rem",
                }}
              />
              <Tab
                label="Quá trình công tác"
                value={TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS}
                sx={{
                  fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS ? "bold" : "normal",
                  "&.Mui-selected": { fontWeight: "bold" },
                  fontSize: "1.2rem",
                }}
              />
              <Tab
                label="Trình độ"
                value={TAB_SELECT_PERSONNEL_ENUM.LEVEL}
                sx={{
                  fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.LEVEL ? "bold" : "normal",
                  "&.Mui-selected": { fontWeight: "bold" },
                  fontSize: "1.2rem",
                }}
              />
              <Tab
                label="Người thân"
                value={TAB_SELECT_PERSONNEL_ENUM.RELATIVE}
                sx={{
                  fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.RELATIVE ? "bold" : "normal",
                  "&.Mui-selected": { fontWeight: "bold" },
                  fontSize: "1.2rem",
                }}
              />
              <Tab
                label="Tài liệu"
                value={TAB_SELECT_PERSONNEL_ENUM.DOCUMENT}
                sx={{
                  fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.DOCUMENT ? "bold" : "normal",
                  "&.Mui-selected": { fontWeight: "bold" },
                  fontSize: "1.2rem",
                }}
              />
            </TabList>
          </Box>

          {/* select status */}
          <Select
            value={valueStatus}
            onChange={handleChangeStatus}
            options={StatusHR}
            getOptionLabel={(option: SelectFieldCommonPersonnelModel) => `${option.description}`}
            getOptionValue={(option: SelectFieldCommonPersonnelModel) => `${option.code}`}
          />
        </Stack>

        {/* all tab panel */}
        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION}>
          <GeneralInformation
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            valuesViewGeneralInformation={valuesViewGeneralInformation}
            setValuesViewGeneralInformation={setValuesViewGeneralInformation}
            valueFormOther={valueFormOther}
            setValueFormOther={setValueFormOther}
            valueFormLeadersAndDepartments={valueFormLeadersAndDepartments}
            setValueFormLeadersAndDepartments={setValueFormLeadersAndDepartments}
            valueFormExpertsAndCoachingStaff={valueFormExpertsAndCoachingStaff}
            setValueFormExpertsAndCoachingStaff={setValueFormExpertsAndCoachingStaff}
            valueFormOperatingParts={valueFormOperatingParts}
            setValueFormOperatingParts={setValueFormOperatingParts}
            valueFormTheSubordinateUnits={valueFormTheSubordinateUnits}
            setValueFormTheSubordinateUnits={setValueFormTheSubordinateUnits}
            fileContractsUpload={fileContractsUpload}
            fileEducationsUpload={fileEducationsUpload}
            fileContractsAddendumUpload={fileContractsAddendumUpload}
            fileDocumentsUpload={fileDocumentsUpload}
            btnCurrentFormActiveNextRef={btnCurrentFormActiveRefNext}
            btnSubmitCurrentFormActiveRef={btnSubmitCurrentFormActiveRef}
            location={location}
            newFileContractsUpload={newFileContractsUpload}
            newFileContractsAddendumUpload={newFileContractsAddendumUpload}
          />
        </TabPanel>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.CONTRACT}>
          <Contract
            id={id}
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            setFileContractsUpload={setFileContractsUpload}
            valuesContract={valuesContract}
            setValuesContract={setValuesContract}
            valuesContractAddendum={valuesContractAddendum}
            setValuesContractAddendum={setValuesContractAddendum}
            btnSubmitContractRef={btnSubmitCurrentFormActiveRef}
            fileContractsAddendumUpload={fileContractsAddendumUpload}
            fileContractsUpload={fileContractsUpload}
            setFileContractsAddendumUpload={setFileContractsAddendumUpload}
            newFileContractsUpload={newFileContractsUpload}
            newFileContractsAddendumUpload={newFileContractsAddendumUpload}
            setNewFileContractsUpload={setNewFileContractsUpload}
            setNewFileContractsAddendumUpload={setNewFileContractsAddendumUpload}
          />
        </TabPanel>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS}>
          <WorkingProcess
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            valuesWorkingProcess={valuesWorkingProcess}
            setValuesWorkingProcess={setValuesWorkingProcess}
          />
        </TabPanel>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.LEVEL}>
          <Level
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            setFileEducationsUpload={setFileEducationsUpload}
            valuesLevel={valuesLevel}
            setValuesLevel={setValuesLevel}
            fileEducationsUpload={fileEducationsUpload}
          />
        </TabPanel>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.RELATIVE}>
          <Relatives
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            valuesRelatives={valuesRelatives}
            setValuesRelatives={setValuesRelatives}
          />
        </TabPanel>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.DOCUMENT}>
          <Document
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            fileDocumentsUpload={fileDocumentsUpload}
            setFileDocumentsUpload={setFileDocumentsUpload}
            valuesDocuments={valuesDocuments}
            setValuesDocuments={setValuesDocuments}
          />
        </TabPanel>
      </TabContext>

      {/* action */}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={spacing_size}
        sx={{
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
          padding: "0 24px",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => handlePreStepper(currentTab)}
          sx={{ visibility: currentTab !== TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION ? "initial" : "hidden" }}
        >
          Quay lại
        </Button>
        <Button
          variant="contained"
          color="orange"
          type="submit"
          sx={{ color: "#000" }}
          startIcon={<GeneralBtnSubmitIcon />}
          onClick={handleSubmit}
        >
          Lưu thông tin nhân sự
        </Button>
        <Button
          sx={{ visibility: currentTab !== TAB_SELECT_PERSONNEL_ENUM.DOCUMENT ? "initial" : "hidden" }}
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => handleNextStepper(currentTab)}
        >
          Tiếp tục
        </Button>
      </Stack>
    </>
  );
};

export default StepperUpdate;
