import React, { useRef } from "react";
import ReactSelect from "react-select";
import { main_color } from "../../themes/color";
import { ErrorMessage, Field } from "formik";
import TextError from "./TextError";
import LabelField from "./LabelField";

export const MultiReactSelectField = (props: any) => {
  const { label, name, tooltip, isRequired, id, options, isDisabled, placeholder, ...rest } = props;
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: "Tất cả"
  };

  const isSelectAllSelected = () => {
    return valueRef.current?.length === props.options?.length;
  }

  const isOptionSelected = (option: any) =>
    valueRef.current.some((data: { value: any }) => data.value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChange = (newValue: any, actionMeta: any) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter((data: { value: any }) => data.value !== option.value),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <ReactSelect
        placeholder={placeholder}
        isOptionSelected={isOptionSelected}
        options={getOptions()}
        value={getValue()}
        onChange={onChange}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isMulti
        styles={{
          menuPortal: (base: any) => ({
            ...base,
            zIndex: 1301,
          }),
          control: (base) => ({
            ...base,
            borderColor: "rgba(0,0,0,0.23)",

            // outline: `2px solid ${main_color}`,
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              borderColor: main_color,
            },
            "&:focus": {
              boxShadow: "none",
              borderColor: main_color,
            },
          }),
        }}
      />
      <ErrorMessage component={TextError} name={name} />
    </>

  );
};