import { useEffect, useRef, useState } from "react";

// import module
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import * as Yup from "yup";

// import project
import Logo from "../../components/logo/Logo";
import { Form, Formik, FormikHelpers } from "formik";
import FieldContainer from "../../components/form/FieldContainer";
import ForgotPwModal from "./ForgotPwModal";
import { loginApi } from "../../api/auth.api";
import { useAppDispatch } from "../../store/hook";
import { KEY_STORES } from "../../constants/key-store.constant";
import { getCurrentUserApiAsync, setCurrentUser } from "./redux/auth.slice";
import { getErrorMsgAction, getErrorMsgAxios } from "../../utils/error.util";
import { useNavigate } from "react-router-dom";
import { isRejected } from "@reduxjs/toolkit";
import { AUTH_MSG, FORM_MSG } from "../../constants/msg/msg.constant";
import { main_color } from "../../themes/color";
import { PASSWORD_REGEX } from "../../constants/regex.constant";
import { AUTH_TITLE } from "../../constants/title-page.constant";
import { spacing_size } from "../../themes/size";
import OtpModal from "./OtpModal";

interface InitialValuesForm {
  account: string;
  password: string;
}

const Login = () => {
  document.title = AUTH_TITLE.login;
  const FIRST_ROUTER = "/doc-in";

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [openModalForgotPW, setOpenModalForgotPW] = useState(false);
  const [openModalOtp, setOpenModalOtp] = useState(false);
  const [emailForgot, setEmailForgot] = useState("");
  const [otpExpirationMinutes, setOtpExpirationMinutes] = useState(0);
  const dispatch = useAppDispatch();
  const accountRef = useRef<any>();

  useEffect(() => {
    localStorage.clear();
    dispatch(setCurrentUser(null));

    setTimeout(() => {
      accountRef?.current?.focus();
    }, 0);
  }, []);

  const labelFields = {
    account: "Tên/email đăng nhập",
    password: "Mật khẩu",
  };

  const initialValues: InitialValuesForm = {
    account: "",
    password: "",
  };

  const validationSchema = Yup.object({
    account: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg(labelFields.account)),
    password: Yup.string()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg(labelFields.password))
      .min(8, FORM_MSG.minLengthFieldMsg(8))
      .matches(PASSWORD_REGEX, FORM_MSG.patternFieldMsg(labelFields.password)),
  });

  const handleOnSubmit = async (value: InitialValuesForm, formikHelper: FormikHelpers<InitialValuesForm>) => {
    try {
      const loginRes = await loginApi(value.account, value.password);
      localStorage.setItem(KEY_STORES.accessToken, loginRes.data);
      const currentUserApiAsyncRes = await dispatch(getCurrentUserApiAsync());
      if (isRejected(currentUserApiAsyncRes)) {
        toast.error(getErrorMsgAction(currentUserApiAsyncRes));
        return;
      }
      navigate(FIRST_ROUTER);
      toast.success(AUTH_MSG.loginSuccess);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const handleOnCloseModalForgotPW = (email = "", otpExpirationMinutes = 0) => {
    setEmailForgot(email);
    setOtpExpirationMinutes(otpExpirationMinutes);
    setOpenModalForgotPW(false);
    if (email) {
      setTimeout(() => {
        setOpenModalOtp(true);
      }, 0);
    }
  };

  return (
    <>
      {openModalForgotPW && <ForgotPwModal openModal={openModalForgotPW} onCloseModal={handleOnCloseModalForgotPW} />}
      {openModalOtp && (
        <OtpModal
          openModal={openModalOtp}
          email={emailForgot}
          otpExpirationMinutes={otpExpirationMinutes}
          onCloseModal={() => {
            setOpenModalOtp(false);
          }}
        />
      )}
      <Box
        component="div"
        sx={{
          background: main_color,
          height: "100vh",
          position: "relative",
          padding: 2,
        }}
      >
        <Card
          sx={{
            position: {
              xs: "relative",
              md: "absolute",
            },
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "100%",
              md: "556px",
            },
            margin: {
              xs: "auto",
              md: 0,
            },
            maxWidth: "100vw",
          }}
        >
          <CardContent>
            <div style={{ textAlign: "center", margin: "16px" }}>
              <Logo type="dark" />
            </div>
            <Typography
              variant="body1"
              sx={{ fontSize: 32, fontWeight: "bold", color: "#3E334E" }}
              color="text.secondary"
              gutterBottom
            >
              Chào mừng,
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Hãy điền tên và mật khẩu để hoàn tất đăng nhập.
            </Typography>
            <Formik
              initialValues={initialValues}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={true}
              initialErrors={{}}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <Form autoComplete="off" noValidate>
                    <Grid container spacing={spacing_size}>
                      <Grid item xs={12}>
                        <FieldContainer
                          fullWidth
                          name="account"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type="text"
                          inputProps={{ maxLength: 255, ref: accountRef }}
                          value={formik.values.account}
                          placeholder=""
                          margin="dense"
                          maxRows={12}
                          label={labelFields.account}
                          notched={true}
                          isRequired
                          variant={"input"}
                          autoComplete="off"
                          id={"account"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FieldContainer
                          fullWidth
                          name="password"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type={showPassword ? "text" : "password"}
                          inputProps={{ maxLength: 255 }}
                          value={formik.values.password}
                          placeholder=""
                          margin="dense"
                          maxRows={12}
                          label={labelFields.password}
                          notched={true}
                          isRequired
                          variant={"input"}
                          id={"password"}
                          autoComplete="off"
                          endAdornment={
                            showPassword ? (
                              <VisibilityOffIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setShowPassword(false);
                                }}
                              />
                            ) : (
                              <VisibilityIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setShowPassword(true);
                                }}
                              />
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            cursor: "pointer",
                            textAlign: "right",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            setOpenModalForgotPW(true);
                          }}
                        >
                          Quên mật khẩu?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" color="primary" fullWidth type="submit">
                          Đăng nhập
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                </>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Login;
