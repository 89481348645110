import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "../../../components/icons/ArrowBackIcon";
import { FROM_DASHBOARD_TASK } from "../../../constants/navigate.constant";
import { ALL_ENUM } from "../../../enum/common.enum";
import FormSearch from "./FormSearch";
import Header from "./Header";
import Result from "./Result";
import { isEmptyObj } from "../../../utils/object.util";

function TaskList() {
  const navigate = useNavigate();
  const defaultValueForm = {
    status: ALL_ENUM,
    fieldFilter: ALL_ENUM,
    fieldValues: [],
    code: "",
    subject: "",
    fromDate: null,
    toDate: null,
    priority: ALL_ENUM,
  };
  const location = useLocation();

  const [bodyFilter, setBodyFilter] = useState<any>(defaultValueForm);

  useEffect(() => {
    const stateLocation = location?.state;
    if (!isEmptyObj(stateLocation) && !stateLocation?.navigateFrom) {
      if (stateLocation) {
        const body = {
          status: stateLocation?.bodyFilter?.status,
          fieldFilter: stateLocation?.bodyFilter?.fieldFilter,
          fieldValues: stateLocation?.bodyFilter?.fieldValues,
          code: stateLocation?.bodyFilter?.code,
          subject: stateLocation?.bodyFilter?.subject,
          fromDate: stateLocation?.bodyFilter?.fromDate,
          toDate: stateLocation?.bodyFilter?.toDate,
          priority: stateLocation?.bodyFilter?.priority,
        };
        setBodyFilter(body);
      }
    }
  }, []);

  return (
    <>
      {location?.state?.navigateFrom == FROM_DASHBOARD_TASK && (
        <Button
          variant="outlined"
          color="primary"
          type="button"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/dashboard")}
        >
          Quay lại
        </Button>
      )}
      {location?.state?.navigateFrom == FROM_DASHBOARD_TASK ? (
        <Header dashboard={location?.state?.dashboard} />
      ) : (
        <Header />
      )}
      <FormSearch defaultValueForm={defaultValueForm} valueForm={bodyFilter} setValueForm={setBodyFilter} />
      <Result bodyFilter={bodyFilter} />
    </>
  );
}

export default TaskList;
