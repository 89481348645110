export const ACTIVE = 1;
export const INACTIVE = 0;
export const statusDepartment = [
  { code: ACTIVE, description: "Hoạt động" },
  { code: INACTIVE, description: "Không hoạt động" },
];

export enum TYPE_FILE_ENUM {
  CONTRACT = "CONTRACT",
  EDUCATION = "EDUCATION",
  DOCUMENT = "DOCUMENT",
  CONTRACT_ADDENDUM = "CONTRACTADDENDUM",
}
