import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { BsInfoCircleFill } from "react-icons/bs";
import React, { FC } from "react";

interface TitleSalaryInformationProps {
  title: string;
  value: string | any;
  titleTooltip: string;
}

const TitleSalaryInformation: FC<TitleSalaryInformationProps> = (props) => {
  const { title, value, titleTooltip } = props;
  return (
    <Grid item xs={12} md={6} lg={2}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "1rem", color: "#253C51", fontWeight: "700" }}>{title}</Typography>
        <Tooltip title={titleTooltip} placement="top" arrow>
          <IconButton sx={{ fontSize: "1rem" }}>
            <BsInfoCircleFill style={{ color: "orange" }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography>{value}</Typography>
    </Grid>
  );
};

export default TitleSalaryInformation;
