import React, { FC } from "react";
import HeaderPage from "../../../components/common/HeaderPage";

interface HeaderRoleProps {}

const HeaderRole: FC<HeaderRoleProps> = (props) => {
  return (
    <>
      <HeaderPage title="Nhóm quyền"></HeaderPage>
    </>
  );
};

export default HeaderRole;
