import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import BoxContainer from "../../components/common/BoxContainer";
import Logo from "../../components/logo/Logo";
import BoxWrap from "./common/BoxWrap";
import HeaderText from "./common/HeaderText";
import { Text } from "./common/Text";

interface PrivacyPolicyProps {}

const PrivacyPolicy: FC<PrivacyPolicyProps> = (props) => {
  return (
    <>
      <Box sx={{ background: "#fff", minHeight: "100vh", overflowX: "hidden" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ maxWidth: "1200px", margin: "0 auto" }}>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  minHeight: "45px",
                  backgroundColor: "#061748",
                  position: "fixed",
                  top: 0,
                  right: 0,
                  left: 0,
                  zIndex: 2,
                }}
              >
                <Box sx={{ padding: "1.2rem" }}>
                  <Box sx={{ maxWidth: "1200px", margin: "0 auto" }}>
                    <Logo type={"light"} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ paddingTop: "5rem" }}>
              <Box sx={{ padding: "0 2.4rem" }}>
                <Typography sx={{ textAlign: "center", marginBottom: "1rem" }} variant="h6">
                  CHÍNH SÁCH BẢO MẬT THÔNG TIN TRÊN NỀN TẢNG VFF-CONNECT
                </Typography>
                <Text
                  title={
                    "Nhằm đảm bảo an toàn cho website và bảo mật thông tin cho người sử dụng. Nền tảng vff-connect (sau đây gọi tắt là “vff-connect”.) đưa ra một số chính sách bảo mật thông tin cho người sử dụng, cá nhân và tổ chức khi sử dụng dịch vụ tại vff-connect."
                  }
                />
                <Text
                  title={
                    "Thuật ngữ “Người dùng” được sử dụng dưới đây được thay thế cho Người dùng là cá nhân hay các tổ chức"
                  }
                />
                <Text
                  title={
                    "vff-connect có thể sửa đổi, cập nhật, bổ sung chính sách này vào mọi thời điểm. Bản sửa đổi có hiệu lực vào thời điểm vff-connect cập nhật. Sau khi chính sách bảo mật được cập nhật, Người dùng vẫn sử dụng vff-connect có nghĩa là người dùng đã hiểu và đồng ý với Chính sách bảo mật của vff-connect."
                  }
                />

                {/* 1 */}

                <BoxWrap>
                  <HeaderText title={"1. THÔNG TIN ĐƯỢC THU THẬP"} />
                  <Text
                    title={
                      "Nền tảng vff-connect thu thập thông tin để cung cấp các dịch vụ tốt hơn cho tất cả người dùng của mình. Khi Người dùng đăng nhập vào nền tảng vff-connect, vff-connect sẽ thu thập các thông tin liên quan tới Người dùng (sau đây gọi chung là “thông tin”.), bao gồm:"
                    }
                  />
                  <HeaderText title={"a. Những thông tin Người dùng chủ động cung cấp cho vff-connect"} />
                  <Text
                    title={
                      "Khi tạo một Tài khoản trên hệ thống, Người dùng cung cấp cho vff-connect thông tin bao gồm Sau khi đăng ký tài khoản, Người dùng có quyền, nhưng không bắt buộc, cung cấp cho vff-connect các thông tin liên lạc khác để phục vụ việc định danh tài khoản."
                    }
                  />
                  <HeaderText title={"b. Thông tin được thu thập khi sử dụng dịch vụ"} />
                  <Text
                    title={
                      "Trong quá trình Người dùng sử dụng dịch vụ của vff-connect, vff-connect có thể thu thập các thông tin sau:"
                    }
                  />
                  <HeaderText title={"- Các ứng dụng, trình duyệt và thiết bị của Người dùng"} />
                  <Text
                    title={
                      "vff-connect thu thập thông tin về các ứng dụng, trình duyệt và thiết bị mà Người dùng dùng để truy cập vào dịch vụ của vff-connect. Điều này giúp vff-connect cung cấp các tính năng như tự động cập nhật sản phẩm."
                    }
                  />
                  <HeaderText title={"- Hoạt động của Người dùng"} />
                  <Text
                    title={
                      "vff-connect thu thập thông tin về hoạt động của Người dùng, bao gồm nhưng không giới hạn là các nội dung Người dùng đăng tải, chia sẻ trên hệ thống vff-connect và sử dụng thông tin này để thực hiện việc tối ưu hóa, cá nhân hóa nội dung hiển thị nhằm phục vụ Người dùng tốt hơn."
                    }
                  />

                  <HeaderText title={"- Danh bạ điện thoại của Người dùng:"} />
                  <Text
                    title={
                      "vff-connect cũng lưu trữ danh bạ điện thoại của Người dùng trên máy chủ sau khi được Người dùng cấp phép truy cập vào Danh bạ điện thoại, nhằm mục đích hỗ trợ sử dụng ứng dụng và thông báo cho Người dùng khi một trong các liên hệ của Người dùng đăng ký vff-connect. vff-connect cam kết sẽ tôn trọng và không sử dụng Danh bạ điện thoại của Người dùng với bất kỳ mục đích nào khác nếu không có sự đồng ý của Người dùng."
                    }
                  />
                  <Box sx={{ display: "flex" }}>
                    <HeaderText title={"c. "} />
                    <Text
                      title={
                        " Người dùng sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu và hộp thư điện tử của mình. Ngoài ra, Người dùng có trách nhiệm thông báo kịp thời cho vff-connect về những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện pháp giải quyết phù hợp."
                      }
                    />
                  </Box>
                </BoxWrap>

                {/* 2 */}
                <BoxWrap>
                  <HeaderText title={"2. MỤC ĐÍCH VIỆC THU THẬP THÔNG TIN"} />
                  <Text
                    title={
                      "Với tôn chỉ hoạt động là “Lấy người dùng làm trọng tâm”, vff-connect xác định và cam kết việc thu thập thông tin là để tối ưu trải nghiệm người dùng với những mục đích cụ thể như sau:"
                    }
                  />
                  <Text title={"a. Cải thiện, phát triển và cung cấp các dịch vụ phù hợp cho người sử dụng;"} />
                  <Text title={"b. Cá nhân hóa trải nghiệm Người dùng;"} />
                  <Text
                    title={
                      "c. Liên lạc và giải quyết với Người dùng trong những trường hợp đặc biệt liên quan đến dịch vụ của vff-connect;"
                    }
                  />
                  <Text title={"d. Bảo vệ Người dùng trên nền tảng vff-connect;"} />
                  <Text
                    title={
                      "e. Trong trường hợp có yêu cầu của pháp luật: vff-connect có trách nhiệm hợp tác cung cấp thông tin cá nhân thành viên khi có yêu cầu từ cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án, cơ quan công an điều tra liên quan đến hành vi vi phạm pháp luật nào đó của Người dùng;"
                    }
                  />
                  <Text title={"f. Ngoài ra, không ai có quyền xâm phạm vào thông tin cá nhân của Người dùng."} />
                </BoxWrap>

                {/* 3 */}
                <BoxWrap>
                  <HeaderText title={"3. PHẠM VI SỬ DỤNG THÔNG TIN, TRƯỜNG HỢP THÔNG TIN ĐƯỢC CHIA SẺ"} />
                  <Text
                    title={
                      "vff-connect rất coi trọng việc bảo mật thông tin Người dùng, vff-connect cam kết tuyệt đối không tự ý sử dụng thông tin Người dùng với mục đích không mang lại lợi ích cho Người dùng, vff-connect cam kết không buôn bán, trao đổi thông tin bảo mật của Người dùng cho bất cứ bên thứ ba nào."
                    }
                  />
                  <Text
                    title={
                      "Tuy nhiên, trong một số trường hợp đặc biệt sau, thông tin có thể được chia sẻ một cách hợp lý khi:"
                    }
                  />
                  <HeaderText title={"a. Người sử dụng tự chia sẻ thông tin của mình"} />
                  <Text
                    title={
                      "Nền tảng vff-connect cho phép Người dùng tự chia sẻ thông tin với người khác và Người dùng nắm quyền kiểm soát cách chia sẻ. Ví dụ: Người dùng có thể chia sẻ các nội dung, video, hình ảnh trên nền tảng vff-connect hoặc có thể quyết định giữ các nội dung, video, hình ảnh của Người dùng ở chế độ riêng tư."
                    }
                  />
                  <HeaderText title={"b. Trường hợp vff-connect chia sẻ thông tin của Người dùng"} />
                  <Text
                    title={
                      "vff-connect sẽ giữ bí mật và không tiết lộ bất kỳ thông tin nào của Người dùng ngoại trừ các trường hợp sau đây:"
                    }
                  />
                  <HeaderText title={"- Có sự đồng ý của Người dùng"} />
                  <Text
                    title={
                      "vff-connect sẽ xin phép Người dùng và phải được sự đồng ý của Người dùng trước khi chia sẻ bất kỳ thông tin nào của Người dùng. Khi Người dùng đăng ký, sử dụng tính năng đăng nhập liên quan đến các nền tảng và ứng dụng của bên thứ ba, điều đó đồng nghĩa với việc các thông tin của Người dùng sẽ được chia sẻ cho bên thứ ba, dựa trên sự chấp thuận của Người dùng. Bằng việc chấp nhận sự chia sẻ này, Người dùng cũng sẽ chấp nhận những rủi ro kèm theo. Trong trường hợp này, Người dùng đồng ý và chấp nhận loại trừ trách nhiệm của vff-connect liên quan tới việc thông tin, dữ liệu của Người dùng bị chia sẻ cho bên thứ ba."
                    }
                  />
                  <HeaderText title={"- Để bảo vệ quyền lợi của công ty và những đối tác của công ty"} />
                  <Text
                    title={
                      "vff-connect chỉ đưa ra những thông tin cá nhân của Người dùng khi chắc chắn rằng những thông tin đó có thể bảo vệ được quyền lợi, tài sản của công ty vff-connect và những đối tác liên quan. Những thông tin này sẽ được tiết lộ một cách hợp pháp theo Pháp luật Việt Nam."
                    }
                  />

                  <HeaderText title={"- Theo quy định của pháp luật Việt Nam"} />
                  <Text
                    title={
                      "vff-connect sẽ chia sẻ thông tin cá nhân cho các cơ quan chức năng của Việt Nam khi có yêu cầu hợp lệ của Tòa án và/hoặc cơ quan hành chính có thẩm quyền theo quy định của pháp luật Việt Nam."
                    }
                  />

                  <Text
                    title={
                      "- Trong một số trường hợp cần thiết phải cung cấp thông tin Người dùng khác, như các chương trình khuyến mãi có sự tài trợ của một bên thứ ba chẳng hạn, vff-connect sẽ thông báo cho quý Người dùng trước khi thông tin của quý khách được chia sẻ. Quý khách có quyền quyết định xem có đồng ý chia sẻ thông tin hoặc tham gia hay không."
                    }
                  />

                  <Text
                    title={
                      "- Nếu vff-connect tham gia vào việc sáp nhập, mua lại hoặc bán tài sản, vff-connect sẽ tiếp tục đảm bảo tính bí mật của thông tin cá nhân của Người dùng và thông báo cho những người dùng bị ảnh hưởng trước khi thông tin cá nhân được chuyển giao hoặc chịu sự điều chỉnh của một chính sách bảo mật khác."
                    }
                  />
                </BoxWrap>

                {/* 4 */}
                <BoxWrap>
                  <HeaderText title={"4. CAM KẾT BẢO MẬT THÔNG TIN"} />
                  <Text
                    title={
                      "Nền tảng vff-connect được giám sát bảo mật bởi các chuyên gia có nhiều năm kinh nghiệm. Luôn nỗ lực cao nhất để bảo vệ dữ liệu Người dùng."
                    }
                  />
                  <Text
                    title={
                      "vff-connect sẽ thông báo đến Người dùng các mối đe dọa bảo mật và tiến trình xử lý bảo mật của vff-connect để bảo vệ quyền và lợi ích của Người dùng khi sử dụng nền tảng vff-connect."
                    }
                  />
                  <HeaderText title={"Cam kết bảo mật thông tin cá nhân Người dùng:"} />
                  <Text
                    title={
                      "- vff-connect cam kết bảo đảm an toàn thông tin cho Người dùng khi đăng ký thông tin cá nhân với vff-connect. vff-connect cam kết không trao đổi mua bán thông tin Người dùng vì mục đích thương mại. Mọi sự chia sẻ và sử dụng thông tin Người dùng, vff-connect cam kết thực hiện theo chính sách bảo mật của vff-connect. vff-connect cam kết mang lại sự tin tưởng và hài lòng về bảo mật thông tin cá nhân khi tham gia và sử dụng những dịch vụ của vff-connect."
                    }
                  />
                  <Text
                    title={
                      "- Để bảo mật thông tin của Người dùng tốt nhất, vff-connect khuyến cáo quý Người dùng khi đăng nhập tài khoản trên vff-connect: hạn chế truy cập tài khoản bằng đăng nhập tự động, chú ý chế độ sao lưu password và đảm bảo đăng xuất khỏi tài khoản khi sử dụng máy tính chung. vff-connect sẽ không chịu trách nhiệm khi những thông tin cá nhân của quý khách bị rò rỉ vì những lý do trên."
                    }
                  />
                  <Text
                    title={
                      "- Chính sách bảo mật chỉ áp dụng những thông tin quý Người dùng đăng ký trên website/ ứng dụng vff-connect. Mọi thông tin quý khách đăng ký tại những website/ ứng dụng hoặc những địa chỉ khác đều không thuộc phạm vi hiệu lực của Chính sách Bảo mật thông tin này."
                    }
                  />
                  <Text
                    title={
                      "- vff-connect quan tâm đến việc bảo vệ thông tin của Người dùng. vff-connect sử dụng các biện pháp an toàn được thiết kế để bảo vệ thông tin của Người dùng từ việc truy cập và tiết lộ trái phép. Tuy nhiên, vff-connect không thể bảo đảm rằng những thông tin cá nhân của Người dùng hoặc các thông tin liên lạc riêng tư luôn riêng tư và an toàn."
                    }
                  />
                </BoxWrap>

                {/* 5 */}
                <BoxWrap>
                  <HeaderText title={"5. THỜI GIAN LƯU TRỮ THÔNG TIN"} />
                  <Text
                    title={
                      "vff-connect sẽ lưu trữ các Thông tin cá nhân do Người dùng cung cấp trên các hệ thống nội bộ của vff-connect trong quá trình cung cấp dịch vụ cho Người dùng hoặc cho đến khi hoàn thành mục đích thu thập hoặc khi Người dùng có yêu cầu hủy các thông tin đã cung cấp."
                    }
                  />
                </BoxWrap>

                {/* 6 */}
                <BoxWrap>
                  <HeaderText title={"6. ĐỊA CHỈ CỦA ĐƠN VỊ THU THẬP VÀ QUẢN LÝ THÔNG TIN CÁ NHÂN"} />
                  <Text title={"- Công ty Cổ Phần Viễn Thông FPT"} />
                  <Text
                    title={
                      "- Trụ sở: Tầng 19, Tòa nhà FPT Tower, Số 10 Phạm Văn Bạch, Phường Dịch Vọng, Quận Cầu Giấy, Hà Nội."
                    }
                  />
                  <Text title={"- Tel: 19006600"} />
                  <Text title={"- Email: quangnh21@fpt.com.vn"} />
                </BoxWrap>

                {/* 7 */}
                <BoxWrap>
                  <HeaderText
                    title={"7. PHƯƠNG TIỆN VÀ CÔNG CỤ ĐỂ NGƯỜI DÙNG TIẾP CẬN VÀ CHỈNH SỬA DỮ LIỆU CÁ NHÂN CỦA MÌNH"}
                  />
                  <Text
                    title={
                      "Người dùng có thể thực hiện các quyền trên bằng cách tự truy cập vào website vff-connect.vn hoặc liên hệ với vff-connect qua email hoặc địa chỉ liên lạc được công bố trên tại: hotro.vff-connect@fpt.com.vn."
                    }
                  />
                </BoxWrap>

                {/* 8 */}
                <BoxWrap>
                  <HeaderText title={"8. HỢP TÁC VỚI CƠ QUAN CHỨC NĂNG"} />
                  <Text
                    title={
                      "Nền tảng vff-connect đặt máy chủ tại Việt Nam. Tất cả các dữ liệu được lưu trữ, sử dụng theo quy định của pháp luật Việt Nam và các điều ước quốc tế mà Việt Nam là thành viên."
                    }
                  />
                  <Text
                    title={
                      "Khi nhận được khiếu nại chính thức bằng văn bản, vff-connect sẽ phản hồi bằng cách liên hệ với người đưa ra khiếu nại này. vff-connect sẽ hợp tác với các cơ quan chức năng để giải quyết bất kỳ khiếu nại nào về việc truyền dữ liệu của Người dùng mà vff-connect không thể giải quyết trực tiếp với Người dùng."
                    }
                  />
                </BoxWrap>

                {/* 9 */}
                <BoxWrap>
                  <HeaderText title={"9. LIÊN KẾT KHÁC"} />
                  <HeaderText title={"a. Liên kết đến website, ứng dụng khác"} />
                  <Text
                    title={
                      "Trong một số trường hợp không mong muốn, vff-connect có thể dẫn đến một số liên kết tới trang web hoặc nguồn dữ liệu khác. Quý khách tự chịu trách nhiệm khi sử dụng các liên kết này. vff-connect không tiến hành thẩm định hay xác thực nội dung, tính chính xác, quan điểm thể hiện tại các trang web và nguồn dữ liệu liên kết này. vff-connect từ chối bất cứ trách nhiệm pháp lý nào liên quan tới tính chính xác, nội dung thể hiện, mức độ an toàn và việc cho hiển thị hay che đi các thông tin trên các trang web và nguồn dữ liệu nói trên."
                    }
                  />
                  <HeaderText title={"b. Liên kết từ website, ứng dụng khác"} />
                  <Text
                    title={
                      "vff-connect không cho phép bất kỳ nhà cung cấp dịch vụ Internet nào được phép “đặt toàn bộ” hay “nhúng” bất kỳ thành tố nào của vff-connect sang một trang khác hoặc sử dụng các kỹ thuật làm thay đổi giao diện/hiển thị mặc định của vff-connect mà chưa có sự đồng ý của vff-connect."
                    }
                  />
                  <HeaderText title={"c. Miễn trừ trách nhiệm"} />
                  <Text
                    title={
                      "- Thông tin hiển thị tại các website, ứng dụng này không đi kèm bất kỳ đảm bảo hay cam kết trách nhiệm từ phía vff-connect về sự phù hợp của sản phẩm, dịch vụ mà Người dùng đã lựa chọn."
                    }
                  />
                  <Text
                    title={
                      "- vff-connect từ chối trách nhiệm hay đưa ra đảm bảo rằng website/ ứng dụng vff-connect sẽ không có lỗi vận hành, an toàn, không bị gián đoạn hay tính chính xác, đầy đủ và đúng hạn của các thông tin hiển thị."
                    }
                  />
                  <Text
                    title={
                      "- Khi truy cập vào các website/ ứng dụng này, quý khách mặc nhiên đồng ý rằng vff-connect, các nhà cung cấp khác cùng với đối tác liên kết, nhân viên của họ không chịu bất cứ trách nhiệm nào liên quan đến thương tật, mất mát, khiếu kiện, thiệt hại trực tiếp hoặc gián tiếp do không lường trước hoặc do hậu quả để lại dưới bất cứ hình thức nào phát sinh từ việc: (1) Sử dụng các thông tin trên website/ ứng dụng này; (2) các truy cập kết nối từ website/ ứng dụng này; (3) Đăng ký thành viên, đăng ký nhận thư điện tử hay tham gia vào chương trình Người dùng thường xuyên của vff-connect."
                    }
                  />
                </BoxWrap>

                {/* 10 */}
                <BoxWrap>
                  <HeaderText title={"10. CÁC ĐIỀU KHOẢN KHÁC"} />
                  <HeaderText title={"a. Hiệu lực"} />
                  <Text
                    title={
                      "Chính sách bảo mật này có hiệu lực ràng buộc giữa vff-connect và Người dùng khi Người dùng đồng ý tham gia vào nền tảng vff-connect. Chính sách bảo mật này áp dụng cho tất cả các thành viên sử dụng Mạng xã hội vff-connect và có giá trị bổ sung cho Thỏa Thuận Cung Cấp Và Sử Dụng Dịch Vụ Nền Tảng vff-connect."
                    }
                  />
                  <HeaderText title={"b. Sửa đổi, bổ sung"} />
                  <Text
                    title={
                      "Các điều khoản tại Chính sách bảo mật này có thể được vff-connect sửa đổi, bổ sung bất cứ lúc nào mà không cần phải thông báo trước tới Người dùng. Những Thông tin sửa đổi, bổ sung sẽ được vff-connect công bố trên hệ thống vff-connect."
                    }
                  />
                </BoxWrap>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
