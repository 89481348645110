import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import BoxContainer from "../../../components/common/BoxContainer";
import NotFoundFilterView from "../../../components/common/NotFoundFilterView";
import PaginationPage from "../../../components/common/Pagination";
import ReadMore from "../../../components/common/ReadMore";
import { VIEW_DATE_TIME_FORMAT } from "../../../constants/date-time.constant";
import { TASK_STATUS_ENUM } from "../../../enum/task.enum";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { header_table_color } from "../../../themes/color";
import { convertTaskPriority } from "../../../utils/task.util";
import OutOfDateTaskIcon from "../common/OutOfDateTaskIcon";
import StatusTaskChip from "../common/StatusTaskChip";
import { setPageFilterTask } from "../redux/task.reducer";

interface ResultSearchProps {
  bodyFilter: any;
}

const Result: FC<ResultSearchProps> = (props) => {
  const { bodyFilter } = props;
  const dispatch = useAppDispatch();
  const totalPage = useAppSelector((state) => state.task.totalTaskFilter);
  const page = useAppSelector((state) => state.task.pageTask);
  const data = useAppSelector((state) => state.task.dataTaskFilter);

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setPageFilterTask(page - 1));
  };

  const isOutOfDateTask = (status: string, endDate: any) => {
    if (!endDate) {
      return false;
    }

    if (
      [TASK_STATUS_ENUM.PENDING, TASK_STATUS_ENUM.PROCESSING, TASK_STATUS_ENUM.SUBMITED].includes(
        status as TASK_STATUS_ENUM
      ) &&
      moment(endDate)?.isBefore(moment.now())
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      {data && (
        <BoxContainer>
          {/* show result */}
          {data.length > 0 && (
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: header_table_color }}>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap", minWidth: "130px" }}>
                        Mã công việc
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap", px: 0.5 }}>Công việc</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap", px: 0.5 }}>Nội dung công việc</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap", px: 0.5 }}>Người giao việc</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap", px: 0.5 }}>Người nhận việc</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap", px: 0.5 }}>Độ ưu tiên</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap", px: 0.5 }}>
                        Thời gian (từ - đến)
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap", px: 0.5 }}>Trạng thái</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item: any, index: number) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                        }}
                        key={index}
                      >
                        <TableCell sx={{ width: "6%" }}>
                          {" "}
                          <Link
                            to={`/task/${item?.id}`}
                            state={{
                              bodyFilter: bodyFilter,
                            }}
                            style={{ textDecoration: "none", color: "#0084FF" }}
                          >
                            {item.code}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>{item.subject}</TableCell>

                        <TableCell sx={{ px: 0.5 }}>
                          <ReadMore text={item.description} showLength={60} />
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          {item.reviewerFullName}
                          <br />({item.reviewerEmail})
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          {item.assigneeFullName}
                          <br />({item.assigneeEmail})
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>{convertTaskPriority(item?.priority)}</TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          <Stack alignItems={"center"} direction={"row"}>
                            <div>
                              {moment(item.startDate).format(VIEW_DATE_TIME_FORMAT)}
                              <br />
                              {item.endDate && moment(item.endDate).format(VIEW_DATE_TIME_FORMAT)}
                            </div>
                            {isOutOfDateTask(item.status, item.endDate) && <OutOfDateTaskIcon />}
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          <StatusTaskChip status={item.status} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationPage total={totalPage} page={page} onChange={handleChangePage} />
            </Box>
          )}

          {/* not found result */}
          {data.length === 0 && <NotFoundFilterView />}
        </BoxContainer>
      )}
    </>
  );
};

export default Result;
