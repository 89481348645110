import React, { FC } from "react";
import AsyncReactSelectField from "./AsyncReactSelectField";
import CheckboxButton from "./CheckboxButton";
import DatePickerField from "./DatePickerField";
import InputFiled from "./InputField";
import NumberInputField, { NumberInputFieldProps } from "./NumberInputField";
import RadioButtons, { RadioButtonsProps } from "./RadioButtons";
import ReactSelectField, { ReactSelectFieldProps } from "./ReactSelectField";
import SelectField, { SelectFieldProps } from "./SelectField";
import TextareaField from "./TextareaField";
import UploadFileInput from "./UploadFileInput";
import { MultiReactSelectField } from "./MultiReactSelectField";

export interface FieldCommonProps {
  id: string;
  name: string;
  label?: string | any;
  isRequired?: boolean;
  placeholder?: string;
  tooltip?: unknown;
  styleContainer?: any;
}

interface FieldContainerProps
  extends FieldCommonProps,
  ReactSelectFieldProps,
  SelectFieldProps,
  RadioButtonsProps,
  NumberInputFieldProps {
  variant:
  | "input"
  | "react-select"
  | "radio"
  | "async-react-select"
  | "date"
  | "select"
  | "textarea"
  | "upload-input"
  | "checkbox"
  | "number-input"
  | "multi-react-select";
  [key: string]: unknown;
}

const FieldContainer: FC<FieldContainerProps> = (props) => {
  const buildChildren = () => {
    switch (props.variant) {
      case "input":
        return <InputFiled {...props} />;
      case "date":
        return <DatePickerField {...props} />;
      case "react-select":
        return <ReactSelectField {...props} />;
      case "async-react-select":
        return <AsyncReactSelectField {...props} />;
      case "select":
        return <SelectField {...props} />;
      case "textarea":
        return <TextareaField {...props} />;
      case "radio":
        return <RadioButtons {...props} />;
      case "upload-input":
        return <UploadFileInput {...props} />;
      case "checkbox":
        return <CheckboxButton {...props} />;
      case "number-input":
        return <NumberInputField {...props} />;
      case "multi-react-select":
        return <MultiReactSelectField {...props} />;
    }
  };

  return <div style={{ ...props.styleContainer }}>{buildChildren()}</div>;
};

export default FieldContainer;
