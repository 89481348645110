import React, { FC } from "react";
import { Modal } from "@mui/material";
import BoxModal, { BoxModalProps } from "./BoxModal";

interface ModalContainerProps extends BoxModalProps {
  open: boolean;
  title: string;
  isCloseClickOutSide?: boolean;
  [key: string]: unknown;
}

const ModalContainer: FC<ModalContainerProps> = (props) => {
  const { open, onClose, title, children, isCloseClickOutSide = false, ...rest } = props;
  return (
    <Modal open={open} onClose={isCloseClickOutSide ? onClose : () => {}} aria-labelledby="modal">
      <BoxModal title={title} onClose={onClose} {...rest}>
        {children}
      </BoxModal>
    </Modal>
  );
};

export default ModalContainer;
