import React, { FC } from "react";
import { Box, Button } from "@mui/material";
import { Params, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "../../../../../components/icons/ArrowBackIcon";
import FormUpdateDocInDomestic from "./FormUpdateDocInDomestic";

interface UpdateDocInDomesticProps {}

const UpdateDocInDomestic: FC<UpdateDocInDomesticProps> = (props) => {
  const navigate = useNavigate();
  const params: Readonly<Params<string>> = useParams();

  if (isNaN(Number(params?.id))) {
    navigate("/404");
  }

  const handleClickButtonBack = () => {
    navigate(`/doc-in/${params?.id}`);
  };

  return (
    <>
      <Box>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          startIcon={<ArrowBackIcon />}
          onClick={handleClickButtonBack}
        >
          Quay lại
        </Button>
        <Box sx={{ marginTop: "0.4rem" }}>
          <FormUpdateDocInDomestic />
        </Box>
      </Box>
    </>
  );
};

export default UpdateDocInDomestic;
