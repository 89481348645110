import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import UploadSVG from "../images/upload.svg";
import { FieldCommonProps } from "./FieldContainer";
import LabelField from "./LabelField";

interface UploadFileInputProps extends FieldCommonProps {
  accept?: string;
  onChange?: (e: React.InputHTMLAttributes<HTMLInputElement>) => void;
  textWarning?: string;
  [key: string]: any;
}

const UploadFileInput: FC<UploadFileInputProps> = (props) => {
  const {
    id = "file-input",
    accept = ".xlsx,.xls,.doc,.docx,.pdf",
    name,
    onChange,
    label,
    isRequired,
    tooltip,
    textWarning,
    ...rest
  } = props;
  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          border: "1px dashed #0240B8",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          <label htmlFor={id}>
            <img
              src={UploadSVG}
              style={{
                width: "65px",
                height: "50px",
              }}
              alt="img background upload"
            />
          </label>
          <Typography
            sx={{
              color: "#ADAAAA",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Tải lên tài liệu liên quan
          </Typography>
        </Box>
        <input
          id={id}
          name={name}
          accept={accept}
          type="file"
          multiple
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            border: "none",
            zIndex: 0,
            opacity: 0,
          }}
          onChange={(e: React.InputHTMLAttributes<HTMLInputElement>) => {
            onChange && onChange(e);
          }}
          {...rest}
        />
      </Box>
      {textWarning && (
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#0084FF",
            fontStyle: "italic",
          }}
        >
          {textWarning}
        </Typography>
      )}
    </>
  );
};

export default UploadFileInput;
