export enum ACCESS_RIGHT_ENUM {
  ADMIN = "ADMIN",
  CLERICAL_ASSISTANT = "CLERICAL_ASSISTANT", // Văn thư
  STAFF_INTERNATIONAL_DEPARTMENT = "STAFF_INTERNATIONAL_DEPARTMENT", // Nhân viên phòng quốc tế
  CHIEF_OF_OFFICE = "CHIEF_OF_OFFICE", // Chánh/phó chánh văn phòng
  HEAD_OF_INTERNATIONAL_DEPARTMENT = "HEAD_OF_INTERNATIONAL_DEPARTMENT", // Lãnh đạo phòng quốc tế
  HR = "HR",
  DASHBOARD = "DASHBOARD" // Thống kê
}

export enum POSITION_ACCESS_RIGHT_ENUM {
  STAFF = "Nhân viên",
  DEPUTY_LEADER = "Phó trưởng phòng",
  LEADER = "Trưởng phòng",
  DEPUTY_CHIEF_OF_OFFICE = "Phó Chánh văn phòng",
  CLERICAL_ASSISTANT = "Văn thư",
  CHIEF_OF_OFFICE = "Chánh văn phòng",
  DEPUTY_SECRETARY_GENERAL = "Phó tổng thư ký",
  GENERAL_SECRETARY = "Tổng thư ký",
  CHAIRMAN = "Chủ tịch",
}

export enum POSITION_CODE_ACCESS_RIGHT_ENUM {
  STAFF = "NV",
  DEPUTY_LEADER = "PTP",
  LEADER = "TP",
  DEPUTY_CHIEF_OF_OFFICE = "PCVP",
  CLERICAL_ASSISTANT = "VT",
  CHIEF_OF_OFFICE = "CVP",
  DEPUTY_SECRETARY_GENERAL = "PTTK",
  GENERAL_SECRETARY = "TTK",
  CHAIRMAN = "CT",
}
