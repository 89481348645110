import React from "react";
import { EmployeeStatusEnum } from "../../../../model/personnel.model";

const StatusEmployee = ({ status }: { status: any }) => {
  if (status === EmployeeStatusEnum.NORMAL) {
    return <span style={{ color: "#4ACB1D" }}>Đang làm việc</span>;
  } else if (status === EmployeeStatusEnum.MATERNITY_LEAVE) {
    return <span style={{ color: "#0084FF" }}>Nghỉ thai sản</span>;
  } else if (status === EmployeeStatusEnum.INACTIVE_DUE_TO_EMAIL_CHANGE) {
    return <span style={{ color: "#0084FF" }}>Không hoạt động do đổi email</span>;
  }
  return <span style={{ color: "red" }}>Nghỉ việc</span>;
};

export default StatusEmployee;
