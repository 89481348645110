import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { FC } from "react";
import HeaderPage from "../../../components/common/HeaderPage";
import { TYPE_FILE_ENUM } from "../../../enum/hr.enum";
import { header_table_color } from "../../../themes/color";
import { downloadFileHrByType } from "../common/util/personnel.util";

interface DocumentDetailProps {
  data: any;
}

const DocumentDetail: FC<DocumentDetailProps> = (props) => {
  const { data } = props;
  return (
    <>
      <HeaderPage title="Tài liệu"></HeaderPage>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ background: header_table_color }}>
                <TableCell sx={{ fontWeight: 600 }}>Ngày ban hành</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Số văn bản</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Ghi chú</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Đính kèm</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.documents?.map((item: any, index: number) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                  }}
                  key={index}
                >
                  <TableCell>{item?.issuedDate} </TableCell>
                  <TableCell>{item?.code}</TableCell>
                  <TableCell>{item?.note}</TableCell>
                  <TableCell
                    sx={{ color: "#0084FF", cursor: "pointer" }}
                    onClick={() =>
                      downloadFileHrByType(
                        item?.documentFile?.id,
                        item?.documentFile?.name || "",
                        TYPE_FILE_ENUM.DOCUMENT
                      )
                    }
                  >
                    {item?.documentFile?.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default DocumentDetail;
