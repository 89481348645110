import { Box } from "@mui/material";
import React, { FC } from "react";

interface BoxWrapProps {
  children?: React.ReactNode | React.ReactElement;
  [key: string]: unknown;
}

const BoxWrap: FC<BoxWrapProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Box sx={{ margin: "1rem 0 0.6rem" }} {...rest}>
      {children}
    </Box>
  );
};

export default BoxWrap;
