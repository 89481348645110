import { Box } from "@mui/material";
import { USER_TITLE } from "../../../constants/title-page.constant";
import FormSearchUser from "./FormSearchUser";
import HeaderUser from "./HeaderUser";
import ResultUser from "./ResultUser";

function FilterUser() {
  document.title = USER_TITLE.list;

  return (
    <Box>
      <HeaderUser />
      <FormSearchUser />
      <ResultUser />
    </Box>
  );
}

export default FilterUser;
