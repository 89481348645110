import HTTP_DOC_SERVICE from "../config/axios/axios-doc.config";
import { Pageable } from "../model/page.model";
import { TemplateFile } from "../model/template.model";

export const getTemplateFileApi = (param: Pageable) => {
  return HTTP_DOC_SERVICE.get<TemplateFile[]>(`/template-file`);
};

export const downloadTemplateFileApi = (id: number) => {
  return HTTP_DOC_SERVICE.get<Blob>(`/template-file/download`, {
    params: { id },
    responseType: "blob",
  });
};
