import { Button } from "@mui/material";
import React, { FC } from "react";
import ArrowBackIcon from "../../../components/icons/ArrowBackIcon";
import { Params, useNavigate, useParams } from "react-router-dom";

const HeaderUpdateTask = () => {
  const navigate = useNavigate();
  const params: Readonly<Params<any>> = useParams();

  const handleClickButtonBack = () => {
    navigate("/task/" + params.id);
  };
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        startIcon={<ArrowBackIcon />}
        onClick={handleClickButtonBack}
      >
        Quay lại
      </Button>
    </>
  );
};

export default HeaderUpdateTask;
