import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { FC, forwardRef, useEffect, useRef } from "react";
import ModalContainer from "../../components/modal/ModalContainer";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FieldContainer from "../../components/form/FieldContainer";
import { COMMON_MSG, FORM_MSG } from "../../constants/msg/msg.constant";
import { toast } from "react-toastify";
import { getErrorMsgAxios, isTooManyRequests } from "../../utils/error.util";
import { verifyOtpApi } from "../../api/auth.api";
import { spacing_size } from "../../themes/size";

interface ForgotPwModalProps {
  openModal: boolean;
  onCloseModal: (email?: string, otpExpirationMinutes?: number) => void;
}
interface InitialValuesForm {
  email: string;
}
const ForgotPwModal: FC<ForgotPwModalProps> = (props) => {
  const { openModal, onCloseModal } = props;
  const emailRef = useRef<any>();

  useEffect(() => {
    setTimeout(() => {
      console.log(emailRef);

      emailRef?.current?.focus();
    }, 0);
  }, []);

  const initialValues: InitialValuesForm = {
    email: "",
  };
  const labelFields = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg(labelFields.email))
      .email(FORM_MSG.patternFieldMsg(labelFields.email)),
  });

  const handleOnSubmit = async (value: InitialValuesForm) => {
    const token = "await recaptchaRef.current.executeAsync()";
    try {
      const res = await verifyOtpApi(value.email, token);
      onCloseModal(value.email, res.data.otpExpirationMinutes);
    } catch (error) {
      if (isTooManyRequests(error)) {
        toast.error(COMMON_MSG.limitChangePW);
      } else {
        toast.error(getErrorMsgAxios(error));
      }
    }
  };

  return (
    <ModalContainer
      open={openModal}
      title={"Quên mật khẩu"}
      onClose={() => {
        onCloseModal();
      }}
    >
      <>
        <Typography mb={spacing_size}>Vui lòng nhập email để nhận mã OTP.</Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form autoComplete="off" noValidate>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} spacing={spacing_size}>
                  <FieldContainer
                    fullWidth
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 32, ref: emailRef }}
                    value={formik.values.email}
                    placeholder="Nhập email"
                    label={labelFields.email}
                    notched={true}
                    isRequired
                    variant={"input"}
                    id={"email"}
                  />
                </Grid>
              </Grid>
              {/* <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_KEY_SITE} /> */}
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 1, md: 3 }}
                sx={{ width: "fit-content", margin: "auto", marginTop: "1.2rem", marginBottom: "0.6rem" }}
              >
                {/* <Button
                    color="cancel"
                    variant="contained"
                    type="button"
                    onClick={() => {
                      onCloseModal();
                    }}
                  >
                    Hủy
                  </Button> */}
                <Button color="primary" variant="contained" type="submit">
                  Nhận mã OTP
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </>
    </ModalContainer>
  );
};

export default ForgotPwModal;
