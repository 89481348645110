import { EnumCommon } from "./common.enum";

export enum TASK_COMMENT_TYPE_ENUM {
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  RETURNED = "RETURNED",
  SUBMITED = "SUBMITED",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  COMMENT = "COMMENT",
  CANCELLED = "CANCELLED",
}

export const ObjectsTaskCommentTypeEnum: EnumCommon<TASK_COMMENT_TYPE_ENUM>[] = [
  {
    code: TASK_COMMENT_TYPE_ENUM.CREATED,
    description: "Tạo mới",
  },
  {
    code: TASK_COMMENT_TYPE_ENUM.UPDATED,
    description: "Cập nhật",
  },
  {
    code: TASK_COMMENT_TYPE_ENUM.RETURNED,
    description: "Hoàn trả task",
  },
  {
    code: TASK_COMMENT_TYPE_ENUM.SUBMITED,
    description: "Chờ đánh giá",
  },
  {
    code: TASK_COMMENT_TYPE_ENUM.COMPLETED,
    description: "Hoàn thành",
  },
  {
    code: TASK_COMMENT_TYPE_ENUM.REJECTED,
    description: "Chưa hoàn thành",
  },
  {
    code: TASK_COMMENT_TYPE_ENUM.COMMENT,
    description: "Ý kiến",
  },
  {
    code: TASK_COMMENT_TYPE_ENUM.CANCELLED,
    description: "Hủy",
  },
];

export enum TASK_FIELD_FILTER_ENUM {
  ASSIGNEE = "ASSIGNEE",
  REVIEWER = "REVIEWER",
  WATCHER = "WATCHER",
}

export const objectsTaskFieldFilterEnums: EnumCommon<TASK_FIELD_FILTER_ENUM>[] = [
  {
    code: TASK_FIELD_FILTER_ENUM.ASSIGNEE,
    description: "Người nhận việc",
  },
  {
    code: TASK_FIELD_FILTER_ENUM.REVIEWER,
    description: "Người giao việc",
  },
  {
    code: TASK_FIELD_FILTER_ENUM.WATCHER,
    description: "Người nhận thông tin",
  },
];

export const objectsTaskDashboardEnums: EnumCommon<TASK_FIELD_FILTER_ENUM>[] = [
  {
    code: TASK_FIELD_FILTER_ENUM.REVIEWER,
    description: "Người giao việc",
  },
  {
    code: TASK_FIELD_FILTER_ENUM.WATCHER,
    description: "Người nhận thông tin",
  },
];

export enum TASK_SEARCH_ENUM {
  MY_JOB = "MY_JOB",
  MY_JOB_REVIEW = "MY_JOB_REVIEW",
}

export enum TASK_STATUS_ENUM {
  PENDING = "PENDING", //="PENDING = "PENDING", //"
  RETURNED = "RETURNED", //("Hoàn trả"),
  SUBMITED = "SUBMITED", //("Chờ đánh giá"),
  COMPLETED = "COMPLETED", //("Hoàn thành"),
  CANCELLED = "CANCELLED", //("Huỷ");
  PROCESSING = "PROCESSING", // Đang xử lý
}

export const objectsTaskStatusEnum: EnumCommon<TASK_STATUS_ENUM>[] = [
  {
    code: TASK_STATUS_ENUM.SUBMITED,
    description: "Chờ kiểm tra",
    color: "#FF8600",
    bgColor: "#FFF1E4",
  },
  {
    code: TASK_STATUS_ENUM.COMPLETED,
    description: "Hoàn thành",
    color: "#40C057",
    bgColor: "#EDFDEF",
  },
  {
    code: TASK_STATUS_ENUM.PENDING,
    description: "Chờ xử lý",
    color: "#FF8600",
    bgColor: "#FFF1E4",
  },
  {
    code: TASK_STATUS_ENUM.PROCESSING,
    description: "Đang xử lý",
    color: "#FF8600",
    bgColor: "#FFF1E4",
  },
  {
    code: TASK_STATUS_ENUM.CANCELLED,
    description: "Hủy bởi người giao",
    color: "#FF4141",
    bgColor: "#FFF5F5",
  },
  {
    code: TASK_STATUS_ENUM.RETURNED,
    description: "Người nhận từ chối",
    color: "#FF8600",
    bgColor: "#FFF1E4",
  },
];

export enum TASK_PRIORITY_ENUM {
  NORMAL = "NORMAL",
  URGENT = "URGENT",
}

export const taskPriorityOptions: EnumCommon<TASK_PRIORITY_ENUM>[] = [
  {
    code: TASK_PRIORITY_ENUM.NORMAL,
    description: "Bình thường",
  },
  {
    code: TASK_PRIORITY_ENUM.URGENT,
    description: "Gấp",
  },
];

export const taskVerifyAgainOptions = [
  {
    code: true,
    description: "Có",
  },
  {
    code: false,
    description: "Không",
  },
];
