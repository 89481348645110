import { ACCESS_RIGHT_ENUM } from "../enum/access-right.enum";
import { CurrentUserModel } from "../features/auth/redux/auth.slice";

export const hasAccessRight = (currentUser: CurrentUserModel | null, accessRight: ACCESS_RIGHT_ENUM): boolean => {
  if (currentUser) {
    return currentUser?.accessRights.map((item) => item.code).includes(accessRight);
  }
  return false;
};

export const hasAnyAccessRight = (currentUser: CurrentUserModel | null, accessRights: ACCESS_RIGHT_ENUM[]): boolean => {
  if (currentUser) {
    return (
      currentUser?.accessRights.map((item) => item.code).filter((ac) => accessRights.includes(ac as ACCESS_RIGHT_ENUM))
        .length > 0
    );
  }

  return false;
};
