import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// import module
import { Provider } from "react-redux";
import { store } from "./store/store";
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

// import projects
import App from "./App";
import Layout from "./layout/Layout";
import FilterDocIn from "./features/document/document-in/filter/FilterDocIn";
import DetailDocIn from "./features/document/document-in/detail/DetailDocIn";
import CreateDocOut from "./features/document/document-out/CreateDocOut";
import DetailDocOut from "./features/document/document-out/detail/DetailDocOut";
import FilterDocOut from "./features/document/document-out/filter/FilterDocOut";
import CreateUser from "./features/user-mgmt/user/CreateUser";
import FilterUser from "./features/user-mgmt/user/FilterUser";
import Role from "./features/user-mgmt/role/Role";
import DocBook from "./features/setting/doc-book/DocBook";
import Dashboard from "./features/dashboard/Dashboard";
import Login from "./features/auth/Login";
import NotFoundPage from "./features/NotFoundPage";
import theme from "./themes";
import HTTP_DOC_SERVICE from "./config/axios/axios-doc.config";
import setupAxiosInterceptors from "./config/axios/axios-interceptor";
import HTTP_TASK_SERVICE from "./config/axios/axios-task.config";
import HTTP_USER_SERVICE from "./config/axios/axios-user.config";
import CreateDocInDomestic from "./features/document/document-in/create/create-domestic/CreateDocInDomestic";
import CreateDocInIntenational from "./features/document/document-in/create/create-international/CreateDocInIntenational";
import AuthenticationRouter from "./features/auth/AuthenticationRouter";
import UpdateDocInDomestic from "./features/document/document-in/update/update-domestic/UpdateDocInDomestic";
import UpdateDocInInternational from "./features/document/document-in/update/update-international/UpdateDocInInternational";
import HistoryDocIn from "./features/document/document-in/history/HistoryDocIn";
import AuthorizationRouter from "./features/auth/AuthorizationRouter";
import { ACCESS_RIGHT_ENUM } from "./enum/access-right.enum";
import PersonnelList from "./features/personnel/personnel-list/PersonnelList";
import CreatePersonnel from "./features/personnel/create/CreatePersonnel";
import { UpdateDocOut } from "./features/document/document-out/UpdateDocOut";
import PersonnelDetail from "./features/personnel/detail/PersonnelDetail";
import HTTP_HR_SERVICE from "./config/axios/axios-hr.config";
import UpdatePersonnel from "./features/personnel/update/UpdatePersonnel";
import Department from "./features/user-mgmt/department/Department";
import Position from "./features/user-mgmt/position/Position";
import { bindActionCreators } from "@reduxjs/toolkit";
import { decreaseFetch, increaseFetch } from "./store/reducers/common.slice";

// user manual
import { OfficeUM } from "./features/vff-um/OfficeUM";
import TaskUM from "./features/vff-um/TaskUM";
import { HrUM } from "./features/vff-um/HrUM";

// policy
import PrivacyPolicy from "./features/vff-article/PrivacyPolicy";

// task
import CreateTask from "./features/task/create/CreateTask";
import TaskDetail from "./features/task/detail/TaskDetail";
import TaskList from "./features/task/task-list/TaskList";

// style
import "./index.scss";
import UpdateTask from "./features/task/update/UpdateTask";
import ViewListDashboardDocIn from "./features/dashboard/doc-in/ViewListDashboardDocin";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const DOC_IN_ROUTER = {
  path: "doc-in",
  element: <Outlet />,
  children: [
    { path: "", element: <FilterDocIn /> },
    {
      path: ":id",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <DetailDocIn />,
        },
        {
          path: "update-domestic",
          element: <UpdateDocInDomestic />,
        },
        {
          path: "update-international",
          element: <UpdateDocInInternational />,
        },
        {
          path: "history",
          element: <HistoryDocIn />,
        },
      ],
    },
    {
      path: "create",
      element: <Outlet />,
      children: [
        {
          path: "domestic",
          element: <CreateDocInDomestic />,
        },
        {
          path: "international",
          element: <CreateDocInIntenational />,
        },
      ],
    },
  ],
};

const DOC_OUT_ROUTER = {
  path: "doc-out",
  element: <Outlet />,
  children: [
    { path: "", element: <FilterDocOut /> },
    {
      path: ":id",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <DetailDocOut />,
        },
        { path: "update", element: <UpdateDocOut /> },
      ],
    },
    { path: "create", element: <CreateDocOut /> },
  ],
};

const USER_ROUTER = {
  path: "user",
  element: <Outlet />,
  children: [
    { path: "", element: <FilterUser /> },
    { path: "create", element: <CreateUser /> },
  ],
};

const ROLE_ROUTER = {
  path: "role",
  element: (
    <AuthorizationRouter roles={[ACCESS_RIGHT_ENUM.ADMIN]}>
      <Outlet />
    </AuthorizationRouter>
  ),
  children: [{ path: "", element: <Role /> }],
};

const DEPARTMENT_ROUTER = {
  path: "department",
  element: (
    <AuthorizationRouter roles={[ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.HR]}>
      <Outlet />,
    </AuthorizationRouter>
  ),
  children: [{ path: "", element: <Department /> }],
};

const POSITION_ROUTER = {
  path: "position",
  element: (
    <AuthorizationRouter roles={[ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.HR]}>
      <Outlet />,
    </AuthorizationRouter>
  ),
  children: [{ path: "", element: <Position /> }],
};

const DOC_BOOK_ROUTER = {
  path: "doc-book",
  element: (
    // (
    //   <AuthorizationRouter
    //     roles={[
    //       ACCESS_RIGHT_ENUM.ADMIN,
    //       ACCESS_RIGHT_ENUM.STAFF_INTERNATIONAL_DEPARTMENT,
    //       ACCESS_RIGHT_ENUM.CLERICAL_ASSISTANT,
    //     ]}
    //   >
    //   </AuthorizationRouter>
    // ),
    <Outlet />
  ),
  children: [{ path: "", element: <DocBook /> }],
};

const PERSONNEL_ROUTER = {
  path: "personnel",
  element: (
    <AuthorizationRouter roles={[ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.HR]}>
      <Outlet />
    </AuthorizationRouter>
  ),
  children: [
    {
      path: "",
      element: <PersonnelList />,
    },
    {
      path: "create-personnel",
      element: <CreatePersonnel />,
    },
    {
      path: "update-personnel",
      element: (
        <AuthorizationRouter roles={[ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.HR]}>
          <Outlet />
        </AuthorizationRouter>
      ),
      children: [{ path: ":id", element: <UpdatePersonnel /> }],
    },
    {
      path: "detail-personnel",
      element: (
        <AuthorizationRouter roles={[ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.HR]}>
          <Outlet />
        </AuthorizationRouter>
      ),
      children: [{ path: ":id", element: <PersonnelDetail /> }],
    },
  ],
};

const USER_MANUAL_ROUTER = {
  path: "user-manual",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <></>,
    },
    {
      path: "office",
      element: <OfficeUM />,
    },
    {
      path: "task",
      element: <TaskUM />,
    },
    {
      path: "hr",
      element: <HrUM />,
    },
  ],
};

const TASK_ROUTER = {
  path: "task",
  element: (
    // <AuthorizationRouter roles={[ACCESS_RIGHT_ENUM.ADMIN]}>
    <Outlet />
    // </AuthorizationRouter>
  ),
  children: [
    {
      path: "",
      element: <TaskList />,
    },
    {
      path: "create",
      element: <CreateTask />,
    },
    {
      path: ":id",
      element: <TaskDetail />,
    },
    {
      path: ":id/update",
      element: <UpdateTask />,
    },
  ],
};

const DASHBOARD_ROUTER = {
  path: "dashboard",
  element: (
    <AuthorizationRouter roles={[ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.DASHBOARD]}>
      <Outlet />
    </AuthorizationRouter>
  ),
  children: [
    {
      path: "",
      element: <Dashboard />,
    },
    {
      path: "doc-ins",
      element: <ViewListDashboardDocIn />,
    },
  ],
};

// config router
const router = createBrowserRouter([
  {
    path: "",
    element: (
      <AuthenticationRouter>
        <Layout />
      </AuthenticationRouter>
    ),
    children: [
      {
        path: "",
        element: <Navigate to={"/doc-in"} replace />,
      },
      // {
      //   path: "dashboard",
      //   element: <Dashboard />,
      // },
      DOC_IN_ROUTER,
      DOC_OUT_ROUTER,
      USER_ROUTER,
      ROLE_ROUTER,
      DEPARTMENT_ROUTER,
      POSITION_ROUTER,
      DOC_BOOK_ROUTER,
      PERSONNEL_ROUTER,
      TASK_ROUTER,
      USER_MANUAL_ROUTER,
      DASHBOARD_ROUTER,
      {
        path: "404",
        element: <NotFoundPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: "article/privacy-policy",
    element: <PrivacyPolicy />,
  },

  {
    path: "login",
    element: <Login />,
  },
  {
    path: "404",
    element: <NotFoundPage />,
  },
]);

const actions = bindActionCreators({ increaseFetch, decreaseFetch }, store.dispatch);

// config axios
setupAxiosInterceptors(
  () => () => {},
  () => actions.increaseFetch(1),
  () => actions.decreaseFetch(1),
  HTTP_TASK_SERVICE
);

setupAxiosInterceptors(
  () => () => {},
  () => actions.increaseFetch(1),
  () => actions.decreaseFetch(1),
  HTTP_DOC_SERVICE
);

setupAxiosInterceptors(
  () => () => {},
  () => actions.increaseFetch(1),
  () => actions.decreaseFetch(1),
  HTTP_USER_SERVICE
);

setupAxiosInterceptors(
  () => () => {},
  () => actions.increaseFetch(1),
  () => actions.decreaseFetch(1),
  HTTP_HR_SERVICE
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
