import { Typography, Stack, Button } from "@mui/material";
import React, { FC } from "react";
import { spacing_size } from "../../themes/size";
import ModalContainer from "./ModalContainer";

interface ConfirmModalProps {
  title: string;
  children: React.ReactNode;
  openModal: boolean;
  txtCancelBtn?: string;
  txtConfirmBtn?: string;
  onCloseModal: () => void;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmModal: FC<ConfirmModalProps> = (props) => {
  const {
    title,
    children,
    openModal,
    txtCancelBtn = "Hủy",
    txtConfirmBtn = "Xác nhận",
    onCloseModal: handleCloseModal,
    onCancel: handleCancel,
    onConfirm: handleConfirm,
  } = props;
  return (
    <ModalContainer open={openModal} title={title} onClose={handleCloseModal}>
      <>
        {children}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={spacing_size}
          sx={{
            mt: 2,
            justifyContent: "end",
          }}
        >
          <Button variant="contained" color="cancel" type="button" onMouseDown={handleCancel}>
            {txtCancelBtn}
          </Button>
          <Button variant="contained" color="primary" type="button" onMouseDown={handleConfirm}>
            {txtConfirmBtn}
          </Button>
        </Stack>
      </>
    </ModalContainer>
  );
};

export default ConfirmModal;
