import { Grid } from "@mui/material";
import React, { FC } from "react";
import { spacing_size } from "../../../themes/size";
interface ItemInformationJob {
  title: string;
  desc: string | any;
}
const ItemInformationJob: FC<ItemInformationJob> = (props) => {
  const { title, desc } = props;
  return (
    <>
      <Grid
        container
        spacing={spacing_size}
        sx={{
          borderBottom: "1px solid #e3e3e3",
          padding: "15px",
          marginLeft: "0px !important",
          width: "100%",
        }}
      >
        {" "}
        <Grid item md={4} xs={12}>
          <div>{title}</div>
        </Grid>
        <Grid item md={8} xs={12}>
          <div>{desc}</div>
        </Grid>
      </Grid>
    </>
  );
};

export default ItemInformationJob;
