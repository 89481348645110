import { EnumCommon } from "../enum/common.enum";

export const getDescriptionEnum = (code: string, data: EnumCommon<unknown>[]) => {
  for (const item of data) {
    if (code === item.code) {
      return item.description;
    }
  }
  return "";
};

export const getItemEnum = (code: string, data: EnumCommon<unknown>[]) => {
  for (const item of data) {
    if (code === item.code) {
      return item;
    }
  }
  return null;
};
