import { Typography } from "@mui/material";
import { OptionProps, components } from "react-select";
import { UserModel } from "../../model/user.model";

const OptionSelectUser = (props: OptionProps<UserModel>) => {
  return (
    <components.Option {...props}>
      <Typography>{props.data.fullName}</Typography>
      <Typography fontSize={"0.8rem"}>{props.data.email}</Typography>
    </components.Option>
  );
};

export default OptionSelectUser;
