import { SearchOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { FilterEmployeeModel } from "../../../model/personnel.model";
import FileSaver from "file-saver";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import React, { FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { exportEmoloyeeApi } from "../../../api/hr.api";
import FieldContainer from "../../../components/form/FieldContainer";
import XCircleIcon from "../../../components/icons/XCircleIcon";
import { VIEW_DATE_FORMAT } from "../../../constants/date-time.constant";
import { SIZE_DEFAULT } from "../../../constants/page.constant";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { spacing_size } from "../../../themes/size";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { filterEmployeeApiAsync, setFilterPersonnel, setPagePersonnel } from "../redux/personnel.reducer";
import { OptionObject } from "./PersonnelList";
import ClearIcon from "@mui/icons-material/Clear";
import { active_contract_color, primary_color } from "../../../themes/color";
interface HeaderPersonnelListProps {
  filterOptions: OptionObject[];
  setFilterOptions: React.Dispatch<React.SetStateAction<OptionObject[]>>;
  initValue: FilterEmployeeModel;
  setBodyFilter: React.Dispatch<SetStateAction<FilterEmployeeModel>>;
  bodyFilter: FilterEmployeeModel;
  setInitValue: React.Dispatch<SetStateAction<FilterEmployeeModel>>;
}

interface InitialValuesForm {
  keyword: string | null;
}

const HeaderPersonnelList: FC<HeaderPersonnelListProps> = (props) => {
  const theme = useTheme();

  const { filterOptions, setFilterOptions, initValue, setBodyFilter, bodyFilter, setInitValue } = props;

  const initBodyFilter: FilterEmployeeModel = {
    keyword: null,
    phone: null,
    position: null,
    department: null,
    startDate: null,
    endDate: null,
    status: null,
    type: null,
    taxCode: null,
    identityCard: null,
    passport: null,
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const filterPersonnel = useAppSelector((state) => state.personnel.filterPersonnel);
  const page = useAppSelector((state) => state.personnel.pagePersonnel);
  const reloadFilterPersonnel = useAppSelector((state) => state.personnel.reloadFilterPersonnel);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [open, setOpen] = React.useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const initialValues: InitialValuesForm = {
    keyword: initValue?.keyword,
  };
  const handleMenuItemClick = (item: OptionObject, indexItem: number) => {
    let listNew = filterOptions.map((item, index) => {
      if (index === indexItem)
        return {
          ...item,
          check: !item.check,
        };
      return item;
    });
    setFilterOptions(listNew);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const validationSchema = Yup.object({});

  const moveToPageCreate = () => {
    navigate("/personnel/create-personnel");
  };

  const buildBodyFilter = (value: InitialValuesForm) => {
    const body = {
      ...value,
      keyword: value?.keyword ? value?.keyword : null,
      position: null,
      department: null,
    };
    return body;
  };

  const onSubmitSearch = (value: InitialValuesForm, formik: FormikHelpers<InitialValuesForm>) => {
    const body = buildBodyFilter(value);
    setBodyFilter({ ...value });
    if (page === 0) {
      const pageable = {
        page: 0,
        size: SIZE_DEFAULT,
      };
      // dispatch(setFilterPersonnel({ ...value }));
      dispatch(filterEmployeeApiAsync({ body, pageable }));
    } else {
      dispatch(setPagePersonnel(0));
    }
  };
  const handleExcel = async () => {
    try {
      const res = await exportEmoloyeeApi(bodyFilter);
      FileSaver.saveAs(res.data, `Danhsachnhansu_${moment(new Date()).format(VIEW_DATE_FORMAT)}.xlsx`);
      toast.success("Xuất excel thành công");
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const handleClear = (formik: FormikHelpers<InitialValuesForm>) => {
    formik.resetForm();
    if (page === 0) {
      const pageable = { page, size: SIZE_DEFAULT };
      dispatch(filterEmployeeApiAsync({ body: initBodyFilter, pageable }));
    } else {
      dispatch(setPagePersonnel(0));
    }
    setInitValue({ ...initValue, keyword: "" });
    setBodyFilter({ ...bodyFilter, keyword: null });
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12} md={12} lg={2}>
          <Typography sx={{ fontWeight: 600, fontSize: "1.6rem" }}>Danh sách nhân sự</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitSearch}
            validateOnBlur={false}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={12} lg={8}>
                    <FieldContainer
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchOutlined />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          {formik?.values?.keyword && (
                            <IconButton sx={{ marginRight: "0.6rem" }} onClick={() => handleClear(formik)}>
                              <ClearIcon sx={{ fontSize: "1rem" }} />
                            </IconButton>
                          )}
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              borderLeft: 0,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                          >
                            Tìm kiếm
                          </Button>
                        </InputAdornment>
                      }
                      fullWidth
                      name="keyword"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.keyword}
                      placeholder="Nhập Mã nhân viên/ Email/ Họ và tên để tìm kiếm"
                      notched={true}
                      variant={"input"}
                      id={"keyword"}
                      sx={{
                        ".MuiOutlinedInput-input": {
                          p: "0.45rem 0.75rem",
                        },
                        pr: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={2}>
                    <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" fullWidth>
                      <Button
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        color="primary"
                        onClick={handleToggle}
                        startIcon={<AddIcon sx={{ color: primary_color }} />}
                      >
                        Thêm bộ lọc
                      </Button>
                    </ButtonGroup>
                    <Popper
                      sx={{
                        zIndex: 1,
                      }}
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList id="split-button-menu" autoFocusItem>
                                {filterOptions.map((option, index) => (
                                  <MenuItem
                                    key={option.key}
                                    // selected={index === selectedIndex}
                                    onClick={() => handleMenuItemClick(option, index)}
                                  >
                                    <Checkbox checked={option.check}></Checkbox>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </Grid>
                  <Grid item xs={12} md={12} lg={2}>
                    <Button variant="outlined" onClick={handleExcel} fullWidth>
                      Xuất excel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>

        <Button
          variant="contained"
          onClick={moveToPageCreate}
          startIcon={<XCircleIcon />}
          sx={{
            [theme.breakpoints.down("md")]: {
              marginTop: "0.8rem",
              width: "100%",
            },
          }}
        >
          Thêm mới
        </Button>
      </Grid>
    </>
  );
};

export default HeaderPersonnelList;
