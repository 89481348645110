import { Typography } from "@mui/material";
import React, { FC } from "react";

interface TitleFormGeneralProps {
  title: string | undefined | null;
}

const TitleFormGeneral: FC<TitleFormGeneralProps> = (props) => {
  const { title } = props;
  return <Typography sx={{ fontSize: "1.2rem", fontWeight: "600", color: "#323E5D" }}>{title}</Typography>;
};

export default TitleFormGeneral;
