import axios from "axios";
import { COMMON_MSG } from "../constants/msg/msg.constant";
import { HttpStatusCode } from "axios";

export const getErrorMsgAxios = (error: any, defaultReturn = COMMON_MSG.common) => {
  return error?.response?.data?.title || error?.response?.data?.message || defaultReturn;
};

export const getErrorMsgAction = (action: any, defaultReturn = COMMON_MSG.common) => {
  return action?.error?.message || defaultReturn;
};

export const isTooManyRequests = (error: any) => {
  if (axios.isAxiosError(error) && error.status === HttpStatusCode.TooManyRequests) {
    return true;
  }

  return false;
};
