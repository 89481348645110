import { List } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { toggleCollapseSidebar } from "../../store/reducers/common.slice";
import ItemSidebar from "../sidebar/ItemSidebar";

interface MainDrawerProps {
  data: any;
}

const MainDrawer = ({ data }: MainDrawerProps) => {
  const dispatch = useAppDispatch();
  const isCollapseSidebar = useAppSelector((state) => state.common.isCollapseSidebar);

  return (
    <div style={{ position: "relative" }}>
      <SwipeableDrawer
        anchor={"left"}
        open={isCollapseSidebar}
        onClose={() => {
          dispatch(toggleCollapseSidebar(false));
        }}
        onOpen={() => toggleCollapseSidebar(true)}
        sx={{
          position: "relative",
        }}
      >
        <div
          style={{
            width: `${280}px`,
            height: "calc(100vh)",
            overflowX: "hidden",
            backgroundColor: "#061748",
            overflowY: "auto",
          }}
        >
          <div style={{ position: "relative", height: "100%" }}>
            <List sx={{ p: 0 }}>{data && data.map((item: any) => <ItemSidebar key={item.id} {...item} />)}</List>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default MainDrawer;
