import { Box, Grid } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { hrDepartmentApi, hrPositionApi } from "../../../api/hr.api";
import FieldContainer from "../../../components/form/FieldContainer";
import LabelField from "../../../components/form/LabelField";
import { MAX_SIZE_EXPORT, SIZE_DEFAULT } from "../../../constants/page.constant";
import { FilterEmployeeModel, HrDepartmentModel, HrPositionModel } from "../../../model/personnel.model";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { isEmptyObj } from "../../../utils/object.util";
import { OptionCommon } from "../common/constants/personnel.constant";
import FormSearch from "./FormSearch";
import HeaderPersonnelList from "./HeaderPersonnelList";
import ResultPersonnel from "./ResultPersonnel";
import { PASSPORT_PATTERN } from "../../../constants/regex.constant";
import { SelectPersonnelOptions, TYPE_PERSONNEL_ENUM } from "../../../enum/personnel.enum";
import { EnumCommon } from "../../../enum/common.enum";
import { useAppDispatch } from "../../../store/hook";
import { filterEmployeeApiAsync } from "../redux/personnel.reducer";

export interface OptionObject {
  key: string;
  value: string;
  component: (formik: FormikProps<FilterEmployeeModel>, options?: any, handleChange?: any) => React.ReactNode;
  check: boolean;
}

export const CreateOptions: OptionObject[] = [
  {
    key: "phone",
    value: "Số điện thoại",
    component: (formik: FormikProps<FilterEmployeeModel>) => (
      <Grid item md={4} xs={12}>
        <FieldContainer
          fullWidth
          name="phone"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="text"
          inputProps={{ maxLength: 255 }}
          value={formik.values.phone}
          placeholder="Nhập thông tin"
          maxRows={12}
          label="Số điện thoại"
          notched={true}
          variant={"input"}
          id={"phone"}
        />
      </Grid>
    ),
    check: false,
  },
  {
    key: "position",
    value: "Chức vụ",
    component: (
      formik: FormikProps<FilterEmployeeModel>,
      hrPositionDetail: HrPositionModel[],
      handleChangePosition: any
    ) => (
      <Grid item md={4} xs={12}>
        <FieldContainer
          fullWidth
          // inputProps={{ maxLength: 255 }}
          name="positionId"
          type="text"
          onBlur={formik.handleBlur}
          onChange={handleChangePosition(formik)}
          value={formik.values.position}
          placeholder="Nhập/chọn thông tin"
          label="Chức vụ"
          variant={"react-select"}
          id={"position"}
          options={hrPositionDetail}
          getOptionLabel={(option: HrPositionModel) => option.name}
          getOptionValue={(option: HrPositionModel) => option.id}
        />
      </Grid>
    ),
    check: false,
  },
  {
    key: "department",
    value: "Bộ phận công tác",
    component: (
      formik: FormikProps<FilterEmployeeModel>,
      hrDepartmentDetail: HrDepartmentModel[],
      handleChangeDepartment: any
    ) => (
      <Grid item md={4} xs={12}>
        <FieldContainer
          fullWidth
          name="department"
          type="text"
          onBlur={formik.handleBlur}
          onChange={handleChangeDepartment(formik)}
          value={formik.values.department}
          placeholder="Nhập/chọn thông tin"
          label="Bộ phận công tác"
          variant={"react-select"}
          id={"department"}
          options={hrDepartmentDetail}
          getOptionLabel={(option: HrDepartmentModel) => option.name}
          getOptionValue={(option: HrDepartmentModel) => option.code}
        />
      </Grid>
    ),
    check: false,
  },
  {
    key: "startDate",
    value: "Thời gian bắt đầu công tác",
    component: (formik: FormikProps<FilterEmployeeModel>) => (
      <Grid item md={4} xs={12} container>
        <Grid item md={12}>
          {" "}
          <LabelField label={"Thời gian bắt đầu công tác"} name={""} />
        </Grid>
        <Grid item md={5.75} xs={12}>
          <FieldContainer
            name="startDate"
            onBlur={formik.handleBlur}
            value={formik.values.startDate}
            notched={true}
            variant={"date"}
            id={"startDate"}
            placeholderText="Từ ngày"
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={50}
          />
        </Grid>
        <Grid item md={0.5} xs={12}></Grid>
        <Grid item md={5.75} xs={12}>
          <FieldContainer
            name="endDate"
            onBlur={formik.handleBlur}
            value={formik.values.endDate}
            notched={true}
            variant={"date"}
            id={"endDate"}
            placeholderText="Đến ngày"
            maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={50}
          />
        </Grid>
      </Grid>
    ),
    check: false,
  },
  {
    key: "status",
    value: "Trạng thái làm việc",
    component: (formik: FormikProps<FilterEmployeeModel>, options: OptionCommon[], handleChangeStatus: any) => (
      <Grid item md={4} xs={12}>
        <FieldContainer
          fullWidth
          name="status"
          type="text"
          onBlur={formik.handleBlur}
          onChange={handleChangeStatus(formik)}
          value={formik.values.status}
          placeholder="Nhập/chọn thông tin"
          label="Trạng thái làm việc"
          variant={"react-select"}
          id={"status"}
          options={options}
          getOptionLabel={(option: OptionCommon) => option.label}
          getOptionValue={(option: OptionCommon) => option.value}
        />
      </Grid>
    ),
    check: false,
  },
  {
    key: "type",
    value: "Đối tượng",
    component: (formik: FormikProps<FilterEmployeeModel>, options: OptionCommon[], handleChangeType: any) => (
      <Grid item md={4} xs={12}>
        <FieldContainer
          fullWidth
          name="type"
          type="text"
          onBlur={formik.handleBlur}
          onChange={handleChangeType(formik)}
          value={formik.values.type}
          placeholder="Nhập/chọn thông tin"
          label="Đối tượng"
          variant={"react-select"}
          id={"type"}
          options={SelectPersonnelOptions}
          getOptionLabel={(option: EnumCommon<TYPE_PERSONNEL_ENUM>) => option.description}
          getOptionValue={(option: EnumCommon<TYPE_PERSONNEL_ENUM>) => option.code}
        />
      </Grid>
    ),
    check: false,
  },
  {
    key: "taxCode",
    value: "Mã số thuế cá nhân",
    component: (formik: FormikProps<FilterEmployeeModel>) => (
      <Grid item md={4} xs={12}>
        <FieldContainer
          fullWidth
          name="taxCode"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="text"
          inputProps={{ maxLength: 13 }}
          value={formik.values.taxCode}
          placeholder="Nhập thông tin"
          maxRows={12}
          label="Mã số thuế cá nhân"
          notched={true}
          variant={"input"}
          id={"taxCode"}
        />
      </Grid>
    ),
    check: false,
  },
  {
    key: "identityCard",
    value: "Số CMND/CCCD",
    component: (formik: FormikProps<FilterEmployeeModel>) => (
      <Grid item md={4} xs={12}>
        <FieldContainer
          fullWidth
          name="identityCard"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="text"
          inputProps={{ maxLength: 12 }}
          value={formik.values.identityCard}
          placeholder="Nhập thông tin"
          maxRows={12}
          label="Số CMND/CCCD"
          notched={true}
          variant={"input"}
          id={"identityCard"}
          onKeyPress={(event: any) => {
            const regex = new RegExp("^[0-9]+$");
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
              event.preventDefault();
              return false;
            }
          }}
        />
      </Grid>
    ),
    check: false,
  },
  {
    key: "passport",
    value: "Hộ chiếu",
    component: (formik: FormikProps<FilterEmployeeModel>) => (
      <Grid item md={4} xs={12}>
        <FieldContainer
          fullWidth
          inputProps={{ minLength: 8, maxLength: 20 }}
          name="passport"
          type="text"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.passport}
          placeholder="Nhập thông tin"
          label="Số hộ chiếu"
          variant={"input"}
          id={"passport"}
          maxRows={12}
          pattern={PASSPORT_PATTERN}
          onKeyPress={(event: any) => {
            const regex = new RegExp(PASSPORT_PATTERN);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
              event.preventDefault();
              return false;
            }
          }}
        />
      </Grid>
    ),
    check: false,
  },
];
function PersonnelList() {
  const initBodyFilter: FilterEmployeeModel = {
    keyword: null,
    phone: null,
    position: null,
    department: null,
    startDate: null,
    endDate: null,
    status: null,
    type: null,
    taxCode: null,
    identityCard: null,
    passport: null,
  };

  const initialValues: FilterEmployeeModel = {
    keyword: "",
    phone: "",
    department: "",
    position: "",
    startDate: null,
    endDate: null,
    type: "",
    status: "",
    taxCode: "",
    identityCard: "",
    passport: "",
  };

  const location = useLocation();
  const dispatch = useAppDispatch();
  const initStateLocation = location?.state;

  const [stateLocation, setStateLocation] = useState<any>(initStateLocation);
  const [filterOptions, setFilterOptions] = useState<OptionObject[]>(CreateOptions);
  const [hrDepartmentDetail, setHrDepartmentDetail] = useState<HrDepartmentModel[]>([]);
  const [hrPositionDetail, setHrPositionDetail] = useState<HrPositionModel[]>([]);
  const [bodyFilter, setBodyFilter] = useState<FilterEmployeeModel>(initBodyFilter);
  const [initValue, setInitValue] = useState<FilterEmployeeModel>(initialValues);
  const [department, setDepartment] = useState<HrDepartmentModel | null>(null);
  const [position, setPosition] = useState<HrPositionModel | null>(null);
  const [type, setType] = useState<OptionCommon | null>(null);
  const [status, setStatus] = useState<OptionCommon | null>(null);

  // useEffect(() => {
  //   const pageable = { page: 0, size: SIZE_DEFAULT };
  //   dispatch(filterEmployeeApiAsync({ body: initBodyFilter, pageable }));
  // }, []);

  const getHrDepartment = async () => {
    const body: HrDepartmentModel = {
      name: null,
    };
    const params = { page: 0, size: MAX_SIZE_EXPORT };

    await hrDepartmentApi(body, params)
      .then((res) => {
        setHrDepartmentDetail(res?.data);
      })
      .catch((err) => {
        toast.error(getErrorMsgAxios(err));
      });
  };

  const getHrPosition = async () => {
    const body: HrPositionModel = {
      name: null,
    };
    const params = { page: 0, size: MAX_SIZE_EXPORT };

    await hrPositionApi(body, params)
      .then((res) => {
        setHrPositionDetail(res?.data);
      })
      .catch((err) => {
        toast.error(getErrorMsgAxios(err));
      });
  };

  useEffect(() => {
    getHrDepartment();
    getHrPosition();
    // dispatch(
    //   setFilterPersonnel({
    //     keyword: null,
    //     phone: null,
    //     position: null,
    //     department: null,
    //     startDate: null,
    //     endDate: null,
    //     status: null,
    //     type: null,
    //     taxCode: null,
    //     identityCard: null,
    //     passport: null,
    //   })
    // );
    if (!isEmptyObj(stateLocation)) {
      if (stateLocation) {
        const body = {
          keyword: stateLocation?.bodyFilter?.keyword || null,
          phone: stateLocation?.bodyFilter?.phone || null,
          department: stateLocation?.department || null,
          position: stateLocation?.position || null,
          startDate: stateLocation?.bodyFilter?.startDate || null,
          endDate: stateLocation?.bodyFilter?.endDate || null,
          status: stateLocation?.status || null,
          type: stateLocation?.type || null,
          taxCode: stateLocation?.bodyFilter?.taxCode || null,
          identityCard: stateLocation?.bodyFilter?.identityCard || null,
          passport: stateLocation?.bodyFilter?.passport || null,
        };
        setInitValue({
          ...body,
          startDate: stateLocation?.bodyFilter?.startDate ? new Date(stateLocation?.bodyFilter?.startDate) : null,
          endDate: stateLocation?.bodyFilter?.endDate ? new Date(stateLocation?.bodyFilter?.endDate) : null,
        });
        setBodyFilter({
          ...body,
          department: stateLocation?.bodyFilter?.department,
          position: stateLocation?.bodyFilter?.position,
          status: stateLocation?.bodyFilter?.status,
          type: stateLocation?.bodyFilter?.type,
        });
        setDepartment(stateLocation?.department);
        setPosition(stateLocation?.position);
        setType(stateLocation?.type);
        setStatus(stateLocation?.status);
      }
    } else {
      setInitValue(initialValues);
    }
  }, []);

  return (
    <Box>
      <HeaderPersonnelList
        setBodyFilter={setBodyFilter}
        bodyFilter={bodyFilter}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        initValue={initValue}
        setInitValue={setInitValue}
      />
      <FormSearch
        filterOptions={filterOptions}
        dataDepartments={hrDepartmentDetail}
        dataPositions={hrPositionDetail}
        setDepartment={setDepartment}
        setPosition={setPosition}
        setType={setType}
        setStatus={setStatus}
        setBodyFilter={setBodyFilter}
        bodyFilter={bodyFilter}
        initValue={initValue}
        setStateLocation={setStateLocation}
        setInitValue={setInitValue}
      />
      <ResultPersonnel
        bodyFilter={bodyFilter}
        department={department}
        position={position}
        type={type}
        status={status}
      />
    </Box>
  );
}

export default PersonnelList;
