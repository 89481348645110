import * as Yup from "yup";
import { IDENTITY_DOCUMENTS_ENUM, OTHER_DOCUMENTS_ENUM, POSITION_ENUM } from "../../../../enum/personnel.enum";
import { FORM_MSG } from "../../../../constants/msg/msg.constant";
import { PHONE_REGEX, EMAIL_REGEX, CODE_EMPLOYEE_REGEX } from "../../../../constants/regex.constant";

export const label_form = {
  fullName: "Họ và tên",
  dob: "Ngày sinh",
  gender: "Giới tính",
  phoneNumber: "Số điện thoại",
  email: "Email công việc",
  maritalStatus: "Tình trạng hôn nhân",
  nationalityId: "Quốc tịch",
  ethnic: "Dân tộc",
  religion: "Tôn giáo",
  positionId: "Chức vụ",
  departmentId: "Bộ phận công tác",
  startDate: "Thời gian bắt đầu công tác",
  identityCard: "Số CMND/CCCD",
  idcIssueDate: "Ngày cấp",
  idcIssuePlace: "Nơi cấp",
  passport: "Số hộ chiếu",
  passportIssueDate: "Ngày cấp",
  passportIssuePlace: "Nơi cấp",
  partyPosition: "Chức vụ Đảng viên",
  partyJoinDate: "Ngày kết nạp",
  partyJoinPlace: "Nơi kết nạp",
  unionPosition: "Chức vụ Đoàn viên",
  unionJoinDate: "Ngày kết nạp",
  unionJoinPlace: "Nơi kết nạp",
  rewards: "Khen thưởng",
  disciplines: "Kỷ luật",
  fullNameContactInfo: "Họ và tên",
  phoneNumberContactInfo: "Số điện thoại",
  relativesContactInfo: "Mối quan hệ với CBNV",
};

// khác
export const validationOtherSchema = Yup.object({
  fullName: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Họ và tên")),
  code: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Mã cá nhân"))
    .max(6, "Mã cá nhân phải 6 ký tự")
    .min(6, "Mã cá nhân phải 6 ký tự")
    .matches(CODE_EMPLOYEE_REGEX, "Mã cá nhân không đúng định dạng"),
  dob: Yup.date()
    .nullable()
    // .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
    .required(FORM_MSG.requiredFieldMsg("Ngày sinh"))
    .test("dob", "Người lao động phải từ đủ 18 tuổi trở lên", function (value: any, ctx: any) {
      const dob = new Date(value);
      const validDate = new Date();
      const valid = validDate.getFullYear() - dob.getFullYear() >= 18;
      return !valid ? ctx.createError() : valid;
    }),
  gender: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Giới tính")),
  phoneNumber: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Số điện thoại"))
    .max(20, "Số điện thoại nhập vào vượt quá quy định")
    .matches(PHONE_REGEX, "Số điện thoại nhập vào không đúng định dạng"),
  email: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Email"))
    .matches(EMAIL_REGEX, "Không đúng định dạng email!"),
  positionId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Chức vụ")),
  departmentId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Bộ phận công tác")),
  // managerId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Quản lý trực tiếp")),
  // startDate: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Thời gian bắt đầu công tác")),
  identityCard: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số CCCD/CMND"));
      }
      return schema;
    })
    .min(9, FORM_MSG.minLengthFieldMsg(9))
    .max(12, FORM_MSG.maxLengthFieldMsg(12)),
  idcIssueDate: Yup.date()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày cấp"));
      }
      return schema;
    }),
  idcIssuePlace: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi cấp"));
      }
      return schema;
    }),
  passport: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số hộ chiếu"));
      }
      return schema;
    })
    .min(8, FORM_MSG.minLengthFieldMsg(8))
    .max(20, FORM_MSG.maxLengthFieldMsg(20)),
  passportIssueDate: Yup.date()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày cấp"));
      }
      return schema;
    }),
  passportIssuePlace: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi cấp"));
      }
      return schema;
    }),
  partyPosition: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Chức vụ đảng viên"));
      }
      return schema;
    }),
  partyJoinDate: Yup.date()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày kết nạp"));
      }
      return schema;
    }),
  partyJoinPlace: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi kết nạp"));
      }
      return schema;
    }),
  unionPosition: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Chức vụ đoàn viên"));
      }
      return schema;
    }),
  unionJoinDate: Yup.date()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày kết nạp"));
      }
      return schema;
    }),
  unionJoinPlace: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi kết nạp"));
      }
      return schema;
    }),
});

// lãnh đạo các ban
export const validationLeadersAndDepartmentsSchema = Yup.object({
  fullName: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Họ và tên")),
  code: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Mã cá nhân"))
    .max(6, "Mã cá nhân phải 6 ký tự")
    .min(6, "Mã cá nhân phải 6 ký tự")
    .matches(CODE_EMPLOYEE_REGEX, "Mã cá nhân không đúng định dạng"),
  dob: Yup.date()
    .nullable()
    // .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
    .required(FORM_MSG.requiredFieldMsg("Ngày sinh"))
    .test("dob", "Người lao động phải từ đủ 18 tuổi trở lên", function (value: any, ctx: any) {
      const dob = new Date(value);
      const validDate = new Date();
      const valid = validDate.getFullYear() - dob.getFullYear() >= 18;
      return !valid ? ctx.createError() : valid;
    }),
  gender: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Giới tính")),
  phoneNumber: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Số điện thoại"))
    .max(20, "Số điện thoại nhập vào vượt quá quy định")
    .matches(PHONE_REGEX, "Số điện thoại nhập vào không đúng định dạng"),
  email: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Email"))
    .matches(EMAIL_REGEX, "Không đúng định dạng email!"),
  positionId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Chức vụ")),
  departmentId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Bộ phận công tác")),
  // managerId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Quản lý trực tiếp")),
  // startDate: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Thời gian bắt đầu công tác")),
  identityCard: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số CCCD/CMND"));
      }
      return schema;
    })
    .min(9, FORM_MSG.minLengthFieldMsg(9))
    .max(12, FORM_MSG.maxLengthFieldMsg(12)),
  idcIssueDate: Yup.date()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày cấp"));
      }
      return schema;
    }),
  idcIssuePlace: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi cấp"));
      }
      return schema;
    }),
  passport: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số hộ chiếu"));
      }
      return schema;
    })
    .min(8, FORM_MSG.minLengthFieldMsg(8))
    .max(20, FORM_MSG.maxLengthFieldMsg(20)),
  passportIssueDate: Yup.date()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày cấp"));
      }
      return schema;
    }),
  passportIssuePlace: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi cấp"));
      }
      return schema;
    }),
  partyPosition: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Chức vụ đảng viên"));
      }
      return schema;
    }),
  partyJoinDate: Yup.date()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày kết nạp"));
      }
      return schema;
    }),
  partyJoinPlace: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi kết nạp"));
      }
      return schema;
    }),
  unionPosition: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Chức vụ đoàn viên"));
      }
      return schema;
    }),
  unionJoinDate: Yup.date()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày kết nạp"));
      }
      return schema;
    }),
  unionJoinPlace: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi kết nạp"));
      }
      return schema;
    }),
});

// chuyên gia và ban huấn luyện
export const validationExpertsAndCoachingStaffSchema = Yup.object({
  fullName: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Họ và tên")),
  code: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Mã cá nhân"))
    .matches(CODE_EMPLOYEE_REGEX, "Mã cá nhân không đúng định dạng"),
  dob: Yup.date()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Ngày sinh"))
    .test("dob", "Người lao động phải từ đủ 18 tuổi trở lên", function (value: any, ctx: any) {
      const dob = new Date(value);
      const validDate = new Date();
      const valid = validDate.getFullYear() - dob.getFullYear() >= 18;
      return !valid ? ctx.createError() : valid;
    }),
  gender: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Giới tính")),
  phoneNumber: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Số điện thoại"))
    .max(20, "Số điện thoại nhập vào vượt quá quy định")
    .matches(PHONE_REGEX, "Số điện thoại nhập vào không đúng định dạng"),
  email: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Email"))
    .matches(EMAIL_REGEX, "Không đúng định dạng email!"),
  positionId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Chức vụ")),
  departmentId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Bộ phận công tác")),
  identityCard: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số CCCD/CMND"));
      }
      return schema;
    })
    .min(9, FORM_MSG.minLengthFieldMsg(9))
    .max(12, FORM_MSG.maxLengthFieldMsg(12)),
  idcIssueDate: Yup.date()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày cấp"));
      }
      return schema;
    }),
  idcIssuePlace: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi cấp"));
      }
      return schema;
    }),
  passport: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số hộ chiếu"));
      }
      return schema;
    })
    .min(8, FORM_MSG.minLengthFieldMsg(8))
    .max(20, FORM_MSG.maxLengthFieldMsg(20)),
  passportIssueDate: Yup.date()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày cấp"));
      }
      return schema;
    }),
  passportIssuePlace: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi cấp"));
      }
      return schema;
    }),
  workPermitNumber: Yup.string()
    .nullable()
    .when("selectDocOther", (selectDocOther: OTHER_DOCUMENTS_ENUM[], schema: any) => {
      if (selectDocOther?.includes(OTHER_DOCUMENTS_ENUM.WORK_PREMIT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số giấy phép lao động"));
      }
      return schema;
    }),
  workPermitFromDate: Yup.date()
    .nullable()
    .when("selectDocOther", (selectDocOther: OTHER_DOCUMENTS_ENUM[], schema: any) => {
      if (selectDocOther?.includes(OTHER_DOCUMENTS_ENUM.WORK_PREMIT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Thời hạn từ ngày"));
      }
      return schema;
    }),
  workPermitToDate: Yup.date()
    .nullable()
    .when("selectDocOther", {
      is: (selectDocOther: OTHER_DOCUMENTS_ENUM, schema: any) =>
        selectDocOther?.includes(OTHER_DOCUMENTS_ENUM.WORK_PREMIT),
      then: Yup.date()
        .min(Yup.ref("workPermitFromDate"), "Thời hạn đến ngày phải lớn hơn Thời hạn từ ngày.")
        .nullable()
        .required(FORM_MSG.requiredFieldMsg("Thời hạn đến ngày")),
      otherwise: Yup.date().nullable(),
    }),
  temporaryResidenceCard: Yup.string()
    .nullable()
    .when("selectDocOther", (selectDocOther: OTHER_DOCUMENTS_ENUM[], schema: any) => {
      if (selectDocOther?.includes(OTHER_DOCUMENTS_ENUM.TEMPORARY)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số thẻ tạm trú"));
      }
      return schema;
    }),
  trcExpiredDate: Yup.date()
    .nullable()
    .when("selectDocOther", (selectDocOther: OTHER_DOCUMENTS_ENUM[], schema: any) => {
      if (selectDocOther?.includes(OTHER_DOCUMENTS_ENUM.TEMPORARY)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Thời hạn "));
      }
      return schema;
    }),
});

// bộ phận điều hành
export const validationOperatingPartsSchema = Yup.object({
  fullName: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Họ và tên")),
  code: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Mã cá nhân"))
    .max(6, "Mã cá nhân phải 6 ký tự")
    .min(6, "Mã cá nhân phải 6 ký tự")
    .matches(CODE_EMPLOYEE_REGEX, "Mã cá nhân không đúng định dạng"),
  dob: Yup.date()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Ngày sinh"))
    .test("dob", "Người lao động phải từ đủ 18 tuổi trở lên", function (value: any, ctx: any) {
      const dob = new Date(value);
      const validDate = new Date();
      const valid = validDate.getFullYear() - dob.getFullYear() >= 18;
      return !valid ? ctx.createError() : valid;
    }),
  gender: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Giới tính")),
  phoneNumber: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Số điện thoại"))
    .max(20, "Số điện thoại nhập vào vượt quá quy định")
    .matches(PHONE_REGEX, "Số điện thoại nhập vào không đúng định dạng"),
  email: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Email"))
    .matches(EMAIL_REGEX, "Không đúng định dạng email!"),
  positionId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Chức vụ")),
  departmentId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Bộ phận công tác")),
  // managerId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Quản lý trực tiếp")),
  // startDate: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Thời gian bắt đầu công tác")),
  identityCard: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số CCCD/CMND"));
      }
      return schema;
    })
    .min(9, FORM_MSG.minLengthFieldMsg(9))
    .max(12, FORM_MSG.maxLengthFieldMsg(12)),
  idcIssueDate: Yup.date()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày cấp"));
      }
      return schema;
    }),
  idcIssuePlace: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi cấp"));
      }
      return schema;
    }),
  passport: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số hộ chiếu"));
      }
      return schema;
    })
    .min(8, FORM_MSG.minLengthFieldMsg(8))
    .max(20, FORM_MSG.maxLengthFieldMsg(20)),
  passportIssueDate: Yup.date()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày cấp"));
      }
      return schema;
    }),
  passportIssuePlace: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi cấp"));
      }
      return schema;
    }),
  partyPosition: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Chức vụ đảng viên"));
      }
      return schema;
    }),
  partyJoinDate: Yup.date()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày kết nạp"));
      }
      return schema;
    }),
  partyJoinPlace: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi kết nạp"));
      }
      return schema;
    }),
  unionPosition: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Chức vụ đoàn viên"));
      }
      return schema;
    }),
  unionJoinDate: Yup.date()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày kết nạp"));
      }
      return schema;
    }),
  unionJoinPlace: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi kết nạp"));
      }
      return schema;
    }),
});

// các đơn vị trực thuộc
export const validationTheSubordinateUnitsSchema = Yup.object({
  fullName: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Họ và tên")),
  dob: Yup.date()
    .nullable()
    // .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
    .required(FORM_MSG.requiredFieldMsg("Ngày sinh"))
    .test("dob", "Người lao động phải từ đủ 18 tuổi trở lên", function (value: any, ctx: any) {
      const dob = new Date(value);
      const validDate = new Date();
      const valid = validDate.getFullYear() - dob.getFullYear() >= 18;
      return !valid ? ctx.createError() : valid;
    }),
  gender: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Giới tính")),
  phoneNumber: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Số điện thoại"))
    .max(20, "Số điện thoại nhập vào vượt quá quy định")
    .matches(PHONE_REGEX, "Số điện thoại nhập vào không đúng định dạng"),
  email: Yup.string()
    .nullable()
    .required(FORM_MSG.requiredFieldMsg("Email"))
    .matches(EMAIL_REGEX, "Không đúng định dạng email!"),
  positionId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Chức vụ")),
  departmentId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Bộ phận công tác")),
  // managerId: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Quản lý trực tiếp")),
  // startDate: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Thời gian bắt đầu công tác")),
  identityCard: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số CCCD/CMND"));
      }
      return schema;
    })
    .min(9, FORM_MSG.minLengthFieldMsg(9))
    .max(12, FORM_MSG.maxLengthFieldMsg(12)),
  idcIssueDate: Yup.date()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày cấp"));
      }
      return schema;
    }),
  idcIssuePlace: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi cấp"));
      }
      return schema;
    }),
  passport: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Số hộ chiếu"));
      }
      return schema;
    })
    .min(8, FORM_MSG.minLengthFieldMsg(8))
    .max(20, FORM_MSG.maxLengthFieldMsg(20)),
  passportIssueDate: Yup.date()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày cấp"));
      }
      return schema;
    }),
  passportIssuePlace: Yup.string()
    .nullable()
    .when("identity", (identity: IDENTITY_DOCUMENTS_ENUM[], schema: any) => {
      if (identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi cấp"));
      }
      return schema;
    }),
  partyPosition: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Chức vụ đảng viên"));
      }
      return schema;
    }),
  partyJoinDate: Yup.date()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày kết nạp"));
      }
      return schema;
    }),
  partyJoinPlace: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi kết nạp"));
      }
      return schema;
    }),
  unionPosition: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Chức vụ đoàn viên"));
      }
      return schema;
    }),
  unionJoinDate: Yup.date()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Ngày kết nạp"));
      }
      return schema;
    }),
  unionJoinPlace: Yup.string()
    .nullable()
    .when("selectPosition", (selectPosition: POSITION_ENUM[], schema: any) => {
      if (selectPosition?.includes(POSITION_ENUM.UNION_MEMBER)) {
        return schema.required(FORM_MSG.requiredFieldMsg("Nơi kết nạp"));
      }
      return schema;
    }),
});
