import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { createCommentTaskApi } from "../../../api/task/task-comment.api";
import PaginationPage from "../../../components/common/Pagination";
import FieldContainer from "../../../components/form/FieldContainer";
import { spacing_size } from "../../../themes/size";
import { paginateSlice } from "../../../utils/array.util";
import { convertDataToFormData } from "../../../utils/axios.ultil";
import { getErrorMsgAxios } from "../../../utils/error.util";
import ItemCommunicate from "./ItemCommunicate";

// typescript
interface CommunicateProps {
  enableCommentBtn: boolean;
  taskComments: any;
  id: any;
  getDetailData: () => void;
}

// set color by id
const setIndexCodeData = (data: any[]) => {
  const emails: any[] = [];
  return data.map((item: any) => {
    const indexEmail = emails.indexOf(item?.user?.email);
    let indexColor = indexEmail;
    if (indexEmail === -1) {
      emails.push(item.email);
      indexColor = emails.length - 1;
    }
    return {
      ...item,
      indexColor,
    };
  });
};

const Communicate: FC<CommunicateProps> = (props) => {
  const { enableCommentBtn, taskComments, id, getDetailData } = props;

  // const dispatch = useAppDispatch();
  const [page, setPage] = useState(0);
  const initialValues = {
    comment: "",
  };

  const onSubmit = async (values: any, formik: FormikHelpers<any>) => {
    formik.resetForm();
    const data = {
      comment: values.comment,
      taskId: id,
    };
    try {
      await createCommentTaskApi(convertDataToFormData(null, data, "", "data"));
      // dispatch(getByIdTaskApiAsync(Number(id)));
      getDetailData();
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page - 1);
  };

  return (
    <>
      <div>
        <Stack justifyContent={"space-between"} direction={"row"} mb={spacing_size}>
          <Typography>Ý kiến</Typography>
          <PaginationPage
            total={taskComments ? taskComments.length : 0}
            page={page}
            onChange={handleChangePage}
            sizeDefault={10}
          />
        </Stack>
        <Divider />

        <div>
          {taskComments && taskComments.length > 0 ? (
            paginateSlice(
              taskComments
                .map((a: any) => ({ ...a, createdDateSort: moment(a.createdDate) }))
                .sort((a0: any, a1: any) => a1.createdDateSort - a0.createdDateSort),
              10,
              page + 1
            )
              .map((a: any) => ({ ...a, createdDateSort: moment(a.createdDate) }))
              .sort((a0: any, a1: any) => a1.createdDateSort - a0.createdDateSort)
              .map((item: any) => <ItemCommunicate key={item.id} {...item} />)
          ) : (
            <Divider />
          )}
        </div>
        {enableCommentBtn && (
          <Box sx={{ mt: spacing_size }}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {(formik) => (
                <Form noValidate autoComplete="off">
                  <Stack direction={"row"} spacing={spacing_size}>
                    <Grid item>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 32,
                            height: 32,
                            backgroundColor: "#0084FF",
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item sx={{ flexGrow: 1 }}>
                      <FieldContainer
                        fullWidth
                        name="comment"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        inputProps={{ maxLength: 255 }}
                        placeholder="Nhập ý kiến"
                        maxRows={12}
                        notched={true}
                        variant={"textarea"}
                        id={"comment"}
                      />
                    </Grid>
                    <Grid item alignSelf={"center"}>
                      <Button variant="contained" type="submit" sx={{ borderRadius: 15, backgroundColor: "#0084FF" }}>
                        Gửi ý kiến
                      </Button>
                    </Grid>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        )}
      </div>
    </>
  );
};

export default Communicate;
