import React from "react";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import DocOutIcon from "../../components/icons/DocOutIcon";
import DashboardSideBarIcon from "../../components/icons/DashboardSideBarIcon";
import UserGroupSidebarIcon from "../../components/icons/UserGroupSidebarIcon";
import DocInIcon from "../../components/icons/DocInIcon";
import UserSettingIcon from "../../components/icons/UserSettingIcon";
import UserIcon from "../../components/icons/UserIcon";
import SettingIcon from "../../components/icons/SettingIcon";

export type IconType = "AcUnitIcon" | string;

export const getIconIndex =
  (iconType: IconType, fontSize: "large" | "medium" | "small" = "medium") =>
  () => {
    switch (iconType) {
      case "AcUnitIcon":
        return <AcUnitIcon fontSize={fontSize || "medium"} />;
      case "PieChartOutlineIcon":
        return <PieChartOutlineIcon fontSize={fontSize || "medium"} />;
      case "DocOutIcon":
        return <DocOutIcon fontSize={fontSize || "medium"} />;
      case "DashboardSideBarIcon":
        return <DashboardSideBarIcon fontSize={fontSize || "medium"} />;
      case "DocInIcon":
        return <DocInIcon fontSize={fontSize || "medium"} />;
      case "UserGroupSidebarIcon":
        return <UserGroupSidebarIcon fontSize={fontSize || "medium"} />;
      case "UserIcon":
        return <UserIcon fontSize={fontSize || "medium"} />;
      case "UserSettingIcon":
        return <UserSettingIcon fontSize={fontSize || "medium"} />;
      case "SettingIcon":
        return <SettingIcon fontSize={fontSize || "medium"} />;
      default:
        return null;
    }
  };
