import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";
import HeaderPage from "../../../components/common/HeaderPage";
import { VIEW_MONTH_FORMAT } from "../../../constants/date-time.constant";
import { header_table_color } from "../../../themes/color";
interface WorkingProcessDetailProps {
  data: any;
}
const WorkingProcessDetail: FC<WorkingProcessDetailProps> = (props) => {
  const { data } = props;
  return (
    <>
      <HeaderPage title="Quá trình công tác"></HeaderPage>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ background: header_table_color }}>
                <TableCell sx={{ fontWeight: 600 }}>Thời gian</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Chức vụ</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Bộ phận công tác </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Nơi làm việc</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Ghi chú</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.employeeWorkingHistories?.map((item: any, index: number) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                  }}
                  key={index}
                >
                  <TableCell>
                    {" "}
                    {moment(item?.startDate).format(VIEW_MONTH_FORMAT)} -{" "}
                    {moment(item?.endDate).format(VIEW_MONTH_FORMAT)}
                  </TableCell>
                  <TableCell>{item?.position}</TableCell>
                  <TableCell>{item?.workDepartment}</TableCell>
                  <TableCell>{item?.workPlace}</TableCell>
                  <TableCell>{item?.workContent}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>{" "}
    </>
  );
};

export default WorkingProcessDetail;
