import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import ViewFile from "../../../components/common/ViewFile";
import FieldContainer from "../../../components/form/FieldContainer";
import TextError from "../../../components/form/TextError";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import ChooseFile from "../../../components/icons/ChooseFile";
import ModalContainer from "../../../components/modal/ModalContainer";
import { UPLOAD_DATE_FORMAT } from "../../../constants/date-time.constant";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { DocumentModel, PersonnelModel } from "../../../model/personnel.model";
import { spacing_size } from "../../../themes/size";
import { formatDateTime } from "../../../utils/data-time.util";
import { changeFileUploadPersonnel, isExistDocumentCode } from "../common/util/personnel.util";
import { subStringFileName } from "../../../utils/file.util";
import { toast } from "react-toastify";

interface ModalUpdateDocumentHrProps {
  open: boolean;
  onClose: () => void;
  valueUploads: PersonnelModel | null;
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  itemDocument: DocumentModel | null;
  indexDocument: number;
  setDocumentsDetail: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  fileDocumentsUpload: File[];
  setFileDocumentsUpload: React.Dispatch<React.SetStateAction<File[]>>;
  valuesDocuments: DocumentModel[] | any;
  setValuesDocuments: React.Dispatch<React.SetStateAction<DocumentModel[] | null | undefined>>;
  arrId: any;
  setArrId: any;
  arrFileDeleteId: number[];
  setArrFileDeleteId: React.Dispatch<React.SetStateAction<number[]>>;
}

const ModalUpdateDocumentHr: FC<ModalUpdateDocumentHrProps> = (props) => {
  const {
    open,
    onClose,
    valueUploads,
    setValuesUploads,
    itemDocument,
    indexDocument,
    setDocumentsDetail,
    fileDocumentsUpload,
    setFileDocumentsUpload,
    valuesDocuments,
    setValuesDocuments,
    arrId,
    setArrId,
    arrFileDeleteId,
    setArrFileDeleteId,
  } = props;

  const fileDocumentIdExist: number = itemDocument?.documentFile?.id;
  const fileDocumentNameExist: string = itemDocument?.documentFile?.name;

  const fileNameDetail = itemDocument?.documentFile?.name
    ? itemDocument?.documentFile?.name
    : itemDocument?.fileName
    ? itemDocument?.fileName
    : "";

  const convertFileDetail: any = fileNameDetail
    ? new File([fileNameDetail], fileNameDetail, {
        type: fileNameDetail?.endsWith(".pdf")
          ? "application/pdf"
          : fileNameDetail?.endsWith(".docx")
          ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    : "";
  const initialValues: DocumentModel = {
    issuedDate: itemDocument?.issuedDate ? new Date(itemDocument?.issuedDate) : null,
    code: itemDocument?.code ? itemDocument?.code : "",
    note: itemDocument?.note ? itemDocument?.note : "",
    fileName: itemDocument?.fileName
      ? itemDocument?.fileName
      : "" || itemDocument?.documentFile
      ? itemDocument?.documentFile?.name
      : "",
    fileTemp: itemDocument?.fileTemp ? itemDocument?.fileTemp : convertFileDetail,
  };

  const getFileDocument: any = fileDocumentsUpload?.filter((item: File) =>
    item?.name === itemDocument?.fileName ? itemDocument?.fileName : itemDocument?.fileTemp?.name
  );

  const currentTime = new Date().getTime();

  const [file, setFile] = useState<File[]>(getFileDocument);
  const [arrFile, setArrFile] = useState<File[] | any>(fileDocumentsUpload);
  const fileRef = useRef<any>(null);

  const validationSchema = Yup.object({
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Số văn bản")),
    // fileTemp: Yup.mixed()
    //   .required(FORM_MSG.requiredFieldUploadFile("Đính kèm"))
    //   .test("fileFormat", "Chỉ tải lên file xlsx, xls, doc, docx, pdf", (value) => {
    //     if (!value) {
    //       return false;
    //     }
    //     const validFormats = [
    //       "application/vnd.ms-excel",
    //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //       "application/msword",
    //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //       "application/pdf",
    //     ];
    //     return validFormats.includes(value.type);
    //   }),
  });

  useEffect(() => {
    if (convertFileDetail) {
      setFile([convertFileDetail]);
    } else if (itemDocument?.fileTemp) {
      setFile([itemDocument?.fileTemp]);
    }
  }, []);

  const changeUploadFile = (formik: FormikHelpers<DocumentModel>) => (e: any) => {
    e.preventDefault();
    formik.setFieldValue("fileTemp", e.target.files[0]);
    const fileUp = changeFileUploadPersonnel(e, file, "", 1);
    const newFileUp = fileUp?.map((item: File, index: number) => {
      const newNameItem = currentTime + "_" + item?.name;
      const newItem = new File([item], newNameItem, { type: item.type });
      return newItem;
    });
    setFile(newFileUp);
    setArrFile([...arrFile, ...newFileUp]);
    if (itemDocument?.id) {
      setArrId([...arrId, itemDocument?.id]);
    }
  };

  const buildBody = (values: DocumentModel) => {
    const newValues = {
      ...values,
      id: itemDocument?.id ? itemDocument?.id : null,
      fileName: file[0]?.name || "",
      issuedDate: formatDateTime(values?.issuedDate, UPLOAD_DATE_FORMAT),
    };
    return newValues;
  };

  const handleOnSubmit = async (values: DocumentModel, formik: FormikHelpers<DocumentModel>) => {
    const documentExceptionCurrent: any = (valuesDocuments || []).filter(
      (item: DocumentModel, index: number) => index !== indexDocument
    );

    if (isExistDocumentCode(documentExceptionCurrent, values?.code)) {
      toast.error("Số văn bản không được trùng nhau!");
      return;
    }

    const newValues = buildBody(values);
    const arrDocument: any = valueUploads?.documents;
    const arrDocumentDetails = valuesDocuments;
    // arrDocument[indexDocument] = newValues;
    arrDocumentDetails[indexDocument] = newValues;
    // const uniqueArrayId: any = arrId?.filter((item: any, index: number) => arrId.indexOf(item) === index);
    setValuesUploads({
      ...valueUploads,
      documents: [...arrDocumentDetails],
      // deleteDocumentIds: uniqueArrayId,
      deleteDocumentFileIds: arrFileDeleteId,
    });
    setDocumentsDetail(arrDocument);
    setValuesDocuments(arrDocumentDetails);
    setFileDocumentsUpload(arrFile);
    onClose();
  };

  const handleRemoveFile = (formik: FormikHelpers<DocumentModel>, item: File) => {
    if (item?.name === fileDocumentNameExist) {
      setArrFileDeleteId([...arrFileDeleteId, fileDocumentIdExist]);
    }
    setFile(file.filter((file: File) => item !== file));
    setArrFile(arrFile?.filter((file: File) => item !== file));
    formik.setFieldValue("fileTemp", "");
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Cập nhật"}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          // enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="issuedDate"
                    onBlur={formik.handleBlur}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.issuedDate}
                    placeholderText={FORM_MSG.dateFormatPlaceholder}
                    maxRows={12}
                    label="Ngày ban hành"
                    notched={true}
                    variant={"date"}
                    id={"issuedDate"}
                    // isRequired={true}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.code}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Số văn bản"
                    notched={true}
                    variant={"input"}
                    id={"code"}
                    isRequired={true}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" alignItems="center" spacing={spacing_size}>
                    <Typography sx={{ fontSize: "1rem", fontWeight: "600", color: "#323E5D" }}>
                      Đính kèm{" "}
                      {/* <Box component="span" sx={{ color: "red" }}>
                        *
                      </Box> */}
                    </Typography>
                    <Button
                      variant="contained"
                      color="cancel"
                      // sx={{ display: "inline-block" }}
                      onClick={() => fileRef.current.click()}
                      disabled={file?.length < 1 ? false : true}
                      startIcon={<ChooseFile />}
                    >
                      Chọn file
                    </Button>
                  </Stack>
                  <Field
                    id="file-input"
                    name="fileTemp"
                    // accept="application/msword, application/vnd.ms-excel, application/pdf"
                    accept=".xlsx,.xls,.doc,.docx,.pdf"
                    type="file"
                    innerRef={fileRef}
                    style={{ display: "none" }}
                    value={""}
                    onChange={changeUploadFile(formik)}
                  />
                  {file?.map((item, index) => {
                    const newItem = subStringFileName(item);
                    if (item?.name) {
                      return (
                        <ViewFile key={index} item={newItem} handleRemove={() => handleRemoveFile(formik, item)} />
                      );
                    } else {
                      return <></>;
                    }
                  })}
                  <ErrorMessage name="fileTemp" component={TextError} />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="note"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.note}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Ghi chú"
                    notched={true}
                    variant={"input"}
                    id={"note"}
                  />
                </Grid>

                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    <Button startIcon={<BrowserIcon />} variant="contained" color="primary" type="submit">
                      Lưu
                    </Button>
                    <Button
                      // ref={btnSubmitEducationtRef}
                      variant="contained"
                      color="primary"
                      type="button"
                      sx={{ display: "none" }}
                      // onClick={() => handleClickSave(formik)}
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalUpdateDocumentHr;
