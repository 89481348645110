import HTTP_TASK_SERVICE from "../../config/axios/axios-task.config";
import { HEADER_FORM_DATA } from "../../constants/axios.constant";
import { Pageable } from "../../model/page.model";

export const createTaskApi = (data: FormData) => {
  return HTTP_TASK_SERVICE.post("/tasks", data, {
    headers: HEADER_FORM_DATA,
  });
};

export const getDetailByIdTaskApi = (id: number) => {
  return HTTP_TASK_SERVICE.get("/tasks/" + id);
};

// Huỷ công việc
export const cancelTaskApi = (id: number, note: string | null) => {
  return HTTP_TASK_SERVICE.put("/tasks/" + id + "/cancel", { note });
};

// Hoàn trả
export const returnTaskApi = (id: number, note: string | null) => {
  return HTTP_TASK_SERVICE.put("/tasks/" + id + "/return", { note });
};

// Hoàn thành công việc
export const approveTaskApi = (id: number, note: string | null) => {
  return HTTP_TASK_SERVICE.put("/tasks/" + id + "/approve", { note });
};

// Chưa hoàn thành công việc
export const rejectTaskApi = (id: number, note: string | null) => {
  return HTTP_TASK_SERVICE.put("/tasks/" + id + "/reject", { note });
};

// Gửi kiểm tra công việc
export const submitTaskApi = (id: number, data: FormData) => {
  return HTTP_TASK_SERVICE.put("/tasks/" + id + "/submit", data, {
    headers: HEADER_FORM_DATA,
  });
};

export const downloadFileTaskApi = (id: number, idFile: number) => {
  return HTTP_TASK_SERVICE.get("/tasks/" + id + "/attachment/" + idFile, { responseType: "blob" });
};

export const filterTaskApi = (body: any, params: any) => {
  return HTTP_TASK_SERVICE.post("/tasks/filter", body, { params });
};

export const updateTaskApi = (data: FormData) => {
  return HTTP_TASK_SERVICE.put("/tasks", data, {
    headers: HEADER_FORM_DATA,
  });
};

// Confirm task
export const confirmTaskApi = (id: number, note: string | null) => {
  return HTTP_TASK_SERVICE.put("/tasks/" + id + "/confirm", { note });
};

// Get Dashboard
export const getDashboardTask = (body: any, pageable: Pageable) => {
  return HTTP_TASK_SERVICE.post("/dashboard", body, { params: { ...pageable } });
};

// Export Dashboard
export const exportDashboardTask = (body: any, pageable: Pageable) => {
  return HTTP_TASK_SERVICE.post("/dashboard/export", body, { params: { ...pageable }, responseType: "blob" });
};
