import HTTP_DOC_SERVICE from "../config/axios/axios-doc.config";
import { DocOutDashboardFilterModel, DocOutModel, FilterDocOutModel } from "../model/doc-out.model";
import { Pageable } from "../model/page.model";

export const createDocOutApi = (data: FormData) => {
  return HTTP_DOC_SERVICE.post("/doc-out", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      type: "formData",
    },
  });
};

export const getDetailApi = (id: number) => {
  return HTTP_DOC_SERVICE.get<DocOutModel>(`/doc-out/${id}`);
};

// download file
export const downloadFileDocOutApi = (docFileId: number) => {
  return HTTP_DOC_SERVICE.get<Blob>("/doc-out/file/download", {
    params: { docFileId },
    responseType: "blob",
  });
};

export const updateDocOutApi = (data: FormData) => {
  return HTTP_DOC_SERVICE.put("/doc-out", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      type: "formData",
    },
  });
};

// Filter
export const filterDocOutApi = (body: FilterDocOutModel | null, pageable: Pageable) => {
  return HTTP_DOC_SERVICE.post("/doc-out/filter", body, {
    params: { ...pageable },
  });
};

// Export
export const exportDocOutApi = (body: FilterDocOutModel | null, pageable: Pageable) => {
  return HTTP_DOC_SERVICE.post("/doc-out/filter/excel-doc-out", body, {
    params: { ...pageable },
    responseType: "blob",
  });
};

// Dashboard
export const getDashboardDocOut = (body: DocOutDashboardFilterModel | null, pageable: Pageable) => {
  return HTTP_DOC_SERVICE.post("/doc-out/dashboard", body, { params: { ...pageable } });
};

// Export dashboard
export const exportDashboardDocOut = (body: DocOutDashboardFilterModel | null, pageable: Pageable) => {
  return HTTP_DOC_SERVICE.post("/doc-out/dashboard/excel", body, { params: { ...pageable }, responseType: "blob" });
};
