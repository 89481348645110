import { Table, TableHead, TableRow, TableCell, TableBody, Typography, Stack, IconButton } from "@mui/material";
import React, { FC } from "react";
import { FiDownload } from "react-icons/fi";
import { UPLOAD_DATE_FORMAT, VIEW_DATE_FORMAT } from "../../../constants/date-time.constant";
import { ContractAddendumModel } from "../../../model/personnel.model";
import { spacing_size } from "../../../themes/size";
import { TYPE_FILE_ENUM } from "../../../enum/hr.enum";
import { active_contract_color } from "../../../themes/color";
import { formatDateTime } from "../../../utils/data-time.util";
import { downloadFileHrByType } from "../common/util/personnel.util";
import ReadMore from "../../../components/common/ReadMore";
import { formatNumber } from "../../../utils/number.util";

interface ContractAddendumTableViewProps {
  contractAddendum: any[];
}

const ContractAddendumTableView: FC<ContractAddendumTableViewProps> = (props) => {
  const { contractAddendum } = props;

  const currentDate = new Date();
  const formatCurrentDateDetail = formatDateTime(currentDate, UPLOAD_DATE_FORMAT);

  if ((contractAddendum || []).length === 0) {
    return <></>;
  }

  return (
    <Table size="small" aria-label="purchases">
      <TableBody>
        {contractAddendum?.map((item: ContractAddendumModel, index: number) => (
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
            }}
            key={index}
          >
            <TableCell sx={{ width: "66px" }}></TableCell>
            <TableCell sx={{ maxWidth: "400px", width: "400px", wordWrap: "break-word" }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "500", fontSize: "1.2rem" }}
              >{`[PLHĐ] - ${item?.code}`}</Typography>
              <Typography variant="body1" sx={{ fontStyle: "italic" }}>{`Hiệu lực: ${formatDateTime(
                item?.startDate,
                VIEW_DATE_FORMAT
              )} ${item?.endDate ? " - " + formatDateTime(item?.endDate, VIEW_DATE_FORMAT) : ""}`}</Typography>
            </TableCell>
            <TableCell sx={{ width: "150px", maxWidth: "150px", wordWrap: "break-word" }}>
              {formatNumber(item?.salary)}
            </TableCell>
            <TableCell sx={{ width: "150px", maxWidth: "150px", wordWrap: "break-word" }}>
              {formatNumber(item?.allowances)}
            </TableCell>
            <TableCell sx={{ width: "200px", maxWidth: "200px", wordWrap: "break-word" }}>
              {formatNumber(item?.monthlyBonus)}
            </TableCell>

            <TableCell sx={{ maxWidth: "200px", wordWrap: "break-word" }}>
              <ReadMore text={item?.note} />
            </TableCell>
            <TableCell sx={{ width: "150px", wordWrap: "break-word" }}>
              {new Date(item?.endDate).getTime() + 7 * 60 * 60 * 1000 >= new Date(formatCurrentDateDetail).getTime() ? (
                <Typography sx={{ color: active_contract_color }}>Còn hiệu lực</Typography>
              ) : (
                <Typography sx={{ color: "red" }}>Hết hiệu lực</Typography>
              )}
            </TableCell>
            <TableCell align="center" sx={{ width: "150px" }}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={spacing_size}
                sx={{
                  justifyContent: "center",
                }}
              >
                <IconButton
                  type="button"
                  onClick={() => {
                    downloadFileHrByType(
                      item.contractAddendumFile?.id,
                      item.contractAddendumFile?.name || "",
                      TYPE_FILE_ENUM.CONTRACT_ADDENDUM
                    );
                  }}
                >
                  <FiDownload style={{ color: "#348F14" }} />
                </IconButton>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ContractAddendumTableView;
