import { isRejected } from "@reduxjs/toolkit";
import React, { FC, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { KEY_STORES } from "../../constants/key-store.constant";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { getCurrentUserApiAsync } from "./redux/auth.slice";

interface AuthenticationRouterProps {
  children?: React.ReactNode;
}

const AuthenticationRouter: FC<AuthenticationRouterProps> = (props) => {
  const accessToken = localStorage.getItem(KEY_STORES.accessToken);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const dispatch = useAppDispatch();

  const init = async () => {
    if (accessToken) {
      if (!currentUser) {
        const currentUserApiAsyncAction = await dispatch(getCurrentUserApiAsync());
        if (isRejected(currentUserApiAsyncAction)) {
          localStorage.clear();
        }
      }
    }
  };

  useEffect(() => {
    init();
  }, []);
  if (accessToken) {
    if (currentUser) {
      if (currentUser.status) {
        return <>{props.children}</>;
      } else {
        return <Navigate to={"/login"} replace />;
      }
    } else {
      // TODO: add spinner
      return <></>;
    }
  }

  return <Navigate to={"/login"} replace />;
};

export default AuthenticationRouter;
