import axios from "axios";
import { TIMEOUT } from "../../constants/axios.constant";

const HTTP_USER_SERVICE = axios.create({
  baseURL: process.env.REACT_APP_USER_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: TIMEOUT,
});

export default HTTP_USER_SERVICE;
