import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const UserSettingIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          d="M7 8.167v1.166a3.5 3.5 0 0 0-3.5 3.5H2.333A4.667 4.667 0 0 1 7 8.167Zm0-.584a3.499 3.499 0 0 1-3.5-3.5A3.5 3.5 0 0 1 7 .583a3.5 3.5 0 0 1 3.5 3.5 3.5 3.5 0 0 1-3.5 3.5Zm0-1.166a2.333 2.333 0 1 0 .001-4.666 2.333 2.333 0 0 0 0 4.666Zm1.514 4.557a2.047 2.047 0 0 1 0-.947l-.579-.334.584-1.01.578.334a2.04 2.04 0 0 1 .82-.474v-.668h1.167v.668c.31.092.59.257.82.474l.578-.335.583 1.01-.579.335c.074.311.074.635 0 .946l.58.334-.584 1.01-.579-.334a2.04 2.04 0 0 1-.82.474v.668H9.918v-.668a2.04 2.04 0 0 1-.82-.474l-.578.335-.584-1.01.579-.334Zm1.986.401a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75Z"
          fill="#D4D9E4"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserSettingIcon;
