import { TableCell, TableRow } from "@mui/material";

import React, { FC } from "react";

interface TableRowDetailProps {
  title?: string;
  content?: React.ReactNode;
  fontWeightTitle?: string;
}

const TableRowDetail: FC<TableRowDetailProps> = (props) => {
  const { title, content, fontWeightTitle = 400 } = props;
  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
      // key={index}
    >
      <TableCell sx={{ width: "30%", fontWeight: fontWeightTitle }}>{title}</TableCell>
      <TableCell>{content}</TableCell>
    </TableRow>
  );
};

export default TableRowDetail;
