import { Chip } from "@mui/material";
import React, { FC } from "react";
import { StatusDocIns, STATUS_DOC_IN_ENUM } from "../../../../enum/doc-in.enum";
import { getDescriptionEnum, getItemEnum } from "../../../../utils/enum.util";

interface ChipStatusDocInProps {
  status: STATUS_DOC_IN_ENUM | string | null | undefined;
}

const ChipStatusDocIn: FC<ChipStatusDocInProps> = (props) => {
  const item = getItemEnum(props.status?.toString() || "", StatusDocIns);
  if (item) {
    return <Chip size="small" label={item.description} sx={{ color: item.textColor, background: item.bgColor }} />;
  }

  return null;
};

export default ChipStatusDocIn;
