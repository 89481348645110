import React, { FC, useEffect, useState } from "react";

// import modules
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { styled } from "@mui/material/styles";

// import projects
import Logo from "../../components/logo/Logo";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import ChangePwIcon from "../../components/icons/ChangePwIcon";
import LogoutIcon from "../../components/icons/LogoutIcon";
import ModalContainer from "../../components/modal/ModalContainer";
import { toggleCollapseSidebar } from "../../store/reducers/common.slice";
import ChangePWModal from "../../features/auth/ChangePWModal";
import { setCurrentUser } from "../../features/auth/redux/auth.slice";
import { main_color } from "../../themes/color";
import { spacing_size } from "../../themes/size";
import { logoutApi } from "../../api/auth.api";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(255, 255, 255, 0.429)",
    boxSizing: "border-box",
  },
}));

interface HeaderProps {}

const Header: FC<HeaderProps> = (props) => {
  const dispatch = useAppDispatch();
  const isToggleCollapseSidebar = useAppSelector((state) => state.common.isCollapseSidebar);
  const activatedCurrentUser = useAppSelector((state) => state.auth.currentUser?.activated);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpenChangePw, setIsOpenChangePw] = useState(false);
  const [isOpenConfirmLogout, setIsOpenConfirmLogout] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!activatedCurrentUser) {
      setIsOpenChangePw(true);
    } else {
      setIsOpenChangePw(false);
    }
  }, [activatedCurrentUser]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePw = () => {
    setIsOpenChangePw(true);
  };

  const handleLogout = async () => {
    await logoutApi();
    localStorage.clear();
    await dispatch(setCurrentUser(null));
    navigate("/login");
  };

  const handleToggleMenuMobile = () => {
    dispatch(toggleCollapseSidebar(!isToggleCollapseSidebar));
  };

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Box
      component="header"
      sx={{
        minHeight: "45px",
        backgroundColor: "#061748",
        position: "sticky",
        // position: "-webkit-sticky",
        top: 0,
        zIndex: 2,
      }}
    >
      <ChangePWModal
        openModal={isOpenChangePw}
        onClose={() => {
          setIsOpenChangePw(false);
        }}
        showIconClose={activatedCurrentUser}
      />

      {/* confirm đăng xuất */}
      <ModalContainer
        open={isOpenConfirmLogout}
        title={"Đăng xuất"}
        onClose={() => {
          setIsOpenConfirmLogout(false);
        }}
      >
        <>
          <Typography>Bạn có chắc chắn muốn đăng xuất ra khỏi hệ thống?</Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, md: 2 }}
            sx={{
              mt: 2,
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              color="cancel"
              type="button"
              onMouseDown={() => {
                setIsOpenConfirmLogout(false);
              }}
            >
              Huỷ
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onMouseDown={() => {
                handleLogout();
              }}
            >
              Đồng ý
            </Button>
          </Stack>
        </>
      </ModalContainer>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: main_color, px: 2, py: 1 }}
      >
        <Grid item alignItems="center">
          <Stack direction={"row"} alignItems="center" spacing={spacing_size}>
            <Hidden mdUp>
              <MenuIcon
                sx={{
                  color: "white",
                }}
                onClick={handleToggleMenuMobile}
              />
            </Hidden>
            <Link to={"/"}>
              <Logo type={"light"} />
            </Link>
            {/* TODO:  add expand left menu */}
            {false && (
              <Hidden mdDown>
                <Tooltip title={"Thu nhỏ mở rộng left menu"}>
                  <AntSwitch inputProps={{ "aria-label": "controlled" }} defaultChecked />
                </Tooltip>
              </Hidden>
            )}
          </Stack>
        </Grid>
        <>
          <IconButton
            size="small"
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Box sx={{ marginRight: "1rem" }}>
              <Typography
                sx={{ color: "#fff", fontSize: "0.6rem", display: "flex", justifyContent: "end", fontStyle: "italic" }}
              >
                Xin chào
              </Typography>
              <Typography sx={{ color: "#fff" }}>{currentUser?.fullName}</Typography>
            </Box>
            <Avatar />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              disableRipple
              disableTouchRipple
              sx={{
                cursor: "default",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <Box sx={{ py: 1, px: 1.5 }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {currentUser?.fullName}
                </Typography>
                <Stack direction={{ xs: "row" }} alignItems="center">
                  <EmailOutlinedIcon sx={{ fontSize: 14 }} />
                  <Typography fontSize={12} pl={1}>
                    {currentUser?.email}
                  </Typography>
                </Stack>
              </Box>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleChangePw} sx={{ px: 1.5 }}>
              <ListItemIcon>
                <ChangePwIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Đổi mật khẩu</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setIsOpenConfirmLogout(true);
              }}
            >
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Đăng xuất</ListItemText>
            </MenuItem>
          </Menu>
        </>
      </Grid>
    </Box>
  );
};

export default Header;
