import React, { FC } from "react";
import { InputLabel } from "@mui/material";
import RequireLabelIcon from "./RequireLabelIcon";

interface LabelFieldProps {
  name: string;
  label?: string;
  tooltip?: unknown;
  isRequired?: boolean;
}

const LabelField: FC<LabelFieldProps> = (props) => {
  const { name, label, tooltip, isRequired } = props;
  return (
    <InputLabel
      htmlFor={name}
      sx={{
        fontSize: "1rem",
        fontWeight: 600,
      }}
    >
      <>
        {label}
        {isRequired && <RequireLabelIcon />}
        {tooltip}
      </>
    </InputLabel>
  );
};

export default LabelField;
