import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FileSaver from "file-saver";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getDashboardTaskReceivers } from "../../../api/hr.api";
import { exportDashboardTask, getDashboardTask } from "../../../api/task/task.api";
import BoxContainer from "../../../components/common/BoxContainer";
import HeaderPage from "../../../components/common/HeaderPage";
import NotFoundFilterView from "../../../components/common/NotFoundFilterView";
import PaginationPage from "../../../components/common/Pagination";
import FieldContainer from "../../../components/form/FieldContainer";
import SearchIcon from "../../../components/icons/SearchIcon";
import { FORM_MSG, TASK_MSG } from "../../../constants/msg/msg.constant";
import { FROM_DASHBOARD_TASK } from "../../../constants/navigate.constant";
import { MAX_SIZE_DEFAULT, TOTAL_HEADER } from "../../../constants/page.constant";
import { ALL_ENUM } from "../../../enum/common.enum";
import { TASK_STATUS_ENUM } from "../../../enum/task.enum";
import { Pageable } from "../../../model/page.model";
import { TaskDashboardFilterDTO } from "../../../model/task.model";
import { header_table_color } from "../../../themes/color";
import { spacing_size } from "../../../themes/size";
import { convertDateToDD_MM_YYYY } from "../../../utils/date.util";
import { getErrorMsgAxios } from "../../../utils/error.util";

const initialValues: TaskDashboardFilterDTO = {
  receivers: [],
  year: new Date().getFullYear(),
};

const pageableDefault: Pageable = {
  page: 0,
  size: 10,
};

function ViewDashboardTask() {
  const navigate = useNavigate();
  const [bodyFilter, setBodyFilter] = useState(initialValues);
  const [initValue, setInitValue] = useState(initialValues);
  const [pageable, setPageable] = useState(pageableDefault);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [receivers, setReceivers] = useState([]);

  useEffect(() => {
    getDashboardTaskReceivers({}, { page: 0, size: 999999 })
      .then((res) => {
        setReceivers(res?.data);
      })
      .catch((err) => {
        if (err.response.data.status === 404) {
          const msg = getErrorMsgAxios(err);
          toast.error(msg);
        }
      });
  }, []);

  useEffect(() => {
    getDashboardTask(bodyFilter, pageable)
      .then((res) => {
        setData(res?.data);
        const total = Number(res.headers[TOTAL_HEADER]) || 0;
        setTotalPage(total);
      })
      .catch((err) => {
        if (err.response.data.status === 404) {
          const msg = getErrorMsgAxios(err);
          toast.error(msg);
        }
      });
  }, [initValue, pageable]);

  // Lấy ra 10 năm gần đây kể từ năm hiện tại
  const getCurrentYear = () => new Date().getFullYear();
  const getTenYearsAgo = () => getCurrentYear() - 10;
  const tenYearsAgo = getTenYearsAgo();
  const yearArray = Array.from({ length: 11 }, (_, index) => tenYearsAgo + index)
    .reverse()
    .map((item) => ({
      code: item,
      description: `${item}`,
    }));
  const [yearTask, setYearTask] = useState(yearArray[0]?.code);

  const onSubmit = (value: TaskDashboardFilterDTO) => {
    const receivers = value.receivers && value.receivers.length ? value.receivers.map((item: any) => item.email) : [];
    const year = value.year ? value.year : null;
    setBodyFilter({ receivers, year });
    setInitValue({ ...value });
  };

  const handleCancel = (formik: FormikProps<TaskDashboardFilterDTO>) => {
    formik.resetForm();
    setInitValue(initialValues);
    setBodyFilter(initialValues);
  };

  const handleExport = async (formik: FormikProps<TaskDashboardFilterDTO>) => {
    let value = formik.values;
    let body = {
      receivers: value.receivers && value.receivers.length ? value.receivers.map((item: any) => item.email) : [],
      year: value.year,
    };
    const pageable = {
      page: 0,
      size: MAX_SIZE_DEFAULT,
    };
    try {
      const res = await exportDashboardTask(body, pageable);
      FileSaver.saveAs(res?.data, `Tonghopsoluongcongviectheotrangthai_${convertDateToDD_MM_YYYY(new Date())}.xlsx`);
      toast.success(TASK_MSG.exportDocOutDashboardSuccess);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page - 1);
    setPageable({ page: page - 1, size: 10 });
  };

  const handleNavigateToTask = (email: any, fullName: any, status?: any, expired?: any) => {
    navigate("/task", {
      state: {
        navigateFrom: FROM_DASHBOARD_TASK,
        bodyFilter: {
          status: status,
        },
        dashboard: {
          email: email,
          fullName: fullName,
          status: expired ? expired : status,
          year: yearTask,
        },
      },
    });
  };

  return (
    <Box>
      <HeaderPage title="Tổng hợp số lượng công việc theo trạng thái"></HeaderPage>
      <BoxContainer>
        <Formik
          initialValues={initValue}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => {
            return (
              <>
                <Form>
                  <Grid container spacing={spacing_size} alignItems={"center"}>
                    <Grid item xs={12} md={6} lg={3}>
                      <FieldContainer
                        onChange={(e: any) => {
                          formik.setFieldValue("receivers", e);
                        }}
                        isMulti
                        isClearable
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                        label="Người nhận việc"
                        name="receivers"
                        value={formik.values.receivers}
                        getOptionLabel={(item: any) => (
                          <>
                            <span>{item.fullName}</span>
                            <br />
                            <span>({item.email})</span>
                          </>
                        )}
                        getOptionValue={(item: any) => item.email}
                        options={receivers}
                        placeholder={FORM_MSG.placeholder}
                        variant={"react-select"}
                        id={"receivers"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FieldContainer
                        onChange={(e: any) => {
                          formik.setFieldValue("year", e.target.value);
                          setYearTask(e.target.value);
                        }}
                        fullWidth
                        name="year"
                        label="Năm"
                        labelOption="description"
                        valueOption="year"
                        options={yearArray}
                        variant={"select"}
                        id={"year"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} justifyContent="center">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={spacing_size}
                        sx={{
                          justifyContent: "end",
                        }}
                      >
                        <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                          Tìm kiếm
                        </Button>
                        <Button
                          variant="contained"
                          color="cancel"
                          type="button"
                          onMouseDown={() => handleCancel(formik)}
                        >
                          Huỷ
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          type="button"
                          onMouseDown={() => handleExport(formik)}
                        >
                          Xuất Excel
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      </BoxContainer>
      <BoxContainer>
        {/* show result */}
        {data && data.length > 0 && (
          <Box>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ background: header_table_color }}>
                    <TableCell sx={{ fontWeight: 600 }}>Người nhận việc</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Số công việc được giao</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Quá hạn xử lý</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Chờ xử lý</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Đang xử lý</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Chờ kiểm tra</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Người nhận từ chối</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Hủy bởi người giao</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Hoàn thành</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.length > 0 &&
                    data.map((item: any, index: number) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                          cursor: "pointer",
                        }}
                        key={index}
                      >
                        <TableCell>
                          <Typography component={"p"} sx={{ fontWeight: "600" }}>
                            <span>{item.fullName}</span>
                            <br />
                            <span>({item.email})</span>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {item.totalAssignee != 0 ? (
                            <Typography
                              style={{ color: "#0084FF", cursor: "pointer", fontWeight: "600" }}
                              onClick={() => handleNavigateToTask(item.email, item.fullName, ALL_ENUM)}
                            >
                              {item.totalAssignee}
                            </Typography>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {item.totalExpired != 0 ? (
                            <Typography
                              style={{ color: "#0084FF", cursor: "pointer", fontWeight: "600" }}
                              onClick={() => handleNavigateToTask(item.email, item.fullName, ALL_ENUM, "EXPIRED")}
                            >
                              {item.totalExpired}
                            </Typography>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {item.totalPending != 0 ? (
                            <Typography
                              style={{ color: "#0084FF", cursor: "pointer", fontWeight: "600" }}
                              onClick={() => handleNavigateToTask(item.email, item.fullName, TASK_STATUS_ENUM.PENDING)}
                            >
                              {item.totalPending}
                            </Typography>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {item.totalProcessing != 0 ? (
                            <Typography
                              style={{ color: "#0084FF", cursor: "pointer", fontWeight: "600" }}
                              onClick={() =>
                                handleNavigateToTask(item.email, item.fullName, TASK_STATUS_ENUM.PROCESSING)
                              }
                            >
                              {item.totalProcessing}
                            </Typography>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {item.totalSubmitted != 0 ? (
                            <Typography
                              style={{ color: "#0084FF", cursor: "pointer", fontWeight: "600" }}
                              onClick={() => handleNavigateToTask(item.email, item.fullName, TASK_STATUS_ENUM.SUBMITED)}
                            >
                              {item.totalSubmitted}
                            </Typography>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {item.totalReturned != 0 ? (
                            <Typography
                              style={{ color: "#0084FF", cursor: "pointer", fontWeight: "600" }}
                              onClick={() => handleNavigateToTask(item.email, item.fullName, TASK_STATUS_ENUM.RETURNED)}
                            >
                              {item.totalReturned}
                            </Typography>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {item.totalCanceled != 0 ? (
                            <Typography
                              style={{ color: "#0084FF", cursor: "pointer", fontWeight: "600" }}
                              onClick={() =>
                                handleNavigateToTask(item.email, item.fullName, TASK_STATUS_ENUM.CANCELLED)
                              }
                            >
                              {item.totalCanceled}
                            </Typography>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {item.totalCompleted != 0 ? (
                            <Typography
                              style={{ color: "#0084FF", cursor: "pointer", fontWeight: "600" }}
                              onClick={() =>
                                handleNavigateToTask(item.email, item.fullName, TASK_STATUS_ENUM.COMPLETED)
                              }
                            >
                              {item.totalCompleted}
                            </Typography>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <PaginationPage total={totalPage} page={page} onChange={handleChangePage} sizeDefault={10} />
          </Box>
        )}

        {/* not found result */}
        {(!data || data.length === 0) && <NotFoundFilterView />}
      </BoxContainer>
    </Box>
  );
}

export default ViewDashboardTask;
