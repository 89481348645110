import { Avatar, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import ReadMore from "../../../components/common/ReadMore";
import moment from "moment";
import { VIEW_DATE_TIME_FORMAT } from "../../../constants/date-time.constant";
import ViewItemFileAttachCommentTask from "./ViewItemFileAttachCommentTask";
const COLOR = ["#4c31ac", "#55A55E", "#FF4242", "#CC6600", "#333300", "#990000", "#666600", "#660033", "#4444TA"];
interface ItemCommunicateProps {
  user: any;
  createdDate: Date | undefined | null | any;
  comment: string;
  informTo: string;
  indexColor: number;
  attachments: any;
  [key: string]: any;
}
const ItemCommunicate: FC<ItemCommunicateProps> = (props) => {
  const { user, createdDate, comment, indexColor, attachments } = props;
  const color = COLOR[indexColor] || "black";
  return (
    <>
      <Typography
        component={"div"}
        sx={{
          borderLeft: `8px solid ${color}`,
          pl: "0.8rem !important",
          mt: 1.5,
          mb: 1.5,
        }}
      >
        <Grid
          columnSpacing={2}
          container
          sx={{
            color,
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Stack direction={"row"}>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  backgroundColor: color,
                  mr: "0.5rem",
                }}
              />
              <span>
                {user?.fullName} ({user?.email})
              </span>
            </Stack>
          </Grid>
          <Grid item sx={{ float: "right" }}>
            {moment(createdDate).format(VIEW_DATE_TIME_FORMAT)}
          </Grid>
        </Grid>

        <Typography>
          <ReadMore text={comment} showLength={300} />
        </Typography>
        {attachments && attachments.map((at: any) => <ViewItemFileAttachCommentTask file={at} />)}
      </Typography>
      <Divider />
    </>
  );
};

export default ItemCommunicate;
