import { Chip } from "@mui/material";
import React, { FC } from "react";
import { objectsTaskStatusEnum } from "../../../enum/task.enum";

interface StatusTaskChipProps {
  status: string;
}

const StatusTaskChip: FC<StatusTaskChipProps> = (props) => {
  const { status } = props;
  const objTask = objectsTaskStatusEnum.filter((item) => item.code === status);
  if (objTask.length > 0) {
    return (
      <Chip
        label={objTask[0].description}
        sx={{
          background: objTask[0].bgColor,
          color: objTask[0].color,
          fontWeight: "bold",
          fontSize: "0.7rem",
          // height: "16px !important",
        }}
      />
    );
  }

  return null;
};

export default StatusTaskChip;
