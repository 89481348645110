import { Box, Button, Grid, Stack } from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import BoxContainer from "../../../components/common/BoxContainer";
import HeaderPage from "../../../components/common/HeaderPage";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import { spacing_size } from "../../../themes/size";
import ModalCancel from "../modal/ModalCancel";
import ModalCompleted from "../modal/ModalCompleted";
import ModalReject from "../modal/ModalReject";
import ModalReturn from "../modal/ModalReturn";
import ModalSendCheck from "../modal/ModalSendCheck";
import Communicate from "./Communicate";
import InformationJob from "./InformationJob";

interface InformationProps {
  task: any;
  getDetailData: () => void;
}

const InformationDetail: FC<InformationProps> = ({ task, getDetailData }) => {
  const navigate = useNavigate();

  // const data = useAppSelector((state) => state.task.detailTask);

  const [openCancel, setOpenCancel] = useState<boolean>(false);
  const [openCompleted, setOpenCompleted] = useState<boolean>(false);
  const [openReject, setOpenReject] = useState<boolean>(false);
  const [openSubmit, setOpenSubmit] = useState<boolean>(false);
  const [openReturn, setOpenReturn] = useState<boolean>(false);

  const handleOnUpdate = () => {
    navigate("/task/" + task.id + "/update");
  };

  return (
    <>
      {openSubmit && (
        <ModalSendCheck
          id={task?.id}
          open={openSubmit}
          onClose={() => setOpenSubmit(false)}
          getDetailData={getDetailData}
        />
      )}
      {openCancel && (
        <ModalCancel
          id={task?.id}
          open={openCancel}
          onClose={() => setOpenCancel(false)}
          getDetailData={getDetailData}
        />
      )}
      {openReject && (
        <ModalReject
          id={task?.id}
          open={openReject}
          onClose={() => setOpenReject(false)}
          getDetailData={getDetailData}
        />
      )}
      {openCompleted && (
        <ModalCompleted
          id={task?.id}
          open={openCompleted}
          onClose={() => setOpenCompleted(false)}
          getDetailData={getDetailData}
        />
      )}

      {openReturn && (
        <ModalReturn
          id={task?.id}
          open={openReturn}
          onClose={() => setOpenReturn(false)}
          getDetailData={getDetailData}
        />
      )}

      <HeaderPage title="Chi tiết công việc" variant="h5" component="h5">
        {actionButton()}
      </HeaderPage>
      <Grid container spacing={spacing_size}>
        <Grid item xl={5} md={12} xs={12}>
          <BoxContainer>
            <Box>
              <InformationJob data={task} getDetailData={getDetailData} />
            </Box>
          </BoxContainer>
        </Grid>
        <Grid item xl={7} md={12} xs={12}>
          <BoxContainer>
            <Box>
              <Communicate
                getDetailData={getDetailData}
                enableCommentBtn={task?.enableCommentBtn}
                taskComments={task?.taskComments}
                id={task?.id}
              />
            </Box>
          </BoxContainer>
        </Grid>
      </Grid>
      {actionButton()}
    </>
  );

  function actionButton() {
    return (
      <>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={spacing_size} justifyContent={"flex-end"}>
          {task?.enableCancelBtn && (
            <Button variant="contained" sx={{ bgcolor: "#DD001A" }} onClick={() => setOpenCancel(true)}>
              Hủy
            </Button>
          )}
          {task?.enableSubmitBtn && (
            <Button
              variant="contained"
              onClick={() => {
                setOpenSubmit(true);
              }}
              startIcon={<BrowserIcon />}
            >
              Gửi kiểm tra
            </Button>
          )}
          {task?.enableApproveBtn && (
            <Button
              variant="contained"
              sx={{ bgcolor: "#63BD47" }}
              onClick={() => {
                setOpenCompleted(true);
              }}
              startIcon={<BrowserIcon />}
            >
              Hoàn thành
            </Button>
          )}
          {task?.enableUpdateBtn && (
            <Button
              sx={{ bgcolor: "#FF8B0C" }}
              variant="contained"
              onClick={handleOnUpdate}
              startIcon={<BrowserIcon />}
            >
              Cập nhật
            </Button>
          )}
          {task?.enableRejectBtn && (
            <Button
              variant="contained"
              onClick={() => {
                setOpenReject(true);
              }}
              startIcon={<BrowserIcon />}
            >
              Chưa hoàn thành
            </Button>
          )}
          {task?.enableReturnBtn && (
            <Button
              variant="contained"
              onClick={() => {
                setOpenReturn(true);
              }}
              startIcon={<BrowserIcon />}
            >
              Từ chối
            </Button>
          )}
        </Stack>
      </>
    );
  }
};

export default InformationDetail;
