import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { changeStatusApi } from "../../../../api/doc-book.api";
import BoxContainer from "../../../../components/common/BoxContainer";
import NotFoundFilterView from "../../../../components/common/NotFoundFilterView";
import PaginationPage from "../../../../components/common/Pagination";
import AntSwitch from "../../../../components/common/switch/AntSwitch";
import ModalContainer from "../../../../components/modal/ModalContainer";
import { SIZE_DEFAULT } from "../../../../constants/page.constant";
import { ACCESS_RIGHT_ENUM } from "../../../../enum/access-right.enum";
import { DocBooks } from "../../../../enum/doc-book.enum";
import { DocBook } from "../../../../model/doc-book.model";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { header_table_color } from "../../../../themes/color";
import { spacing_size } from "../../../../themes/size";
import { hasAccessRight } from "../../../../utils/auth.util";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { setPage, setReloadFilter } from "../redux/doc-book.slice";

const ResultFilterDocBook = () => {
  const data = useAppSelector((state) => state.docBook.dataDocBooks);
  const total = useAppSelector((state) => state.docBook.totalDataDocBooks);
  const page = useAppSelector((state) => state.docBook.page);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const dispatch = useAppDispatch();
  const [isOpenConfirmChangeStatus, setIsOpenConfirmChangeStatus] = useState(false);
  const [idChangeStatus, setIdChangeStatus] = useState<number | null>(null);

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setPage(page - 1));
  };

  const handleChangeStatusDocBook = async (id: number | null) => {
    if (id) {
      try {
        await changeStatusApi(id);
        await dispatch(setReloadFilter(null));
        setIsOpenConfirmChangeStatus(false);
      } catch (error) {
        toast.error(getErrorMsgAxios(error));
      }
    }
  };

  const showCodeText = (code: string | undefined) => {
    if (code) {
      for (const db of DocBooks) {
        if (db.code === code) {
          return db.description;
        }
      }
    }

    return null;
  };

  return (
    <>
      {/* confirm change status */}
      <ModalContainer
        open={isOpenConfirmChangeStatus}
        title={"Thay đổi trạng thái"}
        onClose={() => {
          setIsOpenConfirmChangeStatus(false);
        }}
      >
        <>
          <Typography>Bạn có chắc muốn đổi trạng thái của Sổ công văn không?</Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={spacing_size}
            sx={{
              mt: 2,
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              color="cancel"
              type="button"
              onMouseDown={() => {
                setIsOpenConfirmChangeStatus(false);
              }}
            >
              Huỷ
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onMouseDown={() => {
                handleChangeStatusDocBook(idChangeStatus);
                // handleChangeStatusUser();
              }}
            >
              Xác nhận
            </Button>
          </Stack>
        </>
      </ModalContainer>

      {data && (
        <BoxContainer>
          {/* show result */}
          {data.length > 0 && (
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: header_table_color }}>
                      <TableCell align="center" sx={{ fontWeight: 600 }}>
                        STT
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 600 }}>
                        Năm công văn
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Loại</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 600 }}>
                        Trạng thái
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item: DocBook, index: number) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                        }}
                        key={index}
                      >
                        <TableCell align="center">{SIZE_DEFAULT * page + index + 1}</TableCell>
                        <TableCell align="center">{item?.docYear}</TableCell>
                        <TableCell>{showCodeText(item?.code)}</TableCell>
                        <TableCell align="center">
                          <AntSwitch
                            checked={item.status}
                            onChange={() => {
                              if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN)) {
                                setIsOpenConfirmChangeStatus(true);
                                setIdChangeStatus(item.id || null);
                              }
                            }}
                            sx={{ marginLeft: "calc(50% - 12px)" }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationPage total={total} page={page} onChange={handleChangePage} />
            </Box>
          )}

          {/* not found result */}
          {data.length === 0 && <NotFoundFilterView />}
        </BoxContainer>
      )}
    </>
  );
};

export default ResultFilterDocBook;
