import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FileSaver from "file-saver";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getDocBookApi } from "../../../api/doc-book.api";
import { exportDocInDashboard } from "../../../api/doc-in.api";
import { filterDepartmentByCode } from "../../../api/hr/department.api";
import BoxContainer from "../../../components/common/BoxContainer";
import HeaderPage from "../../../components/common/HeaderPage";
import NotFoundFilterView from "../../../components/common/NotFoundFilterView";
import PaginationPage from "../../../components/common/Pagination";
import FieldContainer from "../../../components/form/FieldContainer";
import SearchIcon from "../../../components/icons/SearchIcon";
import { DOC_IN_MSG } from "../../../constants/msg/msg.constant";
import { SIZE_10 } from "../../../constants/page.constant";
import { DOC_BOOK_ENUM } from "../../../enum/doc-book.enum";
import { CategoryDocIns, DEADLINE_DOC_IN_TYPE_ENUM, STATUS_DOC_IN_ENUM } from "../../../enum/doc-in.enum";
import { Department, DepartmentHR } from "../../../model/department.model";
import { CodeDocBookModel, DocBook } from "../../../model/doc-book.model";
import { DocInDashboard, InitialDocInDashboardForm } from "../../../model/doc-in.model";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { header_table_color } from "../../../themes/color";
import { spacing_size } from "../../../themes/size";
import { convertDateToDD_MM_YYYY } from "../../../utils/date.util";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { buildOptionObject } from "../../../utils/options.util";
import {
  getDocInDashboardThunk,
  setDashboardDocInsForm,
  setDocInDashboardForm,
  setPageDashboardDocIns,
  setPageDocInDashboard,
} from "./redux/doc-in-dashboard.reducer";

const ViewDashboardDocIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const totalPage = useAppSelector((state) => state.docInDashboard.totalDocInDashboard);
  const page = useAppSelector((state) => state.docInDashboard.pageDocInDashboard);
  const docInDashboardForm = useAppSelector((state) => state.docInDashboard.docInDashboardForm);
  const docinDashboards = useAppSelector((state) => state.docInDashboard.detailDocInDashboards);

  const [docBookOptions, setDocBookOptions] = useState<any[]>([]);
  const [docBookList, setDocBookList] = useState<DocBook[]>([]);
  const [dashboardDepartments, setDashboardDepartments] = useState<Department[]>([]);
  const [reload, setReload] = useState<number>(0);

  const size = SIZE_10;
  const currentDate = new Date();

  useEffect(() => {
    const fetchData = async () => {
      await getDocBook();
      await getAllDepartment();
      setReload(reload + 1);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (reload == 1 && (location?.state?.isReload == null || location?.state?.isReload == true)) {
        resetData();
      } else {
        let tmpDocbookList = docBookList
          .filter((docbook) => docbook.code == `DOC_IN_${docInDashboardForm.category}`)
          .sort((a, b) => b.docYear! - a.docYear!);
        setDocBookOptions(tmpDocbookList);

        let pageable = {
          page: page,
          size: size,
        };
        let body = {
          category: docInDashboardForm.category,
          docBookId: docInDashboardForm.docBookId,
          departmentCode: docInDashboardForm.dashboardDepartmentCode?.map(
            (department: { code: any }) => department.code
          ),
        };
        dispatch(getDocInDashboardThunk({ body, pageable }));
      }
    };

    fetchData();
  }, [page, reload]);

  const getDocBook = async () => {
    const params: CodeDocBookModel = {
      code: `${DOC_BOOK_ENUM.DOC_IN_DOMESTIC},${DOC_BOOK_ENUM.DOC_IN_INTERNATIONAL}`,
    };
    let res = await getDocBookApi(params);
    setDocBookList(res.data);
    let tmpDocbookOptions = res.data
      .filter((docbook) => docbook.code == DOC_BOOK_ENUM.DOC_IN_DOMESTIC)
      .sort((a, b) => b.docYear! - a.docYear!);
    setDocBookOptions(tmpDocbookOptions);
    return res.data;
  };

  const getAllDepartment = async () => {
    let arrDepartment: Department[] = [];
    let res = await filterDepartmentByCode(currentUser!.dashboardDepartmentCode);
    res.data?.map((item: DepartmentHR) => {
      arrDepartment.push(buildOptionObject(item, "code", "name", `${item?.code} - ${item.name}`));
    });
    setDashboardDepartments(arrDepartment);
  };

  const handleChangeDashboardDepartment =
    (formik: FormikHelpers<InitialDocInDashboardForm>) => (e: Object | null | any) => {
      formik.setFieldValue("dashboardDepartmentCode", e);
    };

  const handleChangeCategory = (formik: FormikHelpers<InitialDocInDashboardForm>) => (e: Object | null | any) => {
    formik.setFieldValue("category", e.target.value);
    let tmp = docBookList
      .filter((docbook) => docbook.code == `DOC_IN_${e.target.value}`)
      .sort((a, b) => b.docYear! - a.docYear!);
    setDocBookOptions(tmp);
    formik.setFieldValue("docBookId", tmp[0]?.id);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setPageDocInDashboard(page - 1));
  };

  const handleOnSubmit = async (value: InitialDocInDashboardForm) => {
    let pageable = {
      page: 0,
      size: size,
    };
    let body = {
      category: value.category,
      docBookId: value.docBookId,
      departmentCode: value.dashboardDepartmentCode?.map((department: { code: any }) => department.code),
    };
    dispatch(getDocInDashboardThunk({ body, pageable }));

    dispatch(
      setDocInDashboardForm({
        category: value.category,
        docBookId: value.docBookId,
        dashboardDepartmentCode: value.dashboardDepartmentCode,
      })
    );
    dispatch(setPageDocInDashboard(0));
  };

  const handleCancel = (formik: FormikProps<InitialDocInDashboardForm>) => {
    formik.resetForm();
    resetData();
  };

  const handleExport = async (formik: FormikProps<InitialDocInDashboardForm>) => {
    let value = formik.values;
    let body = {
      category: value.category,
      docBookId: value.docBookId,
      departmentCode: value.dashboardDepartmentCode?.map((department: { code: any }) => department.code),
    };
    try {
      const res = await exportDocInDashboard(body);
      FileSaver.saveAs(res?.data, `Tonghopsoluongcongvandentheotrangthai_${convertDateToDD_MM_YYYY(currentDate)}.xlsx`);
      toast.success(DOC_IN_MSG.exportDocInDashboardSuccess);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const handleNavigateToDocIn = (dashBoardType: string, department: Department) => {
    dispatch(
      setDashboardDocInsForm({
        dashBoardType: dashBoardType,
        docBookId: docInDashboardForm.docBookId,
        departmentCode: department.code,
        category: docInDashboardForm.category,
        departmentName: department.name,
      })
    );
    dispatch(setPageDashboardDocIns(0));
    navigate("/dashboard/doc-ins");
  };

  const renderNumberFormat = (count: number, dashBoardType: string, department: Department) => {
    return (
      <>
        {count ? (
          <Typography
            style={{ textDecoration: "none", color: "#0084FF", fontWeight: "600" }}
            onClick={() => handleNavigateToDocIn(dashBoardType, department)}
          >
            {count}
          </Typography>
        ) : (
          "-"
        )}
      </>
    );
  };

  const resetData = () => {
    let tmpDocbookOptions = docBookList
      .filter((docbook) => docbook.code == DOC_BOOK_ENUM.DOC_IN_DOMESTIC)
      .sort((a, b) => b.docYear! - a.docYear!);
    setDocBookOptions(tmpDocbookOptions);
    let pageable = {
      page: page,
      size: size,
    };
    let body = {
      category: CategoryDocIns[0].code,
      docBookId: tmpDocbookOptions[0]?.id,
      departmentCode: dashboardDepartments.map((department) => department?.code),
    };
    dispatch(getDocInDashboardThunk({ body, pageable }));
    dispatch(
      setDocInDashboardForm({
        category: CategoryDocIns[0].code,
        docBookId: tmpDocbookOptions[0]?.id,
        dashboardDepartmentCode: dashboardDepartments,
      })
    );
    dispatch(setPageDocInDashboard(0));
    setReload(reload + 1);
  };

  return (
    <Box>
      <HeaderPage title="Tổng hợp số lượng công văn đến theo trạng thái"></HeaderPage>
      <BoxContainer>
        <Formik
          initialValues={docInDashboardForm}
          onSubmit={handleOnSubmit}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => {
            return (
              <>
                <Form>
                  <Grid container spacing={spacing_size}>
                    <Grid item xs={12} md={4} lg={4}>
                      <FieldContainer
                        // format code
                        onChange={handleChangeCategory(formik)}
                        name="category"
                        label="Phân loại"
                        labelOption="description"
                        valueOption="code"
                        options={CategoryDocIns}
                        variant={"select"}
                        id={"category"}
                      />
                    </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                      <FieldContainer
                        fullWidth
                        name="dashboardDepartmentCode"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeDashboardDepartment(formik)}
                        type="text"
                        inputProps={{ maxLength: 12 }}
                        value={formik.values.dashboardDepartmentCode}
                        getOptionLabel={(item: DepartmentHR) => `${item.name ? item.name : item?.displayName}`}
                        getOptionValue={(item: DepartmentHR) => item.code}
                        placeholder="Chọn thông tin"
                        label="Bộ phận"
                        variant={"multi-react-select"}
                        id={"dashboardDepartmentCode"}
                        isClearable
                        options={dashboardDepartments}
                        menuPlacement="bottom"
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <FieldContainer
                        onChange={formik.handleChange}
                        name="docBookId"
                        label="Sổ công văn năm"
                        labelOption="docYear"
                        valueOption="id"
                        options={docBookOptions}
                        variant={"select"}
                        id={"docBookId"}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                      }}
                    >
                      <Stack direction="row" spacing={spacing_size}>
                        <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                          Tìm kiếm
                        </Button>
                        <Button
                          variant="contained"
                          color="cancel"
                          type="button"
                          onMouseDown={() => handleCancel(formik)}
                        >
                          Huỷ
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          type="button"
                          onMouseDown={() => handleExport(formik)}
                        >
                          Xuất Excel
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      </BoxContainer>
      <BoxContainer>
        {docinDashboards != null && docinDashboards.length > 0 && (
          <Box>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ background: header_table_color }}>
                    <TableCell sx={{ fontWeight: 600 }}>Bộ phận</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Số công văn đã tạo</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Quá hạn theo THXL của VB</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Quá hạn theo THXL của CVP/PCVP</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Quá hạn theo THXL của LĐP</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Chờ trình duyệt</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Chờ phân công</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Chờ xử lý</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Đã xử lý</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Đã phúc đáp</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Đã hoàn trả</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Đã hủy</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {docinDashboards?.map((item: DocInDashboard, index: number) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                        cursor: "pointer",
                      }}
                      key={index}
                    >
                      <TableCell>
                        <Typography component={"span"} style={{ fontWeight: "600" }}>
                          {item.name}
                        </Typography>
                      </TableCell>
                      <TableCell>{renderNumberFormat(item.countCreatedDocIn, "CREATED", item)}</TableCell>
                      <TableCell>
                        {renderNumberFormat(item.countDeadline, DEADLINE_DOC_IN_TYPE_ENUM.DEADLINE, item)}
                      </TableCell>
                      <TableCell>
                        {renderNumberFormat(item.countDeadline2, DEADLINE_DOC_IN_TYPE_ENUM.DEADLINE2, item)}
                      </TableCell>
                      <TableCell>
                        {renderNumberFormat(
                          item.countProcessingDeadline,
                          DEADLINE_DOC_IN_TYPE_ENUM.PROCESSING_DEADLINE,
                          item
                        )}
                      </TableCell>
                      <TableCell>
                        {renderNumberFormat(item.countUnapproved, STATUS_DOC_IN_ENUM.UNAPPROVED, item)}
                      </TableCell>
                      <TableCell>
                        {renderNumberFormat(item.countUnassigned, STATUS_DOC_IN_ENUM.UNASSIGNED, item)}
                      </TableCell>
                      <TableCell>{renderNumberFormat(item.countPending, STATUS_DOC_IN_ENUM.PENDING, item)}</TableCell>
                      <TableCell>
                        {renderNumberFormat(item.countProcessed, STATUS_DOC_IN_ENUM.PROCESSED, item)}
                      </TableCell>
                      <TableCell>{renderNumberFormat(item.countReply, STATUS_DOC_IN_ENUM.REPLY, item)}</TableCell>
                      <TableCell>{renderNumberFormat(item.countReturned, STATUS_DOC_IN_ENUM.RETURNED, item)}</TableCell>
                      <TableCell>{renderNumberFormat(item.countCanceled, STATUS_DOC_IN_ENUM.CANCELED, item)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationPage total={totalPage} page={page} onChange={handleChangePage} sizeDefault={size} />
          </Box>
        )}
        {/* not found result */}
        {(docinDashboards == null || docinDashboards.length === 0) && <NotFoundFilterView />}
      </BoxContainer>
    </Box>
  );
};

export default ViewDashboardDocIn;
