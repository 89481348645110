import React from "react";

// import modules
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

// import projects
import FieldContainer from "../../../components/form/FieldContainer";

interface InitialValuesForm {}

function CreateUser() {
  const initialValues: InitialValuesForm = {};
  const validationSchema = Yup.object({});
  const handleOnSubmit = (value: InitialValuesForm, formikHelper: FormikHelpers<InitialValuesForm>) => {};
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <Form>
              {/* <FieldContainer
								fullWidth
								name=""
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="password"
								inputProps={{ maxLength: 12 }}
								// value={formik.values.}
								placeholder="abc"
								maxRows={12}
								label="Chỉ còn lại nỗi nhớ"
								notched={true}
								isRequired
								variant={"input"}
								id={""}
							/> */}
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}

export default CreateUser;
