import { ErrorMessage, Field } from "formik";
import React, { FC } from "react";
import { FieldCommonProps } from "./FieldContainer";
import LabelField from "./LabelField";
import TextError from "./TextError";

export interface SelectFieldProps extends FieldCommonProps {
  options?: Object[] | any;
  labelOption?: string;
  valueOption?: string;
  [key: string]: unknown;
}

const SelectField: FC<SelectFieldProps> = (props) => {
  const {
    label,
    name,
    isRequired,
    placeholder,
    tooltip,
    labelOption = "label",
    valueOption = "value",
    options = [],
    id,
    ...rest
  } = props;
  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <Field as="select" id={id} name={name} className="form-control form-control-select" style={{}} {...rest}>
        {options.map((option: any) => {
          return (
            <option key={option[valueOption]} value={option[valueOption]}>
              {option[labelOption]}
            </option>
          );
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default SelectField;
