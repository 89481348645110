import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Stack, Tab, Typography } from "@mui/material";
import React, { FC } from "react";
import BoxContainer from "../../../components/common/BoxContainer";
import { spacing_size } from "../../../themes/size";
import StatusEmployee from "../common/component/StatusEmployee";
import ContractDetail from "./ContractDetail";
import DocumentDetail from "./DocumentDetail";
import GeneralInformationDetail from "./GeneralInformationDetail";
import LevelDetail from "./LevelDetail";
import RelativesDetail from "./RelativesDetail";
import WorkingProcessDetail from "./WorkingProcessDetail";
import { TAB_SELECT_PERSONNEL_ENUM } from "../../../enum/personnel.enum";
import { getNextStepTabPersonnel } from "../common/util/personnel.util";

interface StepperPersonnelDetailProps {
  data: any;
}

const StepperPersonnelDetail: FC<StepperPersonnelDetailProps> = (props) => {
  const { data } = props;
  const [currentTab, setCurrentTab] = React.useState<string>(TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <BoxContainer>
        <TabContext value={currentTab}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={spacing_size}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList aria-label="lab API tabs example" onChange={handleChange} variant="scrollable">
                <Tab label="Thông tin chung" value={TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION} />
                <Tab label="Hợp đồng" value={TAB_SELECT_PERSONNEL_ENUM.CONTRACT} />
                <Tab label="Quá trình công tác" value={TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS} />
                <Tab label="Trình độ" value={TAB_SELECT_PERSONNEL_ENUM.LEVEL} />
                <Tab label="Người thân" value={TAB_SELECT_PERSONNEL_ENUM.RELATIVE} />
                <Tab label="Tài liệu" value={TAB_SELECT_PERSONNEL_ENUM.DOCUMENT} />
              </TabList>
            </Box>
            <Typography>
              Trạng thái: <StatusEmployee status={data?.status} />{" "}
            </Typography>
          </Stack>
          <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION}>
            <GeneralInformationDetail data={data} />
          </TabPanel>
          <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.CONTRACT}>
            <ContractDetail data={data} />
          </TabPanel>
          <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS}>
            <WorkingProcessDetail data={data} />
          </TabPanel>
          <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.LEVEL}>
            <LevelDetail data={data} />
          </TabPanel>
          <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.RELATIVE}>
            <RelativesDetail data={data} />
          </TabPanel>
          <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.DOCUMENT}>
            <DocumentDetail data={data} />
          </TabPanel>
        </TabContext>
      </BoxContainer>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={spacing_size}
        sx={{
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          type="button"
          sx={{ visibility: currentTab !== TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION ? "initial" : "hidden" }}
          onClick={() => {
            setCurrentTab(getNextStepTabPersonnel(currentTab, -1));
          }}
        >
          Quay lại
        </Button>
        <Button
          sx={{ visibility: currentTab !== TAB_SELECT_PERSONNEL_ENUM.DOCUMENT ? "initial" : "hidden" }}
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => {
            setCurrentTab(getNextStepTabPersonnel(currentTab, 1));
          }}
        >
          Tiếp tục
        </Button>
      </Stack>
    </>
  );
};

export default StepperPersonnelDetail;
