import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllApi as getAllAccessRights } from "../../../../api/access-right.api";
import { filterDepartmentApi, filterPositionApi } from "../../../../api/hr.api";
import { filterApi } from "../../../../api/user.api";
import { TOTAL_HEADER } from "../../../../constants/page.constant";
import { AccessRightModel } from "../../../../model/access-right.model";
import { Pageable } from "../../../../model/page.model";
import { DepartmentModel, FilterDepartmentReqModel, FilterPositionReqModel, FilterUserReqModel, PositionModel, UserModel } from "../../../../model/user.model";

interface UserSliceState {
  accessRights: AccessRightModel[] | null;
  page: number;
  dataUsers: UserModel[] | null;
  totalDataUsers: number;
  reloadFilter: number;

  // department
  dataDepartment: DepartmentModel[] | null;
  pageDepartment: number;
  totalDataDepartment: number;

  // position
  dataPosition: any[] | null;
  pagePosition: number;
  totalDataPosition: number;
}

export const getAllAccessRightsApiAsync = createAsyncThunk("UserSlice/getAllAccessRights", async () => {
  try {
    const res = await getAllAccessRights();
    return res.data;
  } catch (error: any) {
    throw Error(error);
  }
});

export const filterUserApiAsync = createAsyncThunk(
  "UserSlice/filter",
  async (data: { body: FilterUserReqModel | null; pageable: Pageable }) => {
    try {
      const res = await filterApi(data.body, data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      throw Error(error);
    }
  }
);

export const filterDepartmentApiAsync = createAsyncThunk(
  "UserSlice/filterDepartment",
  async (data: { body: FilterDepartmentReqModel | null; pageable: Pageable }) => {
    try {
      const res = await filterDepartmentApi(data.body, data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      throw Error(error);
    }
  }
);

export const filterPositionApiAsync = createAsyncThunk(
  "UserSlice/filterPosition",
  async (data: { body: FilterPositionReqModel | null; pageable: Pageable }) => {
    try {
      const res = await filterPositionApi(data.body, data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      throw Error(error);
    }
  }
);

const initialState: UserSliceState = {
  accessRights: null,
  page: 0,
  dataUsers: null,
  reloadFilter: 0,
  totalDataUsers: 0,
  dataDepartment: null,
  pageDepartment: 0,
  totalDataDepartment: 0,
  dataPosition: null,
  pagePosition: 0,
  totalDataPosition: 0,
};

const UserSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    setPage(state, action) {
      return {
        ...state,
        page: action.payload,
      };
    },
    setReloadFilter(state, action) {
      return {
        ...state,
        reloadFilter: action.payload === 0 ? 0 : action.payload || state.reloadFilter + 1,
      };
    },

    changePageDepartment(state, action) {
      return {
        ...state,
        pageDepartment: action.payload,
      };
    },

    changePagePosition(state, action) {
      return {
        ...state,
        pagePosition: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllAccessRightsApiAsync.fulfilled, (state, action: { payload: AccessRightModel[] | null }) => {
        return {
          ...state,
          accessRights: action.payload,
        };
      })
      .addCase(
        filterUserApiAsync.fulfilled,
        (state, action: { payload: { data: UserModel[] | null; total: number } }) => {
          return {
            ...state,
            dataUsers: action.payload.data,
            totalDataUsers: action.payload.total,
          };
        }
      )

      .addCase(
        filterDepartmentApiAsync.fulfilled,
        (state, action: { payload: { data: DepartmentModel[] | null; total: number } }) => {
          return {
            ...state,
            dataDepartment: action.payload.data,
            totalDataDepartment: action.payload.total,
          };
        }
      )

      .addCase(
        filterPositionApiAsync.fulfilled,
        (state, action: { payload: { data: PositionModel[] | null; total: number } }) => {
          return {
            ...state,
            dataPosition: action.payload.data,
            totalDataPosition: action.payload.total,
          };
        }
      );
  },
});

export const { setPage, setReloadFilter, changePageDepartment, changePagePosition } = UserSlice.actions;
export default UserSlice.reducer;
