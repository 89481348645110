import { ErrorMessage, Field } from "formik";
import React, { FC } from "react";
import AsyncSelect from "react-select/async";
import { main_color } from "../../themes/color";
import { FieldCommonProps } from "./FieldContainer";
import LabelField from "./LabelField";
import TextError from "./TextError";

export interface AsyncReactSelectFieldProps extends FieldCommonProps {
  [key: string]: unknown;
}

// link props for select https://react-select.com/props
// link AsyncSelect https://react-select.com/async
const AsyncReactSelectField: FC<AsyncReactSelectFieldProps> = (props) => {
  const { label, name, tooltip, isRequired, id, options, ...rest } = props;
  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <Field name={name}>
        {({ field, form }: { field: any; form: any }) => (
          <AsyncSelect
            cacheOptions
            defaultOptions
            noOptionsMessage={() => "Không có kết quả tìm kiếm"}
            styles={{
              menuPortal: (base: any) => ({
                ...base,
                zIndex: 1301,
              }),
              control: (base) => ({
                ...base,
                borderColor: "rgba(0,0,0,0.23)",

                // outline: `2px solid ${main_color}`,
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  borderColor: main_color,
                },
                "&:focus": {
                  boxShadow: "none",
                  borderColor: main_color,
                },
              }),
            }}
            {...rest}
          />
        )}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default AsyncReactSelectField;
