import { Button, Grid, Stack } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { filterDepartmentApi, hrCreateDepartment } from "../../../api/hr.api";
import FieldContainer from "../../../components/form/FieldContainer";
import ModalContainer from "../../../components/modal/ModalContainer";
import { FORM_MSG, HR_MSG } from "../../../constants/msg/msg.constant";
import { HrDepartmentModel } from "../../../model/personnel.model";
import { DepartmentModel } from "../../../model/user.model";
import { spacing_size } from "../../../themes/size";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { useAppDispatch } from "../../../store/hook";
import { getAllDepartmentApiAsync } from "../redux/personnel.reducer";
import { PAGEABLE_MAX_SIZE } from "../../../constants/page.constant";

interface ModalCreateDepartmentProps {
  open: boolean;
  onClose: () => void;
}

const ModalCreateDepartment: FC<ModalCreateDepartmentProps> = (props) => {
  const { open, onClose } = props;
  const dispatch = useAppDispatch();

  const initialValues: HrDepartmentModel = {
    code: "",
    name: "",
    parentId: "",
  };

  const [departmentActive, setDepartmentActive] = useState<DepartmentModel[]>([]);

  const validationSchema = Yup.object({
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Mã phòng ban")),
    name: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Phòng ban")),
  });

  const getDepartmentActive = async () => {
    const body = {
      name: null,
      status: 1,
    };
    const pageable = { page: 0, size: 1000000 };
    await filterDepartmentApi(body, pageable).then((res) => {
      setDepartmentActive(res.data);
    });
  };

  useEffect(() => {
    getDepartmentActive();
  }, []);

  const handleChangeDepartmentActive = (formik: FormikHelpers<HrDepartmentModel>) => (e: HrDepartmentModel) => {
    formik.setFieldValue("parentId", e);
  };

  const handleSubmit = async (values: HrDepartmentModel, formik: FormikHelpers<HrDepartmentModel>) => {
    const newValues: HrDepartmentModel = {
      ...values,
      parentId: values?.id,
    };
    await hrCreateDepartment(newValues)
      .then((res) => {
        if (res) {
          dispatch(getAllDepartmentApiAsync({ body: { name: null, status: "ACTIVE" }, pageable: PAGEABLE_MAX_SIZE }));
          onClose();
          toast.success(HR_MSG.createDepartmentSuccess);
        }
      })
      .catch((err) => {
        toast.error(getErrorMsgAxios(err));
      });
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Thêm mới phòng ban"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnChange={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off" style={{ padding: "0px 1px" }}>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.code}
                    placeholder={FORM_MSG.placeholder}
                    label="Mã phòng ban"
                    isRequired={true}
                    variant={"input"}
                    id={"code"}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.name}
                    placeholder={FORM_MSG.placeholder}
                    label="Phòng ban"
                    isRequired={true}
                    variant={"input"}
                    id={"name"}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="parentId"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeDepartmentActive(formik)}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    maxRows={12}
                    value={formik.values.parentId}
                    placeholder={FORM_MSG.placeholder}
                    label="Phòng ban cha"
                    variant={"react-select"}
                    id={"parentId"}
                    getOptionLabel={(option: any) => `${option.name}`}
                    getOptionValue={(option: any) => option.code}
                    options={departmentActive}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Lưu
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalCreateDepartment;
