import { SvgIconProps } from "@mui/material/SvgIcon";
import React, { FC } from "react";

const AddCircleIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)" stroke="#D4D9E4" strokeLinecap="round" strokeLinejoin="round">
        <path d="M7 12.834A5.833 5.833 0 1 0 7 1.167a5.833 5.833 0 0 0 0 11.667Zm2.475-5.812-4.95-.044m2.497-2.453-.044 4.95" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddCircleIcon;
