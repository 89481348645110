import { registerLocale, setDefaultLocale } from "react-datepicker";

// import style
import "./App.scss";
import "react-datepicker/dist/react-datepicker.css";

// config toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// config datepicker
import vi from "date-fns/locale/vi";
import Spinner from "./components/common/Spinner";
import { useAppSelector } from "./store/hook";
registerLocale("vi", vi);
setDefaultLocale("vi");

function App() {
  const loading = useAppSelector((state) => state.common.spinnerLoading);
  const fetchCount = useAppSelector((state: any) => state.common.fetchCount);

  return (
    <>
      <Spinner open={fetchCount !== 0} />
      <Spinner open={loading} />
      <ToastContainer />
    </>
  );
}

export default App;
