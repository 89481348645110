import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import BoxContainer from "../../../../components/common/BoxContainer";
import NotFoundFilterView from "../../../../components/common/NotFoundFilterView";
import PaginationPage from "../../../../components/common/Pagination";
import ReadMore from "../../../../components/common/ReadMore";
import { VIEW_DATE_FORMAT } from "../../../../constants/date-time.constant";
import { ResultDocOutModel } from "../../../../model/doc-out.model";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { header_table_color } from "../../../../themes/color";
import { setPageDocOut } from "../redux/doc-out.reducer";

interface ViewFilterDocOutProps {
  bodyFilter: any;
  docBook: any;
}

const ViewFilterDocOut: FC<ViewFilterDocOutProps> = (props) => {
  const { bodyFilter, docBook } = props;

  const dispatch = useAppDispatch();

  const page = useAppSelector((state) => state.docOut.pageDocOut);
  const totalPage = useAppSelector((state) => state.docOut.totalDocOutDetail);
  const docOutDetail = useAppSelector((state) => state.docOut.docOutDetail);

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setPageDocOut(page - 1));
  };
  return (
    <>
      {docOutDetail && (
        <BoxContainer>
          {docOutDetail?.length > 0 && (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: header_table_color }}>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Số đi</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Trích yếu</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Loại công văn đi</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Sổ công văn</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Nơi nhận thông tin</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Số công văn đến</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Người tạo</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Thời gian tạo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {docOutDetail?.map((item: ResultDocOutModel, index: number) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                        }}
                        key={index}
                      >
                        <TableCell>
                          <Link
                            to={`/doc-out/${item.id}`}
                            state={{
                              bodyFilter: bodyFilter,
                              docBook: docBook,
                            }}
                            style={{ textDecoration: "none", color: "#0084FF" }}
                          >
                            {item?.arrivalNbr}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ width: "20%" }}>
                          <ReadMore text={item?.summary} />
                        </TableCell>
                        <TableCell>{item?.docTypeFullName}</TableCell>
                        <TableCell>{item?.docBook}</TableCell>
                        <TableCell>{item?.fromPlace}</TableCell>
                        <TableCell>
                          {item?.codeDocIn && (
                            <>
                              {item?.codeDocIn} - {item?.arrivalNbrDocIn}
                            </>
                          )}
                        </TableCell>
                        <TableCell>{item?.createdBy}</TableCell>
                        <TableCell>{moment(item?.createdDate).format(VIEW_DATE_FORMAT)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationPage total={totalPage} page={page} onChange={handleChangePage} />
            </>
          )}
          {docOutDetail.length === 0 && <NotFoundFilterView />}
        </BoxContainer>
      )}
    </>
  );
};

export default ViewFilterDocOut;
