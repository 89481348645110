import { toast } from "react-toastify";
import { COMMON_MSG } from "../constants/msg/msg.constant";
import FileSaver from "file-saver";
import { ONE_MB } from "../constants/file.constant";

export const fileToUrl = (file: File) => {
  return URL.createObjectURL(file);
};

export const isAllowExtensionFile = (file: File, extensions: string[], enableToast = true): boolean => {
  const splitStr = file?.name?.split(".");
  const extFile = splitStr[splitStr.length - 1].toLowerCase();

  for (const ext of extensions) {
    if (extFile.toLowerCase() === ext.toLowerCase()) {
      return true;
    }
  }

  enableToast && toast.warning(COMMON_MSG.acceptExtensionFile(extensions.join(", ")));

  return false;
};

export const isMinSizeFile = (file: File, minSizeMB: number, enableToast = true): boolean => {
  if (file.size <= minSizeMB * ONE_MB) {
    enableToast && toast.warning(COMMON_MSG.minSizeFileUpload(minSizeMB));
    return true;
  }
  return false;
};

export const isMaxSizeFile = (file: File, maxSizeMB: number, enableToast = true): boolean => {
  if (file.size >= maxSizeMB * ONE_MB) {
    enableToast && toast.warning(COMMON_MSG.maxSizeFileUpload(maxSizeMB, "mb"));
    return true;
  }
  return false;
};

export const isDuplicateFile = (files: File[], fileCheck: File): boolean => {
  if (files.length === 0) {
    return false;
  }

  for (let i = 0; i < files.length; i++) {
    if (fileCheck.name === files[i].name && fileCheck.size === files[i].size) {
      return true;
    }
  }
  return false;
};

export const isTypeImage = (file: File) => {
  if (!file) {
    return false;
  }
  return file.type.startsWith("image/");
};

export const isDuplicateFileUploaded = (filesUploaded: any[], fileCheck: File) => {
  if (filesUploaded.length === 0) {
    return false;
  }

  for (let i = 0; i < filesUploaded.length; i++) {
    if (fileCheck.name === filesUploaded[i].name) {
      return true;
    }
  }
  return false;
};

export const removeFile = (item: File, list: File[]): File[] => {
  return list.filter((element: any) => element !== item);
};

export const convertFilesToArr = (files: FileList) => {
  const arrFiles: any[] = [];

  if (files === null || files.length === 0) {
    return arrFiles;
  }

  for (let i = 0; i < files.length; i++) {
    arrFiles.push(files[i]);
  }

  return arrFiles;
};

export const downloadFile = (file: File, hasToast = false) => {
  if (file) {
    FileSaver.saveAs(file, file.name);
    if (hasToast) {
      toast.success("Xuất file thành công.");
    }
  }
};

export const previewOrDowndloadFile = (name: string, docType: string, data: Blob): void => {
  if (docType.toLowerCase() === "pdf") {
    previewFile(data);
  } else {
    downloadBlobFile(data, name);
  }
};

export const previewAndDowndloadFile = (name: string, docType: string, data: Blob): void => {
  downloadBlobFile(data, name);
  if (docType.toLowerCase() === "pdf") {
    previewFile(data);
  }
};

export const getFileExtension = (filename: string): string => {
  return filename.split(".").pop() || "";
};

const previewFile = (data: Blob) => {
  const file = new Blob([data], { type: "application/pdf" });
  const blobUrl = URL.createObjectURL(file);
  window.open(blobUrl, "_blank");
};

const downloadBlobFile = (data: Blob, name: string) => {
  FileSaver.saveAs(data, name);
  toast.success("Xuất file thành công.");
};

export const subStringFileName = (item: File) => {
  const originalString = item?.name;
  let newItem = item;
  const regexPattern = /_(.*)/;
  const match = originalString.match(regexPattern);
  if (match) {
    const substringToKeep = match[1];
    const fileType = item.type;
    const blob = item.slice(0, item.size, item.type);
    newItem = new File([blob], substringToKeep, { type: fileType });
  }
  return newItem;
};
