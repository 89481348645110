import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import BoxContainer from "../../../components/common/BoxContainer";
import NotFoundFilterView from "../../../components/common/NotFoundFilterView";
import PaginationPage from "../../../components/common/Pagination";
import { DepartmentModel } from "../../../model/user.model";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { header_table_color } from "../../../themes/color";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { changePageDepartment } from "../user/redux/user.slice";
import ModalUpdateDepartment from "./modal/ModalUpdateDepartment";

function ResultDepartment(props: any) {
  const { buildDataFilter, initValue } = props;
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.user.dataDepartment);
  const total = useAppSelector((state) => state.user.totalDataDepartment);
  const page = useAppSelector((state) => state.user.pageDepartment);
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);
  const [departmentUpdate, setDepartmentUpdate] = useState<any>(null);
  const handleOpenModal = (departmentUpdate: any) => async () => {
    try {
      setDepartmentUpdate(departmentUpdate);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }

    setOpenModalUpdate(true);
  };

  const handleCloseModal = () => {
    setOpenModalUpdate(false);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(changePageDepartment(page - 1));
  };
  return (
    <>
      {departmentUpdate && (
        <ModalUpdateDepartment
          open={openModalUpdate}
          onClose={handleCloseModal}
          department={departmentUpdate}
          buildDataFilter={buildDataFilter}
          initValue={initValue}
        />
      )}
      {data && (
        <BoxContainer>
          {data.length > 0 && (
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: header_table_color }}>
                      <TableCell sx={{ fontWeight: 600 }}>Mã bộ phận công tác</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Bộ phận công tác</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Bộ phận công tác cha</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Email bộ phận công tác</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Trạng thái</TableCell>
                      <TableCell align="center" sx={{ fontWeight: 600 }}>
                        Hành động
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item: DepartmentModel, index: number) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                        }}
                        key={index}
                      >
                        {/* <TableCell align="center">{SIZE_DEFAULT * page + index + 1}</TableCell> */}
                        <TableCell>{item?.code}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.parentDepartment?.name}</TableCell>
                        <TableCell>{item?.email}</TableCell>
                        <TableCell
                          sx={{
                            color: item?.status === "ACTIVE" ? "#4ACB1D" : "#CA3D3D",
                          }}
                        >
                          {item?.status === "ACTIVE" ? "Hoạt động" : "Không hoạt động"}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="outlined"
                            onClick={handleOpenModal(item)}
                            color="primary"
                            size="small"
                            type="button"
                          >
                            Cập nhật
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationPage total={total} page={page} onChange={handleChangePage} />
            </Box>
          )}

          {/* not found result */}
          {data.length === 0 && <NotFoundFilterView />}
        </BoxContainer>
      )}
    </>
  );
}

export default ResultDepartment;
