import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ACCESS_RIGHT_ENUM, POSITION_CODE_ACCESS_RIGHT_ENUM } from "../enum/access-right.enum";
import { SidebarModel } from "../model/sidebar.model";
import { useAppSelector } from "../store/hook";
import { SidebarUtil } from "../utils/sidebar.util";
import MainDrawer from "./drawer/MainDrawer";
import Header from "./header/Header";
import { getIconIndex } from "./sidebar/GetIconSidebar";
import Sidebar from "./sidebar/Sidebar";

const Layout = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const dataSideBar: SidebarModel[] = [
      {
        icon: getIconIndex("DashboardSideBarIcon"),
        id: "dashboard",
        link: "/dashboard",
        title: "Thống kê",
        roles: [ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.DASHBOARD],
        position: [],
      },
      {
        icon: getIconIndex("DocInIcon"),
        id: "Công văn",
        link: "",
        title: "Công văn",
        children: [
          {
            icon: getIconIndex("DocInIcon"),
            id: "Công văn đến",
            link: "/doc-in",
            title: "Công văn đến",
            patternLinks: ["/doc-in", "/doc-in/create", "/doc-in/:id"],
          },
          {
            icon: getIconIndex("DocOutIcon"),
            id: "Công văn đi",
            link: "/doc-out",
            title: "Công văn đi",
            patternLinks: ["/doc-out", "/doc-out/create", "/doc-out/:id"],
            roles: [ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.CLERICAL_ASSISTANT],
            position: [
              POSITION_CODE_ACCESS_RIGHT_ENUM.CHIEF_OF_OFFICE,
              POSITION_CODE_ACCESS_RIGHT_ENUM.DEPUTY_CHIEF_OF_OFFICE,
              POSITION_CODE_ACCESS_RIGHT_ENUM.GENERAL_SECRETARY,
              POSITION_CODE_ACCESS_RIGHT_ENUM.CHAIRMAN,
            ],
          },
          {
            icon: getIconIndex("UserIcon", "small"),
            id: "Sổ công văn",
            link: "/doc-book",
            title: "Sổ công văn",
            roles: [ACCESS_RIGHT_ENUM.ADMIN],
            position: [],
            sxIcon: {},
            sxText: {
              marginLeft: "5px",
            },
            patternLinks: ["/doc-book"],
          },
        ],
      },
      {
        icon: getIconIndex("UserGroupSidebarIcon"),
        id: "Nhân sự",
        link: "",
        title: "Nhân sự",
        roles: [ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.HR],
        position: [],
        children: [
          {
            icon: getIconIndex("UserIcon", "small"),
            id: "Danh sách nhân sự",
            link: "/personnel",
            title: "Danh sách nhân sự",
            sxIcon: {},
            sxText: {
              marginLeft: "5px",
            },
            patternLinks: ["/personnel"],
          },
          {
            icon: getIconIndex("UserSettingIcon", "small"),
            id: "Danh sách bộ phận",
            link: "/department",
            title: "Danh sách bộ phận",
            sxIcon: {
              // marginLeft: "14px",
            },
            sxText: {
              marginLeft: "5px",
            },
          },
          {
            icon: getIconIndex("UserSettingIcon", "small"),
            id: "Danh sách chức vụ",
            link: "/position",
            title: "Danh sách chức vụ",
            sxIcon: {
              // marginLeft: "14px",
            },
            sxText: {
              marginLeft: "5px",
            },
          },
        ],
      },
      {
        icon: getIconIndex("UserGroupSidebarIcon"),
        id: "user",
        link: "/user",
        title: "Quản lý người dùng",
        roles: [ACCESS_RIGHT_ENUM.ADMIN],
        position: [],
        children: [
          {
            icon: getIconIndex("UserIcon", "small"),
            id: "abc1_children",
            link: "/user",
            title: "Danh sách người dùng",
            sxIcon: {},
            roles: [ACCESS_RIGHT_ENUM.ADMIN],
            sxText: {
              marginLeft: "5px",
            },
            patternLinks: ["/abc/a1", "/abc/a1/a11"],
          },
          {
            icon: getIconIndex("UserSettingIcon", "small"),
            id: "Phân quyền",
            link: "/role",
            title: "Phân quyền",
            roles: [ACCESS_RIGHT_ENUM.ADMIN],
            sxIcon: {
              // marginLeft: "14px",
            },
            sxText: {
              marginLeft: "5px",
            },
          },
        ],
      },
      {
        icon: getIconIndex("UserGroupSidebarIcon"),
        id: "Công việc",
        link: "",
        title: "Công việc",
        // roles: [ACCESS_RIGHT_ENUM.ADMIN],
        children: [
          {
            icon: getIconIndex("UserIcon", "small"),
            id: "Danh sách công việc",
            link: "/task",
            title: "Danh sách công việc",
            sxIcon: {},
            sxText: {
              marginLeft: "5px",
            },
            patternLinks: ["/task"],
          },
        ],
      },
      {
        icon: getIconIndex("UserGroupSidebarIcon"),
        id: "Hướng dẫn sử dụng",
        link: "",
        title: "Hướng dẫn sử dụng",
        children: [
          {
            icon: getIconIndex("UserGroupSidebarIcon"),
            id: "Hướng dẫn sử dụng office",
            link: "user-manual/office",
            title: "Công văn",
          },
          {
            icon: getIconIndex("UserGroupSidebarIcon"),
            id: "Hướng dẫn sử dụng hr",
            link: "user-manual/hr",
            roles: [ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.HR],
            position: [],
            title: "Nhân sự",
          },
          {
            icon: getIconIndex("UserGroupSidebarIcon"),
            id: "Hướng dẫn sử dụng task",
            link: "user-manual/task",
            title: "Quản lý công việc",
          },
        ],
      },
    ];
    const res = SidebarUtil(
      dataSideBar,
      currentUser?.accessRights.map((item: any) => item.code) || [],
      null,
      currentUser?.positionCode || null,
      currentUser?.isPicDocOut || false
    );
    setData(res);
  }, [currentUser]);
  return (
    <>
      <Sidebar data={data} />
      <Header />
      <MainDrawer data={data} />
      <Box
        component="main"
        sx={{
          position: "relative",
          marginLeft: { xs: "0px", md: "280px" },
          height: "calc(100vh - 49px - 20px)",
          overflow: "hidden",
          padding: {
            md: "20px 0px",
          },
        }}
      >
        <Box
          component="div"
          sx={{
            position: "absolute",
            top: 0,
            minHeight: "50vh",
            width: "calc(90% - 60px)",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(29, 52, 115, 1)",
            borderRadius: "15px",
            margin: "auto",
          }}
        ></Box>
        <Box
          component="div"
          sx={{
            position: "absolute",
            top: "10px",
            minHeight: "50vh",
            width: "calc(96% - 60px)",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(94, 115, 175, 0.5)",
            borderRadius: "15px",
            margin: "auto",
          }}
        ></Box>
        <Box
          component="div"
          sx={{
            backgroundColor: "white",
            position: "relative",
            borderRadius: "15px",
            margin: { md: "0px 1.2rem", xs: "0px 1rem" },
            height: "calc(100%)",
            overflowY: "scroll",
            padding: "24px",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Layout;
