import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getDashboardDocIns, getDocInDashboard } from "../../../../api/doc-in.api";
import { TOTAL_HEADER } from "../../../../constants/page.constant";
import { DocInDashboard, InitialDashboardDocInsForm, InitialDocInDashboardForm, ResponseDashboardDocInsModel } from "../../../../model/doc-in.model";
import { Pageable } from "../../../../model/page.model";
import { getErrorMsgAxios } from "../../../../utils/error.util";

interface DocInDashboardReducerState {
  pageDocInDashboard: number;
  totalDocInDashboard: number;
  isReloadDashboard: boolean;
  detailDocInDashboards: DocInDashboard[] | null
  docInDashboardForm: InitialDocInDashboardForm;
  dashboardDocInsForm: InitialDashboardDocInsForm;
  dashboardDocInsData: ResponseDashboardDocInsModel[] | null;
  pageDashboardDocIns: number;
  totalDashboardDocIns: number;
  countLoadDashboardDocIns: number;
}

const initialDocInDashboardFormValue: InitialDocInDashboardForm = {
  category: null,
  docBookId: null,
  dashboardDepartmentCode: null,
}

const initialDashboardDocInsFormValue: InitialDashboardDocInsForm = {
  dashBoardType: null,
  docBookId: null,
  departmentCode: null,
  fromPlace: null,
  code: null,
  summary: null,
  category: null,
  fromDate: null,
  toDate: null,
  departmentName: null,
}

const initialState: DocInDashboardReducerState = {
  pageDocInDashboard: 0,
  totalDocInDashboard: 0,
  isReloadDashboard: true,
  detailDocInDashboards: null,
  docInDashboardForm: initialDocInDashboardFormValue,
  dashboardDocInsForm: initialDashboardDocInsFormValue,
  dashboardDocInsData: null,
  pageDashboardDocIns: 0,
  totalDashboardDocIns: 0,
  countLoadDashboardDocIns: 0
};

export const getDocInDashboardThunk = createAsyncThunk(
  "doc-in/dashboard",
  async (data: { body: any; pageable: Pageable }) => {
    try {
      const res = await getDocInDashboard(data.body, data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      const msg = getErrorMsgAxios(error);
      toast.error(msg);
      throw Error(error);
    }
  }
);

export const getDashboardDocInsThunk = createAsyncThunk(
  "doc-in/dashboard/doc-ins",
  async (data: { body: any; pageable: Pageable }) => {
    try {
      const res = await getDashboardDocIns(data.body, data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      const msg = getErrorMsgAxios(error);
      toast.error(msg);
      throw Error(error);
    }
  }
);

const DocInDashboardReducer = createSlice({
  name: "DocInDashboardReducer",
  initialState,
  reducers: {
    setPageDocInDashboard(state, action) {
      return {
        ...state,
        pageDocInDashboard: action.payload,
      };
    },
    setDocInDashboardForm(state, action) {
      return {
        ...state,
        docInDashboardForm: action.payload
      }
    },
    setIsReloadDashboard(state, action) {
      return {
        ...state,
        isReloadDashboard: action.payload
      }
    },
    setDashboardDocInsForm(state, action) {
      return {
        ...state,
        dashboardDocInsForm: action.payload
      }
    },
    setPageDashboardDocIns(state, action) {
      return {
        ...state,
        pageDashboardDocIns: action.payload
      }
    },
    setCountLoadDashboardDocIns(state, action) {
      return {
        ...state,
        countLoadDashboardDocIns: action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getDocInDashboardThunk.fulfilled,
        (
          state,
          action: {
            payload: { data: DocInDashboard[] | null; total: number };
          }
        ) => {
          return {
            ...state,
            detailDocInDashboards: action.payload.data,
            totalDocInDashboard: action.payload.total,
          };
        }
      )
      .addCase(
        getDashboardDocInsThunk.fulfilled,
        (
          state,
          action: {
            payload: { data: ResponseDashboardDocInsModel[] | null; total: number };
          }
        ) => {
          return {
            ...state,
            dashboardDocInsData: action.payload.data,
            totalDashboardDocIns: action.payload.total,
          };
        }
      )
  },
});

export const {
  setPageDocInDashboard,
  setDocInDashboardForm,
  setIsReloadDashboard,
  setDashboardDocInsForm,
  setPageDashboardDocIns,
  setCountLoadDashboardDocIns
} = DocInDashboardReducer.actions;
export default DocInDashboardReducer.reducer;