import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getDashboardTaskReceivers } from "../../../api/hr.api";
import BoxContainer from "../../../components/common/BoxContainer";
import FieldContainer from "../../../components/form/FieldContainer";
import LabelField from "../../../components/form/LabelField";
import SearchIcon from "../../../components/icons/SearchIcon";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { SIZE_DEFAULT } from "../../../constants/page.constant";
import { ALL_ENUM, AllItem } from "../../../enum/common.enum";
import {
  objectsTaskDashboardEnums,
  objectsTaskFieldFilterEnums,
  objectsTaskStatusEnum,
  taskPriorityOptions,
} from "../../../enum/task.enum";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { spacing_size } from "../../../themes/size";
import { sortByDescriptionASCEnum } from "../../../utils/array.util";
import { isValueAllEnum } from "../../../utils/axios.ultil";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { setPageDocOut } from "../../document/document-out/redux/doc-out.reducer";
import { filterTaskApiAsync, setPageFilterTask } from "../redux/task.reducer";
import { FROM_DASHBOARD_TASK } from "../../../constants/navigate.constant";

interface FormSearchProps {
  valueForm: any;
  setValueForm: any;
  defaultValueForm: any;
}

const FormSearch: FC<FormSearchProps> = (props) => {
  const { defaultValueForm, valueForm, setValueForm } = props;
  const location = useLocation();

  const dispatch = useAppDispatch();
  const reloadFilterDocIn = useAppSelector((state) => state.task.reloadTaskFilter);
  const page = useAppSelector((state) => state.task.pageTask);
  const [isInitFilter, setIsInitFilter] = useState(true);
  const [fieldValueData, setFieldValueData] = useState([]);

  const validationSchema = Yup.object({
    fromDate: Yup.date().nullable(),
    toDate: Yup.date()
      .nullable()
      .when("fromDate", (fromDate: any, schema: any) => {
        if (fromDate) {
          return schema.min(fromDate, FORM_MSG.fromDateMoreToDate);
        }
        return schema;
      }),
    // fieldValues: Yup.mixed().when("fieldFilter", (fieldFilter: any, schema: any) => {
    //   if (fieldFilter !== ALL_ENUM) {
    //     return schema.min(1, "Với giá trị");
    //   }
    //   return schema;
    // }),
  });

  const buildBodyFilter = (values: any) => {
    const status = isValueAllEnum(values.status) ? null : values.status;
    const fieldFilter = isValueAllEnum(values.fieldFilter) ? null : values.fieldFilter;
    const fieldValues = fieldFilter && values.fieldValues ? values.fieldValues.map((item: any) => item?.email) : null;
    const priority = isValueAllEnum(values.priority) ? null : values.priority;
    const stateLocation = location?.state;
    if (stateLocation?.navigateFrom == FROM_DASHBOARD_TASK) {
      values["fromDashboard"] = true;
      values["dashboardAssignee"] = stateLocation?.dashboard?.email;
      values["dashboardStatus"] = stateLocation?.dashboard?.status;
      const year = stateLocation?.dashboard?.year;
      values["fromDate"] = new Date(year, 0, 1);
      values["toDate"] = new Date(year, 11, 31);
    }
    return {
      ...values,
      status,
      fieldFilter,
      fieldValues,
      priority,
    };
  };

  const loadDataFilter = (body = valueForm) => {
    const pageable = { page, size: SIZE_DEFAULT };
    const bodyFilter = buildBodyFilter(body);
    setValueForm(bodyFilter);
    dispatch(filterTaskApiAsync({ body: bodyFilter, pageable }));
  };

  useEffect(() => {
    getDashboardTaskReceivers({}, { page: 0, size: 999999 })
      .then((res) => {
        setFieldValueData(res?.data);
      })
      .catch((err) => {
        if (err.response.data.status === 404) {
          const msg = getErrorMsgAxios(err);
          toast.error(msg);
        }
      });
  }, []);

  useEffect(() => {
    if (reloadFilterDocIn !== 0) {
      loadDataFilter();
    }
  }, [reloadFilterDocIn]);

  useEffect(() => {
    if (isInitFilter) {
      setIsInitFilter(false);
      if (page > 0) {
        dispatch(setPageDocOut(0));
      } else {
        const stateLocation = location?.state;
        const body = {
          status: stateLocation?.bodyFilter?.status,
          fieldFilter: stateLocation?.bodyFilter?.fieldFilter,
          fieldValues: stateLocation?.bodyFilter?.fieldValues,
          code: stateLocation?.bodyFilter?.code,
          subject: stateLocation?.bodyFilter?.subject,
          fromDate: stateLocation?.bodyFilter?.fromDate,
          toDate: stateLocation?.bodyFilter?.toDate,
          priority: stateLocation?.bodyFilter?.priority,
        };
        loadDataFilter(body);
      }
    } else {
      loadDataFilter();
    }
  }, [page]);

  const handleChangeFieldFilter = (formik: any) => (e: any) => {
    formik.setFieldValue("fieldFilter", e.target.value);
    setValueForm({
      ...valueForm,
      fieldFilter: e.target.value,
      fieldValues: [],
    });
  };

  const handleChangeFieldValues = (formik: any) => (e: any) => {
    formik.setFieldValue("fieldValues", e);
    setValueForm({
      ...valueForm,
      fieldValues: e,
    });
  };

  const onSubmitSearch = async (values: any) => {
    const body = buildBodyFilter(values);
    setValueForm(values);
    if (page === 0) {
      const pageable = {
        page,
        size: SIZE_DEFAULT,
      };
      dispatch(filterTaskApiAsync({ body: buildBodyFilter(values), pageable }));
    } else {
      dispatch(setPageFilterTask(0));
    }
  };

  const handleCancel = (formik: any) => {
    formik.resetForm();
    setValueForm(defaultValueForm);
    if (page === 0) {
      loadDataFilter(defaultValueForm);
    } else {
      dispatch(setPageFilterTask(0));
    }
  };

  return (
    <>
      <BoxContainer>
        <Formik
          initialValues={valueForm}
          validationSchema={validationSchema}
          onSubmit={onSubmitSearch}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={spacing_size}>
                <Grid item md={4} xs={12}>
                  <FieldContainer
                    fullWidth
                    name="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.code}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    placeholder="Nhập thông tin"
                    maxRows={12}
                    label="Mã công việc"
                    notched={true}
                    variant={"input"}
                    id={"code"}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Stack direction={"row"} spacing={spacing_size} alignItems={"center"}>
                    <Typography component={"div"} flexGrow={1}>
                      <FieldContainer
                        name="fieldFilter"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeFieldFilter(formik)}
                        label="Tìm kiếm trong"
                        variant={"select"}
                        id={"fieldFilter"}
                        labelOption="description"
                        valueOption="code"
                        options={
                          location?.state?.navigateFrom == FROM_DASHBOARD_TASK
                            ? [AllItem, ...objectsTaskDashboardEnums]
                            : [AllItem, ...objectsTaskFieldFilterEnums]
                        }
                        value={formik.values.fieldFilter}
                      />
                    </Typography>
                    <Typography sx={{ pt: 2 }}>Với giá trị</Typography>
                  </Stack>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldContainer
                    onChange={handleChangeFieldValues(formik)}
                    isMulti
                    isClearable
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    label={<span>&nbsp;</span>}
                    name="fieldValues"
                    value={formik.values.fieldValues}
                    getOptionLabel={(item: any) => `${item.email}`}
                    getOptionValue={(item: any) => item.email}
                    options={fieldValueData}
                    placeholder={FORM_MSG.reactSelectPlaceholder}
                    variant={"react-select"}
                    id={"fieldValues"}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <LabelField label={"Thời gian"} name={""} />
                  <Stack direction={"row"} spacing={1} alignItems="center">
                    <Grid item xs={12} md={6} lg={6}>
                      <FieldContainer
                        name="fromDate"
                        onBlur={formik.handleBlur}
                        notched={true}
                        variant={"date"}
                        id={"fromDate"}
                        placeholderText="Từ ngày"
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                      />
                    </Grid>
                    <HorizontalRuleIcon fontSize="small" />
                    <Grid item xs={12} md={6} lg={6}>
                      <FieldContainer
                        name="toDate"
                        onBlur={formik.handleBlur}
                        notched={true}
                        variant={"date"}
                        id={"toDate"}
                        placeholderText="Đến ngày"
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                      />
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FieldContainer
                    fullWidth
                    name="subject"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.subject}
                    placeholder="Nhập thông tin"
                    maxRows={12}
                    label="Công việc"
                    notched={true}
                    variant={"input"}
                    id={"subject"}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <FieldContainer
                    name="status"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="Trạng thái"
                    variant={"select"}
                    id={"status"}
                    labelOption="description"
                    valueOption="code"
                    options={[AllItem, ...objectsTaskStatusEnum.sort(sortByDescriptionASCEnum)]}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <FieldContainer
                    name="priority"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="Độ ưu tiên"
                    variant={"select"}
                    id={"status"}
                    labelOption="description"
                    valueOption="code"
                    options={[AllItem, ...taskPriorityOptions.sort(sortByDescriptionASCEnum)]}
                  />
                </Grid>
                <Grid item xs={12} md={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      mt: 2,
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="cancel"
                      type="button"
                      onClick={() => {
                        handleCancel(formik);
                      }}
                    >
                      Hủy
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      startIcon={<SearchIcon />}
                      disabled={
                        valueForm?.fieldFilter !== ALL_ENUM && valueForm?.fieldValues?.length === 0 ? true : false
                      }
                    >
                      Tìm kiếm
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </BoxContainer>
    </>
  );
};

export default FormSearch;
