import { ErrorMessage, Field } from "formik";
import React, { FC } from "react";
import { FieldCommonProps } from "./FieldContainer";
import LabelField from "./LabelField";
import TextError from "./TextError";

export interface CheckboxButtonProps extends FieldCommonProps {
  options?: Object[];
  labelOption?: string;
  valueOption?: string;
  [key: string]: unknown;
}

const CheckboxButton: FC<CheckboxButtonProps> = (props) => {
  const { label, name, options, tooltip, isRequired, labelOption = "label", valueOption = "value", ...rest } = props;

  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <Field name={name}>
        {({ field, form }: { field: any; form: any }) => {
          return options?.map((option: any) => {
            return (
              <span key={option[labelOption]} style={{ marginRight: "1rem", cursor: "pointer" }}>
                <input
                  type="checkbox"
                  // id={name}
                  {...field}
                  onChange={() => {
                    if (field.value.includes(option[valueOption])) {
                      const nextValue = field.value.filter((value: any) => value !== option[valueOption]);
                      form.setFieldValue(name, nextValue);
                    } else {
                      const nextValue = field.value.concat(option[valueOption]);
                      form.setFieldValue(name, nextValue);
                    }
                  }}
                  {...rest}
                  value={option[valueOption]}
                  id={`${name}_${option[valueOption]}`}
                  checked={field.value?.includes(option[valueOption])}

                />
                <label htmlFor={`${name}_${option[valueOption]}`} style={{ cursor: "pointer" }}>
                  {option[labelOption]}
                </label>
              </span>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default CheckboxButton;
