import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { currentUserApi } from "../../../api/auth.api";
import { AccessRightModel } from "../../../model/access-right.model";

export interface CurrentUserModel {
  accessRight: string[];
  account: string;
  activated: boolean;
  code: string;
  department: string;
  email: string;
  fullName: string;
  id: number;
  imageUrl: string;
  phone: string;
  position: string;
  positionCode: string;
  resetKey: string;
  status: boolean;
  accessRights: AccessRightModel[];
  departmentCode: string;
  isPicDocOut: boolean;
  dashboardDepartmentCode: string;
}

interface AuthSliceState {
  currentUser: CurrentUserModel | null;
}

const initialState: AuthSliceState = {
  currentUser: null,
};

export const getCurrentUserApiAsync = createAsyncThunk("AuthSlice/currentUser", async () => {
  try {
    const res = await currentUserApi();
    return res.data;
  } catch (error: any) {
    throw Error(error);
  }
});

const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
    setCurrentUser(state, action: { payload: CurrentUserModel | null }) {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
  },
  extraReducers: (build) => {
    build.addCase(getCurrentUserApiAsync.fulfilled, (state, action: { payload: CurrentUserModel | null }) => {
      return {
        ...state,
        currentUser: action.payload,
      };
    });
  },
});

export const { setCurrentUser } = AuthSlice.actions;
export default AuthSlice.reducer;
