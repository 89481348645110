import { Button, Grid, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import ModalContainer from "../../../../components/modal/ModalContainer";
import { spacing_size } from "../../../../themes/size";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import FieldContainer from "../../../../components/form/FieldContainer";
import { docInAssignmentApi, docInUnassignedApi } from "../../../../api/doc-in.api";
import { toast } from "react-toastify";
import { DOC_IN_MSG, FORM_MSG } from "../../../../constants/msg/msg.constant";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { useNavigate } from "react-router-dom";
import {
  DOC_IN_PRIORITY_TYPE_ENUM,
  DOC_IN_UNASSIGNED_TYPE_ENUM,
  docInPriorityTypeOptions,
  docInUnassignedTypeOptions,
} from "../../../../enum/doc-in.enum";
import { UserModel } from "../../../../model/user.model";
import OptionSelectUser from "../../../../components/common/OptionSelectUser";
import { filterOptions } from "../../../../utils/options.util";
import { getUserFromDepartment } from "../../../../api/department.api";
import { DepartmentCodeDocIn } from "../../../../model/department.model";

interface InitialValuesForm {
  comment: string;
  priority: string;
  deadline: string | Date | null | undefined;
  type?: any;
  account: string | object | null | any;
}

interface ModalUnassignedProps {
  open: boolean;
  id: any;
  onClose: () => void;
  handleOnSubmitModal: () => void;
  departmentCode: string | undefined;
}

const ModalUnassigned: FC<ModalUnassignedProps> = (props) => {
  const { open, id, onClose, handleOnSubmitModal, departmentCode } = props;

  const validationSchema = Yup.object({
    account: Yup.array()
      .nullable()
      .when("type", (type: any, schema: any) => {
        if (type === DOC_IN_UNASSIGNED_TYPE_ENUM.SIGNED_AND_ASSIGNED) {
          return schema.required(FORM_MSG.requiredSelectFieldMsg("Người thực hiện"));
        }
        return schema;
      })
      .min(1, FORM_MSG.requiredSelectFieldMsg("Người thực hiện")),
    priority: Yup.string().nullable().required(FORM_MSG.requiredSelectFieldMsg("Độ ưu tiên")),
  });
  const navigate = useNavigate();

  const initialValues: InitialValuesForm = {
    type: DOC_IN_UNASSIGNED_TYPE_ENUM.UNASSIGNED,
    account: "",
    comment: "Chuyển tới các Phòng để triển khai như thông tin công văn đính kèm ",
    deadline: null,
    priority: DOC_IN_PRIORITY_TYPE_ENUM.NORMAL,
  };

  const [userOfDepartment, setUserOfDepartment] = useState<UserModel[]>([]);

  const getUserOfDepartment = () => {
    getUserFromDepartment(departmentCode || "", "", false).then((res) => {
      setUserOfDepartment(res?.data);
    });
  };

  useEffect(() => {
    if (open) {
      getUserOfDepartment();
    }
  }, [open]);

  const handleChangeDeadlineTime = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("deadline", e);
  };

  const handleChangeType = (formik: FormikHelpers<InitialValuesForm>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("type", e.target.value);
  };

  const handleChangeAccount =
    (formik: FormikHelpers<InitialValuesForm>) => (e: Object | string | null | undefined | any, action: any) => {
      formik.setFieldValue("account", e);
    };

  const handleCancle = () => {
    onClose();
  };

  const handleSubmit = async (values: InitialValuesForm) => {
    const { account, comment, deadline, type, priority } = values;

    if (type === DOC_IN_UNASSIGNED_TYPE_ENUM.SIGNED_AND_ASSIGNED) {
      const convertAccount = account ? account?.map((item: DepartmentCodeDocIn) => item?.email) : [];
      const body = {
        id: Number(id),
        account: convertAccount,
        coordinator: [],
        comment: comment,
        actionType: type,
        priority: priority,
        deadline: deadline,
      };
      handleOnSubmitModal();
      await docInAssignmentApi(body)
        .then((res) => {
          if (res) {
            toast.success(DOC_IN_MSG.assignmentSuccess);
            onClose();
            navigate("/doc-in");
          }
        })
        .catch((err) => {
          const msg = getErrorMsgAxios(err);
          toast.error(msg);
          throw Error(err);
        });
    } else {
      const body = {
        comment: comment,
        deadline: deadline,
      };
      handleOnSubmitModal();
      await docInUnassignedApi(Number(id), body)
        .then((res) => {
          if (res) {
            toast.success(DOC_IN_MSG.unAssignedSuccess);
            onClose();
            navigate("/doc-in");
          }
        })
        .catch((err) => {
          const msg = getErrorMsgAxios(err);
          toast.error(msg);
          throw Error(err);
        });
      onClose();
    }
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title="Xác nhận"
      >
        <Typography id="modal-modal-description">
          Phiếu được gửi đến Phòng Chủ trì giải quyết. Vui lòng nhập thông tin và xác nhận!
        </Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off" style={{ margin: "0px 2px" }}>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={12} lg={12} sx={{ marginTop: "8px" }}>
                  <FieldContainer
                    name="type"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeType(formik)}
                    value={formik.values.type}
                    placeholder=""
                    label="Xác nhận chuyển"
                    variant={"radio"}
                    id={"type"}
                    labelOption="description"
                    valueOption="code"
                    options={docInUnassignedTypeOptions}
                    block={true}
                    isRequired
                  />
                </Grid>

                {formik?.values?.type === DOC_IN_UNASSIGNED_TYPE_ENUM.SIGNED_AND_ASSIGNED && (
                  <Grid item xs={12} md={12} lg={12}>
                    <FieldContainer
                      fullWidth
                      name="account"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeAccount(formik)}
                      value={formik.values.account}
                      placeholder="Nhập thông tin"
                      label="Người thực hiện"
                      isRequired
                      variant={"react-select"}
                      getOptionValue={(item: UserModel) => item.email}
                      getOptionLabel={(item: UserModel) => item.email}
                      id={"account"}
                      options={userOfDepartment}
                      menuPortalTarget={document.querySelector("body")}
                      components={{ Option: OptionSelectUser }}
                      isMulti
                      filterOption={filterOptions}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="comment"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 5000 }}
                    value={formik.values.comment}
                    placeholder="Nhập thông tin"
                    label="Ý kiến lãnh đạo văn phòng xử lý văn bản"
                    variant={"textarea"}
                    // isRequired
                    id={"comment"}
                  />
                </Grid>

                {formik?.values?.type === DOC_IN_UNASSIGNED_TYPE_ENUM.SIGNED_AND_ASSIGNED && (
                  <Grid item xs={12}>
                    <FieldContainer
                      name="priority"
                      label="Độ ưu tiên"
                      options={docInPriorityTypeOptions}
                      labelOption="description"
                      valueOption="code"
                      variant={"select"}
                      id={"priority"}
                      isRequired
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="deadline"
                    onBlur={() => {
                      // setOpenDeadLineTimeModal(true);
                    }}
                    onChange={handleChangeDeadlineTime(formik)}
                    value={formik.values.deadline}
                    placeholderText="dd/mm/yyyy hh:mm"
                    label="Thời hạn xử lý theo đề xuất của CVP/PCVP"
                    variant={"date"}
                    id={"deadline"}
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeCaption="Thời gian"
                    showTimeSelect
                    // customInput={<CustomInputCalendar btnRef={deadlineTimeBtnInputRef} onMouseDown={() => {}} />}
                  />
                </Grid>

                {/* handle  */}
                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button type="button" variant="outlined" onClick={handleCancle}>
                      Huỷ
                    </Button>
                    <Button type="submit" variant="contained">
                      Xác nhận
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalUnassigned;
