import axios from "axios";
import { TIMEOUT } from "../../constants/axios.constant";

const HTTP_TASK_SERVICE = axios.create({
  baseURL: process.env.REACT_APP_TASK_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: TIMEOUT,
});

export default HTTP_TASK_SERVICE;
