import React, { FC } from "react";

interface ContentInformationProps {
  title?: string;
  description?: string | any;
  top?: string | any;
}

const ContentInformation: FC<ContentInformationProps> = (props) => {
  const { title, description, top } = props;
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", marginTop: `${top}px` }}>
        <div style={{ width: "150px", fontSize: "14px", color: "#212B35", fontWeight: "bold" }}>{title}:</div>
        <div style={{ fontSize: "14px", color: "#212B35", fontWeight: "400" }}>{description}</div>
      </div>
    </>
  );
};

export default ContentInformation;
