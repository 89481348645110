export interface OptionCommon {
  value: string;
  label: string;
}

export const optionStatus: OptionCommon[] = [
  {
    label: "Đang làm việc",
    value: "NORMAL",
  },
  {
    label: "Nghỉ việc",
    value: "RETIRED",
  },
  {
    label: "Nghỉ thai sản",
    value: "MATERNITY_LEAVE",
  },
];

export const optionType: OptionCommon[] = [
  {
    label: "Chuyên gia",
    value: "0",
  },
  {
    label: "Bộ phận hành chính",
    value: "1",
  },
  {
    label: "VIP",
    value: "2",
  },
  {
    label: "Khác",
    value: "3",
  },
];
