import { Grid, Typography } from "@mui/material";

import { FC } from "react";

interface HeaderPageProps {
  title?: string;
  children?: JSX.Element;
  overTitleSx?: Object;
  [key: string]: unknown;
}

const HeaderPage: FC<HeaderPageProps> = (props) => {
  const { title, children, overTitleSx, ...rest } = props;
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
      <Typography {...rest} sx={{ fontWeight: 600, fontSize: "1.6rem", ...overTitleSx }}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

export default HeaderPage;
