import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import React, { FC, useRef, useState } from "react";
import * as Yup from "yup";
import BoxContainer from "../../../components/common/BoxContainer";
import ReadMore from "../../../components/common/ReadMore";
import ViewFile from "../../../components/common/ViewFile";
import FieldContainer from "../../../components/form/FieldContainer";
import TextError from "../../../components/form/TextError";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import ChooseFile from "../../../components/icons/ChooseFile";
import { VIEW_YEAR_FORMAT } from "../../../constants/date-time.constant";
import { VALID_FORMAT_FILE } from "../../../constants/file.constant";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { LevelModel, PersonnelModel } from "../../../model/personnel.model";
import { header_table_color } from "../../../themes/color";
import { spacing_size } from "../../../themes/size";
import { formatDateTime } from "../../../utils/data-time.util";
import { changeFileUploadPersonnel } from "../common/util/personnel.util";
import ModalDelete from "../modal/ModalDelete";
import ModalUpdateLevel from "../modal/ModalUpdateLevel";
import { downloadFile, subStringFileName } from "../../../utils/file.util";
import { subStringFunc } from "../../../utils/string.util";

interface LevelProps {
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  valueUploads: PersonnelModel | null;
  setFileEducationsUpload: React.Dispatch<React.SetStateAction<File[]>>;
  btnSubmitEducationRef: React.RefObject<HTMLButtonElement>;
  fileEducationsUpload: File[];
}

const Level: FC<LevelProps> = (props) => {
  const { valueUploads, setValuesUploads, setFileEducationsUpload, btnSubmitEducationRef, fileEducationsUpload } =
    props;

  const initialValues: LevelModel = {
    name: "",
    issuePlace: "",
    rank: "",
    issueYear: null,
    note: "",
    fileName: "",
    fileTemp: "",
  };

  const currentTime = new Date().getTime();

  const arrEducations: any = valueUploads?.educations;
  const [educationsDetail, setEducationsDetail] = useState<LevelModel[]>(arrEducations);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);
  const [indexEducation, setIndexEducation] = useState<number>(0);
  const [itemEducation, setItemEducation] = useState<LevelModel | null>(null);

  const [file, setFile] = useState<File[]>([]);
  const [arrFile, setArrFile] = useState<File[] | any>([]);
  const fileRef = useRef<any>(null);

  const validationSchema = Yup.object({
    name: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Tên bằng cấp chứng chỉ")),
    // fileTemp: Yup.mixed()
    //   .required(FORM_MSG.requiredFieldUploadFile("Đính kèm"))
    //   .test("fileFormat", "Chỉ tải lên file xlsx, xls, doc, docx, pdf", (value) => {
    //     if (!value) {
    //       return false;
    //     }
    //     return VALID_FORMAT_FILE.includes(value.type);
    //   }),
  });

  const handleOpenModalDelete = async (index: number, e: React.SyntheticEvent) => {
    setOpenModalDelete(true);
    setIndexEducation(index);
  };

  const handleCloseModal = () => {
    setOpenModalDelete(false);
  };

  const handleOpenModalUpdate = (item: LevelModel, index: number) => {
    setOpenModalUpdate(true);
    setItemEducation(item);
    setIndexEducation(index);
  };

  const handleCloseModalUpdate = () => {
    setOpenModalUpdate(false);
  };

  const changeUploadFile = (formik: FormikHelpers<LevelModel>) => (e: any) => {
    e.preventDefault();
    formik.setFieldValue("fileTemp", e.target.files[0]);
    const fileUp = changeFileUploadPersonnel(e, file, "", 1);
    const newFileUp = fileUp?.map((item: File, index: number) => {
      const newNameItem = currentTime + "_" + item?.name;
      const newItem = new File([item], newNameItem, { type: item.type });
      return newItem;
    });
    setFile(newFileUp);
    setArrFile([...arrFile, ...newFileUp]);
  };

  const buildBody = (values: LevelModel) => {
    const newValues = {
      ...values,
      fileName: file[0]?.name || "",
      issueYear: formatDateTime(values?.issueYear, VIEW_YEAR_FORMAT),
    };
    return newValues;
  };

  const handleOnSubmit = (values: LevelModel, formik: FormikHelpers<LevelModel>) => {
    const newValues = buildBody(values);
    setEducationsDetail([...educationsDetail, newValues]);
    setValuesUploads({ ...valueUploads, educations: [...educationsDetail, newValues] });
    setFileEducationsUpload(arrFile);
    formik.resetForm();
    setFile([]);
  };

  const handleDeleteItem = () => {
    const item: any = educationsDetail?.filter((item: LevelModel, i: number) => i !== indexEducation);
    const newArrFile = arrFile?.filter((item1: File, i: number) => indexEducation !== i);
    setArrFile(newArrFile);
    setFileEducationsUpload(newArrFile);
    setEducationsDetail(item);
    setValuesUploads({ ...valueUploads, educations: [...item] });
  };

  const handleClickSave = (formik: FormikProps<LevelModel>) => {
    // setValuesUploads({ ...valueUploads, educations: [...educationsDetail] });
    // setFileEducationsUpload(file);
  };

  const handleClickDownload = (item: LevelModel) => {
    downloadFile(item?.fileTemp, true);
  };

  return (
    <>
      {openModalUpdate && (
        <ModalUpdateLevel
          open={openModalUpdate}
          onClose={handleCloseModalUpdate}
          setValuesUploads={setValuesUploads}
          valueUploads={valueUploads}
          itemEducation={itemEducation}
          setEducationsDetail={setEducationsDetail}
          indexEducation={indexEducation}
          fileEducationsUpload={fileEducationsUpload}
          setFileEducationsUpload={setFileEducationsUpload}
        />
      )}
      {openModalDelete && (
        <ModalDelete open={openModalDelete} onClose={handleCloseModal} handleDeleteItem={handleDeleteItem} />
      )}
      <Box>
        <BoxContainer>
          <Typography sx={{ fontSize: "1.2rem", fontWeight: "500", marginBottom: "0.6rem" }}>
            Thêm mới trình độ
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={true}
            // enableReinitialize={true}
          >
            {(formik) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.name}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Tên chứng chỉ/ bằng cấp"
                      notched={true}
                      variant={"input"}
                      id={"name"}
                      isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="issuePlace"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.issuePlace}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Tổ chức ghi nhận"
                      notched={true}
                      variant={"input"}
                      id={"issuePlace"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="rank"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.rank}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Xếp loại"
                      notched={true}
                      variant={"input"}
                      id={"rank"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      name="issueYear"
                      onBlur={formik.handleBlur}
                      value={formik.values.issueYear}
                      notched={true}
                      variant={"date"}
                      id={"issueYear"}
                      label="Năm cấp"
                      placeholderText={"YYYY"}
                      dateFormat="yyyy"
                      showYearPicker
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Stack direction="row" alignItems="center" spacing={spacing_size}>
                      <Typography sx={{ fontSize: "1rem", fontWeight: "600", color: "#323E5D" }}>
                        Đính kèm{" "}
                        {/* <Box component="span" sx={{ color: "red" }}>
                          *
                        </Box> */}
                      </Typography>
                      <Button
                        variant="contained"
                        color="cancel"
                        // sx={{ display: "inline-block" }}
                        onClick={() => fileRef.current.click()}
                        disabled={file?.length < 1 ? false : true}
                        startIcon={<ChooseFile />}
                      >
                        Chọn file
                      </Button>
                    </Stack>
                    <Field
                      id="file-input"
                      name="fileTemp"
                      // accept="application/msword, application/vnd.ms-excel, application/pdf"
                      accept=".xlsx,.xls,.doc,.docx,.pdf"
                      type="file"
                      innerRef={fileRef}
                      style={{ display: "none" }}
                      value={""}
                      onChange={changeUploadFile(formik)}
                    />
                    {file.map((item, index) => {
                      const newItem = subStringFileName(item);
                      return (
                        <ViewFile
                          key={index}
                          item={newItem}
                          handleRemove={() => {
                            setFile(file.filter((file) => item !== file));
                            formik.setFieldValue("fileTemp", "");
                          }}
                        />
                      );
                    })}
                    <ErrorMessage name="fileTemp" component={TextError} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FieldContainer
                      fullWidth
                      name="note"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.note}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Ghi chú"
                      notched={true}
                      variant={"input"}
                      id={"note"}
                    />
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={spacing_size}
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      <Button startIcon={<BrowserIcon />} variant="contained" color="primary" type="submit">
                        Lưu thông tin trình độ
                      </Button>
                      <Button
                        ref={btnSubmitEducationRef}
                        variant="contained"
                        color="primary"
                        type="button"
                        sx={{ display: "none" }}
                        onClick={() => handleClickSave(formik)}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </BoxContainer>
      </Box>
      {educationsDetail && educationsDetail?.length > 0 && (
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: header_table_color }}>
                  <TableCell sx={{ fontWeight: 600 }}>Tên chứng chỉ/ bằng cấp</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Tổ chức ghi nhận</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Xếp loại</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Năm cấp</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Đính kèm</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Ghi chú</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    Hành động
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {educationsDetail?.map((item: LevelModel, index: number) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                    }}
                    key={index}
                  >
                    <TableCell>{item?.name}</TableCell>
                    <TableCell>{item?.issuePlace}</TableCell>
                    <TableCell>{item?.rank}</TableCell>
                    <TableCell>{item?.issueYear?.toString()}</TableCell>
                    <TableCell sx={{ color: "#0084FF" }}>
                      <Typography
                        sx={{ color: "#0084FF", cursor: "pointer" }}
                        onClick={() => handleClickDownload(item)}
                      >
                        {subStringFunc(item?.fileName)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ReadMore text={item?.note} />
                    </TableCell>
                    <TableCell align="center">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={spacing_size}
                        sx={{
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          type="button"
                          onClick={(e) => handleOpenModalDelete(index, e)}
                        >
                          Xoá
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          type="button"
                          onClick={() => handleOpenModalUpdate(item, index)}
                        >
                          Cập nhật
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <PaginationPage total={10} page={1} onChange={handleChangePage} /> */}
        </Box>
      )}

      {/* not found result */}
      {/* {data.length === 0 && <NotFoundFilterView />} */}
      {/* </BoxContainer> */}
    </>
  );
};

export default Level;
