import React, { FC, useState } from "react";
import {
  GenderPersonnels,
  IDENTITY_DOCUMENTS_ENUM,
  IdentityDocumentsPersonnel,
  MaritalStatusPersonnels,
  POSITION_ENUM,
  PositionPersonnel,
  TYPE_PERSONNEL_ENUM,
} from "../../../../enum/personnel.enum";
import { Accordion, AccordionSummary, AccordionDetails, Grid, Stack, Box, Button } from "@mui/material";
import { FormikHelpers, FormikComputedProps, FormikProps, Formik, isEmptyArray, Form } from "formik";
import { Location, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OptionSelectUser from "../../../../components/common/OptionSelectUser";
import FieldContainer from "../../../../components/form/FieldContainer";
import { UPLOAD_DATE_FORMAT } from "../../../../constants/date-time.constant";
import { FORM_MSG } from "../../../../constants/msg/msg.constant";
import { validationOperatingPartsSchema } from "../../common/constants/validate-form.constants";
import {
  SelectFieldCommonPersonnelModel,
  NationalityModel,
  HrDepartmentModel,
  HrPositionModel,
  GeneralInformationModel,
  EmployeeDetailModel,
  PersonnelModel,
} from "../../../../model/personnel.model";
import { spacing_size } from "../../../../themes/size";
import TitleFormGeneral from "../../common/component/TitleFormGeneral";
import {
  isEnableFieldIdentityCard,
  isEnableFieldPassport,
  isEnableFieldPartyMember,
  isEnableFieldUnionMember,
  handleKeyPressPreventWithPattern,
  handleCreateHr,
  loadActiveEmployeeWithDebounce,
  handleUpdateHr,
} from "../../common/util/personnel.util";
import ModalCreateDepartment from "../../modal/ModalCreateDepartment";
import ModalCreatePosition from "../../modal/ModalCreatePosition";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { formatDateTime } from "../../../../utils/data-time.util";
import { isEmptyArr } from "../../../../utils/array.util";
import {
  PHONE_PATTERN,
  IDENTITY_CARD_PATTERN,
  PASSPORT_PATTERN,
  CODE_EMPLOYEE_REGEX,
} from "../../../../constants/regex.constant";

interface GeneralInformationOperatingPartsProps {
  valueUploads: PersonnelModel | null;
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  valueForm: GeneralInformationModel | null;
  setValueForm: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  allActiveDepartment: HrDepartmentModel[];
  allActivePosition: HrPositionModel[];
  btnSubmitInformationRef: React.RefObject<HTMLButtonElement>;
  btnInformationStaffRefNext: React.RefObject<HTMLButtonElement>;
  fileContractsUpload: File[];
  fileEducationsUpload: File[];
  fileContractsAddendumUpload: File[];
  fileDocumentsUpload: File[];
  allNationality: NationalityModel[];
  location: Location;
  newFileContractsUpload: File[];
  newFileContractsAddendumUpload: File[];
}

interface InitialValuesForm {
  type?: string;
  fullName?: string | undefined | null;
  code: string | undefined | null;
  dob?: string | undefined | null | any;
  gender?: string | object | any;
  phoneNumber?: number | undefined | null | any;
  workingAddress?: string | undefined | null;
  maritalStatus?: string | undefined | null | any;
  nationalityId?: string | undefined | null | any;
  ethnic?: string | undefined | null;
  religion?: string | undefined | null;
  nativePlace?: string | undefined | null;
  permanentAddress?: string | undefined | null;
  address?: string | undefined | null;
  email?: string | undefined | null;
  positionId?: string | any;
  departmentId?: string | any;
  managerId?: number | undefined | null | any;
  startDate?: string | undefined | null | Date | any;
  taxCode?: string | undefined | null;
  // salary?: number | undefined | null | any;
  identityCard?: string | undefined | null;
  idcIssueDate?: string | undefined | null | Date | any;
  idcIssuePlace?: string | undefined | null;
  passport?: string | undefined | null;
  passportIssueDate?: string | undefined | null | Date | any;
  passportIssuePlace?: string | undefined | null;
  partyPosition?: string | undefined | null;
  partyJoinDate?: string | undefined | null | Date | any;
  partyJoinPlace?: string | undefined | null;
  unionPosition?: string | undefined | null;
  unionJoinDate?: string | undefined | null | Date | any;
  unionJoinPlace?: string | undefined | null;
  rewards?: string | undefined | null;
  disciplines?: string | undefined | null;
  relationship?: string | undefined | null;
  workPermitNumber?: string | undefined | null;
  workPermitFromDate?: string | Date | null | undefined | any;
  workPermitToDate?: string | Date | null | undefined | any;
  temporaryResidenceCard?: string | undefined | null;
  trcExpiredDate?: string | Date | null | any;
  department?: object | any;
  position?: object | any;
  identity?: IDENTITY_DOCUMENTS_ENUM[] | any;
  selectPosition?: POSITION_ENUM[] | any;
  fullNameContactInfo?: string | undefined | null;
  phoneNumberContactInfo?: string | undefined | null;
  relativesContactInfo?: string | undefined | null;
}

const GeneralInformationOperatingParts: FC<GeneralInformationOperatingPartsProps> = (props) => {
  const {
    valueUploads,
    setValuesUploads,
    btnSubmitInformationRef,
    valueForm,
    setValueForm,
    allActiveDepartment,
    allActivePosition,
    btnInformationStaffRefNext,
    fileContractsUpload,
    fileEducationsUpload,
    fileContractsAddendumUpload,
    fileDocumentsUpload,
    allNationality,
    location,
    newFileContractsUpload,
    newFileContractsAddendumUpload,
  } = props;

  const navigate = useNavigate();

  const initialValues: InitialValuesForm = {
    type: TYPE_PERSONNEL_ENUM.OPERATING_PARTS,
    fullName: valueForm?.fullName || "",
    code: valueForm?.code || "",
    dob: valueForm?.dob || null,
    gender: valueForm?.gender || "",
    phoneNumber: valueForm?.phoneNumber || "",
    workingAddress: valueForm?.workingAddress || "",
    maritalStatus: valueForm?.maritalStatus || "",
    nationalityId: valueForm?.nationalityId || "",
    ethnic: valueForm?.ethnic || "",
    religion: valueForm?.religion || "",
    nativePlace: valueForm?.nativePlace || "",
    permanentAddress: valueForm?.permanentAddress || "",
    address: valueForm?.address || "",
    email: valueForm?.email || "",
    positionId: valueForm?.positionId || "",
    departmentId: valueForm?.departmentId || "",
    managerId: valueForm?.managerId || "",
    startDate: valueForm?.startDate || null,
    taxCode: valueForm?.taxCode || "",
    identityCard: valueForm?.identityCard || "",
    idcIssueDate: valueForm?.idcIssueDate || "",
    idcIssuePlace: valueForm?.idcIssuePlace || "",
    passport: valueForm?.passport || "",
    passportIssueDate: valueForm?.passportIssueDate || null,
    passportIssuePlace: valueForm?.passportIssuePlace || "",
    partyPosition: valueForm?.partyPosition || "",
    partyJoinDate: valueForm?.partyJoinDate || null,
    partyJoinPlace: valueForm?.partyJoinPlace || "",
    unionPosition: valueForm?.unionPosition || "",
    unionJoinDate: valueForm?.unionJoinDate || null,
    unionJoinPlace: valueForm?.unionJoinPlace || "",
    rewards: valueForm?.rewards || "",
    disciplines: valueForm?.disciplines || "",
    relationship: valueForm?.relationship || "",
    workPermitNumber: valueForm?.workPermitNumber || "",
    workPermitFromDate: valueForm?.workPermitFromDate || null,
    workPermitToDate: valueForm?.workPermitToDate || null,
    temporaryResidenceCard: valueForm?.temporaryResidenceCard || "",
    trcExpiredDate: valueForm?.trcExpiredDate || null,
    identity: valueForm?.identity || [],
    selectPosition: valueForm?.selectPosition || [],
    fullNameContactInfo: valueForm?.fullNameContactInfo || "",
    phoneNumberContactInfo: valueForm?.phoneNumberContactInfo || "",
    relativesContactInfo: valueForm?.relativesContactInfo || "",
  };

  const currentDate = new Date();
  const [openModalCreateDepartment, setOpenModalCreateDepartment] = useState<boolean>(false);
  const [openModalCreatePosition, setOpenModalCreatePosition] = useState<boolean>(false);

  const handleOpenModalCreateDepartment = () => {
    setOpenModalCreateDepartment(true);
  };

  const handleCloseModalCreateDepartment = () => {
    setOpenModalCreateDepartment(false);
  };

  const handleOpenModalCreatePosition = () => {
    setOpenModalCreatePosition(true);
  };

  const handleCloseModalCreatePosition = () => {
    setOpenModalCreatePosition(false);
  };

  const handleChangeFullName =
    (formik: FormikHelpers<InitialValuesForm>) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = e.target.value?.charAt(0).toUpperCase() + e.target.value.slice(1);
      formik.setFieldValue("fullName", newValues);
    };

  const handleChangeGender =
    (formik: FormikHelpers<InitialValuesForm>) => (e: SelectFieldCommonPersonnelModel | null) => {
      formik.setFieldValue("gender", e);
    };

  const handleChangeMaritalStatus =
    (formik: FormikHelpers<InitialValuesForm>) => (e: SelectFieldCommonPersonnelModel | null) => {
      formik.setFieldValue("maritalStatus", e);
    };

  const handleChangeDirectManagement =
    (formik: FormikHelpers<InitialValuesForm>) => (e: SelectFieldCommonPersonnelModel | null) => {
      formik.setFieldValue("managerId", e);
    };

  const handleChangeIdentity =
    (formik: FormikHelpers<InitialValuesForm> | any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (formik?.values?.identity?.includes(e.target.value)) {
        const nextValue = formik?.values?.identity?.filter((value: any) => value !== e.target.value);
        formik.setFieldValue("identity", nextValue);
        if (nextValue?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
          // formik.setFieldValue("passport", "");
          // formik.setFieldValue("passportIssueDate", "");
          // formik.setFieldValue("passportIssuePlace", "");
        } else if (nextValue?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
          // formik.setFieldValue("identityCard", "");
          // formik.setFieldValue("idcIssueDate", "");
          // formik.setFieldValue("idcIssuePlace", "");
        }
      } else {
        const nextValue = formik?.values?.identity?.concat(e.target.value);
        formik.setFieldValue("identity", nextValue);
      }
    };

  const handleChangePosition =
    (formik: FormikHelpers<InitialValuesForm> | any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (formik?.values?.selectPosition?.includes(e.target.value)) {
        const nextValue = formik?.values?.selectPosition?.filter((value: any) => value !== e.target.value);
        formik.setFieldValue("selectPosition", nextValue);
        if (nextValue?.includes(POSITION_ENUM.PARTY_MEMBER)) {
          // formik.setFieldValue("unionPosition", "");
          // formik.setFieldValue("unionJoinDate", "");
          // formik.setFieldValue("unionJoinPlace", "");
        } else if (nextValue?.includes(POSITION_ENUM.UNION_MEMBER)) {
          // formik.setFieldValue("partyPosition", "");
          // formik.setFieldValue("partyJoinDate", "");
          // formik.setFieldValue("partyJoinPlace", "");
        }
      } else {
        const nextValue = formik?.values?.selectPosition?.concat(e.target.value);
        formik.setFieldValue("selectPosition", nextValue);
      }
    };

  const handleChangeNationality = (formik: FormikHelpers<InitialValuesForm>) => (e: NationalityModel) => {
    formik.setFieldValue("nationalityId", e);
  };

  const handleChangeDepartment = (formik: FormikHelpers<InitialValuesForm>) => (e: HrDepartmentModel) => {
    formik.setFieldValue("departmentId", e);
  };

  const handleChangePositionId = (formik: FormikHelpers<InitialValuesForm>) => (e: HrPositionModel) => {
    formik.setFieldValue("positionId", e);
  };

  const buildBody = (values: InitialValuesForm): GeneralInformationModel => {
    const formatDob = formatDateTime(values.dob, UPLOAD_DATE_FORMAT);
    const formatStartDate = formatDateTime(values?.startDate, UPLOAD_DATE_FORMAT);
    const formatIdcIssueDate = formatDateTime(values?.idcIssueDate, UPLOAD_DATE_FORMAT);
    const formatPassportIssueDate = formatDateTime(values?.passportIssueDate, UPLOAD_DATE_FORMAT);
    const formatPartyJoinDate = formatDateTime(values?.partyJoinDate, UPLOAD_DATE_FORMAT);
    const formatUnionJoinDate = formatDateTime(values?.unionJoinDate, UPLOAD_DATE_FORMAT);

    const newValues = {
      ...values,
      dob: formatDob,
      gender: values?.gender ? values?.gender?.code : null,
      maritalStatus: values?.maritalStatus ? values?.maritalStatus?.code : null,
      nationalityId: values?.nationalityId ? values?.nationalityId?.id : null,
      startDate: formatStartDate,
      identityCard: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD) ? values?.identityCard : "",
      idcIssueDate: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD) ? formatIdcIssueDate : "",
      idcIssuePlace: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD) ? values?.idcIssuePlace : "",
      passport: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT) ? values?.passport : "",
      passportIssueDate: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT) ? formatPassportIssueDate : "",
      passportIssuePlace: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)
        ? values?.passportIssuePlace
        : "",
      partyPosition: values?.selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER) ? values?.partyPosition : "",
      partyJoinDate: values?.selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER) ? formatPartyJoinDate : "",
      partyJoinPlace: values?.selectPosition?.includes(POSITION_ENUM.PARTY_MEMBER) ? values?.partyJoinPlace : "",
      unionPosition: values?.selectPosition?.includes(POSITION_ENUM.UNION_MEMBER) ? values?.unionPosition : "",
      unionJoinDate: values?.selectPosition?.includes(POSITION_ENUM.UNION_MEMBER) ? formatUnionJoinDate : "",
      unionJoinPlace: values?.selectPosition?.includes(POSITION_ENUM.UNION_MEMBER) ? values?.unionJoinPlace : "",
      positionId: values?.positionId ? values?.positionId?.id : null,
      departmentId: values?.departmentId ? values?.departmentId?.id : null,
      managerId: values?.managerId ? values?.managerId?.id : null,
      employeeContactInfo: {
        fullName: values?.fullNameContactInfo,
        phoneNumber: values?.phoneNumberContactInfo,
        relationship: values?.relativesContactInfo,
      },
    };
    return newValues;
  };

  const onSubmitSearch = (values: InitialValuesForm, formik: FormikComputedProps<InitialValuesForm> | any) => {
    const body = buildBody(values);
    const newBody = {
      ...valueUploads,
      ...body,
    };

    handleUpdateHr(
      newBody,
      fileContractsUpload,
      fileEducationsUpload,
      fileContractsAddendumUpload,
      fileDocumentsUpload,
      newFileContractsUpload,
      newFileContractsAddendumUpload,
      navigate,
      location
    );
  };

  const handleClickSave = (formik: FormikProps<InitialValuesForm>) => {
    const values = formik?.values;
    const body = buildBody(values);
    setValuesUploads({ ...valueUploads, ...body });
    setValueForm(values);
  };

  return (
    <>
      {openModalCreateDepartment && (
        <ModalCreateDepartment open={openModalCreateDepartment} onClose={handleCloseModalCreateDepartment} />
      )}

      {openModalCreatePosition && (
        <ModalCreatePosition open={openModalCreatePosition} onClose={handleCloseModalCreatePosition} />
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={validationOperatingPartsSchema}
        onSubmit={onSubmitSearch}
        validateOnBlur={false}
      >
        {(formik) => (
          <Form noValidate autoComplete="off">
            {/* Thông tin cá nhân */}
            <Accordion
              defaultExpanded={true}
              sx={{
                borderLeft: "5px solid #2E3D5B",
                p: 2,
                borderRadius: "4px",
                bgcolor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vff-panel-personal-information-content"
                id="vff-panel-personal-information-header"
              >
                <TitleFormGeneral title={"Thông tin cá nhân"} />
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={12 / 5}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="fullName"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeFullName(formik)}
                      value={formik.values.fullName}
                      placeholder="Nhập thông tin"
                      label="Họ và tên"
                      variant={"input"}
                      id={"fullName"}
                      maxRows={12}
                      isRequired={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={12 / 5}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 15 }}
                      name="dob"
                      type="text"
                      onBlur={formik.handleBlur}
                      value={formik.values.dob}
                      placeholderText="dd/mm/yyyy"
                      label="Ngày sinh"
                      variant={"date"}
                      id={"dob"}
                      maxRows={12}
                      isRequired={true}
                      maxDate={currentDate}
                      // peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                      // dropdownMode="select"
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={12 / 5}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="gender"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeGender(formik)}
                      value={formik.values.gender}
                      placeholder="Nhập thông tin"
                      label="Giới tính"
                      variant={"react-select"}
                      id={"gender"}
                      getOptionLabel={(item: SelectFieldCommonPersonnelModel) => item?.description}
                      getOptionValue={(item: SelectFieldCommonPersonnelModel) => item?.code}
                      options={GenderPersonnels}
                      isRequired={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={12 / 5}>
                    <FieldContainer
                      fullWidth
                      name="phoneNumber"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                      placeholder="Nhập thông tin"
                      label="Số điện thoại"
                      variant={"input"}
                      id={"phoneNumber"}
                      maxRows={12}
                      isRequired={true}
                      inputProps={{ maxLength: 20 }}
                      onKeyPress={handleKeyPressPreventWithPattern(PHONE_PATTERN)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={12 / 5}>
                    <FieldContainer
                      fullWidth
                      name="code"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.code}
                      placeholder="Nhập thông tin"
                      label="Mã cá nhân"
                      variant={"input"}
                      id={"code"}
                      maxRows={12}
                      isRequired={true}
                      inputProps={{ maxLength: 6 }}
                      onKeyPress={handleKeyPressPreventWithPattern(CODE_EMPLOYEE_REGEX)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={12 / 5}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="email"
                      type="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      placeholder="Nhập thông tin"
                      label="Email công việc"
                      disabled
                      variant={"input"}
                      id={"email"}
                      maxRows={12}
                      isRequired={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={12 / 5}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="maritalStatus"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeMaritalStatus(formik)}
                      value={formik.values.maritalStatus}
                      placeholder="Nhập thông tin"
                      label="Tình trạng hôn nhân"
                      variant={"react-select"}
                      id={"maritalStatus"}
                      options={MaritalStatusPersonnels}
                      getOptionLabel={(item: SelectFieldCommonPersonnelModel) => item?.description}
                      getOptionValue={(item: SelectFieldCommonPersonnelModel) => item?.code}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={12 / 5}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="nationalityId"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeNationality(formik)}
                      value={formik.values.nationalityId}
                      placeholder="Nhập thông tin"
                      label="Quốc tịch"
                      variant={"react-select"}
                      id={"nationalityId"}
                      maxRows={12}
                      options={allNationality}
                      getOptionLabel={(item: NationalityModel) => item?.name}
                      getOptionValue={(item: NationalityModel) => item?.name}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={12 / 5}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="ethnic"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.ethnic}
                      placeholder="Nhập thông tin"
                      label="Dân tộc"
                      variant={"input"}
                      id={"ethnic"}
                      maxRows={12}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={12 / 5}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="religion"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.religion}
                      placeholder="Nhập thông tin"
                      label="Tôn giáo"
                      variant={"input"}
                      id={"religion"}
                      maxRows={12}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 13 }}
                      name="taxCode"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.taxCode}
                      placeholder="Nhập thông tin"
                      label="Mã số thuế cá nhân"
                      variant={"input"}
                      id={"taxCode"}
                      maxRows={12}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="nativePlace"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.nativePlace}
                      placeholder="Nhập thông tin"
                      label="Nguyên quán"
                      variant={"input"}
                      id={"nativePlace"}
                      maxRows={12}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="permanentAddress"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.permanentAddress}
                      placeholder="Nhập thông tin"
                      label="Địa chỉ thường trú"
                      variant={"input"}
                      id={"permanentAddress"}
                      maxRows={12}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="address"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.address}
                      placeholder="Nhập thông tin"
                      label="Nơi ở hiện tại"
                      variant={"input"}
                      id={"address"}
                      maxRows={12}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Thông tin cơ quan */}
            <Accordion
              defaultExpanded={true}
              sx={{
                borderLeft: "5px solid #2E3D5B",
                p: 2,
                borderRadius: "4px",
                bgcolor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vff-panel-office-information-content"
                id="vff-panel-office-information-header"
              >
                <TitleFormGeneral title={"Thông tin cơ quan"} />
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="workingAddress"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.workingAddress}
                      placeholder="Nhập thông tin"
                      label="Nơi làm việc"
                      variant={"input"}
                      id={"workingAddress"}
                      maxRows={12}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Stack
                      direction="row"
                      spacing={spacing_size}
                      // justifyContent="end" alignItems="end"
                    >
                      <Grid item xs={12} md={11} lg={11}>
                        <FieldContainer
                          fullWidth
                          // inputProps={{ maxLength: 255 }}
                          name="departmentId"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={handleChangeDepartment(formik)}
                          value={formik.values.departmentId}
                          placeholder="Nhập/chọn thông tin"
                          label="Bộ phận công tác"
                          variant={"react-select"}
                          id={"departmentId"}
                          // maxRows={12}
                          isRequired={true}
                          options={allActiveDepartment}
                          getOptionLabel={(option: HrDepartmentModel) => `${option.code} - ${option.name}`}
                          getOptionValue={(option: HrDepartmentModel) => option.code}
                        />
                      </Grid>
                      <Grid item xs={12} md={1} lg={1}>
                        <Box
                          sx={{ display: "inline-block", cursor: "pointer", marginTop: "1.6rem" }}
                          onClick={handleOpenModalCreateDepartment}
                        >
                          <AddCircleOutlineIcon sx={{ fontSize: "2rem", color: "#4ACB1D" }} />
                        </Box>
                      </Grid>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Stack
                      direction="row"
                      spacing={spacing_size}
                      // justifyContent="end" alignItems="end"
                    >
                      <Grid item xs={12} md={11} lg={11}>
                        <FieldContainer
                          fullWidth
                          // inputProps={{ maxLength: 255 }}
                          name="positionId"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={handleChangePositionId(formik)}
                          value={formik.values.positionId}
                          placeholder="Nhập/chọn thông tin"
                          label="Chức vụ"
                          variant={"react-select"}
                          id={"positionId"}
                          // maxRows={12}
                          isRequired={true}
                          options={allActivePosition}
                          getOptionLabel={(option: HrPositionModel) => option.name}
                          getOptionValue={(option: HrPositionModel) => option.id}
                        />
                      </Grid>
                      <Grid item xs={12} md={1} lg={1}>
                        <Box
                          sx={{ display: "inline-block", cursor: "pointer", marginTop: "1.6rem" }}
                          onClick={handleOpenModalCreatePosition}
                        >
                          <AddCircleOutlineIcon sx={{ fontSize: "2rem", color: "#4ACB1D" }} />
                        </Box>
                      </Grid>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <FieldContainer
                      name="managerId"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeDirectManagement(formik)}
                      value={formik.values.managerId}
                      placeholder="Nhập thông tin"
                      label="Quản lý trực tiếp"
                      variant={"async-react-select"}
                      id={"managerId"}
                      maxRows={12}
                      // options={directManagementDetail}
                      loadOptions={loadActiveEmployeeWithDebounce}
                      isClearable
                      getOptionLabel={(option: EmployeeDetailModel) => option.email}
                      getOptionValue={(option: EmployeeDetailModel) => option.id}
                      components={{ Option: OptionSelectUser }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="startDate"
                      type="text"
                      onBlur={formik.handleBlur}
                      value={formik.values.startDate}
                      placeholderText="dd/mm/yyyy"
                      label="Thời gian bắt đầu công tác"
                      variant={"date"}
                      id={"startDate"}
                      maxRows={12}
                      maxDate={currentDate}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Loại giấy tờ tuỳ thân */}
            <Accordion
              defaultExpanded={true}
              sx={{
                borderLeft: "5px solid #2E3D5B",
                p: 2,
                borderRadius: "4px",
                bgcolor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vff-panel-document-personal-content"
                id="vff-panel-document-personal-header"
              >
                <TitleFormGeneral title={"Loại giấy tờ tuỳ thân"} />
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={spacing_size}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ margin: "1rem 0" }}
                >
                  <Grid container spacing={spacing_size}>
                    <Grid item xs={12} md={12} lg={12} sx={{ marginTop: "0.3rem" }}>
                      <FieldContainer
                        name="identity"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeIdentity(formik)}
                        value={formik.values.identity}
                        placeholder=""
                        label=""
                        variant={"checkbox"}
                        id={"identity"}
                        options={IdentityDocumentsPersonnel}
                        labelOption="description"
                        valueOption="code"
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Grid container spacing={spacing_size}>
                  {isEnableFieldIdentityCard(formik.values.identity) && (
                    <>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 12 }}
                          name="identityCard"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.identityCard}
                          placeholder="Nhập thông tin"
                          label="Số CMND/CCCD"
                          variant={"input"}
                          id={"identityCard"}
                          maxRows={12}
                          isRequired={true}
                          onKeyPress={handleKeyPressPreventWithPattern(IDENTITY_CARD_PATTERN)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="idcIssueDate"
                          type="text"
                          onBlur={formik.handleBlur}
                          value={formik.values.idcIssueDate}
                          placeholderText={FORM_MSG.dateFormatPlaceholder}
                          label="Ngày cấp"
                          variant={"date"}
                          id={"idcIssueDate"}
                          maxRows={12}
                          maxDate={currentDate}
                          isRequired={true}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="idcIssuePlace"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.idcIssuePlace}
                          placeholder="Nhập thông tin"
                          label="Nơi cấp"
                          variant={"input"}
                          id={"idcIssuePlace"}
                          maxRows={12}
                          isRequired={true}
                        />
                      </Grid>
                    </>
                  )}
                  {isEnableFieldPassport(formik.values.identity) && (
                    <>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ minLength: 8, maxLength: 20 }}
                          name="passport"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.passport}
                          placeholder="Nhập thông tin"
                          label="Số hộ chiếu"
                          variant={"input"}
                          id={"passport"}
                          maxRows={12}
                          pattern={PASSPORT_PATTERN}
                          onKeyPress={handleKeyPressPreventWithPattern(PASSPORT_PATTERN)}
                          isRequired={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="passportIssueDate"
                          type="text"
                          onBlur={formik.handleBlur}
                          value={formik.values.passportIssueDate}
                          label="Ngày cấp"
                          placeholderText={FORM_MSG.dateFormatPlaceholder}
                          variant={"date"}
                          id={"passportIssueDate"}
                          maxRows={12}
                          maxDate={currentDate}
                          isRequired={true}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="passportIssuePlace"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.passportIssuePlace}
                          placeholder="Nhập thông tin"
                          label="Nơi cấp"
                          variant={"input"}
                          id={"passportIssuePlace"}
                          maxRows={12}
                          isRequired={true}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Lý lịch đảng viên / đoàn viên */}
            <Accordion
              defaultExpanded={true}
              sx={{
                borderLeft: "5px solid #2E3D5B",
                p: 2,
                borderRadius: "4px",
                bgcolor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vff-panel-party-content"
                id="vff-panel-party-header"
              >
                <TitleFormGeneral title={"Lý lịch Đảng viên/Đoàn viên"} />
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={spacing_size}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ margin: "1rem 0" }}
                >
                  <Grid container spacing={spacing_size}>
                    <Grid item xs={12} md={12} lg={12} sx={{ marginTop: "0.3rem" }}>
                      <FieldContainer
                        name="selectPosition"
                        onBlur={formik.handleBlur}
                        onChange={handleChangePosition(formik)}
                        value={formik.values.selectPosition}
                        label=""
                        variant={"checkbox"}
                        id={"selectPosition"}
                        options={PositionPersonnel}
                        labelOption="description"
                        valueOption="code"
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Grid container spacing={spacing_size}>
                  {isEnableFieldPartyMember(formik.values.selectPosition) && (
                    <>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="partyPosition"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.partyPosition}
                          placeholder="Nhập thông tin"
                          label="Chức vụ Đảng viên"
                          variant={"input"}
                          id={"partyPosition"}
                          maxRows={12}
                          isRequired={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="partyJoinDate"
                          type="text"
                          onBlur={formik.handleBlur}
                          value={formik.values.partyJoinDate}
                          placeholderText={FORM_MSG.dateFormatPlaceholder}
                          label="Ngày kết nạp"
                          variant={"date"}
                          id={"partyJoinDate"}
                          maxRows={12}
                          maxDate={currentDate}
                          isRequired={true}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="partyJoinPlace"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.partyJoinPlace}
                          placeholder="Nhập thông tin"
                          label="Nơi kết nạp"
                          variant={"input"}
                          id={"partyJoinPlace"}
                          maxRows={12}
                          isRequired={true}
                        />
                      </Grid>
                    </>
                  )}
                  {isEnableFieldUnionMember(formik.values.selectPosition) && (
                    <>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="unionPosition"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.unionPosition}
                          placeholder="Nhập thông tin"
                          label="Chức vụ Đoàn viên"
                          variant={"input"}
                          id={"unionPosition"}
                          maxRows={12}
                          isRequired={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="unionJoinDate"
                          type="text"
                          onBlur={formik.handleBlur}
                          value={formik.values.unionJoinDate}
                          placeholderText={FORM_MSG.dateFormatPlaceholder}
                          label="Ngày kết nạp"
                          variant={"date"}
                          id={"unionJoinDate"}
                          maxRows={12}
                          maxDate={currentDate}
                          isRequired={true}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="unionJoinPlace"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.unionJoinPlace}
                          label="Nơi kết nạp"
                          variant={"input"}
                          id={"unionJoinPlace"}
                          maxRows={12}
                          placeholder="Nhập thông tin"
                          isRequired={true}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Khen thưởng kỷ luật */}
            <Accordion
              defaultExpanded={true}
              sx={{
                borderLeft: "5px solid #2E3D5B",
                p: 2,
                borderRadius: "4px",
                bgcolor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vff-panel-reward-discipline-content"
                id="vff-panel-reward-discipline-header"
              >
                <TitleFormGeneral title={"Khen thưởng kỷ luật"} />
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={12} lg={12}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="rewards"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.rewards}
                      placeholder="Nhập thông tin"
                      label="Khen thưởng"
                      variant={"input"}
                      id={"rewards"}
                      maxRows={12}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="disciplines"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.disciplines}
                      placeholder="Nhập thông tin"
                      label="Kỷ luật"
                      variant={"input"}
                      id={"disciplines"}
                      maxRows={12}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Thông tin liên hệ */}
            <Accordion
              defaultExpanded={true}
              sx={{
                borderLeft: "5px solid #2E3D5B",
                p: 2,
                borderRadius: "4px",
                bgcolor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vff-panel-contact-content"
                id="vff-panel-contact-header"
              >
                <TitleFormGeneral title={"Thông tin liên hệ của người thân"} />
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="fullNameContactInfo"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.fullNameContactInfo}
                      placeholder="Nhập thông tin"
                      label="Họ và tên"
                      variant={"input"}
                      id={"fullNameContactInfo"}
                      maxRows={12}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FieldContainer
                      fullWidth
                      name="phoneNumberContactInfo"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumberContactInfo}
                      placeholder="Nhập thông tin"
                      label="Số điện thoại"
                      variant={"input"}
                      id={"phoneNumberContactInfo"}
                      maxRows={12}
                      inputProps={{ maxLength: 20 }}
                      onKeyPress={handleKeyPressPreventWithPattern(PHONE_PATTERN)}
                      // onKeyDown={(e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="relativesContactInfo"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.relativesContactInfo}
                      placeholder="Nhập thông tin"
                      label="Mối quan hệ với CBNV"
                      variant={"input"}
                      id={"relativesContactInfo"}
                      maxRows={12}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* action */}
            <Button type="submit" ref={btnSubmitInformationRef} sx={{ visibility: "hidden", height: 0 }}>
              Lưu
            </Button>

            <Button
              type="button"
              ref={btnInformationStaffRefNext}
              onClick={() => handleClickSave(formik)}
              sx={{ visibility: "hidden", height: 0 }}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GeneralInformationOperatingParts;
