import { EnumCommon, AllItem } from "./common.enum";

export enum CATEGORY_DOC_IN_ENUM {
  DOMESTIC = "DOMESTIC",
  INTERNATIONAL = "INTERNATIONAL",
}

export enum SEN_CHANNEL_DOC_IN_ENUM {
  ORIGINAL = "ORIGINAL",
  EMAIL = "EMAIL",
}

export const CategoryDocIns: EnumCommon<CATEGORY_DOC_IN_ENUM>[] = [
  { code: CATEGORY_DOC_IN_ENUM.DOMESTIC, description: "Trong nước" },
  { code: CATEGORY_DOC_IN_ENUM.INTERNATIONAL, description: "Quốc tế" },
];

export const senChannelDocIn: EnumCommon<SEN_CHANNEL_DOC_IN_ENUM>[] = [
  { code: SEN_CHANNEL_DOC_IN_ENUM.ORIGINAL, description: "Bản gốc" },
  { code: SEN_CHANNEL_DOC_IN_ENUM.EMAIL, description: "Email" },
];

export const CategoryDocInsWithAll: EnumCommon<CATEGORY_DOC_IN_ENUM>[] = [
  // for format
  AllItem,
  ...CategoryDocIns,
];

export const senChannelDocInWithAll: EnumCommon<SEN_CHANNEL_DOC_IN_ENUM>[] = [
  // for format
  AllItem,
  ...senChannelDocIn,
];

export enum DEADLINE_DOC_IN_TYPE_ENUM {
  DEADLINE = "DEADLINE",
  DEADLINE2 = "DEADLINE2",
  PROCESSING_DEADLINE = "PROCESSING_DEADLINE"
}

export enum STATUS_DOC_IN_ENUM {
  UNAPPROVED = "UNAPPROVED",
  UNASSIGNED = "UNASSIGNED",
  PENDING = "PENDING",
  RETURNED = "RETURNED",
  PROCESSED = "PROCESSED",
  CANCELED = "CANCELED",
  REPLY = "REPLY",
}

export const StatusDocIns: EnumCommon<STATUS_DOC_IN_ENUM>[] = [
  { code: STATUS_DOC_IN_ENUM.UNAPPROVED, description: "Chờ trình duyệt", textColor: "#FF6F07", bgColor: "none" },
  { code: STATUS_DOC_IN_ENUM.UNASSIGNED, description: "Chờ phân công", textColor: "#FF6F07", bgColor: "none" },
  { code: STATUS_DOC_IN_ENUM.RETURNED, description: "Đã hoàn trả", textColor: "#FF2001", bgColor: "none" },
  { code: STATUS_DOC_IN_ENUM.CANCELED, description: "Đã hủy", textColor: "#FF2001", bgColor: "none" },
  { code: STATUS_DOC_IN_ENUM.PENDING, description: "Chờ xử lý", textColor: "#FF6F07", bgColor: "none" },
  { code: STATUS_DOC_IN_ENUM.PROCESSED, description: "Đã xử lý", textColor: "#4ACB1D", bgColor: "none" },
  { code: STATUS_DOC_IN_ENUM.REPLY, description: "Đã phúc đáp", textColor: "#419CDD", bgColor: "none" },
];

export const StatusDocInsWithAll: EnumCommon<STATUS_DOC_IN_ENUM>[] = [
  // for mat
  AllItem,
  ...StatusDocIns,
];

export enum UPLOAD_CATEGORY_DOC_IN_ENUM {
  DOMESTIC = "DOMESTIC",
  INTERNATIONAL = "INTERNATIONAL",
}

export enum DEADLINE_DOC_IN_ENUM {
  PROCESSED = "PROCESSED",
  OTHER = "OTHER",
}

export const deadlineDocIns: EnumCommon<DEADLINE_DOC_IN_ENUM>[] = [
  { code: DEADLINE_DOC_IN_ENUM.PROCESSED, description: "Đã xử lý" },
  { code: DEADLINE_DOC_IN_ENUM.OTHER, description: "Khác" },
];

export enum DOC_IN_UNASSIGNED_TYPE_ENUM {
  UNASSIGNED = "",
  SIGNED_AND_ASSIGNED = "SIGNED_AND_ASSIGNED",
}

export const docInUnassignedTypeOptions: EnumCommon<DOC_IN_UNASSIGNED_TYPE_ENUM>[] = [
  {
    code: DOC_IN_UNASSIGNED_TYPE_ENUM.UNASSIGNED,
    description: "Chuyển tới Phòng Chủ trì giải quyết để phân công",
  },
  {
    code: DOC_IN_UNASSIGNED_TYPE_ENUM.SIGNED_AND_ASSIGNED,
    description: "Chuyển tới cá nhân trực tiếp để xử lý",
  },
];

export enum DOC_IN_PRIORITY_TYPE_ENUM {
  NORMAL = "NORMAL",
  EMERGENCY = "EMERGENCY",
  URGENT = "URGENT",
  HIGH_PRIORITY = "HIGH_PRIORITY",
}

export const docInPriorityTypeOptions: EnumCommon<DOC_IN_PRIORITY_TYPE_ENUM>[] = [
  {
    code: DOC_IN_PRIORITY_TYPE_ENUM.NORMAL,
    description: "Bình thường",
  },
  {
    code: DOC_IN_PRIORITY_TYPE_ENUM.EMERGENCY,
    description: "Hoả tốc",
  },
  {
    code: DOC_IN_PRIORITY_TYPE_ENUM.URGENT,
    description: "Khẩn",
  },
  {
    code: DOC_IN_PRIORITY_TYPE_ENUM.HIGH_PRIORITY,
    description: "Thượng khẩn",
  },
];
