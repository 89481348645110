import { OutlinedInput } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import { FC } from "react";
import { FieldCommonProps } from "./FieldContainer";
import LabelField from "./LabelField";
import TextError from "./TextError";

interface InputFieldProps extends FieldCommonProps {
  [key: string]: unknown;
}

const InputField: FC<InputFieldProps> = (props) => {
  const { label, name, isRequired, placeholder, tooltip, ...rest } = props;
  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <Field
        name={name}
        placeholder={placeholder}
        required={isRequired}
        sx={{
          "&": {
            lineHeight: 1.5,
          },
          ".Mui-disabled": {
            bgcolor: "#e9ecef",
          },
          ".MuiOutlinedInput-input": {
            p: "0.45rem 0.75rem",
          },
        }}
        margin="dense"
        {...rest}
        component={OutlinedInput}
      />
      <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default InputField;
