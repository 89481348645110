import React, { FC, useEffect, useRef, useState } from "react";
import { AccessRightModel } from "../../../model/access-right.model";
import { Employee } from "../../../model/user.model";
import * as Yup from "yup";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import ModalContainer from "../../../components/modal/ModalContainer";
import { spacing_size } from "../../../themes/size";
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import FieldContainer from "../../../components/form/FieldContainer";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { changeFileDocIn } from "../../document/document-in/common/util/doc-in.util";
import UploadSVG from "../../../components/images/upload.svg";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import { removeFile, subStringFileName } from "../../../utils/file.util";
import ChooseFile from "../../../components/icons/ChooseFile";
import ViewFile from "../../../components/common/ViewFile";
import TextError from "../../../components/form/TextError";
import {
  ContractAddendumModel,
  ContractModel,
  PersonnelModel,
  SelectFieldCommonPersonnelModel,
} from "../../../model/personnel.model";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { EXCEPT_SYMBOLS } from "../../../constants/except.constant";
import { ContactTypePersonnels, CONTACT_TYPE_ENUM, FormPayHr } from "../../../enum/personnel.enum";
import {
  changeFileUploadPersonnel,
  isContractOrContractAddendumActive,
  isExistContract,
  isExistContractActive,
  isOverlapDateTimeContract,
  isTimeContractCoincidesContractAddendum,
} from "../common/util/personnel.util";
import moment from "moment";
import { UPLOAD_DATE_FORMAT } from "../../../constants/date-time.constant";
import { toast } from "react-toastify";
import { VALID_FORMAT_FILE } from "../../../constants/file.constant";
import { formatDateTime } from "../../../utils/data-time.util";
import { isForInStatement } from "typescript";
import ModalConfirm from "./ModalConfirmUpdateContract";

interface ModalUpdateContactHrProps {
  open: boolean;
  onClose: () => void;
  valueUploads: PersonnelModel | null;
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  itemContract: ContractModel | null;
  indexContract: number;
  setContractDetail: React.Dispatch<React.SetStateAction<ContractModel[]>>;
  fileContractsUpload: File[];
  setFileContractsUpload: React.Dispatch<React.SetStateAction<File[]>>;
  valuesContract: ContractModel[];
  setValuesContract: React.Dispatch<React.SetStateAction<ContractModel[]>>;
  arrId: any;
  setArrId: any;
  arrCodeDetail: SelectFieldCommonPersonnelModel[];
  setArrCodeDetail: React.Dispatch<React.SetStateAction<SelectFieldCommonPersonnelModel[]>>;
  arrFileContractDeleteId: number[];
  setArrFileContractDeleteId: React.Dispatch<React.SetStateAction<number[]>>;
  valuesContractAddendum: ContractAddendumModel[];
  setValuesContractAddendum: React.Dispatch<React.SetStateAction<ContractAddendumModel[]>>;
}

const ModalUpdateContactHr: FC<ModalUpdateContactHrProps> = (props) => {
  const {
    open,
    onClose,
    valueUploads,
    setValuesUploads,
    itemContract,
    indexContract,
    setContractDetail,
    fileContractsUpload,
    setFileContractsUpload,
    valuesContract,
    setValuesContract,
    arrId,
    setArrId,
    arrCodeDetail,
    setArrCodeDetail,
    arrFileContractDeleteId,
    setArrFileContractDeleteId,
    valuesContractAddendum,
    setValuesContractAddendum,
  } = props;

  const contractAddendumDetail = valuesContractAddendum?.filter(
    (item: ContractAddendumModel) => item?.contractCode === itemContract?.code
  );

  const startDateContractAddendum = contractAddendumDetail?.length > 0 ? contractAddendumDetail[0]?.startDate : null;
  const endDateContractAddendum =
    contractAddendumDetail?.length > 0 ? contractAddendumDetail[contractAddendumDetail?.length - 1]?.endDate : null;

  const currentDate = new Date();
  const currentTime = new Date().getTime();

  const fileContractIdExist: number = itemContract?.contractFile?.id;
  const fileContractNameExist: string = itemContract?.contractFile?.name;

  const getTypeContract: SelectFieldCommonPersonnelModel[] = ContactTypePersonnels?.filter(
    (item: SelectFieldCommonPersonnelModel) => item?.code === itemContract?.type
  );
  const typeContractObj = getTypeContract?.length > 0 ? Object.assign({}, ...getTypeContract) : "";

  const getTypeFormPay: SelectFieldCommonPersonnelModel[] = FormPayHr?.filter(
    (item: SelectFieldCommonPersonnelModel) => item?.code === itemContract?.formPay
  );
  const typeFormPayObj = getTypeFormPay?.length > 0 ? Object.assign({}, ...getTypeFormPay) : "";

  const fileNameDetail = itemContract?.contractFile?.name
    ? itemContract?.contractFile?.name
    : itemContract?.fileTemp?.name;
  const convertFileDetail: any = new File([fileNameDetail], fileNameDetail, {
    type: fileNameDetail?.endsWith(".pdf")
      ? "application/pdf"
      : fileNameDetail?.endsWith(".docx")
      ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const getFileContract: any = fileContractsUpload?.filter((item: File) =>
    item?.name === itemContract?.fileName ? itemContract?.fileName : itemContract?.fileTemp?.name
  );

  const arrTime: any = valuesContract?.map((item: ContractModel) => {
    return {
      startDate: moment(item?.startDate),
      endDate: moment(item?.endDate),
    };
  });
  const arrTypeIndefinite: any = valuesContract
    ?.filter((item: ContractModel) => item?.type === CONTACT_TYPE_ENUM.PERMANENT_TERM)
    ?.map((item: ContractModel) => item?.type);

  let checkActive = false;

  const fileRef = useRef<HTMLButtonElement | HTMLInputElement | any>(null);
  const [file, setFile] = useState<File[]>(getFileContract);
  const [arrFile, setArrFile] = useState<File[] | any>(fileContractsUpload);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [valuesContractUpdate, setValuesContractUpdate] = useState<ContractModel | null>(null);

  const initialValues: ContractModel = {
    code: itemContract?.code ? itemContract?.code : "",
    type: typeContractObj,
    status: itemContract?.status ? itemContract?.status : "",
    startDate: itemContract?.startDate ? new Date(itemContract?.startDate) : null,
    endDate: itemContract?.endDate ? new Date(itemContract?.endDate) : null,
    fileName: itemContract?.fileName
      ? itemContract?.fileName
      : "" || itemContract?.contractFile
      ? itemContract?.contractFile?.name
      : "",
    note: itemContract?.note ? itemContract?.note : "",
    salary: itemContract?.salary ? itemContract?.salary : "",
    allowances: itemContract?.allowances ? itemContract?.allowances : "",
    monthlyBonus: itemContract?.monthlyBonus ? itemContract?.monthlyBonus : "",
    fileTemp: itemContract?.fileTemp ? itemContract?.fileTemp : convertFileDetail,
    formPay: typeFormPayObj,
  };

  const validationSchema = Yup.object({
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Số hợp đồng")),
    type: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Loại hợp đồng")),
    startDate: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Ngày bắt đầu")),
    endDate: Yup.date()
      .nullable()
      .when("type", {
        is: (type: any) => type.code !== CONTACT_TYPE_ENUM.PERMANENT_TERM,
        then: Yup.date()
          .min(Yup.ref("startDate"), "Ngày kết thúc phải lớn hơn ngày bắt đầu.")
          .nullable()
          .required(FORM_MSG.requiredFieldMsg("Ngày kết thúc")),
        otherwise: Yup.date().nullable(),
      }),
    fileTemp: Yup.mixed()
      .required(FORM_MSG.requiredFieldUploadFile("Đính kèm hợp đồng"))
      .test("fileFormat", "Chỉ tải lên file xlsx, xls, doc, docx, pdf", (value) => {
        if (!value) {
          return false;
        }
        return VALID_FORMAT_FILE.includes(value.type);
      }),
  });

  useEffect(() => {
    if (convertFileDetail) {
      setFile([convertFileDetail]);
    } else {
      setFile([itemContract?.fileTemp]);
    }
  }, []);

  const handleChangeType = (formik: FormikHelpers<ContractModel>) => (e: SelectFieldCommonPersonnelModel | null) => {
    formik.setFieldValue("type", e);
  };

  const changeUploadFile = (formik: FormikHelpers<ContractModel>) => (e: any) => {
    e.preventDefault();
    formik.setFieldValue("fileTemp", e.target.files[0]);
    const fileUp = changeFileUploadPersonnel(e, file, "", 1);
    const newFileUp = fileUp?.map((item: File, index: number) => {
      const newNameItem = currentTime + "_" + item?.name;
      const newItem = new File([item], newNameItem, { type: item.type });
      return newItem;
    });
    setFile(newFileUp);
    setArrFile([...arrFile, ...newFileUp]);
    // if (itemContract?.id) {
    //   setArrId([...arrId, itemContract?.id]);
    // }
  };

  const handleChangeFormPay = (formik: FormikHelpers<ContractModel>) => (e: SelectFieldCommonPersonnelModel | null) => {
    formik.setFieldValue("formPay", e);
  };

  const buildBody = (values: ContractModel) => {
    const convertSalary = values?.salary ? values?.salary?.toString().replace(/,/g, "") : null;
    const convertAllowances = values?.allowances ? values?.allowances?.toString().replace(/,/g, "") : null;
    const convertMonthlyBonus = values?.monthlyBonus ? values?.monthlyBonus?.toString().replace(/,/g, "") : null;

    const newValues = {
      ...values,
      id: itemContract?.id ? itemContract?.id : null,
      fileName: file[0]?.name || values?.contractFile?.name,
      type: values?.type?.code,
      startDate: formatDateTime(values?.startDate, UPLOAD_DATE_FORMAT),
      endDate: formatDateTime(values?.endDate, UPLOAD_DATE_FORMAT),
      salary: Number(convertSalary) || null,
      allowances: Number(convertAllowances) || null,
      monthlyBonus: Number(convertMonthlyBonus) || null,
      formPay: values?.formPay?.code,
    };
    return newValues;
  };

  const updateContractData = (values: ContractModel) => {
    const newValues = buildBody(values);
    const arrContract: any = valueUploads?.contracts;
    const arrContractDetails = valuesContract;
    arrContract[indexContract] = newValues;
    arrContractDetails[indexContract] = newValues;
    const uniqueArrayId: any = arrId?.filter((item: any, index: number) => arrId.indexOf(item) === index);
    const newCodeDetail: SelectFieldCommonPersonnelModel = { code: values?.code, description: values?.code };
    arrCodeDetail[indexContract] = newCodeDetail;

    const filterContractAddendumByCode = valuesContractAddendum?.filter(
      (item: ContractAddendumModel) => !(item?.contractCode !== itemContract?.code)
    );

    const getIdFilterContractAddendumByCode = filterContractAddendumByCode?.map(
      (item: ContractAddendumModel) => item?.id
    );

    if (values?.code !== itemContract?.code && contractAddendumDetail?.length > 0) {
      const arrContractAddendum: any = valuesContractAddendum?.filter(
        (item: ContractAddendumModel) => item?.contractCode !== itemContract?.code
      );
      setValuesContractAddendum(arrContractAddendum);
    }
    setValuesUploads({
      ...valueUploads,
      contracts: [...arrContractDetails],
      // deleteContractIds: uniqueArrayId,
      deleteContractAddendumIds: getIdFilterContractAddendumByCode,
      deleteContractFileIds: arrFileContractDeleteId,
    });
    setContractDetail(arrContract);
    setValuesContract(arrContractDetails);
    setFileContractsUpload(arrFile);
    setArrCodeDetail(arrCodeDetail);
    onClose();
  };

  const handleOnSubmit = async (values: ContractModel, formik: FormikHelpers<ContractModel>) => {
    // Check ngày kết thúc phải lớn hơn ngày bắt đầu
    if (moment(values.startDate).isSameOrAfter(moment(values.endDate), "date")) {
      toast.error("Ngày bắt đầu phải lớn hơn kết thúc");
      return;
    }

    const contractExceptionCurrent: any = (valuesContract || []).filter(
      (item: ContractModel, index: number) => index !== indexContract
    );

    // Hợp đồng không được trùng nhau
    if (isExistContract(contractExceptionCurrent, values.code || "")) {
      toast.error("Hợp đồng không được trùng nhau");
      return;
    }

    // Thời gian trùng với hợp đồng khác
    if (isOverlapDateTimeContract(contractExceptionCurrent, values)) {
      toast.error("Thời gian bị trùng với hợp đồng khác");
      return;
    }

    // Đang tồn tại hợp đồng còn hiệu lực
    if (isExistContractActive(contractExceptionCurrent) && isContractOrContractAddendumActive(values)) {
      toast.error("Đang tồn tại hợp đồng còn hiệu lực");
      return;
    }

    //Nếu chứa PLHĐ thì thời gian hợp đồng khi update phải trùng với thời gian PLHĐ
    if (startDateContractAddendum !== null && endDateContractAddendum !== null) {
      if (
        isTimeContractCoincidesContractAddendum(
          values?.startDate,
          values?.endDate,
          startDateContractAddendum,
          endDateContractAddendum
        )
      ) {
        toast.error("Thời gian của HĐ phải phù hợp với thời gian của PLHĐ");
        return;
      }
    }

    // check nếu mã hợp đồng thay đổi so với ban đầu
    if (values?.code !== itemContract?.code && contractAddendumDetail?.length > 0) {
      setOpenModalConfirm(true);
      setValuesContractUpdate(values);
    } else {
      updateContractData(values);
    }
  };

  const handleRemoveFile = (formik: FormikHelpers<ContractModel>, item: File) => {
    if (item?.name === fileContractNameExist) {
      setArrFileContractDeleteId([...arrFileContractDeleteId, fileContractIdExist]);
    }

    setFile(file.filter((file) => item !== file));
    setArrFile(arrFile.filter((file: File) => item !== file));
    formik.setFieldValue("fileTemp", "");
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  return (
    <>
      <ModalConfirm
        open={openModalConfirm}
        onCloseModalConfirm={handleCloseModalConfirm}
        onClose={onClose}
        updateData={updateContractData}
        values={valuesContractUpdate}
      />
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Cập nhật"}
        width="50%"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          // enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off">
              {/* <>{JSON.stringify(formik.values)}</> */}
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={6} lg={5}>
                  <Stack direction={"row"} spacing={spacing_size}>
                    <Grid item xs={12} md={6} lg={5.5}>
                      <FieldContainer
                        name="startDate"
                        onBlur={formik.handleBlur}
                        value={formik.values.startDate}
                        notched={true}
                        variant={"date"}
                        id={"startDate"}
                        label="Ngày bắt đầu"
                        isRequired={true}
                        placeholderText="DD/MM/YYYY"
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={1} sx={{ display: "flex", justifyContent: "center" }}>
                      <HorizontalRuleIcon fontSize="small" sx={{ marginTop: "2rem" }} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={5.5}>
                      <FieldContainer
                        name="endDate"
                        onBlur={formik.handleBlur}
                        value={formik.values.endDate}
                        notched={true}
                        variant={"date"}
                        id={"endDate"}
                        label="Ngày kết thúc"
                        isRequired={formik?.values?.type?.code === CONTACT_TYPE_ENUM.PERMANENT_TERM ? false : true}
                        placeholderText="DD/MM/YYYY"
                        // minDate={currentDate}
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                      />
                    </Grid>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FieldContainer
                    fullWidth
                    name="type"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeType(formik)}
                    value={formik.values.type}
                    notched={true}
                    options={ContactTypePersonnels}
                    variant={"react-select"}
                    id={"type"}
                    label="Loại hợp đồng"
                    isRequired={true}
                    placeholder="Nhập/chọn thông tin"
                    getOptionLabel={(item: SelectFieldCommonPersonnelModel) => item?.description}
                    getOptionValue={(item: SelectFieldCommonPersonnelModel) => item?.code}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    name="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.code}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Số hợp đồng"
                    notched={true}
                    variant={"input"}
                    id={"code"}
                    isRequired={true}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" alignItems="center" spacing={spacing_size} sx={{ margin: "0.6rem 0" }}>
                    <Typography sx={{ fontSize: "1rem", fontWeight: "600", color: "#323E5D" }}>
                      Đính kèm hợp đồng{" "}
                      <Box component="span" sx={{ color: "red" }}>
                        *
                      </Box>
                    </Typography>
                    <Button
                      variant="contained"
                      color="cancel"
                      // sx={{ display: "flex" }}
                      onClick={() => fileRef.current.click()}
                      disabled={file?.length < 1 ? false : true}
                      startIcon={<ChooseFile />}
                    >
                      Chọn file
                    </Button>
                  </Stack>
                  <Field
                    id="file-input"
                    name="fileTemp"
                    // accept="application/msword, application/vnd.ms-excel, application/pdf"
                    accept=".xlsx,.xls,.doc,.docx,.pdf"
                    type="file"
                    innerRef={fileRef}
                    style={{ display: "none" }}
                    value={""}
                    onChange={changeUploadFile(formik)}
                  />
                  {file.map((item, index) => {
                    let newItem = subStringFileName(item);
                    return <ViewFile key={index} item={newItem} handleRemove={() => handleRemoveFile(formik, item)} />;
                  })}
                  <ErrorMessage name="fileTemp" component={TextError} />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="note"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.note}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Ghi chú"
                    notched={true}
                    variant={"input"}
                    id={"note"}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    // fullWidth
                    name="salary"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.salary}
                    placeholder={FORM_MSG.placeholder}
                    // maxRows={12}
                    label="Lương theo hợp đồng (VNĐ)"
                    // notched={true}
                    variant={"number-input"}
                    id={"salary"}
                    thousandSeparator=","
                    // inputProps={{ min: 0 }}
                    onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    // fullWidth
                    name="allowances"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.allowances}
                    placeholder={FORM_MSG.placeholder}
                    // maxRows={12}
                    label="Phụ cấp (VNĐ)"
                    // notched={true}
                    variant={"number-input"}
                    id={"allowances"}
                    thousandSeparator=","
                    // inputProps={{ min: 0 }}
                    onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    // fullWidth
                    name="monthlyBonus"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.monthlyBonus}
                    placeholder={FORM_MSG.placeholder}
                    // maxRows={12}
                    label="Thưởng tháng (VNĐ)"
                    // notched={true}
                    variant={"number-input"}
                    id={"monthlyBonus"}
                    thousandSeparator=","
                    // inputProps={{ min: 0 }}
                    onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    id={"formPay"}
                    name="formPay"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeFormPay(formik)}
                    type="text"
                    value={formik.values.formPay}
                    placeholder={FORM_MSG.placeholder}
                    label="Hình thức trả lương"
                    notched={true}
                    variant={"react-select"}
                    getOptionLabel={(item: SelectFieldCommonPersonnelModel) => item?.description}
                    getOptionValue={(item: SelectFieldCommonPersonnelModel) => item?.code}
                    options={FormPayHr}
                  />
                </Grid>

                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    <Button variant="contained" color="primary" type="submit" startIcon={<BrowserIcon />}>
                      Lưu
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalUpdateContactHr;
