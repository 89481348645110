import { SvgIconProps } from "@mui/material/SvgIcon";
import React, { FC } from "react";

interface GeneralBtnSubmitIconProps {}

const GeneralBtnSubmitIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_2)">
        <path
          d="M10.749 4.3975L10.8235 4.323C10.8796 4.26696 10.9461 4.22251 11.0194 4.19219C11.0926 4.16188 11.1711 4.14628 11.2504 4.14631C11.3297 4.14633 11.4082 4.16197 11.4814 4.19233C11.5547 4.22269 11.6212 4.26718 11.6772 4.32325C11.7333 4.37932 11.7777 4.44589 11.8081 4.51914C11.8384 4.59239 11.854 4.6709 11.8539 4.75018C11.8539 4.82945 11.8383 4.90795 11.8079 4.98119C11.7776 5.05442 11.7331 5.12096 11.677 5.177L11.6025 5.251C11.7299 5.39393 11.7979 5.5802 11.7924 5.77161C11.7869 5.96303 11.7084 6.1451 11.573 6.2805L6.427 11.427C6.39483 11.459 6.35456 11.4816 6.3105 11.4925L4.3105 11.9925C4.26865 12.0029 4.22481 12.0023 4.18325 11.9908C4.14168 11.9793 4.10381 11.9572 4.07332 11.9267C4.04282 11.8962 4.02073 11.8583 4.00919 11.8168C3.99766 11.7752 3.99708 11.7314 4.0075 11.6895L4.5075 9.6895C4.51844 9.64562 4.54108 9.60553 4.573 9.5735L9.394 4.7525C9.34578 4.71856 9.28712 4.70276 9.22839 4.70789C9.16965 4.71301 9.11461 4.73873 9.073 4.7805L7.427 6.427C7.40376 6.45024 7.37616 6.46868 7.34579 6.48126C7.31542 6.49384 7.28287 6.50032 7.25 6.50032C7.21713 6.50032 7.18458 6.49384 7.15421 6.48126C7.12384 6.46868 7.09624 6.45024 7.073 6.427C7.04976 6.40376 7.03132 6.37616 7.01874 6.34579C7.00616 6.31542 6.99968 6.28287 6.99968 6.25C6.99968 6.21713 7.00616 6.18458 7.01874 6.15421C7.03132 6.12384 7.04976 6.09624 7.073 6.073L8.72 4.427C8.85549 4.29151 9.0377 4.21301 9.22923 4.20762C9.42077 4.20222 9.6071 4.27035 9.75 4.398C9.88738 4.27522 10.0652 4.20731 10.2494 4.20721C10.4337 4.20712 10.6115 4.27486 10.749 4.3975V4.3975ZM10.427 4.7805C10.3801 4.73363 10.3165 4.7073 10.2502 4.7073C10.184 4.7073 10.1204 4.73363 10.0735 4.7805L4.975 9.878L4.593 11.4065L6.1215 11.0245L11.22 5.927C11.2433 5.90378 11.2618 5.87619 11.2744 5.84582C11.287 5.81544 11.2934 5.78288 11.2934 5.75C11.2934 5.71712 11.287 5.68456 11.2744 5.65418C11.2618 5.62381 11.2433 5.59622 11.22 5.573L10.4275 4.7805H10.427Z"
          fill="black"
        />
      </g>
      <circle cx="8" cy="8" r="7.5" stroke="black" />
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="8" height="8" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GeneralBtnSubmitIcon;
