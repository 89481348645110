import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const ChooseFile: FC<SvgIconProps> = (props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.93"
        d="M5.99935 0.166504C2.77935 0.166504 0.166016 2.77984 0.166016 5.99984C0.166016 9.21984 2.77935 11.8332 5.99935 11.8332C9.21935 11.8332 11.8327 9.21984 11.8327 5.99984C11.8327 2.77984 9.21935 0.166504 5.99935 0.166504ZM8.54852 7.37067L7.25352 8.66567C6.93852 8.98067 6.53018 9.13234 6.12185 9.13234C5.71352 9.13234 5.29935 8.97484 4.99018 8.66567C4.68685 8.36234 4.51768 7.95984 4.51768 7.534C4.51768 7.10817 4.68685 6.69984 4.99018 6.40234L5.81268 5.57984C5.98185 5.41067 6.26185 5.41067 6.43102 5.57984C6.60018 5.749 6.60018 6.029 6.43102 6.19817L5.60852 7.02067C5.46852 7.16067 5.39268 7.3415 5.39268 7.534C5.39268 7.7265 5.46852 7.91317 5.60852 8.04734C5.89435 8.33317 6.35518 8.33317 6.64102 8.04734L7.93602 6.75234C8.67685 6.0115 8.67685 4.80984 7.93602 4.069C7.19518 3.32817 5.99352 3.32817 5.25268 4.069L3.84102 5.48067C3.54352 5.77817 3.38018 6.169 3.38018 6.58317C3.38018 6.99734 3.54352 7.394 3.84102 7.68567C4.01018 7.85484 4.01018 8.13484 3.84102 8.304C3.67185 8.47317 3.39185 8.47317 3.22268 8.304C2.75602 7.85484 2.49935 7.24234 2.49935 6.589C2.49935 5.93567 2.75018 5.32317 3.21102 4.86234L4.62268 3.45067C5.70185 2.3715 7.46352 2.3715 8.54268 3.45067C9.62768 4.52984 9.62768 6.2915 8.54852 7.37067Z"
        fill="#2E3D5B"
      />
    </svg>
  );
};

export default ChooseFile;
