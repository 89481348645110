import { EnumCommon } from "./common.enum";

export enum TAB_SELECT_PERSONNEL_ENUM {
  GENERAL_INFORMATION = "GENERAL_INFORMATION",
  CONTRACT = "CONTRACT",
  WORKING_PROCESS = "WORKING_PROCESS",
  LEVEL = "LEVEL",
  RELATIVE = "RELATIVE",
  DOCUMENT = "DOCUMENT",
}

export const ObjectTabSelectPersonnel: EnumCommon<TAB_SELECT_PERSONNEL_ENUM>[] = [
  {
    code: TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION,
    description: "Thông tin chung",
    order: 0,
  },
  {
    code: TAB_SELECT_PERSONNEL_ENUM.CONTRACT,
    description: "Hợp đồng",
    order: 1,
  },
  {
    code: TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS,
    description: "Quá trình công tác",
    order: 2,
  },
  {
    code: TAB_SELECT_PERSONNEL_ENUM.LEVEL,
    description: "Trình độ",
    order: 3,
  },
  {
    code: TAB_SELECT_PERSONNEL_ENUM.RELATIVE,
    description: "Người thân",
    order: 4,
  },
  {
    code: TAB_SELECT_PERSONNEL_ENUM.DOCUMENT,
    description: "Tài liệu",
    order: 5,
  },
];

export enum TYPE_PERSONNEL_ENUM {
  // STAFF = "STAFF",
  // EXPERT = "EXPERT",
  // VIP = "VIP",

  LEADERS_AND_DEPARTMENTS = "LEADERS_AND_DEPARTMENTS", // ("Lãnh đạo/các ban"),
  OPERATING_PARTS = "OPERATING_PARTS", // ("Bộ phần điều hành"),
  EXPERTS_AND_COACHING_STAFF = "EXPERTS_AND_COACHING_STAFF", //("Chuyên gia/ban huấn luyện"),
  THE_SUBORDINATE_UNITS = "THE_SUBORDINATE_UNITS", //("Các đơn vị trực thuộc"),
  OTHER = "OTHER",
}

export const SelectPersonnelOptions: EnumCommon<TYPE_PERSONNEL_ENUM>[] = [
  { code: TYPE_PERSONNEL_ENUM.LEADERS_AND_DEPARTMENTS, description: "Lãnh đạo/các ban" },
  { code: TYPE_PERSONNEL_ENUM.OPERATING_PARTS, description: "Bộ phần điều hành" },
  { code: TYPE_PERSONNEL_ENUM.EXPERTS_AND_COACHING_STAFF, description: "Chuyên gia/ban huấn luyện" },
  { code: TYPE_PERSONNEL_ENUM.THE_SUBORDINATE_UNITS, description: "Các đơn vị trực thuộc" },
  { code: TYPE_PERSONNEL_ENUM.OTHER, description: "Khác" },
];

export enum GENDER_ENUM {
  MALE = "M",
  FEMALE = "F",
  OTHER = "O",
}

export const GenderPersonnels: EnumCommon<GENDER_ENUM>[] = [
  { code: GENDER_ENUM.MALE, description: "Nam" },
  { code: GENDER_ENUM.FEMALE, description: "Nữ" },
  { code: GENDER_ENUM.OTHER, description: "Khác" },
];

export enum MARITAL_STATUS_ENUM {
  SINGLE = "S",
  MARRIED = "M",
}

export const MaritalStatusPersonnels: EnumCommon<MARITAL_STATUS_ENUM>[] = [
  { code: MARITAL_STATUS_ENUM.SINGLE, description: "Độc thân" },
  { code: MARITAL_STATUS_ENUM.MARRIED, description: "Đã kết hôn" },
];

export enum CONTACT_TYPE_ENUM {
  FIXED_TERM = "FIXED_TERM",
  PROBATIONARY = "PROBATIONARY",
  SEASONAL = "SEASONAL",
  PERMANENT_TERM = "PERMANENT_TERM",
}

export const ContactTypePersonnels: EnumCommon<CONTACT_TYPE_ENUM>[] = [
  { code: CONTACT_TYPE_ENUM.FIXED_TERM, description: "HĐLĐ xác định được thời hạn" },
  { code: CONTACT_TYPE_ENUM.PROBATIONARY, description: "HĐ thử việc" },
  { code: CONTACT_TYPE_ENUM.SEASONAL, description: "HĐLĐ mùa vụ" },
  { code: CONTACT_TYPE_ENUM.PERMANENT_TERM, description: "HĐLĐ không thời hạn" },
];

// Status contract
export enum CONTACT_STATUS_ENUM {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export const ContactStatusPersonnels: EnumCommon<CONTACT_STATUS_ENUM>[] = [
  { code: CONTACT_STATUS_ENUM.ACTIVE, description: "Còn hiệu lực" },
  { code: CONTACT_STATUS_ENUM.INACTIVE, description: "Hết hiệu lực" },
];

export enum RELATIVES_ENUM {
  FATHER = "FATHER",
  MOTHER = "MOTHER",
  CHILD = "BABY",
  SIBLINGS = "SIBLINGS",
  WIFE_HUSBAND = "WIFE_HUSBAND",
}

export const RelativesPersonnels: EnumCommon<RELATIVES_ENUM>[] = [
  { code: RELATIVES_ENUM.FATHER, description: "Bố" },
  { code: RELATIVES_ENUM.MOTHER, description: "Mẹ" },
  { code: RELATIVES_ENUM.CHILD, description: "Con" },
  { code: RELATIVES_ENUM.SIBLINGS, description: "Anh/Chị/em ruột" },
  { code: RELATIVES_ENUM.WIFE_HUSBAND, description: "Vợ/Chồng" },
];

// Position
export enum POSITION_ENUM {
  PARTY_MEMBER = "Đảng viên",
  UNION_MEMBER = "Đoàn viên",
}

export const PositionPersonnel: EnumCommon<POSITION_ENUM>[] = [
  { code: POSITION_ENUM.PARTY_MEMBER, description: "Đảng viên" },
  { code: POSITION_ENUM.UNION_MEMBER, description: "Đoàn viên" },
];

// Identity Documents
export enum IDENTITY_DOCUMENTS_ENUM {
  IDENTITY_CARD = "IDENTITY_CARD",
  PASSPORT = "PASSPORT",
}

export const IdentityDocumentsPersonnel: EnumCommon<IDENTITY_DOCUMENTS_ENUM>[] = [
  { code: IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD, description: "CMND/CCCD" },
  { code: IDENTITY_DOCUMENTS_ENUM.PASSPORT, description: "Hộ chiếu" },
];

// Other Documents
export enum OTHER_DOCUMENTS_ENUM {
  WORK_PREMIT = "WORK_PREMIT",
  TEMPORARY = "TEMPORARY",
}

export const OtherDocumentsPersonnel: EnumCommon<OTHER_DOCUMENTS_ENUM>[] = [
  { code: OTHER_DOCUMENTS_ENUM.WORK_PREMIT, description: "Giấy phép lao động" },
  { code: OTHER_DOCUMENTS_ENUM.TEMPORARY, description: "Thẻ tạm trú" },
];

// Status
export enum HR_STATUS_ENUM {
  NORMAL = "NORMAL",
  RETIRED = "RETIRED",
  MATERNITY_LEAVE = "MATERNITY_LEAVE",
  INACTIVE_DUE_TO_EMAIL_CHANGE = "INACTIVE_DUE_TO_EMAIL_CHANGE",
}

export const StatusHR: EnumCommon<HR_STATUS_ENUM>[] = [
  { code: HR_STATUS_ENUM.NORMAL, description: "Đang làm việc" },
  { code: HR_STATUS_ENUM.RETIRED, description: "Nghỉ việc" },
  { code: HR_STATUS_ENUM.MATERNITY_LEAVE, description: "Nghỉ thai sản" },
  { code: HR_STATUS_ENUM.INACTIVE_DUE_TO_EMAIL_CHANGE, description: "Không hoạt động do đổi email" },
];

// Form Pay
export enum FORM_PAY_ENUM {
  SALARY_GROSS = "SALARY_GROSS",
  SALARY_NET = "SALARY_NET",
}

export const FormPayHr: EnumCommon<FORM_PAY_ENUM>[] = [
  { code: FORM_PAY_ENUM.SALARY_GROSS, description: "Lương Gross" },
  { code: FORM_PAY_ENUM.SALARY_NET, description: "Lương Net" },
];

// Thông tin thay đổi
export enum INFORMATION_CHANGE_ENUM {
  SALARY_CONTRACT = "SALARY_CONTRACT",
  ALLOWANCES = "ALLOWANCES",
  MONTHLY_BONUS = "MONTHLY_BONUS",
}

export const InformationChangeContractAddendum: EnumCommon<INFORMATION_CHANGE_ENUM>[] = [
  { code: INFORMATION_CHANGE_ENUM.SALARY_CONTRACT, description: "Lương theo hợp đồng" },
  { code: INFORMATION_CHANGE_ENUM.ALLOWANCES, description: "Phụ cấp" },
  { code: INFORMATION_CHANGE_ENUM.MONTHLY_BONUS, description: "Thưởng tháng" },
];
