import HTTP_USER_SERVICE from "../config/axios/axios-user.config";
import { VerifyOTPModel } from "../model/auth.model";

export const loginApi = (account: string, password: string) => {
  return HTTP_USER_SERVICE.post("/login", { account, password });
};

export const logoutApi = () => {
  return HTTP_USER_SERVICE.get("/logout");
};

export const currentUserApi = () => {
  return HTTP_USER_SERVICE.get("/current-user");
};

export const resetPasswordApi = (email: string, code: string) => {
  return HTTP_USER_SERVICE.get("/reset-password", { params: { email, code } });
};

export const verifyOtpApi = (email: string, token: string) => {
  return HTTP_USER_SERVICE.get<VerifyOTPModel>("/verify-otp", { params: { email } });
};

export const changePwApi = (oldPassword: string, password: string) => {
  return HTTP_USER_SERVICE.post("/change-password", {
    oldPassword,
    password,
  });
};
