import { debounce } from "@mui/material";
import { getUserApi } from "../api/user.api";
import { Department } from "../model/department.model";
import { UserModel } from "../model/user.model";
import { WAIT_TIME_DEBOUNCE } from "../constants/date-time.constant";

export const buildOptionObject = (
  obj: object | any,
  fieldValue: string,
  fieldLabel: string,
  label?: string,
  value?: string | number | object | undefined
) => ({
  ...obj,
  value: value || obj[fieldValue],
  label: label || obj[fieldLabel],
});

export const buildOptionObjectDepartment = (department: Department) =>
  `${department?.code} - ${department?.displayName}`;

export const buildOptionUserLabel = (user: UserModel) => `${user?.fullName} (${user?.email})`;

export const loadEmployee = (inputValue: string, callback: (options: UserModel[]) => void) => {
  const MIN_LENGTH_INPUT = -1;
  if (inputValue && inputValue.length >= MIN_LENGTH_INPUT) {
    getUserApi(inputValue, "").then((res) => {
      callback(res.data);
    });
  } else {
    callback([]);
  }
};

export const loadEmployeeWithDebounce = debounce(loadEmployee, WAIT_TIME_DEBOUNCE);

export const filterOptions = (candidate: any, input: string) => {
  if (input) {
    const { email, fullName } = candidate?.data || {};
    if (
      email.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
      fullName.toLocaleLowerCase().includes(input.toLocaleLowerCase())
    ) {
      return true;
    }

    return false;
  }
  return true;
};
