import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { spacing_size } from "../../../../themes/size";
import { DOC_IN_MSG } from "../../../../constants/msg/msg.constant";
import { docInCancelApi } from "../../../../api/doc-in.api";
import ModalContainer from "../../../../components/modal/ModalContainer";
import { toast } from "react-toastify";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { useNavigate } from "react-router-dom";

interface ModalCancelProps {
  open: boolean;
  onClose: () => void;
  id: number;
}

interface InitialValuesForm {}

const ModalCancel: FC<ModalCancelProps> = (props) => {
  const { open, onClose, id } = props;
  const navigate = useNavigate();

  const handleCancel = () => {
    onClose();
  };

  const handleOnSubmit = async () => {
    await docInCancelApi(id)
      .then((res) => {
        if (res) {
          toast.success(DOC_IN_MSG.cancelSuccess);
          onClose();
          navigate("/doc-in");
        }
      })
      .catch((err) => {
        const msg = getErrorMsgAxios(err);
        toast.error(msg);
        throw Error(err);
      });
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title="Xác nhận"
      >
        <Typography id="modal-modal-description">Phiếu sẽ được huỷ. Vui lòng xác nhận!</Typography>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={spacing_size}
          sx={{
            mt: 2,
            justifyContent: "end",
          }}
        >
          <Button type="button" variant="outlined" onClick={handleCancel}>
            Huỷ
          </Button>
          <Button type="button" variant="contained" onClick={handleOnSubmit}>
            Xác nhận
          </Button>
        </Stack>
      </ModalContainer>
    </>
  );
};

export default ModalCancel;
