export const convertDateToDD_MM_YYYY = (date: Date) => {
  // Extract day, month, and year
  let day = date.getDate();
  let month = date.getMonth() + 1; // Adding 1 because months are zero-indexed
  let year = date.getFullYear();

  // Add leading zeros if needed
  let dayStr = (day < 10 ? '0' : '') + day;
  let monthStr = (month < 10 ? '0' : '') + month;

  // Format the date as dd-mm-yyyy
  return `${dayStr}_${monthStr}_${year}`;
}