import React, { FC } from "react";
import { ErrorMessage, Field } from "formik";
import DatePicker from "react-datepicker";
import { FieldCommonProps } from "./FieldContainer";
import LabelField from "./LabelField";
import TextError from "./TextError";
import { FormControl } from "@mui/material";

interface DatePickerFieldProps extends FieldCommonProps {
  [key: string]: unknown;
}

const DatePickerField: FC<DatePickerFieldProps> = (props) => {
  const { label, name, tooltip, isRequired, id, className, ...rest } = props;
  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <Field name={name}>
        {({ form, field }: { form: any; field: any }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <DatePicker
              id={id}
              {...field}
              onChange={(val: any) => {
                // console.log(val);
                setFieldValue(name, val && (val + "").toLowerCase() !== "invalid date" ? val : null);
              }}
              showIcon
              // prevMonthButtonDisabled
              // nextMonthButtonDisabled
              // prevYearButtonDisabled
              // nextYearButtonDisabled
              dateFormat="dd/MM/yyyy"
              autoComplete="off"
              className={"form-control vff-custom-date-icon-input " + className}
              selected={value}
              {...rest}
            />
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default DatePickerField;
