import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { Box } from "@mui/material";
import AddCircleIcon from "../../../../components/icons/AddCircleIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormFilterDocIn from "./FormFilterDocIn";
import ViewFilterDocIn from "./ViewFilterDocIn";
import HeaderPage from "../../../../components/common/HeaderPage";
import { useLocation, useNavigate } from "react-router-dom";
import { isEnableCreateButton } from "../common/util/doc-in.util";
import { useAppSelector } from "../../../../store/hook";
import { ACCESS_RIGHT_ENUM } from "../../../../enum/access-right.enum";
import { hasAccessRight } from "../../../../utils/auth.util";
import { RequestFilterDocInModel } from "../../../../model/doc-in.model";
import { isEmptyObj } from "../../../../utils/object.util";
import { ALL_ENUM } from "../../../../enum/common.enum";
import { FROM_DASHBOARD } from "../../../../constants/navigate.constant";
import ArrowBackIcon from "../../../../components/icons/ArrowBackIcon";

const buttonCreateOptions = ["Trong nước", "Quốc tế"];

interface InitialValuesForm {
  code?: string | null | undefined;
  docBookId?: string | null | undefined | Date | any;
  docCategory?: string | null | undefined;
  summary?: string | null | undefined;
  status?: string | null | undefined;
  fromDate?: string | null | undefined | Date;
  toDate?: string | null | undefined | Date;
}

const FilterDocIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initBodyFilter: RequestFilterDocInModel = {
    code: null,
    docBookId: null,
    docCategory: null,
    summary: null,
    status: null,
    fromDate: null,
    toDate: null,
  };

  const initialValues: InitialValuesForm = {
    code: "",
    docBookId: "",
    docCategory: ALL_ENUM,
    summary: "",
    status: ALL_ENUM,
    fromDate: "",
    toDate: "",
  };

  const [bodyFilter, setBodyFilter] = useState<RequestFilterDocInModel>(initBodyFilter);
  const [initValue, setInitValue] = useState<InitialValuesForm>(initialValues);
  const [docYear, setDocYear] = useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  useEffect(() => {
    const stateLocation = location?.state;
    if (!isEmptyObj(stateLocation)) {
      if (stateLocation) {
        const body = {
          code: stateLocation?.bodyFilter?.code,
          docBookId: stateLocation?.docBookId,
          docCategory: stateLocation?.bodyFilter?.docCategory,
          summary: stateLocation?.bodyFilter?.summary,
          status: stateLocation?.bodyFilter?.status,
          fromDate: stateLocation?.bodyFilter?.fromDate,
          toDate: stateLocation?.bodyFilter?.toDate,
        };
        setInitValue({
          ...body,
          fromDate: stateLocation?.bodyFilter?.fromDate ? new Date(stateLocation?.bodyFilter?.fromDate) : null,
          toDate: stateLocation?.bodyFilter?.toDate ? new Date(stateLocation?.bodyFilter?.toDate) : null,
        });
        setBodyFilter({
          ...docYear,
          docYear: stateLocation?.bodyFilter?.docYear,
        });
        setDocYear(stateLocation?.docYear);
      }
    } else {
      setInitValue(initialValues);
    }
  }, []);

  const handleClick = () => {
    if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN)) {
      if (selectedIndex === 0) {
        navigate("/doc-in/create/domestic");
      }
      if (selectedIndex === 1) {
        navigate("/doc-in/create/international");
      }
    } else if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.CLERICAL_ASSISTANT)) {
      navigate("/doc-in/create/domestic");
    } else if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.STAFF_INTERNATIONAL_DEPARTMENT)) {
      navigate("/doc-in/create/international");
    } else {
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
    if (index === 0) {
      navigate("/doc-in/create/domestic");
    }
    if (index === 1) {
      navigate("/doc-in/create/international");
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box>
      {/* header */}
      {location?.state?.navigateFrom == FROM_DASHBOARD && (
        <Button
          variant="outlined"
          color="primary"
          type="button"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/dashboard")}
        >
          Quay lại
        </Button>
      )}
      <HeaderPage title="Danh sách công văn đến">
        <>
          {currentUser && isEnableCreateButton(currentUser) && (
            <>
              <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button color="primary" startIcon={<AddCircleIcon />} onClick={handleClick}>
                  Thêm mới
                </Button>
                {hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN) && (
                  <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                )}
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {buttonCreateOptions.map((option, index) => (
                            <MenuItem
                              key={option}
                              disabled={index === 2}
                              selected={index === selectedIndex}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          )}
        </>
      </HeaderPage>

      {/* filter */}
      <FormFilterDocIn
        setBodyFilter={setBodyFilter}
        bodyFilter={bodyFilter}
        initValue={initValue}
        setDocYear={setDocYear}
      />

      {/* view */}
      <ViewFilterDocIn bodyFilter={bodyFilter} docYear={docYear} />
    </Box>
  );
};

export default FilterDocIn;
