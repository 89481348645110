import React from "react";
import ModalContainer from "../../../components/modal/ModalContainer";
import { Button, Stack, Typography } from "@mui/material";
import { spacing_size } from "../../../themes/size";

interface ModalConfirmProps {
  open: boolean;
  onClose: () => void;
  onCloseModalConfirm: () => void;
  updateData: (values: any) => void;
  values: any;
}

const ModalConfirm = (props: ModalConfirmProps) => {
  const { open, onClose, onCloseModalConfirm, values, updateData } = props;

  const handleCancel = () => {
    onCloseModalConfirm();
  };
  const handleOnSubmit = () => {
    updateData(values);
    onCloseModalConfirm();
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title="Xác nhận"
        style={{ zIndex: "1000" }}
      >
        <Typography id="modal-modal-description">
          HĐ đang tồn tại PLHĐ, nếu Đồng ý Lưu các PLHĐ này sẽ bị xóa. Bạn có chắc chắn không?
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={spacing_size}
          sx={{
            mt: 2,
            justifyContent: "end",
          }}
        >
          <Button type="button" variant="outlined" onClick={handleCancel}>
            Huỷ
          </Button>
          <Button type="button" variant="contained" onClick={handleOnSubmit}>
            Xác nhận
          </Button>
        </Stack>
      </ModalContainer>
    </>
  );
};

export default ModalConfirm;
