import { Box, Button } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderPage from "../../../../components/common/HeaderPage";
import AddCircleIcon from "../../../../components/icons/AddCircleIcon";
import ArrowBackIcon from "../../../../components/icons/ArrowBackIcon";
import { FROM_DASHBOARD_DOC_OUT } from "../../../../constants/navigate.constant";
import { ACCESS_RIGHT_ENUM } from "../../../../enum/access-right.enum";
import { DocBook } from "../../../../model/doc-book.model";
import { RequestFilterDocInModel } from "../../../../model/doc-in.model";
import { FilterDocOutModel } from "../../../../model/doc-out.model";
import { useAppSelector } from "../../../../store/hook";
import { hasAnyAccessRight } from "../../../../utils/auth.util";
import { isEmptyObj } from "../../../../utils/object.util";
import FormFilterDocOut from "./FormFilterDocOut";
import ViewFilterDocOut from "./ViewFilterDocOut";

interface FilterDocOutProps {}

interface InitialValuesForm {
  docCategory?: string | null | undefined;
  summary?: string | null | undefined;
  docBook?: string | null | undefined | DocBook | any;
  createdBy?: string | null | undefined;
  arrivalNbr?: string | number | null | undefined;
  fromPlace?: string | null | undefined;
  codeDocIn?: string | null | undefined;
}

const FilterDocOut: FC<FilterDocOutProps> = (props) => {
  const location = useLocation();
  const initBodyFilter: FilterDocOutModel = {
    docCategory: null,
    summary: null,
    docBook: null,
    createdBy: null,
    arrivalNbr: null,
    fromPlace: null,
    codeDocIn: null,
  };

  const initialValues: InitialValuesForm = {
    docCategory: "ALL",
    summary: "",
    docBook: "",
    createdBy: "",
    arrivalNbr: "",
    fromPlace: "",
    codeDocIn: "",
  };

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [bodyFilter, setBodyFilter] = useState<RequestFilterDocInModel>(initBodyFilter);
  const [initValue, setInitValue] = useState<InitialValuesForm>(initialValues);
  const [docBook, setDocBook] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const stateLocation = location?.state;
    if (!isEmptyObj(stateLocation)) {
      if (stateLocation) {
        const body = {
          docCategory: stateLocation?.bodyFilter?.docCategory,
          summary: stateLocation?.bodyFilter?.summary,
          docBook: stateLocation?.docBook ? stateLocation?.docBook : [],
          createdBy: stateLocation?.bodyFilter?.createdBy,
          arrivalNbr: stateLocation?.bodyFilter?.arrivalNbr,
          fromPlace: stateLocation?.bodyFilter?.fromPlace,
          codeDocIn: stateLocation?.bodyFilter?.codeDocIn,
        };

        setInitValue(body);
        setBodyFilter({
          ...body,
          docBook: stateLocation?.bodyFilter?.docBook,
        });
        setDocBook(stateLocation?.docBook);
      }
    } else {
      setInitValue(initialValues);
    }
  }, []);
  const handleClick = () => {
    navigate("/doc-out/create");
  };

  return (
    <>
      {location?.state?.navigateFrom == FROM_DASHBOARD_DOC_OUT && (
        <Button
          variant="outlined"
          color="primary"
          type="button"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/dashboard")}
        >
          Quay lại
        </Button>
      )}
      <Box>
        <HeaderPage title="Danh sách công văn đi">
          <>
            {hasAnyAccessRight(currentUser, [ACCESS_RIGHT_ENUM.ADMIN, ACCESS_RIGHT_ENUM.CLERICAL_ASSISTANT]) && (
              <Button variant="contained" color="primary" startIcon={<AddCircleIcon />} onClick={handleClick}>
                Thêm mới
              </Button>
            )}
          </>
        </HeaderPage>
        <FormFilterDocOut
          setBodyFilter={setBodyFilter}
          bodyFilter={bodyFilter}
          initValue={initValue}
          setDocBook={setDocBook}
        />
        <ViewFilterDocOut bodyFilter={bodyFilter} docBook={docBook} />
      </Box>
    </>
  );
};

export default FilterDocOut;
