import React, { FC, useEffect, useState } from "react";
import ModalContainer from "../../components/modal/ModalContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import FieldContainer from "../../components/form/FieldContainer";
import { AUTH_MSG, FORM_MSG } from "../../constants/msg/msg.constant";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { changePwApi } from "../../api/auth.api";
import { useAppDispatch } from "../../store/hook";
import { toast } from "react-toastify";
import { getErrorMsgAxios } from "../../utils/error.util";
import { getCurrentUserApiAsync } from "./redux/auth.slice";
import { PASSWORD_REGEX } from "../../constants/regex.constant";
import { spacing_size } from "../../themes/size";

interface ChangePWModalProps {
  openModal: boolean;
  showIconClose?: boolean;
  onClose: () => void;
}
interface InitialValuesForm {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePWModal: FC<ChangePWModalProps> = (props) => {
  const { openModal, showIconClose = true, onClose } = props;
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useAppDispatch();
  const labelFields = {
    oldPassword: "Mật khẩu cũ",
    newPassword: "Mật khẩu mới",
    confirmPassword: "Mật khẩu xác nhận",
  };

  useEffect(() => {
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  }, [openModal]);

  const initialValues: InitialValuesForm = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg(labelFields.oldPassword))
      .min(8, FORM_MSG.minLengthFieldMsg(8))
      .matches(PASSWORD_REGEX, FORM_MSG.patternFieldMsg(labelFields.oldPassword)),
    newPassword: Yup.string()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg(labelFields.newPassword))
      .min(8, FORM_MSG.minLengthFieldMsg(8))
      .matches(PASSWORD_REGEX, FORM_MSG.patternFieldMsg(labelFields.newPassword))
      .notOneOf([Yup.ref("oldPassword"), null], "Mật khẩu mới không thể giống mật khẩu cũ"),
    confirmPassword: Yup.string()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg(labelFields.confirmPassword))
      .min(8, FORM_MSG.minLengthFieldMsg(8))
      .matches(PASSWORD_REGEX, FORM_MSG.patternFieldMsg(labelFields.confirmPassword))
      .oneOf([Yup.ref("newPassword"), null], "Mật khẩu xác nhận chưa chính xác"),
  });

  const handleOnSubmit = async (value: InitialValuesForm, formikHelper: FormikHelpers<InitialValuesForm>) => {
    try {
      await changePwApi(value.oldPassword, value.newPassword);
      await dispatch(getCurrentUserApiAsync());
      toast.success(AUTH_MSG.changePwSuccess);
      formikHelper.resetForm();
      onClose();
    } catch (error: any) {
      if (error?.response?.data?.errorKey === "WRONG_PASSWORD") {
        formikHelper.setFieldError("oldPassword", AUTH_MSG.wrongPassword);
      } else {
        toast.error(getErrorMsgAxios(error));
      }
    }
  };

  return (
    <ModalContainer
      open={openModal}
      title={"Đổi mật khẩu"}
      onClose={() => {
        onClose();
      }}
      showIconClose={showIconClose}
      width={{
        sm: 800,
        xs: 600,
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={false}
      >
        {(formik) => (
          <>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={6} spacing={spacing_size}>
                  <FieldContainer
                    fullWidth
                    name="oldPassword"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showOldPassword ? "text" : "password"}
                    inputProps={{ minLength: 8, maxLength: 255 }}
                    value={formik.values.oldPassword}
                    placeholder={labelFields.oldPassword}
                    maxRows={12}
                    label={labelFields.oldPassword}
                    notched={true}
                    isRequired
                    variant={"input"}
                    id={"oldPassword"}
                    styleContainer={{
                      marginBottom: `${16}px`,
                    }}
                    endAdornment={
                      showOldPassword ? (
                        <VisibilityOffIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowOldPassword(false);
                          }}
                        />
                      ) : (
                        <VisibilityIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowOldPassword(true);
                          }}
                        />
                      )
                    }
                  />

                  <FieldContainer
                    fullWidth
                    name="newPassword"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showNewPassword ? "text" : "password"}
                    inputProps={{ minLength: 8, maxLength: 255 }}
                    value={formik.values.newPassword}
                    placeholder={labelFields.newPassword}
                    maxRows={12}
                    label={labelFields.newPassword}
                    notched={true}
                    isRequired
                    variant={"input"}
                    id={"newPassword"}
                    styleContainer={{
                      marginBottom: `${16}px`,
                    }}
                    endAdornment={
                      showNewPassword ? (
                        <VisibilityOffIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowNewPassword(false);
                          }}
                        />
                      ) : (
                        <VisibilityIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowNewPassword(true);
                          }}
                        />
                      )
                    }
                  />
                  <FieldContainer
                    fullWidth
                    name="confirmPassword"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showConfirmPassword ? "text" : "password"}
                    inputProps={{ minLength: 8, maxLength: 255 }}
                    value={formik.values.confirmPassword}
                    placeholder={"Nhập lại mật khẩu mới"}
                    maxRows={12}
                    label={"Nhập lại mật khẩu mới"}
                    notched={true}
                    isRequired
                    variant={"input"}
                    id={"confirmPassword"}
                    endAdornment={
                      showConfirmPassword ? (
                        <VisibilityOffIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowConfirmPassword(false);
                          }}
                        />
                      ) : (
                        <VisibilityIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowConfirmPassword(true);
                          }}
                        />
                      )
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6} alignSelf="center">
                  <Typography fontSize={"1rem"} fontWeight={600}>
                    Quy định về mật khẩu
                  </Typography>
                  <ol style={{ paddingLeft: "16px" }}>
                    <li>Tối thiểu 8 kí tự</li>
                    <li>
                      Chứa các kí tự thuộc tất cả các nhóm:
                      <ol type="a" style={{ paddingLeft: 0, color: "rgba(33, 43, 53, 0.7)" }}>
                        <li>Ký tự tiếng Anh viết hoa (A-Z)</li>
                        <li>Ký tự tiếng Anh viết thường (a-z)</li>
                        <li>Ký tự số (0-9)</li>
                        <li>Ký tự đặc biệt (!~*@^&)</li>
                      </ol>
                    </li>
                  </ol>
                </Grid>

                <Grid item xs={12} justifyContent="center">
                  <Stack direction={{ xs: "column", md: "row" }} spacing={spacing_size} justifyContent="end">
                    {showIconClose && (
                      <Button
                        variant="contained"
                        color="cancel"
                        type="button"
                        onMouseDown={() => {
                          setShowConfirmPassword(false);
                          setShowNewPassword(false);
                          setShowOldPassword(false);
                          formik.resetForm();
                        }}
                      >
                        Huỷ
                      </Button>
                    )}

                    <Button variant="contained" color="primary" type="submit">
                      Lưu
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </ModalContainer>
  );
};

export default ChangePWModal;
