import { Box, Button } from "@mui/material";
import React, { FC } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "../../../../../components/icons/ArrowBackIcon";
import FormUpdateDocInInternational from "./FormUpdateDocInInternational";

interface UpdateDocInInternationalProps {}

const UpdateDocInInternational: FC<UpdateDocInInternationalProps> = (props) => {
  const navigate = useNavigate();
  const params: Readonly<Params<string>> = useParams();

  if (isNaN(Number(params?.id))) {
    navigate("/404");
  }

  const handleClickButtonBack = () => {
    navigate(`/doc-in/${params?.id}`);
  };
  return (
    <>
      <Box sx={{ padding: "15px" }}>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          startIcon={<ArrowBackIcon />}
          onClick={handleClickButtonBack}
        >
          Quay lại
        </Button>
        <FormUpdateDocInInternational />
      </Box>
    </>
  );
};

export default UpdateDocInInternational;
