import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import React, { FC, useEffect } from "react";
import BoxContainer from "../../../../components/common/BoxContainer";
import PaginationPage from "../../../../components/common/Pagination";
import ReadMore from "../../../../components/common/ReadMore";
import { VIEW_DATE_TIME_FORMAT } from "../../../../constants/date-time.constant";
import { SIZE_DEFAULT } from "../../../../constants/page.constant";
import { HistoryDocInModel } from "../../../../model/doc-in.model";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { header_table_color } from "../../../../themes/color";
import { getHistoryDocInByIdAsync, setPageDocInHistory } from "../redux/doc-in.reducer";
import ReadmoreUppercase from "../../../../components/common/ReadmoreUppercase";

interface ResultHistoryDocInProps {
  id: number;
}

const ResultHistoryDocIn: FC<ResultHistoryDocInProps> = (props) => {
  const { id } = props;

  const dispatch = useAppDispatch();

  const page = useAppSelector((state) => state.docIn.pageDocInHistory);
  const total = useAppSelector((state) => state.docIn.totalDocInHistory);
  const historyDocInByIdDetail = useAppSelector((state) => state.docIn.docInHistoryDetailById);

  const getHistoryDocInById = async () => {
    const pageable = { id, page, size: SIZE_DEFAULT };
    dispatch(getHistoryDocInByIdAsync({ pageable }));
  };

  useEffect(() => {
    getHistoryDocInById();
  }, []);

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setPageDocInHistory(page - 1));
  };

  return (
    <>
      <Box sx={{ marginTop: "2rem" }}>
        <BoxContainer>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: header_table_color }}>
                  <TableCell sx={{ fontWeight: 600 }}>Hành động</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Người thực hiện</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Thời gian thực hiện</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Ý kiến</TableCell>
                  {/* <TableCell sx={{ fontWeight: 600 }}>Thời hạn xử lý</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {historyDocInByIdDetail?.map((item: HistoryDocInModel, index: number) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                    }}
                    key={index}
                  >
                    <TableCell>{item?.actionName}</TableCell>
                    <TableCell>{item?.pic}</TableCell>
                    <TableCell>{moment(item?.createdDate).format(VIEW_DATE_TIME_FORMAT)}</TableCell>
                    <TableCell sx={{ width: "50%" }}>
                      <ReadmoreUppercase text={item?.comment} />
                    </TableCell>
                    {/* <TableCell>
                      {item?.deadline ? moment(item?.deadline).format(VIEW_DATE_TIME_FORMAT) : null}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationPage total={total} page={page} onChange={handleChangePage} />
        </BoxContainer>
      </Box>
    </>
  );
};

export default ResultHistoryDocIn;
