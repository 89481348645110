import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getDocInByIdApi } from "../../../../api/doc-in.api";
import BoxContainer from "../../../../components/common/BoxContainer";
import ReadMore from "../../../../components/common/ReadMore";
import { DocInModel } from "../../../../model/doc-in.model";
import TableRowDetail from "../../../../components/common/TableRowDetail";
import { VIEW_DATE_FORMAT } from "../../../../constants/date-time.constant";

interface FormHistoryDocInProps {
  id: number;
}

const FormHistoryDocIn: FC<FormHistoryDocInProps> = (props) => {
  const { id } = props;
  const location = useLocation();
  const [docInDetailById, setDocInDetailById] = useState<DocInModel>();

  const getDocInById = async () => {
    await getDocInByIdApi(id)
      .then((res) => {
        setDocInDetailById(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDocInById();
  }, []);

  return (
    <>
      <Box>
        <BoxContainer>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
            <Grid item xs={12} md={6} lg={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRowDetail
                      title={"Số và ký hiệu văn bản:"}
                      fontWeightTitle="500"
                      content={
                        <Link
                          to={`/doc-in/${id}`}
                          state={{
                            bodyFilter: location?.state?.bodyFilter,
                            docYear: location?.state?.docYear,
                          }}
                          style={{ textDecoration: "none", color: "#0084FF" }}
                        >
                          {docInDetailById?.code}
                        </Link>
                      }
                    />
                    <TableRowDetail
                      title={"Nơi gửi văn bản:"}
                      fontWeightTitle="500"
                      content={docInDetailById?.fromPlace}
                    />
                    <TableRowDetail
                      title={"Ngày tháng văn bản:"}
                      fontWeightTitle="500"
                      content={moment(docInDetailById?.docDate).add(7, 'hours').format(VIEW_DATE_FORMAT)}
                    />
                    <TableRowDetail
                      title={"Ngày văn bản đến:"}
                      fontWeightTitle="500"
                      content={moment(docInDetailById?.arrivalDate).add(7, 'hours').format(VIEW_DATE_FORMAT)}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRowDetail
                      title={"Số công văn:"}
                      fontWeightTitle="500"
                      content={docInDetailById?.docBook?.name}
                    />
                    <TableRowDetail title={"Số đến:"} fontWeightTitle="500" content={docInDetailById?.arrivalNbr} />
                    <TableRowDetail
                      title={"Trích yếu:"}
                      fontWeightTitle="500"
                      content={<ReadMore text={docInDetailById?.summary} />}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </BoxContainer>
      </Box>
    </>
  );
};

export default FormHistoryDocIn;
