import { Box, Button } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "../../../../../components/icons/ArrowBackIcon";
import FormCreateDocInInternational from "./FormCreateDocInInternational";

interface CreateDocInIntenationalProps {}

const CreateDocInIntenational: FC<CreateDocInIntenationalProps> = (props) => {
  const navigate = useNavigate();

  const handleClickButtonBack = () => {
    navigate("/doc-in");
  };

  return (
    <>
      <Box>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          startIcon={<ArrowBackIcon />}
          onClick={handleClickButtonBack}
        >
          Quay lại
        </Button>
        <FormCreateDocInInternational />
      </Box>
    </>
  );
};

export default CreateDocInIntenational;
