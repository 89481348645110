import React from "react";
import ModalContainer from "../../../components/modal/ModalContainer";
import { Button, Stack, Typography } from "@mui/material";
import { spacing_size } from "../../../themes/size";
import { ContractAddendumModel, ContractModel } from "../../../model/personnel.model";
import { isExistContractAddendumActive } from "../common/util/personnel.util";

interface ModalDeleteContractProps {
  open: boolean;
  onClose: () => void;
  handleDeleteItem: any;
  itemContract: ContractModel | null;
  valuesContractAddendum: any;
}

const ModalDeleteContract = (props: ModalDeleteContractProps) => {
  const { open, onClose, handleDeleteItem, itemContract, valuesContractAddendum } = props;

  const contractAddendumByCode = valuesContractAddendum?.filter(
    (itemFilter: ContractAddendumModel) => itemFilter?.contractCode === itemContract?.code
  );

  const handleCancel = () => {
    onClose();
  };
  const handleOnSubmit = () => {
    handleDeleteItem();
    onClose();
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title="Xác nhận"
      >
        {contractAddendumByCode?.length > 0 && isExistContractAddendumActive(contractAddendumByCode) ? (
          <Typography id="modal-modal-description">
            Hợp đồng đang tồn tại phụ lục hợp đồng còn hiệu lực. Bạn có chắc chắn muốn xoá?
          </Typography>
        ) : (
          <Typography id="modal-modal-description">Bạn có chắc chắn muốn xoá?</Typography>
        )}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={spacing_size}
          sx={{
            mt: 2,
            justifyContent: "end",
          }}
        >
          <Button type="button" variant="outlined" onClick={handleCancel}>
            Huỷ
          </Button>
          <Button type="button" variant="contained" onClick={handleOnSubmit}>
            Xác nhận
          </Button>
        </Stack>
      </ModalContainer>
    </>
  );
};

export default ModalDeleteContract;
