import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const LogoutIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 3a.5.5 0 1 0 0-1h-1a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h1a.5.5 0 1 0 0-1H3V3h.5Zm6.91 2.71L9 3.71a.5.5 0 1 0-.815.58l.86 1.21H5a.5.5 0 1 0 0 1h4l-.9 1.2a.5.5 0 1 0 .8.6l1.5-2a.5.5 0 0 0 .01-.59Z"
        fill="#2E3D5B"
      />
    </svg>
  );
};

export default LogoutIcon;
