export const removeCommaStr = (str: string) => {
  return str.replace(/,/g, "");
};

export const subStringFunc = (item: string | any) => {
  const originalString = item;
  const regexPattern = /_(.*)/;
  const match = originalString?.match(regexPattern);
  if (match) {
    const substringToKeep = match[1];
    return substringToKeep;
  } else {
    return "";
  }
};

export const convertTextAreaToString = (str: string, valueReturn = "") => {
  return str ? str.replace(/<\/?[^>]+(>|$)/g, "") : valueReturn;
};
