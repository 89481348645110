import { ALLOW_EXTENSION_FILE_DOC_OUT } from "../../../../../constants/doc-out.constant";
import { MAX_SIZE_FILE_MB } from "../../../../../constants/file.constant";
import { CategoryDocOuts } from "../../../../../enum/doc-out.enum";
import {
  convertFilesToArr,
  isAllowExtensionFile,
  isDuplicateFile,
  isDuplicateFileUploaded,
  isMaxSizeFile,
  isMinSizeFile,
} from "../../../../../utils/file.util";

export const changeFileDocOut = (e: any, files: File[], fileUploaded: any, size: number) => {
  const dataFiles: File[] = [];
  if (e.target.files) {
    const currentFiles = convertFilesToArr(e.target.files);
    if (files.length > 0) {
      dataFiles.push(...files);
    }
    for (let i = 0; i < currentFiles.length; i++) {
      if (dataFiles.length + (fileUploaded.length || 0) === size) {
        break;
      }
      const file = currentFiles[i];
      if (
        isAllowExtensionFile(file, ALLOW_EXTENSION_FILE_DOC_OUT) &&
        !isMinSizeFile(file, 0) &&
        !isMaxSizeFile(file, MAX_SIZE_FILE_MB) &&
        !isDuplicateFile(dataFiles, file) &&
        !isDuplicateFileUploaded(fileUploaded, file)
      ) {
        dataFiles.push(file);
      }
    }
    e.target.value = null;
    return dataFiles;
  } else {
    return dataFiles;
  }
};

export const docCategoryDocOut = (docBookCategory: string | undefined | null) => {
  for (const categotuDocOut of CategoryDocOuts) {
    if (categotuDocOut.code === docBookCategory) {
      return categotuDocOut.description;
    }
  }
  return null;
};
export const buildBodyCreate = () => {};

export const convertTZ = (date: any, tzString: any) => {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
};

export const convertToDate = (d: any) => {
  if (d) {
    if (typeof d == "string") {
      return convertTZ(d, "Asia/Ho_Chi_Minh");
    }
  }
  return d;
};
