import { FC, useEffect, useState } from "react";

// import module
import { Button, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import * as Yup from "yup";
import FileSaver from "file-saver";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

// import modules
import { exportDocInApi } from "../../../../api/doc-in.api";
import BoxContainer from "../../../../components/common/BoxContainer";
import FieldContainer from "../../../../components/form/FieldContainer";
import LabelField from "../../../../components/form/LabelField";
import SearchIcon from "../../../../components/icons/SearchIcon";
import { DOC_IN_MSG, FORM_MSG } from "../../../../constants/msg/msg.constant";
import { MAX_SIZE_EXPORT, SIZE_DEFAULT } from "../../../../constants/page.constant";
import { CategoryDocInsWithAll, StatusDocInsWithAll } from "../../../../enum/doc-in.enum";
import { RequestFilterDocInModel } from "../../../../model/doc-in.model";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { filterDocInApiAsync, setPageDocIn } from "../redux/doc-in.reducer";
import { spacing_size } from "../../../../themes/size";
import { getDocBookApi } from "../../../../api/doc-book.api";
import { CodeDocBookModel, DocBook } from "../../../../model/doc-book.model";
import { DOC_BOOK_ENUM } from "../../../../enum/doc-book.enum";
import { ALL_ENUM } from "../../../../enum/common.enum";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { UPLOAD_DATE_FORMAT } from "../../../../constants/date-time.constant";
import { useLocation } from "react-router-dom";

interface FormFilterDocInProps {
  setBodyFilter: any;
  bodyFilter: any;
  initValue: any;
  setDocYear: any;
}

interface InitialValuesForm {
  code?: string | null | undefined;
  docBookId?: string | null | undefined | Date | any;
  docCategory?: string | null | undefined;
  summary?: string | null | undefined;
  status?: string | null | undefined;
  fromDate?: string | null | undefined;
  toDate?: string | null | undefined;
  fromPlace?: string | null | undefined;
}

const FormFilterDocIn: FC<FormFilterDocInProps> = (props) => {
  const { setBodyFilter, bodyFilter, initValue, setDocYear } = props;

  const initBodyFilter: InitialValuesForm = {
    code: null,
    docBookId: null,
    docCategory: null,
    summary: null,
    status: null,
    fromDate: null,
    toDate: null,
    fromPlace: null,
  };

  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentDate = new Date();
  const reloadFilterDocIn = useAppSelector((state) => state.docIn.reloadFilterDocIn);
  const page = useAppSelector((state) => state.docIn.pageDocIn);
  const [docBookDetail, setDocBookDetail] = useState<DocBook[]>([]);
  const [isInitFilter, setIsInitFilter] = useState(true);

  const loadDataFilter = (body = bodyFilter) => {
    const pageable = { page, size: SIZE_DEFAULT };
    dispatch(filterDocInApiAsync({ body, pageable }));
  };

  const getDocBook = async () => {
    const params: CodeDocBookModel = {
      code: `${DOC_BOOK_ENUM.DOC_IN_DOMESTIC},${DOC_BOOK_ENUM.DOC_IN_INTERNATIONAL}`,
    };

    await getDocBookApi(params).then((res) => {
      setDocBookDetail(res.data);
    });
  };

  useEffect(() => {
    getDocBook();
  }, []);

  useEffect(() => {
    if (reloadFilterDocIn !== 0) {
      loadDataFilter();
    }
  }, [reloadFilterDocIn]);

  useEffect(() => {
    if (isInitFilter) {
      setIsInitFilter(false);
      if (page > 0) {
        dispatch(setPageDocIn(0));
      } else {
        const stateLocation = location?.state;
        const body = {
          code: stateLocation?.bodyFilter?.code || null,
          docBookId: stateLocation?.bodyFilter?.docBookId || null,
          docCategory: stateLocation?.bodyFilter?.docCategory || null,
          summary: stateLocation?.bodyFilter?.summary || null,
          status: stateLocation?.bodyFilter?.status || null,
          fromDate: stateLocation?.bodyFilter?.fromDate || null,
          toDate: stateLocation?.bodyFilter?.toDate || null,
          fromPlace: stateLocation?.bodyFilter?.fromPlace || null,
        };
        loadDataFilter(body);
      }
    } else {
      loadDataFilter();
    }
  }, [page]);

  const validationSchema = Yup.object({
    fromDate: Yup.date()
      .nullable()
      .when("toDate", (toDate: any, schema: any) => {
        if (toDate) {
          return schema.max(toDate, FORM_MSG.fromDateMoreToDate);
        }
        return schema;
      }),
    toDate: Yup.date().nullable(),
  });

  const buildBodyFilter = (value: InitialValuesForm): RequestFilterDocInModel => {
    setDocYear(value?.docBookId);
    const convertFromDate = value?.fromDate ? moment(value?.fromDate).format(UPLOAD_DATE_FORMAT) : null;
    const convertToDate = value?.toDate ? moment(value?.toDate).format(UPLOAD_DATE_FORMAT) : null;
    const newValues = {
      ...value,
      code: value?.code || null,
      docBookId: value?.docBookId?.id || null,
      docCategory: ALL_ENUM === value?.docCategory ? null : value?.docCategory,
      status: value?.status === ALL_ENUM ? null : value?.status,
      summary: value?.summary || null,
      fromDate: convertFromDate,
      toDate: convertToDate,
      fromPlace: value?.fromPlace || null,
    };

    return newValues;
  };

  const handleOnSubmit = (value: InitialValuesForm, formikHelper: FormikHelpers<InitialValuesForm>) => {
    const body = buildBodyFilter(value);
    setBodyFilter(body);
    if (page === 0) {
      const pageable = {
        page,
        size: SIZE_DEFAULT,
      };
      dispatch(filterDocInApiAsync({ body, pageable }));
    } else {
      dispatch(setPageDocIn(0));
    }
  };

  const handleExport = async (formik: FormikProps<InitialValuesForm>) => {
    const body = buildBodyFilter(formik.values);
    const pageable = {
      page,
      size: MAX_SIZE_EXPORT,
    };
    try {
      const res = await exportDocInApi(body, pageable);
      FileSaver.saveAs(res?.data, `danh-sach-cong-van-den-${currentDate.getDate()}.xlsx`);
      toast.success(DOC_IN_MSG.exportListSuccess);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const handleCancel = (formik: FormikProps<InitialValuesForm>) => {
    formik.resetForm();
    setBodyFilter(initBodyFilter);
    if (page === 0) {
      loadDataFilter(initBodyFilter);
    } else {
      dispatch(setPageDocIn(0));
    }
  };

  const handleChangeDocYearDocBook = (formik: FormikHelpers<InitialValuesForm>) => (e: DocBook | null) => {
    formik.setFieldValue("docBookId", e);
  };

  return (
    <BoxContainer>
      <Formik
        initialValues={initValue}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <Form>
              {/* <pre>{JSON.stringify(formik, undefined, 2)}</pre> */}
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    // format code
                    onChange={formik.handleChange}
                    name="docCategory"
                    label="Loại CV"
                    options={CategoryDocInsWithAll}
                    labelOption="description"
                    valueOption="code"
                    variant={"select"}
                    id={"docCategory"}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    label="Sổ công văn"
                    name="docBookId"
                    onBlur={formik.handleBlur}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.docBookId}
                    notched={true}
                    placeholder={FORM_MSG.placeholder}
                    getOptionLabel={(option: DocBook) => `${option.name}`}
                    getOptionValue={(option: DocBook) => option.id}
                    variant={"react-select"}
                    id={"docBookId"}
                    onChange={handleChangeDocYearDocBook(formik)}
                    options={docBookDetail}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <LabelField label={"Ngày văn bản đến"} name="" />
                  <Stack direction={"row"} spacing={1} alignItems="center">
                    <Grid item xs={12} md={6} lg={6}>
                      <FieldContainer
                        name="fromDate"
                        onBlur={formik.handleBlur}
                        value={formik.values.fromDate}
                        notched={true}
                        variant={"date"}
                        id={"fromDate"}
                        placeholderText="Từ ngày"
                        maxDate={currentDate}
                      />
                    </Grid>
                    <HorizontalRuleIcon fontSize="small" />
                    <Grid item xs={12} md={6} lg={6}>
                      <FieldContainer
                        name="toDate"
                        onBlur={formik.handleBlur}
                        value={formik.values.toDate}
                        notched={true}
                        variant={"date"}
                        id={"toDate"}
                        placeholderText="Đến ngày"
                        maxDate={currentDate}
                      />
                    </Grid>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    label="Nơi ban hành văn bản"
                    name="fromPlace"
                    onBlur={formik.handleBlur}
                    type="text"
                    inputProps={{ maxLength: 500 }}
                    value={formik.values.fromPlace}
                    notched={true}
                    placeholder={FORM_MSG.placeholder}
                    variant={"input"}
                    id={"fromPlace"}
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    name="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.code}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Số và kí hiệu văn bản"
                    notched={true}
                    variant={"input"}
                    id={"code"}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    label="Trích yếu"
                    name="summary"
                    onBlur={formik.handleBlur}
                    type="text"
                    inputProps={{ maxLength: 500 }}
                    value={formik.values.summary}
                    notched={true}
                    placeholder={FORM_MSG.placeholder}
                    variant={"input"}
                    id={"summary"}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    // format code
                    name="status"
                    label="Trạng thái"
                    options={StatusDocInsWithAll}
                    labelOption="description"
                    valueOption="code"
                    variant={"select"}
                    id={"status"}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button variant="contained" color="cancel" type="button" onMouseDown={() => handleCancel(formik)}>
                      Huỷ
                    </Button>
                    <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                      Tìm kiếm
                    </Button>
                    <Button variant="outlined" color="primary" type="button" onMouseDown={() => handleExport(formik)}>
                      Xuất Excel
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </BoxContainer>
  );
};

export default FormFilterDocIn;
