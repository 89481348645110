import React, { useEffect, useState } from "react";
import { SIZE_DEFAULT } from "../../../constants/page.constant";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { filterPositionApiAsync } from "../user/redux/user.slice";
import FormSearchPosition from "./FormSearchPosition";
import HeaderPosition from "./HeaderPosition";
import ResultPosition from "./ResultPosition";
interface InitialValuesForm {
  name: string | null;
}

function Position() {
  const dispatch = useAppDispatch();
  const page = useAppSelector((state) => state.user.pagePosition);
  const initialValues: InitialValuesForm = {
    name: null,
  };
  const [initValue, setInitValue] = useState<InitialValuesForm>(initialValues);

  const buildDataFilter = (values: any) => {
    const body = values;
    const pageable = { page, size: SIZE_DEFAULT };
    return {
      body,
      pageable,
    };
  };

  const getDataPosition = async (body = initValue) => {
    await dispatch(filterPositionApiAsync(buildDataFilter(body)));
  };

  useEffect(() => {
    getDataPosition(initValue);
  }, [page]);
  return (
    <>
      <HeaderPosition buildDataFilter={buildDataFilter} initValue={initValue} />
      <FormSearchPosition setInitValue={setInitValue} initValue={initValue} buildDataFilter={buildDataFilter} />
      <ResultPosition buildDataFilter={buildDataFilter} initValue={initValue} />
    </>
  );
}

export default Position;
