import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import BoxContainer from "../../../components/common/BoxContainer";
import PaginationPage from "../../../components/common/Pagination";
import { SIZE_DEFAULT } from "../../../constants/page.constant";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { header_table_color } from "../../../themes/color";
import { getAllAccessRightsApiAsync } from "../user/redux/user.slice";

const ResultRole = () => {
  const allAccessRights = useAppSelector((state) => state.user.accessRights) || [];
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getAllAccessRightsApiAsync());
  }, []);

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  return (
    <BoxContainer>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: header_table_color }}>
              <TableCell align="center" sx={{ fontWeight: 600 }}>
                STT
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Nhóm quyền</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allAccessRights.slice((page - 1) * SIZE_DEFAULT, page * SIZE_DEFAULT).map((item, index: number) => (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                }}
                key={index}
              >
                <TableCell align="center" sx={{ width: "10%" }}>
                  {index + 1}
                </TableCell>
                <TableCell>{item?.text}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationPage total={allAccessRights?.length || 0} page={page - 1} onChange={handleChangePage} />
    </BoxContainer>
  );
};

export default ResultRole;
