import { AllItem, EnumCommon } from "./common.enum";

export enum CATEGORY_DOC_OUT_ENUM {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export const CategoryDocOuts: EnumCommon<CATEGORY_DOC_OUT_ENUM>[] = [
  { code: CATEGORY_DOC_OUT_ENUM.EXTERNAL, description: "Bên ngoài" },
  { code: CATEGORY_DOC_OUT_ENUM.INTERNAL, description: "Nội bộ" },
];

export enum INTERNAL_DOC_OUT_ENUM {
  DOC_OUT = "DOC_OUT",
  SUBMISSION = "SUBMISSION",
  DECISION = "DECISION",
}

// phiếu trình
export enum SUBMISSION_DOC_OUT_ENUM {
  GENERAL_SECRETARY = "GENERAL_SECRETARY", //Phiếu trình Ban Tổng thư ký
  STANDING_BCH = "STANDING_BCH", // Thường trực BCH
  FUNCTIONAL_DEPARTMENTS = "FUNCTIONAL_DEPARTMENTS", // Các Ban chức năng
}

// quyết định, nghị quyết
export enum DECISION_DOC_OUT_ENUM {
  GENERAL_SECRETARY = "GENERAL_SECRETARY", //Phiếu trình Ban Tổng thư ký
  STANDING_BCH = "STANDING_BCH", // Thường trực BCH
  FUNCTIONAL_DEPARTMENTS = "FUNCTIONAL_DEPARTMENTS", // Các Ban chức năng
}

export const InternalDocOuts: EnumCommon<INTERNAL_DOC_OUT_ENUM>[] = [
  {
    code: INTERNAL_DOC_OUT_ENUM.DOC_OUT,
    description: "Công văn đi",
  },
  {
    code: INTERNAL_DOC_OUT_ENUM.SUBMISSION,
    description: "Phiếu trình",
  },
  {
    code: INTERNAL_DOC_OUT_ENUM.DECISION,
    description: "Quyết định, nghị quyết",
  },
];

// phiếu trình
export const SubmissionDocOuts: EnumCommon<SUBMISSION_DOC_OUT_ENUM>[] = [
  {
    code: SUBMISSION_DOC_OUT_ENUM.GENERAL_SECRETARY,
    description: "Ban Tổng thư ký",
  },
  {
    code: SUBMISSION_DOC_OUT_ENUM.STANDING_BCH,
    description: "Thường trực BCH, BCH",
  },
  {
    code: SUBMISSION_DOC_OUT_ENUM.FUNCTIONAL_DEPARTMENTS,
    description: "Các ban chức năng",
  },
];

// quyết định, nghị quyết
export const DecisionDocOuts: EnumCommon<DECISION_DOC_OUT_ENUM>[] = [
  {
    code: DECISION_DOC_OUT_ENUM.GENERAL_SECRETARY,
    description: "Ban Tổng thư ký",
  },
  {
    code: DECISION_DOC_OUT_ENUM.STANDING_BCH,
    description: "Thường trực BCH, BCH",
  },
  {
    code: DECISION_DOC_OUT_ENUM.FUNCTIONAL_DEPARTMENTS,
    description: "Các Ban chức năng",
  },
];

export enum CATEGORY_DOC_OUT_FILTER_ENUM {
  // BEN_NGOAI = "EXTERNAL",
  // NOI_BO = "INTERNAL",
  // NOI_BO_PHIEU_TRINH_BCH = "NOI_BO_PHIEU_TRINH_BCH",
  // NOI_BO_PHIEU_TRINH_TTK = "NOI_BO_PHIEU_TRINH_TTK",
  // NOI_BO_QUYET_DINH_BCH = "NOI_BO_QUYET_DINH_BCH",
  // NOI_BO_QUYET_DINH_TTK = "NOI_BO_QUYET_DINH_TTK",
  EXTERNAL = "EXTERNAL", //"Bên ngoài
  INTERNAL_DOC_OUT = "INTERNAL_DOC_OUT", //("Nội bộ - Công văn đi"),
  INTERNAL_SUBMISSION_GENERAL_SECRETARY = "INTERNAL_SUBMISSION_GENERAL_SECRETARY", //Nội bộ - Phiếu trình Ban Tổng thư ký
  INTERNAL_SUBMISSION_STANDING_BCH = "INTERNAL_SUBMISSION_STANDING_BCH", //Nội bộ - Phiếu trình Thường trực BCH, BCH
  // INTERNAL_SUBMISSION_PRESIDENT_AND_VICE_PRESIDENTS = "INTERNAL_SUBMISSION_PRESIDENT_AND_VICE_PRESIDENTS", //Nội bộ - Phiếu trình Chủ tịch và các Phó Chủ tịch
  INTERNAL_SUBMISSION_FUNCTIONAL_DEPARTMENTS = "INTERNAL_SUBMISSION_FUNCTIONAL_DEPARTMENTS", //Nội bộ - Phiếu trình các Ban chức năng
  INTERNAL_DECISION_AND_RESOLUTION_GENERAL_SECRETARY = "INTERNAL_DECISION_AND_RESOLUTION_GENERAL_SECRETARY", //Nội bộ - Quyết định, nghị quyết Ban Tổng thư ký
  INTERNAL_DECISION_AND_RESOLUTION_STANDING_BCH = "INTERNAL_DECISION_AND_RESOLUTION_STANDING_BCH", //Nội bộ - Quyết định Thường trực BCH, BCH
  // INTERNAL_DECISION_PRESIDENT_AND_VICE_PRESIDENTS = "INTERNAL_DECISION_PRESIDENT_AND_VICE_PRESIDENTS", //Nội bộ - Quyết định Chủ tịch và các Phó Chủ tịch
  INTERNAL_DECISION_AND_RESOLUTION_FUNCTIONAL_DEPARTMENTS = "INTERNAL_DECISION_AND_RESOLUTION_FUNCTIONAL_DEPARTMENTS", //Nội bộ - Quyết định của các Ban chức năng
}

export const FilterCategoryDocOuts: EnumCommon<CATEGORY_DOC_OUT_FILTER_ENUM>[] = [
  {
    code: CATEGORY_DOC_OUT_FILTER_ENUM.EXTERNAL,
    description: "Bên ngoài",
  },

  {
    code: CATEGORY_DOC_OUT_FILTER_ENUM.INTERNAL_DOC_OUT,
    description: "Nội bộ - Công văn đi",
  },

  {
    code: CATEGORY_DOC_OUT_FILTER_ENUM.INTERNAL_SUBMISSION_GENERAL_SECRETARY,
    description: "Nội bộ - Phiếu trình - Ban Tổng thư ký",
  },

  {
    code: CATEGORY_DOC_OUT_FILTER_ENUM.INTERNAL_SUBMISSION_STANDING_BCH,
    description: "Nội bộ - Phiếu trình - Thường trực BCH, BCH",
  },

  {
    code: CATEGORY_DOC_OUT_FILTER_ENUM.INTERNAL_SUBMISSION_FUNCTIONAL_DEPARTMENTS,
    description: "Nội bộ - Phiếu trình - Các Ban chức năng",
  },

  {
    code: CATEGORY_DOC_OUT_FILTER_ENUM.INTERNAL_DECISION_AND_RESOLUTION_GENERAL_SECRETARY,
    description: "Nội bộ - Quyết định, nghị quyết - Ban Tổng thư ký",
  },

  {
    code: CATEGORY_DOC_OUT_FILTER_ENUM.INTERNAL_DECISION_AND_RESOLUTION_STANDING_BCH,
    description: "Nội bộ - Quyết định, nghị quyết - Thường trực BCH, BCH",
  },

  {
    code: CATEGORY_DOC_OUT_FILTER_ENUM.INTERNAL_DECISION_AND_RESOLUTION_FUNCTIONAL_DEPARTMENTS,
    description: "Nội bộ - Quyết định, nghị quyết - Các Ban chức năng",
  },
];

export const CategoryDocOutOptions = [AllItem, ...FilterCategoryDocOuts];
