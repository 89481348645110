import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { filterEmployeeApi, getNationality, hrDepartmentApi, hrPositionApi } from "../../../api/hr.api";
import { TOTAL_HEADER } from "../../../constants/page.constant";
import { Pageable } from "../../../model/page.model";
import {
  EmployeeDetailModel,
  FilterEmployeeModel,
  HrDepartmentModel,
  HrPositionModel,
  NationalityModel,
} from "../../../model/personnel.model";
import { getErrorMsgAxios } from "../../../utils/error.util";

interface PersonnelReducerState {
  pagePersonnel: number;
  personnelDetail: EmployeeDetailModel[] | null;
  totalPersonnelDetail: number;
  reloadFilterPersonnel: number;
  filterPersonnel: FilterEmployeeModel | null;
  allNationality: NationalityModel[];
  allActivePosition: HrPositionModel[];
  allActiveDepartment: HrDepartmentModel[];
}

const initialState: PersonnelReducerState = {
  pagePersonnel: 0,
  totalPersonnelDetail: 0,
  personnelDetail: null,
  reloadFilterPersonnel: 0,
  filterPersonnel: {
    keyword: null,
    phone: null,
    position: null,
    department: null,
    startDate: null,
    endDate: null,
    status: null,
    type: null,
    taxCode: null,
    identityCard: null,
    passport: null,
  },
  allNationality: [],
  allActivePosition: [],
  allActiveDepartment: [],
};

export const filterEmployeeApiAsync = createAsyncThunk(
  "employee/filter",
  async (data: { body: FilterEmployeeModel | null; pageable: Pageable }) => {
    try {
      const res = await filterEmployeeApi(data.body, data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      const msg = getErrorMsgAxios(error);
      toast.error(msg);
    }
  }
);

export const getAllNationalityApiAsync = createAsyncThunk("employee/getAllNationality", async () => {
  try {
    const res = await getNationality();
    return res.data;
  } catch (error: any) {
    throw Error(error);
  }
});

export const getAllPositionApiAsync = createAsyncThunk(
  "employee/getAllPosition",
  async (data: { body: HrPositionModel | null; pageable: Pageable }) => {
    try {
      const res = await hrPositionApi(data.body, data.pageable);
      return res.data;
    } catch (error: any) {
      throw Error(error);
    }
  }
);

export const getAllDepartmentApiAsync = createAsyncThunk(
  "employee/getAllDepartment",
  async (data: { body: HrDepartmentModel | null; pageable: Pageable }) => {
    try {
      const res = await hrDepartmentApi(data.body, data.pageable);
      return res.data;
    } catch (error: any) {
      throw Error(error);
    }
  }
);

const PersonnelReducer = createSlice({
  name: "PersonnelReducer",
  initialState,
  reducers: {
    setPagePersonnel(state, action) {
      return {
        ...state,
        pagePersonnel: action.payload,
      };
    },
    setReloadFilterPersonnel(state, action) {
      return {
        ...state,
        reloadFilterPersonnel: action.payload,
      };
    },
    setFilterPersonnel(state, action) {
      return {
        ...state,
        filterPersonnel: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllNationalityApiAsync.fulfilled, (state, action) => {
        return {
          ...state,
          allNationality: action.payload,
        };
      })
      .addCase(getAllPositionApiAsync.fulfilled, (state, action) => {
        return {
          ...state,
          allActivePosition: action.payload,
        };
      })
      .addCase(getAllDepartmentApiAsync.fulfilled, (state, action) => {
        return {
          ...state,
          allActiveDepartment: action.payload,
        };
      })
      .addCase(filterEmployeeApiAsync.fulfilled, (state, action) => {
        return {
          ...state,
          totalPersonnelDetail: action.payload?.total || 0,
          personnelDetail: action.payload?.data,
        };
      });
  },
});

export const { setPagePersonnel, setFilterPersonnel, setReloadFilterPersonnel } = PersonnelReducer.actions;
export default PersonnelReducer.reducer;
