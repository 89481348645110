import HTTP_HR_SERVICE from "../../config/axios/axios-hr.config";
import { DepartmentHR } from "../../model/department.model";

export const getListDepartmentFromHr = (keyword: string, pageNumber = 0, pageSize = 1000000, status = true) => {
  return HTTP_HR_SERVICE.get<DepartmentHR[]>("/department/query", {
    params: { keyword, pageNumber: 0, pageSize, status },
  });
};

export const filterDepartmentByCode = (code: string) => {
  return HTTP_HR_SERVICE.get<DepartmentHR[]>("/department/filter-by-code", {
    params: {code}
  })
}
