import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import BoxContainer from "../../../../components/common/BoxContainer";
import PaginationPage from "../../../../components/common/Pagination";
import { ResponseFilterDocInModel } from "../../../../model/doc-in.model";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import moment from "moment";
import ChipStatusDocIn from "../common/ChipStatusDocIn";
import { setPageDocIn } from "../redux/doc-in.reducer";
import { STATUS_DOC_IN_ENUM } from "../../../../enum/doc-in.enum";
import { VIEW_DATE_FORMAT, VIEW_TIME_FORMAT } from "../../../../constants/date-time.constant";
import { header_table_color } from "../../../../themes/color";
import NotFoundFilterView from "../../../../components/common/NotFoundFilterView";
import ReadMore from "../../../../components/common/ReadMore";
import OutOfDateDocInIcon from "../common/OutOfDateDocInIcon";
import { nameCodeDocIn } from "../../../../utils/doc-in.util";

interface ViewFilterDocInProps {
  bodyFilter: any;
  docYear: any;
}

const ViewFilterDocIn: FC<ViewFilterDocInProps> = (props) => {
  const { bodyFilter, docYear } = props;
  const navigate = useNavigate();
  const currentDate: Date | number | string | any = new Date();
  const dispatch = useAppDispatch();
  const totalPage = useAppSelector((state) => state.docIn.totalDocInDetail);
  const page = useAppSelector((state) => state.docIn.pageDocIn);
  const docInDetail = useAppSelector((state) => state.docIn.docInDetail);
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setPageDocIn(page - 1));
  };

  return (
    <>
      {docInDetail && (
        <BoxContainer>
          {/* show result */}
          {docInDetail.length > 0 && (
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: header_table_color }}>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Loại CV</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Sổ công văn</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Ngày văn bản đến</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Số đến</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap", width: "15%" }}>
                        Nơi ban hành văn bản
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Số và ký hiệu văn bản</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Trích yếu</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Thời hạn xử lý theo văn bản</TableCell>
                      <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>Trạng thái</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {docInDetail?.map((item: ResponseFilterDocInModel, index: number) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                          cursor: "pointer",
                        }}
                        key={index}
                        onClick={() => {
                          navigate(`/doc-in/${item.id}`, { state: { bodyFilter: bodyFilter, docYear: docYear } });
                        }}
                      >
                        <TableCell>{nameCodeDocIn(item?.docCategory)}</TableCell>
                        <TableCell>{item.docBook?.name}</TableCell>
                        <TableCell>{moment(item?.arrivalDate).format(VIEW_DATE_FORMAT)}</TableCell>
                        <TableCell>{item?.arrivalNbr}</TableCell>
                        <TableCell>{item?.fromPlace}</TableCell>
                        <TableCell>
                          <Link
                            to={`/doc-in/${item.id}`}
                            state={{
                              bodyFilter: bodyFilter,
                              docYear: docYear,
                            }}
                            style={{ textDecoration: "none", color: "#0084FF" }}
                          >
                            {item?.code}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <ReadMore text={item?.summary} />
                        </TableCell>
                        <TableCell>
                          {item?.deadline && (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                {moment(item?.deadline).format(VIEW_DATE_FORMAT)}
                                {[
                                  STATUS_DOC_IN_ENUM.UNAPPROVED,
                                  STATUS_DOC_IN_ENUM.UNASSIGNED,
                                  STATUS_DOC_IN_ENUM.PENDING,
                                ].includes(item.status as STATUS_DOC_IN_ENUM) &&
                                  new Date(item?.deadline).getTime() < currentDate.getTime() && <OutOfDateDocInIcon />}
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: "0.8rem",
                                  color: "#8A8A8A",
                                }}
                              >
                                {moment(item?.deadline).format(VIEW_TIME_FORMAT)}
                              </Typography>
                            </>
                          )}
                          {!item?.deadline && "-"}
                        </TableCell>
                        <TableCell>
                          <ChipStatusDocIn status={item?.status} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationPage total={totalPage} page={page} onChange={handleChangePage} />
            </Box>
          )}

          {/* not found result */}
          {docInDetail.length === 0 && <NotFoundFilterView />}
        </BoxContainer>
      )}
    </>
  );
};

export default ViewFilterDocIn;
