import React, { FC, useRef, useState } from "react";
import * as Yup from "yup";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import ModalContainer from "../../../components/modal/ModalContainer";
import { spacing_size } from "../../../themes/size";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import FieldContainer from "../../../components/form/FieldContainer";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import { LevelModel, PersonnelModel, SelectFieldCommonPersonnelModel } from "../../../model/personnel.model";
import moment from "moment";
import { VIEW_YEAR_FORMAT } from "../../../constants/date-time.constant";
import { changeFileUploadPersonnel } from "../common/util/personnel.util";
import ChooseFile from "../../../components/icons/ChooseFile";
import ViewFile from "../../../components/common/ViewFile";
import TextError from "../../../components/form/TextError";
import { VALID_FORMAT_FILE } from "../../../constants/file.constant";
import { formatDateTime } from "../../../utils/data-time.util";
import { subStringFileName } from "../../../utils/file.util";

interface ModalUpdateLevelProps {
  open: boolean;
  onClose: () => void;
  valueUploads: PersonnelModel | null;
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  itemEducation: LevelModel | null;
  indexEducation: number;
  setEducationsDetail: React.Dispatch<React.SetStateAction<LevelModel[]>>;
  fileEducationsUpload: File[];
  setFileEducationsUpload: React.Dispatch<React.SetStateAction<File[]>>;
}

const ModalUpdateLevel: FC<ModalUpdateLevelProps> = (props) => {
  const {
    open,
    onClose,
    valueUploads,
    setValuesUploads,
    itemEducation,
    indexEducation,
    setEducationsDetail,
    fileEducationsUpload,
    setFileEducationsUpload,
  } = props;

  const initialValues: LevelModel = {
    name: itemEducation?.name ? itemEducation?.name : "",
    issuePlace: itemEducation?.issuePlace ? itemEducation?.issuePlace : "",
    rank: itemEducation?.rank ? itemEducation?.rank : "",
    issueYear: itemEducation?.issueYear ? new Date(itemEducation?.issueYear) : null,
    note: itemEducation?.note ? itemEducation?.note : "",
    fileName: itemEducation?.fileName
      ? itemEducation?.fileName
      : "" || itemEducation?.educationFile
      ? itemEducation?.educationFile?.name
      : "",
    fileTemp: itemEducation?.fileTemp,
  };

  const getFileEducation: any = fileEducationsUpload?.filter((item: File) => item?.name === itemEducation?.fileName);
  const currentTime = new Date().getTime();

  const [file, setFile] = useState<File[]>(getFileEducation);
  const [arrFile, setArrFile] = useState<File[] | any>(fileEducationsUpload);
  const fileRef = useRef<any>(null);

  const validationSchema = Yup.object({
    name: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Tên bằng cấp chứng chỉ")),
    // fileTemp: Yup.mixed()
    //   .required(FORM_MSG.requiredFieldUploadFile("Đính kèm"))
    //   .test("fileFormat", "Chỉ tải lên file xlsx, xls, doc, docx, pdf", (value) => {
    //     if (!value) {
    //       return false;
    //     }
    //     return VALID_FORMAT_FILE.includes(value.type);
    //   }),
  });

  const changeUploadFile = (formik: FormikHelpers<LevelModel>) => (e: any) => {
    e.preventDefault();
    formik.setFieldValue("fileTemp", e.target.files[0]);
    const fileUp = changeFileUploadPersonnel(e, file, "", 1);
    const newFileUp = fileUp?.map((item: File, index: number) => {
      const newNameItem = currentTime + "_" + item?.name;
      const newItem = new File([item], newNameItem, { type: item.type });
      return newItem;
    });
    setFile(newFileUp);
    setArrFile([...arrFile, ...newFileUp]);
  };

  const buildBody = (values: LevelModel) => {
    const newValues = {
      ...values,
      fileName: file[0]?.name,
      issueYear: formatDateTime(values?.issueYear, VIEW_YEAR_FORMAT),
    };
    return newValues;
  };

  const handleOnSubmit = async (values: LevelModel, formik: FormikHelpers<LevelModel>) => {
    const newValues = buildBody(values);
    const arrEducation: any = valueUploads?.educations;
    arrEducation[indexEducation] = newValues;
    setValuesUploads({ ...valueUploads, educations: [...arrEducation] });
    setEducationsDetail(arrEducation);
    setFileEducationsUpload(arrFile);
    onClose();
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Cập nhật"}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          // enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off">
              {/* {JSON.stringify(formik.values)} */}
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.name}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Tên chứng chỉ/ bằng cấp"
                    notched={true}
                    variant={"input"}
                    id={"name"}
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="issuePlace"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.issuePlace}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Tổ chức ghi nhận"
                    notched={true}
                    variant={"input"}
                    id={"issuePlace"}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="rank"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.rank}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Xếp loại"
                    notched={true}
                    variant={"input"}
                    id={"rank"}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    name="issueYear"
                    onBlur={formik.handleBlur}
                    value={formik.values.issueYear}
                    notched={true}
                    variant={"date"}
                    id={"issueYear"}
                    label="Năm cấp"
                    placeholderText={"YYYY"}
                    dateFormat="yyyy"
                    showYearPicker
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" alignItems="center" spacing={spacing_size}>
                    <Typography sx={{ fontSize: "1rem", fontWeight: "600", color: "#323E5D" }}>
                      Đính kèm{" "}
                      {/* <Box component="span" sx={{ color: "red" }}>
                        *
                      </Box> */}
                    </Typography>
                    <Button
                      variant="contained"
                      color="cancel"
                      // sx={{ display: "inline-block" }}
                      onClick={() => fileRef.current.click()}
                      disabled={file?.length < 1 ? false : true}
                      startIcon={<ChooseFile />}
                    >
                      Chọn file
                    </Button>
                  </Stack>
                  <Field
                    id="file-input"
                    name="fileTemp"
                    // accept="application/msword, application/vnd.ms-excel, application/pdf"
                    accept=".xlsx,.xls,.doc,.docx,.pdf"
                    type="file"
                    innerRef={fileRef}
                    style={{ display: "none" }}
                    value={""}
                    onChange={changeUploadFile(formik)}
                  />
                  {file.map((item, index) => {
                    const newItem = subStringFileName(item);
                    return (
                      <ViewFile
                        key={index}
                        item={newItem}
                        handleRemove={() => {
                          setFile(file.filter((file) => item !== file));
                          setArrFile(arrFile?.filter((file: File) => item !== file));
                          formik.setFieldValue("fileTemp", "");
                        }}
                      />
                    );
                  })}
                  <ErrorMessage name="fileTemp" component={TextError} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="note"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.note}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Ghi chú"
                    notched={true}
                    variant={"input"}
                    id={"note"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                      marginTop: "20px",
                    }}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Lưu
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalUpdateLevel;
