import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import BoxContainer from "../../../components/common/BoxContainer";
import NotFoundFilterView from "../../../components/common/NotFoundFilterView";
import PaginationPage from "../../../components/common/Pagination";
import { VIEW_DATE_FORMAT } from "../../../constants/date-time.constant";
import {
  EmployeeDetailModel,
  EmployeeTypeEnum,
  FilterEmployeeModel,
  HrDepartmentModel,
  HrPositionModel,
} from "../../../model/personnel.model";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
// import { useAppDispatch } from "../../../store/hook";
import { header_table_color } from "../../../themes/color";
import StatusEmployee from "../common/component/StatusEmployee";
import { OptionCommon } from "../common/constants/personnel.constant";
import { convertType } from "../common/util/personnel.util";
import { setPagePersonnel } from "../redux/personnel.reducer";

interface ResultPersonnelProps {
  bodyFilter: FilterEmployeeModel | null;
  department: HrDepartmentModel | null;
  position: HrPositionModel | null;
  type: OptionCommon | null;
  status: OptionCommon | null;
}

const ResultPersonnel: FC<ResultPersonnelProps> = (props) => {
  const { bodyFilter, department, position, type, status } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const totalPage = useAppSelector((state) => state.personnel.totalPersonnelDetail);
  const page = useAppSelector((state) => state.personnel.pagePersonnel);
  const data = useAppSelector((state) => state.personnel.personnelDetail);

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setPagePersonnel(page - 1));
  };

  const handleClickBtnUpdate = (id: number | undefined) => {
    navigate(`/personnel/update-personnel/${id}`, {
      state: {
        bodyFilter: bodyFilter,
        department: department,
        position: position,
        type: type,
        status: status,
      },
    });
  };

  return (
    <>
      {data && (
        <BoxContainer>
          {/* show result */}
          {data.length > 0 && (
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ width: "150%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: header_table_color }}>
                      <TableCell sx={{ fontWeight: 600 }} align="center">
                        Hành động
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Mã cá nhân</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Họ và tên</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Đối tượng</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Bộ phận công tác</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Chức vụ</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Số điện thoại</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>CMND/CCCD</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Hộ chiếu</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Mã số thuế cá nhân</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Thời gian bắt đầu công tác</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Trạng thái làm việc</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item: EmployeeDetailModel, index: number) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                        }}
                        key={index}
                      >
                        <TableCell align="center">
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            type="button"
                            onClick={() => handleClickBtnUpdate(item?.id)}
                          >
                            Cập nhật
                          </Button>
                        </TableCell>
                        <TableCell sx={{ width: "6%" }}>
                          {" "}
                          <Link
                            to={`/personnel/detail-personnel/${item?.id}`}
                            style={{ textDecoration: "none", color: "#0084FF" }}
                            state={{
                              bodyFilter: bodyFilter,
                              department: department,
                              position: position,
                              type: type,
                              status: status,
                            }}
                          >
                            {item.code}
                          </Link>
                        </TableCell>
                        <TableCell>{item.fullName}</TableCell>
                        <TableCell>{convertType(item?.type)}</TableCell>
                        <TableCell>{item.department}</TableCell>
                        <TableCell>{item.position}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.phoneNumber}</TableCell>
                        <TableCell>{item.identityCard}</TableCell>
                        <TableCell>{item.passport}</TableCell>
                        <TableCell>{item.taxCode}</TableCell>
                        <TableCell>{item.startDate ? moment(item.startDate).format(VIEW_DATE_FORMAT) : ""}</TableCell>
                        <TableCell>
                          <StatusEmployee status={item.status} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationPage total={totalPage} page={page} onChange={handleChangePage} />
            </Box>
          )}

          {/* not found result */}
          {data.length === 0 && <NotFoundFilterView />}
        </BoxContainer>
      )}
    </>
  );
};

export default ResultPersonnel;
