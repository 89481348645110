import React, { FC, useEffect, useState } from "react";
import { Params, useParams } from "react-router-dom";
import { getDetailPersonnelApi } from "../../../api/hr.api";
import HeaderPersonnelDetail from "./HeaderPersonnelDetail";
import StepperPersonnelDetail from "./StepperPersonnelDetail";

interface PersonnelDetailProps {}

const PersonnelDetail: FC<PersonnelDetailProps> = (props) => {
  const params: Readonly<Params<string>> = useParams();
  const [data, setData] = useState<any>();

  const getDetailById = async () => {
    await getDetailPersonnelApi(params?.id)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getDetailById();
  }, []);
  return (
    <>
      <HeaderPersonnelDetail data={data} />
      <StepperPersonnelDetail data={data} />
    </>
  );
};

export default PersonnelDetail;
