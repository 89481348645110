import { Button } from "@mui/material";
import React, { useState } from "react";
import HeaderPage from "../../../../components/common/HeaderPage";
import XCircleIcon from "../../../../components/icons/XCircleIcon";
import { ACCESS_RIGHT_ENUM } from "../../../../enum/access-right.enum";
import { useAppSelector } from "../../../../store/hook";
import { hasAccessRight } from "../../../../utils/auth.util";
import CreateDocBookModal from "../modal/CreateDocBookModal";

const HeaderFilterDocBook = () => {
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const handleCreate = () => {
    setOpenModalCreate(true);
  };
  return (
    <>
      {openModalCreate && <CreateDocBookModal open={openModalCreate} onClose={() => setOpenModalCreate(false)} />}
      <HeaderPage title="Danh sách sổ công văn">
        <>
          {hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN) && (
            <Button variant="contained" onClick={handleCreate} startIcon={<XCircleIcon />}>
              Thêm mới
            </Button>
          )}
        </>
      </HeaderPage>
    </>
  );
};

export default HeaderFilterDocBook;
