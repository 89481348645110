import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const DocInIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" stroke="#D4D9E4">
        <path
          d="M12.5 13.75H3.75A1.25 1.25 0 0 1 2.5 12.5V4.687L5.938 1.25h5.312A1.25 1.25 0 0 1 12.5 2.5V5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6 1.5v3a.5.5 0 0 1-.5.5H3" />
        <path d="M13.883 9H8.05m2.45 2L8 9l2.5-2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M15 0H0v15h15z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DocInIcon;
