import { Button, Grid, Stack, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FC, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { submitTaskApi } from "../../../api/task/task.api";
import ViewFile from "../../../components/common/ViewFile";
import FieldContainer from "../../../components/form/FieldContainer";
import ChooseFile from "../../../components/icons/ChooseFile";
import ModalContainer from "../../../components/modal/ModalContainer";
import { spacing_size } from "../../../themes/size";
import { convertDataToFormData } from "../../../utils/axios.ultil";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { changeFileUploadPersonnel } from "../../personnel/common/util/personnel.util";

interface ModalSendCheckProps {
  open: boolean;
  onClose: () => void;
  getDetailData: () => void;
  id: any;
}

const ModalSendCheck: FC<ModalSendCheckProps> = (props) => {
  const { open, onClose, id, getDetailData } = props;

  // const dispatch = useAppDispatch();
  const fileRef = useRef<HTMLButtonElement | HTMLInputElement | any>(null);
  const [file, setFile] = useState<File[]>([]);
  const [arrFile, setArrFile] = useState<File[] | any>([]);

  const initialValues = {
    note: "",
  };
  const validationSchema = Yup.object({});

  const changeUploadFile = (formik: any) => (e: any) => {
    e.preventDefault();
    formik.setFieldValue("fileTemp", e.target.files[0]);
    const fileUp = changeFileUploadPersonnel(e, file, "", 1);
    setFile(fileUp);
    setArrFile([...arrFile, ...fileUp]);
  };

  const handleOnSubmit = async (values: any) => {
    try {
      await submitTaskApi(Number(id), convertDataToFormData(arrFile, { note: values.note }, "dataFile", "data"));
      // dispatch(getByIdTaskApiAsync(Number(id)));
      getDetailData();
      onClose();
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title={"Xác nhận gửi kiểm tra"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <Typography sx={{ marginBottom: "20px" }}>
              Công việc gửi đến người giao việc để kiểm tra. Vui lòng đính kèm tài liệu (nếu có) và nhập ý kiến.
            </Typography>
            <Form autoComplete="off" noValidate style={{ padding: "0px 2px" }}>
              <Button
                variant="contained"
                color="cancel"
                // sx={{ display: "flex" }}
                onClick={() => fileRef.current.click()}
                startIcon={<ChooseFile />}
                sx={{ marginBottom: "20px" }}
              >
                Chọn file
              </Button>
              <Field
                id="file-input"
                name="fileTemp"
                accept=".xlsx,.xls,.doc,.docx,.pdf"
                type="file"
                innerRef={fileRef}
                style={{ display: "none" }}
                value={""}
                onChange={changeUploadFile(formik)}
              />
              {file.map((item, index) => (
                <ViewFile
                  key={index}
                  item={item}
                  handleRemove={() => {
                    setFile(file.filter((file) => item !== file));
                    formik.setFieldValue("fileTemp", "");
                  }}
                />
              ))}
              <Grid container spacing={spacing_size}>
                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="note"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: 255 }}
                    placeholder="Nhập ý kiến"
                    maxRows={12}
                    notched={true}
                    variant={"textarea"}
                    id={"note"}
                    label={"Ý kiến"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="cancel"
                      type="button"
                      onMouseDown={() => {
                        formik.resetForm();
                        onClose();
                      }}
                    >
                      Huỷ
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Xác nhận
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </ModalContainer>
  );
};

export default ModalSendCheck;
