import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const ChangePwIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m6.03 10.2 1.71-1.695L6.03 6.81l-.63.63.645.645a2.08 2.08 0 0 1-.817-.135 1.997 1.997 0 0 1-.713-.465c-.2-.2-.353-.43-.458-.69a1.977 1.977 0 0 1-.09-1.29c.046-.17.108-.335.188-.495l-.66-.66c-.17.25-.295.515-.375.795C3.04 5.425 3 5.71 3 6c0 .38.075.755.225 1.125.15.37.37.7.66.99.29.29.615.508.975.653.36.145.73.222 1.11.232l-.57.57.63.63Zm2.475-2.55c.17-.25.295-.515.375-.795a3.097 3.097 0 0 0-.097-1.988 2.857 2.857 0 0 0-.653-.997 2.796 2.796 0 0 0-.982-.645 3.099 3.099 0 0 0-1.118-.21l.57-.585-.63-.63-1.71 1.695L5.97 5.19l.63-.63-.66-.66c.27 0 .545.052.825.157.28.105.52.258.72.458.2.2.353.43.458.69a1.977 1.977 0 0 1 .09 1.29c-.046.17-.108.335-.188.495l.66.66ZM6 12a5.84 5.84 0 0 1-2.34-.473 6.055 6.055 0 0 1-1.905-1.282A6.055 6.055 0 0 1 .473 8.34 5.84 5.84 0 0 1 0 6c0-.83.158-1.61.473-2.34a6.055 6.055 0 0 1 1.282-1.905A6.063 6.063 0 0 1 3.66.472 5.846 5.846 0 0 1 6 0c.83 0 1.61.157 2.34.472a6.06 6.06 0 0 1 1.905 1.283c.54.54.967 1.175 1.282 1.905.315.73.473 1.51.473 2.34 0 .83-.158 1.61-.473 2.34a6.055 6.055 0 0 1-1.282 1.905c-.54.54-1.175.967-1.905 1.282A5.84 5.84 0 0 1 6 12Zm0-1.2c1.34 0 2.475-.465 3.405-1.395C10.335 8.475 10.8 7.34 10.8 6c0-1.34-.465-2.475-1.395-3.405C8.475 1.665 7.34 1.2 6 1.2c-1.34 0-2.475.465-3.405 1.395C1.665 3.525 1.2 4.66 1.2 6c0 1.34.465 2.475 1.395 3.405.93.93 2.065 1.395 3.405 1.395Z"
        fill="#2E3D5B"
      />
    </svg>
  );
};

export default ChangePwIcon;
