import { ErrorMessage, Field } from "formik";
import React, { FC } from "react";
import { FieldCommonProps } from "./FieldContainer";
import LabelField from "./LabelField";
import TextError from "./TextError";

interface TextareaFieldProps extends FieldCommonProps {
  options?: Object[];
  [key: string]: unknown;
}

const TextareaField: FC<TextareaFieldProps> = (props) => {
  const { label, name, tooltip, isRequired, id, options, ...rest } = props;

  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <Field component="textarea" id={name} name={name} className="form-control" rows="3" {...rest} />
      <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default TextareaField;
