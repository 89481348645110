import { Backdrop, CircularProgress, Theme } from "@mui/material";
import React, { FC } from "react";

interface SpinnerProps {
  open: boolean;
}

const Spinner: FC<SpinnerProps> = (props) => {
  const { open } = props;
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Spinner;
