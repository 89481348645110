// import modules
import { FC, useState } from "react";

// import modules
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";

// imports projects
import { SidebarModel } from "../../model/sidebar.model";
import { isActiveRouter } from "../../utils/router.util";

const ListItemSidebar = styled(ListItem)(() => ({
  "&:hover": {
    backgroundColor: " rgba(255, 255, 255, 0.275)",
  },
  "&": {
    color: "white",
    borderRadius: "1rem",
  },
  ".Mui-selected": {
    backgroundColor: " rgba(255, 255, 255, 0.275) !important",
    fontWeight: 600,
  },
}));

interface ItemSidebarProps extends SidebarModel {}

const ItemSidebar: FC<ItemSidebarProps> = (props) => {
  const [openChildren, setOpenChildren] = useState(false);
  const { pathname } = useLocation();

  const isActiveRouterChildren = (pathChildren: SidebarModel[]) => {
    if (pathChildren.length !== 0) {
      return pathChildren.some((item) => isActiveRouter(pathname, item.link, props.patternLinks));
    }
    return false;
  };

  const isActiveChildren =
    isActiveRouter(pathname, props.link, props.patternLinks) || isActiveRouterChildren(props.children || []);

  const handleClick = (pathname: string) => {
    if (props.link === pathname) {
      window.location.reload();
    }
  };

  return (
    <>
      <ListItemSidebar disablePadding>
        {/* item not children */}
        {!props.children && (
          <ListItemButton
            selected={isActiveChildren}
            component={Link}
            to={props.link}
            target={props.targetLink || "_self"}
            sx={{
              "&:hove": {
                background: "none",
              },
              borderRadius: "1rem",
            }}
            divider
            onClick={() => handleClick(pathname)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                color: "white",
                marginRight: "16px",
                ...props.sxIcon,
              }}
            >
              {props.icon && props.icon()}
            </ListItemIcon>
            <ListItemText
              primary={<Typography sx={{ display: "inline-block", ...props.sxText }}>{props.title}</Typography>}
            />
          </ListItemButton>
        )}

        {/* item with children */}
        {props.children && (
          <ListItemButton
            selected={isActiveChildren}
            onClick={() => setOpenChildren(!openChildren)}
            sx={{
              "&:hove": {
                background: "none",
              },
              borderRadius: "14px",
            }}
            divider
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                color: "white",
                marginRight: "16px",
                ...props.sxIcon,
              }}
            >
              {props.icon && props.icon()}
            </ListItemIcon>
            <ListItemText
              primary={<Typography sx={{ display: "inline-block", ...props.sxText }}>{props.title}</Typography>}
            />
            {openChildren ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}
      </ListItemSidebar>

      {/* show children */}
      {props.children && (
        <Collapse in={openChildren} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ marginLeft: 2 }}>
            {props.children.map((item) => (
              <ItemSidebar key={item.id} {...item} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default ItemSidebar;
