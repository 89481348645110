export const defaultTitle = "VFF - CONNECT";

export const DOC_IN_TITLE = {
  create: "Tạo công văn đến",
  filter: "Danh sách công văn đến",
  detail: "Chi tiết công văn đến",
};

export const DOC_OUT_TITLE = {
  create: "Tạo công văn đi",
  main: "Danh sách công văn đi",
  detail: "Chi tiết công văn đi",
};

export const USER_TITLE = {
  list: `Danh sách người dùng - ${defaultTitle}`,
};

export const REPORT_TITLE = {
  main: `Thống kê - ${defaultTitle}`,
};

export const DOC_BOOK_TITLE = {
  list: `Danh sách sổ công văn - ${defaultTitle}`,
};

export const ROLE_TITLE = {
  list: `Danh sách nhóm quyền - ${defaultTitle}`,
};

export const AUTH_TITLE = {
  login: `Đăng nhập - ${defaultTitle}`,
};
