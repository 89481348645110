import { Box, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";

export interface BoxModalProps {
  title?: string;
  height?: number;
  overSx?: Object;
  showIconClose?: boolean;
  showDivider?: boolean;
  children?: React.ReactNode;
  width?: Object | string;
  onClose: (dataResponse: Object) => void;
}

const BoxModal: FC<BoxModalProps> = ({
  title,
  height,
  overSx,
  showIconClose = true,
  showDivider = true,
  width = 600,
  children,
  onClose,
}) => {
  return (
    <Box
      component="div"
      sx={{
        position: "absolute",
        top: "7vh",
        left: "50%",
        transform: "translate(-50%, 0%)",
        width,
        maxWidth: "94vw",
        height,
        ...overSx,
      }}
    >
      <Paper elevation={0} sx={{ p: 2 }}>
        <Grid justifyContent="space-between" container mb={1.5} alignItems="center">
          <Typography component="h2" sx={{ fontWeight: 600, color: "#475985", fontSize: "1.4rem" }}>
            {title}
          </Typography>
          {showIconClose && (
            <IconButton onClick={onClose} size="small">
              <CloseIcon sx={{ color: "black" }} />
            </IconButton>
          )}
        </Grid>
        {showDivider && <Divider />}
        <Box
          sx={{
            mt: 2,
            overflowY: "auto",
            maxHeight: "calc(100vh - 20vh)",
          }}
        >
          {children}
        </Box>
      </Paper>
    </Box>
  );
};

export default BoxModal;
