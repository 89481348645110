import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ModalContainer from "../../../../components/modal/ModalContainer";
import { DOC_IN_MSG } from "../../../../constants/msg/msg.constant";
import { spacing_size } from "../../../../themes/size";

interface ModalSubmitProps {
  open: boolean;
  onClose: () => void;
  handleOnSubmitModal: () => void;
  textConfirm?: string;
}

const ModalSubmit: FC<ModalSubmitProps> = (props) => {
  const { open, onClose, handleOnSubmitModal, textConfirm = DOC_IN_MSG.confirmSubmit } = props;
  const navigate = useNavigate();
  const handleCancle = () => {
    onClose();
  };
  const handleSubmit = () => {
    handleOnSubmitModal();
    onClose();
  };
  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title="Xác nhận"
      >
        <Typography id="modal-modal-description">{textConfirm}</Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={spacing_size} sx={{ mt: 2, justifyContent: "end" }}>
          <Button variant="outlined" onClick={handleCancle}>
            Huỷ
          </Button>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Xác nhận
          </Button>
        </Stack>
      </ModalContainer>
    </>
  );
};

export default ModalSubmit;
