export const USER_MSG = {
  createSuccess: "Thêm mới người dùng thành công.",
  updateSuccess: "Cập nhật người dùng thành công.",
};

export const DEPARTMENT_MSG = {
  createSuccess: "Thêm mới phòng ban thành công.",
  updateSuccess: "Cập nhật phòng ban thành công.",
};

export const POSITION_MSG = {
  createSuccess: "Thêm mới chức vụ thành công.",
  updateSuccess: "Cập nhật chức vụ thành công.",
};

export const COMMON_MSG = {
  common: "Có lỗi xảy ra với hệ thống.",
  zeroSizeFileUpload: "File tải lên kích thước phải lơn hơn 0kb.",
  acceptExtensionFile: (extensions: string) => `Chỉ được upload file ${extensions}. Xin thử lại!`,
  minSizeFileUpload: (size: number, unit: "kb" | "mb" = "kb") => `File tải lên kích thước phải lớn hơn ${size}${unit}.`,
  maxSizeFileUpload: (size: number, unit: "kb" | "mb" = "kb") => `File tải lên kích thước phải nhỏ hơn ${size}${unit}.`,
  limitChangePW: "Vui lòng thực hiện chức năng này sau một tiếng nữa.",
};

export const DOC_IN_MSG = {
  exportListSuccess: "Xuất excel Danh sách công văn đến thành công!",
  createDomesticSuccess: "Tạo phiếu công văn đến thành công!",
  createInternationalSuccess: "Tạo phiếu văn bản quốc tế đến thành công.",
  updateDomesticSuccess: "Cập nhật phiếu công văn đến thành công.",
  updateInternationalSuccess: "Cập nhật phiếu công văn quốc tế thành công",
  unAssignedSuccess: "Duyệt phiếu công văn đến thành công!",
  refuseSuccess: "Hoàn trả công văn đến thành công!",
  assignmentSuccess: "Phiếu công văn đến được phân công thành công!",
  cancelSuccess: "Huỷ công văn đến thành công!",
  replySuccess: "Phiếu công văn được gửi đi để phúc đáp thành công!",
  processionSuccess: "Công văn đến được xử lý thành công!",
  arrivalDateMaxDeadlineTime: "Ngày văn bản đến không thể lớn hơn Thời hạn xử lý",
  docDateMaxArrivalDate: "Ngày tháng văn bản không thể lớn hơn Ngày văn bản đến",
  confirmSubmit: "Phiếu sẽ được gửi đi để trình duyệt. Vui lòng xác nhận!",
  confirmProcessed: "Phiếu sẽ được xử lý. Vui lòng xác nhận!",
  exportDocInDashboardSuccess: "Xuất excel Báo cáo tổng hợp số lượng công văn đến theo trạng thái thành công!",
};

export const DEPARMENT_IN_MSG = {
  exportDepartment: "Xuất excel danh sách phòng ban thành công!",
};

export const POSITION_IN_MSG = {
  exportPosition: "Xuất excel danh sách chức vụ thành công!",
};

export const DOC_OUT_MSG = {
  confirmSave: "Công văn đi sẽ được lưu. Vui lòng xác nhận?",
  exportListSuccess: "Xuất excel Danh sách công văn đi thành công!",
  createSuccess: "Thêm mới công văn đi thành công",
  updateSuccess: "Cập nhật công văn đi thành công",
  exportDocOutDashboardSuccess: "Xuất excel Báo cáo tổng hợp số lượng công văn đi theo loại công văn thành công!",
};

export const TASK_MSG = {
  exportDocOutDashboardSuccess: "Xuất excel Báo cáo tổng hợp số lượng công việc theo trạng thái thành công!",
};

export const ROLE_MSG = {};

export const AUTH_MSG = {
  loginSuccess: "Đăng nhập thành công.",
  changePwSuccess: "Đổi mật khẩu thành công.",
  wrongPassword: "Mật khẩu cũ chưa chính xác.",
  forgotPWSuccess: "Mật khẩu mới sẽ được gửi vào email của bạn.",
};

export const DOC_BOOK_MSG = {
  createSuccess: "Thêm mới sô công văn thành công.",
};

export const FORM_MSG = {
  placeholder: "Nhập thông tin",
  reactSelectPlaceholder: "Nhập/chọn thông tin",
  dateFormatPlaceholder: "dd/mm/yyyy",
  monthFormatPlaceholder: "mm/yyyy",
  dateTimeFormatPlaceholder: "dd/mm/yyyy hh:mm",
  fromDateMoreToDate: "Ngày bắt đầu phải nhỏ hơn ngày đến.",
  requiredFieldMsg: (field: string) => `Vui lòng nhập ${field.toLowerCase()}.`,
  requiredSelectFieldMsg: (field: string) => `Vui lòng chọn ${field.toLowerCase()}.`,
  maxLengthFieldMsg: (maxLength: string | number) => `Nhập tối tối đa ${maxLength} ký tự.`,
  minLengthFieldMsg: (minLength: string | number) => `Nhập tối tối thiếu ${minLength} ký tự.`,
  patternFieldMsg: (field: string) => `${field} chưa hợp lệ.`,
  formatNumber: (field: string) => `${field} phải ở dạng số`,
  positiveNumber: (field: string) => `${field} phải ở dạng số dương`,
  integerNumber: (field: string) => `${field} phải ở dạng số nguyên`,
  requiredFieldUploadFile: (field: string) => `Vui lòng tải file ${field.toLowerCase()} lên!`,
};

export const HR_MSG = {
  createSuccess: "Tạo mới nhân sự thành công!",
  createPositionSuccess: "Tạo mới chức vụ thành công!",
  createDepartmentSuccess: "Tạo mới phòng ban thành công!",
  updateSuccess: "Cập nhật nhân sự thành công!",
};
