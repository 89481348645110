import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import HeaderPage from "../../../components/common/HeaderPage";
import ArrowBackIcon from "../../../components/icons/ArrowBackIcon";

interface HeaderPersonnelDetailProps {
  data: any;
}

const HeaderPersonnelDetail: FC<HeaderPersonnelDetailProps> = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const backPersonnelListPage = () => {
    const { bodyFilter, department, position, type, status } = location?.state || {};
    navigate("/personnel", {
      state: {
        bodyFilter: bodyFilter,
        department: department,
        position: position,
        type: type,
        status: status,
      },
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        startIcon={<ArrowBackIcon />}
        onClick={backPersonnelListPage}
        sx={{ marginBottom: "15px" }}
      >
        Trở về
      </Button>
      <HeaderPage title={`Chi tiết thông tin: CBNV ${data?.fullName}`}></HeaderPage>
    </>
  );
};
export default HeaderPersonnelDetail;
