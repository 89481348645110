import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import BoxContainer from "../../../components/common/BoxContainer";
import ViewFile from "../../../components/common/ViewFile";
import FieldContainer from "../../../components/form/FieldContainer";
import { UPLOAD_DATE_FORMAT, VIEW_DATE_FORMAT } from "../../../constants/date-time.constant";
import { EXCEPT_SYMBOLS } from "../../../constants/except.constant";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { ContactTypePersonnels, FormPayHr } from "../../../enum/personnel.enum";
import {
  ContractAddendumModel,
  ContractModel,
  PersonnelModel,
  SelectFieldCommonPersonnelModel,
  SummarySalaryModel,
} from "../../../model/personnel.model";
import { header_table_color, primary_color } from "../../../themes/color";
import { spacing_size } from "../../../themes/size";
import {
  changeFileUploadPersonnel,
  convertFormPayHr,
  convertTypeContract,
  downloadTempHrFileByType,
  getActiveContractOrContractAddendum,
  getSalarySummaryDetail,
  isContractOrContractAddendumActive,
  isExistContract,
  isExistContractActive,
  isOverlapDateTimeContract,
  isPermanentTermContactType,
} from "../common/util/personnel.util";
import TextError from "../../../components/form/TextError";
import ChooseFile from "../../../components/icons/ChooseFile";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import ModalCreateContractAddendum from "../modal/ModalCreateContractAddendum";
import ModalUpdateContact from "../modal/ModalUpdateContact";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TbFolderX } from "react-icons/tb";
import { FiDownload } from "react-icons/fi";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import ModalUpdateContractAddendum from "../modal/ModalUpdateContractAddendum";
import ModalDeleteContractAddendum from "../modal/ModalDeleteContractAddendum";
import { toast } from "react-toastify";
import { CONTACT_TYPE_ENUM } from "../../../enum/personnel.enum";
import { formatDateTime } from "../../../utils/data-time.util";
import { removeCommaStr } from "../../../utils/string.util";
import { downloadFile, subStringFileName } from "../../../utils/file.util";
import { VALID_FORMAT_FILE } from "../../../constants/file.constant";
import { formatNumber } from "../../../utils/number.util";
import ReadMore from "../../../components/common/ReadMore";
import { TYPE_FILE_ENUM } from "../../../enum/hr.enum";
import ModalDeleteContract from "../modal/ModalDeleteContract";

interface ContractProps {
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  valueUploads: PersonnelModel | null;
  fileContractsUpload: File[];
  setFileContractsUpload: React.Dispatch<React.SetStateAction<File[]>>;
  btnSubmitContractRef: React.RefObject<HTMLButtonElement>;
  fileContractsAddendumUpload: File[];
  setFileContractsAddendumUpload: React.Dispatch<React.SetStateAction<File[]>>;
}

const Contract: FC<ContractProps> = (props) => {
  const theme = useTheme();

  const {
    valueUploads,
    setValuesUploads,
    fileContractsUpload,
    setFileContractsUpload,
    btnSubmitContractRef,
    fileContractsAddendumUpload,
    setFileContractsAddendumUpload,
  } = props;

  const getArrCodeDetail: any = (valueUploads: any) =>
    valueUploads?.contracts?.map((item: ContractModel) => {
      return {
        code: item?.code,
        description: item?.code,
      };
    });

  const arrContractDetail: any = valueUploads?.contracts;
  const arrContractAddendumDetail: any = valueUploads?.contractAddendums;

  const currentDate = new Date();
  const currentTime = new Date().getTime();
  const formatCurrentDateDetail = formatDateTime(currentDate, UPLOAD_DATE_FORMAT);
  const [contractDetail, setContractDetail] = useState<ContractModel[] | any[]>(arrContractDetail);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);
  const [openModalContractAddendum, setOpenModalContractAddendum] = useState<boolean>(false);
  const [openModalDeleteContractAddendum, setOpenModalDeleteContractAddendum] = useState<boolean>(false);
  const [openModalUpdateContractAddendum, setOpenModalUpdateContractAddendum] = useState<boolean>(false);
  const [indexContract, setIndexContract] = useState<number>(0);
  const [indexContractAddendum, setIndexContractAddendum] = useState<number>(0);

  const [itemContract, setItemContract] = useState<ContractModel | null>(null);
  const [itemContractAddendum, setItemContractAddendum] = useState<ContractAddendumModel | null>(null);
  const [contractAddendumDetail, setContractAddendumDetail] =
    useState<ContractAddendumModel[]>(arrContractAddendumDetail);

  const [arrCodeDetail, setArrCodeDetail] = useState<SelectFieldCommonPersonnelModel[]>(getArrCodeDetail(valueUploads));

  const [summarySalaryDetail, setSummarySalaryDetail] = useState<SummarySalaryModel | null>(null);

  const [file, setFile] = useState<File[]>([]);
  const [arrFile, setArrFile] = useState<File[] | any>([]);

  const fileRef = useRef<HTMLButtonElement | HTMLInputElement | any>(null);
  const [arrFileContractAddendum, setArrFileContractAddendum] = useState<File[] | any>([]);

  const initialValues: ContractModel = {
    code: "",
    type: "",
    status: "",
    startDate: null,
    endDate: null,
    fileName: "",
    note: "",
    salary: "",
    allowances: "",
    monthlyBonus: "",
    fileTemp: "",
    formPay: "",
  };

  const validationSchema = Yup.object({
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Số hợp đồng")),
    type: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Loại hợp đồng")),
    startDate: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Thời gian bắt đầu")),
    endDate: Yup.date()
      .nullable()
      .when("type", {
        is: (type: any) => type?.code !== CONTACT_TYPE_ENUM.PERMANENT_TERM,
        then: Yup.date()
          .min(Yup.ref("startDate"), "Thời gian kết thúc phải lớn hơn thời gian bắt đầu.")
          .nullable()
          .required(FORM_MSG.requiredFieldMsg("Thời gian kết thúc")),
        otherwise: Yup.date().nullable(),
      }),
    fileTemp: Yup.mixed()
      .required(FORM_MSG.requiredFieldUploadFile("Đính kèm hợp đồng"))
      .test("fileFormat", "Chỉ tải lên file xlsx, xls, doc, docx, pdf", (value) => {
        if (!value) {
          return false;
        }
        return VALID_FORMAT_FILE.includes(value.type);
      }),
  });

  useEffect(() => {
    setSummarySalaryDetail(getSalarySummaryDetail(contractAddendumDetail, contractDetail));
  }, [contractAddendumDetail, contractDetail]);

  const handleOpenModalDelete = (item: ContractModel, index: number, e: React.ChangeEvent<unknown>) => {
    setOpenModalDelete(true);
    setIndexContract(index);
    setItemContract(item);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleOpenModalContractAddendum = () => {
    setOpenModalContractAddendum(true);
  };

  const handleCloseModalContractAddendum = () => {
    setOpenModalContractAddendum(false);
  };

  const handleOpenModalUpdate = (item: ContractModel, index: number) => {
    setOpenModalUpdate(true);
    setItemContract(item);
    setIndexContract(index);
  };

  const handleCloseModalUpdate = () => {
    setOpenModalUpdate(false);
  };

  const handleOpenModalDeleteContractAddendum = (item: ContractAddendumModel, index: number) => {
    setOpenModalDeleteContractAddendum(true);
    setIndexContractAddendum(index);
    setItemContractAddendum(item);
  };

  const handleCloseModalDeleteContractAddendum = () => {
    setOpenModalDeleteContractAddendum(false);
  };

  const handleOpenModalUpdateContractAddendum = (item: ContractAddendumModel, index: number) => {
    setOpenModalUpdateContractAddendum(true);
    setItemContractAddendum(item);
    setIndexContractAddendum(index);
  };

  const handleCloseModalUpdateContractAddendum = () => {
    setOpenModalUpdateContractAddendum(false);
  };

  const handleChangeType = (formik: FormikHelpers<ContractModel>) => (e: SelectFieldCommonPersonnelModel | null) => {
    formik.setFieldValue("type", e);
  };

  const changeUploadFile = (formik: FormikHelpers<ContractModel>) => (e: any) => {
    e.preventDefault();
    formik.setFieldValue("fileTemp", e.target.files[0]);
    const fileUp = changeFileUploadPersonnel(e, file, "", 1);
    const newFileUp = fileUp?.map((item: File, index: number) => {
      const newNameItem = currentTime + "_" + item?.name;
      const newItem = new File([item], newNameItem, { type: item.type });
      return newItem;
    });
    setFile(newFileUp);
    setArrFile([...arrFile, ...newFileUp]);
  };

  const handleChangeFormPay = (formik: FormikHelpers<ContractModel>) => (e: SelectFieldCommonPersonnelModel | null) => {
    formik.setFieldValue("formPay", e);
  };

  const buildBody = (values: ContractModel) => {
    const convertSalary = values?.salary ? removeCommaStr(values.salary) : null;
    const convertAllowances = values?.allowances ? removeCommaStr(values?.allowances) : null;
    const convertMonthlyBonus = values?.monthlyBonus ? removeCommaStr(values?.monthlyBonus) : null;

    return {
      ...values,
      fileName: file[0].name,
      type: values?.type?.code || "",
      startDate: formatDateTime(values.startDate, UPLOAD_DATE_FORMAT),
      endDate: formatDateTime(values.endDate, UPLOAD_DATE_FORMAT),
      salary: Number(convertSalary) || null,
      allowances: Number(convertAllowances) || null,
      monthlyBonus: Number(convertMonthlyBonus) || null,
      formPay: values?.formPay?.code,
    };
  };

  const handleOnSubmit = (values: ContractModel, formik: FormikHelpers<ContractModel>) => {
    // Check ngày kết thúc phải lớn hơn ngày bắt đầu
    if (
      !isPermanentTermContactType(values.type) &&
      moment(values.startDate).isSameOrAfter(moment(values.endDate), "date")
    ) {
      toast.error("Ngày bắt đầu phải lớn hơn kết thúc");
      return;
    }

    // Hợp đồng không được trùng nhau
    if (isExistContract(contractDetail, values.code || "")) {
      toast.error("Hợp đồng không được trùng nhau");
      return;
    }

    // Thời gian trùng với hợp đồng khác
    if (isOverlapDateTimeContract(contractDetail, values)) {
      toast.error("Thời gian bị trùng với hợp đồng khác");
      return;
    }

    // Đang tồn tại hợp đồng còn hiệu lực
    if (isExistContractActive(contractDetail) && isContractOrContractAddendumActive(values)) {
      toast.error("Đang tồn tại hợp đồng còn hiệu lực");
      return;
    }

    const body = buildBody(values);

    setContractDetail([...contractDetail, body]);
    setValuesUploads({ ...valueUploads, contracts: [...contractDetail, body] });
    setFileContractsUpload(arrFile);
    setArrCodeDetail([
      ...arrCodeDetail,
      {
        code: values?.code,
        description: values?.code,
      },
    ]);
    formik.resetForm();
    setFile([]);
  };

  const handleDeleteItem = () => {
    const item: any = contractDetail?.filter((item: ContractModel, i: number) => i !== indexContract);
    const arrCode = arrCodeDetail?.filter((item: SelectFieldCommonPersonnelModel, i: number) => i !== indexContract);
    const newArrFile = arrFile?.filter((item1: File, i: number) => indexContract !== i);

    const filterContractAddendumByCode = contractAddendumDetail?.filter(
      (item: ContractAddendumModel) => item?.contractCode !== itemContract?.code
    );

    setContractAddendumDetail(filterContractAddendumByCode);
    setArrFile(newArrFile);
    setFileContractsUpload(newArrFile);
    setContractDetail(item);
    setArrCodeDetail(arrCode);
    setValuesUploads({ ...valueUploads, contracts: [...item], contractAddendums: [...filterContractAddendumByCode] });
  };

  const handleDeleteItemContractAddendum = () => {
    const item: any = contractAddendumDetail?.filter(
      (itemFilter: ContractAddendumModel, i: number) => itemFilter?.code !== itemContractAddendum?.code
    );
    const newArrFile = arrFileContractAddendum?.filter((item1: File, i: number) => indexContractAddendum !== i);
    setContractAddendumDetail(item);
    setFileContractsAddendumUpload(newArrFile);
    setArrFileContractAddendum(newArrFile);
    setValuesUploads({ ...valueUploads, contractAddendums: [...item] });
  };

  const handleClickSave = () => {
    setValuesUploads({ ...valueUploads, contracts: [...contractDetail] });
    // setFileContractsUpload(arrFile);
  };

  return (
    <>
      {openModalUpdate && (
        <ModalUpdateContact
          open={openModalUpdate}
          onClose={handleCloseModalUpdate}
          valueUploads={valueUploads}
          setValuesUploads={setValuesUploads}
          indexContract={indexContract}
          itemContract={itemContract}
          setContractDetail={setContractDetail}
          fileContractsUpload={fileContractsUpload}
          setFileContractsUpload={setFileContractsUpload}
          arrCodeDetail={arrCodeDetail}
          setArrCodeDetail={setArrCodeDetail}
          contractAddendumDetail={contractAddendumDetail}
          setContractAddendumDetail={setContractAddendumDetail}
          fileContractsAddendumUpload={fileContractsAddendumUpload}
          setFileContractsAddendumUpload={setFileContractsAddendumUpload}
        />
      )}

      {openModalUpdateContractAddendum && (
        <ModalUpdateContractAddendum
          open={openModalUpdateContractAddendum}
          onClose={handleCloseModalUpdateContractAddendum}
          valueUploads={valueUploads}
          setValuesUploads={setValuesUploads}
          contractAddendumDetail={contractAddendumDetail}
          arrCodeDetail={arrCodeDetail}
          setContractAddendumDetail={setContractAddendumDetail}
          fileContractsAddendumUpload={fileContractsAddendumUpload}
          setFileContractsAddendumUpload={setFileContractsAddendumUpload}
          itemContractAddendum={itemContractAddendum}
          indexContractAddendum={indexContractAddendum}
        />
      )}

      {openModalContractAddendum && (
        <ModalCreateContractAddendum
          open={openModalContractAddendum}
          onClose={handleCloseModalContractAddendum}
          valueUploads={valueUploads}
          setValuesUploads={setValuesUploads}
          contractAddendumDetail={contractAddendumDetail}
          arrCodeDetail={arrCodeDetail}
          setContractAddendumDetail={setContractAddendumDetail}
          fileContractsAddendumUpload={fileContractsAddendumUpload}
          setFileContractsAddendumUpload={setFileContractsAddendumUpload}
          setArrFileContractAddendum={setArrFileContractAddendum}
        />
      )}

      {openModalDelete && (
        <ModalDeleteContract
          open={openModalDelete}
          onClose={handleCloseModalDelete}
          handleDeleteItem={handleDeleteItem}
          itemContract={itemContract}
          valuesContractAddendum={contractAddendumDetail}
        />
      )}

      {openModalDeleteContractAddendum && (
        <ModalDeleteContractAddendum
          open={openModalDeleteContractAddendum}
          onClose={handleCloseModalDeleteContractAddendum}
          handleDeleteItem={handleDeleteItemContractAddendum}
        />
      )}

      {/* form */}
      <Box>
        <BoxContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              [theme.breakpoints.down("sm")]: {
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <Typography sx={{ fontSize: "1.2rem", fontWeight: "500", marginBottom: "0.6rem" }}>
              Thêm mới hợp đồng
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="button"
              onClick={handleOpenModalContractAddendum}
            >
              Thêm mới PLHĐ
            </Button>
          </Box>

          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={true}
            // enableReinitialize={true}
          >
            {(formik) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={3}>
                    <Stack direction={{ xs: "column", lg: "row" }} spacing={spacing_size}>
                      <Grid item xs={12} md={6} lg={5.5}>
                        <FieldContainer
                          name="startDate"
                          onBlur={formik.handleBlur}
                          value={formik.values.startDate}
                          variant={"date"}
                          id={"startDate"}
                          label="Thời gian bắt đầu"
                          isRequired={true}
                          placeholderText="DD/MM/YYYY"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={1}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          [theme.breakpoints.down("md")]: {
                            display: "none",
                          },
                        }}
                      >
                        <HorizontalRuleIcon
                          fontSize="small"
                          sx={{
                            marginTop: "2rem",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={5.5}>
                        <FieldContainer
                          name="endDate"
                          onBlur={formik.handleBlur}
                          value={formik.values.endDate}
                          variant={"date"}
                          id={"endDate"}
                          label="Thời gian kết thúc"
                          isRequired={formik?.values?.type?.code === CONTACT_TYPE_ENUM.PERMANENT_TERM ? false : true}
                          placeholderText="DD/MM/YYYY"
                          // minDate={currentDate}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <FieldContainer
                      fullWidth
                      name="type"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeType(formik)}
                      value={formik.values.type}
                      options={ContactTypePersonnels}
                      variant={"react-select"}
                      id={"type"}
                      label="Loại hợp đồng"
                      isRequired={true}
                      placeholder="Nhập/chọn thông tin"
                      getOptionLabel={(item: SelectFieldCommonPersonnelModel) => item?.description}
                      getOptionValue={(item: SelectFieldCommonPersonnelModel) => item?.code}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="code"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.code}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Số hợp đồng"
                      notched={true}
                      variant={"input"}
                      id={"code"}
                      isRequired={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <Stack direction="row" alignItems="center" spacing={spacing_size} sx={{ margin: "0.6rem 0" }}>
                      <Typography sx={{ fontSize: "1rem", fontWeight: "600", color: "#323E5D" }}>
                        Đính kèm hợp đồng{" "}
                        <Box component="span" sx={{ color: "red" }}>
                          *
                        </Box>
                      </Typography>
                      <Button
                        variant="contained"
                        color="cancel"
                        // sx={{ display: "flex" }}
                        onClick={() => fileRef.current.click()}
                        disabled={file?.length < 1 ? false : true}
                        startIcon={<ChooseFile />}
                      >
                        Chọn file
                      </Button>
                    </Stack>
                    <Field
                      id="file-input"
                      name="fileTemp"
                      // accept="application/msword, application/vnd.ms-excel, application/pdf"
                      accept=".xlsx,.xls,.doc,.docx,.pdf"
                      type="file"
                      innerRef={fileRef}
                      style={{ display: "none" }}
                      value={""}
                      onChange={changeUploadFile(formik)}
                    />
                    {file.map((item, index) => {
                      let newItem = subStringFileName(item);
                      return (
                        <ViewFile
                          key={index}
                          item={newItem}
                          handleRemove={() => {
                            setFile(file.filter((file) => item !== file));
                            formik.setFieldValue("fileTemp", "");
                          }}
                        />
                      );
                    })}
                    <ErrorMessage name="fileTemp" component={TextError} />
                  </Grid>

                  <Grid item xs={12} md={6} lg={9}>
                    <FieldContainer
                      fullWidth
                      name="note"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.note}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Ghi chú"
                      notched={true}
                      variant={"input"}
                      id={"note"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={2}>
                    <FieldContainer
                      // fullWidth
                      name="salary"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.salary}
                      placeholder={FORM_MSG.placeholder}
                      // maxRows={12}
                      label="Lương theo hợp đồng (VNĐ)"
                      variant={"number-input"}
                      id={"salary"}
                      thousandSeparator=","
                      // InputProps={{ inputProps: { max: 0 } }}
                      onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <FieldContainer
                      // fullWidth
                      name="allowances"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.allowances}
                      placeholder={FORM_MSG.placeholder}
                      // maxRows={12}
                      label="Phụ cấp (VNĐ)"
                      variant={"number-input"}
                      id={"allowances"}
                      thousandSeparator=","
                      // InputProps={{ inputProps: { min: 0 } }}
                      onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <FieldContainer
                      // fullWidth
                      name="monthlyBonus"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.monthlyBonus}
                      placeholder={FORM_MSG.placeholder}
                      // maxRows={12}
                      label="Thưởng tháng (VNĐ)"
                      variant={"number-input"}
                      id={"monthlyBonus"}
                      thousandSeparator=","
                      // InputProps={{ inputProps: { min: 0 } }}
                      onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <FieldContainer
                      fullWidth
                      id={"formPay"}
                      name="formPay"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeFormPay(formik)}
                      type="text"
                      value={formik.values.formPay}
                      placeholder={FORM_MSG.placeholder}
                      // maxRows={12}
                      label="Hình thức trả lương"
                      variant={"react-select"}
                      getOptionLabel={(item: SelectFieldCommonPersonnelModel) => item?.description}
                      getOptionValue={(item: SelectFieldCommonPersonnelModel) => item?.code}
                      options={FormPayHr}
                    />
                  </Grid>

                  <Grid item xs={12} justifyContent="center">
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={spacing_size}
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      <Button variant="contained" color="primary" type="submit" startIcon={<BrowserIcon />}>
                        Lưu hợp đồng
                      </Button>
                      <Button
                        ref={btnSubmitContractRef}
                        variant="contained"
                        color="primary"
                        type="button"
                        sx={{ display: "none" }}
                        onClick={handleClickSave}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </BoxContainer>
      </Box>

      {/* overview salary */}
      <Box>
        <BoxContainer>
          <Grid container spacing={spacing_size} alignItems="center">
            <Grid item xs={12} md={6} lg={6}>
              <Typography sx={{ fontSize: "1.2rem", color: "#253C51", fontWeight: "700" }}>
                Tổng hợp thông tin lương
              </Typography>
            </Grid>
            <TitleSalaryInformation
              title={"Lương (VNĐ)"}
              value={formatNumber(summarySalaryDetail?.salary)}
              titleTooltip={"Lấy từ thông tin trên HĐ/PLHĐ còn hiệu lực mới nhất"}
            />
            <TitleSalaryInformation
              title={"Phụ cấp (VNĐ)"}
              value={formatNumber(summarySalaryDetail?.allowances)}
              titleTooltip={"Lấy từ thông tin trên HĐ/PLHĐ còn hiệu lực mới nhất"}
            />
            <TitleSalaryInformation
              title={"Thưởng tháng (VNĐ)"}
              value={formatNumber(summarySalaryDetail?.monthlyBonus)}
              titleTooltip={"Lấy từ thông tin trên HĐ/PLHĐ còn hiệu lực mới nhất"}
            />
          </Grid>
        </BoxContainer>
      </Box>

      {/* list contracts */}
      {contractDetail && contractDetail?.length > 0 && (
        <Box>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: header_table_color }}>
                  <TableCell sx={{ width: "1%" }}></TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Hợp đồng/ Phụ lục hợp đồng</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Lương (VNĐ)</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Phụ cấp (VNĐ)</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Thưởng tháng (VNĐ)</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Ghi chú</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Trạng thái</TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="center">
                    Hành động
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contractDetail?.map((item: ContractModel, index: number) => (
                  <Row key={index} item={item} index={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );

  function Row(props: any) {
    const { item, index } = props;
    const [openRow, setOpenRow] = useState<boolean>(false);

    const contractAddendumByCode = contractAddendumDetail?.filter(
      (itemFilter: ContractAddendumModel) => itemFilter?.contractCode === item?.code
    );

    useEffect(() => {
      if (contractAddendumByCode?.length > 0) {
        setOpenRow(true);
      }
    }, []);

    return (
      <React.Fragment>
        <TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
          }}
          key={index}
        >
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpenRow(!openRow)}>
              {openRow ? (
                <KeyboardArrowUpIcon sx={{ color: primary_color }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: primary_color }} />
              )}
            </IconButton>
          </TableCell>
          <TableCell sx={{ width: "400px", maxWidth: "400px", wordWrap: "break-word" }}>
            <Typography variant="body1" sx={{ fontWeight: "500", fontSize: "1.2rem" }}>{`[${convertTypeContract(
              item?.type
            )}] - ${item?.code}`}</Typography>
            <Typography variant="body1" sx={{ fontStyle: "italic" }}>{`Hiệu lực: ${formatDateTime(
              item?.startDate,
              VIEW_DATE_FORMAT
            )} ${item?.endDate ? " - " + formatDateTime(item?.endDate, VIEW_DATE_FORMAT) : ""}`}</Typography>
            <Typography variant="body1" sx={{ color: "#FFA215" }} fontWeight={400}>
              {convertFormPayHr(item?.formPay)}
            </Typography>
          </TableCell>
          <TableCell sx={{ width: "150px", maxWidth: "150px", wordWrap: "break-word" }}>
            {formatNumber(item?.salary)}
          </TableCell>
          <TableCell sx={{ width: "150px", maxWidth: "150px", wordWrap: "break-word" }}>
            {formatNumber(item?.allowances)}
          </TableCell>
          <TableCell sx={{ width: "200px", maxWidth: "200px", wordWrap: "break-word" }}>
            {formatNumber(item?.monthlyBonus)}
          </TableCell>
          <TableCell sx={{ maxWidth: "200px", wordWrap: "break-word" }}>
            <ReadMore text={item?.note} />
          </TableCell>
          <TableCell sx={{ width: "150px", wordWrap: "break-word" }}>
            {(item?.endDate ? new Date(item?.endDate).getTime() + 7 * 60 * 60 * 1000 : new Date().getTime()) >=
            new Date(formatCurrentDateDetail).getTime() ? (
              <Typography sx={{ color: "#348F14" }}>Còn hiệu lực</Typography>
            ) : (
              <Typography sx={{ color: "red" }}>Hết hiệu lực</Typography>
            )}
          </TableCell>
          <TableCell align="center" sx={{ width: "150px" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={spacing_size}
              sx={{
                justifyContent: "center",
              }}
            >
              <IconButton color="error" onClick={(e) => handleOpenModalDelete(item, index, e)}>
                <TbFolderX />
              </IconButton>

              <IconButton
                type="button"
                onClick={() => downloadTempHrFileByType(item.fileTemp, item.fileTemp?.name, TYPE_FILE_ENUM.CONTRACT)}
              >
                <FiDownload style={{ color: "#348F14" }} />
              </IconButton>

              <IconButton onClick={() => handleOpenModalUpdate(item, index)}>
                <MdModeEditOutline style={{ color: "#419CDD" }} />
              </IconButton>
            </Stack>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }} colSpan={8}>
            <Collapse in={openRow} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {contractAddendumDetail
                      ?.filter((i: ContractAddendumModel) => i?.contractCode === item?.code)
                      ?.map((item1: ContractAddendumModel, index: number) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                          }}
                          key={index}
                        >
                          <TableCell sx={{ width: "66px" }}></TableCell>
                          <TableCell sx={{ maxWidth: "400px", width: "400px", wordWrap: "break-word" }}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "500", fontSize: "1.2rem" }}
                            >{`[PLHĐ] - ${item1?.code}`}</Typography>
                            <Typography variant="body1" sx={{ fontStyle: "italic" }}>{`Hiệu lực: ${formatDateTime(
                              item1?.startDate,
                              VIEW_DATE_FORMAT
                            )} ${
                              item1?.endDate ? " - " + formatDateTime(item1?.endDate, VIEW_DATE_FORMAT) : ""
                            }`}</Typography>
                          </TableCell>
                          <TableCell sx={{ width: "150px", maxWidth: "150px", wordWrap: "break-word" }}>
                            {formatNumber(item1?.salary)}
                          </TableCell>
                          <TableCell sx={{ width: "150px", maxWidth: "150px", wordWrap: "break-word" }}>
                            {formatNumber(item1?.allowances)}
                          </TableCell>
                          <TableCell sx={{ width: "200px", maxWidth: "200px", wordWrap: "break-word" }}>
                            {formatNumber(item1?.monthlyBonus)}
                          </TableCell>

                          <TableCell sx={{ maxWidth: "200px", wordWrap: "break-word" }}>
                            <ReadMore text={item1?.note} />
                          </TableCell>
                          <TableCell sx={{ width: "150px", wordWrap: "break-word" }}>
                            {new Date(item1?.endDate).getTime() + 7 * 60 * 60 * 1000 >=
                            new Date(formatCurrentDateDetail).getTime() ? (
                              <Typography sx={{ color: "#348F14" }}>Còn hiệu lực</Typography>
                            ) : (
                              <Typography sx={{ color: "red" }}>Hết hiệu lực</Typography>
                            )}
                          </TableCell>
                          <TableCell align="center" sx={{ width: "150px" }}>
                            <Stack
                              direction={{ xs: "column", sm: "row" }}
                              spacing={spacing_size}
                              sx={{
                                justifyContent: "center",
                              }}
                            >
                              <IconButton
                                color="error"
                                onClick={() => handleOpenModalDeleteContractAddendum(item1, index)}
                              >
                                <TbFolderX />
                              </IconButton>

                              <IconButton
                                type="button"
                                onClick={() =>
                                  downloadTempHrFileByType(
                                    item1.fileTemp,
                                    item1.fileTemp?.name,
                                    TYPE_FILE_ENUM.CONTRACT_ADDENDUM
                                  )
                                }
                              >
                                <FiDownload style={{ color: "#348F14" }} />
                              </IconButton>

                              <IconButton onClick={() => handleOpenModalUpdateContractAddendum(item1, index)}>
                                <MdModeEditOutline style={{ color: "#419CDD" }} />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
};

const TitleSalaryInformation = (props: any) => {
  const { title, value, titleTooltip } = props;
  return (
    <Grid item xs={12} md={6} lg={2}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "1rem", color: "#253C51", fontWeight: "700" }}>{title}</Typography>
        <Tooltip title={titleTooltip} placement="top" arrow>
          <IconButton sx={{ fontSize: "1rem" }}>
            <BsInfoCircleFill style={{ color: "orange" }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography>{value}</Typography>
    </Grid>
  );
};

export default Contract;
