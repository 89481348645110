import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const UserGroupSidebarIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.175 7.129a2.87 2.87 0 0 0 .992-2.17 2.917 2.917 0 0 0-5.834 0 2.87 2.87 0 0 0 .992 2.17 4.667 4.667 0 0 0-2.742 4.246.583.583 0 0 0 1.167 0 3.5 3.5 0 0 1 7 0 .583.583 0 0 0 1.167 0A4.667 4.667 0 0 0 7.175 7.13Zm-1.925-.42a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 0 1 0 3.5Zm5.682.186A2.916 2.916 0 0 0 8.75 2.042a.583.583 0 0 0 0 1.167 1.75 1.75 0 0 1 1.514 2.622 1.75 1.75 0 0 1-.639.638.583.583 0 0 0-.03.992l.228.152.076.04a4.082 4.082 0 0 1 2.334 3.722.583.583 0 0 0 1.166 0 5.25 5.25 0 0 0-2.467-4.48Z"
        fill="#D4D9E4"
      />
    </svg>
  );
};

export default UserGroupSidebarIcon;
