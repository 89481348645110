import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { filterDocOutApi } from "../../../../api/doc-out.api";
import { TOTAL_HEADER } from "../../../../constants/page.constant";
import { FilterDocOutModel, ResultDocOutModel } from "../../../../model/doc-out.model";
import { Pageable } from "../../../../model/page.model";
import { getErrorMsgAxios } from "../../../../utils/error.util";

interface DocOutReducerState {
  pageDocOut: number;
  docOutDetail: ResultDocOutModel[] | null;
  totalDocOutDetail: number;
  reloadFilterDocOut: number;
}

const initialState: DocOutReducerState = {
  pageDocOut: 0,
  docOutDetail: null,
  totalDocOutDetail: 0,
  reloadFilterDocOut: 0,
};

export const filterDocOutApiAsync = createAsyncThunk(
  "docOut/filter",
  async (data: { body: FilterDocOutModel | null; pageable: Pageable }) => {
    try {
      const res = await filterDocOutApi(data.body, data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      const msg = getErrorMsgAxios(error);
      toast.error(msg);
      throw Error(error);
    }
  }
);

const DocOutReducer = createSlice({
  name: "DocOutReducer",
  initialState,
  reducers: {
    setReloadFilterDocOut(state, action) {
      return {
        ...state,
        reloadFilterDocOut: action.payload,
      };
    },
    setPageDocOut(state, action) {
      return {
        ...state,
        pageDocOut: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      filterDocOutApiAsync.fulfilled,
      (
        state,
        action: {
          payload: { data: ResultDocOutModel[] | null; total: number };
        }
      ) => {
        // action is inferred correctly here if using TS
        return {
          ...state,
          docOutDetail: action.payload.data,
          totalDocOutDetail: action.payload.total,
        };
      }
    );
  },
});

export const { setPageDocOut } = DocOutReducer.actions;
export default DocOutReducer.reducer;
