import { Button } from "@mui/material";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import HeaderPage from "../../../components/common/HeaderPage";
import ArrowBackIcon from "../../../components/icons/ArrowBackIcon";

interface HeaderCreateProps {}

const HeaderCreate: FC<HeaderCreateProps> = (props) => {
  const navigate = useNavigate();

  const backPersonnelListPage = () => {
    navigate("/personnel");
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        startIcon={<ArrowBackIcon />}
        onClick={backPersonnelListPage}
        sx={{ marginBottom: "15px" }}
      >
        Trở về
      </Button>
      <HeaderPage title="Chi tiết công việc"></HeaderPage>
    </>
  );
};

export default HeaderCreate;
