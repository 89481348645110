import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import BoxContainer from "../../../../../components/common/BoxContainer";
import HeaderPage from "../../../../../components/common/HeaderPage";
import FieldContainer from "../../../../../components/form/FieldContainer";
import BrowserIcon from "../../../../../components/icons/BrowserIcon";
import ModalSubmit from "../../modal/ModalSubmit";
import UploadSVG from "../../../../../components/images/upload.svg";
import { DOC_IN_MSG, FORM_MSG } from "../../../../../constants/msg/msg.constant";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { changeFileDocIn } from "../../common/util/doc-in.util";
import {
  deadlineDocIns,
  DEADLINE_DOC_IN_ENUM,
  SEN_CHANNEL_DOC_IN_ENUM,
  UPLOAD_CATEGORY_DOC_IN_ENUM,
} from "../../../../../enum/doc-in.enum";
import { Department, DepartmentHR } from "../../../../../model/department.model";
import { UserModel } from "../../../../../model/user.model";
import { buildOptionObject, buildOptionUserLabel } from "../../../../../utils/options.util";
import { getUserApi } from "../../../../../api/user.api";
import { createDocInApi, getArrivalNbrApi } from "../../../../../api/doc-in.api";
import { convertArrToString } from "../../../../../utils/array.util";
import moment from "moment";
import { UPLOAD_DATE_FORMAT, VIEW_DATE_TIME_WITHOUT_SECOND_FORMAT } from "../../../../../constants/date-time.constant";
import { spacing_size } from "../../../../../themes/size";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CodeDocBookModel, DocBook } from "../../../../../model/doc-book.model";
import { getDocBookApi } from "../../../../../api/doc-book.api";
import { getErrorMsgAxios } from "../../../../../utils/error.util";
import { EnumCommon } from "../../../../../enum/common.enum";
import { CustomInputCalendar } from "../../common/CustomInputCalendar";
import { ALLOW_INPUT_FILE_DOC_IN, MAX_LENGTH_FILE_DOC_IN } from "../../../../../constants/doc-in.constant";
import { removeFile } from "../../../../../utils/file.util";
import { getListDepartmentFromHr } from "../../../../../api/hr/department.api";
import { EXCEPT_SYMBOLS } from "../../../../../constants/except.constant";
import HeaderForm from "../../../../../components/common/HeaderForm";
import { DOC_BOOK_ENUM } from "../../../../../enum/doc-book.enum";

interface FormCreateDocInInternationalProps {
  arrivalNumber?: number | string;
}

interface InitialValuesForm {
  code?: string;
  docCategory?: string;
  fromPlace?: string;
  docDate?: string | null;
  arrivalDate?: string | null;
  docBookId?: string | number | Object | any;
  arrivalNbr?: string | number;
  deadline?: any | null;
  sentChannel?: string | null;
  summary?: string;
  handlingDepartment?: any;
  combinedDepartment?: any;
  bod?: any;
  contentTranslated?: string;
  deadlineTime?: any;
}
const FormCreateDocInInternational: FC<FormCreateDocInInternationalProps> = (props) => {
  const initialValues: InitialValuesForm = {
    docCategory: UPLOAD_CATEGORY_DOC_IN_ENUM.INTERNATIONAL,
    code: "",
    fromPlace: "",
    docDate: null,
    arrivalDate: null,
    docBookId: 2,
    summary: "",
    arrivalNbr: "",
    deadline: null,
    sentChannel: SEN_CHANNEL_DOC_IN_ENUM.EMAIL,
    handlingDepartment: "",
    combinedDepartment: "",
    bod: "",
    contentTranslated: "",
    deadlineTime: "",
  };

  const currentDate = new Date();
  const navigate = useNavigate();
  const btnSubmitRef = useRef<HTMLButtonElement>(null);
  const btnCancelRef = useRef<HTMLButtonElement>(null);
  const deadlineTimeBtnInputRef = useRef<HTMLButtonElement>(null);

  const [openModalSubmit, setOpenModalSubmit] = useState<boolean>(false);
  const [file, setFile] = useState<File[]>([]);
  const [departmentDetail, setDepartmentDetail] = useState<Department[]>([]);
  const [userDetail, setUserDetail] = useState<UserModel[]>([]);
  const [docBookDetail, setDocBookDetail] = useState<DocBook[]>([]);
  const [valuesUpload, setValuesUpload] = useState<any>();
  const [openDeadLineTimeModal, setOpenDeadLineTimeModal] = useState<boolean>(false);
  const [textConfirm, setTextConfirm] = useState<string>(DOC_IN_MSG.confirmSubmit);

  const validationSchema = Yup.object({
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Số và ký hiệu văn bản")),
    fromPlace: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Nơi ban hành văn bản")),
    docDate: Yup.date()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg("Ngày tháng văn bản"))
      .when("arrivalDate", (docDate: any, schema: any) => {
        if (docDate) {
          return schema.max(docDate, DOC_IN_MSG.docDateMaxArrivalDate);
        }
        return schema;
      }),
    arrivalDate: Yup.date()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg("Ngày văn bản đến"))
      .when(["deadline"], (deadline: any, schema: any) => {
        if (deadline?.code === DEADLINE_DOC_IN_ENUM.OTHER && deadline?.deadlineTime) {
          return schema.max(new Date(deadline?.deadlineTime), DOC_IN_MSG.arrivalDateMaxDeadlineTime);
        }
        return schema;
      }),
    arrivalNbr: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Số đến")),
    docBookId: Yup.object().nullable().required(FORM_MSG.requiredSelectFieldMsg("Sổ công văn")),
    summary: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Trích yếu nội dung văn bản ")),
    handlingDepartment: Yup.array()
      .nullable()
      .required(FORM_MSG.requiredSelectFieldMsg("Đơn vị/phòng phối hợp thực hiện")),
    bod: Yup.array()
      .nullable()
      .min(1, FORM_MSG.requiredSelectFieldMsg("Báo cáo lãnh đạo/Ban tổng thư ký"))
      .required(FORM_MSG.requiredSelectFieldMsg("Báo cáo lãnh đạo/Ban tổng thư ký")),
  });

  const getAllDepartment = async () => {
    let arrDepartment: Department[] = [];
    const params: any = {
      keyword: "",
    };
    await getListDepartmentFromHr(params.keyword).then((data) => {
      data?.data?.map((item: DepartmentHR) => {
        arrDepartment.push(buildOptionObject(item, "code", "name", `${item.code} - ${item.name}`));
      });
    });
    setDepartmentDetail(arrDepartment);
  };

  const getAllUserForm = async () => {
    let arrAllUser: UserModel[] = [];
    const params: any = {
      keyword: "",
      code: "",
    };
    await getUserApi(params.keyword, params?.code).then((res) => {
      res?.data?.map((item: UserModel) => {
        arrAllUser.push(buildOptionObject(item, "account", "fullName", buildOptionUserLabel(item)));
      });
    });
    setUserDetail(arrAllUser);
  };

  const getDocBook = async () => {
    let arrDocBook: DocBook[] = [];

    const params: CodeDocBookModel = {
      code: `${DOC_BOOK_ENUM.DOC_IN_INTERNATIONAL}`,
    };

    await getDocBookApi(params).then((res) => {
      res?.data?.map((item: DocBook) => {
        arrDocBook.push(buildOptionObject(item, "id", "name"));
      });
    });

    setDocBookDetail(arrDocBook);
  };

  useEffect(() => {
    getAllDepartment();
    getAllUserForm();
    getDocBook();
  }, []);

  const handleCancle = (formik: FormikHelpers<InitialValuesForm>) => {
    formik.resetForm();
    setFile([]);
  };

  const handleOnSubmit = (values: InitialValuesForm, formik: FormikHelpers<InitialValuesForm>) => {
    const formatDocDate = values?.docDate ? moment(values?.docDate).format(UPLOAD_DATE_FORMAT) : null;
    const formatArrivalDate = values?.arrivalDate ? moment(values?.arrivalDate).format(UPLOAD_DATE_FORMAT) : null;
    const formatDeadline = values?.deadline ? moment(values?.deadline).format(UPLOAD_DATE_FORMAT) : null;

    const arrCodeHandlingDepartment = values?.handlingDepartment
      ? values?.handlingDepartment?.map((item: Department) => item?.code)
      : [];
    const arrCodeCombinedDepartment = values?.combinedDepartment
      ? values?.combinedDepartment?.map((item: Department) => item?.code)
      : [];
    const formatCombinedDepartment = convertArrToString(arrCodeCombinedDepartment);
    const formatHandlingDepartment = convertArrToString(arrCodeHandlingDepartment);
    const arrBod = values?.bod?.map((item: UserModel) => item?.account);
    const formatBod = convertArrToString(arrBod);

    const newValues = {
      ...values,
      docDate: formatDocDate,
      arrivalDate: formatArrivalDate,
      isProcess: DEADLINE_DOC_IN_ENUM.PROCESSED === values?.deadline?.code,
      deadline: DEADLINE_DOC_IN_ENUM.PROCESSED !== values?.deadline?.code ? values?.deadlineTime : null,
      handlingDepartment: formatHandlingDepartment,
      combinedDepartment: formatCombinedDepartment,
      bod: formatBod,
      arrivalNbr: Number(values?.arrivalNbr),
      docBookId: values?.docBookId?.id,
    };

    setValuesUpload(newValues);
    setOpenModalSubmit(true);
  };

  const handleCloseModalSubmit = () => {
    setOpenModalSubmit(false);
  };

  const changeUploadFile = (e: any) => {
    const files: any = changeFileDocIn(e, file, "", MAX_LENGTH_FILE_DOC_IN);
    setFile(files);
  };

  const handleRemoveFile = (item: any) => {
    setFile(removeFile(item, file));
  };

  const handleChangeDocBookId = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("docBookId", e);
  };

  const getArrivalNumber = async (formik: FormikProps<InitialValuesForm>, docBookId: number) => {
    await getArrivalNbrApi(docBookId, DOC_BOOK_ENUM.DOC_IN_INTERNATIONAL).then((res) => {
      formik.setFieldValue("arrivalNbr", res?.data + "");
    });
  };

  const handleChangeArrivalNbr = (formik: FormikHelpers<InitialValuesForm>) => (e: Number | any) => {
    formik.setFieldValue("arrivalNbr", e.target.value);
  };

  const handleChangeHandlingDepartment = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("handlingDepartment", e);
  };

  const handleChangeCombinedDepartment = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("combinedDepartment", e);
  };

  const handleChangeBod = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("bod", e);
  };

  const handleOnSubmitModal = () => {
    let bodyFormData = new FormData();

    for (let index = 0; index < file.length; index++) {
      const element = file[index];
      bodyFormData.append("dataFile", element);
    }
    bodyFormData.append(
      "data",
      new Blob([JSON.stringify(valuesUpload)], {
        type: "application/json",
      })
    );
    createDocInApi(bodyFormData)
      .then((res) => {
        if (res) {
          navigate("/doc-in");
          toast.success(DOC_IN_MSG.createInternationalSuccess);
        }
      })
      .catch((e) => {
        const msg = getErrorMsgAxios(e);
        toast.error(msg);
        throw Error(e);
      });
  };

  const handleChangeDeadlineTime = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("deadlineTime", e);
    const formatDeadlineTime = moment(e).format(VIEW_DATE_TIME_WITHOUT_SECOND_FORMAT);
    const convertDeadlineTime = {
      code: DEADLINE_DOC_IN_ENUM.OTHER,
      description: formatDeadlineTime,
      deadlineTime: e,
    };
    formik.setFieldValue("deadline", convertDeadlineTime);
    setOpenDeadLineTimeModal(false);
  };

  const handleChangeHandlingDeadline = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("deadline", e);
    if (e?.code === DEADLINE_DOC_IN_ENUM.OTHER) {
      deadlineTimeBtnInputRef.current?.click();
      setOpenDeadLineTimeModal(true);
      setTextConfirm(DOC_IN_MSG.confirmSubmit);
    } else {
      setOpenDeadLineTimeModal(false);
      setTextConfirm(DOC_IN_MSG.confirmProcessed);
    }
  };

  return (
    <>
      <ModalSubmit
        open={openModalSubmit}
        onClose={handleCloseModalSubmit}
        handleOnSubmitModal={handleOnSubmitModal}
        textConfirm={textConfirm}
      />
      <HeaderPage title="Phiếu xử lý văn bản quốc tế đến" variant="h5" component="h5">
        <Stack direction={{ xs: "column", sm: "row" }} spacing={spacing_size} sx={{ marginBottom: "10px" }}>
          <Button variant="outlined" onClick={() => btnCancelRef?.current?.click()}>
            Nhập lại
          </Button>
          <Button
            type="submit"
            variant="contained"
            startIcon={<BrowserIcon />}
            onClick={() => btnSubmitRef?.current?.click()}
          >
            Trình duyệt
          </Button>
        </Stack>
      </HeaderPage>

      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <Form noValidate>
              <Box>
                <BoxContainer>
                  <HeaderForm title={"Thông tin chung"} />
                  <Grid container spacing={spacing_size}>
                    <Grid item xs={12} md={6} lg={6}>
                      <FieldContainer
                        fullWidth
                        name="code"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        inputProps={{ maxLength: 255 }}
                        value={formik.values.code}
                        placeholder="Nhập thông tin"
                        maxRows={12}
                        label="Số và ký hiệu văn bản"
                        notched={true}
                        variant={"input"}
                        id={"code"}
                        isRequired={true}
                        autoComplete="off"
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <FieldContainer
                        fullWidth
                        name="fromPlace"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        inputProps={{ maxLength: 255 }}
                        value={formik.values.fromPlace}
                        placeholder="Nhập thông tin"
                        label="Nơi ban hành văn bản"
                        isRequired
                        variant={"input"}
                        id={"fromPlace"}
                        autoComplete="off"
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <FieldContainer
                        fullWidth
                        name="docDate"
                        onBlur={formik.handleBlur}
                        // onChange={formik.handleChange}
                        type="text"
                        // inputProps={{ maxLength: 12 }}
                        // value={formik.values.docDate}
                        placeholderText="dd/mm/yyyy"
                        label="Ngày tháng văn bản"
                        isRequired
                        variant={"date"}
                        id={"docDate"}
                        maxDate={currentDate}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <FieldContainer
                        fullWidth
                        name="arrivalDate"
                        onBlur={formik.handleBlur}
                        // onChange={formik.handleChange}
                        type="text"
                        // inputProps={{ maxLength: 12 }}
                        // value={formik.values.arrivalDate}
                        placeholderText="dd/mm/yyyy"
                        label="Ngày văn bản đến"
                        isRequired
                        variant={"date"}
                        id={"arrivalDate"}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <FieldContainer
                        fullWidth
                        name="docBookId"
                        onBlur={formik.handleBlur}
                        onChange={(e: any) => {
                          formik.setFieldValue("docBookId", e);
                          getArrivalNumber(formik, Number(e?.id));
                        }}
                        type="text"
                        inputProps={{ maxLength: 12 }}
                        value={formik.values.docBookId}
                        placeholder="Nhập thông tin"
                        label="Sổ công văn"
                        isRequired
                        variant={"react-select"}
                        id={"docBookId"}
                        options={docBookDetail}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <FieldContainer
                        fullWidth
                        name="arrivalNbr"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeArrivalNbr}
                        type="text"
                        maxLength={5}
                        value={formik.values.arrivalNbr}
                        placeholder="Nhập thông tin"
                        label="Số đến"
                        isRequired
                        variant={"number-input"}
                        id={"arrivalNbr"}
                        // disabled
                        onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <FieldContainer
                        fullWidth
                        name="summary"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        inputProps={{ maxLength: 5000 }}
                        value={formik.values.summary}
                        placeholder="Nhập thông tin"
                        label="Trích yếu nội dung văn bản"
                        isRequired
                        variant={"textarea"}
                        id={"summary"}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <Typography>Thông tin đính kèm</Typography>
                      <p
                        style={{
                          color: "#466FFF",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Đính kèm file
                      </p>
                      {file && file.length < 5 && (
                        <>
                          <Grid container spacing={spacing_size}>
                            <Grid item sm={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                  border: "1px dashed #0240B8",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    padding: "1rem",
                                  }}
                                >
                                  <label>
                                    <img
                                      alt=""
                                      src={UploadSVG}
                                      style={{
                                        width: "65px",
                                        height: "50px",
                                      }}
                                    />
                                  </label>
                                  <Typography
                                    className="mt-2"
                                    sx={{
                                      color: "#ADAAAA",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Tải lên tài liệu liên quan
                                  </Typography>
                                </Box>
                                <input
                                  id="file-input"
                                  accept={ALLOW_INPUT_FILE_DOC_IN}
                                  type="file"
                                  multiple
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    top: 0,
                                    left: 0,
                                    border: "none",
                                    zIndex: 0,
                                    opacity: 0,
                                  }}
                                  onChange={(e: any) => changeUploadFile(e)}
                                  // #inputFile
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }} className="mt-2">
                        <Grid container item xs={12} md={12} lg={12}>
                          {file
                            ? file.map((item: any) => {
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        color: "#466FFF",
                                        fontWeight: "500",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {item.name}
                                    </Box>
                                    <Box sx={{ width: "45px" }}>
                                      <>
                                        <IconButton
                                          onClick={() => handleRemoveFile(item)}
                                          sx={{
                                            color: "#D4D9E4",
                                            fontSize: "16px",
                                          }}
                                        >
                                          <HighlightOffOutlinedIcon />
                                        </IconButton>
                                      </>
                                    </Box>
                                  </Box>
                                );
                              })
                            : null}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#0084FF",
                          fontStyle: "italic",
                        }}
                      >
                        ! Vui lòng tải file Word, Excel, PDF, PNG, JPG, JPEG, RAR, ZIP
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Stack direction="row" sx={{ flexWrap: "nowrap" }} alignItems="flex-end">
                        <FieldContainer
                          fullWidth
                          name="deadline"
                          onBlur={formik.handleBlur}
                          onChange={handleChangeHandlingDeadline(formik)}
                          value={formik.values.deadline}
                          placeholder="Chọn thông tin"
                          label="Thời hạn xử lý theo văn bản"
                          // isRequired
                          variant={"react-select"}
                          id={"deadline"}
                          getOptionValue={(item: EnumCommon<DEADLINE_DOC_IN_ENUM>) => item.code}
                          getOptionLabel={(item: EnumCommon<DEADLINE_DOC_IN_ENUM>) => item.description}
                          isClearable
                          options={deadlineDocIns}
                          menuPlacement="top"
                          styleContainer={{ width: "100%" }}
                        />
                        {(formik.values?.deadline?.code === DEADLINE_DOC_IN_ENUM.OTHER || true) && (
                          <Typography
                            sx={{
                              opacity: formik.values?.deadline?.code === DEADLINE_DOC_IN_ENUM.OTHER ? 1 : 0,
                              width: formik.values?.deadline?.code === DEADLINE_DOC_IN_ENUM.OTHER ? "initial" : 0,
                            }}
                          >
                            <FieldContainer
                              fullWidth
                              name="deadlineTime"
                              onBlur={() => {
                                setOpenDeadLineTimeModal(true);
                              }}
                              onChange={handleChangeDeadlineTime(formik)}
                              value={formik.values.deadlineTime}
                              placeholderText="dd/mm/yyyy"
                              label=""
                              variant={"date"}
                              id={"deadlineTime"}
                              dateFormat="dd/MM/yyyy hh:mm aa"
                              showTimeSelect
                              // open={formik.values?.deadline?.code === DEADLINE_DOC_IN_ENUM.OTHER && openDeadLineTimeModal}
                              customInput={
                                <CustomInputCalendar btnRef={deadlineTimeBtnInputRef} onMouseDown={() => {}} />
                              }
                            />
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </BoxContainer>
              </Box>

              <Box>
                <BoxContainer>
                  <Typography sx={{ fontSize: "1.2rem", fontWeight: "600" }}>Nội dung văn bản được dịch</Typography>
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <FieldContainer
                        fullWidth
                        name="contentTranslated"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        inputProps={{ maxLength: 12 }}
                        value={formik.values.contentTranslated}
                        placeholder="Nhập thông tin"
                        label=""
                        isRequired
                        variant={"textarea"}
                        id={"contentTranslated"}
                      />
                    </Grid>
                  </Grid>
                </BoxContainer>
              </Box>

              <Box>
                <BoxContainer>
                  <Typography sx={{ fontSize: "1.2rem", fontWeight: "600" }}>Quá trình luân chuyển văn bản</Typography>
                  <Grid container spacing={spacing_size}>
                    <Grid item xs={12} md={4} lg={4}>
                      <FieldContainer
                        fullWidth
                        name="handlingDepartment"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeHandlingDepartment(formik)}
                        // onChange={formik.handleChange}
                        type="text"
                        inputProps={{ maxLength: 12 }}
                        value={formik.values.handlingDepartment}
                        placeholder="Chọn thông tin"
                        label="Đơn vị/phòng chủ trì giải quyết"
                        isRequired
                        variant={"react-select"}
                        id={"handlingDepartment"}
                        isClearable
                        options={departmentDetail}
                        menuPlacement="top"
                        isMulti
                      />
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <FieldContainer
                        fullWidth
                        name="combinedDepartment"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeCombinedDepartment(formik)}
                        type="text"
                        inputProps={{ maxLength: 12 }}
                        value={formik.values.combinedDepartment}
                        placeholder="Nhập/chọn thông tin"
                        label="Đơn vị/phòng phối hợp thực hiện"
                        // isRequired
                        variant={"react-select"}
                        id={"combinedDepartment"}
                        isClearable
                        isMulti
                        options={departmentDetail}
                        menuPlacement="top"
                      />
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <FieldContainer
                        fullWidth
                        name="bod"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeBod(formik)}
                        type="text"
                        inputProps={{ maxLength: 12 }}
                        value={formik.values.bod}
                        placeholder="Nhập thông tin"
                        label="Báo cáo lãnh đạo/Ban Tổng thư ký"
                        isRequired
                        variant={"react-select"}
                        id={"bod"}
                        options={userDetail}
                        isMulti
                        menuPlacement="top"
                      />
                    </Grid>
                  </Grid>
                </BoxContainer>
              </Box>

              <Grid item sm={12} justifyContent="center">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={spacing_size}
                  sx={{ marginBottom: "10px", justifyContent: "end" }}
                >
                  <Button variant="outlined" onClick={() => handleCancle(formik)} ref={btnCancelRef}>
                    Nhập lại
                  </Button>
                  <Button type="submit" variant="contained" ref={btnSubmitRef} startIcon={<BrowserIcon />}>
                    Trình duyệt
                  </Button>
                </Stack>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default FormCreateDocInInternational;
