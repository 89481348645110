import { DocInModel, RequestFilterDocInModel, ResponseFilterDocInModel } from "./../model/doc-in.model";
import HTTP_DOC_SERVICE from "../config/axios/axios-doc.config";
import { Pageable } from "../model/page.model";
import { STATUS_DOC_IN_ENUM } from "../enum/doc-in.enum";

export const createDocInApi = (data: FormData) => {
  return HTTP_DOC_SERVICE.post("/doc-in", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      type: "formData",
    },
  });
};

export const updateDocInApi = (data: FormData) => {
  return HTTP_DOC_SERVICE.put("/doc-in", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      type: "formData",
    },
  });
};

export const filterDocInApi = (body: RequestFilterDocInModel | null, pageable: Pageable) => {
  return HTTP_DOC_SERVICE.post<ResponseFilterDocInModel[]>("/doc-in/filter", body, {
    params: { ...pageable },
  });
};

export const exportDocInApi = (body: RequestFilterDocInModel | null, pageable: Pageable) => {
  return HTTP_DOC_SERVICE.post("/doc-in/filter/excel-doc-in", body, {
    params: { ...pageable },
    responseType: "blob",
  });
};

export const getArrivalNbrApi = (docBookId: number, docBookType: string) => {
  return HTTP_DOC_SERVICE.get(`/doc-in/arrival-nbr?docBookId=${docBookId}&docBookType=${docBookType}`);
};

export const getHistoryDocInByIdApi = (pageable: Pageable) => {
  return HTTP_DOC_SERVICE.get("/doc-in-history", {
    params: { ...pageable },
  });
};

export const getDocInByIdApi = (id: number) => {
  return HTTP_DOC_SERVICE.get<DocInModel>(`/doc-in/${id}`);
};

export const downloadFileDocIn = (docFileId: number) => {
  return HTTP_DOC_SERVICE.get("doc-in/file/download", {
    params: { docFileId },
    responseType: "blob",
  });
};

// Trình duyệt
export const docInUnassignedApi = (id: number, body: any) => {
  return HTTP_DOC_SERVICE.put(`/doc-in/unassigned/${id}`, body);
};

// Hoàn trả
export const docInReturnApi = (id: number, data: any) => {
  return HTTP_DOC_SERVICE.put(`/doc-in/return/${id}`, data);
};

// Phân công
export const docInAssignmentApi = (data: any) => {
  return HTTP_DOC_SERVICE.post("/doc-in/assignment", data);
};

// Huỷ
export const docInCancelApi = (id: number) => {
  return HTTP_DOC_SERVICE.patch(`/doc-in/cancel/${id}`);
};

// Phúc đáp
export const docInReplyApi = (id: number) => {
  return HTTP_DOC_SERVICE.put(`/doc-in/reply/${id}`);
};

// Xử lý
export const docInProcessionApi = (id: number) => {
  return HTTP_DOC_SERVICE.get(`/doc-in/procession`, {
    params: { id },
  });
};

// get all doc in by status in
export const getAllDocInByStatusIn = (status: STATUS_DOC_IN_ENUM[]) => {
  return HTTP_DOC_SERVICE.get<DocInModel[]>(`/doc-in`, {
    params: { status: status.join(",") },
  });
};

export const getDocInDashboard = (body: any, pageable: Pageable) => {
  return HTTP_DOC_SERVICE.post<any>(`/doc-in/dashboard`, body, { params: { ...pageable } })
}

export const exportDocInDashboard = (body: any) => {
  return HTTP_DOC_SERVICE.post<any>(`/doc-in/dashboard/excel`, body, { responseType: "blob" })
}

export const getDashboardDocIns = (body: any, pageable: Pageable) => {
  return HTTP_DOC_SERVICE.post<any>(`/doc-in/dashboard/doc-ins`, body, { params: { ...pageable } })
}

export const exportDashboardDocIns = (body: any) => {
  return HTTP_DOC_SERVICE.post<any>(`/doc-in/dashboard/doc-ins/excel`, body, { responseType: "blob" })
}
