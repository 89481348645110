import React, { FC, useRef, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import BoxContainer from "../../../components/common/BoxContainer";
import { DocumentModel, PersonnelModel } from "../../../model/personnel.model";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import { spacing_size } from "../../../themes/size";
import FieldContainer from "../../../components/form/FieldContainer";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import ViewFile from "../../../components/common/ViewFile";
import ChooseFile from "../../../components/icons/ChooseFile";
import TextError from "../../../components/form/TextError";
import {
  changeFileUploadPersonnel,
  downloadFileHrByType,
  downloadTempHrFileByType,
  exportFileFromBrowser,
  isExistDocumentCode,
} from "../common/util/personnel.util";
import { header_table_color } from "../../../themes/color";
import { UPLOAD_DATE_FORMAT, VIEW_DATE_FORMAT } from "../../../constants/date-time.constant";
import ModalDelete from "../modal/ModalDelete";
import ModalUpdateDocumentHr from "../modal/ModalUpdateDocumentHr";
import { formatDateTime } from "../../../utils/data-time.util";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import { TYPE_FILE_ENUM } from "../../../enum/hr.enum";
import { subStringFileName } from "../../../utils/file.util";
import { subStringFunc } from "../../../utils/string.util";
interface DocumentProps {
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  valueUploads: PersonnelModel | null;
  fileDocumentsUpload: File[];
  setFileDocumentsUpload: React.Dispatch<React.SetStateAction<File[]>>;
  valuesDocuments: DocumentModel[] | null | undefined | any;
  setValuesDocuments: React.Dispatch<React.SetStateAction<DocumentModel[] | null | undefined>>;
}

const Document: FC<DocumentProps> = (props) => {
  const {
    valueUploads,
    setValuesUploads,
    fileDocumentsUpload,
    setFileDocumentsUpload,
    valuesDocuments,
    setValuesDocuments,
  } = props;

  const initialValues: DocumentModel = {
    issuedDate: null,
    code: "",
    note: "",
    fileName: "",
    fileTemp: "",
  };

  const arrItemIdDelete: number[] | any = [];
  const currentTime = new Date().getTime();
  const arrDocumentsDetail: any = valueUploads?.documents;
  const [documentsDetail, setDocumentsDetail] = useState<DocumentModel[]>(arrDocumentsDetail);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);
  const [indexDocument, setIndexDocument] = useState<number>(0);
  const [itemDocument, setItemDocument] = useState<DocumentModel | null>(null);
  const [arrId, setArrId] = useState<any>([]);
  const [arrIdDelete, setArrIdDelete] = useState<any>([]);

  const [file, setFile] = useState<File[]>([]);
  const [arrFile, setArrFile] = useState<File[] | any>([]);
  const [arrFileDeleteId, setArrFileDeleteId] = useState<number[]>([]);
  const fileRef = useRef<any>(null);

  const validationSchema = Yup.object({
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Số văn bản")),
  });

  const handleOpenModalDelete = async (item: DocumentModel, index: number, e: React.SyntheticEvent) => {
    setOpenModalDelete(true);
    setIndexDocument(index);
    setItemDocument(item);
  };

  const handleCloseModal = () => {
    setOpenModalDelete(false);
  };

  const handleOpenModalUpdate = (item: DocumentModel, index: number) => {
    setOpenModalUpdate(true);
    setItemDocument(item);
    setIndexDocument(index);
  };

  const handleCloseModalUpdate = () => {
    setOpenModalUpdate(false);
  };

  const changeUploadFile = (formik: FormikHelpers<DocumentModel>) => (e: any) => {
    e.preventDefault();
    formik.setFieldValue("fileTemp", e.target.files[0]);
    const fileUp = changeFileUploadPersonnel(e, file, "", 1);
    const newFileUp = fileUp?.map((item: File, index: number) => {
      const newNameItem = currentTime + "_" + item?.name;
      const newItem = new File([item], newNameItem, { type: item.type });
      return newItem;
    });
    setFile(newFileUp);
    setArrFile([...arrFile, ...newFileUp]);
  };

  const buildBody = (values: DocumentModel) => {
    const newValues = {
      ...values,
      fileName: file[0]?.name || "",
      issuedDate: formatDateTime(values?.issuedDate, UPLOAD_DATE_FORMAT),
    };
    return newValues;
  };

  const handleOnSubmit = (values: DocumentModel, formik: FormikHelpers<DocumentModel>) => {
    if (isExistDocumentCode(valuesDocuments, values?.code)) {
      toast.error("Số văn bản không được trùng nhau!");
      return;
    }

    const newValues = buildBody(values);
    setDocumentsDetail([...documentsDetail, newValues]);
    setValuesDocuments([...valuesDocuments, newValues]);
    setValuesUploads({ ...valueUploads, documents: [...documentsDetail, newValues] });
    setFileDocumentsUpload(arrFile);
    formik.resetForm();
    setFile([]);
  };

  const handleDeleteItem = () => {
    const item: any = valuesDocuments?.filter((item: DocumentModel, i: number) => i !== indexDocument);
    const itemId: any = item?.filter?.((i: DocumentModel) => i?.id)?.map((item: DocumentModel) => item?.id);
    const itemUpload: any = item?.filter((i: DocumentModel) => !itemId?.includes(i?.id));
    const newArrFile = arrFile?.filter((item1: File, i: number) => item1?.name !== itemDocument?.fileName);
    const itemIdDelete: any = valuesDocuments
      ?.filter((item: DocumentModel, i: number) => i === indexDocument)
      ?.map((i: DocumentModel) => (i?.id ? i?.id : null));
    arrItemIdDelete.push(...itemIdDelete);
    if (!itemIdDelete?.includes(null)) {
      arrItemIdDelete.push(...arrIdDelete, ...itemIdDelete);
      setArrIdDelete([...arrIdDelete, ...itemIdDelete]);
    }
    setArrFile(newArrFile);
    setFileDocumentsUpload(newArrFile);
    setValuesDocuments(item);
    setDocumentsDetail(itemUpload);
    setValuesUploads({ ...valueUploads, documents: [...itemUpload], deleteDocumentIds: arrItemIdDelete });
  };

  const handleDownload = (item: DocumentModel) => {
    if (item?.documentFile && item?.documentFile?.id) {
      downloadFileHrByType(item?.documentFile?.id, item?.documentFile?.name, TYPE_FILE_ENUM.DOCUMENT);
    } else {
      downloadTempHrFileByType(item?.fileTemp, item?.fileTemp?.name, TYPE_FILE_ENUM.DOCUMENT);
    }
  };

  return (
    <>
      {openModalUpdate && (
        <ModalUpdateDocumentHr
          open={openModalUpdate}
          onClose={handleCloseModalUpdate}
          valueUploads={valueUploads}
          setValuesUploads={setValuesUploads}
          itemDocument={itemDocument}
          indexDocument={indexDocument}
          setDocumentsDetail={setDocumentsDetail}
          fileDocumentsUpload={fileDocumentsUpload}
          setFileDocumentsUpload={setFileDocumentsUpload}
          valuesDocuments={valuesDocuments}
          setValuesDocuments={setValuesDocuments}
          arrId={arrId}
          setArrId={setArrId}
          arrFileDeleteId={arrFileDeleteId}
          setArrFileDeleteId={setArrFileDeleteId}
        />
      )}
      {openModalDelete && (
        <ModalDelete open={openModalDelete} onClose={handleCloseModal} handleDeleteItem={handleDeleteItem} />
      )}
      <Box>
        <BoxContainer>
          <Typography sx={{ fontSize: "1.2rem", fontWeight: "500", marginBottom: "0.6rem" }}>
            Thêm mới tài liệu
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={true}
            // enableReinitialize={true}
          >
            {(formik) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="issuedDate"
                      onBlur={formik.handleBlur}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.issuedDate}
                      placeholderText={FORM_MSG.dateFormatPlaceholder}
                      maxRows={12}
                      label="Ngày ban hành"
                      notched={true}
                      variant={"date"}
                      id={"issuedDate"}
                      // isRequired={true}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="code"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.code}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Số văn bản"
                      notched={true}
                      variant={"input"}
                      id={"code"}
                      isRequired={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FieldContainer
                      fullWidth
                      name="note"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.note}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Ghi chú"
                      notched={true}
                      variant={"input"}
                      id={"note"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <Stack direction="row" alignItems="center" spacing={spacing_size}>
                      <Typography sx={{ fontSize: "1rem", fontWeight: "600", color: "#323E5D" }}>
                        Đính kèm{" "}
                        {/* <Box component="span" sx={{ color: "red" }}>
                          *
                        </Box> */}
                      </Typography>
                      <Button
                        variant="contained"
                        color="cancel"
                        // sx={{ display: "inline-block" }}
                        onClick={() => fileRef.current.click()}
                        disabled={file?.length < 1 ? false : true}
                        startIcon={<ChooseFile />}
                      >
                        Chọn file
                      </Button>
                    </Stack>
                    <Field
                      id="file-input"
                      name="fileTemp"
                      // accept="application/msword, application/vnd.ms-excel, application/pdf"
                      accept=".xlsx,.xls,.doc,.docx,.pdf"
                      type="file"
                      innerRef={fileRef}
                      style={{ display: "none" }}
                      value={""}
                      onChange={changeUploadFile(formik)}
                    />
                    {file?.map((item, index) => {
                      const newItem = subStringFileName(item);
                      return (
                        <ViewFile
                          key={index}
                          item={newItem}
                          handleRemove={() => {
                            setFile(file.filter((file) => item !== file));
                            setFile(arrFile.filter((file: File) => item !== file));
                            formik.setFieldValue("fileTemp", "");
                          }}
                          onClickItem={() => exportFileFromBrowser(file)}
                        />
                      );
                    })}
                    <ErrorMessage name="fileTemp" component={TextError} />
                  </Grid>

                  <Grid item xs={12} justifyContent="center">
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={spacing_size}
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      <Button startIcon={<BrowserIcon />} variant="contained" color="primary" type="submit">
                        Lưu thông tin tài liệu
                      </Button>
                      <Button
                        // ref={btnSubmitEducationtRef}
                        variant="contained"
                        color="primary"
                        type="button"
                        sx={{ display: "none" }}
                        // onClick={() => handleClickSave(formik)}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </BoxContainer>
      </Box>
      {valuesDocuments && valuesDocuments?.length > 0 && (
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: header_table_color }}>
                  <TableCell sx={{ fontWeight: 600 }}>Ngày ban hành</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Số văn bản</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Ghi chú</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Đính kèm</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    Hành động
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {valuesDocuments?.map((item: DocumentModel, index: number) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                    }}
                    key={index}
                  >
                    <TableCell>{item?.issuedDate ? moment(item?.issuedDate).format(VIEW_DATE_FORMAT) : ""}</TableCell>
                    <TableCell>{item?.code}</TableCell>
                    <TableCell>{item?.note}</TableCell>
                    <TableCell>
                      <Typography sx={{ color: "#0084FF", cursor: "pointer" }} onClick={() => handleDownload(item)}>
                        {subStringFunc(item?.fileName) || item?.documentFile?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={spacing_size}
                        sx={{
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          type="button"
                          onClick={(e) => handleOpenModalDelete(item, index, e)}
                        >
                          Xoá
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          type="button"
                          onClick={() => handleOpenModalUpdate(item, index)}
                        >
                          Cập nhật
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <PaginationPage total={10} page={1} onChange={handleChangePage} /> */}
        </Box>
      )}
    </>
  );
};

export default Document;
