import React from "react";
import HeaderCreate from "./HeaderCreate";
import StepperCreate from "./StepperCreate";

function CreatePersonnel() {
  return (
    <>
      <HeaderCreate />
      <StepperCreate />
    </>
  );
}

export default CreatePersonnel;
