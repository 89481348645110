import { Box } from "@mui/material";
import React, { FC } from "react";
import { Params, useParams } from "react-router-dom";
import HeaderPage from "../../../../components/common/HeaderPage";
import FormHistoryDocIn from "./FormHistoryDocIn";
import ResultHistoryDocIn from "./ResultHistoryDocIn";

interface HistoryDocInProps {}

const HistoryDocIn: FC<HistoryDocInProps> = (props) => {
  const params: Readonly<Params<string>> = useParams();
  const convertId = Number(params?.id);
  return (
    <>
      <Box>
        <HeaderPage title="Lịch sử công văn"></HeaderPage>
        <FormHistoryDocIn id={convertId} />
        <ResultHistoryDocIn id={convertId} />
      </Box>
    </>
  );
};

export default HistoryDocIn;
