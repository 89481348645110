import React from "react";
import ViewDashboardDocIn from "./doc-in/ViewDashboardDocIn";
import ViewDashboardDocOut from "./doc-out/ViewDashboardDocOut";
import ViewDashboardTask from "./task/ViewDashboardTask";

const Dashboard = () => {
  return (
    <>
      <ViewDashboardDocIn />
      <ViewDashboardTask />
      <ViewDashboardDocOut />
    </>
  );
};

export default Dashboard;
