import { Button, Grid, Stack } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { FC, SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import BoxContainer from "../../../components/common/BoxContainer";
import SearchIcon from "../../../components/icons/SearchIcon";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { PAGEABLE_DEFAULT, SIZE_DEFAULT } from "../../../constants/page.constant";
import { EnumCommon } from "../../../enum/common.enum";
import { TYPE_PERSONNEL_ENUM } from "../../../enum/personnel.enum";
import { FilterEmployeeModel, HrDepartmentModel, HrPositionModel } from "../../../model/personnel.model";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { spacing_size } from "../../../themes/size";
import { OptionCommon, optionStatus, optionType } from "../common/constants/personnel.constant";
import { filterEmployeeApiAsync, setFilterPersonnel, setPagePersonnel } from "../redux/personnel.reducer";
import { OptionObject } from "./PersonnelList";

interface FormSearchProps {
  filterOptions: OptionObject[];
  dataDepartments: HrDepartmentModel[];
  dataPositions: HrPositionModel[];
  setDepartment: any;
  setPosition: any;
  setType: any;
  setStatus: any;
  setBodyFilter: React.Dispatch<SetStateAction<FilterEmployeeModel>>;
  bodyFilter: FilterEmployeeModel;
  initValue: FilterEmployeeModel;
  setStateLocation: any;
  setInitValue: React.Dispatch<SetStateAction<FilterEmployeeModel>>;
}

const FormSearch: FC<FormSearchProps> = (props) => {
  const {
    bodyFilter,
    initValue,
    filterOptions,
    dataDepartments,
    dataPositions,
    setDepartment,
    setPosition,
    setType,
    setStatus,
    setBodyFilter,
    setStateLocation,
    setInitValue,
  } = props;

  const initBodyFilter: FilterEmployeeModel = {
    keyword: null,
    phone: null,
    position: null,
    department: null,
    startDate: null,
    endDate: null,
    status: null,
    type: null,
    taxCode: null,
    identityCard: null,
    passport: null,
  };

  const initialValues: FilterEmployeeModel = {
    keyword: "",
    phone: "",
    department: "",
    position: "",
    startDate: null,
    endDate: null,
    type: "",
    status: "",
    taxCode: "",
    identityCard: "",
    passport: "",
  };

  const dispatch = useAppDispatch();
  const location = useLocation();
  const page = useAppSelector((state) => state.personnel.pagePersonnel);

  const reloadFilterPersonnel = useAppSelector((state) => state.personnel.reloadFilterPersonnel);

  const [isInitFilter, setIsInitFilter] = useState<boolean>(true);

  const validationSchema = Yup.object({
    endDate: Yup.date()
      .nullable()
      .when("startDate", (startDate: any, schema: any) => {
        if (startDate) {
          return schema.min(startDate, FORM_MSG.fromDateMoreToDate);
        }
        return schema;
      }),
    toDate: Yup.date().nullable(),
  });

  const loadDataFilter = async (body = bodyFilter) => {
    const pageable = { page, size: SIZE_DEFAULT };
    await dispatch(filterEmployeeApiAsync({ body, pageable }));
  };

  useEffect(() => {
    if (reloadFilterPersonnel !== 0) {
      loadDataFilter();
    }
  }, [reloadFilterPersonnel]);

  useEffect(() => {
    if (isInitFilter) {
      setIsInitFilter(false);
      const stateLocation = location?.state;
      if (page > 0 && stateLocation === null) {
        dispatch(setPagePersonnel(0));
      }
      const body = {
        keyword: stateLocation?.bodyFilter?.keyword || null,
        phone: stateLocation?.bodyFilter?.phone || null,
        department: stateLocation?.bodyFilter?.department || null,
        position: stateLocation?.bodyFilter?.position || null,
        startDate: stateLocation?.bodyFilter?.startDate || null,
        endDate: stateLocation?.bodyFilter?.endDate || null,
        status: stateLocation?.bodyFilter?.status || null,
        type: stateLocation?.bodyFilter?.type || null,
        taxCode: stateLocation?.bodyFilter?.taxCode || null,
        identityCard: stateLocation?.bodyFilter?.identityCard || null,
        passport: stateLocation?.bodyFilter?.passport || null,
      };
      loadDataFilter(body);
    } else {
      loadDataFilter();
    }
  }, [page]);

  const handleChangeDepartment = (formik: FormikHelpers<FilterEmployeeModel>) => (e: HrDepartmentModel) => {
    formik.setFieldValue("department", e);
    setDepartment(e);
  };

  const handleChangePosition = (formik: FormikHelpers<FilterEmployeeModel>) => (e: HrPositionModel) => {
    formik.setFieldValue("position", e);
    setPosition(e);
  };

  const handleChangeStatus = (formik: FormikHelpers<FilterEmployeeModel>) => (e: HrPositionModel) => {
    formik.setFieldValue("status", e);
    setStatus(e);
  };

  const handleChangeType = (formik: FormikHelpers<FilterEmployeeModel>) => (e: HrPositionModel) => {
    formik.setFieldValue("type", e);
    setType(e);
  };

  const buildBodyFilter = (value: FilterEmployeeModel) => {
    const bodyFormat = {
      position: value.position as HrPositionModel,
      department: value.department as HrDepartmentModel,
      status: value.status as OptionCommon,
      type: value.type as EnumCommon<TYPE_PERSONNEL_ENUM> | any,
    };

    const body = {
      ...value,
      keyword: value?.keyword || null,
      phone: value?.phone || null,
      position: bodyFormat.position?.id != null ? bodyFormat.position?.id : null,
      department: bodyFormat.department?.id != null ? bodyFormat.department?.id : null,
      startDate: value?.startDate || null,
      endDate: value?.endDate || null,
      status: bodyFormat.status?.value != null ? bodyFormat.status?.value : null,
      type: bodyFormat.type?.code != null ? bodyFormat.type?.code : null,
      taxCode: value?.taxCode || null,
      identityCard: value?.identityCard || null,
      passport: value?.passport || null,
    };
    return body;
  };
  const onSubmitSearch = (value: FilterEmployeeModel, formik: FormikHelpers<FilterEmployeeModel>) => {
    const body = buildBodyFilter(value);
    setBodyFilter(body);
    if (page === 0) {
      const pageable = {
        page: 0,
        size: SIZE_DEFAULT,
      };
      dispatch(filterEmployeeApiAsync({ body: body, pageable }));
      // dispatch(setFilterPersonnel({ ...body, keyword: null }));
    } else {
      dispatch(setPagePersonnel(0));
    }
  };
  const handleCancel = (formik: FormikHelpers<FilterEmployeeModel>) => {
    formik.resetForm();
    setBodyFilter(initBodyFilter);
    setInitValue(initialValues);
    setDepartment(null);
    setPosition(null);
    setType(null);
    setStatus(null);
    if (page === 0) {
      loadDataFilter(initBodyFilter);
    } else {
      dispatch(setPagePersonnel(0));
    }
    setStateLocation(null);
    window.history.replaceState(setStateLocation(null), "", "/personnel");
  };

  return (
    <>
      {filterOptions.find((item) => item.check == true) != null ? (
        <BoxContainer>
          <Formik
            initialValues={initValue}
            validationSchema={validationSchema}
            onSubmit={onSubmitSearch}
            validateOnBlur={false}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={spacing_size}>
                  {filterOptions.map((item, index) => {
                    if (!item.check) return null;
                    else if (item.key === "department")
                      return item.component(formik, dataDepartments, handleChangeDepartment);
                    else if (item.key === "position")
                      return item.component(formik, dataPositions, handleChangePosition);
                    else if (item.key === "status") return item.component(formik, optionStatus, handleChangeStatus);
                    else if (item.key === "type") return item.component(formik, optionType, handleChangeType);
                    return item.component(formik);
                  })}
                  <Grid item xs={12} md={12} justifyContent="center">
                    {/* <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={spacing_size}
                        sx={{
                          justifyContent: "center",
                        }}
                      >

                        <Button variant="outlined" color="primary" type="submit" >
                          Hủy
                        </Button>
                      </Stack> */}
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={spacing_size}
                      sx={{
                        mt: 2,
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="cancel"
                        type="button"
                        onClick={() => {
                          handleCancel(formik);
                        }}
                      >
                        Hủy
                      </Button>
                      <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                        Tìm kiếm
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </BoxContainer>
      ) : null}
    </>
  );
};

export default FormSearch;
