import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import React, { FC, useState } from "react";
import * as Yup from "yup";
import BoxContainer from "../../../components/common/BoxContainer";
import ReadMore from "../../../components/common/ReadMore";
import FieldContainer from "../../../components/form/FieldContainer";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import { UPLOAD_DATE_FORMAT, VIEW_MONTH_FORMAT } from "../../../constants/date-time.constant";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { PersonnelModel, WorkingProcessModel } from "../../../model/personnel.model";
import { header_table_color } from "../../../themes/color";
import { spacing_size } from "../../../themes/size";
import { formatDateTime } from "../../../utils/data-time.util";
import ModalDelete from "../modal/ModalDelete";
import ModalUpdateWP from "../modal/ModalUpdateWP";
import { isEmptyArr } from "../../../utils/array.util";

interface WorkingProcessProps {
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  valueUploads: PersonnelModel | null;
  btnSubmitWorkingProcessRef: React.RefObject<HTMLButtonElement>;
}

const WorkingProcess: FC<WorkingProcessProps> = (props) => {
  const { valueUploads, setValuesUploads, btnSubmitWorkingProcessRef } = props;

  const initialValues: WorkingProcessModel = {
    startDate: null,
    endDate: null,
    position: "",
    workPlace: "",
    workContent: "",
    workDepartment: "",
  };

  const validationSchema = Yup.object({
    startDate: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Thời gian làm việc từ tháng/năm")),
    endDate: Yup.date()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg("Thời gian làm việc đến tháng/năm"))
      .when("startDate", (startDate: any, schema: any) => {
        if (startDate) {
          return schema.min(
            startDate,
            "Thời gian làm việc đến tháng/năm phải lớn hơn Thời gian làm việc từ tháng/năm."
          );
        }
        return schema;
      }),
    // position: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Chức vụ")),
    // workPlace: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Bộ phận công tác công tác")),
  });

  const arrWorkingProcess: WorkingProcessModel[] | null | undefined | any = valueUploads?.employeeWorkingHistories;
  const [workingProcessDetail, setWorkingProcessDetail] = useState<WorkingProcessModel[]>(arrWorkingProcess);
  const [openModalWorkingProcess, setOpenModalWorkingProcess] = useState<boolean>(false);
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);
  const [indexWorkingProcess, setIndexWorkingProcess] = useState<number>(0);
  const [itemWorkingProcess, setItemWorkingProcess] = useState<WorkingProcessModel | null>(null);

  const handleOpen = async (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    setOpenModalWorkingProcess(true);
    setIndexWorkingProcess(index);
  };

  const handleCloseModal = () => {
    setOpenModalWorkingProcess(false);
  };

  const handleOpenModalUpdate = (item: WorkingProcessModel, index: number) => {
    setOpenModalUpdate(true);
    setItemWorkingProcess(item);
    setIndexWorkingProcess(index);
  };

  const handleCloseModalUpdate = () => {
    setOpenModalUpdate(false);
  };

  const buildBody = (values: WorkingProcessModel) => {
    const newValues = {
      ...values,
      startDate: formatDateTime(values?.startDate, UPLOAD_DATE_FORMAT),
      endDate: formatDateTime(values?.endDate, UPLOAD_DATE_FORMAT),
    };
    return newValues;
  };

  const handleOnSubmit = (values: WorkingProcessModel, formik: FormikHelpers<WorkingProcessModel>) => {
    const newValues = buildBody(values);
    setWorkingProcessDetail([...workingProcessDetail, newValues]);
    setValuesUploads({ ...valueUploads, employeeWorkingHistories: [...workingProcessDetail, newValues] });
    formik.resetForm();
  };

  const handleDeleteItem = () => {
    const item: any = workingProcessDetail?.filter((item: WorkingProcessModel, i: number) => i !== indexWorkingProcess);
    setWorkingProcessDetail(item);
    setValuesUploads({ ...valueUploads, employeeWorkingHistories: [...item] });
  };

  const handleClickSave = (formik: FormikProps<WorkingProcessModel>) => {
    // setValuesUploads({ ...valueUploads, employeeWorkingHistories: [...workingProcessDetail] });
  };

  return (
    <>
      {openModalUpdate && (
        <ModalUpdateWP
          open={openModalUpdate}
          onClose={handleCloseModalUpdate}
          itemWorkingProcess={itemWorkingProcess}
          indexWorkingProcess={indexWorkingProcess}
          valueUploads={valueUploads}
          setValuesUploads={setValuesUploads}
          setWorkingProcessDetail={setWorkingProcessDetail}
        />
      )}

      {openModalWorkingProcess && (
        <ModalDelete open={openModalWorkingProcess} onClose={handleCloseModal} handleDeleteItem={handleDeleteItem} />
      )}

      {/* form */}
      <Box>
        <BoxContainer>
          <Typography sx={{ fontSize: "1.2rem", fontWeight: "500", marginBottom: "0.6rem" }}>
            Thêm mới quá trình công tác
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={true}
            // enableReinitialize={true}
          >
            {(formik) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      name="startDate"
                      onBlur={formik.handleBlur}
                      value={formik.values.startDate}
                      notched={true}
                      variant={"date"}
                      id={"startDate"}
                      label="Thời gian làm việc từ tháng/năm"
                      isRequired={true}
                      placeholderText={FORM_MSG?.monthFormatPlaceholder}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      name="endDate"
                      onBlur={formik.handleBlur}
                      value={formik.values.endDate}
                      notched={true}
                      variant={"date"}
                      id={"endDate"}
                      label="Thời gian làm việc đến tháng/năm"
                      isRequired={true}
                      placeholderText={FORM_MSG?.monthFormatPlaceholder}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="position"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.position}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Chức vụ"
                      notched={true}
                      variant={"input"}
                      id={"position"}
                      // isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="workDepartment"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.workDepartment}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Bộ phận công tác "
                      notched={true}
                      variant={"input"}
                      id={"workDepartment"}
                      // isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="workPlace"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.workPlace}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Nơi làm việc"
                      notched={true}
                      variant={"input"}
                      id={"workPlace"}
                      // isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={9}>
                    <FieldContainer
                      fullWidth
                      name="workContent"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.workContent}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label="Ghi chú"
                      notched={true}
                      variant={"input"}
                      id={"workContent"}
                    />
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={spacing_size}
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      <Button startIcon={<BrowserIcon />} variant="contained" color="primary" type="submit">
                        Lưu quá trình công tác
                      </Button>

                      <Button
                        ref={btnSubmitWorkingProcessRef}
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={() => handleClickSave(formik)}
                        sx={{ display: "none" }}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </BoxContainer>
      </Box>

      {/* show list data */}
      {!isEmptyArr(workingProcessDetail) && (
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: header_table_color }}>
                  <TableCell sx={{ fontWeight: 600 }}>Thời gian</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Chức vụ</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Bộ phận công tác công tác</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Nơi làm việc</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Ghi chú</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    Hành động
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workingProcessDetail?.map((item: WorkingProcessModel, index: number) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                    }}
                    key={index}
                  >
                    <TableCell>
                      {moment(item?.startDate).format(VIEW_MONTH_FORMAT)} -{" "}
                      {moment(item?.endDate).format(VIEW_MONTH_FORMAT)}
                    </TableCell>
                    <TableCell>{item?.position}</TableCell>
                    <TableCell>{item?.workDepartment}</TableCell>
                    <TableCell>{item?.workPlace}</TableCell>
                    <TableCell>
                      <ReadMore text={item?.workContent} />
                    </TableCell>
                    <TableCell align="center">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={spacing_size}
                        sx={{
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          type="button"
                          onClick={(e: any) => handleOpen(index, e)}
                        >
                          Xoá
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          type="button"
                          onClick={() => handleOpenModalUpdate(item, index)}
                        >
                          Cập nhật
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <PaginationPage total={10} page={1} onChange={handleChangePage} /> */}
        </Box>
      )}
    </>
  );
};

export default WorkingProcess;
