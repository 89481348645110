import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import { confirmTaskApi } from "../../../api/task/task.api";
import ModalContainer from "../../../components/modal/ModalContainer";
import { spacing_size } from "../../../themes/size";
import { getErrorMsgAxios } from "../../../utils/error.util";

interface ModalConfirmProps {
  open: boolean;
  onClose: () => void;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
  getDetailData: () => void;
}

const ModalConfirm = (props: ModalConfirmProps) => {
  const { open, onClose, setChecked, id, getDetailData } = props;

  // const dispatch = useAppDispatch();

  const handleCancel = () => {
    setChecked(false);
    onClose();
  };

  const handleSubmit = async () => {
    try {
      const res = await confirmTaskApi(Number(id), "");
      if (res) {
        // dispatch(getByIdTaskApiAsync(Number(id)));
        getDetailData();
        setChecked(true);
        onClose();
        toast.success("Lưu thông tin thành công");
      }
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={handleCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title={"Xác nhận"}
    >
      <Typography>
        Người nhận xác nhận đã nắm thông tin và cam kết hoàn thành đúng thời gian người giao đưa ra.
      </Typography>
      <Typography>Bạn có muốn lưu thông tin này?</Typography>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={spacing_size}
        sx={{
          justifyContent: "end",
        }}
      >
        <Button variant="contained" color="cancel" type="button" onClick={handleCancel}>
          Huỷ
        </Button>
        <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>
          Xác nhận
        </Button>
      </Stack>
    </ModalContainer>
  );
};

export default ModalConfirm;
