import { Typography, useTheme } from "@mui/material";
import React, { FC, useState } from "react";

interface ReadMoreProps {
  text?: string | any;
  overSx?: any;
  showLength?: number | any;
}

const ReadMore: FC<ReadMoreProps> = (props) => {
  const { text, overSx, showLength = 100 } = props;
  const theme = useTheme();
  const { main } = theme.palette.primary;
  const [toggleReadMore, setToggleReadMore] = useState(true);

  const handleClickReadMore = (e: any) => {
    e.stopPropagation();
    setToggleReadMore(!toggleReadMore);
  };

  if (text) {
    return (
      <Typography
        style={{
          whiteSpace: "pre-line",
          textAlign: "left",
        }}
        {...overSx}
      >
        {text.length > showLength ? (
          <>
            {toggleReadMore ? text.slice(0, showLength) + "..." : text}
            &nbsp;
            <Typography component="small" sx={{ color: "#0084FF", cursor: "pointer" }} onClick={handleClickReadMore}>
              {toggleReadMore ? "Xem thêm" : "Thu gọn"}
            </Typography>
          </>
        ) : (
          text
        )}
      </Typography>
    );
  }

  return text;
};

export default ReadMore;
