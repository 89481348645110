import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FileSaver from "file-saver";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getDocBookApi } from "../../../api/doc-book.api";
import { exportDashboardDocOut, getDashboardDocOut } from "../../../api/doc-out.api";
import BoxContainer from "../../../components/common/BoxContainer";
import HeaderPage from "../../../components/common/HeaderPage";
import NotFoundFilterView from "../../../components/common/NotFoundFilterView";
import PaginationPage from "../../../components/common/Pagination";
import FieldContainer from "../../../components/form/FieldContainer";
import SearchIcon from "../../../components/icons/SearchIcon";
import { DOC_OUT_MSG } from "../../../constants/msg/msg.constant";
import { FROM_DASHBOARD_DOC_OUT } from "../../../constants/navigate.constant";
import { MAX_SIZE_DEFAULT, TOTAL_HEADER } from "../../../constants/page.constant";
import { DOC_BOOK_ENUM } from "../../../enum/doc-book.enum";
import { CategoryDocOutOptions } from "../../../enum/doc-out.enum";
import { CodeDocBookModel, DocBook } from "../../../model/doc-book.model";
import { DocOutDashboardFilterModel } from "../../../model/doc-out.model";
import { Pageable } from "../../../model/page.model";
import { header_table_color } from "../../../themes/color";
import { spacing_size } from "../../../themes/size";
import { convertDateToDD_MM_YYYY } from "../../../utils/date.util";
import { getErrorMsgAxios } from "../../../utils/error.util";

const initialValues: DocOutDashboardFilterModel = {
  docCategory: CategoryDocOutOptions[0],
  docBookId: 0,
};

const pageableDefault: Pageable = {
  page: 0,
  size: 10,
};

function ViewDashboardDocOut() {
  const navigate = useNavigate();
  const [bodyFilter, setBodyFilter] = useState<any>(null);
  const [initValue, setInitValue] = useState(initialValues);
  const [docBookFirst, setDocBookFirst] = useState<any>({});
  const [pageable, setPageable] = useState(pageableDefault);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [docBooks, setDocBooks] = useState<DocBook[]>([]);
  const getDocBook = async () => {
    const params: CodeDocBookModel = {
      code: DOC_BOOK_ENUM.DOC_OUT,
    };
    await getDocBookApi(params).then((res) => {
      setDocBooks(res.data);
      setDocBookFirst(res.data[0]);
      setBodyFilter({ docCategory: CategoryDocOutOptions[0].code, docBookId: res.data[0].id });
      setInitValue({ docBookId: { ...res.data[0] } });
    });
  };

  useEffect(() => {
    getDocBook();
  }, []);

  useEffect(() => {
    if (bodyFilter && bodyFilter?.docBookId) {
      getDashboardDocOut(bodyFilter, pageable)
        .then((res) => {
          setData(res?.data);
          const total = Number(res.headers[TOTAL_HEADER]) || 0;
          setTotalPage(total);
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            const msg = getErrorMsgAxios(err);
            toast.error(msg);
          }
        });
    }
  }, [initValue, pageable]);

  const onSubmit = (value: DocOutDashboardFilterModel) => {
    const docCategory = value?.docCategory ? value.docCategory?.code || value.docCategory : null;
    const docBookId = value?.docBookId ? value.docBookId?.id || value.docBookId : null;
    setBodyFilter({ docCategory, docBookId });
    setInitValue({ ...value });
  };

  const handleCancel = (formik: FormikProps<DocOutDashboardFilterModel>) => {
    formik.resetForm();
    setBodyFilter({ docCategory: CategoryDocOutOptions[0].code, docBookId: docBookFirst?.id });
    setInitValue({ ...initialValues, docBookId: docBookFirst });
  };

  const handleExport = async (formik: FormikProps<DocOutDashboardFilterModel>) => {
    let value = formik.values;
    let body = {
      docCategory: value.docCategory?.code || value.docCategory,
      docBookId: value.docBookId?.id || value.docBookId,
    };
    const pageable = {
      page: 0,
      size: MAX_SIZE_DEFAULT,
    };
    try {
      const res = await exportDashboardDocOut(body, pageable);
      FileSaver.saveAs(res?.data, `Tonghopsoluongcongvanditheoloaicongvan_${convertDateToDD_MM_YYYY(new Date())}.xlsx`);
      toast.success(DOC_OUT_MSG.exportDocOutDashboardSuccess);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page - 1);
    setPageable({ page: page - 1, size: 10 });
  };

  const handleNavigateToDocOut = (code: string) => {
    const docBookId = initValue.docBookId?.id || initValue.docBookId;
    navigate("/doc-out", {
      state: {
        navigateFrom: FROM_DASHBOARD_DOC_OUT,
        bodyFilter: {
          docCategory: code,
          docBook: docBookId,
        },
        docBook: docBooks.find((item: any) => item.id == docBookId),
      },
    });
  };

  return (
    <Box>
      <HeaderPage title="Tổng hợp số lượng công văn đi theo loại công văn"></HeaderPage>
      <BoxContainer>
        <Formik
          initialValues={initValue}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => {
            return (
              <>
                <Form>
                  <Grid container spacing={spacing_size} alignItems={"center"}>
                    <Grid item xs={12} md={6} lg={3}>
                      <FieldContainer
                        onChange={(e: any) => {
                          formik.setFieldValue("docCategory", e.target.value);
                        }}
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                        name="docCategory"
                        value={formik.values.docCategory}
                        label="Loại công văn đi"
                        labelOption="description"
                        valueOption="code"
                        options={CategoryDocOutOptions}
                        variant={"select"}
                        id={"docCategory"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <FieldContainer
                        onChange={formik.handleChange}
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                        name="docBookId"
                        value={formik.values.docBookId}
                        label="Sổ công văn năm"
                        labelOption="name"
                        valueOption="id"
                        options={docBooks}
                        variant={"select"}
                        id={"docBookId"}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} justifyContent="end">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={spacing_size}
                        sx={{
                          justifyContent: "end",
                        }}
                      >
                        <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                          Tìm kiếm
                        </Button>
                        <Button
                          variant="contained"
                          color="cancel"
                          type="button"
                          onMouseDown={() => handleCancel(formik)}
                        >
                          Huỷ
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          type="button"
                          onMouseDown={() => handleExport(formik)}
                        >
                          Xuất Excel
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      </BoxContainer>
      <BoxContainer>
        {/* show result */}
        {data && data.length > 0 && (
          <Box>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ background: header_table_color }}>
                    <TableCell sx={{ fontWeight: 600 }}>Loại Công văn đi</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Số lượng</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.length > 0 &&
                    data?.map((item: any, index: number) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                          cursor: "pointer",
                        }}
                        key={index}
                      >
                        <TableCell sx={{ width: "50%" }}>
                          <Typography component="span" sx={{ fontWeight: item.code == "ALL" ? "bold" : "600" }}>
                            {item.type}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: "50%" }}>
                          {item.total != 0 ? (
                            <Typography
                              style={{ color: "#0084FF", cursor: "pointer" }}
                              onClick={() => handleNavigateToDocOut(item.code)}
                              sx={{ fontWeight: item.code == "ALL" ? "bold" : "600" }}
                            >
                              {item.total}
                            </Typography>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationPage total={totalPage} page={page} onChange={handleChangePage} sizeDefault={10} />
          </Box>
        )}

        {/* not found result */}
        {(!data || data.length === 0) && <NotFoundFilterView />}
      </BoxContainer>
    </Box>
  );
}

export default ViewDashboardDocOut;
