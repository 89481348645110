import { Button, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FC } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { returnTaskApi } from "../../../api/task/task.api";
import FieldContainer from "../../../components/form/FieldContainer";
import ModalContainer from "../../../components/modal/ModalContainer";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { spacing_size } from "../../../themes/size";
import { getErrorMsgAxios } from "../../../utils/error.util";

interface ModalReturnProps {
  open: boolean;
  onClose: () => void;
  getDetailData: () => void;
  id: any;
}

const ModalReturn: FC<ModalReturnProps> = (props) => {
  const { open, onClose, id, getDetailData } = props;

  // const dispatch = useAppDispatch();
  const initialValues = {
    note: "",
  };

  const validationSchema = Yup.object({
    note: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Ý kiến")),
  });

  const handleOnSubmit = async (values: any) => {
    try {
      await returnTaskApi(Number(id), values.note);
      // dispatch(getByIdTaskApiAsync(Number(id)));
      getDetailData();
      onClose();
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title={"Xác nhận từ chối"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <Typography>Công việc được từ chối bởi người nhận việc. Vui lòng nhập ý kiến và xác nhận.</Typography>
            <Form autoComplete="off" noValidate style={{ padding: "0px 2px" }}>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="note"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: 255 }}
                    placeholder="Nhập ý kiến"
                    maxRows={12}
                    notched={true}
                    variant={"textarea"}
                    isRequired
                    id={"note"}
                    label={"Ý kiến"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="cancel"
                      type="button"
                      onMouseDown={() => {
                        formik.resetForm();
                        onClose();
                      }}
                    >
                      Huỷ
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={{
                        bgcolor: "#C72C16",
                      }}
                    >
                      Xác nhận
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </ModalContainer>
  );
};

export default ModalReturn;
