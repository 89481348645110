import { Button } from "@mui/material";
import React, { useState } from "react";
import HeaderPage from "../../../components/common/HeaderPage";
import XCircleIcon from "../../../components/icons/XCircleIcon";
import ModalCreatePosition from "./modal/ModalCreatePosition";

function HeaderPosition(props: any) {
  const { buildDataFilter, initValue } = props;
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);

  const handleCreate = async () => {
    setOpenModalCreate(true);
  };

  const handleCloseModal = () => {
    setOpenModalCreate(false);
  };
  return (
    <>
      <ModalCreatePosition
        open={openModalCreate}
        onClose={handleCloseModal}
        buildDataFilter={buildDataFilter}
        initValue={initValue}
      />
      <HeaderPage title="Danh sách chức vụ">
        <Button variant="contained" onClick={handleCreate} startIcon={<XCircleIcon />}>
          Thêm mới
        </Button>
      </HeaderPage>
    </>
  );
}

export default HeaderPosition;
