import React, { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import FieldContainer from "../../../components/form/FieldContainer";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import ViewFile from "../../../components/common/ViewFile";
import ChooseFile from "../../../components/icons/ChooseFile";
import { spacing_size } from "../../../themes/size";
import TextError from "../../../components/form/TextError";
import {
  ContractAddendumModel,
  ContractModel,
  PersonnelModel,
  SelectFieldCommonPersonnelModel,
} from "../../../model/personnel.model";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { EXCEPT_SYMBOLS } from "../../../constants/except.constant";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import {
  changeFileUploadPersonnel,
  isContractOrContractAddendumActive,
  isDuplicateCodeContractAddendum,
  // isExistContractAddendumActive,
  isOverlapDateTimeContractAddendum,
  isTimeContractAddendumBetweenTimeContract,
} from "../common/util/personnel.util";
import { UPLOAD_DATE_FORMAT } from "../../../constants/date-time.constant";
import moment from "moment";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import ModalContainer from "../../../components/modal/ModalContainer";
import { toast } from "react-toastify";
import { VALID_FORMAT_FILE } from "../../../constants/file.constant";
import { formatDateTime } from "../../../utils/data-time.util";
import { subStringFileName } from "../../../utils/file.util";

interface ModalUpdateContractAddendumHrProps {
  open: boolean;
  onClose: () => void;
  valueUploads: PersonnelModel | null;
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  contractAddendumDetail: ContractAddendumModel[];
  arrCodeDetail: any;
  setContractAddendumDetail: React.Dispatch<any>;
  fileContractsAddendumUpload: File[];
  setFileContractsAddendumUpload: React.Dispatch<React.SetStateAction<File[]>>;
  itemContractAddendum: ContractAddendumModel | null;
  indexContractAddendum: number;
  valuesContractAddendum: ContractAddendumModel[] | null | undefined | any;
  setValuesContractAddendum: any;
  valuesContract: ContractModel[];
  arrId: any;
  setArrId: any;
  arrFileContractAddendumDeleteId: number[];
  setArrFileContractAddendumDeleteId: React.Dispatch<React.SetStateAction<number[]>>;
}

const ModalUpdateContractAddendumHr: FC<ModalUpdateContractAddendumHrProps> = (props) => {
  const {
    open,
    onClose,
    valueUploads,
    setValuesUploads,
    contractAddendumDetail,
    arrCodeDetail,
    setContractAddendumDetail,
    fileContractsAddendumUpload,
    setFileContractsAddendumUpload,
    itemContractAddendum,
    indexContractAddendum,
    valuesContractAddendum,
    setValuesContractAddendum,
    valuesContract,
    arrId,
    setArrId,
    arrFileContractAddendumDeleteId,
    setArrFileContractAddendumDeleteId,
  } = props;

  const fileContractsAddendumpIdExist: number = itemContractAddendum?.contractAddendumFile?.id;
  const fileContractsAddendumpNameExist: string = itemContractAddendum?.contractAddendumFile?.name;

  const fileNameDetail = itemContractAddendum?.contractAddendumFile?.name
    ? itemContractAddendum?.contractAddendumFile?.name
    : itemContractAddendum?.fileTemp?.name
    ? itemContractAddendum?.fileTemp?.name
    : "";

  const convertFileDetail: any = new File([fileNameDetail], fileNameDetail, {
    type: fileNameDetail?.endsWith(".pdf")
      ? "application/pdf"
      : fileNameDetail?.endsWith(".docx")
      ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const getContractCode: SelectFieldCommonPersonnelModel[] = arrCodeDetail?.filter(
    (item: SelectFieldCommonPersonnelModel) => item?.code === itemContractAddendum?.contractCode
  );
  const contractCodeObj = getContractCode?.length > 0 ? Object.assign({}, ...getContractCode) : "";

  const initialValues: ContractAddendumModel = {
    code: itemContractAddendum?.code ? itemContractAddendum?.code : "",
    contractCode: contractCodeObj,
    startDate: itemContractAddendum?.startDate ? new Date(itemContractAddendum?.startDate) : null,
    endDate: itemContractAddendum?.endDate ? new Date(itemContractAddendum?.endDate) : null,
    fileName: itemContractAddendum?.fileName ? itemContractAddendum?.fileName : "",
    note: itemContractAddendum?.note ? itemContractAddendum?.note : "",
    salary: itemContractAddendum?.salary ? itemContractAddendum?.salary : "",
    allowances: itemContractAddendum?.allowances ? itemContractAddendum?.allowances : "",
    monthlyBonus: itemContractAddendum?.monthlyBonus ? itemContractAddendum?.monthlyBonus : "",
    fileTemp: itemContractAddendum?.fileTemp ? itemContractAddendum?.fileTemp : convertFileDetail,
  };

  const getFileContractAddendum: any = fileContractsAddendumUpload?.filter((item: File) =>
    item?.name === itemContractAddendum?.fileName
      ? itemContractAddendum?.fileName
      : itemContractAddendum?.fileTemp?.name
  );

  const currentDate = new Date();
  const currentTime = new Date().getTime();
  const arrTime: any = valuesContractAddendum?.map((item: ContractAddendumModel) => {
    return {
      startDate: moment(item?.startDate),
      endDate: moment(item?.endDate),
    };
  });

  const getArrTimeDetail: any = valuesContract
    ?.filter((item: ContractModel) => item?.code === contractCodeObj?.code)
    ?.map((i: ContractModel) => {
      return {
        startDate: moment(i?.startDate),
        endDate: i?.endDate ? moment(i?.endDate) : "",
      };
    });

  const [arrTimeDetail, setArrTimeDetail] = useState<any>(getArrTimeDetail);
  const [selectedContract, setSelectedContract] = useState<ContractModel | any>(undefined);

  const [file, setFile] = useState<File[]>(getFileContractAddendum);

  const [arrFile, setArrFile] = useState<File[] | any>(fileContractsAddendumUpload);
  const fileRef = useRef<HTMLButtonElement | HTMLInputElement | any>(null);

  const validationSchema = Yup.object({
    startDate: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Ngày bắt đầu")),
    endDate: Yup.date()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg("Ngày kết thúc"))
      .when("startDate", (startDate: any, schema: any) => {
        if (startDate) {
          return schema.min(startDate, "Ngày kết thúc phải lớn hơn ngày bắt đầu.");
        }
        return schema;
      }),
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Số hợp đồng")),
    contractCode: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Mã hợp đồng điều chỉnh")),
    fileTemp: Yup.mixed()
      .required(FORM_MSG.requiredFieldUploadFile("Đính kèm hợp đồng"))
      .test("fileFormat", "Chỉ tải lên file xlsx, xls, doc, docx, pdf", (value) => {
        if (!value) {
          return false;
        }
        return VALID_FORMAT_FILE.includes(value.type);
      }),
  });

  useEffect(() => {
    if (convertFileDetail) {
      setFile([convertFileDetail]);
    } else {
      setFile([itemContractAddendum?.fileTemp]);
    }
  }, []);

  useEffect(() => {
    const newArrContract: any = valuesContract?.filter((item: ContractModel) => item?.code === contractCodeObj?.code);
    setSelectedContract(newArrContract[0]);
  }, []);

  const handleChangeContractCode =
    (formik: FormikHelpers<ContractAddendumModel>) => (e: SelectFieldCommonPersonnelModel | null) => {
      formik.setFieldValue("contractCode", e);
      const newArrContract = valuesContract?.filter((item: ContractModel) => item?.code === e?.code);
      const arrTime: any = newArrContract?.map((item: ContractModel) => {
        return {
          startDate: moment(item?.startDate),
          endDate: item?.endDate ? moment(item?.endDate) : "",
        };
      });
      setArrTimeDetail([...arrTimeDetail, ...arrTime]);
      setSelectedContract(newArrContract[0]);
    };

  const changeUploadFile = (formik: FormikHelpers<ContractAddendumModel>) => (e: any) => {
    e.preventDefault();
    formik.setFieldValue("fileTemp", e.target.files[0]);
    const fileUp = changeFileUploadPersonnel(e, file, "", 1);
    const newFileUp = fileUp?.map((item: File, index: number) => {
      const newNameItem = currentTime + "_" + item?.name;
      const newItem = new File([item], newNameItem, { type: item.type });
      return newItem;
    });
    setFile(newFileUp);
    setArrFile([...arrFile, ...newFileUp]);
    // if (itemContractAddendum?.id) {
    //   setArrId([...arrId, itemContractAddendum?.id]);
    // }
  };

  const buildBody = (values: ContractAddendumModel) => {
    const convertSalary = values?.salary ? values?.salary?.toString().replace(/,/g, "") : null;
    const convertAllowances = values?.allowances ? values?.allowances?.toString().replace(/,/g, "") : null;
    const convertMonthlyBonus = values?.monthlyBonus ? values?.monthlyBonus?.toString().replace(/,/g, "") : null;

    const newValues = {
      ...values,
      id: itemContractAddendum?.id ? itemContractAddendum?.id : null,
      fileName: file[0].name,
      contractAddendumFile: {
        name: file[0].name,
      },
      contractCode: values?.contractCode ? values?.contractCode?.code : "",
      startDate: formatDateTime(values?.startDate, UPLOAD_DATE_FORMAT),
      endDate: formatDateTime(values?.endDate, UPLOAD_DATE_FORMAT),
      salary: Number(convertSalary) || null,
      allowances: Number(convertAllowances) || null,
      monthlyBonus: Number(convertMonthlyBonus) || null,
    };
    return newValues;
  };

  const handleOnSubmit = async (values: ContractAddendumModel, formik: FormikHelpers<ContractAddendumModel>) => {
    // mã phục lục hợp đồng không được trùng
    const contractAddendumExceptionCurrent: any = valuesContractAddendum?.filter(
      (item: ContractAddendumModel, index: number) => item?.code !== itemContractAddendum?.code
    );

    if (
      contractAddendumExceptionCurrent &&
      isDuplicateCodeContractAddendum(contractAddendumExceptionCurrent, values?.code || "")
    ) {
      toast.error("Số phụ lục hợp đồng trùng với số phụ lục hợp đồng khác !");
      return;
    }

    // khoảng thời gian phụ lục phải nằm trong thời gian hợp đồng
    if (isTimeContractAddendumBetweenTimeContract(values.startDate, values.endDate, selectedContract)) {
      toast.error("Thời gian phụ lục hợp đồng phải nằm trong thời gian ký kết hợp đồng điều chỉnh !");
      return;
    }

    // check trùng thời gian phục lục hợp đồng
    if (isOverlapDateTimeContractAddendum(contractAddendumExceptionCurrent, values)) {
      toast.error("Thời gian bị trùng với phụ lục hợp đồng khác !");
      return;
    }

    //Đang tồn tại hợp đồng còn hiệu lực
    /*if (isExistContractAddendumActive(contractAddendumExceptionCurrent) && isContractOrContractAddendumActive(values)) {
      toast.error("Chỉ được phép tồn tại 1 phụ lục hợp đồng còn hiệu lực !");
      return;
    }*/

    const newValues = buildBody(values);
    const uniqueArrayId: any = arrId?.filter((item: any, index: number) => arrId.indexOf(item) === index);
    const arrContractAddendum: any = valueUploads?.contractAddendums;
    const arrContractAddendumDetails = valuesContractAddendum;
    for (let index = 0; index < valuesContractAddendum?.length; index++) {
      const element = valuesContractAddendum[index];
      if (element?.code === itemContractAddendum?.code) {
        arrContractAddendum[index] = newValues;
        arrContractAddendumDetails[index] = newValues;
      }
    }
    setValuesUploads({
      ...valueUploads,
      contractAddendums: [...arrContractAddendumDetails],
      // deleteContractAddendumIds: uniqueArrayId,
      deleteContractAddendumFileIds: arrFileContractAddendumDeleteId,
    });
    setContractAddendumDetail(arrContractAddendum);
    setValuesContractAddendum(arrContractAddendumDetails);
    setFileContractsAddendumUpload(arrFile);
    onClose();
  };

  const handleRemoveFile = (formik: FormikHelpers<ContractAddendumModel>, item: File) => {
    if (item?.name === fileContractsAddendumpNameExist) {
      setArrFileContractAddendumDeleteId([...arrFileContractAddendumDeleteId, fileContractsAddendumpIdExist]);
    }
    setFile(file.filter((file) => item !== file));
    setArrFile(arrFile.filter((file: File) => item !== file));
    formik.setFieldValue("fileTemp", "");
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Cập nhật phụ lục hợp đồng"}
        width="50%"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          // enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off">
              {/* <>{JSON.stringify(formik.values)}</> */}
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={6} lg={5}>
                  <Stack direction={"row"} spacing={spacing_size}>
                    <Grid item xs={12} md={6} lg={5.5}>
                      <FieldContainer
                        name="startDate"
                        onBlur={formik.handleBlur}
                        value={formik.values.startDate}
                        notched={true}
                        variant={"date"}
                        id={"startDate"}
                        label="Thời gian bắt đầu"
                        isRequired={true}
                        placeholderText="DD/MM/YYYY"
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={1} sx={{ display: "flex", justifyContent: "center" }}>
                      <HorizontalRuleIcon fontSize="small" sx={{ marginTop: "2rem" }} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={5.5}>
                      <FieldContainer
                        name="endDate"
                        onBlur={formik.handleBlur}
                        value={formik.values.endDate}
                        notched={true}
                        variant={"date"}
                        id={"endDate"}
                        label="Thời gian kết thúc"
                        isRequired={true}
                        placeholderText="DD/MM/YYYY"
                        // minDate={currentDate}
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                      />
                    </Grid>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    fullWidth
                    name="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.code}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Số phụ lục hợp đồng"
                    notched={true}
                    variant={"input"}
                    id={"code"}
                    isRequired={true}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FieldContainer
                    fullWidth
                    name="contractCode"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeContractCode(formik)}
                    value={formik.values.contractCode}
                    notched={true}
                    options={arrCodeDetail}
                    variant={"react-select"}
                    id={"contractCode"}
                    label="Mã hợp đồng điều chỉnh"
                    isRequired={true}
                    placeholder="Nhập/chọn thông tin"
                    getOptionLabel={(item: SelectFieldCommonPersonnelModel) => item?.description}
                    getOptionValue={(item: SelectFieldCommonPersonnelModel) => item?.code}
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Stack direction="row" alignItems="center" spacing={spacing_size} sx={{ margin: "0.6rem 0" }}>
                    <Typography sx={{ fontSize: "1rem", fontWeight: "600", color: "#323E5D" }}>
                      Đính kèm{" "}
                      <Box component="span" sx={{ color: "red" }}>
                        *
                      </Box>
                    </Typography>
                    <Button
                      variant="contained"
                      color="cancel"
                      // sx={{ display: "flex" }}
                      onClick={() => fileRef.current.click()}
                      disabled={file?.length < 1 ? false : true}
                      startIcon={<ChooseFile />}
                    >
                      Chọn file
                    </Button>
                  </Stack>
                  <Field
                    id="file-input"
                    name="fileTemp"
                    // accept="application/msword, application/vnd.ms-excel, application/pdf"
                    accept=".xlsx,.xls,.doc,.docx,.pdf"
                    type="file"
                    innerRef={fileRef}
                    style={{ display: "none" }}
                    value={""}
                    onChange={changeUploadFile(formik)}
                  />
                  {file.map((item, index) => {
                    const newItem = subStringFileName(item);
                    return <ViewFile key={index} item={newItem} handleRemove={() => handleRemoveFile(formik, item)} />;
                  })}
                  <ErrorMessage name="fileTemp" component={TextError} />
                </Grid>

                <Grid item xs={12} md={8} lg={8}>
                  <FieldContainer
                    fullWidth
                    name="note"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.note}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Ghi chú"
                    notched={true}
                    variant={"input"}
                    id={"note"}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    // fullWidth
                    name="salary"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.salary}
                    placeholder={FORM_MSG.placeholder}
                    // maxRows={12}
                    label="Lương theo hợp đồng (VNĐ)"
                    // notched={true}
                    variant={"number-input"}
                    id={"salary"}
                    thousandSeparator=","
                    // inputProps={{ min: 0 }}
                    onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    // fullWidth
                    name="allowances"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.allowances}
                    placeholder={FORM_MSG.placeholder}
                    // maxRows={12}
                    label="Phụ cấp (VNĐ)"
                    // notched={true}
                    variant={"number-input"}
                    id={"allowances"}
                    thousandSeparator=","
                    // inputProps={{ min: 0 }}
                    onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FieldContainer
                    // fullWidth
                    name="monthlyBonus"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.monthlyBonus}
                    placeholder={FORM_MSG.placeholder}
                    // maxRows={12}
                    label="Thưởng tháng (VNĐ)"
                    // notched={true}
                    variant={"number-input"}
                    id={"monthlyBonus"}
                    thousandSeparator=","
                    // inputProps={{ min: 0 }}
                    onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                  />
                </Grid>

                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    <Button variant="contained" color="primary" type="submit" startIcon={<BrowserIcon />}>
                      Lưu
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalUpdateContractAddendumHr;
