import HTTP_USER_SERVICE from "../config/axios/axios-user.config";
import { Department } from "../model/department.model";
import { Pageable } from "../model/page.model";
import { CreateUserReqModel, Employee, FilterUserReqModel, UpdateUserReqModel, UserModel } from "../model/user.model";

export const createApi = (body: CreateUserReqModel) => {
  return HTTP_USER_SERVICE.post("/user/create", body);
};

export const getByIdApi = (id: number) => {
  return HTTP_USER_SERVICE.get<UserModel>(`/user/${id}`);
};

export const updateApi = (body: UpdateUserReqModel) => {
  return HTTP_USER_SERVICE.put("/user/update", body);
};

export const updateStatusApi = (id: number, status: boolean) => {
  return HTTP_USER_SERVICE.put("/user/update/status", { id, status });
};

export const filterApi = (body: FilterUserReqModel | null, pageable: Pageable) => {
  return HTTP_USER_SERVICE.post<UserModel[]>("/user/filter", body, {
    params: { ...pageable },
  });
};

export const searchVffApi = (keyword: string, pageable: Pageable) => {
  return HTTP_USER_SERVICE.get<Employee[]>("/user/search-vff/listEmployee", {
    params: { keyword, ...pageable },
  });
};

export const searchDepartmentApi = () => {
  return HTTP_USER_SERVICE.get<Department[]>("/employee/search-department", {});
};

export const getUserApi = (keyword: string, code: string, ignoreSpinner = true) => {
  return HTTP_USER_SERVICE.get("/user", {
    params: { keyword, code },
    ignoreSpinner,
  });
};
