import { ErrorMessage, Field } from "formik";
import { FC } from "react";
import Select from "react-select";
import { main_color } from "../../themes/color";
import { FieldCommonProps } from "./FieldContainer";
import LabelField from "./LabelField";
import TextError from "./TextError";

export interface ReactSelectFieldProps extends FieldCommonProps {
  options?: Object[] | any;
  isDisabled?: boolean | any;
  [key: string]: unknown;
}

const ReactSelectField: FC<ReactSelectFieldProps> = (props) => {
  const { label, name, tooltip, isRequired, id, options, isDisabled, ...rest } = props;
  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <Field name={name}>
        {({ field, form }: { field: any; form: any }) => (
          <Select
            isDisabled={isDisabled}
            options={options}
            isSearchable={true}
            onBlur={form.handleBlur}
            isClearable={true}
            name={name}
            noOptionsMessage={() => "Không có kết quả tìm kiếm"}
            styles={{
              menuPortal: (base: any) => ({
                ...base,
                zIndex: 1301,
              }),
              control: (base) => ({
                ...base,
                borderColor: "rgba(0,0,0,0.23)",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  borderColor: main_color,
                },
                "&:focus": {
                  boxShadow: "none",
                  borderColor: main_color,
                },
              }),
            }}
            {...rest}
          />
        )}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default ReactSelectField;
