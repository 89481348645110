import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Stack } from "@mui/material";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React, { FC, useState } from "react";
import FieldContainer from "../../../../components/form/FieldContainer";
import { UPLOAD_DATE_FORMAT } from "../../../../constants/date-time.constant";
import { FORM_MSG } from "../../../../constants/msg/msg.constant";
import {
  GenderPersonnels,
  IdentityDocumentsPersonnel,
  IDENTITY_DOCUMENTS_ENUM,
  OtherDocumentsPersonnel,
  OTHER_DOCUMENTS_ENUM,
  POSITION_ENUM,
  TYPE_PERSONNEL_ENUM,
} from "../../../../enum/personnel.enum";
import {
  GeneralInformationModel,
  HrDepartmentModel,
  HrPositionModel,
  NationalityModel,
  PersonnelModel,
  SelectFieldCommonPersonnelModel,
} from "../../../../model/personnel.model";
import { spacing_size } from "../../../../themes/size";
import TitleFormGeneral from "../../common/component/TitleFormGeneral";
import {
  handleKeyPressPreventWithPattern,
  handleUpdateHr,
  isEnableFieldIdentityCard,
  isEnableFieldPassport,
  isEnableFieldTemporary,
  isEnableFieldWorkPermit,
} from "../../common/util/personnel.util";
import ModalCreateDepartment from "../../modal/ModalCreateDepartment";
import ModalCreatePosition from "../../modal/ModalCreatePosition";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { validationExpertsAndCoachingStaffSchema } from "../../common/constants/validate-form.constants";
import { formatDateTime } from "../../../../utils/data-time.util";
import { Location, useNavigate } from "react-router-dom";
import {
  PHONE_PATTERN,
  IDENTITY_CARD_PATTERN,
  PASSPORT_PATTERN,
  CODE_EMPLOYEE_REGEX,
} from "../../../../constants/regex.constant";

interface GeneralInformationExpertsAndCoachingStaffProps {
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  valueUploads: PersonnelModel | null;
  btnSubmitInformationRef: React.RefObject<HTMLButtonElement>;
  hrDepartmentDetail: HrDepartmentModel[];
  hrPositionDetail: HrPositionModel[];
  valueForm: PersonnelModel | null;
  setValueForm: any;
  btnInformationExpertRefNext: React.RefObject<HTMLButtonElement>;
  fileContractsUpload: File[];
  fileEducationsUpload: File[];
  fileContractsAddendumUpload: File[];
  fileDocumentsUpload: File[];
  nationalityDetail: NationalityModel[];
  location: Location;
  newFileContractsUpload: File[];
  newFileContractsAddendumUpload: File[];
}

interface InitialValuesForm {
  type: string;
  fullName: string;
  code: string;
  dob: string | null;
  gender: string | object | any;
  phoneNumber: number | string;
  workingAddress: string;
  maritalStatus: string | object | any | null;
  nationalityId: string | any;
  ethnic: string;
  religion: string;
  nativePlace: string;
  permanentAddress: string;
  address: string;
  email: string;
  positionId: string | HrPositionModel | null | any;
  departmentId: string | any;
  managerId: number | any;
  startDate: string | Date | null;
  taxCode: string;
  identityCard: string;
  idcIssueDate: string | Date | null;
  idcIssuePlace: string;
  passport: string;
  passportIssueDate: string | Date | null;
  passportIssuePlace: string;
  partyPosition: string;
  partyJoinDate: string | Date | null;
  partyJoinPlace: string;
  unionPosition: string;
  unionJoinDate: string | Date | null;
  unionJoinPlace: string;
  rewards: string;
  disciplines: string;
  relationship: string;
  workPermitNumber?: string;
  workPermitFromDate?: string | Date | null;
  workPermitToDate?: string | Date | null;
  temporaryResidenceCard?: string;
  trcExpiredDate?: string | Date | null;
  identity?: IDENTITY_DOCUMENTS_ENUM[] | any;
  selectPosition?: POSITION_ENUM[] | any;
  selectDocOther?: OTHER_DOCUMENTS_ENUM[] | undefined | any;
}

const GeneralInformationExpertsAndCoachingStaff: FC<GeneralInformationExpertsAndCoachingStaffProps> = (props) => {
  const {
    valueUploads,
    setValuesUploads,
    btnSubmitInformationRef,
    hrDepartmentDetail,
    hrPositionDetail,
    valueForm,
    setValueForm,
    btnInformationExpertRefNext,
    fileContractsUpload,
    fileEducationsUpload,
    fileContractsAddendumUpload,
    fileDocumentsUpload,
    nationalityDetail,
    location,
    newFileContractsUpload,
    newFileContractsAddendumUpload,
  } = props;

  const initialValues: InitialValuesForm = {
    type: TYPE_PERSONNEL_ENUM.EXPERTS_AND_COACHING_STAFF,
    fullName: valueForm?.fullName || "",
    code: valueForm?.code || "",
    dob: valueForm?.dob || null,
    gender: valueForm?.gender || "",
    phoneNumber: valueForm?.phoneNumber || "",
    workingAddress: valueForm?.workingAddress || "",
    maritalStatus: valueForm?.maritalStatus || "",
    nationalityId: valueForm?.nationalityId || "",
    ethnic: valueForm?.ethnic || "",
    religion: valueForm?.religion || "",
    nativePlace: valueForm?.nativePlace || "",
    permanentAddress: valueForm?.permanentAddress || "",
    address: valueForm?.address || "",
    email: valueForm?.email || "",
    positionId: valueForm?.positionId || "",
    departmentId: valueForm?.departmentId || "",
    managerId: valueForm?.managerId || "",
    startDate: valueForm?.startDate || null,
    taxCode: valueForm?.taxCode || "",
    identityCard: valueForm?.identityCard || "",
    idcIssueDate: valueForm?.idcIssueDate || null,
    idcIssuePlace: valueForm?.idcIssuePlace || "",
    passport: valueForm?.passport || "",
    passportIssueDate: valueForm?.passportIssueDate || null,
    passportIssuePlace: valueForm?.passportIssuePlace || "",
    partyPosition: valueForm?.partyPosition || "",
    partyJoinDate: valueForm?.partyJoinDate || null,
    partyJoinPlace: valueForm?.partyJoinPlace || "",
    unionPosition: valueForm?.unionPosition || "",
    unionJoinDate: valueForm?.unionJoinDate || null,
    unionJoinPlace: valueForm?.unionJoinPlace || "",
    rewards: valueForm?.rewards || "",
    disciplines: valueForm?.disciplines || "",
    relationship: valueForm?.relationship || "",
    workPermitNumber: valueForm?.workPermitNumber || "",
    workPermitFromDate: valueForm?.workPermitFromDate || null,
    workPermitToDate: valueForm?.workPermitToDate || null,
    temporaryResidenceCard: valueForm?.temporaryResidenceCard || "",
    trcExpiredDate: valueForm?.trcExpiredDate || null,
    identity: valueForm?.identity || [],
    selectDocOther: valueForm?.selectDocOther || [],
  };

  const navigate = useNavigate();
  const currentDate = new Date();
  const [openModalCreateDepartment, setOpenModalCreateDepartment] = useState<boolean>(false);
  const [openModalCreatePosition, setOpenModalCreatePosition] = useState<boolean>(false);

  const handleOpenModalCreatePosition = () => {
    setOpenModalCreatePosition(true);
  };

  const handleCloseModalCreatePosition = () => {
    setOpenModalCreatePosition(false);
  };

  const handleOpenModalCreateDepartment = () => {
    setOpenModalCreateDepartment(true);
  };

  const handleCloseModalCreateDepartment = () => {
    setOpenModalCreateDepartment(false);
  };

  const handleChangeFullName = (formik: FormikHelpers<InitialValuesForm>) => (e: string | any) => {
    const newValues = e.target.value?.charAt(0).toUpperCase() + e.target.value.slice(1);
    formik.setFieldValue("fullName", newValues);
  };

  const handleChangeGender =
    (formik: FormikHelpers<InitialValuesForm>) => (e: SelectFieldCommonPersonnelModel | null) => {
      formik.setFieldValue("gender", e);
    };

  const handleChangeNationality = (formik: FormikHelpers<InitialValuesForm>) => (e: NationalityModel) => {
    formik.setFieldValue("nationalityId", e);
  };

  const handleChangeDepartment = (formik: FormikHelpers<InitialValuesForm>) => (e: HrDepartmentModel) => {
    formik.setFieldValue("departmentId", e);
  };

  const handleChangePositionId = (formik: FormikHelpers<InitialValuesForm>) => (e: HrPositionModel) => {
    formik.setFieldValue("positionId", e);
  };

  const handleChangeIdentity = (formik: FormikHelpers<InitialValuesForm> | any) => (e: any | null) => {
    if (formik?.values?.identity?.includes(e.target.value)) {
      const nextValue = formik?.values?.identity?.filter((value: any) => value !== e.target.value);
      formik.setFieldValue("identity", nextValue);
      if (nextValue?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD)) {
        // formik.setFieldValue("passport", "");
        // formik.setFieldValue("passportIssueDate", "");
        // formik.setFieldValue("passportIssuePlace", "");
      } else if (nextValue?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)) {
        // formik.setFieldValue("identityCard", "");
        // formik.setFieldValue("idcIssueDate", "");
        // formik.setFieldValue("idcIssuePlace", "");
      }
    } else {
      const nextValue = formik?.values?.identity?.concat(e.target.value);
      formik.setFieldValue("identity", nextValue);
    }
  };

  const handleChangeDocumentOther = (formik: FormikHelpers<InitialValuesForm> | any) => (e: any | null) => {
    if (formik?.values?.selectDocOther?.includes(e.target.value)) {
      const nextValue = formik?.values?.selectDocOther?.filter((value: any) => value !== e.target.value);
      formik.setFieldValue("selectDocOther", nextValue);
      if (nextValue?.includes(OTHER_DOCUMENTS_ENUM?.WORK_PREMIT)) {
        // formik.setFieldValue("temporaryResidenceCard", "");
        // formik.setFieldValue("trcExpiredDate", "");
      } else if (OTHER_DOCUMENTS_ENUM?.TEMPORARY) {
        // formik.setFieldValue("workPermitNumber", "");
        // formik.setFieldValue("workPermitFromDate", "");
        // formik.setFieldValue("workPermitToDate", "");
      }
    } else {
      const nextValue = formik?.values?.selectDocOther?.concat(e.target.value);
      formik.setFieldValue("selectDocOther", nextValue);
    }
  };

  const buildBody = (values: InitialValuesForm): GeneralInformationModel => {
    const formatDob = formatDateTime(values?.dob, UPLOAD_DATE_FORMAT);
    const formatIdcIssueDate = formatDateTime(values?.idcIssueDate, UPLOAD_DATE_FORMAT);
    const formatPassportIssueDate = formatDateTime(values.passportIssueDate, UPLOAD_DATE_FORMAT);
    const formatWorkPermitFromDate = formatDateTime(values?.workPermitFromDate, UPLOAD_DATE_FORMAT);
    const formatTrcExpiredDate = formatDateTime(values?.trcExpiredDate, UPLOAD_DATE_FORMAT);
    const formatWorkPermitToDate = formatDateTime(values?.workPermitToDate, UPLOAD_DATE_FORMAT);

    const newValues = {
      ...values,
      dob: formatDob,
      nationalityId: values?.nationalityId ? values?.nationalityId?.id : null,
      identityCard: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD) ? values?.identityCard : "",
      idcIssueDate: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD) ? formatIdcIssueDate : "",
      idcIssuePlace: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.IDENTITY_CARD) ? values?.idcIssuePlace : "",
      passport: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT) ? values?.passport : "",
      passportIssueDate: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT) ? formatPassportIssueDate : "",
      passportIssuePlace: values?.identity?.includes(IDENTITY_DOCUMENTS_ENUM.PASSPORT)
        ? values?.passportIssuePlace
        : "",
      workPermitNumber: values?.selectDocOther?.includes(OTHER_DOCUMENTS_ENUM.WORK_PREMIT)
        ? values?.workPermitNumber
        : "",
      workPermitFromDate: values?.selectDocOther?.includes(OTHER_DOCUMENTS_ENUM.WORK_PREMIT)
        ? formatWorkPermitFromDate
        : "",
      workPermitToDate: values?.selectDocOther?.includes(OTHER_DOCUMENTS_ENUM.WORK_PREMIT)
        ? formatWorkPermitToDate
        : "",
      temporaryResidenceCard: values?.selectDocOther?.includes(OTHER_DOCUMENTS_ENUM?.TEMPORARY)
        ? values?.temporaryResidenceCard
        : "",
      trcExpiredDate: values?.selectDocOther?.includes(OTHER_DOCUMENTS_ENUM.TEMPORARY) ? formatTrcExpiredDate : "",
      gender: values?.gender ? values?.gender?.code : null,
      maritalStatus: values?.maritalStatus ? values?.maritalStatus?.code : null,
      positionId: values?.positionId ? values?.positionId?.id : null,
      departmentId: values?.departmentId ? values?.departmentId?.id : null,
      managerId: null,
      employeeContactInfo: null,
    };
    return newValues;
  };

  const onSubmitSearch = (values: InitialValuesForm, formik: FormikHelpers<InitialValuesForm> | any) => {
    const body = buildBody(values);
    const newBody = {
      ...valueUploads,
      ...body,
    };

    handleUpdateHr(
      newBody,
      fileContractsUpload,
      fileEducationsUpload,
      fileContractsAddendumUpload,
      fileDocumentsUpload,
      newFileContractsUpload,
      newFileContractsAddendumUpload,
      navigate,
      location
    );
  };

  const handleClickSave = (formik: FormikProps<InitialValuesForm>) => {
    const values = formik?.values;
    const body = buildBody(values);
    setValuesUploads({ ...valueUploads, ...body });
    setValueForm(values);
  };

  return (
    <>
      {openModalCreateDepartment && (
        <ModalCreateDepartment open={openModalCreateDepartment} onClose={handleCloseModalCreateDepartment} />
      )}

      {openModalCreatePosition && (
        <ModalCreatePosition open={openModalCreatePosition} onClose={handleCloseModalCreatePosition} />
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={validationExpertsAndCoachingStaffSchema}
        onSubmit={onSubmitSearch}
        validateOnBlur={false}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form noValidate autoComplete="off">
            {/* Thông tin cá nhân */}
            <Accordion
              defaultExpanded={true}
              sx={{
                borderLeft: "5px solid #2E3D5B",
                p: 2,
                borderRadius: "4px",
                bgcolor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vff-panel-personal-information-content"
                id="vff-panel-personal-information-header"
              >
                <TitleFormGeneral title={"Thông tin cá nhân"} />
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="fullName"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeFullName(formik)}
                      value={formik.values.fullName}
                      placeholder="Nhập thông tin"
                      label="Họ và tên"
                      variant={"input"}
                      id={"fullName"}
                      maxRows={12}
                      isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="dob"
                      type="text"
                      onBlur={formik.handleBlur}
                      value={formik.values.dob}
                      placeholderText="dd/mm/yyyy"
                      label="Ngày sinh"
                      variant={"date"}
                      id={"dob"}
                      maxRows={12}
                      isRequired={true}
                      maxDate={currentDate}
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={50}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="gender"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeGender(formik)}
                      value={formik.values.gender}
                      placeholder="Nhập thông tin"
                      label="Giới tính"
                      variant={"react-select"}
                      id={"gender"}
                      getOptionLabel={(item: SelectFieldCommonPersonnelModel) => item?.description}
                      getOptionValue={(item: SelectFieldCommonPersonnelModel) => item?.code}
                      options={GenderPersonnels}
                      isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="phoneNumber"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                      placeholder="Nhập thông tin"
                      label="Số điện thoại"
                      variant={"input"}
                      id={"phoneNumber"}
                      maxRows={12}
                      isRequired={true}
                      inputProps={{ maxLength: 20 }}
                      onKeyPress={handleKeyPressPreventWithPattern(PHONE_PATTERN)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      name="code"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.code}
                      placeholder="Nhập thông tin"
                      label="Mã cá nhân"
                      variant={"input"}
                      id={"code"}
                      maxRows={12}
                      isRequired={true}
                      inputProps={{ maxLength: 6 }}
                      onKeyPress={handleKeyPressPreventWithPattern(CODE_EMPLOYEE_REGEX)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="email"
                      type="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      placeholder="Nhập thông tin"
                      label="Email công việc"
                      variant={"input"}
                      id={"email"}
                      disabled
                      maxRows={12}
                      isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="nationalityId"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeNationality(formik)}
                      value={formik.values.nationalityId}
                      placeholder="Nhập thông tin"
                      label="Quốc tịch"
                      variant={"react-select"}
                      id={"nationalityId"}
                      maxRows={12}
                      options={nationalityDetail}
                      getOptionLabel={(item: NationalityModel) => item?.name}
                      getOptionValue={(item: NationalityModel) => item?.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldContainer
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      name="address"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.address}
                      placeholder="Nhập thông tin"
                      label="Nơi ở hiện tại"
                      variant={"input"}
                      id={"address"}
                      maxRows={12}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Thông tin cơ quan */}
            <Accordion
              defaultExpanded={true}
              sx={{
                borderLeft: "5px solid #2E3D5B",
                p: 2,
                borderRadius: "4px",
                bgcolor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vff-panel-office-information-content"
                id="vff-panel-office-information-header"
              >
                <TitleFormGeneral title={"Thông tin cơ quan"} />
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Stack direction="row" spacing={spacing_size}>
                      <Grid item xs={12} md={11} lg={11}>
                        <FieldContainer
                          fullWidth
                          name="departmentId"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={handleChangeDepartment(formik)}
                          value={formik.values.departmentId}
                          placeholder="Nhập/chọn thông tin"
                          label="Bộ phận công tác"
                          variant={"react-select"}
                          id={"departmentId"}
                          // maxRows={12}
                          isRequired={true}
                          options={hrDepartmentDetail}
                          getOptionLabel={(option: HrDepartmentModel) => `${option.code} - ${option.name}`}
                          getOptionValue={(option: HrDepartmentModel) => option.code}
                        />
                      </Grid>
                      <Grid item xs={11} md={1} lg={1}>
                        <Box
                          sx={{ display: "inline-block", cursor: "pointer", mt: "1.6rem" }}
                          onClick={handleOpenModalCreateDepartment}
                        >
                          <AddCircleOutlineIcon sx={{ fontSize: "2rem", color: "#4ACB1D" }} />
                        </Box>
                      </Grid>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Stack direction="row" spacing={spacing_size}>
                      <Grid item xs={12} md={11} lg={11}>
                        <FieldContainer
                          fullWidth
                          name="positionId"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={handleChangePositionId(formik)}
                          value={formik.values.positionId}
                          placeholder="Nhập/chọn thông tin"
                          label="Chức vụ"
                          variant={"react-select"}
                          id={"positionId"}
                          isRequired={true}
                          options={hrPositionDetail}
                          getOptionLabel={(option: HrPositionModel) => option.name}
                          getOptionValue={(option: HrPositionModel) => option.id}
                        />
                      </Grid>
                      <Grid item xs={11} md={1} lg={1}>
                        <Box
                          sx={{ display: "inline-block", cursor: "pointer", mt: "1.6rem" }}
                          onClick={handleOpenModalCreatePosition}
                        >
                          <AddCircleOutlineIcon sx={{ fontSize: "2rem", color: "#4ACB1D" }} />
                        </Box>
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Loại giấy tờ tuỳ thân */}
            <Accordion
              defaultExpanded={true}
              sx={{
                borderLeft: "5px solid #2E3D5B",
                p: 2,
                borderRadius: "4px",
                bgcolor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vff-panel-document-personal-content"
                id="vff-panel-document-personal-header"
              >
                <TitleFormGeneral title={"Loại giấy tờ tuỳ thân"} />
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={spacing_size}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ margin: "1rem 0" }}
                >
                  <Grid container spacing={spacing_size}>
                    <Grid item xs={12} md={12} lg={12} sx={{ marginTop: "0.3rem" }}>
                      <FieldContainer
                        name="identity"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeIdentity(formik)}
                        value={formik.values.identity}
                        placeholder=""
                        label=""
                        variant={"checkbox"}
                        id={"identity"}
                        options={IdentityDocumentsPersonnel}
                        labelOption="description"
                        valueOption="code"
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Grid container spacing={spacing_size}>
                  {isEnableFieldIdentityCard(formik.values.identity) && (
                    <>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 12 }}
                          name="identityCard"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.identityCard}
                          placeholder="Nhập thông tin"
                          label="Số CMND/CCCD"
                          variant={"input"}
                          id={"identityCard"}
                          maxRows={12}
                          isRequired={true}
                          onKeyPress={handleKeyPressPreventWithPattern(IDENTITY_CARD_PATTERN)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="idcIssueDate"
                          type="text"
                          onBlur={formik.handleBlur}
                          value={formik.values.idcIssueDate}
                          placeholderText={FORM_MSG.dateFormatPlaceholder}
                          label="Ngày cấp"
                          variant={"date"}
                          id={"idcIssueDate"}
                          maxRows={12}
                          maxDate={currentDate}
                          isRequired={true}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="idcIssuePlace"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.idcIssuePlace}
                          placeholder="Nhập thông tin"
                          label="Nơi cấp"
                          variant={"input"}
                          id={"idcIssuePlace"}
                          maxRows={12}
                          isRequired={true}
                        />
                      </Grid>
                    </>
                  )}
                  {isEnableFieldPassport(formik.values.identity) && (
                    <>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 20 }}
                          name="passport"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.passport}
                          placeholder="Nhập thông tin"
                          label="Số hộ chiếu"
                          variant={"input"}
                          id={"passport"}
                          maxRows={12}
                          pattern={PASSPORT_PATTERN}
                          onKeyPress={handleKeyPressPreventWithPattern(PASSPORT_PATTERN)}
                          isRequired={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="passportIssueDate"
                          type="text"
                          onBlur={formik.handleBlur}
                          value={formik.values.passportIssueDate}
                          label="Ngày cấp"
                          placeholderText={FORM_MSG.dateFormatPlaceholder}
                          variant={"date"}
                          id={"passportIssueDate"}
                          maxRows={12}
                          maxDate={currentDate}
                          isRequired={true}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="passportIssuePlace"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.passportIssuePlace}
                          placeholder="Nhập thông tin"
                          label="Nơi cấp"
                          variant={"input"}
                          id={"passportIssuePlace"}
                          maxRows={12}
                          isRequired={true}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* Thông tin giấy tờ khác */}
            <Accordion
              defaultExpanded={true}
              sx={{
                borderLeft: "5px solid #2E3D5B",
                p: 2,
                borderRadius: "4px",
                bgcolor: "white",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                mb: 2,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vff-panel-document-other-content"
                id="vff-panel-document-other-header"
              >
                <TitleFormGeneral title={"Thông tin giấy tờ khác"} />
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={spacing_size}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ margin: "1rem 0" }}
                >
                  <Grid container spacing={spacing_size}>
                    <Grid item xs={12} md={12} lg={12} sx={{ marginTop: "0.3rem" }}>
                      <FieldContainer
                        name="selectDocOther"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeDocumentOther(formik)}
                        value={formik?.values?.selectDocOther}
                        placeholder=""
                        label=""
                        variant={"checkbox"}
                        id={"selectDocOther"}
                        options={OtherDocumentsPersonnel}
                        labelOption="description"
                        valueOption="code"
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Grid container spacing={spacing_size}>
                  {isEnableFieldWorkPermit(formik?.values?.selectDocOther) && (
                    <>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="workPermitNumber"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.workPermitNumber}
                          placeholder="Nhập thông tin"
                          label="Số giấy phép lao động"
                          variant={"input"}
                          id={"workPermitNumber"}
                          maxRows={12}
                          isRequired={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="workPermitFromDate"
                          type="text"
                          onBlur={formik.handleBlur}
                          value={formik.values.workPermitFromDate}
                          label="Thời hạn từ ngày"
                          variant={"date"}
                          id={"workPermitFromDate"}
                          maxRows={12}
                          placeholderText={FORM_MSG.dateFormatPlaceholder}
                          maxDate={currentDate}
                          isRequired={true}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="workPermitToDate"
                          type="text"
                          onBlur={formik.handleBlur}
                          value={formik.values.workPermitToDate}
                          placeholderText={FORM_MSG.dateFormatPlaceholder}
                          label="Thời hạn đến ngày"
                          variant={"date"}
                          id={"workPermitToDate"}
                          maxRows={12}
                          isRequired={true}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                    </>
                  )}
                  {isEnableFieldTemporary(formik?.values?.selectDocOther) && (
                    <>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="temporaryResidenceCard"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.temporaryResidenceCard}
                          placeholder="Nhập thông tin"
                          label="Số thẻ tạm trú"
                          variant={"input"}
                          id={"temporaryResidenceCard"}
                          maxRows={12}
                          isRequired={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FieldContainer
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                          name="trcExpiredDate"
                          type="text"
                          onBlur={formik.handleBlur}
                          value={formik.values.trcExpiredDate}
                          placeholderText={FORM_MSG.dateFormatPlaceholder}
                          label="Thời hạn"
                          variant={"date"}
                          id={"trcExpiredDate"}
                          maxRows={12}
                          isRequired={true}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/* action */}
            <Button ref={btnSubmitInformationRef} type="submit" sx={{ display: "none" }}>
              Lưu
            </Button>
            <Button
              ref={btnInformationExpertRefNext}
              type="button"
              sx={{ display: "none" }}
              onClick={() => handleClickSave(formik)}
            >
              Lưu
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GeneralInformationExpertsAndCoachingStaff;
