import { Button, Grid, Stack } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { DOC_IN_MSG, FORM_MSG } from "../../../../constants/msg/msg.constant";
import { spacing_size } from "../../../../themes/size";
import FieldContainer from "../../../../components/form/FieldContainer";
import { getUserFromDepartment } from "../../../../api/department.api";
import ModalContainer from "../../../../components/modal/ModalContainer";
import { docInAssignmentApi } from "../../../../api/doc-in.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { UserModel } from "../../../../model/user.model";
import OptionSelectUser from "../../../../components/common/OptionSelectUser";
import { DepartmentCodeDocIn } from "../../../../model/department.model";
import React from "react";
import { DOC_IN_PRIORITY_TYPE_ENUM, docInPriorityTypeOptions } from "../../../../enum/doc-in.enum";
import moment from "moment";
import { FORMAT_DATE_TIME_UTC, FORMAT_ORIGINAL_TIME } from "../../../../constants/date-time.constant";

interface ModalAssignmentProps {
  open: boolean;
  onClose: () => void;
  departmentCode: string | undefined;
  id: number;
  countAssignClose: number;
}

interface InitialValuesForm {
  account: string | object | any;
  coordinator: string | any;
  comment: string;
  priority: string | null;
  processingDeadline: string | null;
}

const ModalAssignment: FC<ModalAssignmentProps> = (props) => {
  const { open, onClose, departmentCode, id, countAssignClose } = props;

  const navigate = useNavigate();

  const initialValues: InitialValuesForm = {
    account: "",
    coordinator: "",
    comment: "",
    priority: DOC_IN_PRIORITY_TYPE_ENUM.NORMAL,
    processingDeadline: "",
  };

  const [optionUsers, setOptionUsers] = useState<UserModel[]>([]);
  const [userOfDepartment, setUserOfDepartment] = useState<UserModel[]>([]);
  const [optionUserOfDepartment, setOptionUserOfDepartment] = useState<UserModel[]>([]);

  const validationSchema = Yup.object({
    account: Yup.array()
      .nullable()
      .min(1, FORM_MSG.requiredSelectFieldMsg("Người thực hiện"))
      .required(FORM_MSG.requiredSelectFieldMsg("Người thực hiện")),
    priority: Yup.string().nullable().required(FORM_MSG.requiredSelectFieldMsg("Độ ưu tiên")),
  });

  const getUserOfDepartment = () => {
    getUserFromDepartment(departmentCode || "", "", false).then((res) => {
      setOptionUsers(res?.data);
      setUserOfDepartment(res.data);
      setOptionUserOfDepartment(res?.data);
    });
  };

  useEffect(() => {
    if (open) {
      getUserOfDepartment();
    }
  }, [open]);

  const handleChangeAccount =
    (formik: FormikHelpers<InitialValuesForm>) => (e: Object | string | null | undefined | any, action: any) => {
      formik.setFieldValue("account", e);
      const newArr = optionUsers?.filter((item: UserModel) => !e?.includes(item));
      setOptionUserOfDepartment(newArr);
    };

  const handleChangeCoordinator = (formik: FormikHelpers<InitialValuesForm>) => (e: any) => {
    formik.setFieldValue("coordinator", e);
    const newArr = optionUsers?.filter((item: UserModel) => !e?.includes(item));
    setUserOfDepartment(newArr);
  };

  const handleCancel = () => {
    onClose();
  };
  const handleOnSubmit = async (values: InitialValuesForm, formik: FormikHelpers<InitialValuesForm>) => {
    const account = values?.account?.map((item: DepartmentCodeDocIn) => item?.email);
    const coordinator =
      values?.coordinator !== "" ? values?.coordinator?.map((item: DepartmentCodeDocIn) => item?.email) : [];
    const comment = values?.comment;
    const priority = values?.priority;
    const originalTime = values?.processingDeadline ? moment(values?.processingDeadline, FORMAT_ORIGINAL_TIME) : "";
    const processingDeadline = originalTime ? originalTime.utc().format(FORMAT_DATE_TIME_UTC) : "";

    const body = {
      id: id,
      account: account,
      coordinator: coordinator,
      comment: comment,
      priority: priority,
      processingDeadline: processingDeadline,
    };

    await docInAssignmentApi(body)
      .then((res) => {
        if (res) {
          toast.success(DOC_IN_MSG.assignmentSuccess);
          onClose();
          navigate("/doc-in");
        }
      })
      .catch((err) => {
        const msg = getErrorMsgAxios(err);
        toast.error(msg);
        throw Error(err);
      });
  };

  const filterOptions = (candidate: any, input: string) => {
    if (input) {
      const { email, fullName } = candidate?.data || {};
      if (
        email.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
        fullName.toLocaleLowerCase().includes(input.toLocaleLowerCase())
      ) {
        return true;
      }

      return false;
    }
    return true;
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title="Phân công nhân viên giải quyết"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off" style={{ margin: "0px 2px" }}>
              {/* <pre>{JSON.stringify(formik.values, undefined, 2)}</pre> */}
              <Grid container spacing={spacing_size}>
                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="account"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeAccount(formik)}
                    value={formik.values.account}
                    placeholder="Nhập thông tin"
                    label="Người thực hiện"
                    isRequired
                    variant={"react-select"}
                    getOptionValue={(item: UserModel) => item.email}
                    getOptionLabel={(item: UserModel) => item.email}
                    id={"account"}
                    options={userOfDepartment}
                    menuPortalTarget={document.querySelector("body")}
                    components={{ Option: OptionSelectUser }}
                    isMulti
                    filterOption={filterOptions}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="coordinator"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeCoordinator(formik)}
                    value={formik.values.coordinator}
                    placeholder="Nhập thông tin"
                    label="Người phối hợp"
                    variant={"react-select"}
                    getOptionValue={(item: UserModel) => item.email}
                    getOptionLabel={(item: UserModel) => item.email}
                    id={"coordinator"}
                    options={optionUserOfDepartment}
                    menuPortalTarget={document.querySelector("body")}
                    components={{ Option: OptionSelectUser }}
                    isMulti
                    filterOption={filterOptions}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FieldContainer
                    fullWidth
                    name="comment"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 5000 }}
                    value={formik.values.comment}
                    placeholder="Nhập thông tin"
                    label="Ý kiến"
                    variant={"textarea"}
                    id={"comment"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldContainer
                    name="priority"
                    label="Độ ưu tiên"
                    options={docInPriorityTypeOptions}
                    labelOption="description"
                    valueOption="code"
                    variant={"select"}
                    id={"priority"}
                    isRequired
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldContainer
                    name="processingDeadline"
                    label="Thời hạn xử lý theo yêu cầu của Lãnh đạo phòng"
                    variant={"date"}
                    id={"processingDeadline"}
                    onBlur={formik.handleBlur}
                    placeholderText={"DD/MM/YYYY HH:mm"}
                    locale="pt-BR"
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="Pp"
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button type="button" variant="outlined" onClick={handleCancel}>
                      Huỷ
                    </Button>
                    <Button type="submit" variant="contained">
                      Xác nhận
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalAssignment;
