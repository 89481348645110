import { ErrorMessage, Field } from "formik";
import React, { FC } from "react";
import { FieldCommonProps } from "./FieldContainer";
import LabelField from "./LabelField";
import TextError from "./TextError";
import { NumericFormat } from "react-number-format";

export interface NumberInputFieldProps extends FieldCommonProps {
  classNameError?: object;
  styleError?: object;
  [key: string]: any;
}

const NumberInputField: FC<NumberInputFieldProps> = (props) => {
  const { label, name, options, tooltip, isRequired, classNameError, styleError, ...rest } = props;
  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <Field name={name}>
        {({ field, form }: { field: any; form: any }) => (
          <>
            <NumericFormat
              className="form-control"
              onValueChange={(selectedOption: any) => {
                form.setFieldValue(name, selectedOption ? selectedOption.value : null);
              }}
              name={name}
              {...rest}
            />
          </>
        )}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default NumberInputField;
