import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Stack } from "@mui/material";
import Tab from "@mui/material/Tab";
import React, { useRef, useState } from "react";
import GeneralBtnSubmitIcon from "../../../components/icons/GeneralBtnSubmitIcon";
import { TAB_SELECT_PERSONNEL_ENUM, TYPE_PERSONNEL_ENUM } from "../../../enum/personnel.enum";
import { GeneralInformationModel, PersonnelModel } from "../../../model/personnel.model";
import { spacing_size } from "../../../themes/size";
import Contract from "./Contract";
import Document from "./Document";
import GeneralInformation from "./GeneralInformation";
import Level from "./Level";
import Relatives from "./Relatives";
import WorkingProcess from "./WorkingProcess";
import { getNextStepTabPersonnel, handleCreateHr, isInValidValueUploadGI } from "../common/util/personnel.util";
import { useNavigate } from "react-router-dom";

function StepperCreate() {
  const initialValuesUpload: PersonnelModel = {
    type: TYPE_PERSONNEL_ENUM.LEADERS_AND_DEPARTMENTS,
    fullName: "",
    dob: null,
    gender: "",
    phoneNumber: "",
    workingAddress: "",
    maritalStatus: "",
    nationalityId: "",
    ethnic: "",
    religion: "",
    nativePlace: "",
    permanentAddress: "",
    address: "",
    email: "",
    positionId: "",
    departmentId: "",
    managerId: 0,
    startDate: null,
    taxCode: "",
    identityCard: "",
    idcIssueDate: null,
    idcIssuePlace: "",
    passport: "",
    passportIssueDate: null,
    passportIssuePlace: "",
    partyPosition: "",
    partyJoinDate: null,
    partyJoinPlace: "",
    unionPosition: "",
    unionJoinDate: null,
    unionJoinPlace: "",
    rewards: "",
    disciplines: "",
    workPermitNumber: "",
    workPermitFromDate: null,
    workPermitToDate: null,
    temporaryResidenceCard: "",
    employeeContactInfo: {
      fullName: "",
      phoneNumber: "",
      relationship: "",
    },
    contracts: [],
    employeeWorkingHistories: [],
    educations: [],
    employeeRelatives: [],
    documents: [],
    contractAddendums: [],
    bankAccount: "",
    workingUnitInFederation: "",
    externalWorkingUnit: "",
  };

  const initValuesView: PersonnelModel = {
    ...initialValuesUpload,
    identity: [],
    selectPosition: [],
    selectDocOther: [],
  };

  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<TAB_SELECT_PERSONNEL_ENUM>(
    TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION
  );

  const [valueUploads, setValuesUploads] = useState<PersonnelModel | null>(initialValuesUpload);
  const [fileContractsUpload, setFileContractsUpload] = useState<File[]>([]);
  const [fileContractsAddendumUpload, setFileContractsAddendumUpload] = useState<File[]>([]);
  const [fileEducationsUpload, setFileEducationsUpload] = useState<File[]>([]);
  const [fileDocumentsUpload, setFileDocumentsUpload] = useState<File[]>([]);

  const [valueFormLeadersAndDepartments, setValueFormLeadersAndDepartments] = useState<PersonnelModel | null>(
    initValuesView
  );
  const [valueFormExpertsAndCoachingStaff, setValueFormExpertsAndCoachingStaff] = useState<PersonnelModel | null>(
    initValuesView
  );
  const [valueFormOperatingParts, setValueFormOperatingParts] = useState<PersonnelModel | null>(initValuesView);
  const [valueFormTheSubordinateUnits, setValueFormTheSubordinateUnits] = useState<PersonnelModel | null>(
    initValuesView
  );
  const [valueFormOther, setValueFormOther] = useState<GeneralInformationModel | null>(initValuesView);

  const [valuesViewGeneralInformation, setValuesViewGeneralInformation] = useState<PersonnelModel | null>(
    initValuesView
  );

  const btnSubmitCurrentFormActiveRef = useRef<HTMLButtonElement>(null);
  const btnCurrentFormActiveRefNext = useRef<HTMLButtonElement>(null);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TAB_SELECT_PERSONNEL_ENUM) => {
    btnCurrentFormActiveRefNext.current?.click();
    setCurrentTab(newValue);
  };

  const handleNextStepper = (currentTab: TAB_SELECT_PERSONNEL_ENUM) => {
    btnCurrentFormActiveRefNext.current?.click();
    setCurrentTab(getNextStepTabPersonnel(currentTab, 1));
  };

  const handlePreStepper = (currentTab: TAB_SELECT_PERSONNEL_ENUM) => {
    btnCurrentFormActiveRefNext.current?.click();
    setCurrentTab(getNextStepTabPersonnel(currentTab, -1));
  };

  const handleSubmit = async () => {
    switch (currentTab) {
      case TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION: {
        btnSubmitCurrentFormActiveRef.current?.click();
        return;
      }
      case TAB_SELECT_PERSONNEL_ENUM.CONTRACT:
      case TAB_SELECT_PERSONNEL_ENUM.DOCUMENT:
      case TAB_SELECT_PERSONNEL_ENUM.LEVEL:
      case TAB_SELECT_PERSONNEL_ENUM.RELATIVE:
      case TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS: {
        if (isInValidValueUploadGI(valueUploads)) {
          setCurrentTab(TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION);
          setTimeout(() => {
            btnSubmitCurrentFormActiveRef.current?.click();
          }, 500);
          return;
        }

        // if (isEmptyArr(valueUploads?.identity)) {
        //   toast.error("Có lỗi ở form thông tin chung. Bắt buộc phải chọn một trong hai loại giấy tờ tuỳ thân!");
        //   return;
        // }

        // if (
        //   valueUploads?.type === TYPE_PERSONNEL_ENUM.EXPERTS_AND_COACHING_STAFF &&
        //   isEmptyArr(valueUploads?.selectDocOther)
        // ) {
        //   toast.error("Có lỗi ở form thông tin chung. Bắt buộc phải chọn một trong hai thông tin loại giấy tờ!");
        //   return;
        // }

        handleCreateHr(
          valueUploads,
          fileContractsUpload,
          fileEducationsUpload,
          fileContractsAddendumUpload,
          fileDocumentsUpload,
          navigate
        );
        break;
      }

      default:
        break;
    }
  };

  return (
    <>
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList aria-label="create employee tab" onChange={handleChangeTab} variant="scrollable">
            <Tab
              label="Thông tin chung"
              value={TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION}
              sx={{
                fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION ? "bold" : "normal",
                "&.Mui-selected": { fontWeight: "bold" },
                fontSize: "1.2rem",
              }}
            />
            <Tab
              label="Hợp đồng"
              value={TAB_SELECT_PERSONNEL_ENUM.CONTRACT}
              sx={{
                fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.CONTRACT ? "bold" : "normal",
                "&.Mui-selected": { fontWeight: "bold" },
                fontSize: "1.2rem",
              }}
            />
            <Tab
              label="Quá trình công tác"
              value={TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS}
              sx={{
                fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS ? "bold" : "normal",
                "&.Mui-selected": { fontWeight: "bold" },
                fontSize: "1.2rem",
              }}
            />
            <Tab
              label="Trình độ"
              value={TAB_SELECT_PERSONNEL_ENUM.LEVEL}
              sx={{
                fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.LEVEL ? "bold" : "normal",
                "&.Mui-selected": { fontWeight: "bold" },
                fontSize: "1.2rem",
              }}
            />
            <Tab
              label="Người thân"
              value={TAB_SELECT_PERSONNEL_ENUM.RELATIVE}
              sx={{
                fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.RELATIVE ? "bold" : "normal",
                "&.Mui-selected": { fontWeight: "bold" },
                fontSize: "1.2rem",
              }}
            />
            <Tab
              label="Tài liệu"
              value={TAB_SELECT_PERSONNEL_ENUM.DOCUMENT}
              sx={{
                fontWeight: currentTab === TAB_SELECT_PERSONNEL_ENUM.DOCUMENT ? "bold" : "normal",
                "&.Mui-selected": { fontWeight: "bold" },
                fontSize: "1.2rem",
              }}
            />
          </TabList>
        </Box>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION}>
          <GeneralInformation
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            valuesViewGeneralInformation={valuesViewGeneralInformation}
            setValuesViewGeneralInformation={setValuesViewGeneralInformation}
            valueFormOther={valueFormOther}
            setValueFormOther={setValueFormOther}
            valueFormLeadersAndDepartments={valueFormLeadersAndDepartments}
            setValueFormLeadersAndDepartments={setValueFormLeadersAndDepartments}
            valueFormExpertsAndCoachingStaff={valueFormExpertsAndCoachingStaff}
            setValueFormExpertsAndCoachingStaff={setValueFormExpertsAndCoachingStaff}
            valueFormOperatingParts={valueFormOperatingParts}
            setValueFormOperatingParts={setValueFormOperatingParts}
            valueFormTheSubordinateUnits={valueFormTheSubordinateUnits}
            setValueFormTheSubordinateUnits={setValueFormTheSubordinateUnits}
            fileContractsUpload={fileContractsUpload}
            fileEducationsUpload={fileEducationsUpload}
            fileContractsAddendumUpload={fileContractsAddendumUpload}
            fileDocumentsUpload={fileDocumentsUpload}
            btnCurrentFormActiveNextRef={btnCurrentFormActiveRefNext}
            btnSubmitCurrentFormActiveRef={btnSubmitCurrentFormActiveRef}
          />
        </TabPanel>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.CONTRACT}>
          <Contract
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            fileContractsUpload={fileContractsUpload}
            setFileContractsUpload={setFileContractsUpload}
            btnSubmitContractRef={btnCurrentFormActiveRefNext}
            fileContractsAddendumUpload={fileContractsAddendumUpload}
            setFileContractsAddendumUpload={setFileContractsAddendumUpload}
          />
        </TabPanel>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.WORKING_PROCESS}>
          <WorkingProcess
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            btnSubmitWorkingProcessRef={btnCurrentFormActiveRefNext}
          />
        </TabPanel>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.LEVEL}>
          <Level
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            fileEducationsUpload={fileEducationsUpload}
            setFileEducationsUpload={setFileEducationsUpload}
            btnSubmitEducationRef={btnCurrentFormActiveRefNext}
          />
        </TabPanel>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.RELATIVE}>
          <Relatives
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            btnSubmitRelativesRef={btnCurrentFormActiveRefNext}
          />
        </TabPanel>

        <TabPanel value={TAB_SELECT_PERSONNEL_ENUM.DOCUMENT}>
          <Document
            valueUploads={valueUploads}
            setValuesUploads={setValuesUploads}
            fileDocumentsUpload={fileDocumentsUpload}
            setFileDocumentsUpload={setFileDocumentsUpload}
          />
        </TabPanel>
      </TabContext>

      {/* action */}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={spacing_size}
        sx={{
          justifyContent: "space-between",
          padding: "0 24px",
        }}
      >
        <Button
          sx={{ visibility: currentTab !== TAB_SELECT_PERSONNEL_ENUM.GENERAL_INFORMATION ? "initial" : "hidden" }}
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => handlePreStepper(currentTab)}
        >
          Quay lại
        </Button>

        <Button
          variant="contained"
          color="orange"
          type="submit"
          sx={{ color: "#000" }}
          startIcon={<GeneralBtnSubmitIcon />}
          onClick={handleSubmit}
        >
          Lưu thông tin nhân sự
        </Button>
        <Button
          sx={{ visibility: currentTab !== TAB_SELECT_PERSONNEL_ENUM.DOCUMENT ? "initial" : "hidden" }}
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => handleNextStepper(currentTab)}
        >
          Tiếp tục
        </Button>
      </Stack>
    </>
  );
}

export default StepperCreate;
