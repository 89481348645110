import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const SettingIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m14.45 9.776-1.023-.875a5.623 5.623 0 0 0 0-1.806l1.023-.875a.5.5 0 0 0 .146-.55l-.014-.04a6.934 6.934 0 0 0-1.246-2.155l-.028-.033a.502.502 0 0 0-.548-.148l-1.27.451a5.507 5.507 0 0 0-1.559-.9l-.245-1.328a.5.5 0 0 0-.403-.401l-.042-.008a7.06 7.06 0 0 0-2.485 0l-.042.008a.5.5 0 0 0-.403.401l-.247 1.334a5.498 5.498 0 0 0-1.547.897l-1.28-.454a.5.5 0 0 0-.548.148l-.028.033A6.97 6.97 0 0 0 1.416 5.63l-.014.04a.502.502 0 0 0 .145.55l1.036.885a5.497 5.497 0 0 0 0 1.784l-1.036.884a.5.5 0 0 0-.145.55l.014.041a6.952 6.952 0 0 0 1.245 2.155l.028.033a.502.502 0 0 0 .549.148l1.28-.455a5.46 5.46 0 0 0 1.546.897l.247 1.335a.501.501 0 0 0 .403.401l.042.008a7.02 7.02 0 0 0 2.485 0l.042-.008a.5.5 0 0 0 .403-.402l.245-1.328a5.468 5.468 0 0 0 1.558-.9l1.27.452a.5.5 0 0 0 .549-.148l.028-.033a6.97 6.97 0 0 0 1.245-2.155l.014-.04a.498.498 0 0 0-.145-.548ZM12.317 7.28a4.429 4.429 0 0 1 0 1.44l-.103.627 1.168.998a5.78 5.78 0 0 1-.666 1.15l-1.45-.514-.49.403c-.374.306-.79.547-1.24.716l-.595.223-.28 1.516c-.441.05-.887.05-1.328 0l-.28-1.519-.59-.226a4.372 4.372 0 0 1-1.23-.714l-.49-.405-1.46.519a5.908 5.908 0 0 1-.666-1.15l1.18-1.008-.101-.625a4.308 4.308 0 0 1 0-1.422l.101-.625-1.18-1.008c.177-.408.4-.792.666-1.15l1.46.519.49-.405c.37-.304.784-.545 1.23-.714l.592-.223.28-1.519c.439-.05.887-.05 1.328 0l.28 1.516.595.223c.448.169.865.41 1.239.716l.49.403 1.45-.514c.266.358.488.743.666 1.15l-1.167.998.101.624ZM8 5.094a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5ZM9.238 9.08A1.744 1.744 0 0 1 8 9.594a1.744 1.744 0 0 1-1.617-1.08 1.744 1.744 0 0 1 .38-1.907c.331-.332.77-.513 1.237-.513.467 0 .906.18 1.238.512a1.744 1.744 0 0 1 .512 1.238c0 .467-.183.906-.512 1.237Z"
        fill="#D4D9E4"
      />
    </svg>
  );
};

export default SettingIcon;
