import { Tooltip } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import React, { FC } from "react";

interface OutOfDateDocInIconProps {}

const OutOfDateDocInIcon: FC<OutOfDateDocInIconProps> = (props) => {
  return (
    <Tooltip
      title="Đã quá hạn xử lý"
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "red",
            "& .MuiTooltip-arrow": {
              color: "red",
            },
          },
        },
      }}
    >
      <WarningAmberIcon
        sx={{
          color: "#FF2001",
          fontSize: "1rem",
          ml: 1,
        }}
      />
    </Tooltip>
  );
};

export default OutOfDateDocInIcon;
