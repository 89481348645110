import React, { FC } from "react";
import { downloadFileTaskApi } from "../../../api/task/task.api";
import { toast } from "react-toastify";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { error } from "console";
import FileSaver from "file-saver";
import { Typography } from "@mui/material";
import { getFileExtension, previewOrDowndloadFile } from "../../../utils/file.util";

interface ViewItemFileAttachTaskProps {
  file: any;
}

const ViewItemFileAttachTask: FC<ViewItemFileAttachTaskProps> = (props) => {
  const { file } = props;

  const handleOnClickFile = async (file: any) => {
    try {
      const res = await downloadFileTaskApi(Number(file?.taskId), Number(file?.id));
      // FileSaver.saveAs(res?.data, file?.name);
      previewOrDowndloadFile(file?.name, getFileExtension(file?.name), res?.data)
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  return (
    <Typography
      sx={{
        cursor: "pointer",
        color: "#0084FF",
      }}
      onClick={() => {
        handleOnClickFile(file);
      }}
    >
      {file?.name}
    </Typography>
  );
};

export default ViewItemFileAttachTask;
