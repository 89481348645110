import React, { useEffect, useState } from "react";
import { SIZE_DEFAULT } from "../../../../constants/page.constant";
import { FilterDocBookReqModel } from "../../../../model/doc-book.model";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { filterDocBookApiAsync, setPage } from "../redux/doc-book.slice";
import * as Yup from "yup";
import BoxContainer from "../../../../components/common/BoxContainer";
import { Form, Formik, FormikHelpers } from "formik";
import { Button, Grid, Stack } from "@mui/material";
import FieldContainer from "../../../../components/form/FieldContainer";
import SearchIcon from "../../../../components/icons/SearchIcon";
import { DocBooksWithAll } from "../../../../enum/doc-book.enum";
import { ALL_ENUM } from "../../../../enum/common.enum";
import { spacing_size } from "../../../../themes/size";
import { addYears, startOfYear } from "date-fns";

interface InitialValuesForm {
  docYear: null | Date;
  code: string; // Loại
  status: string;
}

const statusOptions = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "true",
    label: "Hoạt động",
  },
  {
    value: "false",
    label: "Không hoạt động",
  },
];

export const FormFilterDocBook = () => {
  const reloadFilter = useAppSelector((state) => state.docBook.reloadFilter);
  const page = useAppSelector((state) => state.docBook.page);
  const RANGE_YEAR_DOC_YEAR = 50;
  const defaultDocYear = startOfYear(new Date());
  const dispatch = useAppDispatch();
  const initBodyFilter = {
    code: null,
    docYear: null,
    status: null,
  };
  const [bodyFilter, setBodyFilter] = useState<FilterDocBookReqModel | null>(initBodyFilter);
  const [isInitFilter, setIsInitFilter] = useState(true);

  const loadDataFilter = (body = bodyFilter) => {
    const pageable = { page, size: SIZE_DEFAULT };
    dispatch(filterDocBookApiAsync({ body, pageable }));
  };

  useEffect(() => {
    if (reloadFilter !== 0) {
      loadDataFilter();
    }
  }, [reloadFilter]);

  useEffect(() => {
    if (isInitFilter) {
      setIsInitFilter(false);
      if (page > 0) {
        dispatch(setPage(0));
      } else {
        loadDataFilter();
      }
    } else {
      loadDataFilter();
    }
  }, [page]);

  const initialValues: InitialValuesForm = {
    docYear: null,
    code: ALL_ENUM,
    status: "",
  };

  const validationSchema = Yup.object({
    // docYear: Yup.date().nullable(),
  });

  const buildBodyFilter = (value: InitialValuesForm): FilterDocBookReqModel | null => {
    return {
      docYear: value.docYear?.getFullYear() || (null as any),
      status: value.status || null,
      code: value.code === ALL_ENUM ? null : value.code,
    };
  };

  const onSubmitSearch = (value: InitialValuesForm, formikHelper: FormikHelpers<InitialValuesForm>) => {
    const body = buildBodyFilter(value);
    setBodyFilter(body);
    if (page === 0) {
      const pageable = { page, size: SIZE_DEFAULT };
      dispatch(filterDocBookApiAsync({ body, pageable }));
    } else {
      dispatch(setPage(0));
    }
  };

  return (
    <>
      <BoxContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitSearch}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={spacing_size}>
                <Grid item md={4} xs={12}>
                  <FieldContainer
                    name="docYear"
                    onBlur={formik.handleBlur}
                    value={formik.values.docYear}
                    dateFormat="yyyy"
                    variant={"date"}
                    showYearPicker
                    id={"docYear"}
                    label="Năm công văn"
                    yearItemNumber={12}
                    minDate={addYears(defaultDocYear, -RANGE_YEAR_DOC_YEAR)}
                    maxDate={addYears(defaultDocYear, RANGE_YEAR_DOC_YEAR)}
                    placeholderText="Năm công văn"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldContainer
                    name="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.code}
                    label="Loại"
                    variant={"select"}
                    id={"code"}
                    labelOption="description"
                    valueOption="code"
                    options={DocBooksWithAll}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldContainer
                    name="status"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.status}
                    label="Trạng thái"
                    variant={"select"}
                    id={"status"}
                    options={statusOptions}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="cancel"
                      type="button"
                      onMouseDown={() => {
                        formik.resetForm();
                        setBodyFilter(initBodyFilter);
                        if (page === 0) {
                          loadDataFilter(initBodyFilter);
                        } else {
                          dispatch(setPage(0));
                        }
                      }}
                    >
                      Huỷ
                    </Button>
                    <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                      Tìm kiếm
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </BoxContainer>
    </>
  );
};
