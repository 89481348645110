import { TableRow, TableCell, IconButton, Typography, Stack, Button, Collapse, Box } from "@mui/material";
import React, { FC, useState } from "react";
import { VIEW_DATE_FORMAT, UPLOAD_DATE_FORMAT } from "../../../constants/date-time.constant";
import { spacing_size } from "../../../themes/size";
import { convertFormPayHr, convertTypeContract, downloadFileHrByType } from "../common/util/personnel.util";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FiDownload } from "react-icons/fi";
import { TYPE_FILE_ENUM } from "../../../enum/hr.enum";
import ContractAddendumTableView from "./ContractAddendumTableView";
import { active_contract_color, inactive_contract_color, primary_color } from "../../../themes/color";
import { formatDateTime } from "../../../utils/data-time.util";
import ReadMore from "../../../components/common/ReadMore";
import { formatNumber } from "../../../utils/number.util";

interface ContractTableViewProps {
  item: any;
  index: any;
}

const ContractTableView: FC<ContractTableViewProps> = (props) => {
  const { item, index } = props;
  // console.log(item);
  const [openRow, setOpenRow] = useState<boolean>(false);

  const currentDate = new Date();
  const formatCurrentDateDetail = formatDateTime(currentDate, UPLOAD_DATE_FORMAT);

  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
        }}
        key={index}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpenRow(!openRow)}>
            {openRow ? (
              <KeyboardArrowUpIcon sx={{ color: primary_color }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: primary_color }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: "400px", maxWidth: "400px", wordWrap: "break-word" }}>
          <Typography variant="body1" sx={{ fontWeight: "500", fontSize: "1.2rem" }}>{`[${convertTypeContract(
            item?.type
          )}] - ${item?.code}`}</Typography>
          <Typography variant="body1" sx={{ fontStyle: "italic" }}>{`Hiệu lực: ${formatDateTime(
            item?.startDate,
            VIEW_DATE_FORMAT
          )} ${item?.endDate ? " - " + formatDateTime(item?.endDate, VIEW_DATE_FORMAT) : ""}`}</Typography>
          <Typography variant="body1" sx={{ color: "#FFA215" }} fontWeight={400}>
            {convertFormPayHr(item?.formPay)}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "150px", maxWidth: "150px", wordWrap: "break-word" }}>
          {formatNumber(item?.salary)}
        </TableCell>
        <TableCell sx={{ width: "150px", maxWidth: "150px", wordWrap: "break-word" }}>
          {formatNumber(item?.allowances)}
        </TableCell>
        <TableCell sx={{ width: "200px", maxWidth: "200px", wordWrap: "break-word" }}>
          {formatNumber(item?.monthlyBonus)}
        </TableCell>
        <TableCell sx={{ maxWidth: "200px", wordWrap: "break-word" }}>
          <ReadMore text={item?.note} />
        </TableCell>
        <TableCell sx={{ width: "150px", wordWrap: "break-word" }}>
            {new Date(item?.endDate).getTime() + 7 * 60 * 60 * 1000 >= new Date(formatCurrentDateDetail).getTime() ? (
                <Typography sx={{ color: "#348F14" }}>Còn hiệu lực</Typography>
              ) : (
                <Typography sx={{ color: "red" }}>Hết hiệu lực</Typography>
            )}
        </TableCell>
        <TableCell align="center" sx={{ width: "150px" }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={spacing_size}
            sx={{
              justifyContent: "center",
            }}
          >
            <IconButton
              type="button"
              onClick={() =>
                downloadFileHrByType(item.contractFile.id, item.contractFile.name || "", TYPE_FILE_ENUM.CONTRACT)
              }
            >
              <FiDownload style={{ color: "#348F14" }} />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }} colSpan={8}>
          <Collapse in={openRow} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <ContractAddendumTableView contractAddendum={item?.contractAddendums || []} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContractTableView;
