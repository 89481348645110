import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterApi } from "../../../../api/doc-book.api";
import { TOTAL_HEADER } from "../../../../constants/page.constant";
import { DocBook, FilterDocBookReqModel } from "../../../../model/doc-book.model";
import { Pageable } from "../../../../model/page.model";

interface DocBookSliceState {
  page: number;
  dataDocBooks: DocBook[] | null;
  totalDataDocBooks: number;
  reloadFilter: number;
}

export const filterDocBookApiAsync = createAsyncThunk(
  "DocBookSlice/filter",
  async (data: { body: FilterDocBookReqModel | null; pageable: Pageable }) => {
    try {
      const res = await filterApi(data.body, data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      throw Error(error);
    }
  }
);

const initialState: DocBookSliceState = {
  page: 0,
  dataDocBooks: null,
  reloadFilter: 0,
  totalDataDocBooks: 0,
};

const DocBookSlice = createSlice({
  name: "DocBookSlice",
  initialState,
  reducers: {
    setPage(state, action) {
      return {
        ...state,
        page: action.payload,
      };
    },
    setReloadFilter(state, action) {
      return {
        ...state,
        reloadFilter: action.payload === 0 ? 0 : action.payload || state.reloadFilter + 1,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(
      filterDocBookApiAsync.fulfilled,
      (state, action: { payload: { data: DocBook[] | null; total: number } }) => {
        return {
          ...state,
          dataDocBooks: action.payload.data,
          totalDataDocBooks: action.payload.total,
        };
      }
    );
  },
});

export const { setPage, setReloadFilter } = DocBookSlice.actions;
export default DocBookSlice.reducer;
