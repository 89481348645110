import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import ModalContainer from "../../components/modal/ModalContainer";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FieldContainer from "../../components/form/FieldContainer";
import { AUTH_MSG, COMMON_MSG } from "../../constants/msg/msg.constant";
import { toast } from "react-toastify";
import { getErrorMsgAxios, isTooManyRequests } from "../../utils/error.util";
import { resetPasswordApi, verifyOtpApi } from "../../api/auth.api";
import { spacing_size } from "../../themes/size";
import { EXCEPT_SYMBOLS } from "../../constants/except.constant";
import Countdown, { zeroPad } from "react-countdown";

interface OtpModalProps {
  openModal: boolean;
  email: string;
  otpExpirationMinutes: number;
  onCloseModal: () => void;
}

interface InitialValuesForm {
  otp1: string | null;
  otp2: string | null;
  otp3: string | null;
  otp4: string | null;
  otp5: string | null;
  otp6: string | null;
}

const OtpModal: FC<OtpModalProps> = (props) => {
  const { openModal, email, onCloseModal } = props;
  const [otpExpirationMinutes, setOtpExpirationMinutes] = useState(props.otpExpirationMinutes);
  const [keyCountDownTime, setKeyCountDownTime] = useState(0);
  const recaptchaRef: any = useRef();
  const input1Ref = useRef<any>();
  const input2Ref = useRef<any>();
  const input3Ref = useRef<any>();
  const input4Ref = useRef<any>();
  const input5Ref = useRef<any>();
  const input6Ref = useRef<any>();

  useEffect(() => {
    if (input1Ref) {
      setTimeout(() => {
        input1Ref?.current?.focus();
      }, 0);
    }
  }, []);

  const initialValues: InitialValuesForm = {
    otp1: null,
    otp2: null,
    otp3: null,
    otp4: null,
    otp5: null,
    otp6: null,
  };

  const validationSchema = Yup.object({
    otp1: Yup.string().nullable(),
    otp2: Yup.string().nullable(),
    otp3: Yup.string().nullable(),
    otp4: Yup.string().nullable(),
    otp5: Yup.string().nullable(),
    otp6: Yup.string().nullable(),
  });

  const isErrorOtp = (value: InitialValuesForm) => {
    return Object.values(value).some((item) => [null, undefined, ""].includes(item));
  };

  const handleOnSubmit = async (values: InitialValuesForm) => {
    if (!isErrorOtp(values)) {
      try {
        await resetPasswordApi(email, Object.values(values).join(""));
        onCloseModal();
        toast.success(AUTH_MSG.forgotPWSuccess);
      } catch (error) {
        if (isTooManyRequests(error)) {
          toast.error(COMMON_MSG.limitChangePW);
        } else {
          toast.error(getErrorMsgAxios(error));
        }
      }
    }
  };

  const handleReSendOtp = async (e: any) => {
    e.preventDefault();
    const token = "await recaptchaRef.current.executeAsync()";
    try {
      const res = await verifyOtpApi(email, token);
      setOtpExpirationMinutes(res.data.otpExpirationMinutes);
      setKeyCountDownTime(keyCountDownTime + 1);
      toast.success("Gửi lại OTP thành công");
    } catch (error) {
      if (isTooManyRequests(error)) {
        toast.error(COMMON_MSG.limitChangePW);
      } else {
        toast.error(getErrorMsgAxios(error));
      }
    }
  };

  const rendererCountdownTime = ({ minutes, seconds }: { minutes: number; seconds: number }) => {
    // Render a countdown
    return (
      <Typography component="span" sx={{ color: "#FF2001" }}>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </Typography>
    );
  };

  const handleChangeFocusOtp = (currentValue: any, focusInputRef: any) => {
    if (![null, undefined, ""].includes(currentValue)) {
      focusInputRef?.current?.focus();
    }
  };

  const handlePasteOtp = (formik: any) => (e: any) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text") || "";
    if (pastedText && pastedText.length == 6) {
      formik.setValues(
        {
          otp1: pastedText[0],
          otp2: pastedText[1],
          otp3: pastedText[2],
          otp4: pastedText[3],
          otp5: pastedText[4],
          otp6: pastedText[5],
        },
        true
      );
    }
  };

  return (
    <ModalContainer
      open={openModal}
      title={"Quên mật khẩu"}
      onClose={() => {
        onCloseModal();
      }}
    >
      <>
        <Typography mb={spacing_size}>
          Mã xác thực đã được gửi tới email{" "}
          <Typography component="b" fontWeight={600}>
            {email}
          </Typography>
          . Vui lòng nhập mã xác thực.
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={() => {}}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => (
            <>
              <Form autoComplete="off" noValidate style={{ padding: "0px 2px" }}>
                <Grid container spacing={spacing_size}>
                  <Grid item xs={2} spacing={spacing_size}>
                    <FieldContainer
                      fullWidth
                      name="otp1"
                      onBlur={formik.handleBlur}
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        handleChangeFocusOtp(e.target.value, input2Ref);
                      }}
                      type="text"
                      value={formik.values.otp1}
                      placeholder={""}
                      label=""
                      variant={"number-input"}
                      id={"otp1"}
                      maxLength={1}
                      thousandSeparator=","
                      getInputRef={input1Ref}
                      onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                      onPaste={handlePasteOtp(formik)}
                    />
                  </Grid>
                  <Grid item xs={2} spacing={spacing_size}>
                    <FieldContainer
                      fullWidth
                      name="otp2"
                      onBlur={formik.handleBlur}
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        handleChangeFocusOtp(e.target.value, input3Ref);
                      }}
                      type="text"
                      value={formik.values.otp2}
                      placeholder={""}
                      label=""
                      maxLength={1}
                      variant={"number-input"}
                      id={"otp2"}
                      getInputRef={input2Ref}
                      thousandSeparator=","
                      onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                      onPaste={handlePasteOtp(formik)}
                    />
                  </Grid>
                  <Grid item xs={2} spacing={spacing_size}>
                    <FieldContainer
                      fullWidth
                      name="otp3"
                      onBlur={formik.handleBlur}
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        handleChangeFocusOtp(e.target.value, input4Ref);
                      }}
                      type="text"
                      value={formik.values.otp3}
                      placeholder={""}
                      label=""
                      variant={"number-input"}
                      id={"otp3"}
                      maxLength={1}
                      getInputRef={input3Ref}
                      thousandSeparator=","
                      onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                      onPaste={handlePasteOtp(formik)}
                    />
                  </Grid>
                  <Grid item xs={2} spacing={spacing_size}>
                    <FieldContainer
                      fullWidth
                      name="otp4"
                      onBlur={formik.handleBlur}
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        handleChangeFocusOtp(e.target.value, input5Ref);
                      }}
                      type="text"
                      value={formik.values.otp4}
                      placeholder={""}
                      label=""
                      variant={"number-input"}
                      id={"otp4"}
                      maxLength={1}
                      getInputRef={input4Ref}
                      thousandSeparator=","
                      onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                      onPaste={handlePasteOtp(formik)}
                    />
                  </Grid>
                  <Grid item xs={2} spacing={spacing_size}>
                    <FieldContainer
                      fullWidth
                      name="otp5"
                      onBlur={formik.handleBlur}
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        handleChangeFocusOtp(e.target.value, input6Ref);
                      }}
                      type="text"
                      value={formik.values.otp5}
                      placeholder={""}
                      label=""
                      variant={"number-input"}
                      id={"otp5"}
                      maxLength={1}
                      getInputRef={input5Ref}
                      thousandSeparator=","
                      onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                      onPaste={handlePasteOtp(formik)}
                    />
                  </Grid>
                  <Grid item xs={2} spacing={spacing_size}>
                    <FieldContainer
                      fullWidth
                      name="otp6"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.otp6}
                      placeholder={""}
                      label=""
                      variant={"number-input"}
                      id={"otp6"}
                      getInputRef={input6Ref}
                      maxLength={1}
                      thousandSeparator=","
                      onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                      onPaste={handlePasteOtp(formik)}
                    />
                  </Grid>
                </Grid>
                {/* <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_KEY_SITE} /> */}
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  spacing={{ xs: 1, md: 3 }}
                  sx={{ margin: "auto", marginTop: "1.2rem", marginBottom: "0.6rem" }}
                >
                  {/* <div>
                    Hiệu lực còn lại của OTP:{" "}
                    <Countdown
                      date={Date.now() + 1000 * 60 * otpExpirationMinutes}
                      renderer={rendererCountdownTime}
                      key={keyCountDownTime}
                      zeroPadTime={2}
                      onComplete={() => {
                        onCloseModal();
                      }}
                    />
                  </div> */}
                  <a href="#" id="reSendOtp" onMouseDown={handleReSendOtp}>
                    Gửi lại OTP
                  </a>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 1, md: 3 }}
                  sx={{ width: "fit-content", margin: "auto", marginTop: "1.2rem", marginBottom: "0.6rem" }}
                >
                  <Button
                    color="cancel"
                    variant="contained"
                    type="button"
                    onClick={() => {
                      onCloseModal();
                    }}
                  >
                    Hủy
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    disabled={isErrorOtp(formik.values)}
                    onClick={() => {
                      handleOnSubmit(formik.values);
                    }}
                  >
                    Gửi
                  </Button>
                </Stack>
              </Form>
            </>
          )}
        </Formik>
      </>
    </ModalContainer>
  );
};

export default OtpModal;
