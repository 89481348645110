import { Box, Table, TableBody, TableContainer, Typography } from "@mui/material";
import { FC } from "react";
import BoxContainer from "../../../../components/common/BoxContainer";
import HeaderPage from "../../../../components/common/HeaderPage";
import TableRowDetail from "../../../../components/common/TableRowDetail";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { downloadFileDocIn } from "../../../../api/doc-in.api";
import { FileModel, DocInModel, HistoryDocInModel } from "../../../../model/doc-in.model";
import {
  VIEW_DATE_FORMAT,
  VIEW_DATE_TIME_FORMAT,
  VIEW_DATE_TIME_WITHOUT_SECOND_FORMAT,
} from "../../../../constants/date-time.constant";
import moment from "moment";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import ReadMore from "../../../../components/common/ReadMore";
import { CATEGORY_DOC_IN_ENUM, STATUS_DOC_IN_ENUM } from "../../../../enum/doc-in.enum";
import { Department } from "../../../../model/department.model";
import { buildOptionObjectDepartment, buildOptionUserLabel } from "../../../../utils/options.util";
import { convertSentChannelDocIn } from "../common/util/doc-in.util";
import { UserModel } from "../../../../model/user.model";
import { previewOrDowndloadFile } from "../../../../utils/file.util";
import ReadmoreUppercase from "../../../../components/common/ReadmoreUppercase";
import { Link } from "react-router-dom";

interface FormDetailDocInProps {
  docInDetail: DocInModel;
}

const FormDetailDocIn: FC<FormDetailDocInProps> = (props) => {
  const { docInDetail } = props;

  const docInHistoryReturned: HistoryDocInModel = docInDetail?.docInHistories?.sort((a, b) => b.id - a.id)[0];

  const dowloadFileDocIn = async (id: number, name: string, docType: string) => {
    const params = {
      docFileId: id,
    };
    await downloadFileDocIn(params?.docFileId)
      .then((res) => {
        previewOrDowndloadFile(name, docType, res?.data);
      })
      .catch((err) => {
        throw Error(err);
      });
  };

  const handleDownloadFile = (id: number, name: string, docType: string) => {
    dowloadFileDocIn(id, name, docType);
  };

  return (
    <>
      <BoxContainer>
        <HeaderPage
          title={
            docInDetail?.docCategory === CATEGORY_DOC_IN_ENUM.DOMESTIC
              ? "Phiếu chuyển công văn đến trong nước"
              : "Phiếu xử lý văn bản quốc tế đến"
          }
          variant="h5"
          component="h5"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>SỐ CÔNG VĂN ĐẾN</Typography>
            <Box
              sx={{
                border: "1px solid #D4D9E4",
                borderRadius: "4px",
                width: "83px",
                height: "31px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {docInDetail?.arrivalNbr}
            </Box>
          </Box>
        </HeaderPage>
        <Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
          >
            THÔNG TIN CHUNG
          </Typography>
          <TableContainer sx={{ padding: "1rem" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRowDetail title={"Số và ký hiệu văn bản:"} content={docInDetail?.code} />
                <TableRowDetail title={"Nơi ban hành văn bản:"} content={docInDetail?.fromPlace} />
                <TableRowDetail
                  title={"Ngày tháng văn bản:"}
                  content={moment(docInDetail?.docDate).add(7, "hours").format(VIEW_DATE_FORMAT)}
                />
                <TableRowDetail
                  title={"Ngày văn bản đến:"}
                  content={moment(docInDetail?.arrivalDate).add(7, "hours").format(VIEW_DATE_FORMAT)}
                />
                {/* <TableRowDetail title={"Thời gian văn bản:"} content={"bbb"} /> */}
                <TableRowDetail title={"Số công văn:"} content={docInDetail?.docBook?.name} />
                <TableRowDetail title={"Số đến:"} content={docInDetail?.arrivalNbr} />
                <TableRowDetail title={"Trích yếu:"} content={docInDetail?.summary} />
                <TableRowDetail
                  title={"Thông tin đính kèm:"}
                  content={docInDetail?.docInFiles?.map((item: FileModel) => (
                    <Box
                      onClick={() => handleDownloadFile(item?.id, item?.name, item?.docType)}
                      title={item?.name}
                      sx={{
                        cursor: "pointer",
                        color: "#0084FF",
                      }}
                    >
                      {item?.name}
                    </Box>
                  ))}
                />
                <TableRowDetail
                  title={"Thời hạn xử lý theo văn bản:"}
                  content={
                    docInDetail?.deadline
                      ? moment(docInDetail?.deadline).format(VIEW_DATE_TIME_WITHOUT_SECOND_FORMAT)
                      : null
                  }
                />
                {docInDetail?.docCategory === CATEGORY_DOC_IN_ENUM.DOMESTIC ? (
                  <TableRowDetail title={"Hình thức:"} content={convertSentChannelDocIn(docInDetail?.sentChannel)} />
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {docInDetail?.docCategory === CATEGORY_DOC_IN_ENUM.INTERNATIONAL ? (
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              Nội dung văn bản được dịch
            </Typography>
            <TableContainer sx={{ padding: "1rem" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  <TableRowDetail
                    title={"Nội dung văn bản được dịch:"}
                    content={<ReadMore text={docInDetail?.contentTranslated} />}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <></>
        )}
        <Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
          >
            QUÁ TRÌNH LUÂN CHUYỂN VĂN BẢN
          </Typography>
          <TableContainer sx={{ padding: "1rem" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRowDetail
                  title={"Đơn vị/Phòng chủ trì giải quyết:"}
                  content={docInDetail?.handlingDepartments?.map((item: Department) => (
                    <Typography sx={{ display: "block", lineHeight: "1.6" }}>
                      {buildOptionObjectDepartment(item)}
                    </Typography>
                  ))}
                  // content={buildOptionObjectDepartment(docInDetail?.handlingDepartment)}
                />
                <TableRowDetail
                  title={" Đơn vị/Phòng phối hợp thực hiện:"}
                  content={docInDetail?.combinedDepartments?.map((item: Department) => (
                    <Typography sx={{ display: "block", lineHeight: "1.6" }}>
                      {buildOptionObjectDepartment(item)}
                    </Typography>
                  ))}
                />
                <TableRowDetail
                  title={" Báo cáo lãnh đạo/Ban tổng thư ký:"}
                  content={docInDetail?.bods?.map((item: UserModel) => (
                    <Box sx={{ display: "block", lineHeight: "1.6", fontSize: "1rem" }}>
                      {buildOptionUserLabel(item)}
                    </Box>
                  ))}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ height: "50px" }}></Box>

        {docInDetail?.status === STATUS_DOC_IN_ENUM.RETURNED ? (
          // thông tin người hoàn trả cuối cùng

          <Box sx={{ display: "flex", alignItems: "start" }}>
            <Box sx={{ color: "#E22D14", fontSize: "1.25rem", margin: "0 0.6rem" }}>
              <ErrorOutlineIcon />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#E22D14",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontStyle: "italic",
                  display: "flex",
                }}
              >
                Người hoàn trả:{" "}
                <Typography variant="body1" sx={{ marginLeft: "0.3rem", display: "block" }}>
                  {docInHistoryReturned?.picDetail?.fullName} ({docInHistoryReturned?.picDetail?.email})
                </Typography>
              </Typography>
              <Typography
                sx={{
                  color: "#E22D14",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontStyle: "italic",
                  display: "flex",
                }}
              >
                Thời gian hoàn trả:
                <Typography variant="body1" sx={{ marginLeft: "0.3rem" }}>
                  {moment(docInHistoryReturned?.createdDate).format(VIEW_DATE_TIME_FORMAT)}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  color: "#E22D14",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontStyle: "italic",
                  display: "flex",
                }}
              >
                Ý kiến:{" "}
                <Typography variant="body1" sx={{ marginLeft: "0.3rem" }}>
                  {/* {docInHistoryReturned?.comment} */}
                  <ReadmoreUppercase text={docInHistoryReturned?.comment} />
                </Typography>
              </Typography>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </BoxContainer>
    </>
  );
};

export default FormDetailDocIn;
