import { Button } from "@mui/material";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderPage from "../../../components/common/HeaderPage";
import ArrowBackIcon from "../../../components/icons/ArrowBackIcon";

interface HeaderUpdateProps {}

const HeaderUpdate: FC<HeaderUpdateProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const backPersonnelListPage = () => {
    const { bodyFilter, department, position, type, status } = location?.state || {};
    navigate("/personnel", {
      state: {
        bodyFilter: bodyFilter,
        department: department,
        position: position,
        type: type,
        status: status,
      },
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        startIcon={<ArrowBackIcon />}
        onClick={backPersonnelListPage}
        sx={{ marginBottom: "15px" }}
      >
        Trở về
      </Button>
      <HeaderPage title="Cập nhật nhân sự"></HeaderPage>
    </>
  );
};

export default HeaderUpdate;
