import { Box, Grid, useTheme } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React, { FC } from "react";
import { FieldCommonProps } from "./FieldContainer";
import LabelField from "./LabelField";
import TextError from "./TextError";

export interface RadioButtonsProps extends FieldCommonProps {
  options?: Object[] | any;
  labelOption?: string;
  valueOption?: string;
  [key: string]: unknown;
}

const RadioButtons: FC<RadioButtonsProps> = (props) => {
  const theme = useTheme();
  const {
    label,
    name,
    options,
    tooltip,
    isRequired,
    labelOption = "label",
    valueOption = "value",
    block,
    ...rest
  } = props;
  return (
    <>
      {label && <LabelField label={label} name={name} isRequired={isRequired} tooltip={tooltip} />}
      <Field name={name}>
        {({ field }: { field: any }) => {
          return (
            <Box
              sx={{
                [theme.breakpoints.down("md")]: {
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                },
              }}
            >
              {options?.map((option: any) => {
                return (
                  <span
                    key={option[labelOption]}
                    style={{
                      marginRight: "1rem",
                      cursor: "pointer",
                      display: block ? "block" : "",
                    }}
                  >
                    <input
                      type="radio"
                      // id={name}
                      {...field}
                      {...rest}
                      id={`${name}_${option[valueOption]}`}
                      value={option[valueOption]}
                      checked={field.value === option[valueOption]}
                    />
                    <label htmlFor={`${name}_${option[valueOption]}`} style={{ cursor: "pointer" }}>
                      {option[labelOption]}
                    </label>
                  </span>
                );
              })}
            </Box>
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
};

export default RadioButtons;
