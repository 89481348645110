import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { ROLE_TITLE } from "../../../constants/title-page.constant";
import { useAppDispatch } from "../../../store/hook";
import { getAllAccessRightsApiAsync } from "../user/redux/user.slice";
import HeaderRole from "./HeaderRole";
import ResultRole from "./ResultRole";

function Role() {
  document.title = ROLE_TITLE.list;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllAccessRightsApiAsync());
  }, []);
  return (
    <>
      <Box>
        <HeaderRole />
        <ResultRole />
      </Box>
    </>
  );
}

export default Role;
