import { Button, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { FC } from "react";
import FieldContainer from "../../../../components/form/FieldContainer";
import { spacing_size } from "../../../../themes/size";
import * as Yup from "yup";
import { DOC_IN_MSG, FORM_MSG } from "../../../../constants/msg/msg.constant";
import { docInReturnApi } from "../../../../api/doc-in.api";
import ModalContainer from "../../../../components/modal/ModalContainer";
import { toast } from "react-toastify";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { useNavigate } from "react-router-dom";

interface ModalRefuseProps {
  open: boolean;
  onClose: () => void;
  id: number;
}

interface InitialValuesForm {
  message: string;
}

const ModalRefuse: FC<ModalRefuseProps> = (props) => {
  const { open, onClose, id } = props;
  const navigate = useNavigate();
  const initialValues: InitialValuesForm = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Ý kiến")),
  });

  const handleCancel = () => {
    onClose();
  };

  const handleOnSubmit = async (values: InitialValuesForm, formik: FormikHelpers<InitialValuesForm>) => {
    await docInReturnApi(id, values)
      .then((res) => {
        if (res) {
          toast.success(DOC_IN_MSG.refuseSuccess);
          onClose();
          navigate("/doc-in");
        }
      })
      .catch((err) => {
        const msg = getErrorMsgAxios(err);
        toast.error(msg);
        throw Error(err);
      });
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title="Xác nhận "
      >
        <Typography id="modal-modal-description">
          Công văn sẽ được hoàn trả. Vui lòng nhập ý kiến và xác nhận!
        </Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off" style={{ margin: "0px 2px" }}>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="message"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 12 }}
                    value={formik.values.message}
                    placeholder="Nhập thông tin"
                    label="Ý kiến"
                    isRequired
                    variant={"textarea"}
                    id={"message"}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button type="button" variant="outlined" onClick={handleCancel}>
                      Huỷ
                    </Button>
                    <Button type="submit" variant="contained">
                      Xác nhận
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalRefuse;
