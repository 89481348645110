import { Button } from "@mui/material";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "../../../components/icons/ArrowBackIcon";

interface HeaderDetailProps {
  bodyFilter: any;
}

const HeaderDetail: FC<HeaderDetailProps> = (props) => {
  const navigate = useNavigate();
  const { bodyFilter } = props;
  const backPersonnelListPage = () => {
    navigate("/task", {
      state: { bodyFilter: bodyFilter },
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        startIcon={<ArrowBackIcon />}
        onClick={backPersonnelListPage}
        sx={{ marginBottom: "15px" }}
      >
        Quay lại
      </Button>
    </>
  );
};

export default HeaderDetail;
