import { ALL_ENUM } from "../enum/common.enum";

export const convertObjectToApplicationJsonFormData = (obj: Object) =>
  new Blob([JSON.stringify(obj)], {
    type: "application/json",
  });

export const convertDataToFormData = (
  files: File[] | null,
  values: any,
  keyFile: string,
  keyValue: string
): FormData => {
  const bodyFormData = new FormData();
  if (files) {
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      bodyFormData.append(keyFile, element);
    }
  }
  bodyFormData.append(
    keyValue,
    new Blob([JSON.stringify(values)], {
      type: "application/json",
    })
  );

  return bodyFormData;
};

export const isValueAllEnum = (value: any) => {
  return ALL_ENUM === value;
};
