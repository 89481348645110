import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC } from "react";
import ModalContainer from "../../../components/modal/ModalContainer";
import { spacing_size } from "../../../themes/size";

interface ModalDeleteProps {
  open: boolean;
  onClose: () => void;
  handleDeleteItem: any;
}

const ModalDelete: FC<ModalDeleteProps> = (props) => {
  const { open, onClose, handleDeleteItem } = props;

  const handleCancel = () => {
    onClose();
  };
  const handleOnSubmit = () => {
    handleDeleteItem();
    onClose();
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title="Xác nhận"
      >
        <Typography id="modal-modal-description">Bạn có chắc chắn muốn xoá?</Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={spacing_size}
          sx={{
            mt: 2,
            justifyContent: "end",
          }}
        >
          <Button type="button" variant="outlined" onClick={handleCancel}>
            Huỷ
          </Button>
          <Button type="button" variant="contained" onClick={handleOnSubmit}>
            Xác nhận
          </Button>
        </Stack>
      </ModalContainer>
    </>
  );
};

export default ModalDelete;
