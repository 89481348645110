export const MAX_LENGTH_FILE_DOC_IN = 5;
export const ALLOW_EXTENSION_FILE_DOC_IN = ["pdf", "docx", "xlsx", "xls", "doc", "jpg", "jpeg", "png", "zip", "rar"];
export const ALLOW_EXTENSION_FILE_TASK = ["xls", "xlsx", "doc", "docx", "pdf", "jpeg", "png"];
export const ALLOW_INPUT_FILE_DOC_IN = ALLOW_EXTENSION_FILE_DOC_IN.map((item) => `.${item}`).join(",");
export const ALLOW_INPUT_FILE_TASK = ALLOW_EXTENSION_FILE_TASK.map((item) => `.${item}`).join(",");
export const ALLOW_DEPARTMENT_CODE_CREATE_UPDATE_DOC_IN = "INR";
export const optionsSentChannel = [
  {
    label: "Email",
    value: "EMAIL",
  },
  {
    label: "Bản gốc",
    value: "ORIGINAL",
  },
];

export const OFFICE_MANAGER = ["Trưởng phòng"];

export const CODE_OFFICE_MANAGER = ["TP", "PTP"];

export const STAFF_POSITION = "Nhân viên";
