import { Typography } from "@mui/material";
import React, { FC } from "react";

interface HeaderTextProps {
  title: string;
}

const HeaderText: FC<HeaderTextProps> = (props) => {
  const { title } = props;
  return (
    <>
      <Typography sx={{ fontSize: "1rem", fontWeight: "500", lineHeight: "1.75", color: "#101426" }}>
        {title}
      </Typography>
    </>
  );
};

export default HeaderText;
