import { Button } from "@mui/material";
import React, { FC } from "react";
import ArrowBackIcon from "../../../components/icons/ArrowBackIcon";
import { useNavigate } from "react-router-dom";

const HeaderCreateTask = () => {
  const navigate = useNavigate();
  const handleClickButtonBack = () => {
    navigate("/task");
  };
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        startIcon={<ArrowBackIcon />}
        onClick={handleClickButtonBack}
      >
        Quay lại
      </Button>
    </>
  );
};

export default HeaderCreateTask;
