import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const UserIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 5.715a2.86 2.86 0 0 0 2.857-2.858A2.86 2.86 0 0 0 5.5 0a2.86 2.86 0 0 0-2.857 2.857A2.86 2.86 0 0 0 5.5 5.715Zm0-4.349c.822 0 1.491.669 1.491 1.491 0 .823-.669 1.492-1.491 1.492a1.493 1.493 0 0 1-1.491-1.492c0-.822.669-1.491 1.49-1.491Zm0 5.714a5.242 5.242 0 0 0-5.236 5.237c0 .377.305.683.683.683h9.107a.683.683 0 0 0 .682-.683A5.242 5.242 0 0 0 5.5 7.081Zm-3.81 4.554A3.877 3.877 0 0 1 5.5 8.447a3.877 3.877 0 0 1 3.81 3.187H1.69Z"
        fill="#D4D9E4"
      />
    </svg>
  );
};

export default UserIcon;
