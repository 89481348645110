import { Typography } from "@mui/material";
import React, { FC } from "react";

interface TextErrorProps {
  sx?: Object;
  children?: any;
}

const TextError: FC<TextErrorProps> = (props) => {
  return (
    <Typography
      sx={{
        color: "red",
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default TextError;
