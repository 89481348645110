import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { findHrByIdApi } from "../../../api/hr.api";
import { PersonnelModel } from "../../../model/personnel.model";
import { getErrorMsgAxios } from "../../../utils/error.util";
import HeaderUpdate from "./HeaderUpdate";
import StepperUpdate from "./StepperUpdate";

interface UpdatePersonnelProps {}

const UpdatePersonnel: FC<UpdatePersonnelProps> = (props) => {
  const params = useParams();
  const id = Number(params?.id);

  const [personnelDetail, setPersonnelDetail] = useState<PersonnelModel | null>(null);

  const getDetailHrById = async () => {
    await findHrByIdApi(id)
      .then((res) => {
        setPersonnelDetail(res?.data);
      })
      .catch((err) => {
        toast.error(getErrorMsgAxios(err));
      });
  };

  useEffect(() => {
    getDetailHrById();
  }, []);

  return (
    <>
      <HeaderUpdate />
      {personnelDetail && <StepperUpdate personnelDetail={personnelDetail} id={id} />}
    </>
  );
};

export default UpdatePersonnel;
