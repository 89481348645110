import { CodeDocBookModel } from "./../model/doc-book.model";
import HTTP_DOC_SERVICE from "../config/axios/axios-doc.config";
import { DOC_BOOK_ENUM } from "../enum/doc-book.enum";
import { DocBook, FilterDocBookReqModel } from "../model/doc-book.model";
import { Pageable } from "../model/page.model";

export const createApi = (code: DOC_BOOK_ENUM, docYear: number) => {
  return HTTP_DOC_SERVICE.post("/doc-book", { code, docYear, status: true });
};

export const changeStatusApi = (id: number) => {
  return HTTP_DOC_SERVICE.get(`/doc-book/${id}/change-status`);
};

export const filterApi = (body: FilterDocBookReqModel | null, pageable: Pageable) => {
  return HTTP_DOC_SERVICE.post<DocBook[]>("/doc-book/filter", body, {
    params: { ...pageable },
  });
};

export const getDocBookApi = (params: CodeDocBookModel) => {
  return HTTP_DOC_SERVICE.get<DocBook[]>(`/doc-book`, {
    params,
  });
};

export const getDistinctDocYearOrderDesc = () => {
  return HTTP_DOC_SERVICE.get<Number[]>(`/doc-book/doc-year`);
}
