import { FC, useEffect, useState } from "react";

// import module
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Select, { components, OptionProps } from "react-select";

// import project
import FieldContainer from "../../../../components/form/FieldContainer";
import { DEPARTMENT_MSG, FORM_MSG, USER_MSG } from "../../../../constants/msg/msg.constant";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { AccessRightModel } from "../../../../model/access-right.model";
import ModalContainer from "../../../../components/modal/ModalContainer";
import { DepartmentModel, Employee } from "../../../../model/user.model";
import { SIZE_DEFAULT } from "../../../../constants/page.constant";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { spacing_size } from "../../../../themes/size";
import { InternalDocOuts } from "../../../../enum/doc-out.enum";
import { statusDepartment } from "../../../../enum/hr.enum";
import BrowserIcon from "../../../../components/icons/BrowserIcon";
import { filterDepartmentApi, updateDepartmentApi } from "../../../../api/hr.api";
import { filterDepartmentApiAsync } from "../../user/redux/user.slice";
import React from "react";
import { EMAIL_REGEX } from "../../../../constants/regex.constant";

interface ModalUpdateDepartmentProps {
  open: boolean;
  onClose: () => void;
  department: DepartmentModel;
  buildDataFilter: any;
  initValue: any;
}

interface InitialValuesForm {
  code: string | null;
  name: string;
  parentId: string | any;
  status: any;
  id: number | null | undefined;
  email: string | undefined | null;
}

const ModalUpdateDepartment: FC<ModalUpdateDepartmentProps> = (props) => {
  const { open, onClose, department, buildDataFilter, initValue } = props;
  const dispatch = useAppDispatch();
  const [departmentActive, setDepartmentActive] = useState<DepartmentModel[]>([]);

  const validationSchema = Yup.object({
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Mã bộ phận công tác")),
    name: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Bộ phận công tác")),
    email: Yup.string()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg("Email"))
      .matches(EMAIL_REGEX, "Không đúng định dạng email!"),
  });

  const initialValues: InitialValuesForm = {
    code: department?.code,
    name: department?.name,
    parentId: department?.parentDepartment
      ? {
          id: department?.parentDepartment && department?.parentDepartment?.id,
          code: department?.parentDepartment && department?.parentDepartment?.code,
          name: department?.parentDepartment && department?.parentDepartment?.name,
        }
      : null,
    status: department?.status === "ACTIVE" ? 1 : 0,
    id: department?.id,
    email: department?.email,
  };

  const getDepartmentActive = async () => {
    const body = {
      name: null,
      status: 1,
    };
    const pageable = { page: 0, size: 1000000 };
    await filterDepartmentApi(body, pageable).then((res) => {
      setDepartmentActive(res.data);
    });
  };

  useEffect(() => {
    if (open) {
      getDepartmentActive();
    }
  }, [open]);

  const handleChangeDepartmentActive = (formik: FormikHelpers<InitialValuesForm>) => (e: any | null) => {
    formik.setFieldValue("parentId", e);
  };

  const handleSubmit = async (values: InitialValuesForm, formik: FormikHelpers<InitialValuesForm>) => {
    try {
      await updateDepartmentApi(
        values?.id || -1,
        values?.code || "",
        values?.name || "",
        values?.parentId?.id || null,
        values?.status,
        values?.email || ""
      );
      toast.success(DEPARTMENT_MSG.updateSuccess);
      await dispatch(filterDepartmentApiAsync(buildDataFilter(initValue)));
      onClose();
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Cập nhật bộ phận công tác"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off" style={{ padding: "0px 1px" }}>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="code"
                    type="text"
                    value={formik.values.code}
                    placeholder="Nhập thông tin"
                    label="Mã bộ phận công tác"
                    notched={true}
                    variant={"input"}
                    id={"code"}
                    isRequired
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.name}
                    placeholder="Nhập thông tin"
                    maxRows={12}
                    label="Bộ phận công tác"
                    notched={true}
                    variant={"input"}
                    id={"name"}
                    isRequired
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    name="parentId"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeDepartmentActive(formik)}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.parentId}
                    placeholder="Nhập/ chọn thông tin"
                    maxRows={12}
                    label="Bộ phận công tác cha"
                    notched={true}
                    variant={"react-select"}
                    id={"parentId"}
                    getOptionLabel={(option: any) => `${option.code} - ${option.name}`}
                    getOptionValue={(option: any) => option.code}
                    options={departmentActive}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldContainer
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    name="email"
                    type="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder="Nhập thông tin"
                    label="Email bộ phận công tác"
                    variant={"input"}
                    id={"email"}
                    maxRows={12}
                    isRequired={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldContainer
                    name="status"
                    onChange={formik.handleChange}
                    value={formik.values.status}
                    variant={"select"}
                    labelOption="description"
                    valueOption="code"
                    id={"status"}
                    options={statusDepartment}
                    label="Trạng thái"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    {/* <Button
                      variant="contained"
                      color="cancel"
                      type="button"
                      onClick={() => {
                        formik.resetForm();
                      }}
                    >
                      Huỷ
                    </Button> */}
                    <Button variant="contained" color="primary" type="submit" startIcon={<BrowserIcon />}>
                      Lưu
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalUpdateDepartment;
