import { useEffect, useState } from "react";

// import module
import { Button, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

// import project
import FieldContainer from "../../../components/form/FieldContainer";
import BoxContainer from "../../../components/common/BoxContainer";
import SearchIcon from "../../../components/icons/SearchIcon";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { filterUserApiAsync, setPage } from "./redux/user.slice";
import { SIZE_DEFAULT } from "../../../constants/page.constant";
import { FilterUserReqModel } from "../../../model/user.model";
import { Department, DepartmentHR } from "../../../model/department.model";
import { spacing_size } from "../../../themes/size";
import { getListDepartment } from "../../../api/department.api";
import { getListDepartmentFromHr } from "../../../api/hr/department.api";

const optionStatus = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "true",
    label: "Hoạt động",
  },
  {
    value: "false",
    label: "Không hoạt động",
  },
];

interface InitialValuesForm {
  keyword: string;
  departmentCode: Department[];
  status: string;
}

const FormSearchUser = () => {
  const reloadFilter = useAppSelector((state) => state.user.reloadFilter);
  const page = useAppSelector((state) => state.user.page);
  const dispatch = useAppDispatch();
  const initBodyFilter = { keyword: null, departmentCode: null, status: null };
  const [bodyFilter, setBodyFilter] = useState<FilterUserReqModel | null>(initBodyFilter);
  const [dataDepartments, setDataDepartments] = useState<DepartmentHR[]>([]);
  const [isInitFilter, setIsInitFilter] = useState(true);

  const loadDataFilter = (body = bodyFilter) => {
    const pageable = { page, size: SIZE_DEFAULT };
    dispatch(filterUserApiAsync({ body, pageable }));
  };

  useEffect(() => {
    if (reloadFilter !== 0) {
      loadDataFilter();
    }
  }, [reloadFilter]);

  useEffect(() => {
    if (isInitFilter) {
      setIsInitFilter(false);
      if (page > 0) {
        dispatch(setPage(0));
      } else {
        loadDataFilter();
      }
    } else {
      loadDataFilter();
    }
  }, [page]);

  useEffect(() => {
    getListDepartmentFromHr("").then((res) => {
      setDataDepartments(res.data);
    });
  }, []);

  const initialValues: InitialValuesForm = {
    keyword: "",
    departmentCode: [],
    status: "",
  };

  const validationSchema = Yup.object({});

  const buildBodyFilter = (value: InitialValuesForm): FilterUserReqModel => {
    const departmentCode =
      value.departmentCode?.length === 0 ? null : value.departmentCode.map((item) => item.code || "");
    const status = value.status === "" ? null : value.status === "false" ? false : true;
    return { keyword: value.keyword, status, departmentCode };
  };

  const onSubmitSearch = (value: InitialValuesForm, formikHelper: FormikHelpers<InitialValuesForm>) => {
    const body = buildBodyFilter(value);
    setBodyFilter(body);
    if (page === 0) {
      const pageable = { page, size: SIZE_DEFAULT };
      dispatch(filterUserApiAsync({ body, pageable }));
    } else {
      dispatch(setPage(0));
    }
  };

  return (
    <>
      <BoxContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitSearch}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={spacing_size}>
                <Grid item md={4} xs={12}>
                  <FieldContainer
                    fullWidth
                    name="keyword"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.keyword}
                    placeholder="Tên/mã nhân viên/tài khoản"
                    maxRows={12}
                    label="Tìm kiếm"
                    notched={true}
                    variant={"input"}
                    id={"keyword"}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldContainer
                    name="departmentCode"
                    onBlur={formik.handleBlur}
                    onChange={(e: any) => {
                      formik.setFieldValue("departmentCode", e);
                    }}
                    value={formik.values.departmentCode}
                    placeholder="Nhập/chọn bộ phận công tác"
                    label="Bộ phận công tác"
                    isMulti
                    getOptionLabel={(option: DepartmentHR) => `${option.code} - ${option.name}`}
                    getOptionValue={(option: DepartmentHR) => option.code}
                    options={dataDepartments}
                    variant={"react-select"}
                    id={"departmentCode"}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldContainer
                    name="status"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.status}
                    label="Trạng thái"
                    variant={"select"}
                    id={"status"}
                    options={optionStatus}
                  />
                </Grid>
                <Grid item xs={12} md={12} justifyContent="center">
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="cancel"
                      type="button"
                      onMouseDown={() => {
                        formik.resetForm();
                        setBodyFilter(initBodyFilter);
                        if (page === 0) {
                          loadDataFilter(initBodyFilter);
                        } else {
                          dispatch(setPage(0));
                        }
                      }}
                    >
                      Huỷ
                    </Button>
                    <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>
                      Tìm kiếm
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </BoxContainer>
    </>
  );
};

export default FormSearchUser;
