import { ALLOW_EXTENSION_FILE_TASK } from "../constants/doc-in.constant";
import { MAX_SIZE_FILE_MB } from "../constants/file.constant";
import { TASK_PRIORITY_ENUM } from "../enum/task.enum";
import {
  convertFilesToArr,
  isAllowExtensionFile,
  isDuplicateFile,
  isDuplicateFileUploaded,
  isMaxSizeFile,
  isMinSizeFile,
} from "./file.util";

export const changeFileTask = (e: any, files: File[], fileUploaded: any, size: number): File[] => {
  const dataFiles: File[] = [];
  if (e.target.files) {
    const currentFiles = convertFilesToArr(e.target.files);
    if (files.length > 0) {
      dataFiles.push(...files);
    }
    for (let i = 0; i < currentFiles.length; i++) {
      if (dataFiles.length + (fileUploaded.length || 0) === size) {
        break;
      }
      const file = currentFiles[i];
      if (
        isAllowExtensionFile(file, ALLOW_EXTENSION_FILE_TASK) &&
        !isMinSizeFile(file, 0) &&
        !isMaxSizeFile(file, MAX_SIZE_FILE_MB) &&
        !isDuplicateFile(dataFiles, file) &&
        !isDuplicateFileUploaded(fileUploaded, file)
      ) {
        dataFiles.push(file);
      }
    }
    e.target.value = null;
    return dataFiles;
  } else {
    return dataFiles;
  }
};

export const convertTaskPriority = (value: string) => {
  switch (value) {
    case TASK_PRIORITY_ENUM.NORMAL:
      return "Bình thường";
    case TASK_PRIORITY_ENUM.URGENT:
      return "Gấp";
    default:
      return "";
  }
};

export const convertTaskVerifyAgain = (value: boolean) => {
  switch (value) {
    case true:
      return "Có";
    case false:
      return "Không";
    default:
      return "";
  }
};
