import React, { FC } from "react";
import * as Yup from "yup";
import { FORM_MSG } from "../../../constants/msg/msg.constant";
import { Form, Formik, FormikHelpers } from "formik";
import ModalContainer from "../../../components/modal/ModalContainer";
import { spacing_size } from "../../../themes/size";
import { Button, Grid, Stack } from "@mui/material";
import FieldContainer from "../../../components/form/FieldContainer";
import { RelativesPersonnels } from "../../../enum/personnel.enum";
import { PersonnelModel, RelativesModel, SelectFieldCommonPersonnelModel } from "../../../model/personnel.model";
import { VIEW_YEAR_FORMAT } from "../../../constants/date-time.constant";
import { formatDateTime } from "../../../utils/data-time.util";
import { PHONE_PATTERN } from "../../../constants/regex.constant";

interface ModalUpdateRelativesProps {
  open: boolean;
  onClose: () => void;
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  valueUploads: PersonnelModel | null;
  itemRelatives: RelativesModel | null;
  indexRelatives: number;
  setRelativesDetail: React.Dispatch<React.SetStateAction<RelativesModel[]>>;
}

const ModalUpdateRelatives: FC<ModalUpdateRelativesProps> = (props) => {
  const { open, onClose, valueUploads, setValuesUploads, itemRelatives, indexRelatives, setRelativesDetail } = props;

  const getRelationshipRelatives: SelectFieldCommonPersonnelModel[] = RelativesPersonnels?.filter(
    (item: SelectFieldCommonPersonnelModel) => item?.code === itemRelatives?.relationship
  );

  const relationshipObj = getRelationshipRelatives?.length > 0 ? Object.assign({}, ...getRelationshipRelatives) : "";

  const initialValues: RelativesModel = {
    fullName: itemRelatives?.fullName ? itemRelatives?.fullName : "",
    relationship: itemRelatives?.relationship ? relationshipObj : "",
    birthYear: itemRelatives?.birthYear ? new Date(itemRelatives?.birthYear) : null,
    occupation: itemRelatives?.occupation ? itemRelatives?.occupation : "",
    workPlace: itemRelatives?.workPlace ? itemRelatives?.workPlace : "",
    phoneNumber: itemRelatives?.phoneNumber ? itemRelatives?.phoneNumber : "",
  };

  const currentDate = new Date();

  const validationSchema = Yup.object({
    fullName: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg("Họ và tên người thân")),
    relationship: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg("Mối quan hệ")),
    birthYear: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Năm sinh")),
  });

  const handleChangeRelationship =
    (formik: FormikHelpers<RelativesModel>) => (e: SelectFieldCommonPersonnelModel | null) => {
      formik.setFieldValue("relationship", e);
    };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    if (!PHONE_PATTERN.test(keyValue)) {
      event.preventDefault();
    }
  };

  const buildBody = (values: RelativesModel) => {
    const newValues = {
      ...values,
      relationship: values?.relationship ? values?.relationship?.code : "",
      birthYear: formatDateTime(values?.birthYear, VIEW_YEAR_FORMAT),
    };
    return newValues;
  };

  const handleSubmit = async (values: RelativesModel, formik: FormikHelpers<RelativesModel>) => {
    const newValues = buildBody(values);
    const arrRelatives: any = valueUploads?.employeeRelatives;
    arrRelatives[indexRelatives] = newValues;
    setValuesUploads({ ...valueUploads, employeeRelatives: [...arrRelatives] });
    setRelativesDetail(arrRelatives);
    onClose();
  };

  return (
    <>
      <ModalContainer
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={"Cập nhật"}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off">
              {/* <>{JSON.stringify(formik?.values)}</> */}
              <Grid container spacing={spacing_size}>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="fullName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.fullName}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Họ và tên người thân"
                    notched={true}
                    variant={"input"}
                    id={"fullName"}
                    isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="relationship"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeRelationship(formik)}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.relationship}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Mối quan hệ"
                    notched={true}
                    variant={"react-select"}
                    id={"relationship"}
                    isRequired={true}
                    options={RelativesPersonnels}
                    getOptionLabel={(option: SelectFieldCommonPersonnelModel) => option.description}
                    getOptionValue={(option: SelectFieldCommonPersonnelModel) => option.code}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    name="birthYear"
                    onBlur={formik.handleBlur}
                    value={formik.values.birthYear}
                    placeholderText={"YYYY"}
                    label="Năm sinh"
                    notched={true}
                    variant={"date"}
                    id={"birthYear"}
                    isRequired={true}
                    maxDate={currentDate}
                    dateFormat="yyyy"
                    showYearPicker
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="phoneNumber"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.phoneNumber}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Số điện thoại"
                    notched={true}
                    variant={"input"}
                    id={"phoneNumber"}
                    inputProps={{ maxLength: 20 }}
                    onKeyPress={handleKeyPress}
                    // isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="occupation"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.occupation}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Nghề nghiệp"
                    notched={true}
                    variant={"input"}
                    id={"occupation"}
                    // isRequired={true}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FieldContainer
                    fullWidth
                    name="workPlace"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    inputProps={{ maxLength: 255 }}
                    value={formik.values.workPlace}
                    placeholder={FORM_MSG.placeholder}
                    maxRows={12}
                    label="Nơi công tác"
                    notched={true}
                    variant={"input"}
                    id={"workPlace"}
                    // isRequired={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                      marginTop: "20px",
                    }}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Lưu
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

export default ModalUpdateRelatives;
