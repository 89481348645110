import { SvgIconProps } from "@mui/material";
import { FC } from "react";

const BrowserIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" stroke="#D4D9E4" />
      <path
        d="M10.749 4.39768L10.8235 4.32318C10.8796 4.26714 10.9462 4.22269 11.0194 4.19237C11.0927 4.16205 11.1712 4.14646 11.2505 4.14648C11.3297 4.14651 11.4082 4.16215 11.4815 4.19251C11.5547 4.22287 11.6212 4.26735 11.6773 4.32343C11.7333 4.3795 11.7778 4.44606 11.8081 4.51932C11.8384 4.59257 11.854 4.67108 11.854 4.75035C11.8539 4.82963 11.8383 4.90813 11.808 4.98136C11.7776 5.0546 11.7331 5.12114 11.677 5.17718L11.6025 5.25118C11.73 5.39411 11.7979 5.58037 11.7924 5.77179C11.7869 5.96321 11.7084 6.14528 11.573 6.28068L6.42703 11.4272C6.39486 11.4592 6.35459 11.4818 6.31053 11.4927L4.31053 11.9927C4.26868 12.0031 4.22484 12.0025 4.18328 11.991C4.14172 11.9795 4.10384 11.9574 4.07335 11.9269C4.04285 11.8964 4.02076 11.8585 4.00922 11.8169C3.99769 11.7754 3.99711 11.7315 4.00753 11.6897L4.50753 9.68968C4.51847 9.6458 4.54111 9.60571 4.57303 9.57368L9.39403 4.75268C9.34581 4.71874 9.28715 4.70294 9.22842 4.70806C9.16968 4.71319 9.11464 4.73891 9.07303 4.78068L7.42703 6.42718C7.40379 6.45042 7.37619 6.46886 7.34582 6.48144C7.31545 6.49402 7.2829 6.50049 7.25003 6.50049C7.21716 6.50049 7.18461 6.49402 7.15424 6.48144C7.12387 6.46886 7.09627 6.45042 7.07303 6.42718C7.04979 6.40393 7.03135 6.37634 7.01877 6.34597C7.00619 6.3156 6.99971 6.28305 6.99971 6.25018C6.99971 6.21731 7.00619 6.18476 7.01877 6.15439C7.03135 6.12402 7.04979 6.09642 7.07303 6.07318L8.72003 4.42718C8.85552 4.29168 9.03773 4.21319 9.22926 4.20779C9.4208 4.2024 9.60713 4.27052 9.75003 4.39818C9.88741 4.2754 10.0652 4.20748 10.2494 4.20739C10.4337 4.2073 10.6115 4.27503 10.749 4.39768V4.39768ZM10.427 4.78068C10.3801 4.73381 10.3166 4.70748 10.2503 4.70748C10.184 4.70748 10.1204 4.73381 10.0735 4.78068L4.97503 9.87818L4.59303 11.4067L6.12153 11.0247L11.22 5.92718C11.2433 5.90395 11.2618 5.87637 11.2744 5.84599C11.287 5.81562 11.2935 5.78306 11.2935 5.75018C11.2935 5.71729 11.287 5.68473 11.2744 5.65436C11.2618 5.62399 11.2433 5.5964 11.22 5.57318L10.4275 4.78068H10.427Z"
        fill="#D4D9E4"
      />
    </svg>
  );
};

export default BrowserIcon;
