import { Button, Grid, Stack, Typography } from "@mui/material";
import FileSaver from "file-saver";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Params, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getDocBookApi } from "../../../api/doc-book.api";
import { filterDocInApi } from "../../../api/doc-in.api";
import { downloadFileDocOutApi, getDetailApi, updateDocOutApi } from "../../../api/doc-out.api";
import { getListDepartmentFromHr } from "../../../api/hr/department.api";
import { getTemplateFileApi } from "../../../api/template-file.api";
import { getUserApi } from "../../../api/user.api";

// import project
import BoxContainer from "../../../components/common/BoxContainer";
import HeaderPage from "../../../components/common/HeaderPage";
import OptionSelectUser from "../../../components/common/OptionSelectUser";
import FieldContainer from "../../../components/form/FieldContainer";
import ArrowBackIcon from "../../../components/icons/ArrowBackIcon";
import BrowserIcon from "../../../components/icons/BrowserIcon";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { MAX_LENGTH_FILE_DOC_OUT } from "../../../constants/doc-out.constant";
import { DOC_OUT_MSG, FORM_MSG } from "../../../constants/msg/msg.constant";
import { MAX_SIZE_DEFAULT } from "../../../constants/page.constant";
import { DOC_BOOK_ENUM } from "../../../enum/doc-book.enum";
import { STATUS_DOC_IN_ENUM } from "../../../enum/doc-in.enum";
import {
  CategoryDocOuts,
  CATEGORY_DOC_OUT_ENUM,
  InternalDocOuts,
  INTERNAL_DOC_OUT_ENUM,
  SubmissionDocOuts,
  SUBMISSION_DOC_OUT_ENUM,
  DecisionDocOuts,
  DECISION_DOC_OUT_ENUM,
} from "../../../enum/doc-out.enum";
import { Department, DepartmentHR } from "../../../model/department.model";
import { DocBook } from "../../../model/doc-book.model";
import { RequestFilterDocInModel, ResponseFilterDocInModel } from "../../../model/doc-in.model";
import { DocOutModel } from "../../../model/doc-out.model";
import { FileModel } from "../../../model/File.model";
import { TemplateFile } from "../../../model/template.model";
import { UserModel } from "../../../model/user.model";
import { useAppSelector } from "../../../store/hook";
import { spacing_size } from "../../../themes/size";
import { convertDataToFormData } from "../../../utils/axios.ultil";
import { getErrorMsgAxios } from "../../../utils/error.util";
import ViewFile from "../document-in/common/ViewFile";
import { changeFileDocOut, convertToDate } from "./common/util/doc-out.util";
import { formatDateTime } from "../../../utils/data-time.util";
import { UPLOAD_DATE_FORMAT } from "../../../constants/date-time.constant";

interface InitialValuesForm {
  fromPlace: string;
  docCategory: CATEGORY_DOC_OUT_ENUM;
  internal: INTERNAL_DOC_OUT_ENUM;
  submission: SUBMISSION_DOC_OUT_ENUM | null;
  decision: DECISION_DOC_OUT_ENUM | null;
  docInId: ResponseFilterDocInModel | null;
  summary: string;
  templateFile: TemplateFile | null;
  issueDate: any | null;
  issueReceivers: UserModel[] | null;
  docBook: DocBook | null;
  arrivalNbr: any;
  issueContent: string;
  issueDepartment: Department | null;
}

export const UpdateDocOut = () => {
  const defaultInitialValues: InitialValuesForm = {
    fromPlace: "",
    docCategory: CATEGORY_DOC_OUT_ENUM.EXTERNAL,
    internal: INTERNAL_DOC_OUT_ENUM.DOC_OUT,
    submission: SUBMISSION_DOC_OUT_ENUM.GENERAL_SECRETARY,
    decision: DECISION_DOC_OUT_ENUM.GENERAL_SECRETARY,
    docInId: null,
    summary: "",
    templateFile: null,
    issueDate: null,
    issueReceivers: null,
    docBook: null,
    arrivalNbr: null,
    issueContent: "",
    issueDepartment: null,
  };
  const { state } = useLocation();
  const { id, bodyFilter, docBook } = state;
  const navigate = useNavigate();
  const params: Readonly<Params<string>> = useParams();
  const btnSaveRef = useRef<HTMLButtonElement>(null);
  const btnCancelRef = useRef<HTMLButtonElement>(null);
  // TODO:  check created by current user
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [files, setFiles] = useState<File[]>([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [docOutDocBooks, setDocOutDocBooks] = useState<DocBook[]>([]);
  const [departments, setDepartments] = useState<DepartmentHR[]>([]);
  const [templateFiles, setTemplateFiles] = useState<TemplateFile[]>([]);
  const [bodyUpdate, setBodyUpdate] = useState<FormData>();
  const [pendingDocIns, setPendingDocIns] = useState<ResponseFilterDocInModel[]>([]);
  const [docOut, setDocOut] = useState<DocOutModel>();
  const [initialValues, setInitialValues] = useState<InitialValuesForm>(defaultInitialValues);
  const [fileUploaded, setFileUploaded] = useState<FileModel[]>([]);

  const labelForm = {
    fromPlace: "Nơi nhận thông tin",
    docIn: "Số công văn đến",
    summary: "Trích yếu nội dung văn bản",
    templateFile: "Chọn mẫu công văn",
    attaches: "Thông tin đính kèm",
    issueDate: "Ngày ban hành",
    issueBy: "Người nhận bản lưu",
    docBook: "Sổ công văn",
    arrivalNbr: "Số đi",
    issueContent: "Nội dung ban hành",
    issueDepartment: "Phòng ban thực hiện ban hành",
    handlingDepartment: "Phòng chủ trì giải quyết",
  };
  const validationSchema = Yup.object({
    docCategory: Yup.string(),
    fromPlace: Yup.string()
      .nullable()
      .when("docCategory", (docCategory: string, schema: any) => {
        if (docCategory === CATEGORY_DOC_OUT_ENUM.EXTERNAL) {
          return schema.required(FORM_MSG.requiredFieldMsg(labelForm.fromPlace));
        }
        return schema;
      }),
    summary: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg(labelForm.summary)),
    arrivalNbr: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg(labelForm.arrivalNbr)),
    docBook: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg(labelForm.docBook)),
  });

  const buildInitialValues = (docOut: DocOutModel): InitialValuesForm => {
    return {
      fromPlace: docOut.fromPlace,
      docCategory: docOut.docCategory as CATEGORY_DOC_OUT_ENUM,
      internal: docOut.internal as INTERNAL_DOC_OUT_ENUM, // INTERNAL_DOC_OUT_ENUM.DOC_OUT,
      submission: docOut.submission || null, // SUBMISSION_DOC_OUT_ENUM.LEVEL_OF_GENERAL_SECRETARIAT,
      decision: docOut.decision || null, // DECISION_DOC_OUT_ENUM.LEVEL_OF_GENERAL_SECRETARIAT,
      docInId: docOut.docIn as any,
      summary: docOut.summary,
      templateFile: docOut.templateFile,
      issueDate: docOut.issueDate ? convertToDate(docOut.issueDate) : null,
      issueReceivers: docOut?.issueReceivers || null,
      docBook: docOut.docBook || null,
      arrivalNbr: docOut.arrivalNbr,
      issueContent: docOut.issueContent,
      issueDepartment: docOut.issueDepartment,
    };
  };

  const getDocOut = async () => {
    if (isNaN(Number(params?.id))) {
      navigate("/404");
    } else {
      await getDetailApi(Number(params?.id))
        .then((res) => {
          setDocOut(res.data);
          setInitialValues(buildInitialValues(res.data));
          setFileUploaded(res.data.docOutFiles || []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getDocOutDocBooks = () => {
    getDocBookApi({
      code: DOC_BOOK_ENUM.DOC_OUT,
    }).then((res) => {
      setDocOutDocBooks(res.data);
    });
  };

  const getAllTemplateFiles = () => {
    getTemplateFileApi({
      page: 0,
      size: MAX_SIZE_DEFAULT,
    }).then((res) => {
      setTemplateFiles(res.data);
    });
  };

  const getAllDepartment = () => {
    getListDepartmentFromHr("").then((res) => {
      setDepartments(res.data);
    });
  };

  const downloadFile = (id: number, name: string) => {
    downloadFileDocOutApi(id).then((res) => {
      FileSaver.saveAs(res?.data, name);
    });
  };

  useEffect(() => {
    getDocOut();
    getDocOutDocBooks();
    getAllDepartment();
    getAllTemplateFiles();
    getPendingDocIns();
  }, []);

  const buildValueUpdate = (values: InitialValuesForm) => {
    const {
      docInId,
      issueReceivers,
      templateFile,
      issueDepartment,
      docBook,
      internal,
      submission,
      docCategory,
      decision,
      ...rest
    } = values;
    return {
      ...rest,
      id: params.id,
      docInId: docInId ? docInId.id : null,
      issueReceivers: issueReceivers ? issueReceivers.map((item) => item.email) : null,
      templateFileId: templateFile ? templateFile.id : null,
      issueDepartment: issueDepartment ? issueDepartment.code : null,
      docBookId: docBook ? docBook.id : null,
      docCategory,
      internal: docCategory === CATEGORY_DOC_OUT_ENUM.INTERNAL ? internal : null,
      submission: internal === INTERNAL_DOC_OUT_ENUM.SUBMISSION ? submission : null,
      decision: internal === INTERNAL_DOC_OUT_ENUM.DECISION ? decision : null,
      docFileIds: fileUploaded.map((item) => item.id),
      issueDate: values?.issueDate ? formatDateTime(values?.issueDate, UPLOAD_DATE_FORMAT) : null,
    };
  };

  const handleOnSubmit = (value: InitialValuesForm, formikHelper: FormikHelpers<InitialValuesForm>) => {
    setBodyUpdate(convertDataToFormData(files, buildValueUpdate(value), "dataFile", "data"));
    setConfirmModal(true);
  };

  const handleClickButtonBack = () => {
    navigate(`/doc-out/${id}`, {
      state: {
        id: id,
        bodyFilter: bodyFilter,
        docBook: docBook,
      },
    });
  };

  const getPendingDocIns = () => {
    const initBodyFilter: RequestFilterDocInModel = {
      code: null,
      docBookId: null,
      docCategory: null,
      summary: null,
      status: STATUS_DOC_IN_ENUM.REPLY,
      fromDate: null,
      toDate: null,
    };
    const params = { page: 0, size: MAX_SIZE_DEFAULT };
    filterDocInApi(initBodyFilter, params).then((res) => {
      setPendingDocIns(res.data);
    });
  };

  const loadDocInAsyncReactSelect = (inputValue: string, callback: (options: ResponseFilterDocInModel[]) => void) => {
    if (inputValue && inputValue.length >= 0) {
      const initBodyFilter: RequestFilterDocInModel = {
        code: inputValue,
        docBookId: null,
        docCategory: null,
        summary: inputValue,
        status: STATUS_DOC_IN_ENUM.REPLY,
        fromDate: null,
        toDate: null,
      };
      const params = { page: 0, size: MAX_SIZE_DEFAULT };
      filterDocInApi(initBodyFilter, params).then((res) => {
        callback(res.data);
      });
    } else {
      callback([]);
    }
  };

  const loadEmployee = (inputValue: string, callback: (options: UserModel[]) => void) => {
    const MIN_LENGTH_INPUT = -1;
    if (inputValue && inputValue.length >= MIN_LENGTH_INPUT) {
      getUserApi(inputValue, "").then((res) => {
        callback(res.data);
      });
    } else {
      callback([]);
    }
  };

  const handleRemoveFileUploaded = (item: FileModel) => {
    const fileUploadedRemoves = fileUploaded.filter((file) => file.id !== item.id);
    setFileUploaded(fileUploadedRemoves);
  };

  return (
    <>
      {/* modal */}
      <ConfirmModal
        title="Xác nhận"
        onCancel={() => {
          setConfirmModal(false);
        }}
        onCloseModal={() => {
          setConfirmModal(false);
        }}
        onConfirm={() => {
          setConfirmModal(false);
          bodyUpdate &&
            updateDocOutApi(bodyUpdate)
              .then((res) => {
                toast.success(DOC_OUT_MSG.updateSuccess);
                handleClickButtonBack();
              })
              .catch((err) => {
                toast.error(getErrorMsgAxios(err));
              });
        }}
        openModal={confirmModal}
      >
        <Typography>{DOC_OUT_MSG.confirmSave}</Typography>
      </ConfirmModal>

      {/* header */}
      <Button
        variant="outlined"
        color="primary"
        type="button"
        startIcon={<ArrowBackIcon />}
        onClick={handleClickButtonBack}
        sx={{
          mb: 2,
        }}
      >
        Quay lại
      </Button>

      <HeaderPage title="Văn bản đi" variant="h5" component="h5">
        <Stack direction={{ xs: "column", sm: "row" }} spacing={spacing_size}>
          <Button variant="outlined" onClick={() => btnCancelRef?.current?.click()}>
            Nhập lại
          </Button>
          <Button
            type="button"
            variant="contained"
            startIcon={<BrowserIcon />}
            onClick={() => btnSaveRef?.current?.click()}
          >
            Lưu
          </Button>
        </Stack>
      </HeaderPage>

      {/* form */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <Form autoComplete="off" noValidate>
              {/* Thông tin chung */}
              <BoxContainer>
                <Typography variant="h2" sx={{ fontSize: 24, mb: spacing_size }}>
                  Thông tin chung
                </Typography>

                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={4}>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={spacing_size} flexWrap="nowrap">
                      <FieldContainer
                        name="docCategory"
                        onBlur={formik.handleBlur}
                        onChange={async (e: any) => {
                          console.log(e.target.value);
                          await formik.setFieldValue("docCategory", e.target.value);
                          await formik.setFieldValue("internal", INTERNAL_DOC_OUT_ENUM.DOC_OUT);
                          await formik.setFieldValue("submission", SUBMISSION_DOC_OUT_ENUM.GENERAL_SECRETARY);
                          await formik.setFieldValue("decision", DECISION_DOC_OUT_ENUM.GENERAL_SECRETARY);
                        }}
                        value={formik.values.docCategory}
                        label="Hình thức gửi"
                        variant={"radio"}
                        labelOption="description"
                        valueOption="code"
                        id={"docCategory"}
                        options={CategoryDocOuts}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    {formik.values.docCategory === CATEGORY_DOC_OUT_ENUM.INTERNAL && (
                      <FieldContainer
                        name="internal"
                        onChange={(e: any) => {
                          console.log(e.target.value);
                          const value = e.target.value;
                          formik.setFieldValue("internal", value);
                          switch (value) {
                            case INTERNAL_DOC_OUT_ENUM.DECISION:
                              formik.setFieldValue("decision", DECISION_DOC_OUT_ENUM.GENERAL_SECRETARY);
                              break;
                            case INTERNAL_DOC_OUT_ENUM.SUBMISSION:
                              formik.setFieldValue("submission", SUBMISSION_DOC_OUT_ENUM.GENERAL_SECRETARY);
                              break;
                            case INTERNAL_DOC_OUT_ENUM.DOC_OUT:
                              break;
                            default:
                              break;
                          }
                        }}
                        value={formik.values.internal}
                        label=""
                        variant={"select"}
                        labelOption="description"
                        valueOption="code"
                        id={"internal"}
                        options={InternalDocOuts}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {formik.values.docCategory === CATEGORY_DOC_OUT_ENUM.INTERNAL &&
                      formik.values.internal === INTERNAL_DOC_OUT_ENUM.SUBMISSION && (
                        <FieldContainer
                          name="submission"
                          onChange={formik.handleChange}
                          value={formik.values.submission}
                          label=""
                          variant={"select"}
                          labelOption="description"
                          valueOption="code"
                          id={"submission"}
                          options={SubmissionDocOuts}
                        />
                      )}

                    {formik.values.docCategory === CATEGORY_DOC_OUT_ENUM.INTERNAL &&
                      formik.values.internal === INTERNAL_DOC_OUT_ENUM.DECISION && (
                        <FieldContainer
                          name="decision"
                          onChange={formik.handleChange}
                          value={formik.values.decision}
                          label=""
                          variant={"select"}
                          labelOption="description"
                          valueOption="code"
                          id={"decision"}
                          options={DecisionDocOuts}
                        />
                      )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FieldContainer
                      fullWidth
                      name="fromPlace"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.fromPlace}
                      placeholder={FORM_MSG.placeholder}
                      label="Nơi nhận thông tin"
                      isRequired={formik.values.docCategory === CATEGORY_DOC_OUT_ENUM.EXTERNAL}
                      variant={"input"}
                      id={"fromPlace"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldContainer
                      fullWidth
                      name="docInId"
                      onBlur={() => formik.handleBlur("docInId")}
                      onChange={async (e: any) => {
                        await formik.setFieldValue("docInId", e);
                      }}
                      value={formik.values.docInId}
                      label="Số công văn đến"
                      variant={"react-select"}
                      id={"docInId"}
                      getOptionLabel={(option: ResponseFilterDocInModel) => option.code}
                      getOptionValue={(option: ResponseFilterDocInModel) => option.id}
                      // loadOptions={loadDocInAsyncReactSelect}
                      options={pendingDocIns}
                      placeholder={FORM_MSG.reactSelectPlaceholder}
                      isDisabled={docOut?.docIn}
                      isClearable
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FieldContainer
                      fullWidth
                      id="summary"
                      name="summary"
                      variant="textarea"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder={FORM_MSG.placeholder}
                      label="Trích yếu nội dung văn bản"
                      value={formik.values.summary}
                      maxLength={5000}
                      isRequired
                    />
                  </Grid>

                  {/* <Grid item xs={9} md={6}>
                    <FieldContainer
                      fullWidth
                      id="templateFile"
                      name="templateFile"
                      variant="react-select"
                      label="Chọn mẫu công văn"
                      onBlur={formik.handleBlur}
                      placeholder={FORM_MSG.reactSelectPlaceholder}
                      getOptionValue={(item: TemplateFile) => item.id}
                      getOptionLabel={(item: TemplateFile) => item.name}
                      onChange={(e: TemplateFile) => {
                        formik.setFieldValue("templateFile", e);
                      }}
                      value={formik.values.templateFile}
                      options={templateFiles}
                      isClearable
                    />
                  </Grid> */}

                  {/* <Grid item xs={3} md={6}>
                    <Typography sx={{ visibility: "hidden" }}>Tải xuống</Typography>
                    {formik.values.templateFile && (
                      <Button
                        variant="contained"
                        onClick={async () => {
                          try {
                            const res = await downloadTemplateFileApi(Number(formik.values.templateFile?.id));
                            FileSaver.saveAs(res?.data, formik.values.templateFile?.name);
                          } catch (error) {
                            toast.error(getErrorMsgAxios(error));
                          }
                        }}
                      >
                        Tải xuống
                      </Button>
                    )}
                  </Grid> */}

                  <Grid item xs={12}>
                    <FieldContainer
                      id="upload-file-doc-out"
                      name="upload-file-doc-out"
                      label="Thông tin đính kèm"
                      variant="upload-input"
                      textWarning="Vui lòng tải file: word, excel, pdf"
                      onChange={(e: React.InputHTMLAttributes<HTMLInputElement>) => {
                        setFiles(changeFileDocOut(e, files, "", MAX_LENGTH_FILE_DOC_OUT - fileUploaded.length));
                      }}
                    />
                  </Grid>
                  {/* show file */}
                  <Grid item container xs={12}>
                    {files.map((item) => (
                      <ViewFile
                        item={item}
                        handleRemove={() => {
                          setFiles(files.filter((file) => item !== file));
                        }}
                      />
                    ))}
                    {fileUploaded &&
                      fileUploaded.map((item: any) => {
                        return (
                          <ViewFile
                            item={item}
                            onClickItem={() => {
                              downloadFile(item.id, item.name);
                            }}
                            handleRemove={handleRemoveFileUploaded}
                          />
                        );
                      })}
                  </Grid>
                </Grid>
              </BoxContainer>

              {/* Thông tin ban hành */}
              <BoxContainer>
                <Typography variant="h2" sx={{ fontSize: 24, mb: spacing_size }}>
                  Thông tin ban hành
                </Typography>

                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6}>
                    <FieldContainer
                      id="issueDate"
                      name="issueDate"
                      label="Ngày ban hành"
                      variant="date"
                      placeholderText="dd/mm/yyyy"
                      value={formik.values.issueDate}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldContainer
                      fullWidth
                      name="issueReceivers"
                      onBlur={formik.handleBlur}
                      type="text"
                      value={formik.values.issueReceivers}
                      placeholder={FORM_MSG.reactSelectPlaceholder}
                      onChange={(e: UserModel) => {
                        formik.setFieldValue("issueReceivers", e);
                      }}
                      label={"Người nhận bản lưu"}
                      getOptionLabel={(item: UserModel) => item.fullName}
                      getOptionValue={(item: UserModel) => item.email}
                      variant={"async-react-select"}
                      id={"issueReceivers"}
                      loadOptions={loadEmployee}
                      isMulti
                      menuPlacement="top"
                      components={{ Option: OptionSelectUser }}
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldContainer
                      id="docBook"
                      name="docBook"
                      variant="react-select"
                      label="Sổ công văn"
                      placeholder={FORM_MSG.reactSelectPlaceholder}
                      value={formik.values.docBook}
                      getOptionLabel={(item: DocBook) => item.name}
                      getOptionValue={(item: DocBook) => item.name}
                      isRequired
                      onBlur={formik.handleBlur}
                      onChange={(e: DocBook) => {
                        formik.setFieldValue("docBook", e);
                      }}
                      options={docOutDocBooks}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldContainer
                      variant="input"
                      name="arrivalNbr"
                      type="text"
                      placeholder={FORM_MSG.placeholder}
                      label="Số đi"
                      inputProps={{ maxLength: 50 }}
                      id="arrivalNbr"
                      value={formik.values.arrivalNbr}
                      isRequired
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      fullWidth
                      // disabled={docOut?.arrivalNbr}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldContainer
                      fullWidth
                      id="issueContent"
                      name="issueContent"
                      variant="textarea"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder={FORM_MSG.placeholder}
                      label="Nội dung ban hành"
                      value={formik.values.issueContent}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FieldContainer
                      fullWidth
                      name="issueDepartment"
                      onBlur={formik.handleBlur}
                      value={formik.values.issueDepartment}
                      placeholder={FORM_MSG.reactSelectPlaceholder}
                      onChange={(e: Department) => {
                        formik.setFieldValue("issueDepartment", e);
                      }}
                      label="Phòng ban thực hiện ban hành"
                      getOptionLabel={(item: DepartmentHR) =>
                        `${item.code} - ${item?.name ? item?.name : item?.displayName}`
                      }
                      getOptionValue={(item: DepartmentHR) => item.code}
                      variant={"react-select"}
                      id="issueDepartment"
                      // loadOptions={(inputValue: string, callback: (options: any[]) => void) => {
                      //   console.log("oki");
                      //   callback([{ label: "abc", value: "abc" }]);
                      // }}
                      options={departments}
                      menuPlacement="top"
                      // components={{ Option: OptionSelectUser }}
                      isClearable
                    />
                  </Grid>
                </Grid>
              </BoxContainer>

              {/* action butotn */}
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={spacing_size}
                sx={{ marginBottom: "10px" }}
                justifyContent="end"
              >
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    formik.resetForm();
                    setFileUploaded(docOut?.docOutFiles || []);
                    setFiles([]);
                  }}
                  ref={btnCancelRef}
                >
                  Nhập lại
                </Button>
                <Button type="submit" variant="contained" startIcon={<BrowserIcon />} ref={btnSaveRef}>
                  Lưu
                </Button>
              </Stack>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
