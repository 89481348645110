import { AllItem, EnumCommon } from "./common.enum";

export enum DOC_BOOK_ENUM {
  DOC_IN_DOMESTIC = "DOC_IN_DOMESTIC",
  DOC_IN_INTERNATIONAL = "DOC_IN_INTERNATIONAL",
  DOC_OUT = "DOC_OUT",
}

export const DocBooks: EnumCommon<DOC_BOOK_ENUM>[] = [
  { code: DOC_BOOK_ENUM.DOC_IN_DOMESTIC, description: "Công văn đến trong nước" },
  { code: DOC_BOOK_ENUM.DOC_IN_INTERNATIONAL, description: "Công văn đến quốc tế" },
  { code: DOC_BOOK_ENUM.DOC_OUT, description: "Công văn đi" },
];

export const DocBooksWithAll: EnumCommon<DOC_BOOK_ENUM>[] = [
  // for mat
  AllItem,
  ...DocBooks,
];
