import { Box, Grid } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import React, { FC, useEffect } from "react";
import * as Yup from "yup";
import FieldContainer from "../../../components/form/FieldContainer";
import { spacing_size } from "../../../themes/size";
import { SelectPersonnelOptions } from "../../../enum/personnel.enum";
import { GeneralInformationModel, PersonnelModel } from "../../../model/personnel.model";
import { PAGEABLE_MAX_SIZE } from "../../../constants/page.constant";
import FormGeneralInformationOther from "./form-general-information-updates/FormGeneralInformationOther";
import {
  isExpertsAndCoachingStaff,
  isLeadersAndDepartments,
  isOperatingParts,
  isOther,
  isTheSubordinateUnits,
} from "../common/util/personnel.util";
import GeneralInformationLeadersAndDepartments from "./form-general-information-updates/GeneralInformationLeadersAndDepartments";
import GeneralInformationOperatingParts from "./form-general-information-updates/GeneralInformationOperatingParts";
import GeneralInformationExpertsAndCoachingStaff from "./form-general-information-updates/GeneralInformationExpertsAndCoachingStaff";
import GeneralInformationTheSubordinateUnits from "./form-general-information-updates/GeneralInformationTheSubordinateUnits";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import {
  getAllDepartmentApiAsync,
  getAllNationalityApiAsync,
  getAllPositionApiAsync,
} from "../redux/personnel.reducer";
import { Location } from "react-router-dom";

interface GeneralInformationProps {
  setValuesUploads: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;
  valueUploads: PersonnelModel | null;

  valuesViewGeneralInformation: GeneralInformationModel | null;
  setValuesViewGeneralInformation: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;

  valueFormLeadersAndDepartments: PersonnelModel | null;
  setValueFormLeadersAndDepartments: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;

  valueFormExpertsAndCoachingStaff: PersonnelModel | null;
  setValueFormExpertsAndCoachingStaff: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;

  valueFormOperatingParts: PersonnelModel | null;
  setValueFormOperatingParts: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;

  valueFormTheSubordinateUnits: PersonnelModel | null;
  setValueFormTheSubordinateUnits: React.Dispatch<React.SetStateAction<PersonnelModel | null>>;

  valueFormOther: GeneralInformationModel | null;
  setValueFormOther: React.Dispatch<React.SetStateAction<GeneralInformationModel | null>>;

  fileContractsUpload: File[];
  fileEducationsUpload: File[];
  fileContractsAddendumUpload: File[];
  fileDocumentsUpload: File[];
  btnSubmitCurrentFormActiveRef: React.RefObject<HTMLButtonElement>;
  btnCurrentFormActiveNextRef: React.RefObject<HTMLButtonElement>;

  location: Location;

  newFileContractsUpload: File[];
  newFileContractsAddendumUpload: File[];
}

interface InitialValuesForm {
  type: string | undefined;
}

const GeneralInformation: FC<GeneralInformationProps> = (props) => {
  const {
    valueUploads,
    setValuesUploads,

    valueFormLeadersAndDepartments,
    setValueFormLeadersAndDepartments,

    valueFormExpertsAndCoachingStaff,
    setValueFormExpertsAndCoachingStaff,

    valueFormOperatingParts,
    setValueFormOperatingParts,

    valueFormTheSubordinateUnits,
    setValueFormTheSubordinateUnits,

    valueFormOther,
    setValueFormOther,

    fileContractsUpload,
    fileEducationsUpload,
    fileContractsAddendumUpload,
    fileDocumentsUpload,
    btnSubmitCurrentFormActiveRef,
    btnCurrentFormActiveNextRef,
    location,

    newFileContractsUpload,
    newFileContractsAddendumUpload,
  } = props;

  const initialValues: InitialValuesForm = {
    type: valueUploads?.type,
  };

  const dispatch = useAppDispatch();
  const allActiveDepartment = useAppSelector((state) => state.personnel.allActiveDepartment);
  const allActivePosition = useAppSelector((state) => state.personnel.allActivePosition);
  const allNationality = useAppSelector((state) => state.personnel.allNationality);

  const handleChangeType = (formik: FormikHelpers<InitialValuesForm>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("type", e.target.value);
    setValuesUploads({
      ...valueUploads,
      type: e.target.value,
    });
  };

  useEffect(() => {
    if (allActiveDepartment.length === 0) {
      dispatch(getAllDepartmentApiAsync({ body: { name: null, status: "ACTIVE" }, pageable: PAGEABLE_MAX_SIZE }));
    }

    if (allNationality.length === 0) {
      dispatch(getAllNationalityApiAsync());
    }

    if (allActivePosition.length === 0) {
      dispatch(getAllPositionApiAsync({ body: { name: null, status: "ACTIVE" }, pageable: PAGEABLE_MAX_SIZE }));
    }
  }, []);

  const validationSchema = Yup.object({});
  const handleOnSubmit = () => {};
  return (
    <>
      {/* select type employee hr */}
      <Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={spacing_size} sx={{ marginBottom: "1.6rem" }}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                  <FieldContainer
                    name="type"
                    onBlur={formik.handleBlur}
                    onChange={handleChangeType(formik)}
                    value={formik.values.type}
                    placeholder=""
                    label=""
                    variant={"radio"}
                    id={"type"}
                    labelOption="description"
                    valueOption="code"
                    options={SelectPersonnelOptions}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>

      {/* Lãnh đạo/các ban */}
      {isLeadersAndDepartments(valueUploads?.type || "") && (
        <GeneralInformationLeadersAndDepartments
          valueUploads={valueUploads}
          setValuesUploads={setValuesUploads}
          valueForm={valueFormLeadersAndDepartments}
          setValueForm={setValueFormLeadersAndDepartments}
          btnSubmitInformationRef={btnSubmitCurrentFormActiveRef}
          allActiveDepartment={allActiveDepartment}
          allActivePosition={allActivePosition}
          btnInformationStaffRefNext={btnCurrentFormActiveNextRef}
          fileContractsUpload={fileContractsUpload}
          fileEducationsUpload={fileEducationsUpload}
          fileDocumentsUpload={fileDocumentsUpload}
          fileContractsAddendumUpload={fileContractsAddendumUpload}
          allNationality={allNationality}
          location={location}
          newFileContractsUpload={newFileContractsUpload}
          newFileContractsAddendumUpload={newFileContractsAddendumUpload}
        />
      )}

      {/* Bộ phận điều hành */}
      {isOperatingParts(valueUploads?.type || "") && (
        <GeneralInformationOperatingParts
          valueUploads={valueUploads}
          setValuesUploads={setValuesUploads}
          valueForm={valueFormOperatingParts}
          setValueForm={setValueFormOperatingParts}
          btnSubmitInformationRef={btnSubmitCurrentFormActiveRef}
          allActiveDepartment={allActiveDepartment}
          allActivePosition={allActivePosition}
          btnInformationStaffRefNext={btnCurrentFormActiveNextRef}
          fileContractsUpload={fileContractsUpload}
          fileEducationsUpload={fileEducationsUpload}
          fileDocumentsUpload={fileDocumentsUpload}
          fileContractsAddendumUpload={fileContractsAddendumUpload}
          allNationality={allNationality}
          location={location}
          newFileContractsUpload={newFileContractsUpload}
          newFileContractsAddendumUpload={newFileContractsAddendumUpload}
        />
      )}

      {/* Chuyên gia/ban huấn luyện */}
      {isExpertsAndCoachingStaff(valueUploads?.type || "") && (
        <GeneralInformationExpertsAndCoachingStaff
          valueUploads={valueUploads}
          setValuesUploads={setValuesUploads}
          btnSubmitInformationRef={btnSubmitCurrentFormActiveRef}
          hrDepartmentDetail={allActiveDepartment}
          hrPositionDetail={allActivePosition}
          valueForm={valueFormExpertsAndCoachingStaff}
          setValueForm={setValueFormExpertsAndCoachingStaff}
          btnInformationExpertRefNext={btnCurrentFormActiveNextRef}
          fileContractsUpload={fileContractsUpload}
          fileEducationsUpload={fileEducationsUpload}
          fileDocumentsUpload={fileDocumentsUpload}
          fileContractsAddendumUpload={fileContractsAddendumUpload}
          nationalityDetail={allNationality}
          location={location}
          newFileContractsUpload={newFileContractsUpload}
          newFileContractsAddendumUpload={newFileContractsAddendumUpload}
        />
      )}

      {/* Các đơn vị trực thuộc */}
      {isTheSubordinateUnits(valueUploads?.type || "") && (
        <GeneralInformationTheSubordinateUnits
          valueUploads={valueUploads}
          setValuesUploads={setValuesUploads}
          valueForm={valueFormTheSubordinateUnits}
          setValueForm={setValueFormTheSubordinateUnits}
          btnSubmitInformationRef={btnSubmitCurrentFormActiveRef}
          allActiveDepartment={allActiveDepartment}
          allActivePosition={allActivePosition}
          btnInformationStaffRefNext={btnCurrentFormActiveNextRef}
          fileContractsUpload={fileContractsUpload}
          fileEducationsUpload={fileEducationsUpload}
          fileDocumentsUpload={fileDocumentsUpload}
          fileContractsAddendumUpload={fileContractsAddendumUpload}
          allNationality={allNationality}
          location={location}
          newFileContractsUpload={newFileContractsUpload}
          newFileContractsAddendumUpload={newFileContractsAddendumUpload}
        />
      )}

      {/* other */}
      {isOther(valueUploads?.type || "") && (
        <FormGeneralInformationOther
          valueUploads={valueUploads}
          setValuesUploads={setValuesUploads}
          valueForm={valueFormOther}
          setValueForm={setValueFormOther}
          btnSubmitInformationRef={btnSubmitCurrentFormActiveRef}
          allActiveDepartment={allActiveDepartment}
          allActivePosition={allActivePosition}
          btnInformationStaffRefNext={btnCurrentFormActiveNextRef}
          fileContractsUpload={fileContractsUpload}
          fileEducationsUpload={fileEducationsUpload}
          fileDocumentsUpload={fileDocumentsUpload}
          fileContractsAddendumUpload={fileContractsAddendumUpload}
          allNationality={allNationality}
          location={location}
          newFileContractsUpload={newFileContractsUpload}
          newFileContractsAddendumUpload={newFileContractsAddendumUpload}
        />
      )}
    </>
  );
};

export default GeneralInformation;
