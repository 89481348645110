import { createTheme } from "@mui/material";
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.8rem",
          color: "#fff",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
          fontSize: "1rem",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTab-root": {
            textTransform: "capitalize",
            "&:first-of-type": {
              textTransform: "none",
            },
            "& span:not(.MuiTab-icon)": {
              display: "none",
            },
            "& .MuiTab-wrapper": {
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "flex-start",
            },
          },
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#2E3D5B",
      contrastText: "white",
    },
    cancel: {
      main: "#E3E3E3",
      contrastText: "#000",
    },
    orange: {
      main: "#FFA215",
      contrastText: "#000",
    },
    green: {
      main: "#348F14",
      contrastText: "#fff",
    },
  },

  typography: {
    fontFamily: "Segoe UI, sans-serif",
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    cancel: Palette["primary"];
    orange: Palette["primary"];
    green: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    cancel?: PaletteOptions["primary"];
    orange?: PaletteOptions["primary"];
    green?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    cancel: true;
    orange: true;
    green: true;
  }
}

export default theme;
