export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!~*@^&#])[a-zA-Z0-9!~*@^&]{8,}/;
// eslint-disable-next-line no-useless-escape
export const CODE_DOC_IN_REGEX = /^[a-zA-Z0-9\s\p{L}\/.-]*$/u;
// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\+\([0-9]{1,}\)[0-9\s-]{0,})|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const CODE_EMPLOYEE_REGEX = /^[0-9]+$/;
export const PASSPORT_PATTERN = /^[a-zA-Z0-9]+$/;
export const PHONE_PATTERN = /^[0-9+()]*$/;
export const IDENTITY_CARD_PATTERN = /^[0-9]+$/;
export const NUMBER_REGEX = /^[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
