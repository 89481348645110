import HTTP_USER_SERVICE from "../config/axios/axios-user.config";
import { Department } from "../model/department.model";
import { UserModel } from "../model/user.model";

export const getListDepartment = (keyword: string) => {
  return HTTP_USER_SERVICE.get<Department[]>("/user/search-vff/listDepartment", {
    params: { keyword },
  });
};

export const getUserFromDepartment = (departmentCode: string, positionCodes: string | null = null, inOperators = true) => {
  return HTTP_USER_SERVICE.get<UserModel[]>("/user/department-code", {
    params: { departmentCode, positionCodes, inOperators },
  });
};
