import { Box } from "@mui/material";
import React from "react";
import { DOC_BOOK_TITLE } from "../../../constants/title-page.constant";
import { FormFilterDocBook } from "./filter/FormFilterDocBook";
import HeaderFilterDocBook from "./filter/HeaderFilterDocBook";
import ResultFilterDocBook from "./filter/ResultFilterDocBook";

const DocBook = () => {
  document.title = DOC_BOOK_TITLE.list;
  return (
    <Box>
      <HeaderFilterDocBook />
      <FormFilterDocBook />
      <ResultFilterDocBook />
    </Box>
  );
};

export default DocBook;
