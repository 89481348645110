import { CategoryDocIns } from "../enum/doc-in.enum";

export const nameCodeDocIn = (categoryDocIn: string | undefined | null) => {
  for (const category of CategoryDocIns) {
    if (category.code === categoryDocIn) {
      return category.description;
    }
  }

  return null;
};