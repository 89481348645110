import { Box, Checkbox, Stack } from "@mui/material";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VIEW_DATE_TIME_FORMAT } from "../../../constants/date-time.constant";
import { TASK_STATUS_ENUM } from "../../../enum/task.enum";
import { convertTaskPriority, convertTaskVerifyAgain } from "../../../utils/task.util";
import StatusTaskChip from "../common/StatusTaskChip";
import ModalConfirm from "../modal/ModalConfirm";
import ItemInformationJob from "./ItemInformationJob";
import ViewItemFileAttachTask from "./ViewItemFileAttachTask";

interface InformationJob {
  data: any;
  getDetailData: () => void;
}

const InformationJob: FC<InformationJob> = (props) => {
  const { data, getDetailData } = props;

  const [checked, setChecked] = useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (data?.confirmInfo === true) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [data]);

  const handleChange = (e: any) => {
    if (e.target.checked === true) {
      setOpenModalConfirm(true);
      setChecked(true);
    }
  };

  const handleCancelModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  return (
    <>
      <ModalConfirm
        id={data?.id}
        setChecked={setChecked}
        open={openModalConfirm}
        onClose={handleCancelModalConfirm}
        getDetailData={getDetailData}
      />
      {data?.showConfirmInfo === true && (
        <Stack width="100%" alignItems={"center"} justifyContent={"space-between"} direction={"row"}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Checkbox
              size="medium"
              checked={checked}
              onChange={handleChange}
              disabled={data?.status !== TASK_STATUS_ENUM.PENDING ? true : false}
            />
            <span>Người nhận đã nhận thông tin</span>
          </Box>
          <div>
            <StatusTaskChip status={data?.status} />
          </div>
        </Stack>
      )}
      <Stack sx={{ marginTop: "1rem" }} width="100%" justifyContent={"space-between"} direction={"row"}>
        <div style={{ fontWeight: "600" }}>Thông tin công việc</div>
        {data?.showConfirmInfo === false && (
          <div>
            <StatusTaskChip status={data?.status} />
          </div>
        )}
      </Stack>

      <ItemInformationJob title="Mã công việc:" desc={data?.code} />
      <ItemInformationJob title="Công việc:" desc={data?.subject} />
      <ItemInformationJob title="Nội dung công việc:" desc={data?.description} />
      <ItemInformationJob
        title="Ngày giờ bắt đầu dự kiến:"
        desc={moment(data?.startDate).format(VIEW_DATE_TIME_FORMAT)}
      />
      <ItemInformationJob
        title="Ngày giờ kết thúc dự kiến:"
        desc={data?.endDate ? moment(data?.endDate).format(VIEW_DATE_TIME_FORMAT) : ""}
      />
      <ItemInformationJob
        title="Người giao việc:"
        desc={data?.reviewer?.fullName + " (" + data?.reviewer?.email + ")"}
      />
      <ItemInformationJob
        title="Người nhận việc:"
        desc={data?.assignee?.fullName + " (" + data?.assignee?.email + ")"}
      />
      <ItemInformationJob
        title="Người nhận thông tin:"
        desc={
          data?.taskWatchers
            ? data?.taskWatchers.map((item: any) => (
                <div key={JSON.stringify(item)}>
                  {item?.fullName} ({item?.email})
                </div>
              ))
            : null
        }
      />
      <ItemInformationJob title="Độ ưu tiên: " desc={convertTaskPriority(data?.priority)} />
      <ItemInformationJob title="Việc cần người giao kiểm tra lại: " desc={convertTaskVerifyAgain(data?.verifyAgain)} />
      <ItemInformationJob
        title="Tài liệu đính kèm:"
        desc={
          data?.taskFiles &&
          data?.taskFiles.map((item: any) => <ViewItemFileAttachTask file={item} key={JSON.stringify(item)} />)
        }
      />
      {data?.docInId && (
        <ItemInformationJob
          title="Chi tiết công văn: "
          desc={<Link to={`../../doc-in/${data?.docInId}`}>{data?.docInCode}</Link>}
        />
      )}
    </>
  );
};

export default InformationJob;
