import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import HeaderPage from "../../../components/common/HeaderPage";
import { header_table_color } from "../../../themes/color";
import { spacing_size } from "../../../themes/size";
import { ContractModel, SummarySalaryModel } from "../../../model/personnel.model";
import BoxContainer from "../../../components/common/BoxContainer";
import TitleSalaryInformation from "../common/component/TitleSalaryInformation";
import ContractTableView from "./ContractTableView";
import { getSalarySummaryDetail } from "../common/util/personnel.util";

interface ContractDetailProps {
  data: any;
}
const ContractDetail: FC<ContractDetailProps> = (props) => {
  const { data } = props;

  const contractAddendumDetail = data.contracts
    ?.map((item: any) => item?.contractAddendums)
    .flatMap((item1: any) => item1);

  const [summarySalaryDetail, setSummarySalaryDetail] = useState<SummarySalaryModel | null>(null);

  useEffect(() => {
    setSummarySalaryDetail(getSalarySummaryDetail(contractAddendumDetail, data.contracts));
  }, []);

  return (
    <>
      <HeaderPage title="Hợp đồng"></HeaderPage>

      {/* thông tin tổng hợp */}
      <Box>
        <BoxContainer>
          <Grid container spacing={spacing_size} alignItems="center">
            <Grid item xs={12} md={6} lg={6}>
              <Typography sx={{ fontSize: "1.2rem", color: "#253C51", fontWeight: "700" }}>
                Tổng hợp thông tin lương
              </Typography>
            </Grid>
            <TitleSalaryInformation
              title={"Lương (VNĐ)"}
              value={summarySalaryDetail?.salary ? Number(summarySalaryDetail?.salary)?.toLocaleString() : 0}
              titleTooltip={"Lấy từ thông tin trên HĐ/PLHĐ còn hiệu lực mới nhất"}
            />
            <TitleSalaryInformation
              title={"Phụ cấp (VNĐ)"}
              value={summarySalaryDetail?.allowances ? Number(summarySalaryDetail?.allowances)?.toLocaleString() : 0}
              titleTooltip={"Lấy từ thông tin trên HĐ/PLHĐ còn hiệu lực mới nhất"}
            />
            <TitleSalaryInformation
              title={"Thưởng tháng (VNĐ)"}
              value={
                summarySalaryDetail?.monthlyBonus ? Number(summarySalaryDetail?.monthlyBonus)?.toLocaleString() : 0
              }
              titleTooltip={"Lấy từ thông tin trên HĐ/PLHĐ còn hiệu lực mới nhất"}
            />
          </Grid>
        </BoxContainer>
      </Box>

      {/* thông tin chi tiết của hợp đồng */}
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ background: header_table_color }}>
                <TableCell sx={{ width: "1%" }}></TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Hợp đồng/ Phụ lục hợp đồng</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Lương (VNĐ)</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Phụ cấp (VNĐ)</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Thưởng tháng (VNĐ)</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Ghi chú</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Trạng thái</TableCell>
                <TableCell sx={{ fontWeight: 600 }} align="center">
                  Hành động
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.contracts?.map((item: ContractModel, index: number) => (
                <ContractTableView key={index} item={item} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ContractDetail;
