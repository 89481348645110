import { Button, Grid, Stack } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { FC } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { createApi } from "../../../../api/doc-book.api";
import FieldContainer from "../../../../components/form/FieldContainer";

import ModalContainer from "../../../../components/modal/ModalContainer";
import { DOC_BOOK_MSG, FORM_MSG } from "../../../../constants/msg/msg.constant";
import { DocBooks, DOC_BOOK_ENUM } from "../../../../enum/doc-book.enum";
import { useAppDispatch } from "../../../../store/hook";
import { spacing_size } from "../../../../themes/size";
import { getErrorMsgAxios } from "../../../../utils/error.util";
import { setReloadFilter } from "../redux/doc-book.slice";
import { addYears, startOfYear } from "date-fns";

interface CreateDocBookModalProps {
  open: boolean;
  onClose: () => void;
}

interface InitialValuesForm {
  code: DOC_BOOK_ENUM;
  docYear: Date | null;
}
const CreateDocBookModal: FC<CreateDocBookModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const { open, onClose } = props;
  const defaultDocYear = startOfYear(new Date());
  const RANGE_YEAR_DOC_YEAR = 50;
  const initialValues: InitialValuesForm = {
    code: DOC_BOOK_ENUM.DOC_IN_DOMESTIC,
    docYear: defaultDocYear,
  };

  const validationSchema = Yup.object({
    docYear: Yup.date().nullable().required(FORM_MSG.requiredFieldMsg("Năm công văn")),
  });

  const handleOnSubmit = async (value: InitialValuesForm, formikHelper: FormikHelpers<InitialValuesForm>) => {
    try {
      await createApi(value.code, value.docYear?.getFullYear() || -1);
      toast.success(DOC_BOOK_MSG.createSuccess);
      dispatch(setReloadFilter(null));
      onClose();
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title={"Thêm mới sổ công văn"}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <Form autoComplete="off" noValidate style={{ padding: "0px 2px" }}>
              <Grid container spacing={spacing_size}>
                <Grid item xs={12}>
                  <FieldContainer
                    name="docYear"
                    onBlur={formik.handleBlur}
                    value={formik.values.docYear}
                    dateFormat="yyyy"
                    isRequired
                    variant={"date"}
                    showYearPicker
                    id={"docYear"}
                    label="Năm công văn"
                    yearItemNumber={12}
                    minDate={addYears(defaultDocYear, -RANGE_YEAR_DOC_YEAR)}
                    maxDate={addYears(defaultDocYear, RANGE_YEAR_DOC_YEAR)}
                    placeholderText="Năm công văn"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldContainer
                    name="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.code}
                    label="Loại"
                    variant={"select"}
                    id={"code"}
                    labelOption="description"
                    valueOption="code"
                    options={DocBooks}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={spacing_size}
                    sx={{
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="cancel"
                      type="button"
                      onMouseDown={() => {
                        formik.resetForm();
                      }}
                    >
                      Huỷ
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Thêm mới
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </ModalContainer>
  );
};

export default CreateDocBookModal;
