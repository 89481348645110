import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { FC } from "react";
import HeaderPage from "../../../components/common/HeaderPage";
import { header_table_color } from "../../../themes/color";
interface RelativesDetailProps {
  data: any;
}
const RelativesDetail: FC<RelativesDetailProps> = (props) => {
  const { data } = props;
  return (
    <>
      <HeaderPage title="Người thân"></HeaderPage>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ background: header_table_color }}>
                <TableCell sx={{ fontWeight: 600 }}>Họ và tên người thân</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Mối quan hệ</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Năm sinh</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Số điện thoại</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Nghề nghiệp</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Nơi công tác</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.employeeRelatives?.map((item: any, index: number) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                  }}
                  key={index}
                >
                  <TableCell>{item?.fullName}</TableCell>
                  <TableCell>{item?.relationshipName}</TableCell>
                  <TableCell>{item?.birthYear}</TableCell>
                  <TableCell>{item?.phoneNumber}</TableCell>
                  <TableCell>{item?.occupation}</TableCell>
                  <TableCell>{item?.workPlace}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>{" "}
    </>
  );
};

export default RelativesDetail;
