import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { filterTaskApi, getDetailByIdTaskApi } from "../../../api/task/task.api";
import { TOTAL_HEADER } from "../../../constants/page.constant";
import { ResponseFilterDocInModel } from "../../../model/doc-in.model";
import { Pageable } from "../../../model/page.model";
import { getErrorMsgAxios } from "../../../utils/error.util";

interface TaskReducerState {
  pageTask: number;
  dataTaskFilter: ResponseFilterDocInModel[] | null;
  totalTaskFilter: number;
  reloadTaskFilter: number;
  detailTask: any;
}

const initialState: TaskReducerState = {
  pageTask: 0,
  dataTaskFilter: null,
  totalTaskFilter: 0,
  reloadTaskFilter: 0,
  detailTask: null,
};

export const filterTaskApiAsync = createAsyncThunk(
  "task/filter",
  async (data: { body: any | null; pageable: Pageable }) => {
    try {
      const res = await filterTaskApi(data.body, data.pageable);
      const total = Number(res.headers[TOTAL_HEADER]) || 0;
      return { data: res.data, total };
    } catch (error: any) {
      const msg = getErrorMsgAxios(error);
      toast.error(msg);
      throw Error(error);
    }
  }
);

export const getByIdTaskApiAsync = createAsyncThunk("task/getByIdTaskApiAsync", async (id: number) => {
  try {
    const res = await getDetailByIdTaskApi(id);
    return res.data;
  } catch (error: any) {
    const msg = getErrorMsgAxios(error);
    toast.error(msg);
    throw Error(error);
  }
});

const TaskReducer = createSlice({
  name: "TaskReducer",
  initialState,
  reducers: {
    setPageFilterTask(state, action) {
      return {
        ...state,
        pageTask: action.payload,
      };
    },
    setReloadTaskFilter(state, action) {
      return {
        ...state,
        reloadFilterDocIn: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        filterTaskApiAsync.fulfilled,
        (
          state,
          action: {
            payload: { data: any[] | null; total: number };
          }
        ) => {
          // action is inferred correctly here if using TS
          return {
            ...state,
            dataTaskFilter: action.payload.data,
            totalTaskFilter: action.payload.total,
          };
        }
      )
      .addCase(getByIdTaskApiAsync.fulfilled, (state, action) => {
        return {
          ...state,
          detailTask: action.payload,
        };
      });
  },
});

export const { setPageFilterTask, setReloadTaskFilter } = TaskReducer.actions;
export default TaskReducer.reducer;
