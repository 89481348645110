import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import commonReducer from "./reducers/common.slice";
import AuthReducer from "../features/auth/redux/auth.slice";
import UserReducer from "../features/user-mgmt/user/redux/user.slice";
import DocInReducer from "../features/document/document-in/redux/doc-in.reducer";
import DocBookReducer from "../features/setting/doc-book/redux/doc-book.slice";
import DocOutReducer from "../features/document/document-out/redux/doc-out.reducer";
import PersonnelReducer from "../features/personnel/redux/personnel.reducer";
import taskReducer from "../features/task/redux/task.reducer";
import DocInDashboardReducer from "../features/dashboard/doc-in/redux/doc-in-dashboard.reducer";

export const store = configureStore({
  reducer: combineReducers({
    common: commonReducer,
    auth: AuthReducer,
    user: UserReducer,
    docIn: DocInReducer,
    docInDashboard: DocInDashboardReducer,
    docBook: DocBookReducer,
    docOut: DocOutReducer,
    personnel: PersonnelReducer,
    task: taskReducer,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
