import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const DashboardSideBarIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.526 7.694A.525.525 0 0 0 0 8.217v5.497c0 .29.236.526.526.526h3.456a.524.524 0 0 0 .524-.526V8.217a.523.523 0 0 0-.524-.523H.526Zm2.93 5.497H1.05V8.743h2.407v4.448ZM15 13.716V5.04a.526.526 0 0 0-.526-.526h-3.456a.526.526 0 0 0-.527.526v8.674c0 .29.236.526.527.526h3.456c.29 0 .526-.235.526-.523Zm-1.05-.526h-2.406V5.565h2.407v7.625Zm-4.722 1.05c.29 0 .523-.236.523-.527V1.287A.525.525 0 0 0 9.228.76H5.772a.526.526 0 0 0-.526.526v12.428c0 .29.235.526.526.526h3.456ZM6.298 1.812h2.407V13.19H6.298V1.812Z"
        fill="#fff"
      />
    </svg>
  );
};

export default DashboardSideBarIcon;
