import { Pageable } from "../model/page.model";

export const SIZE_DEFAULT = 20;
export const MAX_SIZE_DEFAULT = 10000000;
export const MAX_SIZE_EXPORT = 100000;
export const SIZE_10 = 10;

export const TOTAL_HEADER = "x-total-count";

export const PAGEABLE_DEFAULT: Pageable = {
  page: 0,
  size: SIZE_DEFAULT,
};

export const PAGEABLE_MAX_SIZE = {
  page: 0,
  size: MAX_SIZE_DEFAULT,
};
