import { IconButton } from "@mui/material";
import { forwardRef } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export const CustomInputCalendar = forwardRef(
  (
    {
      onClick,
      onMouseDown,
      btnRef,
    }: { value?: string; onClick?: () => void; onMouseDown: () => void; btnRef?: React.RefObject<HTMLButtonElement> },
    ref: any
  ) => (
    <IconButton type="button" onClick={onClick} ref={btnRef} onMouseDown={onMouseDown}>
      <CalendarTodayIcon />
    </IconButton>
  )
);
