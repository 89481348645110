import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentInformation from "./ContentInformation";
import { VIEW_DATE_FORMAT } from "../../../constants/date-time.constant";
import { checkExpertAndCoachingStaff, convertGender, convertMaritalStatus } from "../common/util/personnel.util";
import { PersonnelModel } from "../../../model/personnel.model";
import { formatDateTime } from "../../../utils/data-time.util";
interface GeneralInformationDetailProps {
  data: PersonnelModel | any;
}

const GeneralInformationDetail: FC<GeneralInformationDetailProps> = (props) => {
  const { data } = props;

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
        {/* Thông tin cá nhân */}
        <Grid item sm={12}>
          <div>
            <Accordion defaultExpanded={true} style={{ borderLeft: "5px solid #2E3D5B" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography style={{ fontWeight: "bold" }}>Thông tin cá nhân</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                {" "}
                <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                  <Grid item sm={12} md={6} lg={4}>
                    <ContentInformation title="Mã cá nhân" description={data?.code} top="10" />

                    <ContentInformation title="Giới tính" description={convertGender(data?.gender)} top="10" />

                    {!checkExpertAndCoachingStaff(data?.type) ? (
                      <>
                        <ContentInformation
                          title="Tình trạng hôn nhân"
                          description={convertMaritalStatus(data?.maritalStatus)}
                          top="10"
                        />
                        <ContentInformation title="Tôn giáo" description={data?.religion} top="10" />
                        <ContentInformation title="Địa chỉ thường trú" description={data?.permanentAddress} top="10" />
                      </>
                    ) : (
                      <ContentInformation title="Quốc tịch" description={data?.nationality?.name} top="10" />
                    )}
                  </Grid>

                  <Grid item sm={12} md={6} lg={4}>
                    <ContentInformation title="Họ và tên" description={data?.fullName} top="10" />
                    <ContentInformation title="Số điện thọai" description={data?.phoneNumber} top="10" />

                    {!checkExpertAndCoachingStaff(data?.type) && (
                      <>
                        <ContentInformation title="Quốc tịch" description={data?.nationality?.name} top="10" />
                        <ContentInformation title="Mã số thuế cá nhân" description={data?.taxCode} top="10" />
                      </>
                    )}
                    <ContentInformation title="Nơi ở hiện tại" description={data?.address} top="10" />
                  </Grid>

                  <Grid item sm={12} md={6} lg={4}>
                    <ContentInformation
                      title="Ngày sinh"
                      description={formatDateTime(data?.dob, VIEW_DATE_FORMAT)}
                      top="10"
                    />
                    <ContentInformation title="Email công việc" description={data?.email} top="10" />
                    {!checkExpertAndCoachingStaff(data?.type) && (
                      <>
                        <ContentInformation title="Nguyên quán" description={data?.nativePlace} top="10" />
                        <ContentInformation title="Dân tộc" description={data?.ethnic} top="10" />
                      </>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>

        {/* Thông tin cơ quan */}
        <Grid item sm={12}>
          <div>
            <Accordion defaultExpanded={true} style={{ borderLeft: "5px solid #2E3D5B" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography style={{ fontWeight: "bold" }}>Thông tin cơ quan</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                {" "}
                <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                  {!checkExpertAndCoachingStaff(data?.type) && (
                    <Grid item sm={12} md={6} lg={4}>
                      <>
                        <ContentInformation title="Nơi làm việc" description={data?.workingAddress} top="10" />
                        <ContentInformation
                          title="Quản lý trực tiếp"
                          description={data?.employeeManager?.fullName}
                          top="10"
                        />
                      </>
                    </Grid>
                  )}
                  <Grid item sm={12} md={6} lg={4}>
                    <ContentInformation title="Bộ phận công tác" description={data?.department?.name} top="10" />
                    {!checkExpertAndCoachingStaff(data?.type) && (
                      <ContentInformation
                        title="Thời gian bắt đầu công tác"
                        description={formatDateTime(data?.startDate, VIEW_DATE_FORMAT)}
                        top="10"
                      />
                    )}
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <ContentInformation title="Chức vụ" description={data?.position?.name} top="10" />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>

        {/* Thông tin giấy tờ tuỳ thân */}
        <Grid item sm={12}>
          <div>
            <Accordion defaultExpanded={true} style={{ borderLeft: "5px solid #2E3D5B" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography style={{ fontWeight: "bold" }}>Thông tin giấy tờ tùy thân</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                {" "}
                <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                  <Grid item sm={12} md={6} lg={4}>
                    <ContentInformation title="Số CMND/CCCD" description={data?.identityCard} top="10" />
                    <ContentInformation title="Hộ chiếu" description={data?.passport} top="10" />
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <ContentInformation
                      title="Ngày cấp CMND/CCCD"
                      description={formatDateTime(data?.idcIssueDate, VIEW_DATE_FORMAT)}
                      top="10"
                    />
                    <ContentInformation
                      title="Ngày cấp HC"
                      description={formatDateTime(data?.passportIssueDate, VIEW_DATE_FORMAT)}
                      top="10"
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <ContentInformation title="Nơi cấp CMND/CCCD" description={data?.idcIssuePlace} top="10" />
                    <ContentInformation title="Nơi cấp HC" description={data?.passportIssuePlace} top="10" />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>

        {/* Khen thưởng ký luật , THông tin đảng đoàn viên, Thông tin liên hệ*/}
        {!checkExpertAndCoachingStaff(data?.type) && (
          <>
            {/* Khen thưởng kỷ luật */}
            <Grid item sm={12}>
              <div>
                <Accordion defaultExpanded={true} style={{ borderLeft: "5px solid #2E3D5B" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography style={{ fontWeight: "bold" }}>Khen thưởng/ kỷ luật</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                    {" "}
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation title="Khen thưởng" description={data?.rewards} top="10" />
                      </Grid>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation title="Kỷ luật" description={data?.disciplines} top="10" />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>

            {/* Thông tin đảng đoàn viên */}
            <Grid item sm={12}>
              <div>
                <Accordion defaultExpanded={true} style={{ borderLeft: "5px solid #2E3D5B" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography style={{ fontWeight: "bold" }}>Thông tin Đảng viên/ Đoàn viên</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                    {" "}
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation title="Chức vụ đảng viên" description={data?.partyPosition} top="10" />
                        <ContentInformation title="Chức vụ đoàn viên" description={data?.unionPosition} top="10" />
                      </Grid>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation
                          title="Ngày kết nạp Đảng"
                          description={formatDateTime(data?.partyJoinDate, VIEW_DATE_FORMAT)}
                          top="10"
                        />
                        <ContentInformation
                          title="Ngày kết nạp Đoàn"
                          description={formatDateTime(data?.unionJoinDate, VIEW_DATE_FORMAT)}
                          top="10"
                        />
                      </Grid>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation title="Nơi kết nạp Đảng" description={data?.partyJoinPlace} top="10" />
                        <ContentInformation title="Nơi kết nạp Đoàn" description={data?.unionJoinPlace} top="10" />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>

            <Grid item sm={12}>
              <div>
                <Accordion defaultExpanded={true} style={{ borderLeft: "5px solid #2E3D5B" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography style={{ fontWeight: "bold" }}>Thông tin liên hệ của người thân</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                    {" "}
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation
                          title="Họ và tên"
                          description={data?.employeeContactInfo?.fullName}
                          top="10"
                        />
                      </Grid>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation
                          title="Số điện thoại"
                          description={data?.employeeContactInfo?.phoneNumber}
                          top="10"
                        />
                      </Grid>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation
                          title="Mối quan hệ với CBNV"
                          description={data?.employeeContactInfo?.relationship}
                          top="10"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </>
        )}

        {/* Thông tin giấy tờ khác */}
        {checkExpertAndCoachingStaff(data?.type) && (
          <>
            <Grid item sm={12}>
              <div>
                <Accordion defaultExpanded={true} style={{ borderLeft: "5px solid #2E3D5B" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography style={{ fontWeight: "bold" }}>Thông tin giấy tờ khác</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ borderTop: "1px solid #D9D9D9" }}>
                    {" "}
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation
                          title="Số giấy phép lao động"
                          description={data?.workPermitNumber}
                          top="10"
                        />
                        <ContentInformation
                          title="Số thẻ tạm trú"
                          description={data?.temporaryResidenceCard}
                          top="10"
                        />
                      </Grid>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation
                          title="Thời hạn từ ngày"
                          description={formatDateTime(data?.workPermitFromDate, VIEW_DATE_FORMAT)}
                          top="10"
                        />
                        <ContentInformation
                          title="Thời hạn"
                          description={formatDateTime(data?.trcExpiredDate, VIEW_DATE_FORMAT)}
                          top="10"
                        />
                      </Grid>
                      <Grid item sm={12} md={6} lg={4}>
                        <ContentInformation
                          title="Thời hạn đến ngày"
                          description={formatDateTime(data?.workPermitToDate, VIEW_DATE_FORMAT)}
                          top="10"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default GeneralInformationDetail;
