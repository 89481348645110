import { Box, Button } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "../../../../../components/icons/ArrowBackIcon";
import FormCreateDocInDomestic from "./FormCreateDocInDomestic";

interface CreateDocInDomesticProps {}

const CreateDocInDomestic: FC<CreateDocInDomesticProps> = (props) => {
  const navigate = useNavigate();

  const handleClickButtonBack = () => {
    navigate("/doc-in");
  };

  return (
    <>
      <Box>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          startIcon={<ArrowBackIcon />}
          onClick={handleClickButtonBack}
        >
          Quay lại
        </Button>
        <Box sx={{ marginTop: "0.4rem" }}>
          <FormCreateDocInDomestic />
        </Box>
      </Box>
    </>
  );
};

export default CreateDocInDomestic;
