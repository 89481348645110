import { matchPath } from "react-router-dom";

export const isActiveRouter = (pathname: string, path: string, patternLinks: string[] = []): boolean => {
  if (patternLinks && patternLinks.length > 0) {
    for (const link of patternLinks) {
      if (matchPath(link, pathname)) {
        return true;
      }
    }
  }
  return matchPath(path, pathname) ? true : false;
};
