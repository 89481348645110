import { EnumCommon } from "../enum/common.enum";

export const addItemAll = (data: Object[]) => {};

export const convertArrToString = (data: String[]) => {
  return data?.toString();
};

export const formatCurrency = (text: string | number | undefined) => {
  return text && text?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};

export const isEmptyArr = (arr: any | any[]): boolean => {
  if (arr && arr.length > 0) {
    return false;
  }

  return true;
};

export const paginateSlice = (array: any[], pageSize: number, pageNumber: number) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

export const sortByDescriptionASCEnum = (a1: EnumCommon<any>, a2: EnumCommon<any>) => {
  if (a1.description.toLowerCase() > a2.description.toLowerCase()) {
    return 1;
  }
  if (a1.description.toLowerCase() < a2.description.toLowerCase()) {
    return -1;
  }
  return 0;
};
