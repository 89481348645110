import React, { useEffect, useState } from "react";
import { SIZE_DEFAULT } from "../../../constants/page.constant";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { filterDepartmentApiAsync } from "../user/redux/user.slice";
import FormSearchDepartment from "./FormSearchDepartment";
import HeaderDepartment from "./HeaderDepartment";
import ResultDepartment from "./ResultDepartment";
interface InitialValuesForm {
  name: string | null;
  status: string | null;
}

function Department() {
  const dispatch = useAppDispatch();
  const page = useAppSelector((state) => state.user.pageDepartment);
  const initialValues: InitialValuesForm = {
    name: null,
    status: null,
  };
  const [initValue, setInitValue] = useState<InitialValuesForm>(initialValues);

  const buildDataFilter = (values: any) => {
    const body = values;
    const pageable = { page, size: SIZE_DEFAULT };
    return {
      body,
      pageable,
    };
  };

  const getDataDepartment = async (body = initValue) => {
    await dispatch(filterDepartmentApiAsync(buildDataFilter(body)));
  };

  useEffect(() => {
    getDataDepartment(initValue);
  }, [page]);
  return (
    <>
      <HeaderDepartment buildDataFilter={buildDataFilter} initValue={initValue} />
      <FormSearchDepartment setInitValue={setInitValue} initValue={initValue} buildDataFilter={buildDataFilter} />
      <ResultDepartment buildDataFilter={buildDataFilter} initValue={initValue} />
    </>
  );
}

export default Department;
