import { Box, Typography, useTheme } from "@mui/material";
import React, { FC, useState } from "react";

interface ReadmoreUppercaseProps {
  text?: string | any;
  overSx?: any;
  showLength?: number | any;
}

const ReadmoreUppercase: FC<ReadmoreUppercaseProps> = (props) => {
  const { text, overSx, showLength = 80 } = props;
  const [toggleReadMore, setToggleReadMore] = useState(true);

  const handleClickReadMore = (e: any) => {
    e.stopPropagation();
    setToggleReadMore(!toggleReadMore);
  };

  if (text) {
    const truncatedText = toggleReadMore ? `${text.slice(0, showLength)}` : text;

    return (
      <Typography
        style={{
          whiteSpace: "pre-line",
          textAlign: "left",
        }}
        {...overSx}
      >
        <span dangerouslySetInnerHTML={{ __html: truncatedText }} />
        {text.length > showLength && (
          <span style={{ color: "#0084FF", cursor: "pointer", marginLeft: "2px" }} onClick={handleClickReadMore}>
            {toggleReadMore ? "... Xem thêm" : " Thu gọn"}
          </span>
        )}
      </Typography>
    );
  }

  return text;
};

export default ReadmoreUppercase;
