export const ALLOW_EXTENSION_FILE = ["pdf", "docx", "xlsx", "xls", "doc"];
export const VALID_FORMAT_FILE = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
];
export const ONE_MB = 1024 * 1024;
export const MAX_SIZE_FILE_MB = 20;
