import { Typography } from "@mui/material";

export const Text = (props: any) => {
  const { title } = props;
  return (
    <Typography
      sx={{
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.75",
        // color: "#323E5D",
        // fontFamily: " Inter,sans-serif",
        // textAlign: "justify",
      }}
    >
      {title}
    </Typography>
  );
};
