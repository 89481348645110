import { Box, IconButton } from "@mui/material";
import React, { FC } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { FileModel } from "../../../../model/doc-in.model";

interface ViewFileProps {
  item: File | FileModel;
  handleRemove: (item: File | FileModel | any) => void;
  onClickItem?: () => void;
}

const ViewFile: FC<ViewFileProps> = (props) => {
  const { item, handleRemove, onClickItem } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          color: "#466FFF",
          fontWeight: "500",
          fontSize: "15px",
          cursor: "pointer",
        }}
        type="button"
        component="a"
        onClick={() => {
          onClickItem && onClickItem();
        }}
      >
        {item.name}
      </Box>
      <Box sx={{ width: "45px" }}>
        <>
          <IconButton
            onClick={() => handleRemove(item)}
            sx={{
              color: "#D4D9E4",
              fontSize: "16px",
            }}
          >
            <HighlightOffOutlinedIcon />
          </IconButton>
        </>
      </Box>
    </Box>
  );
};

export default ViewFile;
