import React, { FC } from "react";
import HeaderPage from "../../../components/common/HeaderPage";
import { Button } from "@mui/material";
import XCircleIcon from "../../../components/icons/XCircleIcon";
import { useNavigate } from "react-router-dom";

interface HeaderJobProps {
  dashboard?: any;
}

const Header: FC<HeaderJobProps> = ({ dashboard }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/task/create");
  };

  return (
    <>
      <HeaderPage
        title={
          !!dashboard
            ? `Danh sách công việc của người nhận việc: ${dashboard.fullName} (${dashboard.email})`
            : "Danh sách công việc"
        }
      >
        <>
          <Button variant="contained" startIcon={<XCircleIcon />} onClick={handleRedirect}>
            Thêm mới
          </Button>
        </>
      </HeaderPage>
    </>
  );
};

export default Header;
