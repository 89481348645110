import React, { FC, useState } from "react";

// import module
import { Box } from "@mui/system";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// import project
import PaginationPage from "../../../components/common/Pagination";
import AntSwitch from "../../../components/common/switch/AntSwitch";
import { UserModel } from "../../../model/user.model";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import ModalUpdateUser from "./modal/ModalUpdateUser";
import BoxContainer from "../../../components/common/BoxContainer";
import { setPage, setReloadFilter } from "./redux/user.slice";
import NotFoundFilterView from "../../../components/common/NotFoundFilterView";
import { getByIdApi as getUserByIdApi, updateStatusApi } from "../../../api/user.api";
import { toast } from "react-toastify";
import { getErrorMsgAxios } from "../../../utils/error.util";
import { getCurrentUserApiAsync } from "../../auth/redux/auth.slice";
import { header_table_color } from "../../../themes/color";
import ModalContainer from "../../../components/modal/ModalContainer";
import { spacing_size } from "../../../themes/size";
import { SIZE_DEFAULT } from "../../../constants/page.constant";
import { hasAccessRight } from "../../../utils/auth.util";
import { ACCESS_RIGHT_ENUM } from "../../../enum/access-right.enum";

const ResultUser = () => {
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const data = useAppSelector((state) => state.user.dataUsers);
  const total = useAppSelector((state) => state.user.totalDataUsers);
  const page = useAppSelector((state) => state.user.page);
  const dispatch = useAppDispatch();
  const [userUpdate, setUserUpdate] = useState<UserModel | null>(null);
  const [isOpenConfirmChangeStatus, setIsOpenConfirmChangeStatus] = useState(false);
  const [userChangeStatus, setUserChangeStatus] = useState<UserModel | null>(null);

  const handleOpenModal = (userUpdate: UserModel) => async () => {
    try {
      const res = await getUserByIdApi(userUpdate.id);
      setUserUpdate(res.data);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }

    setOpenModalUpdate(true);
  };

  const handleCloseModal = () => {
    setOpenModalUpdate(false);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setPage(page - 1));
  };

  const handleChangeStatusUser = async (item: UserModel) => {
    try {
      await updateStatusApi(item.id, !item.status);
      if (item.email === currentUser?.email) {
        await dispatch(getCurrentUserApiAsync());
      }
      await dispatch(setReloadFilter(null));
      setIsOpenConfirmChangeStatus(false);
    } catch (error) {
      toast.error(getErrorMsgAxios(error));
    }
  };

  const formatAccessRight = (accessRight: String) => {
    const accessRights = accessRight?.split("|");
    return (
      <>
        {accessRights?.map((item) => {
          return (
            <>
              <span>{item}</span>
              <br />
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      {/* confirm change status */}
      <ModalContainer
        open={isOpenConfirmChangeStatus}
        title={"Thay đổi trạng thái"}
        onClose={() => {
          setIsOpenConfirmChangeStatus(false);
        }}
      >
        <>
          <Typography>Bạn có chắc muốn đổi trạng thái của người dùng này không?</Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={spacing_size}
            sx={{
              mt: 2,
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              color="cancel"
              type="button"
              onMouseDown={() => {
                setIsOpenConfirmChangeStatus(false);
              }}
            >
              Huỷ
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onMouseDown={() => {
                userChangeStatus && handleChangeStatusUser(userChangeStatus);
              }}
            >
              Xác nhận
            </Button>
          </Stack>
        </>
      </ModalContainer>

      {userUpdate && <ModalUpdateUser open={openModalUpdate} onClose={handleCloseModal} user={userUpdate} />}

      {data && (
        <BoxContainer>
          {/* show result */}
          {data.length > 0 && (
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: header_table_color }}>
                      <TableCell align="center" sx={{ fontWeight: 600 }}>
                        STT
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Tên</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Mã nhân viên</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Tài khoản</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Bộ phận công tác</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Chức vụ</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Nhóm quyền</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Trạng thái</TableCell>
                      {hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN) && (
                        <TableCell align="center" sx={{ fontWeight: 600 }}>
                          Hành động
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((item: UserModel, index: number) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: index % 2 === 0 ? "" : "rgba(233, 233, 233, 0.7)",
                        }}
                        key={index}
                      >
                        <TableCell align="center">{SIZE_DEFAULT * page + index + 1}</TableCell>
                        <TableCell>
                          <Typography fontWeight={600} fontSize={"0.875rem"}>
                            {item?.fullName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={"0.8rem"}>{item.code}</Typography>
                        </TableCell>
                        <TableCell>{item?.email}</TableCell>
                        <TableCell>{item?.departmentName}</TableCell>
                        <TableCell>{item?.position}</TableCell>
                        <TableCell sx={{ width: "20%" }}>{formatAccessRight(item?.accessRight)}</TableCell>
                        <TableCell align="center">
                          <AntSwitch
                            checked={item.status}
                            onChange={() => {
                              if (hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN)) {
                                setUserChangeStatus(item);
                                setIsOpenConfirmChangeStatus(true);
                              }
                            }}
                            sx={{ marginLeft: "calc(25%)" }}
                          />
                        </TableCell>
                        {hasAccessRight(currentUser, ACCESS_RIGHT_ENUM.ADMIN) && (
                          <TableCell align="center">
                            <Button
                              variant="outlined"
                              onClick={handleOpenModal(item)}
                              color="primary"
                              size="small"
                              type="button"
                            >
                              Cập nhật
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationPage total={total} page={page} onChange={handleChangePage} />
            </Box>
          )}

          {/* not found result */}
          {data.length === 0 && <NotFoundFilterView />}
        </BoxContainer>
      )}
    </>
  );
};

export default ResultUser;
