import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const XCircleIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_2)">
        <path
          d="M7 12.8333C10.2217 12.8333 12.8333 10.2217 12.8333 7C12.8333 3.77834 10.2217 1.16667 7 1.16667C3.77834 1.16667 1.16667 3.77834 1.16667 7C1.16667 10.2217 3.77834 12.8333 7 12.8333Z"
          stroke="#D4D9E4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9.47478 7.02212L4.52523 6.97788" stroke="#D4D9E4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.02213 4.52522L6.97789 9.47477" stroke="#D4D9E4" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default XCircleIcon;
