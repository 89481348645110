import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

const ArrowBackIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.75 1L1 4.75L4.75 8.5" stroke="#2E3D5B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.125 1.25L5.375 5L9.125 8.75" stroke="#2E3D5B" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowBackIcon;
