import { FC, useEffect, useRef, useState } from "react";

// import modules
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useNavigate, Params, useParams } from "react-router-dom";

// import project
import HeaderPage from "../../../../../components/common/HeaderPage";
import BrowserIcon from "../../../../../components/icons/BrowserIcon";
import BoxContainer from "../../../../../components/common/BoxContainer";
import FieldContainer from "../../../../../components/form/FieldContainer";
import { getDocBookApi } from "../../../../../api/doc-book.api";
import { getArrivalNbrApi, getDocInByIdApi, updateDocInApi } from "../../../../../api/doc-in.api";
import { getUserApi } from "../../../../../api/user.api";
import OptionSelectUser from "../../../../../components/common/OptionSelectUser";
import ConfirmModal from "../../../../../components/modal/ConfirmModal";
import {
  UPLOAD_DATE_FORMAT,
  VIEW_DATE_TIME_FORMAT,
  VIEW_DATE_TIME_WITHOUT_SECOND_FORMAT,
} from "../../../../../constants/date-time.constant";
import { ALLOW_INPUT_FILE_DOC_IN, MAX_LENGTH_FILE_DOC_IN } from "../../../../../constants/doc-in.constant";
import { FORM_MSG, DOC_IN_MSG } from "../../../../../constants/msg/msg.constant";
import { CODE_DOC_IN_REGEX } from "../../../../../constants/regex.constant";
import { deadlineDocIns, DEADLINE_DOC_IN_ENUM, UPLOAD_CATEGORY_DOC_IN_ENUM } from "../../../../../enum/doc-in.enum";

import { DocBook, CodeDocBookModel } from "../../../../../model/doc-book.model";
import { FileModel, DocInModel } from "../../../../../model/doc-in.model";
import { UserModel } from "../../../../../model/user.model";
import { spacing_size } from "../../../../../themes/size";
import { convertArrToString } from "../../../../../utils/array.util";
import { changeFileDocIn } from "../../common/util/doc-in.util";
import ViewFile from "../../common/ViewFile";
import UploadSVG from "../../../../../components/images/upload.svg";
import { getErrorMsgAxios } from "../../../../../utils/error.util";
import { CustomInputCalendar } from "../../common/CustomInputCalendar";
import { EnumCommon } from "../../../../../enum/common.enum";
import { removeFile } from "../../../../../utils/file.util";
import { Department, DepartmentHR } from "../../../../../model/department.model";
import { getListDepartmentFromHr } from "../../../../../api/hr/department.api";
import { EXCEPT_SYMBOLS } from "../../../../../constants/except.constant";
import HeaderForm from "../../../../../components/common/HeaderForm";
import { DOC_BOOK_ENUM } from "../../../../../enum/doc-book.enum";

interface FormUpdateDocInInternationalProps {}

interface InitialValuesForm {
  code?: string;
  docCategory?: string;
  fromPlace?: string;
  docDate?: Date | null;
  arrivalDate?: Date | null;
  docBook?: string | number | any;
  arrivalNbr?: string | number;
  deadline?: any;
  // sentChannel?: SEN_CHANNEL_DOC_IN_ENUM | string;
  summary?: string;
  handlingDepartment?: any;
  combinedDepartment?: any;
  bod?: any;
  contentTranslated?: string;
  id?: number;
  deadlineTime?: Date | null;
}

const FormUpdateDocInInternational: FC<FormUpdateDocInInternationalProps> = (props) => {
  const defaultInitialValues: InitialValuesForm = {
    docCategory: UPLOAD_CATEGORY_DOC_IN_ENUM.INTERNATIONAL,
    code: "",
    fromPlace: "",
    docDate: null,
    arrivalDate: null,
    docBook: -1,
    summary: "",
    arrivalNbr: 0,
    deadline: null,
    // sentChannel: SEN_CHANNEL_DOC_IN_ENUM.EMAIL,
    handlingDepartment: "",
    combinedDepartment: "",
    contentTranslated: "",
    bod: "",
    id: -1,
  };

  const navigate = useNavigate();
  const btnSubmitRef = useRef<HTMLButtonElement>(null);
  const btnCancelRef = useRef<HTMLButtonElement>(null);
  const deadlineTimeBtnInputRef = useRef<HTMLButtonElement>(null);

  const params: Readonly<Params<string>> = useParams();
  const currentDate = new Date();
  const [files, setFiles] = useState<File[]>([]);
  const [fileUploaded, setFileUploaded] = useState<FileModel[]>([]);
  const [openModalSubmit, setOpenModalSubmit] = useState<boolean>(false);
  const [departments, setDepartments] = useState<DepartmentHR[]>([]);
  const [leaderShips, setLeaderShips] = useState<UserModel[]>([]);
  const [docBooks, setDocBooks] = useState<DocBook[]>([]);
  const [docInDetail, setDocInDetail] = useState<DocInModel>();
  const [initialValues, setInitialValues] = useState<InitialValuesForm>(defaultInitialValues);
  const [bodyFormData, setBodyFormData] = useState<FormData | null>(null);
  const [openDeadLineTimeModal, setOpenDeadLineTimeModal] = useState<boolean>(false);
  const [isProcessed, setIsProcessed] = useState(false);

  const labelFieldForm = {
    code: "Số và ký hiệu văn bản",
    fromPlace: "Nơi gửi văn bản",
    docDate: "Ngày tháng văn bản",
    arrivalDate: "Ngày văn bản đến",
    docBook: "Sổ công văn",
    summary: "Trích yếu nội dung văn bản ",
    deadline: "Ngày văn bản đến",
    handlingDepartment: "Đơn vị/phòng chủ trì giải quyết",
    combinedDepartment: "Đơn vị/phòng phối hợp thực hiện",
    bod: "Báo cáo lãnh đạo/Ban tổng thư ký",
    arrivalNbr: "Số đến",
    // sentChannel: "Hình thưc gửi",
    contentTranslated: "Nội dung văn bản được dịch",
  };

  const validationSchema = Yup.object({
    code: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg(labelFieldForm.code)),
    // .matches(CODE_DOC_IN_REGEX, FORM_MSG.patternFieldMsg(labelFieldForm.code)),
    fromPlace: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg(labelFieldForm.fromPlace)),
    docDate: Yup.date()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg(labelFieldForm.docDate))
      .when("arrivalDate", (docDate: any, schema: any) => {
        if (docDate) {
          return schema.max(docDate, DOC_IN_MSG.docDateMaxArrivalDate);
        }
        return schema;
      }),
    arrivalDate: Yup.date()
      .nullable()
      .required(FORM_MSG.requiredFieldMsg(labelFieldForm.arrivalDate))
      .when(["deadline"], (deadline: any, schema: any) => {
        if (deadline?.code === DEADLINE_DOC_IN_ENUM.OTHER && deadline?.deadlineTime) {
          return schema.max(new Date(deadline?.deadlineTime), DOC_IN_MSG.arrivalDateMaxDeadlineTime);
        }
        return schema;
      }),
    arrivalNbr: Yup.number().nullable().required(FORM_MSG.requiredFieldMsg(labelFieldForm.arrivalNbr)),
    docBook: Yup.object().nullable().required(FORM_MSG.requiredSelectFieldMsg(labelFieldForm.docBook)),
    summary: Yup.string().nullable().required(FORM_MSG.requiredFieldMsg(labelFieldForm.summary)),
    // deadline: Yup.object().nullable().required(FORM_MSG.requiredFieldMsg(labelFieldForm.deadline)),
    handlingDepartment: Yup.array()
      .nullable()
      .required(FORM_MSG.requiredSelectFieldMsg(labelFieldForm.handlingDepartment)),
    // combinedDepartment: Yup.array()
    //   .nullable()
    //   .min(1, FORM_MSG.requiredSelectFieldMsg(labelFieldForm.combinedDepartment))
    //   .required(FORM_MSG.requiredSelectFieldMsg(labelFieldForm.combinedDepartment)),
    bod: Yup.array()
      .nullable()
      .min(1, FORM_MSG.requiredSelectFieldMsg(labelFieldForm.bod))
      .required(FORM_MSG.requiredSelectFieldMsg(labelFieldForm.bod)),
  });

  const getAllDepartment = () => {
    getListDepartmentFromHr("").then((res) => {
      setDepartments(res.data);
    });
  };

  const getAllLeaderShip = () => {
    getUserApi("", "").then((res) => {
      setLeaderShips(res.data);
    });
  };

  const getDocBook = () => {
    const params: CodeDocBookModel = {
      code: `${DOC_BOOK_ENUM.DOC_IN_INTERNATIONAL}`,
    };
    getDocBookApi(params).then((res) => {
      setDocBooks(res.data);
    });
  };

  const buildInitialValues = (docIn: DocInModel): InitialValuesForm => {
    const deadline = docIn?.deadline
      ? {
          code: DEADLINE_DOC_IN_ENUM.OTHER,
          description: moment(docIn?.deadline).format(VIEW_DATE_TIME_WITHOUT_SECOND_FORMAT),
          deadlineTime: new Date(docIn?.deadline),
        }
      : null;
    const valueForm: InitialValuesForm = {
      docCategory: UPLOAD_CATEGORY_DOC_IN_ENUM.INTERNATIONAL,
      code: docIn.code,
      fromPlace: docIn.fromPlace,
      docDate: new Date(docIn.docDate),
      arrivalDate: new Date(docIn.arrivalDate),
      docBook: docIn.docBook,
      summary: docIn.summary,
      arrivalNbr: docIn.arrivalNbr,
      deadline,
      deadlineTime: docIn?.deadline ? new Date(docIn?.deadline) : null,
      // sentChannel: docIn.sentChannel,
      handlingDepartment: docIn?.handlingDepartments,
      combinedDepartment: docIn?.combinedDepartments,
      contentTranslated: docIn.contentTranslated,
      bod: docIn.bods,
      id: docIn.id,
    };
    return valueForm;
  };

  const getDocIn = async () => {
    try {
      const res = await getDocInByIdApi(Number(params?.id));
      setDocInDetail(res?.data);
      setInitialValues(buildInitialValues(res.data));
      setFileUploaded(res.data.docInFiles);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDocIn();
    getAllDepartment();
    getAllLeaderShip();
    getDocBook();
  }, []);

  const handleCancel = (formik: FormikHelpers<InitialValuesForm>) => {
    formik.resetForm();
    setFileUploaded(docInDetail?.docInFiles || []);
    setFiles([]);
  };

  const handleOnSubmit = (values: InitialValuesForm, formik: FormikHelpers<InitialValuesForm>) => {
    const formatDocDate = values?.docDate ? moment(values?.docDate).format(UPLOAD_DATE_FORMAT) : null;
    const formatArrivalDate = values?.arrivalDate ? moment(values?.arrivalDate).format(UPLOAD_DATE_FORMAT) : null;
    const arrCodeHandlingDepartment = values?.handlingDepartment?.map((item: Department) => item?.code) || [];
    const arrCodeCombinedDepartment = values?.combinedDepartment?.map((item: Department) => item?.code) || [];
    const formatHandlingDepartment = convertArrToString(arrCodeHandlingDepartment);
    const formatCombinedDepartment = convertArrToString(arrCodeCombinedDepartment);
    const arrBod = values?.bod?.map((item: UserModel) => item?.account);
    const formatBod = convertArrToString(arrBod);
    const newValues = {
      ...values,
      docDate: formatDocDate,
      arrivalDate: formatArrivalDate,
      isProcess: DEADLINE_DOC_IN_ENUM.PROCESSED === values?.deadline?.code,
      deadline: DEADLINE_DOC_IN_ENUM.PROCESSED !== values?.deadline?.code ? values?.deadlineTime : null,
      handlingDepartment: formatHandlingDepartment,
      combinedDepartment: formatCombinedDepartment,
      bod: formatBod,
      arrivalNbr: values?.arrivalNbr,
      docBookId: values?.docBook?.id,
      docFileIds: fileUploaded.map((item) => item.id),
    };
    setIsProcessed(DEADLINE_DOC_IN_ENUM.PROCESSED === values?.deadline?.code);
    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      formData.append("dataFile", element);
    }
    formData.append(
      "data",
      new Blob([JSON.stringify(newValues)], {
        type: "application/json",
      })
    );

    setBodyFormData(formData);
    setOpenModalSubmit(true);
  };

  const changeUploadFile = (e: any) => {
    const tempFiles: any = changeFileDocIn(e, files, "", MAX_LENGTH_FILE_DOC_IN - fileUploaded.length);
    setFiles(tempFiles);
  };

  const handleRemoveFile = (item: File) => {
    setFiles(removeFile(item, files));
  };

  const handleRemoveFileUploaded = (item: FileModel) => {
    const fileUploadedRemoves = fileUploaded.filter((file) => file.id !== item.id);
    setFileUploaded(fileUploadedRemoves);
  };

  const handleChangeDocBookId = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("docBook", e);
  };

  const getArrivalNumber = async (formik: FormikProps<InitialValuesForm>, docBookId: number) => {
    await getArrivalNbrApi(docBookId, DOC_BOOK_ENUM.DOC_IN_INTERNATIONAL).then((res) => {
      formik.setFieldValue("arrivalNbr", res?.data + "");
    });
  };

  const handleChangeHandlingDepartment = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("handlingDepartment", e);
  };

  const handleChangeCombinedDepartment = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("combinedDepartment", e);
  };

  const handleChangeBod = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("bod", e);
  };

  const handleConfirmSubmit = async () => {
    if (bodyFormData) {
      try {
        await updateDocInApi(bodyFormData);
        navigate("/doc-in");
        toast.success(DOC_IN_MSG.updateDomesticSuccess);
      } catch (err) {
        toast.error(getErrorMsgAxios(err));
      }
    }
  };

  const handleChangeHandlingDeadline = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("deadline", e);
    if (e?.code === DEADLINE_DOC_IN_ENUM.OTHER) {
      deadlineTimeBtnInputRef.current?.click();
      setOpenDeadLineTimeModal(true);
    } else {
      formik.setFieldValue("deadlineTime", "");
      setOpenDeadLineTimeModal(false);
    }
  };

  const handleChangeDeadlineTime = (formik: FormikHelpers<InitialValuesForm>) => (e: Object | null | any) => {
    formik.setFieldValue("deadlineTime", e);
    const formatDeadlineTime = moment(e).format(VIEW_DATE_TIME_FORMAT);
    const convertDeadlineTime = {
      code: DEADLINE_DOC_IN_ENUM.OTHER,
      description: formatDeadlineTime,
      deadlineTime: e,
    };
    formik.setFieldValue("deadline", convertDeadlineTime);
    setOpenDeadLineTimeModal(false);
  };

  return (
    <>
      {/* confirm submit */}
      <ConfirmModal
        title="Xác nhận"
        openModal={openModalSubmit}
        onCloseModal={() => {
          setOpenModalSubmit(false);
        }}
        onCancel={() => {
          setOpenModalSubmit(false);
        }}
        onConfirm={() => {
          setOpenModalSubmit(false);
          handleConfirmSubmit();
        }}
      >
        <Typography>{isProcessed ? DOC_IN_MSG.confirmProcessed : DOC_IN_MSG.confirmSubmit}</Typography>
      </ConfirmModal>

      {/* header */}
      <HeaderPage title="Phiếu chuyển công văn đến quốc tế" variant="h5" component="h5">
        <Stack direction={{ xs: "column", sm: "row" }} spacing={spacing_size} sx={{ marginBottom: "10px" }}>
          <Button variant="outlined" type="button" onClick={() => btnCancelRef.current?.click()}>
            Nhập lại
          </Button>
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              btnSubmitRef.current?.click();
            }}
            startIcon={<BrowserIcon />}
          >
            Trình duyệt
          </Button>
        </Stack>
      </HeaderPage>

      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <Form noValidate autoComplete="off">
              {/* <pre>{JSON.stringify(formik?.values, undefined, 2)}</pre> */}
              {/* form input */}
              <BoxContainer>
                <HeaderForm title={"Thông tin chung"} />
                <Grid container spacing={spacing_size}>
                  <Grid item xs={12} md={6} lg={6}>
                    <FieldContainer
                      fullWidth
                      name="code"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.code}
                      placeholder={FORM_MSG.placeholder}
                      maxRows={12}
                      label={labelFieldForm.code}
                      notched={true}
                      variant={"input"}
                      id={"code"}
                      isRequired={true}
                      autoComplete="off"
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FieldContainer
                      fullWidth
                      name="fromPlace"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 255 }}
                      value={formik.values.fromPlace}
                      placeholder={FORM_MSG.placeholder}
                      label={labelFieldForm.fromPlace}
                      isRequired
                      variant={"input"}
                      id={"fromPlace"}
                      autoComplete="off"
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FieldContainer
                      fullWidth
                      name="docDate"
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholderText={FORM_MSG.dateFormatPlaceholder}
                      label={labelFieldForm.docDate}
                      isRequired
                      variant={"date"}
                      id={"docDate"}
                      maxDate={currentDate}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FieldContainer
                      fullWidth
                      name="arrivalDate"
                      onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      type="text"
                      // inputProps={{ maxLength: 12 }}
                      // value={formik.values.arrivalDate}
                      placeholderText={FORM_MSG.dateFormatPlaceholder}
                      label={labelFieldForm.arrivalDate}
                      isRequired
                      variant={"date"}
                      id={"arrivalDate"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FieldContainer
                      fullWidth
                      name="docBook"
                      onBlur={formik.handleBlur}
                      onChange={(e: any) => {
                        formik.setFieldValue("docBook", e);
                        getArrivalNumber(formik, Number(e?.id));
                      }}
                      type="text"
                      inputProps={{ maxLength: 12 }}
                      value={formik.values.docBook}
                      placeholder={FORM_MSG.placeholder}
                      label={labelFieldForm.docBook}
                      isRequired
                      variant={"react-select"}
                      getOptionLabel={(item: DocBook) => item.name}
                      getOptionValue={(item: DocBook) => item.id}
                      id={"docBook"}
                      options={docBooks}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FieldContainer
                      fullWidth
                      name="arrivalNbr"
                      type="number"
                      value={formik.values.arrivalNbr}
                      label={labelFieldForm.arrivalNbr}
                      variant={"input"}
                      id={"arrivalNbr"}
                      // disabled
                      onChange={formik.handleChange}
                      onKeyDown={(e: any) => EXCEPT_SYMBOLS.includes(e.key) && e.preventDefault()}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <FieldContainer
                      fullWidth
                      name="summary"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 5000 }}
                      value={formik.values.summary}
                      placeholder={FORM_MSG.placeholder}
                      label={labelFieldForm.summary}
                      isRequired
                      variant={"textarea"}
                      id={"summary"}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <Typography>Thông tin đính kèm</Typography>
                    <p
                      style={{
                        color: "#466FFF",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Đính kèm file
                    </p>

                    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                      <Grid item sm={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                            border: "1px dashed #0240B8",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "1rem",
                            }}
                          >
                            <label>
                              <img
                                alt="img upload"
                                src={UploadSVG}
                                style={{
                                  width: "65px",
                                  height: "50px",
                                }}
                              />
                            </label>
                            <Typography
                              className="mt-2"
                              sx={{
                                color: "#ADAAAA",
                                fontSize: "12px",
                                fontWeight: "500",
                              }}
                            >
                              Tải lên tài liệu liên quan
                            </Typography>
                          </Box>
                          <input
                            id="file-input"
                            // accept="application/msword, application/vnd.ms-excel, application/pdf"
                            accept={ALLOW_INPUT_FILE_DOC_IN}
                            type="file"
                            multiple
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              top: 0,
                              left: 0,
                              border: "none",
                              zIndex: 0,
                              opacity: 0,
                            }}
                            onChange={(e: any) => changeUploadFile(e)}
                            // #inputFile
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    {/* show list file */}
                    {(files?.length > 0 || fileUploaded?.length > 0) && (
                      <Grid container spacing={spacing_size} className="mt-2">
                        <Grid container item xs={12} md={12} lg={12}>
                          {files &&
                            files.map((item: any) => {
                              return <ViewFile item={item} handleRemove={handleRemoveFile} />;
                            })}

                          {fileUploaded &&
                            fileUploaded.map((item: any) => {
                              return <ViewFile item={item} handleRemove={handleRemoveFileUploaded} />;
                            })}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#0084FF",
                        fontStyle: "italic",
                      }}
                    >
                      ! Vui lòng tải file Word, Excel, PDF, PNG, JPG, JPEG, RAR, ZIP
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <Stack direction="row" sx={{ flexWrap: "nowrap" }} alignItems="flex-end">
                      <FieldContainer
                        fullWidth
                        name="deadline"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeHandlingDeadline(formik)}
                        value={formik.values.deadline}
                        placeholder="Chọn thông tin"
                        label="Thời hạn xử lý theo văn bản"
                        // isRequired
                        variant={"react-select"}
                        id={"deadline"}
                        getOptionValue={(item: EnumCommon<DEADLINE_DOC_IN_ENUM>) => item.code}
                        getOptionLabel={(item: EnumCommon<DEADLINE_DOC_IN_ENUM>) => item.description}
                        isClearable
                        options={deadlineDocIns}
                        menuPlacement="top"
                        styleContainer={{ width: "100%" }}
                      />
                      {(formik.values?.deadline?.code === DEADLINE_DOC_IN_ENUM.OTHER || true) && (
                        <Typography
                          sx={{
                            opacity: formik.values?.deadline?.code === DEADLINE_DOC_IN_ENUM.OTHER ? 1 : 0,
                            width: formik.values?.deadline?.code === DEADLINE_DOC_IN_ENUM.OTHER ? "initial" : 0,
                          }}
                        >
                          <FieldContainer
                            fullWidth
                            name="deadlineTime"
                            onBlur={() => {
                              setOpenDeadLineTimeModal(true);
                            }}
                            onChange={handleChangeDeadlineTime(formik)}
                            value={formik.values.deadlineTime}
                            placeholderText="dd/mm/yyyy"
                            label=""
                            variant={"date"}
                            id={"deadlineTime"}
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            showTimeSelect
                            // open={formik.values?.deadline?.code === DEADLINE_DOC_IN_ENUM.OTHER && openDeadLineTimeModal}
                            customInput={
                              <CustomInputCalendar btnRef={deadlineTimeBtnInputRef} onMouseDown={() => {}} />
                            }
                          />
                        </Typography>
                      )}
                    </Stack>
                  </Grid>

                  {/* <Grid item xs={12} md={6} lg={6}>
                    <FieldContainer
                      name="sentChannel"
                      // onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      // inputProps={{ maxLength: 12 }}
                      value={formik.values.sentChannel}
                      placeholder=""
                      label={labelFieldForm.sentChannel}
                      isRequired
                      variant={"radio"}
                      id={"sentChannel"}
                      options={optionsSentChannel}
                    />
                  </Grid> */}
                </Grid>
              </BoxContainer>

              {/* nội dung văn bản được dịch */}
              <BoxContainer>
                <Typography sx={{ fontSize: "1.2rem", fontWeight: "600" }}>Nội dung văn bản được dịch</Typography>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <FieldContainer
                      fullWidth
                      name="contentTranslated"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 12 }}
                      value={formik.values.contentTranslated}
                      placeholder={FORM_MSG.placeholder}
                      label=""
                      isRequired
                      variant={"textarea"}
                      id={"contentTranslated"}
                    />
                  </Grid>
                </Grid>
              </BoxContainer>

              {/* Quá trình luân chuyển văn bản */}
              <BoxContainer>
                <Typography sx={{ fontSize: "1.2rem", fontWeight: "600" }}>Quá trình luân chuyển văn bản</Typography>
                <Grid container rowSpacing={spacing_size} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={4} lg={4}>
                    <FieldContainer
                      fullWidth
                      name="handlingDepartment"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeHandlingDepartment(formik)}
                      value={formik.values.handlingDepartment}
                      placeholder={FORM_MSG.reactSelectPlaceholder}
                      label={labelFieldForm.handlingDepartment}
                      isRequired
                      getOptionLabel={(item: DepartmentHR) =>
                        `${item.code} - ${item.name ? item.name : item?.displayName}`
                      }
                      getOptionValue={(item: DepartmentHR) => item.code}
                      variant={"react-select"}
                      id={"handlingDepartment"}
                      isClearable
                      options={departments}
                      menuPlacement="top"
                      isMulti
                    />
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <FieldContainer
                      fullWidth
                      name="combinedDepartment"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeCombinedDepartment(formik)}
                      type="text"
                      value={formik.values.combinedDepartment}
                      placeholder={FORM_MSG.reactSelectPlaceholder}
                      label={labelFieldForm.combinedDepartment}
                      // isRequired
                      variant={"react-select"}
                      getOptionLabel={(item: DepartmentHR) =>
                        `${item.code} - ${item.name ? item.name : item?.displayName}`
                      }
                      getOptionValue={(item: DepartmentHR) => item.code}
                      id={"combinedDepartment"}
                      isClearable
                      isMulti
                      options={departments}
                      menuPlacement="top"
                    />
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <FieldContainer
                      fullWidth
                      name="bod"
                      onBlur={formik.handleBlur}
                      onChange={handleChangeBod(formik)}
                      type="text"
                      value={formik.values.bod}
                      placeholder={FORM_MSG.reactSelectPlaceholder}
                      label={labelFieldForm.bod}
                      getOptionLabel={(item: UserModel) => item.fullName}
                      getOptionValue={(item: UserModel) => item.email}
                      isRequired
                      variant={"react-select"}
                      id={"bod"}
                      options={leaderShips}
                      isMulti
                      menuPlacement="top"
                      components={{ Option: OptionSelectUser }}
                    />
                  </Grid>
                </Grid>
              </BoxContainer>

              {/* actions footer */}
              <Grid item sm={12} justifyContent="center">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={spacing_size}
                  sx={{ marginBottom: "10px", justifyContent: "end" }}
                >
                  <Button variant="outlined" ref={btnCancelRef} onClick={() => handleCancel(formik)}>
                    Nhập lại
                  </Button>
                  <Button type="submit" variant="contained" ref={btnSubmitRef} startIcon={<BrowserIcon />}>
                    Trình duyệt
                  </Button>
                </Stack>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default FormUpdateDocInInternational;
